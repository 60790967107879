import { getRentalImagesAPI } from "../../../services/getRentalImages";

export enum getRenatlImagesType {
  GET_RENTAL_IMAGES_INFO = "GET_RENTAL_IMAGES_INFO",
  GET_RENTAL_IMAGES_INFO_SUCCESS = "GET_RENTAL_IMAGES_INFO_SUCCESS",
  GET_RENTAL_IMAGES_INFO_FALIURE = "GET_RENTAL_IMAGES_INFO_FALIURE",
}

export const getRentalImagesInfo = () => (dispatch: any) => {
  dispatch({
    type: getRenatlImagesType.GET_RENTAL_IMAGES_INFO,
  });
};

export const getRentalImagesInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getRenatlImagesType.GET_RENTAL_IMAGES_INFO_SUCCESS,
    data,
  });
};

export const getRentalImagesInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getRenatlImagesType.GET_RENTAL_IMAGES_INFO_FALIURE,
    error: msg,
  });
};

export const getRentalImagesData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getRentalImagesInfo());
    const result: any = await getRentalImagesAPI(id);
    dispatch(getRentalImagesInfoSuccess(result));
  } catch (e: any) {
    dispatch(getRentalImagesInfoFailed(e?.response?.data?.message));
  }
};
