import { useEffect, useState } from "react";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import CircleSuccessIcon from "../../assets/images/circle-success-icon.svg";
import CircleInfoIcon from "../../assets/images/circle-info-icon.svg";
import CircleWaitIcon from "../../assets/images/circle-wait-icon.svg";
import PageLoaderIcon from "../../assets/images/page-loader.svg";

import {
  SearchUserPanel,
  SearchUserBox,
  FormGroup,
  SearchBoxNote,
  UserListWrap,
  UserListItem,
  UserListImage,
  UserTime,
  UserListInfo,
  UserListInfoImage,
  SearchListLoader,
} from "./leftSearchPanelStyle";

import CustomInput from "../CustomInput";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { planDetailsHandler } from "../../redux/action/planDetails/planDetails";
import moment from "moment";
import { searchUserData } from "../../redux/action/search-user/searchUser";
import { planActivityHandler } from "../../redux/action/getPlanActivity/getPlanActivity";
import GetAllCollectionsModal from "../../components/collectionsModal";
import PlansModal from "../../components/plansModal/index";
import AnotherDayModal from "../../components/anotherDayModal";
import { getImageGoogleData } from "../../redux/action/getImageGoogle/getImageGoogle";
import CreatePlanModal from "../../components/createPlanModal/index";
import InviteOthersModal from "../../components/inviteOthersModal/index";
import { getGroupData } from "../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../redux/action/plan-listing/planListing";
import ChatsPlanModal from "../chatsPlanModal";
import CopyPlanModal from "../copyPlanModal";
import ShareWithModal from "../shareWithModal";
import LasVegas from "../../assets/images/las-vegas.png";
import VerifiedIcon from "@mui/icons-material/Verified";

const LeftSearchPanel = (props: any) => {
  const history: any = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [rentalItemId, setRentalItemId] = useState("");
  const [venue, setVenue] = useState("");
  const [plansModal, setPlansModal] = useState(false);
  const [copyPlanModal, setCopyPlanModal] = useState(false);
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [image, setImage] = useState("");
  const [interestData, setInterestData] = useState("");
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [sharewithImage, setShareWithImage] = useState("");
  const [rentalLink, setRentalLink] = useState("");
  const [rentalName, setRentalName] = useState("");
  const [locationData, setlocationData] = useState("");
  const [rentalAddress, setRentalAddress] = useState("");
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [collectionName, setCollectionName] = useState("");
  const SideSearchUserPanel = () => {
    const panelBtn = document.querySelector(".searchUserPanel");
    if (document.body.classList !== null) {
      document.body.classList.toggle("left-panel-open");
    }
    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };

  const searchPage = useSelector((state: any) => state?.searchUser);
  const getCretaedPlanDetails = useSelector((state: any) => state?.createPlan);
  const updatePlan: any = useSelector((state: any) => state?.updatePlan);
  const planActivityDetails = useSelector((state: any) => state?.planAcitivity);

  const planStatus: any = useSelector((state: any) => state?.planStatus);
  const invitePlan: any = useSelector((state: any) => state?.invitePlan);
  const manageDayPlan: any = useSelector((state: any) => state?.manageDayPlan);
  const addCollection: any = useSelector((state: any) => state?.addCollection);

  const removeInvitePlan: any = useSelector(
    (state: any) => state?.removeInvitePlan
  );
  const addAnotherDayPlan: any = useSelector(
    (state: any) => state?.addAnotherDayPlan
  );
  const copyAnotherDayPlan: any = useSelector(
    (state: any) => state?.copyAnotherDayPlan
  );
  const deleteVenuePlan: any = useSelector(
    (state: any) => state?.deleteVenuePlan
  );
  const updateVenuePlan: any = useSelector(
    (state: any) => state?.updateVenuePlan
  );
  const manageVenuePlan: any = useSelector(
    (state: any) => state?.manageVenuePlan
  );
  const deleteCollectionItem: any = useSelector(
    (state: any) => state?.deleteCollectionItem
  );

  const updateCollection: any = useSelector(
    (state: any) => state?.updateCollection
  );

  useEffect(() => {
    dispatch(planActivityHandler());
  }, [
    getCretaedPlanDetails,
    updatePlan,
    planStatus,
    deleteCollectionItem,
    removeInvitePlan,
    invitePlan,
    addAnotherDayPlan,
    copyAnotherDayPlan,
    deleteVenuePlan,
    updateVenuePlan,
    addCollection?.data?.data,
    manageVenuePlan,
    updateCollection,
  ]);

  const submitForm = (values: any) => {
    console.log(values, "values");
  };
  const searchTextData = (event: any) => {
    setSearchTerm(event);
    const finalValue = {
      search: event.trim(),
    };

    if (event.trim() !== "") {
      dispatch(searchUserData(finalValue));
    }
  };

  const userNameHandler = (e: any) => {
    const re = /^[a-zA-Z0-9-_.]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const getImageGoogle: any = useSelector((state: any) => state.getImageGoogle);

  const planData = (e: any) => {
    setInterestData(getImageGoogle?.data?.result);
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const handleDetails = (e: any, j: any) => {
    if (
      e === "point of interest" ||
      e === "food and drink" ||
      e === "hiking" ||
      e === "point of interest" ||
      e === "food and drink" ||
      e === "hiking"
    ) {
      const finalDatas = {
        place_id: j,
      };
      dispatch(getImageGoogleData(finalDatas));
    }
  };

  //for plan create
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getCollectionModal, setCollectionModal] = useState(false);

  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [showSortBy, setShowSortBy] = useState(-1);
  const [sortingType, setSortingType] = useState("Price");
  const [totalPages, setTotalPages] = useState(1);
  const [getAllImages, setAllImages] = useState([]);
  const [openChatModel, setOpenChatModel] = useState(false);
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [placeIdData, setPlaceIdData] = useState("");
  const [groupImages, setGroupImages] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [getActivity, setActivity] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);

  const [getImageUrl, setImageUrl] = useState("");
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [allItems, setAllItems] = useState("");
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setUploadImage] = useState("");
  const [showimage, setShowImage] = useState("");

  const createPlan = useSelector((state: any) => state?.createPlan);

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const [name, setName] = useState("");
  const planDetails: any = useSelector((state: any) => state?.planDetails);

  useEffect(() => {
    const data: any = {
      plan_id: getActivity,
    };
    getActivity && dispatch(planDetailsHandler(data));
  }, [getActivity]);

  const shareChatImages = (ele: any) => {
    if (ele?.activity_type === "Collection_item") {
      if (ele?.item?.venue_type === "rental") {
        setName("Collection");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/rental-result-details");
      } else if (ele?.item?.venue_type === "food and drink") {
        setName("Collection");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/food-and-drink-details");
      } else if (ele?.item?.venue_type === "hotel") {
        setName("Collection");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/hotel-result-details");
      } else if (ele?.item?.venue_type === "point of interest") {
        setName("Collection");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/interest-details-results");
      } else if (ele?.item?.venue_type === "hiking") {
        setName("Collection");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/hiking-result-details");
      } else if (ele?.item?.venue_type === "concerts") {
        setName("Collection");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/concert-result-details");
      } else if (ele?.item?.venue_type === "festival") {
        setName("Collection");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/festival-result-details");
      } else if (ele?.item?.venue_type === "sports") {
        setName("Collection");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.item.venue_id);
        setDynamicLocation("/sports-results-details");
      }
    } else {
      if (ele?.venue_type === "rental") {
        setName("venue");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/rental-result-details");
      } else if (ele?.venue_type === "food and drink") {
        setName("venue");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/food-and-drink-details");
      } else if (ele?.venue_type === "hotel") {
        setName("venue");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/hotel-result-details");
      } else if (ele?.venue_type === "point of interest") {
        setName("venue");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/interest-details-results");
      } else if (ele?.venue_type === "hiking") {
        setName("venue");
        setGroupImages(ele.item);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/hiking-result-details");
      } else if (ele?.venue_type === "concerts") {
        setName("venue");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/concert-result-details");
      } else if (ele?.venue_type === "festival") {
        setName("venue");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/festival-result-details");
      } else if (ele?.venue_type === "sports") {
        setName("venue");
        setGroupImages(ele?.cover_photo);
        setAllItems(ele.item);
        setPlaceIdData(ele.venue_id);
        setDynamicLocation("/sports-results-details");
      }
    }
  };

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  useEffect(() => {
    if (getImageGoogle?.data?.result) {
      setInterestData(getImageGoogle?.data?.result);
      setRentalLink(getImageGoogle?.data?.result?.website);
    }
  }, [getImageGoogle?.data?.result]);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const navigateToShareWith = (ele: any) => {
    if (ele?.activity_type === "Collection_item") {
      if (
        ele.item?.venue_type === "point of interest" ||
        ele.item?.venue_type === "food and drink" ||
        ele.item?.venue_type === "hiking"
      ) {
        const finalDatas = {
          place_id: ele.item.venue_id,
        };
        dispatch(getImageGoogleData(finalDatas));
      }

      if (
        ele?.item?.venue_type === "rental" ||
        ele?.item?.venue_type === "hotel"
      ) {
        setRentalName(ele?.collection_item_name);
        setShareWithImage(ele?.cover_photo);
        setRentalLink(ele?.item?.Links?.WebSearchResult?.Href);
        setRentalAddress(`${ele?.item?.location} ${ele?.item?.province}`);
      }
      if (ele?.item?.venue_type === "concerts") {
        setRentalAddress(`${ele?.item?.location} ${ele?.item?.province}`);
        setRentalName(ele?.collection_item_name);
        // setShareWithImage(ele?.cover_photo);
      }
    } else {
      if (
        ele.venue_type === "point of interest" ||
        ele.venue_type === "food and drink" ||
        ele.venue_type === "hiking"
      ) {
        const finalDatas = {
          place_id: ele.venue_id,
        };
        dispatch(getImageGoogleData(finalDatas));
        setRentalName(ele?.venue_name);
        setShareWithImage(ele?.cover_photo);
        // setRentalLink(getImageGoogle?.data?.result?.website);
        setRentalAddress(ele?.item?.vicinity);
      } else if (
        ele?.venue_type === "concerts" ||
        ele?.venue_type === "festival" ||
        ele?.venue_type === "sports"
      ) {
        setRentalName(ele?.venue_name);
        setShareWithImage(ele?.cover_photo);
        setRentalLink(ele?.item?._links["event:webpage"].href);
        setRentalAddress(
          `${ele?.item?._embedded?.venue?.city} ${ele?.item?._embedded?.venue?.name}`
        );
      } else if (ele?.venue_type === "rental" || ele?.venue_type === "hotel") {
        setRentalName(ele?.venue_name);
        setShareWithImage(ele?.cover_photo);
        setRentalLink(ele?.item?.Links?.WebSearchResult?.Href);
        setRentalAddress(
          `${ele?.item?.Location?.Address?.City} ${ele?.item?.Location?.Address?.Country} ${ele?.item?.Location?.Address?.PostalCode}`
        );
      }
    }

    setShareWithModalShow(true);

    // setShareWithImage(ele?.cover_photo);
    // setRentalLink(getImageGoogle?.data?.result?.website);
    // setRentalName(ele?.venue_name);
    // setRentalAddress(ele?.item?.vicinity);
  };

  const navigateToProfile = (ele: any) => {
    if (ele?.user_id === localStorage.getItem("id")) {
      navigate("/myprofile");
    } else {
      navigate(`/socialprofile/${ele?.user_id}`);
    }
  };

  const navigateToItem = (type: any, id: any) => {
    switch (type) {
      case "concerts":
        navigate(`/concert-result-details/${id}`);
        break;
      case "sports":
        navigate(`/sports-results-details/${id}`);
        break;
      case "festival":
        navigate(`/festival-result-details/${id}`);
        break;
      case "rental":
        navigate(`/rental-result-details/${id}`);
        break;
      case "food and drink":
        navigate(`/food-and-drink-details/${id}`);
        break;
      case "point of interest":
        navigate(`/interest-details-results/${id}`);
        break;
      case "hiking":
        navigate(`/hiking-result-details/${id}`);
        break;
      case "hotel":
        navigate(`/hotel-result-details/${id}`);
        break;

      default:
    }
  };

  return (
    <>
      <SearchUserPanel className="searchUserPanel">
        <Button onClick={SideSearchUserPanel} className="panelToggleBtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.166"
            height="10.673"
            viewBox="0 0 9.166 10.673"
          >
            <g transform="translate(-1338.023 -173.24)">
              <path
                d="M-9189.215-12227.616l-4.913,4.912,4.913,4.913"
                transform="translate(10533 12401.28)"
                fill="none"
                stroke="#4d27da"
                stroke-width="1.2"
              />
              <path
                d="M-9191.147-12227.612l-2.98,2.977,2.98,2.982"
                transform="translate(10537.912 12403.21)"
                fill="none"
                stroke="#4d27da"
                stroke-width="1.2"
              />
            </g>
          </svg>
        </Button>
        <SearchUserBox>
          <FormGroup>
            <Formik
              initialValues={{
                search: "",
              }}
              validate={(values: any) => {
                let errors: any = {};
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <CustomInput
                      inputid="search"
                      inputlabe=""
                      inputtype="text"
                      placeholder="Search User"
                      value={searchTerm}
                      handleBlur={handleBlur}
                      handleChange={(event: any) => {
                        // setSearchTerm(event.target.value);
                        searchTextData(event.target.value);
                      }}
                      onKeyPress={userNameHandler}
                      errors=""
                      touched=""
                      autocomplete="off"
                    />

                    <button
                      type="submit"
                      className="searchIconBtn"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.921"
                        height="22.01"
                        viewBox="0 0 21.921 22.01"
                      >
                        <g transform="translate(0 0)">
                          <path
                            d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                            transform="translate(-3.002 -3.001)"
                            fill="#cecece"
                          />
                        </g>
                      </svg>
                    </button>
                  </form>
                </>
              )}
            </Formik>
          </FormGroup>
        </SearchUserBox>

        {searchPage?.data?.data?.length === 0 && searchTerm.trim() !== "" && (
          <SearchBoxNote>No result found for {searchTerm} </SearchBoxNote>
        )}

        {searchTerm.trim() === "" &&
          planActivityDetails?.data?.data?.length === 0 && (
            <SearchBoxNote>
              Follow people to see which events, experiences, restaurants,
              rental, hotels and places they are interested in and trending.
            </SearchBoxNote>
          )}

        {searchPage?.isLoading && (
          <SearchListLoader>
            <img src={PageLoaderIcon} alt="Loading..." />
          </SearchListLoader>
        )}

        {searchTerm.trim() !== "" && (
          <UserListWrap className="searchUserList">
            {searchPage?.data?.data?.map((val: any, key: any) => {
              return (
                <UserListItem>
                  <Button
                    type="button"
                    onClick={() => {
                      if (val._id !== localStorage.getItem("id")) {
                        navigate(`/socialprofile/${val._id}`);
                      } else {
                        navigate(`/myprofile`);
                      }
                      setSearchTerm("");
                    }}
                  >
                    <>
                      <UserListImage>
                        <img src={val?.photo ?? ""} alt={val?.userName} />
                      </UserListImage>
                      <UserListInfo>
                        <h3>
                          <strong>
                            {val?.userName ?? ""}{" "}
                            {val.batch ? (
                              <VerifiedIcon
                                style={{ color: "#458eff", paddingLeft: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                          </strong>
                        </h3>
                      </UserListInfo>
                    </>
                  </Button>
                </UserListItem>
              );
            })}
          </UserListWrap>
        )}

        {planActivityDetails?.isLoading && (
          <SearchListLoader>
            <img src={PageLoaderIcon} alt="Loading..." />
          </SearchListLoader>
        )}

        {searchTerm.trim() === "" && (
          <UserListWrap>
            {planActivityDetails?.data?.data?.map((ele: any) => {
              return (
                <>
                  <UserListItem>
                    <UserListImage>
                      <Link
                        to={
                          ele?.user_id === localStorage.getItem("id")
                            ? "/myprofile"
                            : `/socialprofile/${ele?.user_id}`
                        }
                      >
                        <img src={ele?.user_image} alt="" />
                      </Link>
                    </UserListImage>

                    <UserListInfo>
                      {ele?.activity_type === "Plan" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>{" "}
                          {` created a plan `}
                          {!ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <Link
                                to={`/viewplandetails/${ele?.plan_id}`}
                                style={{ color: "black" }}
                              >
                                <strong>{`${ele?.plan_name}`}</strong>{" "}
                              </Link>
                            )}
                          {(ele?.is_deleted ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <strong>{` ${ele?.plan_name}`}</strong>
                          )}
                        </h3>
                      )}

                      {ele?.activity_type === "Status" && (
                        <h3>
                          <strong
                            onClick={() => navigateToProfile(ele)}
                          >{`${ele?.user_name}`}</strong>{" "}
                          {ele?.status_value === "Going" ||
                          ele?.status_value === "Unsure"
                            ? `is `
                            : `selected `}
                          <strong
                            style={{
                              color:
                                ele?.status_value === "Going"
                                  ? "#159a43"
                                  : ele?.status_value === "Unsure"
                                  ? "#ffa700"
                                  : ele?.status_value === "Another Time"
                                  ? "#4d27da"
                                  : "",
                            }}
                          >
                            {` ${ele?.status_value} `}
                          </strong>
                          {ele?.status_value === "Going" && (
                            <>
                              <img src={CircleSuccessIcon} alt="success-icon" />
                            </>
                          )}
                          {ele?.status_value === "Unsure" && (
                            <>
                              <img src={CircleInfoIcon} alt="success-icon" />
                            </>
                          )}
                          {ele?.status_value === "Another Time" && (
                            <>
                              <img src={CircleWaitIcon} alt="success-icon" />
                            </>
                          )}{" "}
                          {!ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <Link
                                to={`/viewplandetails/${ele?.plan_id}`}
                                style={{ color: "black" }}
                              >
                                <strong>{` ${ele?.plan_name} `}</strong>{" "}
                              </Link>
                            )}
                          {(ele?.is_deleted ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <strong>{` ${ele?.plan_name} `}</strong>
                          )}
                        </h3>
                      )}

                      {ele?.activity_type === "Venue" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>{" "}
                          Added{" "}
                          <strong
                            onClick={() =>
                              navigateToItem(ele?.venue_type, ele?.venue_id)
                            }
                          >
                            {ele?.venue_name}{" "}
                          </strong>
                          {!ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <Link
                                to={`/viewplandetails/${ele?.plan_id}`}
                                style={{ color: "black" }}
                              >
                                <strong>{` to ${ele?.plan_name} `}</strong>{" "}
                                {` day ${ele?.day_no}`}
                              </Link>
                            )}
                          {(ele?.is_deleted ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <>
                              <strong>{` to ${ele?.plan_name} `}</strong>
                              {` day ${ele?.day_no}`}
                            </>
                          )}
                        </h3>
                      )}

                      {ele?.activity_type === "Moved" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>{" "}
                          {`Moved `}
                          <strong
                            onClick={() =>
                              navigateToItem(ele?.venue_type, ele?.venue_id)
                            }
                          >
                            {ele?.venue_name}{" "}
                          </strong>{" "}
                          to day {ele?.day_no}{" "}
                          {!ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <Link
                                to={`/viewplandetails/${ele?.plan_id}`}
                                style={{ color: "black" }}
                              >
                                <strong>{` in ${ele?.plan_name}`}</strong>{" "}
                              </Link>
                            )}
                          {(ele?.is_deleted ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <strong>{` in ${ele?.plan_name}`}</strong>
                          )}
                        </h3>
                      )}

                      {ele?.activity_type === "Copied" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>{" "}
                          {`Copied `}
                          <strong
                            onClick={() =>
                              navigateToItem(ele?.venue_type, ele?.venue_id)
                            }
                          >
                            {ele?.venue_name}{" "}
                          </strong>{" "}
                          to day {ele?.day_no}{" "}
                          {!ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <Link
                                to={`/viewplandetails/${ele?.plan_id}`}
                                style={{ color: "black" }}
                              >
                                <strong>{` in ${ele?.plan_name}`}</strong>{" "}
                              </Link>
                            )}
                          {(ele?.is_deleted ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <strong>{` in ${ele?.plan_name}`}</strong>
                          )}
                        </h3>
                      )}

                      {/* <strong
                            onClick={() =>
                              navigateToItem(ele?.venue_type, ele?.venue_id)
                            }
                          >
                            {ele?.venue_name}{" "}
                          </strong> */}

                      {ele?.activity_type === "Collection" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>
                          {` created a collection for `}
                          <Link
                            to={`/collection-items/${ele?.collection_id}`}
                            style={{ color: "black" }}
                          >
                            <strong>{` ${ele?.collection_name}`}</strong>
                          </Link>
                        </h3>
                      )}

                      {ele?.activity_type === "Collection_item" && (
                        <h3>
                          <strong onClick={() => navigateToProfile(ele)}>
                            {ele?.user_name}
                          </strong>
                          {` added `}
                          <strong
                            onClick={() =>
                              navigateToItem(
                                ele?.item?.venue_type,
                                ele?.item?.venue_id
                              )
                            }
                          >
                            {ele?.collection_item_name}
                          </strong>
                          {` to `}
                          <Link
                            to={`/collection-items/${ele?.collection_id}`}
                            style={{ color: "black" }}
                          >
                            <strong>{` ${ele?.collection_name}`}</strong>
                          </Link>
                        </h3>
                      )}

                      <UserTime>{moment(ele?.createdAt).fromNow()}</UserTime>

                      {
                        <UserListInfoImage>
                          {((ele?.cover_photo &&
                            ele?.activity_type === "Collection_item") ||
                            (ele?.cover_photo &&
                              ele?.activity_type === "Venue") ||
                            (ele?.cover_photo &&
                              ele?.activity_type === "Copied") ||
                            (ele?.cover_photo &&
                              ele?.activity_type === "Moved") ||
                            (ele?.collection_photo &&
                              ele?.activity_type === "Collection")) && (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (ele?.activity_type === "Collection_item") {
                                  navigateToItem(
                                    ele?.item?.venue_type,
                                    ele?.item?.venue_id
                                  );
                                } else if (
                                  ele?.activity_type === "Collection"
                                ) {
                                  navigate("/collection");
                                } else {
                                  navigateToItem(
                                    ele?.venue_type,
                                    ele?.venue_id
                                  );
                                }
                              }}
                              src={
                                ele?.activity_type === "Collection"
                                  ? ele?.collection_photo
                                  : ele?.cover_photo
                              }
                              alt="los-angeles"
                            />
                          )}
                          {((!ele?.cover_photo &&
                            ele?.activity_type === "Collection_item") ||
                            (!ele?.cover_photo &&
                              ele?.activity_type === "Venue") ||
                            (!ele?.cover_photo &&
                              ele?.activity_type === "Copied") ||
                            (!ele?.cover_photo &&
                              ele?.activity_type === "Moved") ||
                            (!ele?.collection_photo &&
                              ele?.activity_type === "Collection")) && (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (ele?.activity_type === "Collection_item") {
                                  navigateToItem(
                                    ele?.item?.venue_type,
                                    ele?.item?.venue_id
                                  );
                                } else {
                                  navigateToItem(
                                    ele?.venue_type,
                                    ele?.venue_id
                                  );
                                }
                              }}
                              src={LasVegas}
                              alt="los-angeles"
                            />
                          )}

                          {ele?.activity_type === "Plan" &&
                            !ele?.is_deleted &&
                            (!ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) ||
                              ele?.view_plan === "Public") && (
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`/viewplandetails/${ele?.plan_id}`);
                                }}
                                src={ele?.plan_image}
                                alt="los-angeles"
                              />
                            )}

                          {((ele?.activity_type === "Plan" &&
                            ele?.is_deleted) ||
                            (ele?.exit_plan?.includes(
                              localStorage.getItem("id")
                            ) &&
                              ele?.view_plan !== "Public")) && (
                            <img src={ele?.plan_image} alt="los-angeles" />
                          )}

                          {ele?.activity_type !== "Collection" &&
                            ele?.activity_type !== "Plan" &&
                            ele?.activity_type !== "Status" && (
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/20000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle cx="8" cy="8" r="8" fill="#fff" />
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      setOpenChatModel(true);
                                      groupPlanDetails();
                                      // setPlaceIdData(ele?.venue_id);
                                      shareChatImages(ele);
                                    }}
                                  >
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      if (
                                        ele?.activity_type === "Collection_item"
                                      ) {
                                        if (
                                          ele?.item?.venue_type ===
                                          "point of interest"
                                        ) {
                                          handleDetails(
                                            ele?.venue_type,
                                            ele?.venue_id
                                          );
                                          setPlansModal(true);
                                          setVenue("point of interest");
                                          setRentalItemId(ele?.item?.venue_id);
                                          setImage(
                                            ele?.activity_type !== "Collection"
                                              ? ele?.cover_photo
                                              : ele?.collection_photo
                                          );
                                        } else if (
                                          ele?.item?.venue_type === "hiking"
                                        ) {
                                          handleDetails(
                                            ele?.venue_type,
                                            ele?.venue_id
                                          );
                                          setPlansModal(true);
                                          setVenue("hiking");
                                          setRentalItemId(ele?.item?.venue_id);
                                          setImage(
                                            ele?.activity_type !== "Collection"
                                              ? ele?.cover_photo
                                              : ele?.collection_photo
                                          );
                                        } else if (
                                          ele?.item?.venue_type ===
                                          "food and drink"
                                        ) {
                                          handleDetails(
                                            ele?.venue_type,
                                            ele?.venue_id
                                          );
                                          setPlansModal(true);
                                          setVenue("food and drink");
                                          setRentalItemId(ele?.item?.venue_id);
                                          setImage(
                                            ele?.activity_type !== "Collection"
                                              ? ele?.cover_photo
                                              : ele?.collection_photo
                                          );
                                        } else {
                                          handleDetails(
                                            ele?.venue_type,
                                            ele?.venue_id
                                          );
                                          setPlansModal(true);
                                          setVenue(ele?.item.venue_type);
                                          setRentalItemId(ele?.item?.venue_id);
                                          setImage(
                                            ele?.activity_type !== "Collection"
                                              ? ele?.cover_photo
                                              : ele?.collection_photo
                                          );
                                        }
                                      } else {
                                        handleDetails(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        );
                                        setPlansModal(true);
                                        setRentalItemId(ele?.venue_id);
                                        setVenue(ele?.venue_type);
                                        setImage(
                                          ele?.activity_type !== "Collection"
                                            ? ele?.cover_photo
                                            : ele?.collection_photo
                                        );
                                      }
                                    }}
                                  >
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      if (
                                        ele?.activity_type === "Collection_item"
                                      ) {
                                        setRentalDetails({
                                          venue_id: ele?.item?.venue_id,
                                          name: ele?.item?.name,
                                          location: ele?.item?.location,
                                          province: ele?.item?.province,
                                          Image: ele?.item?.Image,
                                          venue_type: ele?.item?.venue_type,
                                        });
                                      } else {
                                        const location: any =
                                          ele?.item?.plus_code?.compound_code.split(
                                            " "
                                          );

                                        setRentalDetails({
                                          venue_id: ele?.venue_id,
                                          name: ele?.venue_name,
                                          location:
                                            location?.length > 0
                                              ? location[1].slice(0, -1)
                                              : "",
                                          province:
                                            location?.length > 0
                                              ? location[2].slice(0, -1)
                                              : "",
                                          Image: ele?.cover_photo,
                                          venue_type: ele?.venue_type,
                                        });
                                      }
                                      setCollectionModal(true);
                                    }}
                                  >
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      if (
                                        ele?.activity_type === "Collection_item"
                                      ) {
                                        // handleDetails(
                                        //   ele?.item?.venue_type,
                                        //   ele?.item?.venue_id
                                        // );
                                        if (
                                          ele?.item?.venue_type ===
                                          "point of interest"
                                        ) {
                                          setCreatePlanModalShow(true);
                                          setCreateData(ele?.item?.venue_id);
                                          setVenue("point of interest");
                                          setRentalItemId(ele?.item?.venue_id);
                                        } else if (
                                          ele?.item?.venue_type === "hiking"
                                        ) {
                                          setCreatePlanModalShow(true);
                                          setCreateData(ele?.item?.venue_id);
                                          setVenue("hiking");
                                          setRentalItemId(ele?.item?.venue_id);
                                        } else if (
                                          ele?.item?.venue_type ===
                                          "food and drink"
                                        ) {
                                          setCreatePlanModalShow(true);
                                          setCreateData(ele?.item?.venue_id);
                                          setVenue("food and drink");
                                          setRentalItemId(ele?.item?.venue_id);
                                        } else {
                                          setCreatePlanModalShow(true);
                                          setCreateData(ele?.item?.venue_id);
                                          setVenue(ele?.item?.venue_type);
                                          setRentalItemId(ele?.item?.venue_id);
                                        }
                                      } else {
                                        handleDetails(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        );
                                        setCreatePlanModalShow(true);
                                        setCreateData(ele?.venue_id);
                                        setVenue(ele?.venue_type);
                                        setRentalItemId(ele?.venue_id);
                                      }
                                      setAllId([]);
                                      setInviteFriend([]);
                                      setInviteFollower([]);
                                      setInviteEmails([]);
                                      setPendingEmail([]);
                                      setGroupMembers([]);
                                      setShowImage("");
                                      setImage("");
                                      setImageValidation("");
                                    }}
                                  >
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => navigateToShareWith(ele)}
                                  >
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                        </UserListInfoImage>
                      }
                    </UserListInfo>
                  </UserListItem>
                </>
              );
            })}
          </UserListWrap>
        )}
      </SearchUserPanel>

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setUploadImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />
      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        sharewithImage={sharewithImage}
        link={rentalLink}
        name={rentalName}
        address={rentalAddress}
        locationData={locationData}
      />
      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        placeIdData={placeIdData}
        groupImages={groupImages}
        dynamicLocation={dynamicLocation}
        name={name}
        allItems={allItems}
      />
      <AnotherDayModal
        show={timeDayModal}
        onHide={() => {
          setTimeDayModal(false);
          setPlansModal(false);
        }}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={venue}
        interestData={interestData}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <CopyPlanModal
        show={copyPlanModal}
        onHide={() => {
          setCopyPlanModal(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        setCopyPlanModal={setCopyPlanModal}
        getActivity={getActivity}
        planDetails={planDetails}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
      />
    </>
  );
};

export default LeftSearchPanel;
