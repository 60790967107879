import { deleteCollectionItemAPI } from "../../../services/deleteCollectionItem";

export enum deleteCollectionItemType {
  DELETE_COLLECTION_ITEM = "DELETE_COLLECTION_ITEM",
  DELETE_COLLECTION_ITEM_SUCCESS = "DELETE_COLLECTION_ITEM_SUCCESS",
  DELETE_COLLECTION_ITEM_FAILURE = "DELETE_COLLECTION_ITEM_FAILURE",
}

export const deleteCollectionItemInfo = () => (dispatch: any) => {
  dispatch({
    type: deleteCollectionItemType.DELETE_COLLECTION_ITEM,
  });
};

export const deleteCollectionItemInfoSucess =
  (data: any) => (dispatch: any) => {
    dispatch({
      type: deleteCollectionItemType.DELETE_COLLECTION_ITEM_SUCCESS,
      data,
    });
  };

export const deleteCollectionItemInfoFailure =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: deleteCollectionItemType.DELETE_COLLECTION_ITEM_FAILURE,
      data: { msg },
    });
  };

export const deleteCollectionItemHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(deleteCollectionItemInfo());
    const result: any = await deleteCollectionItemAPI(data);
    dispatch(deleteCollectionItemInfoSucess(result));
  } catch (e: any) {
    dispatch(deleteCollectionItemInfoFailure(e?.response?.data?.message));
  }
};
