import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import { GlobalDataType } from "../../../redux/action/globalData";
import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CandyJohns from "../../../assets/images/candy-johns.png";
import FollowerCandy from "../../../assets/images/follower-one.png";
import FollowerDiana from "../../../assets/images/follower-two.png";
import FollowerDaniel from "../../../assets/images/follower-three.png";
import FollowerJoseph from "../../../assets/images/follower-four.png";
import FollowerJeremy from "../../../assets/images/follower-five.png";

import {
  MyPageWrap,
  MainContentPanel,
  FormGroup,
  TabsWrap,
  TabsContent,
  ModalBody,
  ModalBodyBtn,
  ModalMemberGroupList,
  ModalMemberGroupItem,
  InviteGroupRadioBox,
  SocialProfileWrap,
  SocialProfileTop,
  UserProfileImage,
  SocialProfileInfo,
  SocialUserName,
  AboutUser,
  UserEmail,
  UserAction,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
} from "./userFollowersStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import { ProfileImage } from "../myProfile/myProfileStyle";
import { getFollowersAPIData } from "../../../redux/action/getfollowers/getFollowers";
import { getFollowingInfoData } from "../../../redux/action/getfollowing/getFollowing";
import { getAllFriendData } from "../../../redux/action/getallfriends/getAllFriends";

const UserFollowers: any = (props: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const SideSearchUserPanel = () => {
    const panelBtn = document.querySelector(".searchUserPanel");
    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };

  const SideTabbingPanel = () => {
    const panelBtn = document.querySelector(".tabbingPanel");
    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };

  const loginUserId: any = useSelector((state: any) => state?.loginData);

  const loginId: any = loginUserId?.data?.id;

  const getFollowerList = useSelector((state: any) => state?.getFollowers);

  const getAllFriendsList = useSelector((state: any) => state?.getAllFriends);

  const getFollowingList = useSelector((state: any) => state?.getFollowing);

  useEffect(() => {
    dispatch(getFollowersAPIData(loginId));
    dispatch(getFollowingInfoData(loginId));
    dispatch(getAllFriendData(loginId));
  }, [loginId]);

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <SocialProfileWrap>
            <SocialProfileTop>
              <UserProfileImage>
                <img src={CandyJohns} alt="Michelle Smith" />
              </UserProfileImage>
              <SocialProfileInfo>
                <SocialUserName>@Michelle Smith</SocialUserName>
                <ul>
                  <li>
                    <Button type="button">
                      <span className="profile-number">2,415</span>
                      <label>Followers</label>
                    </Button>
                  </li>
                  <li>
                    <Button type="button">
                      <span className="profile-number">790</span>
                      <label>Following</label>
                    </Button>
                  </li>
                  <li>
                    <Button type="button">
                      <span className="profile-number">1100</span>
                      <label>Friends</label>
                    </Button>
                  </li>
                </ul>
                <AboutUser>
                  Cali girl who loves to explore new places and try new food.
                </AboutUser>
                <UserEmail>michellesmith@.com</UserEmail>
              </SocialProfileInfo>
              <UserAction>
                <Button className="btn-border">Following</Button>
                <Button className="btn-border">Add Friend</Button>
              </UserAction>
            </SocialProfileTop>

            <SocialTabsWrap>
              <TabsWrap>
                <Tabs defaultActiveKey="followers">
                  <Tab eventKey="followers" title="Followers">
                    <TabsContent>
                      <SocialTabInner>
                        <Form>
                          <FormGroup>
                            <Form.Control
                              type="text"
                              placeholder="Search User"
                            />
                            <button type="submit" className="searchIconBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21.921"
                                height="22.01"
                                viewBox="0 0 21.921 22.01"
                              >
                                <path
                                  d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                  transform="translate(-3.002 -3.001)"
                                  fill="#9597a5"
                                />
                              </svg>
                            </button>
                          </FormGroup>
                        </Form>
                        <FollowList>
                          <ul>
                            {getFollowerList?.data?.data[0]?.follower?.map(
                              (item: any, i: number) => {
                                return (
                                  <li>
                                    <FollowListLeft>
                                      <FollowImage>
                                        <img
                                          src={FollowerDiana}
                                          alt="Diana Ray"
                                        />
                                      </FollowImage>
                                      <FollowName> {item}</FollowName>
                                    </FollowListLeft>
                                    <Button className="btn-border">
                                      Remove
                                    </Button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </FollowList>
                      </SocialTabInner>
                    </TabsContent>
                  </Tab>
                  <Tab eventKey="following" title="Following">
                    <TabsContent>
                      <SocialTabInner>
                        <Form>
                          <FormGroup>
                            <Form.Control
                              type="text"
                              placeholder="Search User"
                            />
                            <button type="submit" className="searchIconBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21.921"
                                height="22.01"
                                viewBox="0 0 21.921 22.01"
                              >
                                <path
                                  d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                  transform="translate(-3.002 -3.001)"
                                  fill="#9597a5"
                                />
                              </svg>
                            </button>
                          </FormGroup>
                        </Form>
                        <FollowList>
                          <ul>
                            {getAllFriendsList?.data?.data[0]?.friends.map(
                              (item: any, i: number) => {
                                return (
                                  <li>
                                    <FollowListLeft>
                                      <FollowImage>
                                        <img
                                          src={FollowerDiana}
                                          alt="Diana Ray"
                                        />
                                      </FollowImage>
                                      <FollowName> {item}</FollowName>
                                    </FollowListLeft>
                                    <Button className="btn-border-color">
                                      Following
                                    </Button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </FollowList>
                      </SocialTabInner>
                    </TabsContent>
                  </Tab>
                  <Tab eventKey="friends" title="Friends">
                    <TabsContent>
                      <SocialTabInner>
                        <Form>
                          <FormGroup>
                            <Form.Control
                              type="text"
                              placeholder="Search User"
                            />
                            <button type="submit" className="searchIconBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21.921"
                                height="22.01"
                                viewBox="0 0 21.921 22.01"
                              >
                                <path
                                  d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                  transform="translate(-3.002 -3.001)"
                                  fill="#9597a5"
                                />
                              </svg>
                            </button>
                          </FormGroup>
                        </Form>
                        <FollowList>
                          <ul>
                            {getAllFriendsList?.data?.data[0]?.friends.map(
                              (item: any, i: number) => {
                                return (
                                  <li>
                                    <FollowListLeft>
                                      <FollowImage>
                                        <img
                                          src={FollowerDiana}
                                          alt="Diana Ray"
                                        />
                                      </FollowImage>
                                      <FollowName> {item}</FollowName>
                                    </FollowListLeft>
                                    <Button className="btn-border">
                                      Remove
                                    </Button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </FollowList>
                      </SocialTabInner>
                    </TabsContent>
                  </Tab>
                </Tabs>
              </TabsWrap>
            </SocialTabsWrap>
          </SocialProfileWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
    </>
  );
};

export default UserFollowers;
