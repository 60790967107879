import { getAllConcertEvents } from "./../../../services/getAllEvent";

export enum getAllEvents {
  GET_ALL_EVENT = "GET_ALL_EVENT",
  GET_ALL_EVENT_SUCCESS = "GET_ALL_EVENT_SUCCESS",
  GET_ALL_EVENT_FALIURE = "GET_ALL_EVENT_FALIURE",
}

export const getAllEvent = () => (dispatch: any) => {
  dispatch({
    type: getAllEvents.GET_ALL_EVENT,
  });
};

export const getAllEventSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getAllEvents.GET_ALL_EVENT_SUCCESS,
    data,
  });
};

export const getAllEventFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getAllEvents.GET_ALL_EVENT_FALIURE,
    error: msg,
  });
};
export const getAllEventHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getAllEvent());
    const result: any = await getAllConcertEvents(data);
    dispatch(getAllEventSuccess(result));
  } catch (e: any) {
    dispatch(getAllEventFailed(e?.response?.data?.message));
  }
};
