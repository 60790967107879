import { invitePlanAPI } from "../../../services/invitePlan";

export enum invitePlanType {
  INVITE_PLAN_INFO = "INVITE_PLAN_INFO",
  INVITE_PLAN_INFO_SUCCESS = "INVITE_PLAN_INFO_SUCCESS",
  INVITE_PLAN_INFO_FAILURE = "INVITE_PLAN_INFO_FAILURE",
}

export const invitePlan = () => (dispatch: any) => {
  dispatch({
    type: invitePlanType.INVITE_PLAN_INFO,
  });
};

export const invitePlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: invitePlanType.INVITE_PLAN_INFO_SUCCESS,
    data,
  });
};

export const invitePlanFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: invitePlanType.INVITE_PLAN_INFO_FAILURE,
    error: msg,
  });
};

export const invitePlanHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(invitePlan());
    const result: any = await invitePlanAPI(data);
    dispatch(invitePlanSuccess(result));
  } catch (e: any) {
    dispatch(invitePlanFailure(e?.response?.data));
  }
};
