import { privacyPolicyType } from "./../../action/privacy-policy/privacyPolicy";
import { privacyPolicyModal } from "../../models/index";

export const initState: privacyPolicyModal = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case privacyPolicyType.PRIVACY_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case privacyPolicyType.PRIVACY_INFO_SUCCESS: {
      return {
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case privacyPolicyType.PRIVACY_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
        data: null,
      };
    }

    default:
      return state;
  }
};
