import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ModalBody, ModalBodyBtn } from "../shareWithModal/shareWithModalStyle";

const WelcomeModal = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="welcome-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .welcome-modal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 418px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .welcome-modal .modal-content {
              background: #FFFFFF;
              border-radius: 18px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Body>
            <h2>Welcome to Tripd</h2>
            <p>Start planning stuff to do with your friends </p>
            <p>
              You can create plans by adding which friends you want to do stuff
              with or you can find stuff and then invite your friends to it.
              Save specific events, places, activities, hotels, and rentals, If
              your friends sign up with facebook login you can search for them
            </p>
            <p>
              Name it like "Michael's birthday" or Jenny's Bachelorette party or
              Lake Tahoe Trip
            </p>

            <ModalBodyBtn>
              <Button onClick={props.onHide} className="btn">
                Next
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WelcomeModal;
