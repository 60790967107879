import { getAllGroupAPI } from "../../../services/getAllGroup";

export enum getAllGroupType {
  GET_GROUP = "GET_GROUP_INFO",
  GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS",
  GET_GROUP_FALIURE = "GET_GROUP_FALIURE",
  GET_GROUP_RESET = "RESET_GROUP",
}

export const getGroupInfo = () => (dispatch: any) => {
  dispatch({
    type: getAllGroupType.GET_GROUP,
  });
};

export const getGroupSucess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getAllGroupType.GET_GROUP_SUCCESS,
    data,
  });
};

export const getGroupFaiure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getAllGroupType.GET_GROUP_FALIURE,
    error: { msg },
  });
};

export const getGroupData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getGroupInfo());
    const result: any = await getAllGroupAPI(data);
    dispatch(getGroupSucess(result));
  } catch (e: any) {
    dispatch(getGroupFaiure(e?.response?.data));
  }
};
