import { removeFriendAPI } from "../../../services/remove-friend";

export enum RemoveFriendType {
  REMOVE_FRIEND = "REMOVE_FRIEND",
  REMOVE_FRIEND_SUCCESS = "REMOVE_FRIEND_SUCCESS",
  REMOVE_FRIEND_FALIURE = "REMOVE_FRIEND_FALIURE",
}

export const removeFriend = () => (dispatch: any) => {
  dispatch({
    type: RemoveFriendType.REMOVE_FRIEND,
  });
};

export const removeFriendSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: RemoveFriendType.REMOVE_FRIEND_SUCCESS,
    data,
  });
};

export const removeFriendFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: RemoveFriendType.REMOVE_FRIEND_FALIURE,
    data: { msg },
  });
};

export const removeFriendData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(removeFriend());
    const result: any = await removeFriendAPI(data);
    dispatch(removeFriendSuccess(result));
  } catch (e: any) {
    dispatch(removeFriendFailed(e.message));
  }
};
