import { planDetailsAPI } from "../../../services/planDetails";

export enum planDetailsType {
  PLAN_DETAILS = "PLAN_DETAILS",
  PLAN_DETAILS_SUCCESS = "PLAN_DETAILS_SUCCESS",
  PLAN_DETAILS_FAILURE = "PLAN_DETAILS_FAILURE",
}

export const planDetailsInfo = () => (dispatch: any) => {
  dispatch({
    type: planDetailsType.PLAN_DETAILS,
  });
};

export const planDetailsInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: planDetailsType.PLAN_DETAILS_SUCCESS,
    data,
  });
};

export const planDetailsInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: planDetailsType.PLAN_DETAILS_FAILURE,
    data: msg,
  });
};

export const planDetailsHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(planDetailsInfo());
    const result: any = await planDetailsAPI(data);
    dispatch(planDetailsInfoSuccess(result));
  } catch (e: any) {
    dispatch(planDetailsInfoFailed(e?.response?.data?.message));
  }
};
