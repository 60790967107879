import { RemoveFriendMyProfileType } from "../../action/remove-Friend-MyProfile/removeFriendMyProfile";
import { removeFriendMyProfileModal } from "../../models/index";

export const initState: removeFriendMyProfileModal = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
