export enum GlobalDataType {
  GLOBAL_DATA = "GLOBAL_DATA",
  DELETE_DATA = "DELETE_DATA",
}

export const signInInfo = () => (dispatch: any) => {
  dispatch({
    type: GlobalDataType.GLOBAL_DATA,
  });
};
