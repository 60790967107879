import { removeFriendMyProfileAPI } from "../../../services/removeFriendMyProfile";

export enum RemoveFriendMyProfileType {
  REMOVE_FRIEND_MYPROFILE_INFO = "REMOVE_FRIEND_MYPROFILE_INFO",
  REMOVE_FRIEND_MYPROFILE_INFO_SUCCESS = "REMOVE_FRIEND_MYPROFILE_INFO_SUCCESS",
  REMOVE_FRIEND_MYPROFILE_INFO_FALIURE = "REMOVE_FRIEND_MYPROFILE_INFO_FALIURE",
}

export const removeFriendMyProfileInfo = () => (dispatch: any) => {
  dispatch({
    type: RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO,
  });
};

export const removeFriendMyProfileInfoSuccess =
  (data: any) => (dispatch: any) => {
    dispatch({
      type: RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO_SUCCESS,
      data,
    });
  };

export const removeFriendMyProfileInfoFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: RemoveFriendMyProfileType.REMOVE_FRIEND_MYPROFILE_INFO_FALIURE,
      data: { msg },
    });
  };

export const removeFriendMyProfileData =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(removeFriendMyProfileInfo());
      const result: any = await removeFriendMyProfileAPI(data);
      dispatch(removeFriendMyProfileInfoSuccess(result));
    } catch (e: any) {
      dispatch(removeFriendMyProfileInfoFailed(e.message));
    }
  };
