import { exitGroupAPI } from "../../../services/exit-group";

export enum ExitGroupeType {
  EXIT_GROUP_INFO = "EXIT_GROUP_INFO",
  EXIT_GROUP_INFO_SUCCESS = "EXIT_GROUP_INFO_SUCCESS",
  EXIT_GROUP_INFO_FALIURE = "EXIT_GROUP_INFO_FALIURE",
}

export const exitGroupInfo = () => (dispatch: any) => {
  dispatch({
    type: ExitGroupeType.EXIT_GROUP_INFO,
  });
};

export const exitGroupInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ExitGroupeType.EXIT_GROUP_INFO_SUCCESS,
    data,
  });
};

export const exitGroupInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ExitGroupeType.EXIT_GROUP_INFO_FALIURE,
    data: { msg },
  });
};

export const exitGroupInfoData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(exitGroupInfo());
    const result: any = await exitGroupAPI(finalData);
    dispatch(exitGroupInfoSuccess(result));
  } catch (e: any) {
    dispatch(exitGroupInfoFailed(e.response.data.message));
  }
};
