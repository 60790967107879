import { privacyPolicyAPI } from "./../../../services/privacyPolicy";

export enum privacyPolicyType {
  PRIVACY_INFO = "PRIVACY_INFO",
  PRIVACY_INFO_SUCCESS = "PRIVACY_SUCCESS",
  PRIVACY_INFO_FALIURE = "PRIVACY_INFO_FALIURE",
}

export const privacyPolicyInfo = () => (dispatch: any) => {
  dispatch({
    type: privacyPolicyType.PRIVACY_INFO,
  });
};

export const privacyPolicyInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: privacyPolicyType.PRIVACY_INFO_SUCCESS,
    data: data,
  });
};

export const privacyPolicyInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: privacyPolicyType.PRIVACY_INFO_FALIURE,
    data: { msg },
  });
};

export const privacyPolicyData = () => async (dispatch: any) => {
  try {
    dispatch(privacyPolicyInfo());
    const result = await privacyPolicyAPI();

    dispatch(privacyPolicyInfoSuccess(result));
  } catch (e: any) {
    dispatch(privacyPolicyInfoFailed(e?.response?.data?.message));
  }
};
