import { searchFriendAPI } from "../../../services/searchFriends";

export enum SearchFiendsType {
  SEARCH_FRIENDS_INFO = "SEARCH_FRIENDS_INFO",
  SEARCH_FRIENDS_INFO_SUCCESS = "SEARCH_FRIENDS_INFO_SUCCESS",
  SEARCH_FRIENDS_INFO_FALIURE = "SEARCH_FRIENDS_INFO_FALIURE",
}

export const SearchFrindsInfo = () => (dispatch: any) => {
  dispatch({
    type: SearchFiendsType.SEARCH_FRIENDS_INFO,
  });
};

export const SearchFrindsInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SearchFiendsType.SEARCH_FRIENDS_INFO_SUCCESS,
    data,
  });
};

export const SearchFrindsInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SearchFiendsType.SEARCH_FRIENDS_INFO_FALIURE,
    data: { msg },
  });
};

export const searchFriendsAPIData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(SearchFrindsInfo());
    const result: any = await searchFriendAPI(data);
    dispatch(SearchFrindsInfoSuccess(result));
  } catch (e: any) {
    dispatch(SearchFrindsInfoFailed(e.message));
  }
};
