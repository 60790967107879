import { DynamicChatType } from "../../action/dynamic-chat/dynamicChat";
import { dynamicChatModal } from "../../models/index";

export const initState: dynamicChatModal = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case DynamicChatType.DYNAMIC_CHAT_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case DynamicChatType.DYNAMIC_CHAT_INFO_SUCCESS: {
      return {
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case DynamicChatType.DYNAMIC_CHAT_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
        data: null,
      };
    }

    case "RESET_DYNAMIC_CHAT": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }

    default:
      return state;
  }
};
