import { searchUserModel } from "../../models/index";
import { SearchUserType } from "../../action/search-user/searchUser";

export const initState: searchUserModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case SearchUserType.SEARCH_USER_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SearchUserType.SEARCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case SearchUserType.SEARCH_USER_INFO_FALIURE: {
      return {
        ...state,
        error: action.data.msg,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
