import { manageDayPlanAPI } from "../../../services/manageDayPlan";

export enum manageDayPlanType {
  MANAGE_DAY_PLAN_INFO = "MANAGE_DAY_PLAN_INFO",
  MANAGE_DAY_PLAN_INFO_SUCCESS = "MANAGE_DAY_PLAN_INFO_SUCCESS",
  MANAGE_DAY_PLAN_INFO_FALIURE = "MANAGE_DAY_PLAN_INFO_FALIURE",
}

export const manageDayPlanInfo = () => (dispatch: any) => {
  dispatch({
    type: manageDayPlanType.MANAGE_DAY_PLAN_INFO,
  });
};

export const manageDayPlanDetailsSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: manageDayPlanType.MANAGE_DAY_PLAN_INFO_SUCCESS,
    data,
  });
};

export const manageDayPlanDetailsFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: manageDayPlanType.MANAGE_DAY_PLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const manageDayPlanDetailsHandler =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(manageDayPlanInfo());
      const result: any = await manageDayPlanAPI(finalData);
      dispatch(manageDayPlanDetailsSuccess(result));
    } catch (e: any) {
      dispatch(manageDayPlanDetailsFailure(e?.response?.data?.message));
    }
  };
