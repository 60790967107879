import { rejectUserAPI } from "../../../services/reject-user";

export enum RejectUserType {
  REJECTUSER_INFO = "REJECTUSER_INFO",
  REJECTUSER_INFO_SUCCESS = "REJECTUSER_INFO_SUCCESS",
  REJECTUSER_INFO_FALIURE = "REJECTUSER_INFO_FALIURE",
}

export const rejectUserInfo = () => (dispatch: any) => {
  dispatch({
    type: RejectUserType.REJECTUSER_INFO,
  });
};

export const rejectUserInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: RejectUserType.REJECTUSER_INFO_SUCCESS,
    data,
  });
};

export const rejectUserInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: RejectUserType.REJECTUSER_INFO_FALIURE,
    data: { msg },
  });
};

export const rejectData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(rejectUserInfo());
    const result: any = await rejectUserAPI(id);
    dispatch(rejectUserInfoSuccess(result));
  } catch (e: any) {
    dispatch(rejectUserInfoFailed(e.message));
  }
};
