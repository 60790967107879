import { GetCollectionItemType } from "../../action/get-collection-item/getCollectionItem";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case GetCollectionItemType.GET_COLLECTION_ITEMS: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case GetCollectionItemType.GET_COLLECTION_ITEMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case GetCollectionItemType.GET_COLLECTION_ITEMS_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }
    default:
      return state;
  }
};
