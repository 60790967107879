import { getAllFollowingCollectionAPI } from "../../../services/getCollectionFollowing";

export enum getCollectionFollowingType {
  GET_COLLECTION_FOLLOWING = "GET_COLLECTION_FOLLOWING",
  GET_COLLECTION_FOLLOWING_SUCCESS = "GET_COLLECTION_FOLLOWING_SUCCESS",
  GET_COLLECTION_FOLLOWING_FALIURE = "GET_COLLECTION_FOLLOWING_FALIURE",
}

export const getCollectionFollowing = () => (dispatch: any) => {
  dispatch({
    type: getCollectionFollowingType.GET_COLLECTION_FOLLOWING,
  });
};

export const getCollectionFollowingSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getCollectionFollowingType.GET_COLLECTION_FOLLOWING_SUCCESS,
    data,
  });
};

export const getCollectionFollowingFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getCollectionFollowingType.GET_COLLECTION_FOLLOWING_FALIURE,
    error: msg,
  });
};

export const getCollectionFollowingHandler = () => async (dispatch: any) => {
  try {
    dispatch(getCollectionFollowing());
    const result: any = await getAllFollowingCollectionAPI();
    dispatch(getCollectionFollowingSuccess(result));
  } catch (e: any) {
    dispatch(getCollectionFollowingFailed(e.response.data.error));
  }
};
