import styled from "styled-components";

export const MyPageWrap = styled.div`
  height: 100vh;
  padding: 30px 15px;
  background: #ffffff;
  font-family: "Poppins";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  .btn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-radius: 8px;
    background: #4d27da;
    padding: 7px 15px;
    margin-top: 20px;
    min-width: 131px;
    border: none;
    font-family: "Poppins";
    outline: none;
    box-shadow: none !important;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #3b3b3b;
    }
  }
`;

export const NotFoundImage = styled.div`
  max-width: 100%;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    width: 604px;
    vertical-align: middle;
    @media screen and (max-device-height: 480px) and (orientation: landscape) {
      width: 300px;
    }
    @media (max-width:480px){
      width: auto;
    }
  }
`;

export const NotFoundText = styled.h4`
  margin: 0;
  max-width: 335px;
  text-align: center;
  font-family: Poppins;
  color: #3b3b3b;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  @media (max-width: 1500px) {
    font-size: 13px;
    line-height: 18px;
  }
`;
