import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import Nevada from "../../../assets/images/nevada.png";
import HotelFufu from "../../../assets/images/hotel-fufu.png";
import TacoRestaurant from "../../../assets/images/taco-restaurant.png";
import InterestName from "../../../assets/images/interest-name.png";
import SuperTaco from "../../../assets/images/super-taco.png";
import GreatCabin from "../../../assets/images/great-cabin.png";
import Caba from "../../../assets/images/caba.png";
import La from "../../../assets/images/la.png";
import FileIcon from "../../../assets/images/file-icon.svg";

import AddCollectionModal from "../../../components/addCollectionModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import RemoveFriendModal from "../../../components/removeFriendModal/index";
import RemoveFollowigModal from "../../../components/removeFollowingModal/index";
import VerifiedIcon from "@mui/icons-material/Verified";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsWrap,
  TabsContent,
  MainContentPanelWrap,
  ProfileWrap,
  ProfileLeft,
  ProfileImage,
  ProfileName,
  ProfileUserName,
  ProfileRight,
  FormGroupWrap,
  MyPlansWrap,
  MyPlansTop,
  MyPlansTitle,
  CreateBtn,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansDate,
  MyPlansLock,
  MyPlansAction,
  MyPlansSubTitle,
  MyPlansActionWrap,
  MyPlansDropdown,
  CityTitle,
  BtnWrap,
  MyPlansMore,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
  PageLoader,
  TabDataNotFound,
  SearchListLoader,
  SearchBoxNote,
} from "./myProfileStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import ResetPassword from "../auth/ResetPassword";
import { myProfileData } from "../../../redux/action/my-profile/myProfile";
import { changePasswordData } from "../../../redux/action/changepassword/changePassword";
import { getProfileData } from "../../../redux/action/get-profile/getProfile";
import { getFollowersAPIData } from "../../../redux/action/getfollowers/getFollowers";
import { getFollowingInfoData } from "../../../redux/action/getfollowing/getFollowing";
import { getAllFriendData } from "../../../redux/action/getallfriends/getAllFriends";
import { searchFollowData } from "../../../redux/action/search-followers/searchFollowers";
import { searchFollowingData } from "../../../redux/action/search-following/searchFollowing";
import { searchFriendsAPIData } from "../../../redux/action/search-friends/searchFriend";
import { unfollowData } from "../../../redux/action/unfollow/unFollow";
import { getPlanDetailsHandler } from "../../../redux/action/myPlan/myPlan";
import UnfollowModal from "../../../components/unfollowModal/index";
import RemoveModal from "../../../components/removeModal/index";
import RemoveFriendsMyProfileModal from "../../../components/removeFriendMyProfile/index";

const MyProfile = (props: any) => {
  const [getFollow, setFollow] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFollowingTerm, setSearchFollowingTerm] = useState("");
  const [searchFriendTerm, setSearchFriendTerm] = useState("");
  const [showProfilePage, setShowProfilePage] = useState(false);
  // const [getRemove, setRemove] = useState(false);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const getFollowerList = useSelector(
    (state: any) => state?.getFollowers?.data?.data
  );

  const getFollowingList = useSelector(
    (state: any) => state?.getFollowing?.data?.data
  );

  const getFriendList = useSelector(
    (state: any) => state?.getAllFriends?.data?.data
  );

  const searchForFollwerSection = useSelector(
    (state: any) => state?.searchFollowers
  );
  const searchForFollowingSection = useSelector(
    (state: any) => state?.searchFollowing
  );

  const searchForFriendSection = useSelector(
    (state: any) => state?.searchFriends
  );

  const submitForm = (values: any) => {
    console.log("ssss", values);
  };

  const [AddCollectionModalShow, setAddCollectionModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = useState(false);
  const [removeFriendModalShow, setRemoveFriendModalShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [removeFollowinModalShow, setRemoveFollowingModalShow] =
    useState(false);
  const [removeFollowigId, setRemoveFollowingId] = useState("");
  const [removeFriendsId, setRemoveFriendsId] = useState("");
  const [removeId, setRemoveId] = useState("");

  const myProfileDataSelect: any = useSelector(
    (state: any) => state?.myProfile
  );

  const getMyProfile: any = useSelector((state: any) => state?.getProfile);
  const removeFriend: any = useSelector((state: any) => state?.removeFriend);
  const removeFriendMyProfileHandler: any = useSelector(
    (state: any) => state?.removeFriendMyProfile
  );
  const unFollowHandler: any = useSelector((state: any) => state?.unFollow);
  const getMyPlan: any = useSelector((state: any) => state?.myPlan);

  const setLoginId = localStorage.getItem("id");

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  const myPlans = useRef(null);
  const myPlansScroll = () => scrollToRef(myPlans);

  const collection = useRef(null);
  const collectionScroll = () => scrollToRef(collection);

  const navigateToSettings = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // My Profile follwer,following and friends page
  useEffect(() => {
    dispatch(getProfileData());
    dispatch(myProfileData(setLoginId));
    dispatch(getFollowersAPIData(setLoginId));
    dispatch(getFollowingInfoData(setLoginId));
    dispatch(getAllFriendData(setLoginId));
  }, [removeFriend, removeFriendMyProfileHandler, unFollowHandler]);

  // Search functionality in following
  const searchFollowing = (event: any) => {
    setSearchFollowingTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };
    if (event.trim() !== "" && getFollow === "following") {
      dispatch(searchFollowingData(finalValue));
    }
  };

  // Search functionality in friend

  const searchFriend = (event: any) => {
    setSearchFriendTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (event.trim() !== "" && getFollow === "friends") {
      dispatch(searchFriendsAPIData(finalValue));
    }
  };

  // Search functionality in follower
  const searchTextData = (event: any) => {
    setSearchTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (event.trim() !== "" && getFollow === "followers") {
      dispatch(searchFollowData(finalValue));
    }
  };

  const navigateToMyProfile = () => {
    setShowProfilePage(false);
    navigate("/myprofile");
  };

  const removeFriendHandler = (friendId: any) => {
    setRemoveFriendsId(friendId);
    setRemoveFriendModalShow(true);
  };

  // Remove Functionality in Followers Listing
  const removeHandler = (itemId: any) => {
    setRemoveId(itemId);
    setRemoveModalShow(true);
  };

  // Unfollow Functionality in Following Listing

  const followingHandler = (unFollowId: any) => {
    setRemoveFollowingId(unFollowId);
    setRemoveFollowingModalShow(true);
  };

  const loader = useSelector((state: any) => state?.removeFriendMyProfile);

  // function myFunction() {
  //   const element = document.getElementById("content");
  //   element.scrollIntoView();
  // }

  const userNameHandler = (e: any) => {
    const re = /^[a-zA-Z0-9-_.]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const navigateToUserProfile = (item: any) => {
    navigate(`/socialprofile/${item?.follower_id}`);
  };
  const navigateFollowing = (item: any) => {
    navigate(`/socialprofile/${item?.following_id}`);
  };

  const navigateFriend = (item: any) => {
    navigate(`/socialprofile/${item?.friends_id}`);
  };

  return (
    <>
      <Header logout={props.logout} />

      {getMyProfile?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile" className="active">
                MY PROFILE
              </Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>

            <TabsContent>
              <ProfileWrap>
                <ProfileLeft>
                  <ProfileImage>
                    <img
                      src={getMyProfile?.data?.data?.photo}
                      alt={getMyProfile?.data?.data?.firstName}
                    />
                  </ProfileImage>

                  <ProfileName>
                    {getMyProfile?.data?.data?.firstName}{" "}
                    {getMyProfile?.data?.data?.lastName}
                    {getMyProfile?.data?.data?.batch ? (
                      <VerifiedIcon
                        style={{ color: "#458eff", paddingLeft: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                  </ProfileName>

                  <ProfileUserName>
                    @{getMyProfile?.data?.data?.userName}
                  </ProfileUserName>

                  <ProfileUserName className="userEmail">
                    <a href={`mailto:${getMyProfile?.data?.data?.email}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.593"
                        height="10.934"
                        viewBox="0 0 14.593 10.934"
                      >
                        <g>
                          <g clip-path="url(#clip-path)">
                            <path
                              d="M14.593,80.7v6.7c-.005.008-.014.015-.016.023a1.35,1.35,0,0,1-1.46,1.14H1.475c-.047,0-.1,0-.142,0A1.342,1.342,0,0,1,0,87.187Q0,84,0,80.8A1.064,1.064,0,0,1,.012,80.7c.026.014.039.02.05.028l5.912,3.942a2.2,2.2,0,0,0,2.644,0L13.9,81.144c.228-.152.46-.3.69-.449"
                              transform="translate(0 -77.622)"
                              fill="#3b3b3b"
                            />
                            <path
                              d="M14.613,1.534a1.936,1.936,0,0,1-.169.432,1.71,1.71,0,0,1-.421.453q-2.972,2-5.957,3.976a1.274,1.274,0,0,1-1.5,0Q3.575,4.41.594,2.413A1.268,1.268,0,0,1,.071.99,1.344,1.344,0,0,1,1.31,0c.043,0,.085,0,.128,0H13.194a1.352,1.352,0,0,1,1.348.92c.027.08.047.162.071.243Z"
                              transform="translate(-0.02 0)"
                              fill="#3b3b3b"
                            />
                          </g>
                        </g>
                      </svg>
                      {getMyProfile?.data?.data?.email}
                    </a>
                  </ProfileUserName>

                  {getMyProfile?.data?.data?.website_url ? (
                    <>
                      <ProfileUserName className="userURL">
                        <a
                          href={`${
                            getMyProfile?.data?.data?.website_url.startsWith(
                              "http://"
                            ) ||
                            getMyProfile?.data?.data?.website_url.startsWith(
                              "https://"
                            )
                              ? getMyProfile?.data?.data?.website_url
                              : "http://" +
                                getMyProfile?.data?.data?.website_url
                          }`}
                          target="_blank"

                          // onClick={() =>
                          //   window.open(
                          //     `${
                          //       getMyProfile?.data?.data?.website_url.startsWith(
                          //         "http://"
                          //       ) ||
                          //       getMyProfile?.data?.data?.website_url.startsWith(
                          //         "https://"
                          //       )
                          //         ? getMyProfile?.data?.data?.website_url
                          //         : "http://" +
                          //           getMyProfile?.data?.data?.website_url
                          //     }`,

                          //     "_blank"
                          //   )
                          // }

                          // href={`../../${getMyProfile?.data?.data?.website_url}`}
                          // rel="noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.46"
                            height="13.441"
                            viewBox="0 0 14.46 13.441"
                          >
                            <g transform="translate(-22026.27 10672.721)">
                              <g transform="translate(22026.27 -10672.721)">
                                <g
                                  transform="translate(0 0)"
                                  clip-path="url(#clip-path)"
                                >
                                  <path
                                    d="M19.69,3.967c-.032.167-.058.335-.1.5A3.5,3.5,0,0,1,18.584,6.2Q17.45,7.283,16.312,8.36c-.022.021-.047.039-.1.085.017-.114.028-.2.04-.279a4.384,4.384,0,0,0-.17-2.065.26.26,0,0,1,.081-.306c.345-.323.688-.648,1.024-.979a1.6,1.6,0,0,0,.449-1.782A1.753,1.753,0,0,0,16.15,1.853a1.806,1.806,0,0,0-1.569.458c-.866.811-1.719,1.634-2.57,2.458a1.633,1.633,0,0,0-.569,1.26A1.732,1.732,0,0,0,12.337,7.5c.19.117.2.152.076.334a6.5,6.5,0,0,1-.967,1.076.2.2,0,0,1-.286.019A3.5,3.5,0,0,1,9.524,6.271a3.458,3.458,0,0,1,1.183-3c.791-.738,1.548-1.508,2.36-2.226A3.922,3.922,0,0,1,17.022.21a3.571,3.571,0,0,1,2.617,2.87c.019.094.035.188.052.283Z"
                                    transform="translate(-5.23 0)"
                                    fill="#3b3b3b"
                                  />
                                  <path
                                    d="M3.426,11.282c-.013.374-.038.736-.036,1.1A3.909,3.909,0,0,0,3.6,13.594a.253.253,0,0,1-.077.3c-.364.343-.73.684-1.082,1.037a1.684,1.684,0,0,0,.555,2.755A1.941,1.941,0,0,0,5.069,17.4c.893-.828,1.765-1.677,2.635-2.526a1.612,1.612,0,0,0,.516-.972,1.676,1.676,0,0,0-.869-1.693c-.2-.129-.215-.157-.1-.359a3.632,3.632,0,0,1,.97-1.065.239.239,0,0,1,.308,0,3.542,3.542,0,0,1,1.606,2.4,3.306,3.306,0,0,1-.443,2.372,3.7,3.7,0,0,1-.541.669c-.715.7-1.442,1.394-2.173,2.084a6.323,6.323,0,0,1-.82.7,3.947,3.947,0,0,1-6.1-2.378A3.4,3.4,0,0,1,1.107,13.48c.738-.725,1.5-1.429,2.251-2.142.022-.021.046-.039.067-.057"
                                    transform="translate(0 -6.236)"
                                    fill="#3b3b3b"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          {getMyProfile?.data?.data?.website_url}
                        </a>
                      </ProfileUserName>
                    </>
                  ) : (
                    <></>
                  )}
                </ProfileLeft>

                <ProfileRight>
                  <ul>
                    <li>
                      <Button
                        type="button"
                        onClick={() => {
                          setFollow("followers");
                          setShowProfilePage(true);
                        }}
                      >
                        <span className="profile-number">
                          {myProfileDataSelect?.data?.data?.follower_count_data}
                        </span>
                        <label>Followers</label>
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="button"
                        onClick={() => {
                          setFollow("following");
                          setShowProfilePage(true);
                        }}
                      >
                        <span className="profile-number">
                          {
                            myProfileDataSelect?.data?.data
                              ?.following_count_data
                          }
                        </span>
                        <label>Following</label>
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="button"
                        onClick={() => {
                          setFollow("friends");
                          setShowProfilePage(true);
                        }}
                      >
                        <span className="profile-number">
                          {myProfileDataSelect?.data?.data?.friend_count_data}
                        </span>
                        <label>Friends</label>
                      </Button>
                    </li>
                  </ul>
                  <FormGroupWrap>
                    <FormGroup className="custom-group">
                      <p>{getMyProfile?.data?.data?.bio} </p>
                    </FormGroup>
                    <Button
                      className="profile-edit-btn"
                      onClick={navigateToSettings}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                      >
                        <g transform="translate(0 0)">
                          <g transform="translate(0 1)">
                            <rect width="24" height="24" fill="none" />
                          </g>
                          <g transform="translate(2.75 1)">
                            <path
                              d="M8.125,26.125H4V22Z"
                              transform="translate(-4 -6.876)"
                              fill="#3b3b3b"
                            />
                            <path
                              d="M0,0,20.533,1.088l.326,6.16L.326,6.16Z"
                              transform="translate(21.25 4.125) rotate(135)"
                              fill="#3b3b3b"
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                  </FormGroupWrap>
                </ProfileRight>
              </ProfileWrap>

              {getFollow === "" || !showProfilePage ? (
                <>
                  <MyPlansWrap ref={myPlans}>
                    {/* <MyPlansTop>
                      <MyPlansTitle>My Plans</MyPlansTitle>
                      <CreateBtn>
                        <button
                          type="submit"
                          className="btn filters"
                          onClick={() => setFiltersModalShow(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.939"
                            height="12.69"
                            viewBox="0 0 20.939 12.69"
                          >
                            <g transform="translate(-309.65 -341.65)">
                              <path
                                d="M-10202.795-11068.4h18.24"
                                transform="translate(10513.795 11411.4)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-linecap="round"
                                stroke-width="2.7"
                              />
                              <path
                                d="M-10202.794-11068.4h12.872"
                                transform="translate(10513.795 11416.4)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-linecap="round"
                                stroke-width="2.7"
                              />
                              <path
                                d="M-10202.795-11068.4h7.259"
                                transform="translate(10513.795 11421.392)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-linecap="round"
                                stroke-width="2.7"
                              />
                            </g>
                          </svg>
                        </button>
                        <Dropdown className="sortby">
                          <Dropdown.Toggle>
                            Sort by
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.656"
                              height="8.596"
                              viewBox="0 0 13.656 8.596"
                            >
                              <path
                                d="M5.944,11.888,0,5.944,5.944,0"
                                transform="translate(0.884 6.828) rotate(-90)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="2.5"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as="button">Upcomimg</Dropdown.Item>
                            <Dropdown.Item as="button">
                              Plan Created
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              Plan Invited to
                            </Dropdown.Item>
                            <Dropdown.Item as="button">Private</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </CreateBtn>
                    </MyPlansTop> */}

                    {/* <MyPlansNotAvailable>
                      Currently my plans is not available
                    </MyPlansNotAvailable> */}

                    {/* <MyPlansList>
                      {getMyPlan?.data?.data[0]?.plans?.map((ele: any) => {
                        return (
                          <MyPlansBox>
                            <MyPlansImage>
                              <Link
                                to={`/viewplandetails/${ele?._id}`}
                                className="myPlanImgLink"
                              >
                                <img src={Nevada} alt="Nevada" />
                              </Link>
                            </MyPlansImage>
                            <MyPlansBoxTitle>
                              <Link to={`/viewplandetails/${ele?._id}`}>
                                {ele?.plan_name}
                              </Link>
                            </MyPlansBoxTitle>
                            <MyPlansDate>
                              {moment(ele?.start_date).format("DD/MM/YYYY")}
                            </MyPlansDate>
                          </MyPlansBox>
                        );
                      })}
                    </MyPlansList> */}
                  </MyPlansWrap>

                  {/* <MyPlansWrap className="collection-tab" ref={collection}>
                    <MyPlansTop>
                      <MyPlansTitle>Wants to do</MyPlansTitle>
                      <MyPlansSubTitle>
                        This collection is private
                      </MyPlansSubTitle>
                    </MyPlansTop>

                    <MyPlansList>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={HotelFufu} alt="Hotel Fufu" />
                          <MyPlansActionWrap className="collection-action">
                            <MyPlansAction>
                              <Dropdown className="action multiple-action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="#fff"
                                    />
                                    <g transform="translate(-462.297 -416.069)">
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(467.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(472.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(477.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <CityTitle>Los Angeles</CityTitle>
                                  <Form>
                                    <FormGroup className="custom-switch-wrap">
                                      <Form.Check
                                        type="switch"
                                        label="switch"
                                      />
                                      <h4>This Collection is Private</h4>
                                      <p>
                                        Private Collections only viewable by
                                        your Followers
                                      </p>
                                    </FormGroup>
                                    <BtnWrap>
                                      <Link to="#" className="delete-btn">
                                        Delete Collection
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Button>
                                    </BtnWrap>
                                  </Form>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansAction>
                            <MyPlansDropdown>
                              <Dropdown className="action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansDropdown>
                          </MyPlansActionWrap>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Hotel Fufu
                        </MyPlansBoxTitle>
                      </MyPlansBox>

                      <MyPlansBox>
                        <MyPlansImage>
                          <img
                            src={TacoRestaurant}
                            alt="Great Taco Restaurant"
                          />
                          <MyPlansActionWrap className="collection-action">
                            <MyPlansAction>
                              <Dropdown className="action multiple-action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="#fff"
                                    />
                                    <g transform="translate(-462.297 -416.069)">
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(467.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(472.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(477.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <CityTitle>Los Angeles</CityTitle>
                                  <Form>
                                    <FormGroup className="custom-switch-wrap">
                                      <Form.Check
                                        type="switch"
                                        label="switch"
                                      />
                                      <h4>This Collection is Private</h4>
                                      <p>
                                        Private Collections only viewable by
                                        your Followers
                                      </p>
                                    </FormGroup>
                                    <BtnWrap>
                                      <Link to="#" className="delete-btn">
                                        Delete Collection
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Button>
                                    </BtnWrap>
                                  </Form>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansAction>
                            <MyPlansDropdown>
                              <Dropdown className="action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansDropdown>
                          </MyPlansActionWrap>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Great Taco Restaurant
                        </MyPlansBoxTitle>
                      </MyPlansBox>

                      <MyPlansBox>
                        <MyPlansImage>
                          <img
                            src={InterestName}
                            alt="Point of interest Name"
                          />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                          <MyPlansActionWrap className="collection-action">
                            <MyPlansAction>
                              <Dropdown className="action multiple-action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="#fff"
                                    />
                                    <g transform="translate(-462.297 -416.069)">
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(467.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(472.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(477.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <CityTitle>Los Angeles</CityTitle>
                                  <Form>
                                    <FormGroup className="custom-switch-wrap">
                                      <Form.Check
                                        type="switch"
                                        label="switch"
                                      />
                                      <h4>This Collection is Private</h4>
                                      <p>
                                        Private Collections only viewable by
                                        your Followers
                                      </p>
                                    </FormGroup>
                                    <BtnWrap>
                                      <Link to="#" className="delete-btn">
                                        Delete Collection
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Button>
                                    </BtnWrap>
                                  </Form>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansAction>
                            <MyPlansDropdown>
                              <Dropdown className="action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansDropdown>
                          </MyPlansActionWrap>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Point of interest Name
                        </MyPlansBoxTitle>
                      </MyPlansBox>

                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={SuperTaco} alt="Super Taco" />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                          <MyPlansActionWrap className="collection-action">
                            <MyPlansAction>
                              <Dropdown className="action multiple-action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="#fff"
                                    />
                                    <g transform="translate(-462.297 -416.069)">
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(467.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(472.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(477.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <CityTitle>Los Angeles</CityTitle>
                                  <Form>
                                    <FormGroup className="custom-switch-wrap">
                                      <Form.Check
                                        type="switch"
                                        label="switch"
                                      />
                                      <h4>This Collection is Private</h4>
                                      <p>
                                        Private Collections only viewable by
                                        your Followers
                                      </p>
                                    </FormGroup>
                                    <BtnWrap>
                                      <Link to="#" className="delete-btn">
                                        Delete Collection
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Button>
                                    </BtnWrap>
                                  </Form>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansAction>
                            <MyPlansDropdown>
                              <Dropdown className="action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansDropdown>
                          </MyPlansActionWrap>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Super Taco
                        </MyPlansBoxTitle>
                      </MyPlansBox>

                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={GreatCabin} alt="Wonderful Great Cabin" />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                          <MyPlansActionWrap className="collection-action">
                            <MyPlansAction>
                              <Dropdown className="action multiple-action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="#fff"
                                    />
                                    <g transform="translate(-462.297 -416.069)">
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(467.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(472.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                      <circle
                                        cx="1.5"
                                        cy="1.5"
                                        r="1.5"
                                        transform="translate(477.297 426.069)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <CityTitle>Los Angeles</CityTitle>
                                  <Form>
                                    <FormGroup className="custom-switch-wrap">
                                      <Form.Check
                                        type="switch"
                                        label="switch"
                                      />
                                      <h4>This Collection is Private</h4>
                                      <p>
                                        Private Collections only viewable by
                                        your Followers
                                      </p>
                                    </FormGroup>
                                    <BtnWrap>
                                      <Link to="#" className="delete-btn">
                                        Delete Collection
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Button>
                                    </BtnWrap>
                                  </Form>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansAction>
                            <MyPlansDropdown>
                              <Dropdown className="action">
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </MyPlansDropdown>
                          </MyPlansActionWrap>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Wonderful Great Cabin...
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                    </MyPlansList>
                    <MyPlansMore>
                      <Button className="see-more">See more...</Button>
                    </MyPlansMore>
                    <MyPlansTop>
                      <MyPlansTitle>Collections</MyPlansTitle>
                      <button
                        type="submit"
                        className="btn modal-btn"
                        onClick={() => setAddCollectionModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <circle cx="15" cy="15" r="15" fill="#f0f0f0" />
                          <path
                            d="M5.749-8.554v4.722h-4.6v2.97h4.6V3.861H8.962V-.862h4.6v-2.97h-4.6V-8.554Z"
                            transform="translate(7.645 17.347)"
                            fill="#3b3b3b"
                            stroke="#f0f0f0"
                            stroke-width="0.5"
                          />
                        </svg>
                      </button>
                    </MyPlansTop>
                    <MyPlansList>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={Nevada} alt="Vegas" />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-lg-title">
                          Vegas
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={Caba} alt="Caba" />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-lg-title">
                          Caba
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={La} alt="La" />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-lg-title">
                          La
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={InterestName} alt="Napa" />
                          <MyPlansLock>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.38"
                              height="16.249"
                              viewBox="0 0 12.38 16.249"
                            >
                              <path
                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                transform="translate(-6 -1.5)"
                                fill="#fff"
                              />
                            </svg>
                          </MyPlansLock>
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-lg-title">
                          Napa
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                    </MyPlansList>
                    <MyPlansTop>
                      <MyPlansTitle>Collections Followings</MyPlansTitle>
                    </MyPlansTop>
                    <MyPlansList>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={HotelFufu} alt="Hotel Fufu" />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Hotel Fufu
                        </MyPlansBoxTitle>
                        <MyPlansBoxTitle className="collection-title">
                          By Penny Black
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img
                            src={TacoRestaurant}
                            alt="Great Taco Restaurant"
                          />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Great Taco Restaurant
                        </MyPlansBoxTitle>
                        <MyPlansBoxTitle className="collection-title">
                          By Penny Black
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img
                            src={InterestName}
                            alt="Point of interest Name"
                          />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Point of interest Name
                        </MyPlansBoxTitle>
                        <MyPlansBoxTitle className="collection-title">
                          By Penny Black
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={SuperTaco} alt="Super Taco" />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Super Taco
                        </MyPlansBoxTitle>
                        <MyPlansBoxTitle className="collection-title">
                          By Penny Black
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                      <MyPlansBox>
                        <MyPlansImage>
                          <img src={GreatCabin} alt="Wonderful Great Cabin" />
                        </MyPlansImage>
                        <MyPlansBoxTitle className="collection-title">
                          Wonderful Great Cabin...
                        </MyPlansBoxTitle>
                        <MyPlansBoxTitle className="collection-title">
                          By Penny Black
                        </MyPlansBoxTitle>
                      </MyPlansBox>
                    </MyPlansList>
                  </MyPlansWrap> */}
                </>
              ) : (
                <>
                  <SocialTabsWrap>
                    <TabsWrap>
                      <Tabs
                        activeKey={getFollow}
                        onSelect={(eventkey: any) => {
                          setFollow(eventkey);
                        }}
                      >
                        <Tab eventKey="followers" title="Followers">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          value={searchTerm}
                                          handleBlur={handleBlur}
                                          onKeyPress={userNameHandler}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchTextData(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />

                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchForFollwerSection?.data?.data
                                    ?.length === 0 &&
                                    searchTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No followers found!
                                      </SearchBoxNote>
                                    )}
                                  {getFollowerList?.length === 0 &&
                                    searchTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any follower.
                                        </p>
                                      </TabDataNotFound>
                                    )}
                                  {searchForFollwerSection?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}
                                  {(
                                    (searchTerm.trim() === "" &&
                                      getFollowerList) ||
                                    searchForFollwerSection?.data?.data
                                  )?.map((item: any, index: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage
                                            onClick={() =>
                                              navigateToUserProfile(item)
                                            }
                                          >
                                            <img
                                              src={item?.photo}
                                              alt={item?.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName
                                            onClick={() =>
                                              navigateToUserProfile(item)
                                            }
                                          >
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>
                                        <Button
                                          className="btn-border"
                                          onClick={() =>
                                            removeHandler(item.follower_id)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="following" title="Following">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          value={searchFollowingTerm}
                                          onKeyPress={userNameHandler}
                                          handleBlur={handleBlur}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFollowing(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchForFollowingSection?.data?.data
                                    ?.length === 0 &&
                                    searchFollowingTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No Following Found!
                                      </SearchBoxNote>
                                    )}

                                  {getFollowingList?.length === 0 &&
                                    searchFollowingTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any following.
                                        </p>
                                      </TabDataNotFound>
                                    )}

                                  {searchForFollowingSection?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}

                                  {(
                                    (searchFollowingTerm.trim() === "" &&
                                      getFollowingList) ||
                                    searchForFollowingSection?.data?.data
                                  )?.map((item: any, i: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage
                                            onClick={() =>
                                              navigateFollowing(item)
                                            }
                                          >
                                            <img
                                              src={item.photo}
                                              alt={item.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName
                                            onClick={() =>
                                              navigateFollowing(item)
                                            }
                                          >
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>
                                        <Button
                                          className="btn-border-color"
                                          onClick={() =>
                                            followingHandler(item?.following_id)
                                          }
                                        >
                                          Following
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="friends" title="Friends">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          onKeyPress={userNameHandler}
                                          value={searchFriendTerm}
                                          handleBlur={handleBlur}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFriend(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  <ul>
                                    {searchForFriendSection?.data?.data
                                      ?.length === 0 &&
                                      searchFriendTerm.trim() !== "" && (
                                        <SearchBoxNote>
                                          No friends found!
                                        </SearchBoxNote>
                                      )}
                                    {getFriendList?.length === 0 &&
                                      searchFriendTerm.trim() === "" && (
                                        <TabDataNotFound>
                                          <p>
                                            You have not currently any friends.
                                          </p>
                                        </TabDataNotFound>
                                      )}
                                    {searchForFriendSection?.isLoading && (
                                      <SearchListLoader>
                                        <img
                                          src={PageLoaderIcon}
                                          alt="Loading..."
                                        />
                                      </SearchListLoader>
                                    )}
                                    {(
                                      (searchFriendTerm.trim() === "" &&
                                        getFriendList) ||
                                      searchForFriendSection?.data?.data
                                    )?.map((item: any, index: number) => {
                                      return (
                                        <li>
                                          <FollowListLeft>
                                            <FollowImage
                                              onClick={() =>
                                                navigateFriend(item)
                                              }
                                            >
                                              <img
                                                src={item?.photo}
                                                alt={item?.user_name}
                                              />
                                            </FollowImage>
                                            <FollowName
                                              onClick={() =>
                                                navigateFriend(item)
                                              }
                                            >
                                              {item?.user_name ??
                                                item?.userName}
                                            </FollowName>
                                          </FollowListLeft>
                                          <Button
                                            className="btn-border"
                                            onClick={() =>
                                              removeFriendHandler(
                                                item?.friends_id
                                              )
                                            }
                                          >
                                            Remove
                                          </Button>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>
                      </Tabs>
                    </TabsWrap>
                  </SocialTabsWrap>
                </>
              )}
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <AddCollectionModal
        show={AddCollectionModalShow}
        onHide={() => setAddCollectionModalShow(false)}
      />

      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <RemoveFriendsMyProfileModal
        show={removeFriendModalShow}
        onHide={() => setRemoveFriendModalShow(false)}
        setUnfollowModalShow={setRemoveFriendModalShow}
        profile_id={removeFriendsId}
      />

      {/*  For Remove Follower Modal*/}
      <RemoveModal
        show={removeModalShow}
        onHide={() => setRemoveModalShow(false)}
        setUnfollowModalShow={setRemoveModalShow}
        profile_id={removeId}
      />

      {/* For Remove Following Modal */}
      <RemoveFollowigModal
        show={removeFollowinModalShow}
        onHide={() => setRemoveFollowingModalShow(false)}
        setUnfollowModalShow={setRemoveFollowingModalShow}
        profile_id={removeFollowigId}
      />
    </>
  );
};

export default MyProfile;
