import { GetTermsType } from "../../action/terms-Listing/termslisting";
import { getTermsModel } from "../../models";

export const initState: getTermsModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case GetTermsType.GET_TERMS_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case GetTermsType.GET_TERMS_INFO_SUCCESS: {
      return {
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case GetTermsType.GET_TERMS_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
        data: null,
      };
    }

    default:
      return state;
  }
};
