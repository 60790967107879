import { getRentalId } from "../../../services/getRentalId";

export enum getRentalIdType {
  GET_RENTAL_ID_INFO = "GET_RENTAL_ID_INFO",
  GET_RENTAL_ID_INFO_SUCCESS = "GET_RENTAL_ID_INFO_SUCCESS",
  GET_RENTAL_ID_INFO_FALIURE = "GET_RENTAL_ID_INFO_FALIURE",
}

export const getRentalIdInfo = () => (dispatch: any) => {
  dispatch({
    type: getRentalIdType.GET_RENTAL_ID_INFO,
  });
};

export const getRentalIdInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getRentalIdType.GET_RENTAL_ID_INFO_SUCCESS,
    data,
  });
};

export const getRentalIdInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getRentalIdType.GET_RENTAL_ID_INFO_FALIURE,
    error: msg,
  });
};

export const getRentalIdData = (rentalId: any) => async (dispatch: any) => {
  try {
    dispatch(getRentalIdInfo());
    const result: any = await getRentalId(rentalId);
    dispatch(getRentalIdInfoSuccess(result));
  } catch (e: any) {
    dispatch(getRentalIdInfoFailed(e?.response?.data));
  }
};
