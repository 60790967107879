import { useState } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GlobalDataType } from "../../redux/action/globalData";
import { updatePlanData } from "../../redux/action/updatePlan/updatePlan";
import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import CandyBrian from "../../assets/images/candy-brian.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import CalendarIcon from "../../assets/images/calendar-icon.svg";
import PrivateIcon from "../../assets/images/private-icon.svg";
import FollowersIcon from "../../assets/images/followers-icon.svg";
import FriendsIcon from "../../assets/images/friends-icon.svg";
import PublicIcon from "../../assets/images/public-icon.svg";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
  FriendItem,
  CreatePlanDate,
  RadioBox,
  CreatePlanBody,
  PlanDates,
  UploadImageBox,
  EditPlanSvgContainer,
  ErrorMessage,
} from "./updatePlanModalStyle";
import { planDetailsHandler } from "../../redux/action/planDetails/planDetails";
import CustomInput from "../CustomInput";
import { Formik, FormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const UpdatePlanModal = (props: any) => {
  const dispatch: any = useDispatch();
  const [getPlanDetails, setPlanDetails] = useState(props.getPlanDetails);
  let { plan_id }: any = useParams();

  const submitForm = (values: any) => {
    const finalValue = {
      ...values,
      plan_name: values.plan_name.trim(),
      description: values.description.trim(),
      start_date: moment(values.start_date).format("MM-DD-YYYY"),
      end_date:
        values.end_date === null || values.end_date === ""
          ? moment(values.start_date).format("MM-DD-YYYY")
          : moment(values.end_date).format("MM-DD-YYYY"),
      plan_id: props?.getPlanDetails?._id,
    };
    props.showimage && Object.assign(finalValue, { photo: props.showimage });
    dispatch(updatePlanData(finalValue));
    props.setCreatePlanModalShow(false);
    props.setCreateNewModalShow(false);
  };

  const imageUploader = async (e: any) => {
    props.setImageValidation("");
    props.setImage(e.target.files[0]);
    const file = e.target.files[0];

    if (!file.type.includes("image")) {
      props.setImageValidation(
        "Uploaded file is not a valid image. Only PNG, JPG and JPEG files are allowed"
      );
    } else if (!(file.size <= 5242880)) {
      props.setImageValidation(
        "Image size is greater then 5 MB. Please upload image below 5 MB"
      );
    } else {
      const base64: any = await convertBase64(file);
      props.setShowImage(base64);
      props.setUploadBox(true);
    }
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="updatePlanModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .updatePlanModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 560px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .updatePlanModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>

        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modalTitle">
              <h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.863"
                  height="19.867"
                  viewBox="0 0 19.863 19.867"
                >
                  <path
                    d="M19.309.208A1.254,1.254,0,0,0,18.135.1l-5.3,2.273L7.525.1c.008,0,0,.034.009.042A1.232,1.232,0,0,0,6.546.1L.752,2.585A1.244,1.244,0,0,0,0,3.727v14.9a1.243,1.243,0,0,0,1.242,1.242,1.282,1.282,0,0,0,.49-.1l5.3-2.273,5.306,2.273a1.282,1.282,0,0,0,.49.1,1.239,1.239,0,0,0,.484-.146l.005.047,5.794-2.483a1.244,1.244,0,0,0,.752-1.142V1.243A1.236,1.236,0,0,0,19.309.208ZM2.483,4.547l3.31-1.419v12.2l-3.31,1.419ZM8.277,3.125,11.59,4.547v12.2L8.277,15.324Zm9.107,12.2-3.31,1.419V4.547l3.31-1.419Z"
                    transform="translate(0 -0.002)"
                    fill="#4d27da"
                  />
                </svg>
                UPDATE A PLAN
              </h2>
            </div>

            <CreatePlanBody>
              <Formik
                enableReinitialize
                initialValues={{
                  start_date: moment(
                    props?.getPlanDetails?.start_date,
                    "MM-DD-YYYY"
                  ).toDate(),
                  end_date:
                    props?.getPlanDetails?.start_date ===
                    props?.getPlanDetails?.end_date
                      ? null
                      : moment(
                          props?.getPlanDetails?.end_date,
                          "MM-DD-YYYY"
                        ).toDate(),
                  plan_name: props?.getPlanDetails?.plan_name,
                  description: props?.getPlanDetails?.description,
                  view_plan: props?.getPlanDetails?.view_plan,
                  invite_other: props?.getPlanDetails?.invite_other,
                }}
                validate={(values: any) => {
                  let errors: any = {};

                  if (!values.plan_name) {
                    errors.plan_name = "Please enter your plan name";
                  }
                  if (!values.start_date) {
                    errors.start_date = "Please select start date";
                  }

                  return errors;
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      {!props.showimage && !props.getUploadBox && (
                        <UploadImageBox>
                          <Form.Control
                            type="file"
                            name="photo"
                            onChange={imageUploader}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                            <path
                              d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                              transform="translate(2.459 16.62)"
                              fill="#3b3b3b"
                            ></path>
                          </svg>
                          <span className="uploader-title">
                            Add Cover Image
                          </span>
                        </UploadImageBox>
                      )}

                      {props.getUploadBox &&
                        (props.showimage || props?.getPlanDetails?.photo) && (
                          <UploadImageBox className="imageUploaded">
                            <img
                              src={
                                props.showimage || props?.getPlanDetails?.photo
                              }
                            />
                            <button
                              onClick={() => {
                                props.setShowImage("");
                                props.setImageValidation("");
                                props.setImage("");
                                props.setUploadBox(false);
                              }}
                              className="deleteBtn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </UploadImageBox>
                        )}

                      {props.imageValidation && (
                        <ErrorMessage>{props.imageValidation}</ErrorMessage>
                      )}

                      <CreatePlanDate style={{ display: "none" }}>
                        <img src={CalendarIcon} alt="calendar-icon" />
                        Dec 21-25
                      </CreatePlanDate>

                      <PlanDates>
                        <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>

                          <DatePicker
                            selected={values.start_date}
                            onChange={(e: any) => {
                              setFieldValue("start_date", e);
                              setFieldValue("end_date", "");
                            }}
                            // dateFormat="MM-DD-YYYY"
                            dateFormat="MM-dd-yyyy"
                            placeholderText={"Start Date"}
                            minDate={moment().toDate()}
                            onBlur={handleBlur}
                            // value={values.date}
                            // onChange={handleChange}
                            className="form-control"
                          />

                          {errors.start_date && touched.start_date && (
                            <label className="error">
                              please select start date
                            </label>
                          )}
                        </FormGroup>

                        <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>
                          <DatePicker
                            selected={
                              values.end_date === null ? null : values.end_date
                            }
                            onChange={(e: any) => {
                              setFieldValue("end_date", e);
                            }}
                            // dateFormat="dd/MM/yyyy"
                            dateFormat="MM-dd-yyyy"
                            minDate={moment(values.start_date)
                              .add(1, "days")
                              .toDate()}
                            placeholderText={"End Date"}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.end_date && touched.end_date && (
                            <label className="error">
                              please select end date
                            </label>
                          )}
                        </FormGroup>
                      </PlanDates>
                      <FormGroup>
                        <CustomInput
                          inputid="plan_name"
                          inputlabe=""
                          inputtype="text"
                          placeholder="Name your Plan"
                          value={values.plan_name.trimStart()}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors.plan_name}
                          touched={touched.plan_name}
                          maxLength={25}
                        />
                      </FormGroup>

                      <FormGroup className="mb-0">
                        <CustomInput
                          inputid="description"
                          inputlabe=""
                          inputtype="text"
                          as="textarea"
                          placeholder="Add notes or description"
                          value={values.description.trimStart()}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors=""
                          touched=""
                          maxLength={255}
                        />
                      </FormGroup>

                      <ul className="planFeature">
                        <li>
                          <h4>Choose who can see and join this</h4>
                          <ul className="planVisibility">
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  value="Private"
                                  checked={
                                    values.view_plan == "Private" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                  id="private"
                                  defaultChecked
                                />
                                <span />
                                <label>
                                  <img src={PrivateIcon} alt="private-icon" />
                                  Private
                                </label>
                                <p>Only people that are invited</p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  value="Friends"
                                  id="friend"
                                  checked={
                                    values.view_plan == "Friends" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img src={FriendsIcon} alt="friends-icon" />
                                  Friends
                                </label>
                                <p>Only your friends on TripD</p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  id="followers"
                                  value="Followers"
                                  checked={
                                    values.view_plan == "Followers"
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img
                                    src={FollowersIcon}
                                    alt="followers-icon"
                                  />
                                  Followers
                                </label>
                                <p>
                                  Your Followers (Friends automatically follow
                                  you)
                                </p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  id="public"
                                  value="Public"
                                  checked={
                                    values.view_plan == "Public" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img src={PublicIcon} alt="public-icon" />
                                  Public
                                </label>
                                <p>All Tripd users</p>
                              </RadioBox>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ModalBodyBtn>
                        <Button type="submit" className="btn">
                          UPDATE
                        </Button>
                      </ModalBodyBtn>
                    </form>
                  </>
                )}
              </Formik>
            </CreatePlanBody>
            {/* </Form> */}
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdatePlanModal;
