import { followCollectionType } from "../../action/followingCollection/followingCollection";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case followCollectionType.FOLLOW_COLLECTION_TYPE: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case followCollectionType.FOLLOW_COLLECTION_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case followCollectionType.FOLLOW_COLLECTION_TYPE_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
        data: null,
      };
    }
    default:
      return state;
  }
};
