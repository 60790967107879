import { combineReducers } from "redux";
import globalDataReducer from "../reducer/globalData";
import signUpReducer from "./signUp/signUp";
import loginReducer from "./signin/loginData";
import userReducer from "./signin/user";
import forgotPasswordReducer from "./forgotPassword/forgotPwd";
import resetPasswordReducer from "./resetPassword/resetPwd";
import inviteSignupReducer from "./inviteSignup/inviteSignUp";
import updateUserReducer from "./updateuser/updateUser";
import myProfileReducer from "./my-profile/myProfile";
import updateProfileReducer from "./update-profile/updateProfile";
import changePasswordReducer from "./changepassword/changePassword";
import addFollowReducer from "./add-follow-req/addFollow";
import getAllUserReducer from "./getallusers/getAllUser";
import reqFollowReducer from "./req-follow/reqFollow";
import getFollowersReducer from "./getfollowers/getFollowers";
import getAllFriendsReducer from "./getallfriends/getAllFriends";
import getFollowingReducer from "./getfollowing/getFollowing";
import unfollowReducer from "./unfollow/unFollow";
import rejectUserReducer from "./rejectuser/rejectUser";
import sendInviteReducer from "./send-invite/sendInvite";
import searchFollowersReducer from "./search-followers/searchFollowers";
import searchFollowingReducer from "./search-following/searchFollowing";
import searchFriendsReducer from "./search-friends/searchFriends";
import searchUserReducer from "./search-user/searchUser";
import getProfileReducer from "./get-profile/getProfile";
import getuserProfileReducer from "./get-user-profile/getUserProfile";
import getAllrequest from "./get-all-request/getAllRequest";
import notification from "./notification/notification";
import deleteFollowRequest from "./deleteFollowRequest/deleteFollowRequest";
import followBackReducer from "./follow-back/followBack";
import addFriendNotificationReducer from "./add-friend-notification/addFriendNotification";
import acceptFriendsNotificationReducer from "./accept-friend-notification-req/acceptFriendNotificationRequest";
import addFriendReducer from "./add-friends/addFriends";
import removeFriendReducer from "./removeFriend/removeFriend";
import removeFriendMyProfileReducer from "./remove-Friend-MyProfile/removeFriendMyProfile";
import getAlleventsReducer from "./get-all-events/getAllEvents";
import linkExpireReducer from "./link-expire/linkExpire";
import resetPwdLinkExpireReducer from "./resetPwd-linkExpire/resetPwdLink-Expire";
import createPlanReducer from "./createPlan/createPlan";
import joinPlanReducer from "./joinPlan/joinPlan";
import declinePlanReducer from "./declinePlan/declinePlan";
import updatePlanReducer from "./updatePlan/updatePlan";
import deletePlanReducer from "./deletePlan/deletePlan";
import manageDayPlanReducer from "./manageDayPlan/manageDayPlan";
import getAllHotelReducer from "./getAllHotel/getAllHotel";
import getRentalsReducer from "./get-rentals/getRentals";
import getEventConcertReducer from "./get-event-concert/getEventConcert";
import getHotelIdReducer from "./getHotelId/getHotelId";
import getRentalIdReducer from "./getRentalId/getRentalId";
import getAllFoodDrinksReducer from "./getAll-FoodDrinks/getAllFoodDrinks";
import getInviteFrndRamdomStringReducer from "./getInviteFrndRandomString/getInviteFrndRamdomString";
import getHotelImagesReducer from "./get-hotel-images/getHotelImages";
import getRentalImagesReducer from "./get-rentals-images/getRenatalImages";
import getPlanActivityReducer from "./getPlanAcitvity/getPlanActivity";
import myPlanReducer from "./myPlan/myPlan";
import planDetailsReducer from "./planDetails/planDetails";
import invitePlanReducer from "./invitePlan/invitePlan";
import planStatusReducer from "./planStatus/planStatus";
import manageVenuePlanReducer from "./manageVenuePlan/manageVenuePlan";
import addDayToAnotherDayPlanReducer from "./addDayToAnotherDayPlan/addDayToAnotherDayPlan";
import copyDayToAnotherDayPlanReducer from "./copyDayToAnotherDayPlan/copyDayToAnotherDayPlan";
import deleteVenuePlanReducer from "./deleteVenuePlan/deleteVenuePlan";
import updateVenuePlanReducer from "./updateVenuePlan/updateVenuePlan";
import removeInvitedUserPlanReducer from "./removeInvitedUserPlan/removeInvitedUserPlan";
import createGroupReducer from "./createGroup/createGroup";
import createAllGroupReducer from "./get-all-group/get-all-group";
import acceptGroupReducer from "./accept-group-req/acceptGroup";
import getConversationReducer from "./getConversation/getConversation";
import deleteGroupMemberReducer from "./deleteGroupmember/deleteGroupMember";
import addInviteGroupReducer from "./add-invite-group/addInviteGroups";
import exitGroupReducer from "./exit-group/exitGroup";
import planListingReducer from "./plan-listing/planListing";
import addFeedbackReducer from "./add-feedback/addFeedback";
import joinPublicPlanReducer from "./joinPublicPlan/joinPublicPlan";
import pointOfInterestReducer from "./point-of-interest/pointOfInterest";
import getImageGoogleReducer from "./getImageGoogle/getImageGoogle";
import hikingReducer from "./hiking/hiking";
import latLongReducer from "./lat-long/latLong";
import addCollectionReducer from "./addCollection/addCollection";
import getCollectionReducer from "./getCollection/getCollection";
import deleteCollectionReducer from "./deleteCollection/deleteCollection";
import getSportsEventReducer from "./get-sports-event/getSportsEvent";
import getFestivalListingReducer from "./getFestivalList/getFestivalListing";
import globalSearchReducer from "./global-search/globalSearch";
import shareWithModelReducer from "./share-with-model/shareWithModel";
import updateCollectionReducer from "./updateCollection/updateCollection";
import getOneCollectionReducer from "./getOneCollection/getOneCollection";
import addItemToCollectionReducer from "./addItemToCollection/addItemToCollection";
import deleteCollectionItemReducer from "./deleteCollectionItem/deleteCollectionItem";
import TermsReducer from "./terms-listing/termsListing";
import getAboutUsReducer from "./aboutUs/aboutUs";
import getPrivacyPolicyReducer from "./privacy-policy/privacyPolicy";
import followingCollectionReducer from "./followingCollection/followingCollection";
import unFollowCollectionReducer from "./unFollowCollection/unFollowCollection";
import getCollectionFollowingReducer from "./getCollectionFollowing/getCollectionFollowing";
import copyPlanReducer from "./copyPlan/copyPlan";
import DynamicCategoryReducer from "./dynamic-category/dynamicCategory";
import CurrentLocationReducer from "./current-location/currentLocation";
import DynamicChatReducer from "./dynamic-chat/dynamicChat";
import HelpAndFaqReducer from "./help-faq-list/helpAndFaq";
import getCollectionItemReducer from "./getCollectionItem/getCollectionItem";
import sendEmailReducer from "./send-email/sendEmail";
import manageFilterReducer from "./manage-filter/manageFilter";
import manageHotelReducer from "./manage-hotel-filter/manageHotelFilter";

export default combineReducers({
  globalData: globalDataReducer,
  signUp: signUpReducer,
  user: userReducer,
  forgotPasword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  inviteSign: inviteSignupReducer,
  loginData: loginReducer,
  updateUser: updateUserReducer,
  myProfile: myProfileReducer,
  updateProfile: updateProfileReducer,
  changePassword: changePasswordReducer,
  addFollowReq: addFollowReducer,
  getAllUser: getAllUserReducer,
  reqFollow: reqFollowReducer,
  getFollowers: getFollowersReducer,
  getAllFriends: getAllFriendsReducer,
  getFollowing: getFollowingReducer,
  unFollow: unfollowReducer,
  rejectUser: rejectUserReducer,
  sendInvite: sendInviteReducer,
  searchFollowers: searchFollowersReducer,
  searchFollowing: searchFollowingReducer,
  searchFriends: searchFriendsReducer,
  searchUser: searchUserReducer,
  getProfile: getProfileReducer,
  getUserProfile: getuserProfileReducer,
  getAllRequest: getAllrequest,
  getAllNotification: notification,
  deleteFollowRequest: deleteFollowRequest,
  followBack: followBackReducer,
  addFriendNotification: addFriendNotificationReducer,
  acceptFriendNotification: acceptFriendsNotificationReducer,
  addFriend: addFriendReducer,
  removeFriend: removeFriendReducer,
  removeFriendMyProfile: removeFriendMyProfileReducer,
  allEvents: getAlleventsReducer,
  linkExpire: linkExpireReducer,
  resetPwdLinkExpire: resetPwdLinkExpireReducer,
  createPlan: createPlanReducer,
  joinPlan: joinPlanReducer,
  declinePlan: declinePlanReducer,
  updatePlan: updatePlanReducer,
  deletePlan: deletePlanReducer,
  manageDayPlan: manageDayPlanReducer,
  getAllHotel: getAllHotelReducer,
  getRentals: getRentalsReducer,
  getEventConcert: getEventConcertReducer,
  getHotelId: getHotelIdReducer,
  getRentalId: getRentalIdReducer,
  getAllFoodDrinks: getAllFoodDrinksReducer,
  getInviteFrndRandomString: getInviteFrndRamdomStringReducer,
  getHotelImages: getHotelImagesReducer,
  getRentalImages: getRentalImagesReducer,
  planAcitivity: getPlanActivityReducer,
  myPlan: myPlanReducer,
  planDetails: planDetailsReducer,
  invitePlan: invitePlanReducer,
  planStatus: planStatusReducer,
  manageVenuePlan: manageVenuePlanReducer,
  addAnotherDayPlan: addDayToAnotherDayPlanReducer,
  copyAnotherDayPlan: copyDayToAnotherDayPlanReducer,
  deleteVenuePlan: deleteVenuePlanReducer,
  updateVenuePlan: updateVenuePlanReducer,
  removeInvitePlan: removeInvitedUserPlanReducer,
  createGroup: createGroupReducer,
  createAllGroup: createAllGroupReducer,
  acceptGroup: acceptGroupReducer,
  getConversation: getConversationReducer,
  deleteGroupMember: deleteGroupMemberReducer,
  addinviteGroup: addInviteGroupReducer,
  exitGroup: exitGroupReducer,
  planListing: planListingReducer,
  addFeedBack: addFeedbackReducer,
  joinPublicPlan: joinPublicPlanReducer,
  pointOfInterest: pointOfInterestReducer,
  getImageGoogle: getImageGoogleReducer,
  hiking: hikingReducer,
  latLong: latLongReducer,
  addCollection: addCollectionReducer,
  getCollection: getCollectionReducer,
  deleteCollection: deleteCollectionReducer,
  getSportEventsList: getSportsEventReducer,
  getFestivalList: getFestivalListingReducer,
  globalSearch: globalSearchReducer,
  shareWithModel: shareWithModelReducer,
  updateCollection: updateCollectionReducer,
  getOneCollection: getOneCollectionReducer,
  addItemToCollection: addItemToCollectionReducer,
  deleteCollectionItem: deleteCollectionItemReducer,
  termsListing: TermsReducer,
  aboutUs: getAboutUsReducer,
  privacyPolicy: getPrivacyPolicyReducer,
  followingCollection: followingCollectionReducer,
  unFollowingCollection: unFollowCollectionReducer,
  getAllCollectionFollowing: getCollectionFollowingReducer,
  copyPlan: copyPlanReducer,
  getDynamicCategory: DynamicCategoryReducer,
  currentLocation: CurrentLocationReducer,
  dynamicChat: DynamicChatReducer,
  helpAndFaqList: HelpAndFaqReducer,
  getCollectionItem: getCollectionItemReducer,
  sendEmail: sendEmailReducer,
  manageFilter: manageFilterReducer,
  hotelFilterinfo: manageHotelReducer,
});
