import { addFriendsNotificationAPI } from "../../../services/add-friend-notification";

export enum AddFriendNotificationType {
  ADD_FRIEND_NOTIFICATION_INFO = "ADD_FRIEND_NOTIFICATION_INFO",
  ADD_FRIEND_NOTIFICATION_INFO_SUCCESS = "ADD_FRIEND_NOTIFICATION_INFO_SUCCESS",
  ADD_FRIEND_NOTIFICATION_INFO_FALIURE = "ADD_FRIEND_NOTIFICATION_INFO_FALIURE",
}

export const addFriendNotificationInfo = () => (dispatch: any) => {
  dispatch({
    type: AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO,
  });
};

export const addFriendNotificationInfoSuccess =
  (data: any) => (dispatch: any) => {
    dispatch({
      type: AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO_SUCCESS,
      data,
    });
  };

export const addFriendNotificationInfoFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO_FALIURE,
      data: { msg },
    });
  };

export const getAddFriendNotificationData = () => async (dispatch: any) => {
  try {
    dispatch(addFriendNotificationInfo());
    const result: any = await addFriendsNotificationAPI();
    dispatch(addFriendNotificationInfoSuccess(result));
  } catch (e: any) {
    dispatch(addFriendNotificationInfoFailed(e.response.data.message));
  }
};
