import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const TabbingPanel = styled.div`
  width: 20.209%;
  background: #ffffff;
  position: relative;
  transition: all 0.35s ease 0s;
  @media (max-width: 1199px) {
    width: 320px;
    position: absolute;
    top: 15px;
    bottom: 0;
    right: 0;
    z-index: 9;
    margin-right: -320px;
  }
  @media (max-width: 767px) {
    top: 5px;
  }
  @media (max-width: 565px) {
    width: calc(100% - 15px);
    margin-right: calc(-100% + 15px);
  }
  &.panel-close {
    margin-right: -20.209%;
    @media (max-width: 1199px) {
      margin-right: 0;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      z-index: 10;
    }
    .panelToggleBtn {
      left: -25px;
      @media (max-width: 1199px) {
        left: -11px;
      }
      @media (max-width: 767px) {
        top: 10px;
      }
      svg {
        transform: rotate(180deg);
        @media (max-width: 1199px) {
          transform: inherit;
        }
      }
    }
  }
  .panelToggleBtn {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 30px;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 77px;
    left: -11px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.35s ease 0s;
    @media (max-width: 1199px) {
      left: -25px;
    }
    @media (max-width: 767px) {
      top: 10px;
    }
    svg {
      vertical-align: middle;
      transition: all 0.35s ease 0s;
      @media (max-width: 1199px) {
        transform: rotate(180deg);
      }
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      background: #4d27da;
      @media (max-width: 1199px) {
        background: inherit;
      }
      path {
        stroke: #fff;
        @media (max-width: 1199px) {
          stroke: #4d27da;
        }
      }
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  max-height: calc(100vh - 152px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }

  @media (max-width: 1199px) {
    padding: 22px 15px;
  }
  // @media (max-width:767px){
  //   height:calc(100vh - 140px);
  //   overflow-y: auto;
  // }
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const GroupDetailMembers = styled.div`
  margin: 25px -12px 0;
  padding: 0 12px;
  overflow-y: auto;
  max-height: calc(100vh - 229px);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }

  ${GroupMemberItem} {
    margin-bottom: 17px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      padding: 0 10px;
    }
    .btn {
      padding: 0;
      border-radius: 0;
      border: none;
      box-shadow: none !important;
      background: transparent;
      margin-left: auto;
      cursor: pointer;
      transition: all 0.35s ease 0s;
      svg {
        vertical-align: middle;
        path,
        g {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        path,
        g {
          stroke: #6840df;
        }
      }
      &.creator-btn {
        border: 1px solid #6840df;
        border-radius: 5px;
        padding: 3px 7px;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #6840df;
        &:hover {
          color: #fff;
          background: #6840df;
        }
      }
    }
  }
`;

export const GroupSaveGallery = styled.ul`
  margin: 0 -12px;
  padding: 0 7px;
  overflow-y: auto;
  list-style-type: none;
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  max-height: calc(100vh - 220px);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
  li {
    padding: 0 5px;
    width: 33.33%;
    margin-bottom: 10px;
    @media (max-width: 1199px) {
      width: 50%;
    }
  }
`;

export const TabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: 1px solid #cecece;
    padding: 0 10px;
    display: flex;
    margin: 0;
    .nav-item {
      flex: 1;
      text-align: center;
      @media (max-width: 1300px) {
        max-width: inherit;
      }
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 17px 10px 15px;
      min-width: 77px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }

      @media screen and (max-width: 1300px) {
        padding: 17px 5px 15px;
        min-width: inherit;
        display: block;
        width: 100%;
      }
    }
  }

  &.GroupDetailsTab {
    ${TabsContent} {
      padding: 15px 24px;
      @media (max-width: 1199px) {
        padding: 15px;
      }
    }
    ${GroupDetailMembers} {
      @media (max-width: 767px) {
        max-height: calc(100vh - 190px);
        overflow-y: auto;
        padding-right: 10px;
        margin-top: 15px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar:horizontal {
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #cecece;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #e9e9e9;
        }
      }
    }
    ${GroupSaveGallery} {
      @media (max-width: 767px) {
        max-height: calc(100vh - 190px);
        overflow-y: auto;
        padding-right: 10px;
        margin-top: 15px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar:horizontal {
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #cecece;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #e9e9e9;
        }
      }
    }
  }
  &.chat-list-tab {
    @media (max-width: 767px) {
      ${TabsContent} {
        max-height: calc(100vh - 140px);
        overflow-y: auto;
        padding: 15px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar:horizontal {
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #cecece;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #e9e9e9;
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 15px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.deleteUserWrap {
    text-align: center;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      color: #4d27da;
      margin-bottom: 20px;
    }
    .modal-body {
      p {
        margin-bottom: 0;
      }
      .btn {
        min-width: 134px;
        + .btn {
          margin-left: 15px;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const PlanChatsGroupsList = styled.div`
  &:empty {
    display: none;
  }
`;

export const PlanChatsGroups = styled.div`
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-bottom: 18px;
  margin: 0 0 20px;
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #8d8d8d;
    margin: 0 0 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0 45px 0 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    position: relative;
    span {
      padding-left: 22px;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #3b3b3b;
    }
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-right: -16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 40px;
        vertical-align: middle;
      }
      .ChatsTotalMember {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const PlanChatsGroupsInner = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ChatsTime = styled.span`
  font-family: "Poppins";
  color: #8d8d8d;
  font-weight: normal;
  font-size: 9px;
  line-height: 18px;
  margin-left: auto;
`;

export const PlanChatsCounter = styled.div`
  margin: 0;
  background: #6840df;
  border-radius: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  display: inline-block;
  min-width: 35px;
  text-align: center;
  padding: 2px 5px;
  &:empty {
    display: none;
  }
`;

export const PlanChats = styled.div`
  margin: 0 0 28px;
  padding-bottom: 28px;
  border-bottom: 1px solid #cecece;
  h3 {
    font-weight: 700;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
    ${PlanChatsGroups} {
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        ${PlanChatsCounter} {
          bottom: 2px;
        }
      }
    }
  }

  .seeMore {
    border: 1px solid #3b3b3b;
    border-radius: 3px;
    background: transparent;
    padding: 3px 13px;
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    min-height: inherit;
    transition: all 0.35s ease 0s;
    box-shadow: none !important;
    &:hover {
      background: #3b3b3b;
      color: #ffffff;
      border-color: #3b3b3b;
    }
  }

  .noMsgTxt {
    margin-bottom: 0;
    color: #a1a3b0;
  }
`;

export const GroupsSingleChat = styled.div`
  margin: 0 0 27px;
  justify-content: space-between;
  display: flex;
`;

export const GroupsSingleChatImage = styled.div`
  padding-right: 15px;
  align-items: center;
  display: flex;
  img {
    width: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
    @media (max-width: 1199px) {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }
  h4 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0;
    padding: 0 0 0 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const GroupsSingleChatInfo = styled.div`
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  ${PlanChatsCounter} {
    position: relative;
    bottom: auto;
    right: auto;
    margin-left: auto;
  }
`;

export const GroupChatWrap = styled.div`
  .chatMessageList {
    overflow-y: auto;
    margin: 0 -12px;
    padding: 0 12px;
    height: calc(100vh - 370px);
    list-style-type: none;
    @media (max-width: 767px) {
      height: calc(100vh - 310px);
    }
    > li {
      margin: 0 0 17px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
  }
`;

export const GroupChatTitle = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #3b3b3b;
  position: relative;
  text-align: center;
  padding: 0 40px;
  margin-bottom: 13px;
  @media (max-width: 1199px) {
    padding: 0 20px;
  }
  .btn {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    font-size: 10px;
    line-height: 14px;
    cursor: pointer;
    background: transparent;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
  }
  .back-btn {
    position: absolute;
    left: 0;
    top: 3px;
    &:hover {
      path {
        stroke: #4d27da;
      }
    }
  }
  .addFriendsBtn {
    border-radius: 50px;
    position: absolute;
    top: -8px;
    right: 0;
    width: 36px;
    height: 36px;
    @media (max-width: 1199px) {
      width: 28px;
      height: 28px;
      top: -3px;
    }
    svg {
      height: 100%;
      width: 100%;
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
  &.groupSaveTitle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

export const GroupChatDate = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-bottom: 1px solid #cecece;
    height: 1px;
  }
  span {
    background: #fff;
    position: relative;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const GroupChatHead = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0 0 8px;
`;

export const GroupChatBody = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const ChatUserName = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #4d27da;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }
`;

export const ChatTime = styled.div`
  font-family: "Poppins";
  font-size: 10px;
  line-height: 18px;
  font-weight: 400;
  color: #8d8d8d;
`;

export const ChatUserImage = styled.div`
  margin-right: 15px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50px;
  @media (max-width: 1199px) {
    margin-right: 6px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    vertical-align: middle;
    object-fit: cover;
    object-position: top;
  }
`;

export const ChatUserMessage = styled.div`
  flex: 1;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #3b3b3b;
  position: relative;
  background: #f5f5f5;
  padding: 7px 15px;
  border-radius: 0 5px 5px 5px;
  min-height: 36px;
  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 5px 7px 7px 7px;
    border-color: #f5f5f5 #f5f5f5 transparent transparent;
    left: -10px;
    top: 0;
  }
  a {
    color: #4d27da;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #000;
    }
  }
  p {
    margin: 0;
  }
`;

export const ChatBodyAttachment = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;
  vertical-align: middle;
  &:first-child {
    margin-top: 0;
  }
  img {
    max-width: 100%;
    width: 110px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    vertical-align: middle;
  }
  .dropdown {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 12px;
    line-height: 12px;
    .dropdown-toggle {
      border: none;
      padding: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      border-radius: 30px;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      svg {
        vertical-align: middle;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &::after {
        display: none;
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      // top: 38px !important;
      left: auto !important;
      right: -15px !important;
      margin: 19px auto 10px;
      // transform: none !important;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
  }
`;

export const GroupChatItem = styled.div`
  word-break: break-word;
  &.userSendChat {
    ${GroupChatBody} {
      flex-direction: row-reverse;
    }
    ${ChatUserImage} {
      margin: 0 0 0 15px;
      @media (max-width: 1199px) {
        margin: 0 0 0 6px;
      }
    }
    ${ChatUserMessage} {
      &:before {
        border-color: #f5f5f5 transparent transparent #f5f5f5;
        left: auto;
        right: -10px;
      }
    }
  }
`;

export const ChatMessageAction = styled.div`
  margin-top: 20px;
  .form-label {
    display: block;
  }
  @media (max-width: 767px) {
    margin-top: 10px;
  }
  ${FormGroup} {
    margin-bottom: 10px;
    .form-control {
      background: #f5f5f5;
      border: none;
      height: 63px;
      resize: none;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #6840df;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
      }
      @media (max-width: 767px) {
        height: 30px;
        padding: 4px 8px;
      }
    }
  }
`;

export const ChatMessageSendBtn = styled.div`
  text-align: right;
  .btn {
    display: inline-block;
    vertical-align: middle;
    background: #4d27da;
    border: none;
    color: #fff;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    padding: 9px 15px;
    border-radius: 5px;
    min-width: 80px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    box-shadow: none;
    &:hover {
      background: #000;
      color: #fff;
    }
    @media (max-width: 767px) {
      padding: 5px 15px;
    }
  }
  .planDetailsBtn {
    margin-right: 4px;
    background: #4d27da;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    svg {
      margin-right: 8px;
      margin-top: -2px;
    }
  }
`;

export const GroupDetailsShortby = styled.div`
  text-align: center;
  margin-bottom: 20px;
  .btn {
    background: transparent;
    border-radius: 0;
    border: none;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #3b3b3b;
    padding: 0;
    box-shadow: none !important;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    svg {
      margin-left: 10px;
      vertical-align: middle;
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      color: #6840df;
      path {
        stroke: #6840df;
      }
    }
  }
`;

export const GroupSaveGalleryItem = styled.div`
  position: relative;
  padding-bottom: 80.75%;
  overflow: hidden;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  button {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    padding: 0;
    border-radius: 30px;
    font-size: 0;
    line-height: 0;
    border: none;
    box-shadow: none !important;
    cursor: pointer;
    outline: none;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #f9328d;
      }
      path {
        fill: #fff;
      }
    }
  }
`;

export const ModalAddBtn = styled.div`
  text-align: center;
  .btn {
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: transparent;
    color: #3b3b3b;
    padding: 0;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
`;
export const SearchListLoader = styled.div`
  text-align: center;
  padding: 5px 0 15px;
  img {
    width: 36px;
    height: 36px;
    vertical-align: middle;
  }
`;
