import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Routing from "./routes/index";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "./redux/action/signin/user";
import { ToastContainer, toast, collapseToast } from "react-toastify";
//import { getMessaging, onMessage  } from 'firebase/messaging';
//import { initializeApp } from 'firebase/app';
// declare global {
//   interface Window {
//     // ⚠️ notice that "Window" is capitalized here
//     getToaster: any;
//   }
// }

if ('serviceWorker' in navigator) {
navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

function App(props: any) {
  const updateData = useSelector((state: any) => state?.updateUser);

  const dispatch = useDispatch();

/*const firebaseConfig = {
  apiKey: "AIzaSyDpOMn2csIskPypLREKQwITsEKFDCIhymg",
  authDomain: "testtripd.firebaseapp.com",
  projectId: "testtripd",
  storageBucket: "testtripd.appspot.com",
  messagingSenderId: "709334481936",
  appId: "1:709334481936:web:d7825272dc580969131e95",
  measurementId: "G-ZS2W0931GV"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);*/

useEffect(() => {
    /*onMessage(messaging, (payload) => {
      // Handle the FCM message payload
      console.log('FCM Message Payload:', payload);

      
        // Perform the redirect without reloading the entire page
        window.location.href = "https://tripdapp.jdinfotech.net/invites";
    
    });*/
	/*onMessage(messaging, (payload:any) => {
   const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,       
    };
        // If it's okay let's create a notification
        var notification = new Notification(notificationTitle,notificationOptions);
        notification.onclick = function(event) {
            event.preventDefault();
            window.open(payload.notification.click_action , '_blank');
			window.location.href = payload.notification.click_action;
            notification.close();
        }
  
   
});*/
  }, []);

  // const toaster = () => {
  //   toast.error("Token Expire");
  // };

  // useEffect(() => {
  //   if (window) {
  //     window.getToaster = function () {
  //       toast.error("Token Expire");
  //     };
  //   }
  // }, []);

  return (
    <div className="App">
      <Routing user={props?.user?.data} logout={props.logout} />
      {/* <ToastContainer autoClose={5000} /> */}
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
