import { getAllHotels } from "../../action/getAllHotel/getAllHotels";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getAllHotels.GET_ALL_HOTELS: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case getAllHotels.GET_ALL_HOTELS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case getAllHotels.GET_ALL_HOTELS_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }

    case "RESET_HOTEL_LIST": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
