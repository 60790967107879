import React, { useState, useEffect } from "react";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CalendarIcon from "../../assets/images/calendar-icon.svg";
import moment from "moment";
import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
  CheckBox,
} from "../anotherDayModal/anotherDayModalStyle";
import { manageVenuePlanDetailsHandler } from "../../redux/action/manageVenuePlan/manageVenuePlan";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { mapKey } from "../../resource/constants";

const AnotherDayModal = (props: any) => {
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  const [daysError, setDaysError] = useState("");
  const [finalData, setFinalData] = useState<any[]>([]);
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  useEffect(() => {
    if (props?.DayData) {
      setDaysError("");
      const data = props?.DayData?.map((item: any) => {
        return {
          ...item,
          checked: false,
          startTime: "",
          endTime: "",
          error: "",
          startTimeError: "",
          endTimeError: "",
        };
      });
      setFinalData(data);
    }
  }, [props]);

  const handleChangeStartTime = (e: any, j: any, k: any) => {
    const cur1 = moment(moment().format("LT"), "h:mm:ss A").format("HH:mm:ss");
    const cur2 = moment(e, "h:mm:ss A").format("HH:mm:ss");
    if (moment(k).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      if (cur1 < cur2) {
        const final = finalData.map((item: any, index: any) => {
          return j === index
            ? {
                ...item,
                startTime: e,
                endTime: "",
                startTimeError: "",
                error: "",
              }
            : item;
        });
        setFinalData(final);
      } else {
        const final = finalData.map((item: any, index: any) => {
          return j === index
            ? {
                ...item,
                startTime: "",
                endTime: "",
                error: "please select start time is greater than currentime",
              }
            : item;
        });
        setFinalData(final);
      }
    } else if (
      moment(k).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
    ) {
      const final = finalData.map((item: any, index: any) => {
        return j === index
          ? {
              ...item,
              startTime: e,
              endTime: "",
              startTimeError: "",
              error: "",
            }
          : item;
      });
      setFinalData(final);
    }
  };

  const endTimeCompare = (e: any, j: any) => {
    if (e !== "") {
      let starHr1: any = moment(e, ["h:mm A"]).format("HH");
      let endHr1: any = moment(e, ["h:mm A"]).format("mm");
      let starHr2: any = moment(j, ["h:mm A"]).format("HH");
      let endHr2: any = moment(j, ["h:mm A"]).format("mm");
      let start1: any = starHr1 * 3600 + endHr1 * 60;
      let start2: any = starHr2 * 3600 + endHr2 * 60;
      let start: any = start2 - start1;
      if (start >= 1800) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const handleChangeEndTime = (e: any, j: any, k: any) => {
    const cur1 = moment(moment().format("LT"), "h:mm:ss A").format("HH:mm:ss");
    const cur2 = moment(e, "h:mm:ss A").format("HH:mm:ss");
    if (moment(k).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      if (cur1 < cur2) {
        const final = finalData.map((item: any, index: any) => {
          return j === index
            ? {
                ...item,
                endTime: endTimeCompare(item.startTime, e) ? e : "",
                error:
                  item.startTime === ""
                    ? ""
                    : endTimeCompare(item.startTime, e)
                    ? ""
                    : "Day endtime will be 30 minutes greater than starttime",
                endTimeError: "",
                // startTimeError:
                //   item.startTime === "" ? "StartTime Will Required" : "",
              }
            : item;
        });
        setDaysError("");
        setFinalData(final);
      } else {
        const final = finalData.map((item: any, index: any) => {
          return j === index
            ? {
                ...item,
                endTime: "",
                error: "Please select end time is greater than current time",
              }
            : item;
        });
        setFinalData(final);
      }
    } else if (
      moment(k).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
    ) {
      const final = finalData.map((item: any, index: any) => {
        return j === index
          ? {
              ...item,
              endTime: endTimeCompare(item.startTime, e) ? e : "",
              error:
                item.startTime === ""
                  ? ""
                  : endTimeCompare(item.startTime, e)
                  ? ""
                  : "Day endtime will be 30 minutes greater than starttime",
              endTimeError: "",
              // startTimeError:
              //   item.startTime === "" ? "StartTime Will Required" : "",
            }
          : item;
      });
      setDaysError("");
      setFinalData(final);
    }
  };
  const handleChangeCheck = (e: any) => {
    const final = finalData.map((item: any, index: any) => {
      return e === index
        ? {
            ...item,
            checked: !item?.checked,
            startTimeError: "",
            endTimeError: "",
            startTime: "",
            endTime: "",
            error: "",
          }
        : item;
    });
    setDaysError("");
    setFinalData(final);
  };

  const handleSubmit = () => {
    const venueData = [] as any;
    // var finaldatas: any = finalData.map((item: any) => {
    //   return item.checked === true
    //     ? {
    //         ...item,
    //         startTimeError:
    //           item.startTime === "" ? "Please select starttime" : "",
    //         endTimeError: item.endTime === "" ? "Please select endtime" : "",
    //       }
    //     : item;
    // });
    // setFinalData(finaldatas);

    for (var i = 0; i < finalData.length; i++) {
      if (
        finalData[i].checked === true &&
        finalData[i].error === "" &&
        finalData[i].startTimeError === "" &&
        finalData[i].endTimeError === ""
      ) {
        venueData.push({
          startTime:
            finalData[i].startTime !== "" ? finalData[i].startTime : null,
          endTime: finalData[i].endTime !== "" ? finalData[i].endTime : null,
          day_id: finalData[i]._id,
        });
      }
    }

    const checkedData = finalData.filter((item: any) => {
      return item.checked === true;
    });
    if (venueData.length > 0) {
      if (venueData.length === checkedData.length) {
        if (
          props?.finalEvent === "point of interest" ||
          props?.finalEvent === "food and drink" ||
          props?.finalEvent === "hiking"
        ) {
          const location: any = props?.interestData?.plus_code
            ? props?.interestData?.plus_code?.compound_code.split(" ")
            : "";
          const finalResult = {
            plan_id: props?.PlanId,
            venue_id: props?.VenueId,
            days: venueData,
            venue: props?.finalEvent,
            name: props?.interestData?.name,
            photo: props?.interestData?.photos
              ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${props?.interestData?.photos[0]?.photo_reference}&key=${mapKey}`
              : "",
            city: location.length > 0 ? location[1].slice(0, -1) : "",
            province: location.length > 0 ? location[2].slice(0, -1) : "",
            item: props?.interestData,
          };
          props.onHide();
          dispatch(manageVenuePlanDetailsHandler(finalResult));
          navigate(`/viewplandetails/${props?.PlanId}`);
        } else {
          const finalResult = {
            plan_id: props?.PlanId,
            venue_id: props?.VenueId,
            days: venueData,
            venue: props?.finalEvent,
          };
          props.onHide();
          dispatch(manageVenuePlanDetailsHandler(finalResult));
          navigate(`/viewplandetails/${props?.PlanId}`);
        }
      }
    } else {
      if (
        moment(finalData[finalData.length - 1].date).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD")
      ) {
        if (checkedData.length == 0) {
          setDaysError("Please select atleast one day.");
        }
        if (finalData.length < 1) {
          setDaysError("Please add new day.");
        }
      } else {
        // props.onHide();
        setDaysError("Please add new day.");
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="anotherDayModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .anotherDayModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 403px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .anotherDayModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton onClick={props.onHide}></Modal.Header>
          <Modal.Body>
            <h2>ADD DAY TO ANOTHER DAY</h2>
            <p>Add the same place to another day</p>

            <ul>
              {finalData?.map((datas: any, index: any) => {
                return (
                  <>
                    {moment(datas.date).format("YYYY-MM-DD") >=
                    moment().format("YYYY-MM-DD") ? (
                      <li>
                        <CheckBox>
                          <input
                            type="checkbox"
                            value={datas?.checked}
                            name="check"
                            onClick={() => {
                              handleChangeCheck(index);
                            }}
                          />
                          <span />
                          Day {index + 1}
                        </CheckBox>

                        {datas?.checked ? (
                          <Row>
                            <Col xs={6}>
                              {/* <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>
                          <Form.Control
                            type="text"
                            placeholder="Start Time"
                            name="startTime"
                            value={datas?.startTime}
                            onChange={(e) => {
                              handleChangeStartTime(e, index);
                            }}
                          />
                        </FormGroup> */}
                              <FormGroup className="dateInputGroup">
                                <i className="calendar-icon">
                                  <img src={CalendarIcon} alt="calendar-icon" />
                                </i>
                                <DatePicker
                                  onChange={(e) => {
                                    handleChangeStartTime(
                                      moment(e).format("LT"),
                                      index,
                                      datas.date
                                    );
                                  }}
                                  value={datas?.startTime}
                                  placeholderText={"Start Time"}
                                  className="form-control"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  dateFormat="LT"
                                  timeCaption="Time"
                                />
                              </FormGroup>
                              {datas.startTimeError !== "" ? (
                                <label className="error">
                                  {datas.startTimeError}
                                </label>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col xs={6}>
                              {/* <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>
                          <Form.Control
                            type="text"
                            placeholder="End Time"
                            value={datas?.endTime}
                            onChange={(e) => {
                              handleChangeEndTime(e, index);
                            }}
                          />
                        </FormGroup> */}
                              <FormGroup className="dateInputGroup">
                                <i className="calendar-icon">
                                  <img src={CalendarIcon} alt="calendar-icon" />
                                </i>
                                <DatePicker
                                  onChange={(e) => {
                                    handleChangeEndTime(
                                      moment(e).format("LT"),
                                      index,
                                      datas.date
                                    );
                                  }}
                                  value={datas?.endTime}
                                  placeholderText={"End Time"}
                                  className="form-control"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  dateFormat="LT"
                                  timeCaption="Time"
                                />
                              </FormGroup>
                              {datas.endTimeError !== "" ? (
                                <label className="error">
                                  {datas.endTimeError}
                                </label>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {datas?.error !== "" ? (
                          <label className="error">{datas?.error}</label>
                        ) : (
                          ""
                        )}

                        {/* <label className="error">
                      {datas?.error}
                    </label> */}
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {moment(finalData[finalData.length - 1]?.date).format(
                "YYYY-MM-DD"
              ) < moment().format("YYYY-MM-DD") ? (
                <div style={{ fontSize: "16px", marginTop: "40px" }}>
                  Please Add New Day.
                </div>
              ) : (
                ""
              )}
            </ul>
            {daysError !== "" ? (
              <label className="error">{daysError}</label>
            ) : (
              ""
            )}
            {moment(finalData[finalData.length - 1]?.date).format(
              "YYYY-MM-DD"
            ) < moment().format("YYYY-MM-DD") ? (
              ""
            ) : (
              <ModalBodyBtn>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  SUBMIT
                </Button>
              </ModalBodyBtn>
            )}
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AnotherDayModal;
