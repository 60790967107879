import { getOneCollectionAPI } from "../../../services/getOneCollection";

export enum getOneCollectionType {
  GET_ONE_COLLECTION = "GET_ONE_COLLECTION",
  GET_ONE_COLLETION_SUCCESS = "GET_ONE_COLLETION_SUCCESS",
  GET_ONE_COLLECTION_FAILURE = "GET_ONE_COLLECTION_FAILURE",
}

export const getOneCollection = () => (dispatch: any) => {
  dispatch({
    type: getOneCollectionType.GET_ONE_COLLECTION,
  });
};

export const getOneCollectionSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getOneCollectionType.GET_ONE_COLLETION_SUCCESS,
    data,
  });
};

export const getOneCollectionFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getOneCollectionType.GET_ONE_COLLECTION_FAILURE,
    error: msg,
  });
};
export const getOneCollectionHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getOneCollection());
    const result: any = await getOneCollectionAPI(data);
    dispatch(getOneCollectionSuccess(result));
  } catch (e: any) {
    dispatch(getOneCollectionFailure(e?.response?.data));
  }
};
