import styled from "styled-components";

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 32px;
      height: 32px;
      background: #3b3b3b;
      margin: 0;
      border-radius: 30px;
      z-index: 2;
      opacity: 0.32;
      box-shadow: none !important;
      border: none;
      outline: none;
      transition: all 0.35s ease 0s;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #fff;
      }
      &:before {
        width: 18px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 18px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    padding: 5px;
    min-height: 300px;
    @media (max-width:565px){
      min-height: inherit;
    }
    h2 {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 15px;
    }

    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ImageGalleryWrap = styled.div`
  margin: 0;
  .image-gallery-slide {
    border-radius: 15px;
    img {
      border-radius: 15px;
      vertical-align: middle;
      max-height: 100vh !important;
    }
  }
  .image-gallery-content {
    position: relative;
    border-radius: 15px;
  }
  .image-gallery-swipe,
  .image-gallery-slides {
    border-radius: 15px;
  }
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 0;
    bottom: 87px;
    background: rgba(59, 59, 59, 0.48);
    border-radius: 6px;
    width: 32px;
    height: 32px;
    filter: none;
    @media (max-width:565px){
      bottom:auto;
      top:10px;
    }
    svg {
      height: 18px;
      width: 18px;
      stroke: #fff;
      transform: none !important;
    }
    &:hover {
      background: #6840df;
    }
  }
  .image-gallery-fullscreen-button {
    right: 10px;
    @media (max-width:565px){
      right: 50px;
    }
  }
  .image-gallery-play-button {
    left: 10px;
  }
  .image-gallery-thumbnails-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(59, 59, 59, 0.48);
    border-radius: 0 0 15px 15px;
    .image-gallery-thumbnails {
      padding: 10px 0;
      .image-gallery-thumbnail {
        border: none;
        height: 57px;
        width: 57px;
        border-radius: 10px;
        margin-left: 10px;
        background: rgba(104, 64, 223, 0.5);
        &:first-child {
          margin-left: 0;
        }
        .image-gallery-thumbnail-inner {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
          opacity: 0.65;
          transition: all 0.35s ease 0s;
        }
        &.active,
        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0;
    background: rgba(104, 64, 223, 0.5);
    filter: none;
    box-shadow: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    svg {
      height: 20px;
      width: 20px;
      stroke-width: 2px;
      stroke: #fff;
      transform: none !important;
    }
    &:hover {
      background: #6840df;
    }
  }
  .image-gallery-left-nav {
    left: 10px;
  }
  .image-gallery-right-nav {
    right: 10px;
  }
`;
