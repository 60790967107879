import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

function AuthRoute(props: any) {
  let { path, data, component: Component, logout } = props;

  return (
    <Routes>
      <Route
        path={path}
        element={data ? <Navigate to="/dashboard" /> : <Component />}
      ></Route>
    </Routes>
  );
}

export default AuthRoute;
