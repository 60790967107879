import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import FileIcon from "../../../assets/images/file-icon.svg";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsContent,
  MainContentPanelWrap,
  FeedbackWrap,
  FeedbackUserId,
  DropZone,
  PageLoader,
  DropZoneWrap,
  DropZoneUploadImage,
} from "./myFeedbackStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";

import { myProfileData } from "../../../redux/action/my-profile/myProfile";

import { addFeedBackData } from "../../../redux/action/add-feedback/addFeedback";

const MyFeedback = (props: any) => {
  const dispatch: any = useDispatch();

  const [image, setImage] = useState<any[]>([]);

  const [showimage, setShowImage] = useState<any>("");

  const [secondShowimg, setSecondShowimg] = useState<any>("");
  const [thirdShowimage, setThirdShowImg] = useState<any>("");

  const [validationFile, setValidationFile] = useState("");
  const [secondimgValidation, setSecondimgValidation] = useState("");
  const [thirdimgValidation, setThirddimgValidation] = useState("");
  const [feedBackToster, setFeedBackToster] = useState(false);

  const [imgone, setImgOne] = useState<any>("");
  const [imgTwo, setImgTwo] = useState<any>("");
  const [imgThree, setImgThree] = useState<any>("");

  var finals: any = [];

  const inputRef: any = useRef(null);
  const imgTwoRef: any = useRef(null);
  const imgThreeRef: any = useRef(null);
  const submitForm = (values: any, { resetForm }: any) => {
    let formData: any = new FormData();

    // let finalImage = {
    //   file: showimage,
    // };
    // let finalImageone = { file: secondShowimg };
    // let finalImagetwo = { file: thirdShowimage };

    // image.push(finalImage);
    // image.push(finalImageone);
    // image.push(finalImagetwo);
    finals.push(showimage, secondShowimg, thirdShowimage);
    formData.append("message", values.message.trim());

    formData.append("file", showimage);
    formData.append("file", secondShowimg);
    formData.append("file", thirdShowimage);
    if (!(validationFile || secondimgValidation || thirdimgValidation)) {
      dispatch(addFeedBackData(formData));
      setFeedBackToster(true);
      resetForm(
        { values: "" },
        setImgOne(""),
        setShowImage(""),
        setSecondShowimg(""),
        setImgTwo(""),
        setThirdShowImg(""),
        setImgThree("")
      );
    }
    inputRef.current.value = null;
    imgTwoRef.current.value = null;
    imgThreeRef.current.value = null;
  };

  const imageChange = async (e: any, id: any) => {
    const file = e.target.files[0];
    const base64: any = await convertBase64(file);
    const filename = file.name.split(".").pop().toLowerCase();

    if (id === 1) {
      setValidationFile("");
      if (file.size <= 1000000) {
        setShowImage(e.target.files[0]);
        if (filename === "jpeg" || filename === "jpg" || filename === "png") {
          setShowImage(e.target.files[0]);
        } else {
          setValidationFile("Please upload JPG,PNG,JPEG files");
        }
      } else {
        setValidationFile(
          "Image size is greater then 1MB. Please upload image below 1 MB"
        );
      }
    }

    if (id === 2) {
      setSecondimgValidation("");
      if (file.size <= 1000000) {
        setSecondShowimg(e.target.files[0]);
        if (filename === "jpeg" || filename === "jpg" || filename === "png") {
          setSecondShowimg(e.target.files[0]);
        } else {
          setSecondimgValidation("Please upload JPG,PNG,JPEG files");
        }
      } else {
        setSecondimgValidation(
          "Image size is greater then 1MB. Please upload image below 1 MB"
        );
      }
    }
    if (id === 3) {
      setThirddimgValidation("");
      if (file.size <= 1000000) {
        setThirdShowImg(e.target.files[0]);
        if (filename === "jpeg" || filename === "jpg" || filename === "png") {
          setThirdShowImg(e.target.files[0]);
        } else {
          setThirddimgValidation("Please upload JPG,PNG,JPEG files");
        }
      } else {
        setThirddimgValidation(
          "Image size is greater then 1MB. Please upload image below 1 MB"
        );
      }
    }

    if (id === 1) {
      if (
        (filename === "jpeg" || filename === "jpg" || filename === "png") &&
        file.size <= 1000000
      ) {
        setImgOne(base64);
      }
    }
    if (id === 2) {
      if (
        (filename === "jpeg" || filename === "jpg" || filename === "png") &&
        file.size <= 1000000
      ) {
        setImgTwo(base64);
      }
    }
    if (id === 3) {
      if (
        (filename === "jpeg" || filename === "jpg" || filename === "png") &&
        file.size <= 1000000
      ) {
        setImgThree(base64);
      }
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const loginUserId: any = useSelector((state: any) => state?.loginData);

  const myProfileDataSelect: any = useSelector(
    (state: any) => state?.myProfile
  );
  const addFeedbackLoader: any = useSelector(
    (state: any) => state?.addFeedBack
  );

  const handleReset = () => {
    setImgOne("");
    setImgTwo("");
    setImgThree("");
    // setShowImage("");
    // setSecondShowimg("");
    // setThirdShowImg("");
    // inputRef.current.value = null;
    // imgTwoRef.current.value = null;
    // imgThreeRef.current.value = null;
  };

  const firstImageReset = () => {
    setImgOne("");
  };

  const secondImageReset = () => {
    setImgTwo("");
  };

  const thirdImageReset = () => {
    setImgThree("");
  };

  useEffect(() => {
    if (feedBackToster) {
      addFeedbackLoader?.data !== null
        ? toast.success("Feedback submitted successfully")
        : toast.error(" file not upload .");
      setFeedBackToster(false);
    }
  }, [addFeedbackLoader?.data]);
  const userIdData = loginUserId?.data?.id;

  useEffect(() => {
    dispatch(myProfileData(userIdData));
  }, [loginUserId]);

  const user: any = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <>
      <Header logout={props.logout} />
      {addFeedbackLoader?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback" className="active">
                FEEDBACK
              </Link>
            </ProfileTopMenu>

            <TabsContent>
              <FeedbackWrap>
                <h3 className="invites-title">We value your feedback.</h3>
                <h4 className="subtitle">
                  We love to hear your thoughts, suggestions, concerns or
                  problems with anything so we can improve!
                </h4>

                <FeedbackUserId>{user.user_name}</FeedbackUserId>

                <Formik
                  initialValues={{
                    message: "",
                    file: "",
                  }}
                  validate={(values: any) => {
                    let errors: any = {};
                    // if (!values.file === "jpg" ||!values.file === "png" || !values.file === "jpeg"&&
                    // !values.file.size <= 10000000)
                    //  {
                    //   errors.file =
                    //     "image size is greater then 10MB. Please upload image below 10 MB.";
                    // }
                    if (!values.message) {
                      errors.message = "Please enter message";
                    }
                    return errors;
                  }}
                  onSubmit={submitForm}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    dirty,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        {/* <Form> */}

                        <FormGroup>
                          <Form.Label>Your Message</Form.Label>
                          {/* <Form.Control
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder="Type here..."
                    /> */}
                          <CustomInput
                            inputid="message"
                            inputtype="text"
                            as="textarea"
                            placeholder="Type here..."
                            value={values.message.trimStart()}
                            maxLength={2000}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.message}
                            touched={touched.message}
                          />
                        </FormGroup>
                        <Row>
                          <Col md={4} sm={6}>
                            <FormGroup className="attachment-form">
                              <Form.Label>Attachment</Form.Label>
                              <DropZone>
                                <DropZoneWrap>
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => imageChange(e, 1)}
                                    ref={inputRef}
                                  />

                                  {imgone ? (
                                    <>
                                      <img
                                        src={imgone}
                                        alt="File Icon"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />

                                      <button
                                        className="deletebtn"
                                        onClick={firstImageReset}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3b3b3b"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                      </button>
                                    </>
                                  ) : (
                                    <img src={FileIcon} alt="File Icon" />
                                  )}
                                  {!imgone && (
                                    <span className="upload-text">
                                      Drop file here or{" "}
                                      <Link to="#">Browse</Link>
                                    </span>
                                  )}

                                  {!imgone && (
                                    <span className="support-file">
                                      Support file : JPEG, PNG, Documents Max
                                      File Size 1 MB limited
                                    </span>
                                  )}
                                </DropZoneWrap>
                                <DropZoneUploadImage>
                                  <img src="" alt="" />
                                </DropZoneUploadImage>
                              </DropZone>
                              <label className="error">{validationFile}</label>
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6}>
                            <FormGroup className="attachment-form">
                              <Form.Label>Attachment</Form.Label>
                              <DropZone>
                                <DropZoneWrap>
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => imageChange(e, 2)}
                                    ref={imgTwoRef}
                                  />
                                  {imgTwo ? (
                                    <>
                                      <img
                                        src={imgTwo}
                                        alt="File Icon"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />

                                      <button
                                        className="deletebtn"
                                        onClick={secondImageReset}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3b3b3b"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                      </button>
                                    </>
                                  ) : (
                                    <img src={FileIcon} alt="File Icon" />
                                  )}
                                  {!imgTwo && (
                                    <span className="upload-text">
                                      Drop file here or{" "}
                                      <Link to="#">Browse</Link>
                                    </span>
                                  )}
                                  {!imgTwo && (
                                    <span className="support-file">
                                      Support file : JPEG, PNG, Documents Max
                                      File Size 1 MB limited
                                    </span>
                                  )}
                                </DropZoneWrap>
                                <DropZoneUploadImage>
                                  <img src="" alt="" />
                                </DropZoneUploadImage>
                              </DropZone>
                              <label className="error">
                                {secondimgValidation}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6}>
                            <FormGroup className="attachment-form">
                              <Form.Label>Attachment</Form.Label>
                              <DropZone>
                                <DropZoneWrap>
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => imageChange(e, 3)}
                                    ref={imgThreeRef}
                                  />
                                  {imgThree ? (
                                    <>
                                      <img
                                        src={imgThree}
                                        alt="File Icon"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                      <button
                                        className="deletebtn"
                                        onClick={thirdImageReset}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3b3b3b"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                      </button>
                                    </>
                                  ) : (
                                    <img src={FileIcon} alt="File Icon" />
                                  )}

                                  {!imgThree && (
                                    <span className="upload-text">
                                      Drop file here or{" "}
                                      <Link to="#">Browse</Link>
                                    </span>
                                  )}
                                  {!imgThree && (
                                    <span className="support-file">
                                      Support file : JPEG, PNG, Documents Max
                                      File Size 1 MB limited
                                    </span>
                                  )}
                                </DropZoneWrap>
                                <DropZoneUploadImage>
                                  <img src="" alt="" />
                                </DropZoneUploadImage>
                              </DropZone>
                              <label className="error">
                                {thirdimgValidation}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Button type="submit">SUBMIT</Button>
                        </FormGroup>

                        {/* </Form> */}
                        {/* <ToastContainer autoClose={4000} /> */}
                      </form>
                    </>
                  )}
                </Formik>
              </FeedbackWrap>
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
    </>
  );
};

export default MyFeedback;
