import { deleteMemberApi } from "../../../services/deleteMemberGroup";

export enum DeleteGroupMemberType {
  DELETE_GROUPMEMBER_INFO = "DELETE_GROUPMEMBER_INFO",
  DELETEPLAN_GROUPMEMBER_INFO_SUCCESS = "DELETEPLAN_GROUPMEMBER_INFO_SUCCESS",
  DELETEPLAN_GROUPMEMBER_INFO_FALIURE = "DELETEPLAN_GROUPMEMBER_INFO_FALIURE",
}

export const deleteGroupInfo = () => (dispatch: any) => {
  dispatch({
    type: DeleteGroupMemberType.DELETE_GROUPMEMBER_INFO,
  });
};

export const deleteGroupSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: DeleteGroupMemberType.DELETEPLAN_GROUPMEMBER_INFO_SUCCESS,
    data,
  });
};

export const deleteGroupFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: DeleteGroupMemberType.DELETEPLAN_GROUPMEMBER_INFO_FALIURE,
    data: { msg },
  });
};

export const deleteGroupMember = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(deleteGroupInfo());
    const result: any = await deleteMemberApi(finalData);
    dispatch(deleteGroupSuccess(result));
  } catch (e: any) {
    dispatch(deleteGroupFailed(e.response.data.message));
  }
};
