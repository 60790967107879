import { acceptGroupAPI } from "../../../services/acceptGroup";

export enum AcceptGroupType {
  ACCEPT_GROUP_INFO = "ACCEPT_GROUP_INFO",
  ACCEPT_GROUP_INFO_SUCCESS = "ACCEPT_GROUP_INFO_SUCCESS",
  ACCEPT_GROUP_INFO_FALIURE = "ACCEPT_GROUP_INFO_FALIURE",
}

export const acceptGroupInfo = () => (dispatch: any) => {
  dispatch({
    type: AcceptGroupType.ACCEPT_GROUP_INFO,
  });
};

export const acceptGroupInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AcceptGroupType.ACCEPT_GROUP_INFO_SUCCESS,
    data,
  });
};

export const acceptGroupFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: AcceptGroupType.ACCEPT_GROUP_INFO_FALIURE,
    data: { msg },
  });
};

export const acceptGroupData = (userIdData: any) => async (dispatch: any) => {
  try {
    dispatch(acceptGroupInfo());
    const result: any = await acceptGroupAPI(userIdData);
    dispatch(acceptGroupInfoSuccess(result));
  } catch (e: any) {
    dispatch(acceptGroupFailed(e.message));
  }
};
