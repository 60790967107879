import { globalSearchAPI } from "../../../services/globalSearch";

export enum globalSearchType {
  GLOBAL_SEARCH_INFO = "GLOBAL_SEARCH_INFO",
  GLOBAL_SEARCH_INFO_SUCCESS = "GLOBAL_SEARCH_INFO_SUCCESS",
  GLOBAL_SEARCH_INFO_FALIURE = "GLOBAL_SEARCH_INFO_FALIURE",
}

export const globalSearchInfo = () => (dispatch: any) => {
  dispatch({
    type: globalSearchType.GLOBAL_SEARCH_INFO,
  });
};

export const globalSearchInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: globalSearchType.GLOBAL_SEARCH_INFO_SUCCESS,
    data,
  });
};

export const globalSearchInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: globalSearchType.GLOBAL_SEARCH_INFO_FALIURE,
    error: msg,
  });
};

// export const globalSearchData = (data: any) => async (dispatch: any) => {
//   try {
//     dispatch(globalSearchInfo());
//     const result: any = await globalSearchAPI(data);
//     dispatch(globalSearchInfoSuccess(result));
//   } catch (e: any) {
//     dispatch(globalSearchInfoFailed(e?.response?.data));
//   }
// };
export const globalSearchData =
  (data: any) =>
  async (dispatch: any) => {
    try {
      dispatch(globalSearchInfo());
      const result: any = await globalSearchAPI(data);
      dispatch(globalSearchInfoSuccess(result));
    } catch (e: any) {
      dispatch(globalSearchInfoFailed(e?.response?.data));
    }
  };
