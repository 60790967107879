import { AddInviteGroupType } from "../../action/add-Invite-Group/addInviteGroup";
import { addInviteGroupModel } from "../../models";

export const initState: addInviteGroupModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};
export default (state = initState, action: any) => {
  switch (action.type) {
    case AddInviteGroupType.ADD_INVITE_GROUP_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AddInviteGroupType.ADD_INVITE_GROUP_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case AddInviteGroupType.ADD_INVITE_GROUP_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
