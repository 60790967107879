import { useState } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CreateGroupModal from "../../components/createGroupModal/index";

import { GlobalDataType } from "../../redux/action/globalData";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
} from "../createNewModal/createNewModalStyle";

import CustomInput from "../CustomInput";
import { Formik } from "formik";
import { Group, Hiking } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CreateNewModal = (props: any) => {
  const dispatch = useDispatch();

  const history = useNavigate();

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="createNewModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .createNewModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 284px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .createNewModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>CREATE A NEW</h2>
            <ul>
              <li>
                <Button
                  className="btn"
                  onClick={() => {
                    props.setCreatePlanModalShow(true);
                    props.setCreateNewModalShow(false);
                    props.setAllId([]);
                    props.setInviteFriend([]);
                    props.setInviteFollower([]);
                    props.setInviteEmails([]);
                    props.setGroupMembers([]);
                    props.setPendingEmail([]);
                    props.setImageValidation("");
                    props.setImage("");
                    props.setShowImage("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.901"
                    height="24.905"
                    viewBox="0 0 24.901 24.905"
                  >
                    <path
                      d="M24.206.26A1.572,1.572,0,0,0,22.734.125L16.09,2.974,9.433.125c.01,0,0,.043.011.053A1.544,1.544,0,0,0,8.206.125L.943,3.24A1.56,1.56,0,0,0,0,4.672V23.351a1.558,1.558,0,0,0,1.557,1.557,1.607,1.607,0,0,0,.614-.125l6.644-2.849,6.652,2.849a1.607,1.607,0,0,0,.614.125,1.553,1.553,0,0,0,.607-.183l.006.059,7.263-3.113a1.56,1.56,0,0,0,.943-1.432V1.558a1.549,1.549,0,0,0-.695-1.3ZM3.113,5.7,7.262,3.921V19.215L3.113,20.994Zm7.263-1.783L14.529,5.7V20.994L10.376,19.21ZM21.793,19.211,17.643,20.99V5.7l4.149-1.779Z"
                      transform="translate(0 -0.002)"
                      fill="#4d27da"
                    />
                  </svg>
                  CREATE A PLAN
                </Button>
              </li>
              <li>
                <Button
                  className="btn"
                  onClick={() => {
                    props.setCreateGroupModalShow(true);
                    props.setCreateNewModalShow(false);
                    props.setAllId([]);
                    props.setInviteFriend([]);
                    props.setInviteFollower([]);
                    props.setInviteEmails([]);
                    props.setGroupMembers([]);
                    props.setPendingEmail([]);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.413"
                    height="26.265"
                    viewBox="0 0 27.413 26.265"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect width="27.413" height="26.265" fill="#4d27da" />
                      </clipPath>
                    </defs>
                    <path
                      d="M2.11,2.048H25.3V18.3H13.545l-2.706,2.627V18.3H2.11ZM0,0V20.344H8.607v5.92l6.1-5.92h12.7V0Z"
                      fill="#4d27da"
                    />
                    <g>
                      <g clip-path="url(#clip-path)">
                        <path
                          d="M44.1,75.887a2.39,2.39,0,1,1-2.389-2.318A2.354,2.354,0,0,1,44.1,75.887"
                          transform="translate(-34.717 -65.2)"
                          fill="#4d27da"
                        />
                        <path
                          d="M105.93,75.887a2.39,2.39,0,1,1-2.389-2.318,2.354,2.354,0,0,1,2.389,2.318"
                          transform="translate(-89.296 -65.2)"
                          fill="#4d27da"
                        />
                        <path
                          d="M164.625,75.887a2.39,2.39,0,1,1-2.389-2.318,2.354,2.354,0,0,1,2.389,2.318"
                          transform="translate(-141.111 -65.2)"
                          fill="#4d27da"
                        />
                      </g>
                    </g>
                  </svg>
                  CREATE GROUP CHAT
                </Button>
              </li>
            </ul>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateNewModal;
