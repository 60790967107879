import { searchUserAPI } from "../../../services/searchUser";

export enum SearchUserType {
  SEARCH_USER_INFO = "SEARCH_USER_INFO",
  SEARCH_USER_INFO_SUCCESS = "SEARCH_USER_INFO_SUCCESS",
  SEARCH_USER_INFO_FALIURE = "SEARCH_USER_INFO_FALIURE",
}

export const SearchUserInfo = () => (dispatch: any) => {
  dispatch({
    type: SearchUserType.SEARCH_USER_INFO,
  });
};

export const SearchUserInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SearchUserType.SEARCH_USER_INFO_SUCCESS,
    data,
  });
};

export const SearchUserInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SearchUserType.SEARCH_USER_INFO_FALIURE,
    data: { msg },
  });
};

export const searchUserData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(SearchUserInfo());
    const result: any = await searchUserAPI(data);
    dispatch(SearchUserInfoSuccess(result));
  } catch (e: any) {
    dispatch(SearchUserInfoFailed(e.message));
  }
};
