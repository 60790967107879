import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  padding: 35px 17px 15px 35px;
  border-left: 5px solid #f0f0f0;
  border-right: 5px solid #f0f0f0;
  background: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }

  @media screen and (max-width: 1440px) {
    padding: 25px 17px 15px 17px;
  }

  @media screen and (max-width: 1199px) {
    border-left: none;
    border-right: none;
  }

  @media screen and (max-width: 767px) {
    padding: 35px 15px 15px;
  }
`;

export const TabbingPanel = styled.div`
  width: 20.209%;
  background: #ffffff;
  position: relative;
  transition: all 0.35s ease 0s;
  &.panel-close {
    margin-right: -20.209%;
    .panelToggleBtn {
      left: -25px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .panelToggleBtn {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 30px;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 77px;
    left: -11px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      transition: all 0.35s ease 0s;
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      background: #4d27da;
      @media screen and (max-width: 1199px) {
        background: inherit;
      }
      path {
        stroke: #fff;
        @media screen and (max-width: 1199px) {
          stroke: #4d27da;
        }
      }
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: 1px solid #cecece;
    padding: 0 10px;
    display: flex;
    margin: 0;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 17px 10px 15px;
      min-width: 77px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  &.GroupDetailsTab {
    // .nav-tabs {
    //   .nav-link {
    //     &:before {
    //       display: none;
    //     }
    //   }
    // }
    ${TabsContent} {
      padding: 15px 24px;
    }
  }
`;

export const GroupChatWrap = styled.div`
  .chatMessageList {
    // height: calc(100vh - 380px);
    overflow-y: auto;
    margin: 0 -12px;
    padding: 0;
    list-style-type: none;
    > li {
      margin: 0 0 17px;
      padding: 0 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
  }
`;

export const GroupChatTitle = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #3b3b3b;
  position: relative;
  text-align: center;
  padding: 0 40px;
  margin-bottom: 13px;
  .btn {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    font-size: 10px;
    line-height: 14px;
    cursor: pointer;
    background: transparent;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
  }
  .back-btn {
    position: absolute;
    left: 0;
    top: 3px;
    &:hover {
      path {
        stroke: #4d27da;
      }
    }
  }
  .addFriendsBtn {
    border-radius: 50px;
    position: absolute;
    top: -8px;
    right: 0;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    svg {
      height: 100%;
      width: 100%;
      position: relative;
      top: auto;
      right: auto;
      margin-left: 15px;
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
  &.groupSaveTitle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

export const GroupChatDate = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-bottom: 1px solid #cecece;
    height: 1px;
  }
  span {
    background: #fff;
    position: relative;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const GroupChatHead = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0 0 8px;
`;

export const GroupChatBody = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const ChatUserName = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #4d27da;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }
`;

export const ChatTime = styled.div`
  font-family: "Poppins";
  font-size: 10px;
  line-height: 18px;
  font-weight: 400;
  color: #8d8d8d;
`;

export const ChatUserImage = styled.div`
  margin-right: 15px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    vertical-align: middle;
    object-fit: cover;
    object-position: top;
  }
`;

export const ChatUserMessage = styled.div`
  flex: 1;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #3b3b3b;
  position: relative;
  background: #f5f5f5;
  padding: 7px 15px;
  border-radius: 0 5px 5px 5px;
  min-height: 36px;
  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 5px 7px 7px 7px;
    border-color: #f5f5f5 #f5f5f5 transparent transparent;
    left: -10px;
    top: 0;
  }
  a {
    color: #4d27da;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #000;
    }
  }
  p {
    margin: 0;
  }
`;

export const ChatBodyAttachment = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;
  vertical-align: middle;
  &:first-child {
    margin-top: 0;
  }
  img {
    max-width: 100%;
    width: 110px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    vertical-align: middle;
  }
  .dropdown {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 12px;
    line-height: 12px;
    .dropdown-toggle {
      border: none;
      padding: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      border-radius: 30px;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      svg {
        vertical-align: middle;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &::after {
        display: none;
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      // top: 38px !important;
      left: auto !important;
      right: -15px !important;
      margin: 19px auto 10px;
      // transform: none !important;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:first-child {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
  }
`;

export const GroupChatItem = styled.div`
  word-break: break-word;
  &.userSendChat {
    ${GroupChatBody} {
      flex-direction: row-reverse;
    }
    ${ChatUserImage} {
      margin: 0 0 0 15px;
    }
    ${ChatUserMessage} {
      &:before {
        border-color: #f5f5f5 transparent transparent #f5f5f5;
        left: auto;
        right: -10px;
      }
    }
  }
`;

export const ChatMessageAction = styled.div`
  margin-top: 20px;
  .form-label {
    display: block;
  }
  ${FormGroup} {
    margin-bottom: 10px;
    .form-control {
      background: #f5f5f5;
      border: none;
      height: 63px;
      resize: none;
    }
  }
`;

export const ChatMessageSendBtn = styled.div`
  text-align: right;
  .btn {
    display: inline-block;
    vertical-align: middle;
    background: #a7aabf;
    border: none;
    color: #fff;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    padding: 9px 15px;
    border-radius: 5px;
    min-width: 80px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    box-shadow: none;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  .planDetailsBtn {
    margin-right: 4px;
    background: #4d27da;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    svg {
      margin-right: 13px;
      margin-top: -2px;
    }
  }
`;

export const PlanDetailsTitle = styled.div`
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 233px;
  @media screen and (max-width: 1600px) {
    margin-bottom: 25px;
    padding: 0 233px 0 100px;
  }
  @media screen and (max-width: 1199px) {
    padding: 0 150px 0 80px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 150px 0 0px;
  }
  @media screen and (max-width: 480px) {
    padding: 0;
    flex-wrap: wrap;
  }
  h2 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 33px;
    font-weight: 500;
    color: #4d27da;
    position: relative;
    padding-right: 10px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media screen and (max-width: 480px) {
      flex: 100%;
      padding-right: 80px;
      font-size: 17px;
      line-height: 22px;
    }
    .btn {
      border: none;
      padding: 0;
      border-radius: 0;
      font-family: "Poppins";
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
      color: #8d8d8d;
      background: transparent;
      position: absolute;
      left: 100%;
      top: 3px;
      white-space: nowrap;
      display: flex;
      align-items: flex-end;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      transition: all 0.35s ease 0s;
      @media screen and (max-width: 480px) {
        left: auto;
        right: 0;
      }
      svg {
        margin-right: 10px;
        path {
          transition: all 0.35s ease 0s;
        }
        @media screen and (max-width: 767px) {
          width: 18px;
        }
      }
      &:hover {
        color: #4d27da;
        path {
          fill: #4d27da;
        }
      }
    }
  }
  .addFriendsBtn {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    padding: 0 63px 0 0;
    border-radius: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    background: transparent;
    outline: none;
    box-shadow: none !important;
    cursor: pointer;
    margin: 8px 0;
    align-items: center;
    display: flex;
    transition: all 0.35s ease 0s;
    @media screen and (max-width: 480px) {
      position: static;
      width: 100%;
      justify-content: flex-end;
      flex-flow: row-reverse;
    }
    svg {
      margin-left: 0;
      position: absolute;
      right: 0;
      top: -15px;
      @media screen and (max-width: 480px) {
        margin-right: 15px;
        position: static;
      }
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
      @media screen and (max-width: 767px) {
        width: 38px;
      }
    }
    &:hover {
      color: #000;
      circle {
        fill: #000;
      }
    }
  }
`;

export const PlanDetailsWrap = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  .react-datepicker-popper {
    line-height: 10px;
    z-index: 9;
    &[data-placement^="bottom"] {
      padding-top: 5px;
    }
    &[data-placement^="top"] {
      padding-bottom: 5px;
    }
  }

  .react-datepicker {
    border-radius: 8px;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    border: 1px solid #efdeff;
    padding: 17px 15px 15px;
    width: 281px;
    color: #505887;
    font-family: "Poppins";
    font-size: 13px;
    line-height: 20px;

    &.react-datepicker--time-only {
      width: auto;
      padding: 15px 10px;
      .react-datepicker__time-box {
        border-radius: 0;
      }
      ul {
        &.react-datepicker__time-list {
          overflow-y: auto;
          border: none;
          padding: 0;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar:horizontal {
            height: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #cecece;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            cursor: pointer;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #e9e9e9;
          }

          li {
            &.react-datepicker__time-list-item {
              cursor: pointer;
              font-size: 13px;
              line-height: 20px;
              height: auto;
              font-weight: 500;
              color: #505887;
              margin-bottom: 1px;
              min-width: inherit;
              border: none;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover,
              &.react-datepicker__time-list-item--selected {
                color: #6840df;
              }
            }
          }
        }
      }
      .react-datepicker__header {
        .react-datepicker-time__header {
          margin-bottom: 0;
          padding-bottom: 11px;
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation--previous {
      left: 7px;
      .react-datepicker__navigation-icon--previous {
        &:before {
          left: -4px;
          right: auto;
        }
      }
    }

    .react-datepicker__navigation--next {
      right: 8px;
      .react-datepicker__navigation-icon--next {
        &:before {
          right: -4px;
          left: auto;
        }
      }
    }

    .react-datepicker__navigation-icon {
      padding: 0;
      &:before {
        border-width: 2px 2px 0 0;
        border-color: #3b3b3b;
        height: 8px;
        width: 8px;
        top: 12px;
      }
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__header {
      background-color: #ffffff;
      border-bottom: none;
      padding: 0 0 10px;
      .react-datepicker__current-month,
      .react-datepicker-year-header,
      .react-datepicker-time__header {
        color: #1c295d;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        border-bottom: 1px solid rgba(23, 3, 89, 0.1);
        padding-bottom: 13px;
        margin-bottom: 13px;
        margin-top: -2px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #505887;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .react-datepicker__header__dropdown {
      color: #505887;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      margin-bottom: 13px;
      margin-top: -2px;
      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow,
      .react-datepicker__navigation-icon::before {
        right: -18px;
        top: 3px;
        border-width: 2px 2px 0 0;
        border-color: #3b3b3b;
        height: 8px;
        width: 8px;
      }
      .react-datepicker__year-dropdown,
      .react-datepicker__month-dropdown,
      .react-datepicker__month-year-dropdown {
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border: 1px solid #efdeff;
        padding: 5px 0;
        width: 140px;
        left: 22.5%;
        .react-datepicker__year-option,
        .react-datepicker__month-option,
        .react-datepicker__month-year-option {
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
          padding: 4px 5px;
          transition: all 0.25s ease 0s;
          margin-bottom: 1px;
          &:hover {
            color: #6c53e4;
            background-color: rgba(108, 83, 228, 0.12);
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:first-of-type {
            border-radius: 0;
          }
          &:last-of-type {
            border-radius: 0;
          }
          &.react-datepicker__month-option--selected_month {
            color: #6c53e4;
            background-color: rgba(108, 83, 228, 0.12);
            font-weight: 600;
          }
        }
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 3px 4px;
      height: 27px;
      width: 27px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 24px;
      font-weight: normal;
      color: #1c295d;
      border-radius: 5px;
      border: 2px solid transparent;
      transition: all 0.35s ease 0s;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 5px;
      border: 2px solid #6c53e4 !important;
      background-color: #6c53e4 !important;
      color: #fff !important;
      font-weight: 500;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 5px;
      border: 2px solid transparent;
      background: rgba(108, 83, 228, 0.12);
      color: #6c53e4;
      font-weight: 500;
    }

    .react-datepicker__day--outside-month {
      color: #8d8d8d;
    }

    .react-datepicker__day--disabled {
      color: #8d8d8d;
      pointer-events: none;
    }
  }
`;

export const PlanDetailsLeft = styled.div`
  width: 65.57%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const PlanDetailsRight = styled.div`
  flex: 1;
  padding-left: 50px;
  padding-top: 22px;
  @media screen and (max-width: 1440px) {
    padding-left: 35px;
  }
  @media screen and (max-width: 991px) {
    padding-left: 30px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 0;
  }
`;

export const PlanDetailsUser = styled.div`
  display: flex;
  margin-bottom: 22px;
`;

export const PlanDetailsUserImage = styled.div`
  width: 96px;
  min-width: 96px;
  @media screen and (max-width: 767px) {
    width: 68px;
    min-width: 68px;
  }
  a {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100px;
  }
  img {
    max-width: 100%;
    border-radius: 100px;
    height: 96px;
    width: 96px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      height: 68px;
      width: 68px;
    }
  }
`;

export const PlanDetailsUserInfo = styled.div`
  flex: 1;
  padding-left: 25px;
  padding-top: 18px;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: 14px;
    }
  }
`;

export const PlanDetailsUserTopInfo = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0 0 17px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  h3 {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    color: #3b3b3b;
    margin: 0;
    align-items: center;
    display: flex;
    @media screen and (max-width: 767px) {
      justify-content: space-between;
      flex: 100%;
    }
  }
  .btn {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(249, 50, 141, 0.7);
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #f9328d;
    }
    @media screen and (max-width: 767px) {
      flex: 100%;
      text-align: right;
      margin-top: 8px;
    }
  }
`;

export const CreatorBtn = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #4d27da;
  display: inline-block;
  vertical-align: middle;
  padding: 4px 7px 3px;
  border-radius: 30px;
  min-width: 70px;
  border: 1px solid #4d27da;
  text-align: center;
  margin-left: 20px;
`;

export const PlanDetailsNote = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  font-style: italic;
  color: #3b3b3b;
  margin: 0 0 5px;
  align-items: center;
  display: flex;
  svg {
    margin-right: 8px;
  }
`;

export const PlanDetailsDes = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:empty {
    display: none;
  }
`;

export const PlanDetailsInfoAction = styled.div`
  margin-top: 14px;
  justify-content: flex-end;
  display: flex;

  .btn {
    background: #4d27da;
    border-radius: 8px;
    padding: 7px 17px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border: none;
    box-shadow: none !important;
    min-width: 163px;
    @media screen and (max-width: 767px) {
      min-width: auto;
      font-size: 12px;
      padding: 4px 12px;
    }
    svg {
      vertical-align: middle;
      margin-right: 10px;
      path {
        transition: all 0.35s ease 0s;
      }
      @media screen and (max-width: 767px) {
        margin-right: 6px;
        width: 16px;
      }
    }
    &:hover {
      background: #000000;
      color: #fff;
      path {
        fill: #000;
      }
    }
  }
`;

export const DetailsPlanListingWrap = styled.div`
  margin: 0 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DetailsPlanListing = styled.ul`
  margin: 0 -10px;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  @media screen and (max-width: 1440px) {
    margin: 0 -8px;
  }
  > li {
    padding: 0 10px;
    margin-bottom: 20px;
    width: 33.33%;
    @media screen and (max-width: 1440px) {
      padding: 0 8px;
      margin-bottom: 16px;
    }
    @media screen and (max-width: 1300px) {
      width: 50%;
    }
    @media screen and (max-width: 1199px) {
      width: 33.33%;
    }
    @media screen and (max-width: 991px) {
      width: 50%;
    }
  }
`;

export const DetailsPlanListingTitle = styled.div`
  margin-bottom: 22px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  @media screen and (max-width: 767px) {
    margin-bottom: 12px;
  }
  h3 {
    margin: 0;
    font-family: "Poppins";
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #3b3b3b;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    span {
      font-weight: 500;
      margin-left: 10px;
      display: inline-block;
    }
  }
`;

export const PlanListingTitleBtn = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #3b3b3b;
  align-items: center;
  display: flex;
  .btn {
    margin-left: 15px;
    border: none;
    padding: 0;
    border-radius: 30px;
    font-size: 12px;
    line-height: 18px;
    background: transparent;
    outline: none;
    box-shadow: none !important;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #f9328d;
      }
    }
  }
`;

export const DetailsPlanListingItem = styled.div`
  text-align: center;
  font-family: "Poppins";
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  color: #3b3b3b;

  @media screen and (max-width: 1440px) {
    font-size: 10px;
  }
`;

export const PlanListingItemImage = styled.div`
  margin-bottom: 5px;
  position: relative;
  a {
    position: relative;
    border-radius: 10px;
    padding-bottom: 75%;
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  .dateDropDown {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    line-height: 12px;
    .dropdown-toggle {
      border: none;
      padding: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      border-radius: 30px;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      svg {
        vertical-align: middle;
        transition: all 0.35s ease 0s;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &::after {
        display: none;
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        g {
          circle {
            fill: #fff;
          }
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
        }
        circle {
          fill: #4d27da;
        }
        g {
          circle {
            fill: #fff;
          }
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      border: none;
      padding: 15px;
      left: 0 !important;
      right: auto !important;
      margin: 4px auto;
      min-width: 225px;
    }
  }
`;

export const PlanListingItemName = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #3b3b3b;
  margin: 0 0 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  a {
    color: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:hover {
      color: #4d27da;
    }
  }
`;

export const PlanListingItemDes = styled.p`
  margin-bottom: 0;
`;

export const PlanListingItemAddBox = styled.div`
  position: relative;
  background: #f0f0f0;
  border-radius: 10px;
  padding-bottom: 75%;
  a {
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #3b3b3b;
    outline: none;
  }
`;

export const PlanListingItemAddBoxInner = styled.div`
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Poppins";
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #3b3b3b;

  p {
    margin-bottom: 0;
  }

  .dropdown {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    line-height: 12px;
    .dropdown-toggle {
      border: none;
      padding: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      border-radius: 30px;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      svg {
        vertical-align: middle;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &::after {
        display: none;
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 7px auto;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
  }
`;

export const StartEndDate = styled.div`
  position: relative;
  padding-left: 45px;
  font-family: "Poppins";
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: #3b3b3b;
  margin: 0 0 13px;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    position: absolute;
    left: 0;
    top: 3px;
    color: #4d27da;
  }
  p {
    margin: 0;
  }
  ul {
    align-items: center;
    border: 1px solid #707070;
    padding: 3px;
    margin: 0;
    list-style-type: none;
    border-radius: 3px;
    display: flex;
    li {
      position: relative;
      color: #3b3b3b;
      padding: 0 10px;
      min-width: 60px;
      white-space: nowrap;
      &:first-child {
        min-width: 93px;
        text-align: center;
        padding-right: 7px;
        border-right: 1px solid #3b3b3b;
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      .form-control {
        padding: 0;
        border-radius: 0;
        border: none;
        font-family: "Poppins";
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #3b3b3b;
        min-width: 55px;
        box-shadow: none;
        ::-webkit-input-placeholder {
          color: #3b3b3b;
          opacity: 1;
        }
        ::-moz-placeholder {
          color: #3b3b3b;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: #3b3b3b;
          opacity: 1;
        }
        :-moz-placeholder {
          color: #3b3b3b;
          opacity: 1;
        }
      }
    }
  }
`;

export const DropdownAction = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  display: flex;
  .removeBtn {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -4px;
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      color: #f9328d;
      path {
        fill: #f9328d;
      }
    }
  }
  .submitBtn {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #fff;
    padding: 4px 7px;
    border-radius: 30px;
    min-width: 70px;
    border: none;
    text-align: center;
    margin: 0;
    background: #3b3b3b;
    outline: none;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    display: inline-block;
    &:hover {
      color: #fff;
      background: #6840df;
    }
  }
`;

export const DropdownFullBtn = styled.div`
  margin: 10px 0 0;
  .btn {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #6840df;
    padding: 4px 7px 3px;
    border-radius: 30px;
    min-width: 70px;
    border: 1px solid #6840df;
    text-align: center;
    margin: 0;
    background: transparent;
    display: block;
    width: 100%;
    outline: none;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    + .btn {
      margin-top: 10px;
    }
    &:hover {
      color: #fff;
      background: #6840df;
    }
  }
`;

export const DropdownDateNote = styled.div`
  color: #ffa700;
  font-weight: normal;
  margin: 4px 0 0;
  align-items: center;
  display: flex;
  svg {
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -2px;
  }
`;

export const GroupMembersList = styled.div`
  margin-top: 20px;
`;

export const MemberItemIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 11px;
  line-height: 11px;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  > img {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    vertical-align: middle;
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 17px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }

  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .btn {
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      g {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      path,
      g {
        stroke: #6840df;
      }
    }
    &.creator-btn {
      border: 1px solid #6840df;
      border-radius: 30px;
      padding: 3px 7px;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #6840df;
      white-space: nowrap;
      &:hover {
        color: #fff;
        background: #6840df;
      }
    }
  }

  &.selectMember {
    padding-left: 64px;
    max-width: fit-content;
    ${MemberItemIcon} {
      left: 34px;
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.deleteDaysWrap {
    text-align: center;
    .modal-body {
      h2 {
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  &.anotherDayWrap {
    text-align: center;
    .modal-body {
      h2 {
        font-weight: bold;
        margin-bottom: 6px;
      }
      p {
        margin-bottom: 0;
      }
      ul {
        padding: 20px 0 0;
        margin: 0;
        list-style-type: none;
        li {
          position: relative;
          text-align: left;
          margin: 0 0 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          padding: 9px 15px;
          &::-webkit-input-placeholder {
            font-size: 14px;
            color: #a1a3b0;
            font-weight: 400;
          }
          &::-moz-placeholder {
            font-size: 14px;
            color: #a1a3b0;
            font-weight: 400;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
            color: #a1a3b0;
            font-weight: 400;
          }
          &:-moz-placeholder {
            font-size: 14px;
            color: #a1a3b0;
            font-weight: 400;
          }
        }
      }
      .dateInputGroup {
        position: relative;
        margin: 0;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 11px;
          right: 20px;
          pointer-events: none;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      .row {
        margin: 0 -7px;
        [class*="col-"] {
          padding: 0 7px;
          position: relative;
          + [class*="col-"] {
            &:before {
              content: "";
              width: 6px;
              background: #8d8d8d;
              position: absolute;
              height: 2px;
              top: 19px;
              left: -3px;
            }
          }
        }
      }
    }
  }

  &.visiblePubliclyWrap {
    text-align: center;
    .modal-body {
      h2 {
        font-weight: bold;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 0;
      }
      h4 {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #3b3b3b;
        text-align: left;
        margin: 18px 0 20px;
      }
    }
    ul {
      margin: 0 -10px;
      padding: 0;
      list-style-type: none;
      flex-wrap: wrap;
      display: flex;
      li {
        padding: 0 10px;
        text-align: left;
        width: 50%;
        margin: 0 0 15px;
        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const ModalMemberGroupList = styled.div`
  margin: 27px auto 0;
  width: 274px;
  max-width: 100%;
`;

export const ModalMemberGroupItem = styled.div`
  position: relative;
  padding-right: 30px;
  margin-bottom: 17px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  border-radius: 30px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const PlanConnectionInvite = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  position: relative;
  padding-right: 45px;
  margin-bottom: 20px;
  .form-switch {
    position: absolute;
    right: 0;
    top: -1px;
    margin: 0;
    font-size: 0;
    line-height: 0;
    min-height: inherit;
    .form-check-input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      z-index: 1;
      cursor: pointer;
      border-radius: 30px;
      background: transparent;
      border: none;
      box-shadow: none;
      opacity: 0;
    }
    .form-check-label {
      border: 1px solid #8d8d8d;
      border-radius: 30px;
      width: 38px;
      height: 22px;
      background: transparent;
      font-size: 0;
      line-height: 0;
      display: block;
      position: relative;
      text-indent: -99px;
      overflow: hidden;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background: #cecece;
        display: inline-block;
        position: absolute;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.25s ease 0s;
      }
    }
    .form-check-input {
      &:checked {
        + .form-check-label {
          &:before {
            left: 18px;
            background: #48d85e;
          }
        }
      }
    }
  }
`;

export const PlanStatusSelect = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  .dropdown {
    font-size: 12px;
    line-height: 12px;
    z-index: 1;
    .dropdown-toggle {
      padding: 5px 20px 5px 8px;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: normal;
      color: #3b3b3b;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      vertical-align: middle;
      border: 1px solid #a1a3b0;
      border-radius: 6px;
      display: flex;
      align-items: center;
      min-height: 36px;
      min-width: 160px;
      position: relative;
      transition: all 0.35s ease 0s;
      &::after {
        content: "";
        position: absolute;
        right: 10px;
        top: 12px;
        width: 7px;
        height: 7px;
        border: 2px solid #a1a3b0;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
      }
      &:hover {
        border-color: #4d27da;
        color: #4d27da;
        &:after {
          border-color: #4d27da;
        }
      }
      img {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        margin-right: 5px;
      }
      svg {
        vertical-align: middle;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        border-color: #4d27da;
        color: #4d27da;
        &:after {
          border-color: #4d27da;
          top: 15px;
          transform: rotate(-135deg);
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      border: none;
      padding: 15px 10px;
      left: 0 !important;
      right: auto !important;
      margin: 4px auto;
      min-width: 160px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        margin-bottom: 10px;
        border: none;
        box-shadow: none;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const SelectOption = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  position: relative;
  padding-left: 26px;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  transition: all 0.35s ease 0s;
  img {
    width: 24px;
    height: 24px;
    border-radius: 30px;
    margin-right: 5px;
  }
  &:hover {
    color: #4d27da;
  }
`;

export const OptionRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 16px;
  border-radius: 30px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 16px;
    width: 16px;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  width: 20px;
  input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 20px;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberRemove = styled.div`
  margin: 0 0 20px;
  .btn {
    border: 1px solid #3b3b3b;
    border-radius: 4px;
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #3b3b3b;
    background: transparent;
    padding: 4px 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    box-shadow: none !important;
    display: flex;
    svg {
      margin-right: 7px;
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      color: #fff;
      background: #3b3b3b;
      path {
        fill: #fff;
      }
    }
  }
`;

export const CheckBox = styled.div`
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  user-select: none;
  margin: 0 0 10px;
  min-height: 20px;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  &:last-child {
    margin-bottom: 0;
  }
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const RadioBox = styled.div`
  display: inline-block;
  padding: 0 0 0 33px;
  margin: 0;
  min-height: 16px;
  position: relative;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #a1a3b0;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
  label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    display: block;
    margin: 0 0 2px;
    img {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;

export const BackToTop = styled.div`
  position: relative;
  .btn {
    position: absolute;
    top: -66px;
    right: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #8d8d8d;
    width: 36px;
    height: 36px;
    border-radius: 30px;
    transform: rotate(90deg);
    align-items: center;
    justify-content: center;
    display: inline-flex;
    transition: all 0.35s ease 0s;
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.07);
    svg {
      vertical-align: middle;
      width: 14px;
      height: 14px;
    }
    path {
      transition: all 0.35s ease 0s;
    }
    &:hover {
      background: #4d27da;
      border-color: #4d27da;
      path {
        stroke: #fff;
      }
    }
  }
`;

export const VenueImage = styled.div`
  position: relative;
  border-radius: 10px !important;
  padding-bottom: 75% !important;
  display: block;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.35s ease 0s !important;
`;
