import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import {
  PageWrap,
  PageWrapInner,
  PageContainer,
  SearchListLoader,
} from "./privacyPolicyStyle";
import { privacyPolicyData } from "../../../redux/action/privacy-policy/privacyPolicy";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";

function PrivacyPolicy() {
  const dispatch: any = useDispatch();
  const privacyListing = useSelector((state: any) => state.privacyPolicy);

  const renderHTML: any = (escapedHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: escapedHTML },
    });

  useEffect(() => {
    dispatch(privacyPolicyData());
  }, []);
  return (
    <>
      <PageWrap>
        <Header />
        {privacyListing?.isLoading && (
          <SearchListLoader>
            <img src={PageLoaderIcon} alt="Loading..." />
          </SearchListLoader>
        )}
        <PageWrapInner>
          <PageContainer>
            <h1>Privacy Policy</h1>
            <h2>{privacyListing?.data?.data?.name}</h2>

            {renderHTML(privacyListing?.data?.data?.description)}
          </PageContainer>
        </PageWrapInner>
        <Footer />
      </PageWrap>
    </>
  );
}
export default PrivacyPolicy;
