import { Box } from "@mui/system";
import React, { useState } from "react";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Res from "../resource";

import { Tabs, Modal, Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Link from "@mui/material/Link";

import { FormGroup } from "./CustomInputStyle";

import EyeOpen from "../assets/images/eye-open.svg";
import EyeClose from "../assets/images/eye-close.svg";

// const useStyle: any = makeStyles(() => ({
//   customInputBox: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     border: "none",
//   },
//   custominputlabel: {
//     paddingBottom: Res.theme.spacing(1),
//     color: Res.theme.palette.secondary.main,
//     fontWeight: Res.theme.typography.fontWeightMedium,
//   },
//   logoStyle: {
//     padding: Res.theme.spacing(0.6),
//     paddingLeft: Res.theme.spacing(1),
//     paddingRight: Res.theme.spacing(1),
//     color: Res.theme.palette.secondary.main,
//     position: "relative",
//     top: 2,
//   },
//   customInput: {
//     padding: Res.theme.spacing(1.4),
//     borderColor: Res.variables.color.primaryColor,
//     borderRadius: Res.theme.spacing(1),
//     flex: 1,
//     backgroundColor: Res.variables.color.secondaryColor,
//     textAlignVertical: "top",
//     "&:focus": {
//       outline: "none",
//     },
//     "&::placeholder": {
//       color: Res.variables.color.blackColor,
//     },
//   },
//   focused: {
//     borderColor: Res.theme.palette.secondary.main,
//   },
//   blurred: {
//     borderColor: Res.theme.palette.secondary.main,
//   },
//   closeButton: {
//     width: 60,
//     top: -2,
//     alignSelf: "flex-end",
//     alignItems: "center",
//     paddingVertical: 3,
//     borderRadius: 2,
//   },
//   eye: {
//     color: "#CEDBEA",
//     height: 29,
//     width: 31,
//   },
// }));

type Props = {
  inputid?: any;
  inputlabe?: any;
  inputtype?: any;
  placeholder?: any;
  value?: any;
  onChange?: any;
  style?: any;
  customStyle?: any;
  onClick?: any;
  onBlur?: any;
  className?: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  handleEyeChange?: any;
  showEye?: any;
  as?: any;
  disabled?: any;
  maxLength?: any;
  onkeyup?: any;
  autocomplete?: any;
  onKeyPress?: any;
  ref?: any;
};

const CustomInput = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <FormGroup
        className={
          props.inputid === "password" || props.inputid === "confirmPassword"
            ? "inputGroupIcon"
            : ""
        }
      >
        {props.inputlabe && <Form.Label>{props.inputlabe}</Form.Label>}

        {(props.inputid === "password" ||
          props.inputid === "confirmPassword" ||
          props.inputid === "old_password" ||
          props.inputid === "new_password" ||
          props.inputid === "confirm_password") && (
          <i className="eye-icon" onClick={props.handleEyeChange}>
            <img src={props.showEye ? EyeClose : EyeOpen} alt="eye-icon" />
          </i>
        )}

        <Form.Control
          type={props.inputtype}
          as={props.as}
          id={props.inputid}
          // {...props}
          placeholder={isFocused ? "" : props.placeholder}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.value}
          disabled={props.disabled}
          maxLength={props.maxLength}
          onKeyUp={props.onkeyup}
          onKeyPress={props.onKeyPress}
          // onBlur={() => {
          //   setIsFocused(false);
          // }}
          // onFocus={() => {
          //   setIsFocused(true);
          // }}
          autocomplete={props.autocomplete}
        />

        {props.errors && props.touched && (
          <label className="error">{props.errors}</label>
        )}
      </FormGroup>

      {/* <Box pb={0.25}>
        <label htmlFor={props.inputid} className={styles.custominputlabel}>
          {props.inputlabe}
        </label>
        <Box className={styles.customInputBox}>
          <input
            style={props.customStyle}
            className={styles.customInput}
            type={props.inputtype}
            id={props.inputid}
            {...props}
            placeholder={isFocused ? "" : props.placeholder}
            onBlur={() => {
              setIsFocused(false);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
          />
        </Box>
      </Box> */}
    </>
  );
};

export default CustomInput;
