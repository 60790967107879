import { resetPwdLinkExpired } from "../../../services/resetPwd-link-expire";

export enum ResetPwdLinkExpireType {
  RESETPWD_LINK_EXPIRE_INFO = "RESETPWD_LINK_EXPIRE_INFO",
  RESETPWD_LINK_EXPIRE_INFO_SUCCESS = "RESETPWD_LINK_EXPIRE_INFO_SUCCESS",
  RESETPWD_LINK_EXPIRE_INFO_FALIURE = "RESETPWD_LINK_EXPIRE_INFO_FALIURE",
}
export const resetPwdLinkExpireInfo = () => (dispatch: any) => {
  dispatch({
    type: ResetPwdLinkExpireType.RESETPWD_LINK_EXPIRE_INFO,
  });
};

export const resetPwdLinkExpireInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ResetPwdLinkExpireType.RESETPWD_LINK_EXPIRE_INFO_SUCCESS,
    data,
  });
};

export const resetPwdLinkExpireInfoFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: ResetPwdLinkExpireType.RESETPWD_LINK_EXPIRE_INFO_FALIURE,
      error: msg,
    });
  };
export const resetPwdLinkExpireData = (email: any) => async (dispatch: any) => {
  try {
    dispatch(resetPwdLinkExpireInfo());
    const result: any = await resetPwdLinkExpired(email);
    dispatch(resetPwdLinkExpireInfoSuccess(result));
  } catch (e: any) {
    dispatch(resetPwdLinkExpireInfoFailed(e.response.data.error));
  }
};
