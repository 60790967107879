import { myProfileAPI } from "../../../services/myProfile";

export enum MyProfileType {
  MYPROFILE_INFO = "MYPROFILE_INFO",
  MYPROFILE_INFO_SUCCESS = "MYPROFILE_INFO_SUCCESS",
  MYPROFILE_INFO_FALIURE = "MYPROFILE_INFO_FALIURE",
}

export const myProfileInfo = () => (dispatch: any) => {
  dispatch({
    type: MyProfileType.MYPROFILE_INFO,
  });
};

export const myProfileInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: MyProfileType.MYPROFILE_INFO_SUCCESS,
    data,
  });
};

export const myProfileInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: MyProfileType.MYPROFILE_INFO_FALIURE,
    data: { msg },
  });
};

export const myProfileData = (userId: any) => async (dispatch: any) => {
  try {
    dispatch(myProfileInfo());
    const result: any = await myProfileAPI(userId);
    dispatch(myProfileInfoSuccess(result));
  } catch (e: any) {
    dispatch(myProfileInfoFailed(e.message));
  }
};
