import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import CreateNewModal from "../../../components/createNewModal/index";
import { Pagination } from "antd";
import PlansModal from "../../../components/plansModal/index";
import AnotherDayModal from "../../../components/anotherDayModal";
import { useLocation } from "react-router";
import GetAllCollectionsModal from "../../../components/collectionsModal";
import ReactGA from "react-ga4";
import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  FestivalsResultList,
  FestivalsResultItem,
  FestivalsResultCalendar,
  FestivalsResultCalendarInner,
  FestivalsResultDate,
  FestivalsResultMonth,
  FestivalsResultDay,
  FestivalsResultTime,
  FestivalsResultName,
  FestivalsResultBtnWrap,
  SportsList,
  PaginationWrap,
  SearchListLoader,
  CreateBtn,
  CreateBtnInner,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsGallery,
  SportsGalleryTop,
  SportsGalleryBottom,
  SportsGalleryItem,
  SportsTitleWrap,
  SportsTitle,
  SportsInfo,
  SportsInfoWrap,
  SportsReview,
  SportsRating,
  SportsPrice,
  SportsTeamDesc,
  RentalsFeature,
  ResultPrice,
  RecordNotFound,
  BackToList,
  ModalSection,
} from "../concertResults/concertResultsStyle";

import ChatsPlanModal from "../../../components/chatsPlanModal";
import { getAllEventHandler } from "../../../redux/action/getAllEvent/getAllEvent";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import Concert from "../../../assets/images/concert.jpg";
import { getEventConcertData } from "../../../redux/action/get-event-concert/getEventConcert";
import SportsCompanyImage from "../../../assets/images/sports-detail-logo.jpg";
import SportsEventMain from "../../../assets/images/sports-event-main.jpg";
import SportsEventThumbOne from "../../../assets/images/sports-event-thumb1.jpg";
import SportsEventThumbTwo from "../../../assets/images/sports-event-thumb2.jpg";
import SportsEventThumbThree from "../../../assets/images/sports-event-thumb3.jpg";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import CreateGroupModal from "../../../components/createGroupModal/index";
//create plan
import CreatePlanModal from "../../../components/createPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import ImageGalleryModal from "../../../components/imageGalleryModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import ShareWithModal from "../../../components/shareWithModal/index";
import { currentLocationInfoSuccess } from "../../../redux/action/current-location/currentLocation";
import { mapKey } from "../../../resource/constants";
import SocketPanel from "./../../../components/socketPanel/index";
import { ToastContainer, toast, collapseToast } from "react-toastify";

const ConcertResults = (props: any) => {
	
	ReactGA.initialize('G-W12FWLYJS8');
	
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [getPageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPagelimit] = useState(20);
  const [current, setCurrent] = useState();
  const [showConcertPage, setShowConncertPage] = useState(false);
  const [imageGalleryModalShow, setImageGalleryModalShow] = useState(false);
  const [plansModal, setPlansModal] = useState(false);
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [finalData, setFinalData] = useState<any[]>([]);
  const [rentalItemId, setRentalItemId] = useState("");
  const [planId, setPlanId] = useState("");
  const [placeIdData, setPlaceIdData] = useState("");
  const [show, setShow] = useState(false);

  //create planmodel start
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [filtersModalShow, setFiltersModalShow] = useState(false);
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [openChatModel, setOpenChatModel] = useState(false);
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [groupImages, setGroupImages] = useState("");
  const [sortingType, setSortingType] = useState("Price");
  const [showSortBy, setShowSortBy] = useState(-1);
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [allItems, setAllItems] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "concert",
  });
  const [getImageUrl, setImageUrl] = useState("");

  const [toDate, setTodate] = useState(null);
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [place, setPlace] = useState<any>("");
  const [getSearchDate, setSearchDate] = useState<any | null>(null);
  //complete planmodel end

  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const allEvent = useSelector((state: any) => state?.allEvents);
  const createPlan = useSelector((state: any) => state?.createPlan);

  const latLong: any = useSelector((state: any) => state?.latLong);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const local: any = JSON.parse(localStorage.getItem("location") || "{}");

  const currentLocation = useSelector((state: any) => state?.currentLocation);

  const location: any = useLocation();

  const locationPage: any = location.pathname.split("/")[2];

  useEffect(() => {
    if (!localStorage.getItem("location")) {
      setShow(true);
      dispatch({ type: "RESET_EVENT" });
    } else {
      (getSearchResult?.search?.trim()?.length >= 3 ||
        getSearchResult?.search?.trim() === "") &&
        dispatch(
          getAllEventHandler({
            page: Number(locationPage),
            limit: pageLimit,
            sort: showSortBy,
            sorting: sortingType,
            search: localStorage.getItem("main-search-panel-data")
              ? localStorage.getItem("main-search-panel-data")
              : getSearchResult?.search,
            date:
              getSearchDate === null
                ? ""
                : moment(getSearchDate).format("YYYY-MM-DD"),
            todate:
              toDate === null
                ? getSearchDate === null
                  ? ""
                  : moment(getSearchDate).format("YYYY-MM-DD")
                : moment(toDate).format("YYYY-MM-DD"),
            location:
              currentLocation?.data !== null
                ? currentLocation?.data
                : JSON.parse(localStorage.getItem("location") || "{}"),
          })
        );
    }
  }, [
    Number(locationPage),
    sortingType,
    showSortBy,
    getSearchDate,
    getSearchResult,
    toDate,
    place,
  ]);

  // useEffect(() => {
  //   (getSearchResult?.search?.trim()?.length >= 3 ||
  //     getSearchResult?.search?.trim() === "") &&
  //     dispatch(
  //       getAllEventHandler({
  //         page: Number(locationPage),
  //         limit: pageLimit,
  //         sort: showSortBy,
  //         sorting: sortingType,
  //         search: localStorage.getItem("main-search-panel-data")
  //           ? localStorage.getItem("main-search-panel-data")
  //           : getSearchResult?.search,
  //         date:
  //           getSearchDate === null
  //             ? ""
  //             : moment(getSearchDate).format("YYYY-MM-DD"),
  //         todate:
  //           toDate === null
  //             ? getSearchDate === null
  //               ? ""
  //               : moment(getSearchDate).format("YYYY-MM-DD")
  //             : moment(toDate).format("YYYY-MM-DD"),
  //         location:
  //           currentLocation?.data !== null
  //             ? currentLocation?.data
  //             : typeof local !== "object" && local !== ""
  //             ? local
  //             : JSON.parse(localStorage.getItem("location") || "{}"),
  //       })
  //     );
  //   // dispatch({ type: "RESET_CURRENT_DATA" });
  // }, [
  //   Number(locationPage),
  //   sortingType,
  //   showSortBy,
  //   getSearchResult,
  //   getSearchDate,
  //   toDate,
  //   place,
  // ]);

  useEffect(() => {
    if (allEvent && allEvent?.data && allEvent?.data?.data) {
      setTotalPage(allEvent?.data?.data?.totalDocs);
      setCurrent(allEvent?.data?.data?.page);
    }
  }, [allEvent]);

  //days model for created plan

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  //end days model for created.
  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const shareChatImages = (item: any) => {
    setGroupImages("");
    setAllItems(item);
    setDynamicLocation("/concert-result-details");
  };

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const [shareWithName, setShareWithName] = useState("");
  const [shareWithLink, setShareWithLink] = useState("");
  const [shareWithAddress, setShareWithAddress] = useState("");

  const navigateToShareWith = (item: any) => {
    setShareWithModalShow(true);
    setShareWithName(item?.name);
    setShareWithLink(item?._links["event:webpage"]?.href);
    setShareWithAddress(
      `${item?._embedded?.venue?.city} ${item?._embedded?.venue?.name} ${item?._embedded?.venue?.postal_code}`
    );
  };

  const navigateToNextPage = (value: any) => {
    navigate(`/concertsresults/${value}`);
  };

  const handleClick = (item:any) => {
	  var lbl=item.name;
    var lbl_act="Concert - Buy Tickets";
  ReactGA.event({
  category: 'Button Click',
    action: lbl_act,
    label: lbl,
});
  
};

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <ModalSection>
              <Modal.Body>
                <p>Please Select Location First</p>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(false)}
                >
                  Ok
                </button>
              </Modal.Body>
            </ModalSection>
          </Modal>

          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            setPageNumber={setPageNumber}
            setSearchDate={setSearchDate}
            getSearchDate={getSearchDate}
            setTodate={setTodate}
            toDate={toDate}
            getPageNumber={setPageNumber}
            place={place}
            setPlace={setPlace}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link
                to="/"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                All
              </Link>
              <Link
                to="/concertsresults/1"
                className="btn select"
                onClick={() => {
                  setShowConncertPage(false);
                  localStorage.removeItem("main-search-panel-data");
                }}
              >
                Concerts
              </Link>
              <Link
                to="/foodanddrinks/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Food &amp; Drinks
              </Link>
              <Link
                to="/festivalsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Festivals
              </Link>
              <Link
                to="/sportsresults/1"
                className="btn "
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Sports
              </Link>
              <Link
                to="/rentalsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Rentals
              </Link>
              <Link
                to="/hotelsresult/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hotels
              </Link>
              <Link
                to="/interestresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Points of Interest
              </Link>
              <Link
                to="/hiking/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                {allEvent?.data?.data?.concerts?.length > 0 && (
                  <Dropdown className="sortby" align="start">
                    <Dropdown.Toggle>
                      Sort by
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.656"
                        height="8.596"
                        viewBox="0 0 13.656 8.596"
                      >
                        <path
                          id="Path_133"
                          data-name="Path 133"
                          d="M5.944,11.888,0,5.944,5.944,0"
                          transform="translate(0.884 6.828) rotate(-90)"
                          fill="none"
                          stroke="#3b3b3b"
                          stroke-width="2.5"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        style={{
                          color:
                            sortingType === "Price" && showSortBy === -1
                              ? "#6840df"
                              : "",
                        }}
                        onClick={() => {
                          setPageNumber(1);
                          setShowSortBy(-1);
                          setSortingType("Price");
                        }}
                      >
                        Price : High to Low
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        style={{
                          color:
                            sortingType === "Price" && showSortBy === 1
                              ? "#6840df"
                              : "",
                        }}
                        onClick={() => {
                          setPageNumber(1);
                          setShowSortBy(1);
                          setSortingType("Price");
                        }}
                      >
                        Price : Low to High
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {allEvent?.data?.data?.concerts.length !== 0 &&
                  allEvent?.isLoading !== true &&
                  localStorage.getItem("location") && (
                    <button
                      type="submit"
                      className="btn"
                      onClick={() => setCreateNewModalShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <g transform="translate(-1016 -337)">
                          <path
                            d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                            transform="translate(1016 337)"
                            fill="#fff"
                          />
                          <path
                            d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                            transform="translate(1020.143 350.845)"
                            fill="#4d27da"
                            stroke="#fff"
                            stroke-width="0.5"
                            className="plusIcon"
                          />
                        </g>
                      </svg>
                      CREATE
                    </button>
                  )}
              </CreateBtnInner>
            </CreateBtn>

            {allEvent?.isLoading && (
              <SearchListLoader>
                <img src={PageLoaderIcon} alt="Loading..." />
              </SearchListLoader>
            )}

            {(allEvent?.data?.data?.concerts?.length === 0 ||
              !localStorage.getItem("location")) && (
              <RecordNotFound>No data found!</RecordNotFound>
            )}
            {/* {!localStorage.getItem("location") && (
              <RecordNotFound>No data found!</RecordNotFound>
            )} */}
            <FestivalsResultList>
              {allEvent?.data?.data?.concerts?.map((item: any) => {
                return (
                  <>
                    <FestivalsResultItem>
                      <FestivalsResultCalendar>
                        <FestivalsResultCalendarInner>
                          <FestivalsResultDate>
                            {moment(item?.start_date, "YYYY/MM/DD").date()}
                          </FestivalsResultDate>
                          <FestivalsResultMonth>
                            {1 + moment(item?.start_date, "YYYY/MM/DD").month()}
                          </FestivalsResultMonth>
                          <FestivalsResultDay>
                            {moment(item?.start_date).format("ddd")}
                          </FestivalsResultDay>
                        </FestivalsResultCalendarInner>
                        <FestivalsResultTime>
                          {moment(item?.start_date).format("hh:mm a")}
                        </FestivalsResultTime>
                      </FestivalsResultCalendar>
                      <FestivalsResultName>
                        <h3>
                          <Link to={`/concert-result-details/${item?._id}`}>
                            {item?.name}
                          </Link>
                        </h3>
                        <p>
                          {` ${item?._embedded?.venue?.city},
                           ${item?._embedded?.venue?.name} `}
                        </p>
                        <ResultPrice>
                          ${item?.min_ticket_price?.amount ?? "0"}
                        </ResultPrice>
                      </FestivalsResultName>
                      <FestivalsResultBtnWrap>
                        <a
                          target="_blank"
                          className="btn"
                          href={item?._links?.["event:webpage"]?.href}
						   onClick={() => handleClick(item)}
                        >
                          Buy Tickets
                        </a>
                        <Dropdown>
                          <Dropdown.Toggle>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 16 16"
                            >
                              <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                              <path
                                d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                transform="translate(2.459 16.62)"
                                fill="#3b3b3b"
                              ></path>
                            </svg>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                shareChatImages(item);
                                setOpenChatModel(true);
                                groupPlanDetails();
                                setPlaceIdData(item?._id);
                              }}
                            >
                              Share to Chat
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setPlansModal(true);
                                setRentalItemId(item?._id);
                              }}
                            >
                              Add to Plans
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setRentalDetails({
                                  venue_id: item?._id,
                                  name: item?.name,
                                  location: "",
                                  province: "",
                                  Image: item?.photo,
                                  venue_type: "concerts",
                                });
                                setCollectionModal(true);
                              }}
                            >
                              Add to collection
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setCreatePlanModalShow(true);
                                setCreateData(item);
                                setRentalItemId(item?._id);
                                setAllId([]);
                                setInviteFriend([]);
                                setInviteFollower([]);
                                setInviteEmails([]);
                                setGroupMembers([]);
                                setPendingEmail([]);
                                setShowImage("");
                                setImage("");
                                setImageValidation("");
                              }}
                            >
                              Create Plans
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => navigateToShareWith(item)}
                            >
                              Share With
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </FestivalsResultBtnWrap>
                    </FestivalsResultItem>
                  </>
                );
              })}
              {allEvent?.data?.data?.concerts?.length !== 0 &&
                localStorage.getItem("location") && (
                  <PaginationWrap>
                    <Pagination
                      total={allEvent?.data?.data?.count}
                      pageSize={pageLimit}
                      showSizeChanger={false}
                      onChange={(value, pagesize) => {
                        // setPageNumber(value);
                        setPagelimit(20);
                        navigateToNextPage(value);
                      }}
                      current={Number(locationPage)}
                    />
                  </PaginationWrap>
                )}
            </FestivalsResultList>
          </HomeListingWrap>
        </MainContentPanel>
        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        setCreateGroupModalShow={setCreateGroupModalShow}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />
      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />

      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />
      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={"concerts"}
      />
      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
        allItems={allItems}
      />
      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
      />
      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        name={shareWithName}
        link={shareWithLink}
        address={shareWithAddress}
      />
    </>
  );
};

export default ConcertResults;
