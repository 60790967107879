import { linkExpired } from "../../../services/register-link-expire";

export enum LinkExpireType {
  LINK_EXPIRE_INFO = "LINK_EXPIRE_INFO",
  LINK_EXPIRE_INFO_SUCCESS = "LINK_EXPIRE_INFO_SUCCESS",
  LINK_EXPIRE_INFO_FALIURE = "LINK_EXPIRE_INFO_FALIURE",
}

export const linkExpireInfo = () => (dispatch: any) => {
  dispatch({
    type: LinkExpireType.LINK_EXPIRE_INFO,
  });
};

export const linkExpireInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: LinkExpireType.LINK_EXPIRE_INFO_SUCCESS,
    data,
  });
};

export const linkExpireInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: LinkExpireType.LINK_EXPIRE_INFO_FALIURE,
    error: msg,
  });
};
export const linkExpiredDataInfo = (email: any) => async (dispatch: any) => {
  try {
    dispatch(linkExpireInfo());
    const result: any = await linkExpired(email);
    dispatch(linkExpireInfoSuccess(result));
  } catch (e: any) {
    dispatch(linkExpireInfoFailed(e.response.data.error));
  }
};
