import { changePasswordAPI } from "../../../services/changePassword";

export enum ChangePasswordType {
  CHANGE_PASSWORD_INFO = "CHANGE_PASSWORD_INFO",
  CHANGE_PASSWORD_INFO_SUCCESS = "CHANGE_PASSWORD_INFO_SUCCESS",
  CHANGE_PASSWORD_INFO_FALIURE = "CHANGE_PASSWORD_INFO_FALIURE",
}

export const changePasswordInfo = () => (dispatch: any) => {
  dispatch({
    type: ChangePasswordType.CHANGE_PASSWORD_INFO,
  });
};

export const changePasswordInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ChangePasswordType.CHANGE_PASSWORD_INFO_SUCCESS,
    data,
  });
};

export const changePasswordInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ChangePasswordType.CHANGE_PASSWORD_INFO_FALIURE,
    data: msg,
  });
};
export const changePasswordData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(changePasswordInfo());
    const result: any = await changePasswordAPI(data);
    dispatch(changePasswordInfoSuccess(result));
  } catch (e: any) {
    dispatch(changePasswordInfoFailed(e.response.data.message));
  }
};
