import { reqFollowAPI } from "../../../services/reqFollow";

export enum ReqFollowType {
  REQFOLLOW_INFO = "REQFOLLOW_INFO",
  REQFOLLOW_INFO_SUCCESS = "REQFOLLOW_INFO_SUCCESS",
  REQFOLLOW_INFO_FALIURE = "REQFOLLOW_INFO_FALIURE",
}

export const reqFollowInfo = () => (dispatch: any) => {
  dispatch({
    type: ReqFollowType.REQFOLLOW_INFO,
  });
};

export const reqFollowInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ReqFollowType.REQFOLLOW_INFO_SUCCESS,
    data,
  });
};

export const reqFollowInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ReqFollowType.REQFOLLOW_INFO_FALIURE,
    data: { msg },
  });
};

export const reqFollowData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(reqFollowInfo());
    const result: any = await reqFollowAPI(data);
    dispatch(reqFollowInfoSuccess(result));
  } catch (e: any) {
    dispatch(reqFollowInfoFailed(e.message));
  }
};
