import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalBody,
  ModalBodyBtn,
} from "../removeFriendMyProfile/removeFriendMyProfileStyle";
import { removeFriendMyProfileData } from "../../redux/action/remove-Friend-MyProfile/removeFriendMyProfile";
import { myProfileData } from "../../redux/action/my-profile/myProfile";

const RemoveModal = (props: any) => {
  const dispatch: any = useDispatch();

  const setLoginId = localStorage.getItem("id");

  const removeHandler = () => {
    const data = {
      profile_id: props.profile_id,
    };

    dispatch(removeFriendMyProfileData(data));
    dispatch(myProfileData(setLoginId));

    props.setUnfollowModalShow(false);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="unfollowModal"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <style>
          {`
            .unfollowModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 343px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .unfollowModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Remove Follower</h2>
            <p>
              Are you sure you want to Remove this
              <br /> user from your follower list?
            </p>
            <ModalBodyBtn>
              <Button
                onClick={() => {
                  removeHandler();
                }}
              >
                YES
              </Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RemoveModal;
