import { getRentalsType } from "../../action/get-rentals/getRentals";

export const initState: any = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getRentalsType.GET_RENTAL_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case getRentalsType.GET_RENTAL_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case getRentalsType.GET_RENTAL_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.error,
        data: null,
      };
    }
    case "RESET_RENTAL_DATA": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
