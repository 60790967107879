import styled, { keyframes } from "styled-components";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  width: 26px;
  height: 26px;
  position: relative;
  color: inherit;
  box-sizing: border-box;
  animation: ${spin} 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  ::before,
  ::after {
    content: "";
    width: 26px;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.35s ease 0s;
  }

  ::before {
    opacity: 0.3;
    border: 2px solid #fff;
  }

  ::after {
    border: 2px solid transparent;
    border-left-color: #fff;
  }
`;

export const AuthWrap = styled.div`
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  background: #f5f5f5;
`;

export const AuthWrapInner = styled.div`
  // min-height: calc(100vh - 63px);
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  flex: 1 0 0;
  display: flex;
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 600;
    color: #4d27da;
    margin: 0 0 30px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 36px;
      margin: 0 0 20px;
    }
  }
  form {
    margin: 0;
  }
  .auth-btn {
    background: #4d27da;
    display: block;
    width: 100%;
    border: none;
    color: #fff;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    padding: 12px 15px;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    position: relative;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.16) !important;
    &:hover {
      background: #000;
      color: #fff;
    }
    ${Spinner} {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0 auto -37px;
    }
  }
  .icon-btn {
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
      vertical-align: middle;
      margin: 0 10px 0 0;
    }
  }
  &.SignupFormWrap {
    padding: 48px 0;
    .row {
      margin-left: -5px;
      margin-right: -5px;
    }
    [class*="col"] {
      padding-left: 5px;
      padding-right: 5px;
    }
    .auth-btn {
      margin-top: 15px;
    }
    .dateError {
      font-family: "Poppins";
      color: #ef3939;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      margin: -10px 0 15px;
      display: block;
    }
    @media screen and (max-width: 480px) {
      padding: 35px 0;
    }
    .birthDate {
      .error {
        white-space: nowrap;
      }
    }
  }
  &.forgotPasswordWrap {
    .titleBox {
      font-size: 12px;
      line-height: 19px;
      h3 {
        margin-bottom: 15px;
      }
      a {
        margin-bottom: 15px;
      }
    }
    .auth-btn {
      margin: 30px 0;
    }
    .backToLogin {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
  &.SignupFormInvite {
    .backToLogin {
      font-size: 14px;
      line-height: 20px;
      margin: 30px 0 0;
    }
  }
`;

export const AuthBoxTitle = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #3b3b3b;
  text-align: center;
  margin-bottom: 15px;
  img {
    width: 84px;
    max-width: 100%;
  }
  h2 {
    margin-bottom: 13px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    @media (max-width: 480px) {
      br {
        display: none;
      }
    }
    span {
      display: block;
    }
  }
  strong {
    font-weight: 600;
  }
`;

export const AuthContainer = styled.div`
  width: 448px;
  max-width: 100%;
  margin: auto;
  padding: 0 15px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #4d27da;
    border-radius: 50px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #cecece;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #cecece;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #cecece;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #cecece;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #000;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const OR = styled.div`
  font-family: "Poppins";
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #9597a5;
  text-align: center;
  padding: 15px 0;
`;

export const FormLink = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #3b3b3b;
  margin: 20px 0;
  text-align: center;
  a {
    display: inline-block;
    color: #4631d1;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
`;

export const FormNote = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  color: #8d8d8d;
  text-align: center;
  margin-top: 15px;
  p {
    margin-bottom: 0;
  }
  a {
    color: #472bd5;
  }
`;

export const SignupFormNote = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
  color: #3b3b3b;
  text-align: center;
  margin: 20px 0 22px;
  p {
    margin-bottom: 0;
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 22px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }
  .modal-body {
    padding: 30px 55px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    min-height: 394px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    .tripd-logo {
      margin-bottom: 15px;
      a {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 84px;
        vertical-align: middle;
      }
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: #3b3b3b;
      text-align: center;
      margin: 0 0 15px;
    }
    p {
      margin-bottom: 0;
      + p {
        margin: 20px 0 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 27px;
  width: 100%;
  .btn {
    background: #4d27da;
    display: block;
    width: 100%;
    border: none;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding: 12px 15px;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.16) !important;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
`;

export const AccountRequestWrap = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const AccountRequestInner = styled.div`
  padding: 45px 15px;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  width: 470px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 41, 0.16);
  a {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    vertical-align: middle;
    width: 84px;
    margin: 0;
  }
  h3 {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #3b3b3b;
    margin: 15px 0 12px;
  }
  p {
    margin: 0;
  }
`;
