import { updateProfileAPI } from "../../../services/updateProfile";

export enum UpdateProfileType {
  UPDATEPROFILE_INFO = "UPDATEPROFILE_INFO",
  UPDATEPROFILE_INFO_SUCCESS = "UPDATEPROFILE_INFO_SUCCESS",
  UPDATEPROFILE_INFO_FALIURE = "UPDATEPROFILE_INFO_FALIURE",
}

export const updateProfileInfo = () => (dispatch: any) => {
  dispatch({
    type: UpdateProfileType.UPDATEPROFILE_INFO,
  });
};

export const updateProfileInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: UpdateProfileType.UPDATEPROFILE_INFO_SUCCESS,
    data,
  });
};

export const updateProfileInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: UpdateProfileType.UPDATEPROFILE_INFO_FALIURE,
    data: { msg },
  });
};

export const updateProfileData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(updateProfileInfo());
    const result: any = await updateProfileAPI(finalData);
    dispatch(updateProfileInfoSuccess(result));
  } catch (e: any) {
    dispatch(updateProfileInfoFailed(e.response.data.message));
  }
};
