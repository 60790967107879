import { getFollowersAPI } from "../../../services/getFollowers";

export enum GetFollowersType {
  GETFOLLOWERS_INFO = "GETFOLLOWERS_INFO",
  GETFOLLOWERS_INFO_SUCCESS = "GETFOLLOWERS_INFO_SUCCESS",
  GETFOLLOWERS_INFO_FALIURE = "GETFOLLOWERS_INFO_FALIURE",
}

export const getFollowersInfo = () => (dispatch: any) => {
  dispatch({
    type: GetFollowersType.GETFOLLOWERS_INFO,
  });
};

export const getFollowersInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetFollowersType.GETFOLLOWERS_INFO_SUCCESS,
    data,
  });
};

export const getFollowersInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: GetFollowersType.GETFOLLOWERS_INFO_FALIURE,
    error: msg,
  });
};

export const getFollowersAPIData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getFollowersInfo());
    const result: any = await getFollowersAPI(id);
    dispatch(getFollowersInfoSuccess(result));
  } catch (e: any) {
    dispatch(getFollowersInfoFailed(e.response.data.error));
  }
};
