import { sendEmail } from "../../../services/send-email";

export enum SendEmailType {
  SEND_EMAIL_INFO = "SEND_EMAIL_INFO",
  SEND_EMAIL_INFO_SUCCESS = "SEND_EMAIL_INFO_SUCCESS",
  SEND_EMAIL_INFO_FALIURE = "SEND_EMAIL_INFO_FALIURE",
}

export const sendEmailInfo = () => (dispatch: any) => {
  dispatch({
    type: SendEmailType.SEND_EMAIL_INFO,
  });
};

export const sendEmailInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SendEmailType.SEND_EMAIL_INFO_SUCCESS,
    data: data,
  });
};

export const sendEmailInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SendEmailType.SEND_EMAIL_INFO_FALIURE,
    data: { msg },
  });
};

export const sendEmailInfoData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(sendEmailInfo());
    const result: any = await sendEmail(data);
    dispatch(sendEmailInfoSuccess(result));
  } catch (e: any) {
    dispatch(sendEmailInfoFailed(e.response.data));
  }
};
