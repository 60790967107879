import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin: 0 -10px 20px;
      padding: 0;
      list-style-type: none;
      max-height: 470px;
      overflow-y: auto;
      flex-wrap: wrap;
      display: flex;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #cecece;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
      }

      @media screen and (max-width: 1440px) {
        max-height: 335px;
      }
      @media screen and (max-width: 767px) {
        max-height: inherit;
        overflow-y: visible;
      }

      li {
        margin: 0 0 20px;
        padding: 0 10px;
        width: 50%;
        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const CollectionsItem = styled.div`
  img {
    max-width: 100%;
  }
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 5px 0 0 0;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    a {
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #4d27da;
      }
    }
  }
`;

export const CollectionsItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const ModalAddBtn = styled.div`
  text-align: center;
  .btn {
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    box-shadow: none !important;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: transparent;
    color: #3b3b3b;
    padding: 0;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
`;

export const SearchListLoader = styled.div`
  text-align: center;
  padding: 5px 0 15px;
  img {
    width: 36px;
    height: 36px;
    vertical-align: middle;
  }
`;



export const BackToList = styled.button`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6c53e4;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.35s ease 0s;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
  path {
    transition: all 0.35s ease 0s;
  }
  &:hover {
    opacity: 1;
    color: #3b3b3b;
    path {
      fill: #3b3b3b;
    }
  }
`;