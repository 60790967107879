import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  background: #ffffff;
  margin: 0 5px;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  @media (max-width: 1199px) {
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
`;

export const ProfileTopMenu = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #cecece;
  padding: 22px 20px 0;
  margin: 0;
  justify-content: space-between;
  display: flex;
  z-index: 3;
  @media (max-width: 565px) {
    overflow-x: scroll;
    width: calc(100vw - 6px);
  }
  a,
  button {
    margin: 0;
    border-radius: 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    position: relative;
    padding: 14px 0 14px;
    text-align: center;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      width: 0;
      margin: auto;
      background: #6840df;
      opacity: 0;
      transition: all 0.35s ease 0s;
    }
    &:hover {
      color: #6840df;
    }
    &.active {
      color: #6840df;
      font-size: 16px;
      &:before {
        opacity: 1;
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    @media (max-width: 600px) {
      font-size: 12px;
    }
    @media (max-width: 565px) {
      min-width: max-content;
      margin: 0 8px;
    }
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 418px;
  @media (max-width: 600px) {
    width: 100%;
  }
  &.attachment-form {
    width: 100%;
  }
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  @media (max-width: 767px) {
    padding: 32px 15px;
  }
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const MainContentPanelWrap = styled.div`
  // padding-top: 22px;
  padding-top: 0;
  .nav-tabs {
    .nav-link {
      padding: 14px 10px 14px;
      font-size: 14px;
      &.active {
        color: #6840df;
      }
    }
  }
`;

export const FeedbackWrap = styled.div`
  .invites-title {
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 5px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  .subtitle {
    color: #3b3b3b;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 20px;
  }
  form {
    .form-label {
      line-height: 21px;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 5px;
    }
    .btn-primary {
      border-radius: 8px;
      background: #4d27da;
      border: none;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      min-width: 131px;
      padding: 8px 15px;
      margin-top: 15px;
      box-shadow: none !important;
      transition: all 0.35s ease 0s;
      &:hover {
        background: #3b3b3b;
      }
    }
  }
`;

export const FeedbackUserId = styled.div`
  color: #4d27da;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
`;
export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;
export const DropZone = styled.div`
  min-height: 170px;
  border: 1px solid #8d8d8d;
  border-radius: 5px;
  padding: 10px 22px;

  position: relative;
  .form-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: none !important;
    font-size: 0;
    line-height: 0;
    background: transparent;
    cursor: pointer;
    opacity: 0;
  }
  .upload-text {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    margin-top: 10px;
    a {
      color: #4d27da;
      text-decoration: underline;
    }
  }
  .support-file {
    color: #3b3b3b;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    margin-bottom: -25px;
    margin-top: 20px;
    text-align: center;
  }
`;
export const DropZoneWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-height: 130px;

  .deletebtn {
    position: absolute;
    top: 5px;
    right: 14px;
    padding: 0;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
    outline: none;
    svg {
      vertical-align: middle;
      width: 16px;
      height: 16px;
    }
  }
`;
export const DropZoneUploadImage = styled.div`
  display: none;
`;
