import { useEffect, useState, useCallback } from "react";
import loadScript from "load-script";
import each from "lodash/each";

let googleMapsApi: any;
let loading = false;
let callbacks: any[] = [];

const useGoogleMapsApi = () => {
  const [getApi, setApi] = useState<any>();
  const callback = useCallback(() => {
    setApi(window.google.maps);
  }, []);

  useEffect(() => {
    if (loading) {
      callbacks.push(callback);
    } else {
      if (!googleMapsApi) {
        loading = true;
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBW_S7WT9E1ExwvcGcxOOl1r3tA4LAtcno
          &libraries=places`,
          { async: true },
          () => {
            loading = false;
            googleMapsApi = window.google.maps;
            setApi(window.google.maps);
            each(callbacks, (init) => init());
            callbacks = [];
          }
        );
      }
    }
  }, []);

  return googleMapsApi;
};

export default useGoogleMapsApi;
