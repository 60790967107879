import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { useLocation, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import RemoveFriendModal from "../../../components/removeFriendModal/index";
import UnfollowModal from "../../../components/unfollowModal/index";
import UnFollowCollectionModal from "../../../components/UnFollowCollection/index";
import LasVegas from "../../../assets/images/las-vegas.png";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CandyJohns from "../../../assets/images/candy-johns.png";
import HotelFufu from "../../../assets/images/hotel-fufu.png";
import TacoRestaurant from "../../../assets/images/taco-restaurant.png";
import InterestName from "../../../assets/images/interest-name.png";
import SuperTaco from "../../../assets/images/super-taco.png";
import GreatCabin from "../../../assets/images/great-cabin.png";
import Caba from "../../../assets/images/caba.png";
import La from "../../../assets/images/la.png";

import {
  MyPageWrap,
  MainContentPanel,
  ModalBody,
  ModalBodyBtn,
  ModalMemberGroupList,
  ModalMemberGroupItem,
  InviteGroupRadioBox,
  SocialProfileWrap,
  SocialProfileTop,
  UserProfileImage,
  SocialProfileInfo,
  SocialUserName,
  AboutUser,
  UserEmail,
  UserAction,
  SocialProfileToDo,
  MyPlansTop,
  MyPlansTitle,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansMore,
  PageLoader,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
  FormGroup,
  TabsContent,
  TabsWrap,
  TabDataNotFound,
  SearchListLoader,
  SearchBoxNote,
  MyPlansLock,
  MyPlansAction,
  CityTitle,
  RecordNotFound,
  MyPlansActionWrap,
  BackToList,
  Collectionbtn,
} from "./socialProfileStyle";

import FollowerDiana from "../../../assets/images/follower-two.png";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import { addFollowData } from "../../../redux/action/add-follow-req/addFollow";
import { getAllUserAPIData } from "../../../redux/action/getallusers/getAllUsers";
import { getFollowersAPIData } from "../../../redux/action/getfollowers/getFollowers";
import { getFollowingInfoData } from "../../../redux/action/getfollowing/getFollowing";
import { getAllFriendData } from "../../../redux/action/getallfriends/getAllFriends";
import { getRequestData } from "../../../redux/action/get-all-request/getAllrequest";
import { unfollowData } from "../../../redux/action/unfollow/unFollow";
import { getUserProfile } from "../../../redux/action/get-user-profile/getUserProfile";
import { addFriendData } from "../../../redux/action/add-friends/addFriends";
import { searchFollowData } from "../../../redux/action/search-followers/searchFollowers";
import { searchFollowingData } from "../../../redux/action/search-following/searchFollowing";
import { searchFriendsAPIData } from "../../../redux/action/search-friends/searchFriend";
import { deleteRequest } from "../../../redux/action/deleteFollowRequest/deleteFollowRequest";
import DeleteCollectionItemModal from "../../../components/deleteCollectionItem";
import VerifiedIcon from "@mui/icons-material/Verified";

const SocialProfile = (props: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFollowingTerm, setSearchFollowingTerm] = useState("");
  const [searchFriendTerm, setSearchFriendTerm] = useState("");
  const [getFollow, setFollow] = useState("");
  const [getUnfollow, setUnFollow] = useState(false);
  const [removeFriendModalShow, setRemoveFriendModalShow] = useState(false);
  const [unfollowModalShow, setUnfollowModalShow] = useState(false);
  const [getCollectionActive, setCollectionActive] = useState({
    wantToDo: true,
    collection: true,
    collectionItem: false,
  });
  const [getAlCollection, setAllCollection] = useState({
    wantToDoCollection: false,
    collectionCollection: false,
    collectionItemColletion: false,
  });

  const [getUploadBox, setUploadBox] = useState(false);
  const [getCollectionItem, setItemCollection] = useState([]);
  const [getCollectionId, setCollectionId] = useState("");
  const [getDeleteCollectionItemModal, setDeleteCollectionItemModal] = useState(
    {
      show: false,
      collection_id: "",
      item_id: "",
    }
  );

  const [getUnfollowCollection, setUnfollowCollection] = useState({
    show: false,
    collection_id: "",
    user_id: "",
    follow: false,
  });

  const setLoginId = localStorage.getItem("id");
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  let { user_id }: any = useParams();

  const loginUserId: any = useSelector((state: any) => state?.loginData);

  const getUserProfileData = useSelector((state: any) => state?.getUserProfile);

  const requestedArray: any = useSelector((state: any) =>
    state?.getAllRequest?.data?.data
      ?.filter((ele: any) => {
        return (
          ele?.user_id === localStorage.getItem("id") &&
          (ele?.notification_type === "Follow Request" ||
            ele?.notification_type === "Accept Follow Request")
        );
      })
      .filter((ele: any) => ele.notification_user_id === user_id)
  );

  const friendRequestedArray: any = useSelector((state: any) =>
    state?.getAllRequest?.data?.data
      ?.filter((ele: any) => {
        return (
          ele?.user_id === localStorage.getItem("id") &&
          ele?.notification_type === "Friend Request"
        );
      })
      .filter((ele: any) => ele.notification_user_id === user_id)
  );

  const getAllFriendsHandler = useSelector(
    (state: any) => state?.getAllFriends?.data?.data
  );

  const requestedUser: any = useSelector((state: any) => state?.getAllRequest);

  const addFollowReq: any = useSelector(
    (state: any) => state?.addFollowReq?.data?.data
  );

  const unFollowHandler: any = useSelector((state: any) => state?.unFollow);

  const removeFriend: any = useSelector((state: any) => state?.removeFriend);

  const userIdData: any = loginUserId?.data?.id;

  // Following selector
  const getFollowingList = useSelector((state: any) => state?.getFollowing);

  const getFriendsList = useSelector((state: any) => state?.getAllFriends);

  const getFollowings = getFollowingList?.data?.data;

  // For Following
  const followingUser = getFollowingList?.data?.data
    ?.filter((ele: any) => {
      return ele._id === user_id;
    })
    .filter((ele: any) => ele?.following_id === localStorage.getItem("id"));

  // Follower selector
  const getFollowerList = useSelector((state: any) => state?.getFollowers);

  const getFollowers = getFollowerList?.data?.data;

  const followerUser = getFollowerList?.data?.data
    ?.filter((ele: any) => {
      return ele._id === user_id;
    })
    .filter((ele: any) => ele?.follower_id === localStorage.getItem("id"));

  // friends
  const friendHandler = getAllFriendsHandler
    ?.filter((ele: any) => {
      return ele._id === user_id;
    })
    .filter((ele: any) => ele?.friends_id === localStorage.getItem("id"));

  const searchApp = useSelector((state: any) => state?.searchFollowing);

  const searchFriendList = useSelector((state: any) => state?.searchFriends);

  // Search Followers
  const searchFollowerss = useSelector((state: any) => state?.searchFollowers);

  const followingCollection = useSelector(
    (state: any) => state?.followingCollection
  );

  const unFollowingCollection = useSelector(
    (state: any) => state?.unFollowingCollection
  );

  const deleteNotification = useSelector(
    (state: any) => state?.deleteFollowRequest?.data?.data
  );

  const addFriendHandler = useSelector(
    (state: any) => state?.addFriend?.data?.data
  );

  const navigationLogic = (type: any, id: any) => {
    switch (type) {
      case "concerts":
        navigate(`/concert-result-details/${id}`);
        break;
      case "sports":
        navigate(`/sports-results-details/${id}`);
        break;
      case "festival":
        navigate(`/festival-result-details/${id}`);
        break;
      case "rental":
        navigate(`/rental-result-details/${id}`);
        break;
      case "food and drink":
        navigate(`/food-and-drink-details/${id}`);
        break;
      case "point of interest":
        navigate(`/interest-details-results/${id}`);
        break;
      case "hiking":
        navigate(`/hiking-result-details/${id}`);
        break;
      case "hotel":
        navigate(`/hotel-result-details/${id}`);
        break;

      default:
    }
  };

  useEffect(() => {
    // dispatch(getAllUserAPIData());
    dispatch({ type: "RESET_REQUEST" });
  }, []);

  // For second search data get
  useEffect(() => {
    dispatch(getFollowersAPIData(user_id));
    dispatch(getFollowingInfoData(user_id));
    dispatch(getAllFriendData(user_id));
    setFollow("");
  }, [user_id]);

  useEffect(() => {
    dispatch(getRequestData());
  }, [
    addFollowReq,
    user_id,
    deleteNotification,
    unFollowHandler,
    addFriendHandler,
    removeFriend,
  ]);

  useEffect(() => {
    dispatch(getUserProfile(user_id));
  }, [
    addFollowReq,
    user_id,
    deleteNotification,
    unFollowHandler,
    addFriendHandler,
    removeFriend,
    followingCollection,
    unFollowingCollection,
  ]);

  // For Follow Request

  const followId = async () => {
    const finalData = {
      follower_id: user_id,
      is_requested: true,
      notification_type: "Follow Request",
    };
    dispatch(addFollowData(finalData));
  };

  const followBackHandler = async () => {
    const finalData = {
      follower_id: user_id,
      is_requested: true,
      notification_type: "Follow Request",
    };
    dispatch(addFollowData(finalData));
  };

  const reuestedHandler = async () => {
    const finalData = {
      follower_id: requestedArray?.[0]?._id,
    };
    dispatch(deleteRequest(finalData));
  };

  const reuestedFriendHandler = async () => {
    const finalData = {
      follower_id: friendRequestedArray?.[0]?._id,
    };
    dispatch(deleteRequest(finalData));
  };

  // Handle Add friend
  const handleAddFriend = async () => {
    const finalData = {
      friend_id: user_id,
      notification_type: "Friend Request",
      is_requested: true,
    };
    dispatch(addFriendData(finalData));
  };

  const submitForm = (values: any) => {
    console.log(values, "values");
  };

  // Search functionality in following

  const searchFollowing = (event: any) => {
    setSearchFollowingTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: user_id,
    };
    if (event.trim() !== "" && getFollow === "following") {
      dispatch(searchFollowingData(finalValue));
    }
  };

  // Search functionality in friend

  const searchFriend = (event: any) => {
    setSearchFriendTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: user_id,
    };

    if (event.trim() !== "" && getFollow === "friends") {
      dispatch(searchFriendsAPIData(finalValue));
    }
  };

  // Search functionality in follower
  const searchTextData = (event: any) => {
    setSearchTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: user_id,
    };

    if (event.trim() !== "" && getFollow === "followers") {
      dispatch(searchFollowData(finalValue));
    }
  };

  const unFollowingHandler = () => {
    setUnfollowModalShow(true);
  };

  const removeFriendModal = () => {
    setRemoveFriendModalShow(true);
  };

  const userNameHandler = (e: any) => {
    const re = /^[a-zA-Z0-9-_.]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const navigateFollowers = (item: any) => {
    if (localStorage.getItem("id") === item?.follower_id) {
      navigate(`/myprofile/${item?.follower_id}`);
    } else {
      navigate(`/socialprofile/${item?.follower_id}`);
    }
  };

  const navigateFollowing = (item: any) => {
    if (localStorage.getItem("id") === item?.following_id) {
      navigate(`/myprofile/${item?.following_id}`);
    } else {
      navigate(`/socialprofile/${item?.following_id}`);
    }
  };

  const navigateFriend = (item: any) => {
    if (localStorage.getItem("id") === item?.friends_id) {
      navigate(`/myprofile/${item?.friends_id}`);
    } else {
      navigate(`/socialprofile/${item?.friends_id}`);
    }
  };

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        {(getUserProfileData?.isLoading || requestedUser?.isLoading) && (
          <PageLoader>
            <img src={PageLoaderIcon} alt="Loading..." />
          </PageLoader>
        )}

        <MainContentPanel>
          <SocialProfileWrap>
            <SocialProfileTop>
              <UserProfileImage>
                <img
                  src={getUserProfileData?.data?.data?.data?.photo}
                  alt={getUserProfileData?.data?.data?.data?.firstName}
                />
              </UserProfileImage>
              <SocialProfileInfo>
                <SocialUserName>
                  @{getUserProfileData?.data?.data?.data?.userName}
                  {getUserProfileData?.data?.data?.data?.batch ? (
                    <VerifiedIcon
                      style={{ color: "#458eff", paddingLeft: "5px" }}
                    />
                  ) : (
                    ""
                  )}
                </SocialUserName>
                <ul>
                  <li
                    onClick={() =>
                      getUserProfileData?.data?.data?.follower_status &&
                      getUserProfileData?.data?.data?.following_status &&
                      setFollow("followers")
                    }
                  >
                    <span className="profile-number">
                      {getUserProfileData?.data?.data?.follower_count_data}
                    </span>
                    <label>Followers</label>
                  </li>
                  <li
                    onClick={() =>
                      getUserProfileData?.data?.data?.follower_status &&
                      getUserProfileData?.data?.data?.following_status &&
                      setFollow("following")
                    }
                  >
                    <span className="profile-number">
                      {getUserProfileData?.data?.data?.following_count_data}
                    </span>
                    <label>Following</label>
                  </li>
                  <li
                    onClick={() =>
                      getUserProfileData?.data?.data?.follower_status &&
                      getUserProfileData?.data?.data?.following_status &&
                      setFollow("friends")
                    }
                  >
                    <span className="profile-number">
                      {getUserProfileData?.data?.data?.friend_count_data}
                    </span>
                    <label>Friends</label>
                  </li>
                </ul>
                <AboutUser>
                  {getUserProfileData?.data?.data?.data?.bio}
                </AboutUser>

                {/* <UserEmail>
                  <a
                    href={`mailto:${getUserProfileData?.data?.data?.data?.email}`}
                  >
                    {getUserProfileData?.data?.data?.data?.email}
                  </a>
                </UserEmail> */}
              </SocialProfileInfo>
              <UserAction>
                {requestedArray?.[0]?.notification_type ===
                  "Follow Request" && (
                  <>
                    <Button className="btn-fill" onClick={reuestedHandler}>
                      Requested
                    </Button>
                  </>
                )}

                {getUserProfileData?.data?.data?.follower_status && (
                  <>
                    <Button className="btn-fill" onClick={unFollowingHandler}>
                      Following
                    </Button>
                  </>
                )}
                {getUserProfileData?.data?.data?.following_status &&
                  getUserProfileData?.data?.data?.follower_status === false &&
                  (requestedArray?.length === 0 ||
                    requestedArray === undefined) && (
                    <>
                      <Button className="btn-fill" onClick={followBackHandler}>
                        Follow Back
                      </Button>
                    </>
                  )}

                {(requestedArray?.length === 0 ||
                  requestedArray === undefined) &&
                  getUserProfileData?.data?.data?.following_status === false &&
                  getUserProfileData?.data?.data?.follower_status === false && (
                    <>
                      <Button className="btn-fill" onClick={followId}>
                        Follow
                      </Button>
                    </>
                  )}
                {friendRequestedArray?.[0]?.notification_type ===
                  "Friend Request" ||
                friendRequestedArray?.[1]?.notification_type ===
                  "Friend Request" ? (
                  <Button
                    className="btn-border"
                    onClick={reuestedFriendHandler}
                  >
                    Requested
                  </Button>
                ) : (
                  !getUserProfileData?.data?.data?.friends_status && (
                    <Button className="btn-border" onClick={handleAddFriend}>
                      Add Friend
                    </Button>
                  )
                )}

                {getUserProfileData?.data?.data?.friends_status && (
                  <Button className="btn-border" onClick={removeFriendModal}>
                    Friends
                  </Button>
                )}
              </UserAction>
            </SocialProfileTop>

            {getFollow === "" ? (
              <>
                <SocialProfileToDo>
                  {getCollectionActive?.wantToDo && (
                    <>
                      <MyPlansTop>
                        <MyPlansTitle>Wants to do</MyPlansTitle>
                      </MyPlansTop>

                      <MyPlansList>
                        {!getAlCollection?.wantToDoCollection &&
                          getUserProfileData?.data?.data?.data?.Collections?.map(
                            (ele: any) => {
                              return (
                                ele?.name === "WANTS TO DO" &&
                                ele?.items?.slice(0, 5).map((item: any) => {
                                  return (
                                    <MyPlansBox>
                                      <MyPlansImage>
                                        <img
                                          src={
                                            item?.Image !== null &&
                                            item?.Image !== ""
                                              ? item?.Image
                                              : LasVegas
                                          }
                                          alt={item?.name}
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                        />
                                      </MyPlansImage>
                                      <MyPlansBoxTitle className="collection-lg-title">
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "500",
                                          }}
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </span>
                                      </MyPlansBoxTitle>
                                    </MyPlansBox>
                                  );
                                })
                              );
                            }
                          )}

                        {getAlCollection?.wantToDoCollection &&
                          getUserProfileData?.data?.data?.data?.Collections?.map(
                            (ele: any) => {
                              return (
                                ele?.name === "WANTS TO DO" &&
                                ele?.items?.map((item: any) => {
                                  return (
                                    <MyPlansBox>
                                      <MyPlansImage>
                                        <img
                                          src={
                                            item?.Image !== null &&
                                            item?.Image !== ""
                                              ? item?.Image
                                              : LasVegas
                                          }
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                          alt={item?.name}
                                        />
                                      </MyPlansImage>
                                      <MyPlansBoxTitle className="collection-lg-title">
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "500",
                                          }}
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </span>
                                      </MyPlansBoxTitle>
                                    </MyPlansBox>
                                  );
                                })
                              );
                            }
                          )}
                      </MyPlansList>

                      {getUserProfileData?.data?.data?.data?.Collections?.map(
                        (ele: any) =>
                          ele?.name === "WANTS TO DO" &&
                          (ele?.items?.length === 0 ? (
                            <RecordNotFound>No Items found!</RecordNotFound>
                          ) : (
                            !getAlCollection?.wantToDoCollection &&
                            getUserProfileData?.data?.data?.data?.Collections?.map(
                              (ele: any) =>
                                ele?.name === "WANTS TO DO" &&
                                ele?.items?.length > 5 && (
                                  <>
                                    <Collectionbtn>
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          setAllCollection({
                                            ...getAlCollection,
                                            wantToDoCollection: true,
                                          });
                                        }}
                                        className="collection-btn"
                                      >
                                        More Items...
                                      </Button>
                                    </Collectionbtn>
                                  </>
                                )
                            )
                          ))
                      )}
                    </>
                  )}

                  {getCollectionActive?.collection && (
                    <>
                      <MyPlansTop>
                        <MyPlansTitle>Collections</MyPlansTitle>
                      </MyPlansTop>

                      <MyPlansList>
                        {!getAlCollection?.collectionCollection &&
                          getUserProfileData?.data?.data?.data?.Collections?.slice(
                            1,
                            6
                          )?.map((ele: any) => {
                            return (
                              ele?.name !== "WANTS TO DO" &&
                              ele?.visibility !== "Private" && (
                                <MyPlansBox>
                                  {!ele?.follower?.includes(
                                    localStorage.getItem("id")
                                  ) && (
                                    <button
                                      onClick={() => {
                                        setUnfollowCollection({
                                          show: true,
                                          collection_id: ele?.id,
                                          user_id: user_id,
                                          follow: true,
                                        });
                                      }}
                                      className="deletebtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.0"
                                        width="512.000000pt"
                                        height="512.000000pt"
                                        viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                      >
                                        <g
                                          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                          fill="#000000"
                                          stroke="none"
                                        >
                                          <path d="M2370 5114 c-19 -2 -78 -9 -130 -15 -791 -90 -1522 -586 -1924 -1305 -146 -262 -252 -588 -297 -914 -18 -125 -18 -515 0 -640 105 -762 511 -1409 1146 -1826 776 -510 1793 -550 2615 -104 125 68 154 91 179 143 43 89 15 193 -68 252 -36 25 -52 30 -104 30 -38 0 -73 -6 -92 -16 -168 -91 -236 -124 -330 -162 -323 -130 -670 -180 -1011 -146 -854 84 -1578 664 -1843 1475 -148 452 -148 896 0 1348 214 653 723 1162 1378 1376 449 147 894 147 1345 -1 488 -160 893 -481 1171 -929 162 -262 273 -595 305 -919 45 -460 -70 -940 -326 -1351 -56 -90 -59 -98 -59 -160 0 -78 26 -127 88 -168 56 -37 153 -39 210 -3 60 37 182 235 276 449 469 1060 169 2297 -734 3025 -364 293 -768 469 -1245 542 -100 15 -472 28 -550 19z" />
                                          <path d="M2492 3649 c-45 -13 -108 -80 -121 -126 -7 -23 -11 -176 -11 -400 l0 -362 -383 -3 -383 -3 -41 -27 c-63 -41 -88 -90 -88 -169 0 -54 5 -72 27 -106 15 -22 44 -51 65 -64 l38 -24 382 -3 382 -3 3 -382 3 -382 24 -38 c13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 l24 38 3 382 3 382 383 3 382 3 41 27 c63 41 88 90 88 169 0 54 -5 72 -27 106 -15 22 -44 51 -65 64 l-38 24 -382 3 -382 3 -3 382 -3 382 -24 38 c-47 76 -151 113 -239 86z" />
                                        </g>
                                      </svg>
                                    </button>
                                  )}
                                  {ele?.follower?.includes(
                                    localStorage.getItem("id")
                                  ) && (
                                    <button
                                      onClick={() => {
                                        setUnfollowCollection({
                                          show: true,
                                          collection_id: ele?.id,
                                          user_id: user_id,
                                          follow: false,
                                        });
                                      }}
                                      className="deletebtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="Layer_1"
                                        data-name="Layer 1"
                                        viewBox="0 0 511.3 511.55"
                                        width="512.000000pt"
                                        height="512.000000pt"
                                      >
                                        <path
                                          d="M237,.6c-1.9.2-7.8.9-13,1.5-79.1,9-152.2,58.6-192.4,130.5C17,158.8,6.4,191.4,1.9,224c-1.8,12.5-1.8,51.5,0,64C12.4,364.2,53,428.9,116.5,470.6c77.6,51,179.3,55,261.5,10.4,12.5-6.8,15.4-9.1,17.9-14.3,4.3-8.9,1.5-19.3-6.8-25.2-3.6-2.5-5.2-3-10.4-3-3.8,0-7.3.6-9.2,1.6-16.8,9.1-23.6,12.4-33,16.2a214,214,0,0,1-101.1,14.6C150,462.5,77.6,404.5,51.1,323.4c-14.8-45.2-14.8-89.6,0-134.8A214.81,214.81,0,0,1,188.9,51c44.9-14.7,89.4-14.7,134.5.1,48.8,16,89.3,48.1,117.1,92.9,16.2,26.2,27.3,59.5,30.5,91.9,4.5,46-7,94-32.6,135.1-5.6,9-5.9,9.8-5.9,16,0,7.8,2.6,12.7,8.8,16.8,5.6,3.7,15.3,3.9,21,.3,6-3.7,18.2-23.5,27.6-44.9A255.27,255.27,0,0,0,416.5,56.7C380.1,27.4,339.7,9.8,292,2.5,282,1,244.8-.3,237,.6Z"
                                          transform="translate(-0.55 -0.3)"
                                        />
                                        <path
                                          d="M362.8,245.3a26.3,26.3,0,0,0-6.5-6.4l-3.8-2.4-38.2-.3-35.39-.28H233.18l-35.48.28-38.3.3-4.1,2.7c-6.3,4.1-8.8,9-8.8,16.9,0,5.4.5,7.2,2.7,10.6a26.3,26.3,0,0,0,6.5,6.4l3.8,2.4,76.4.6h40.2l38.3-.3,38.2-.3,4.1-2.7c6.3-4.1,8.8-9,8.8-16.9C365.5,250.5,365,248.7,362.8,245.3Z"
                                          transform="translate(-0.55 -0.3)"
                                        />
                                        <path
                                          d="M235.9,276.1h0Z"
                                          transform="translate(-0.55 -0.3)"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                  <MyPlansImage>
                                    <img
                                      onClick={() => {
                                        setCollectionId(ele?._id);
                                        setItemCollection(ele?.items);
                                        setCollectionActive({
                                          wantToDo: false,
                                          collection: false,
                                          collectionItem: true,
                                        });
                                      }}
                                      src={ele?.cover_photo}
                                      alt="Wonderful Great Cabin"
                                    />
                                    {ele?.visibility === "Private" && (
                                      <MyPlansLock>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12.38"
                                          height="16.249"
                                          viewBox="0 0 12.38 16.249"
                                        >
                                          <path
                                            d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                            transform="translate(-6 -1.5)"
                                            fill="#fff"
                                          />
                                        </svg>
                                      </MyPlansLock>
                                    )}
                                  </MyPlansImage>

                                  <MyPlansBoxTitle className="collection-lg-title">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => {
                                        setCollectionId(ele?._id);
                                        setItemCollection(ele?.items);
                                        setCollectionActive({
                                          wantToDo: false,
                                          collection: false,
                                          collectionItem: true,
                                        });
                                      }}
                                    >
                                      {ele?.name}
                                    </span>
                                  </MyPlansBoxTitle>
                                </MyPlansBox>
                              )
                            );
                          })}

                        {getAlCollection?.collectionCollection &&
                          getUserProfileData?.data?.data?.data?.Collections?.map(
                            (ele: any) => {
                              return (
                                ele?.name !== "WANTS TO DO" &&
                                ele?.visibility !== "Private" && (
                                  <MyPlansBox>
                                    <MyPlansImage>
                                      {!ele?.follower?.includes(
                                        localStorage.getItem("id")
                                      ) && (
                                        <button
                                          onClick={() => {
                                            setUnfollowCollection({
                                              show: true,
                                              collection_id: ele?.id,
                                              user_id: user_id,
                                              follow: true,
                                            });
                                          }}
                                          className="deletebtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.0"
                                            width="512.000000pt"
                                            height="512.000000pt"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                          >
                                            <g
                                              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                              fill="#000000"
                                              stroke="none"
                                            >
                                              <path d="M2370 5114 c-19 -2 -78 -9 -130 -15 -791 -90 -1522 -586 -1924 -1305 -146 -262 -252 -588 -297 -914 -18 -125 -18 -515 0 -640 105 -762 511 -1409 1146 -1826 776 -510 1793 -550 2615 -104 125 68 154 91 179 143 43 89 15 193 -68 252 -36 25 -52 30 -104 30 -38 0 -73 -6 -92 -16 -168 -91 -236 -124 -330 -162 -323 -130 -670 -180 -1011 -146 -854 84 -1578 664 -1843 1475 -148 452 -148 896 0 1348 214 653 723 1162 1378 1376 449 147 894 147 1345 -1 488 -160 893 -481 1171 -929 162 -262 273 -595 305 -919 45 -460 -70 -940 -326 -1351 -56 -90 -59 -98 -59 -160 0 -78 26 -127 88 -168 56 -37 153 -39 210 -3 60 37 182 235 276 449 469 1060 169 2297 -734 3025 -364 293 -768 469 -1245 542 -100 15 -472 28 -550 19z" />
                                              <path d="M2492 3649 c-45 -13 -108 -80 -121 -126 -7 -23 -11 -176 -11 -400 l0 -362 -383 -3 -383 -3 -41 -27 c-63 -41 -88 -90 -88 -169 0 -54 5 -72 27 -106 15 -22 44 -51 65 -64 l38 -24 382 -3 382 -3 3 -382 3 -382 24 -38 c13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 l24 38 3 382 3 382 383 3 382 3 41 27 c63 41 88 90 88 169 0 54 -5 72 -27 106 -15 22 -44 51 -65 64 l-38 24 -382 3 -382 3 -3 382 -3 382 -24 38 c-47 76 -151 113 -239 86z" />
                                            </g>
                                          </svg>
                                        </button>
                                      )}
                                      {ele?.follower?.includes(
                                        localStorage.getItem("id")
                                      ) && (
                                        <button
                                          onClick={() => {
                                            setUnfollowCollection({
                                              show: true,
                                              collection_id: ele?.id,
                                              user_id: user_id,
                                              follow: false,
                                            });
                                          }}
                                          className="deletebtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 511.3 511.55"
                                            width="512.000000pt"
                                            height="512.000000pt"
                                          >
                                            <path
                                              d="M237,.6c-1.9.2-7.8.9-13,1.5-79.1,9-152.2,58.6-192.4,130.5C17,158.8,6.4,191.4,1.9,224c-1.8,12.5-1.8,51.5,0,64C12.4,364.2,53,428.9,116.5,470.6c77.6,51,179.3,55,261.5,10.4,12.5-6.8,15.4-9.1,17.9-14.3,4.3-8.9,1.5-19.3-6.8-25.2-3.6-2.5-5.2-3-10.4-3-3.8,0-7.3.6-9.2,1.6-16.8,9.1-23.6,12.4-33,16.2a214,214,0,0,1-101.1,14.6C150,462.5,77.6,404.5,51.1,323.4c-14.8-45.2-14.8-89.6,0-134.8A214.81,214.81,0,0,1,188.9,51c44.9-14.7,89.4-14.7,134.5.1,48.8,16,89.3,48.1,117.1,92.9,16.2,26.2,27.3,59.5,30.5,91.9,4.5,46-7,94-32.6,135.1-5.6,9-5.9,9.8-5.9,16,0,7.8,2.6,12.7,8.8,16.8,5.6,3.7,15.3,3.9,21,.3,6-3.7,18.2-23.5,27.6-44.9A255.27,255.27,0,0,0,416.5,56.7C380.1,27.4,339.7,9.8,292,2.5,282,1,244.8-.3,237,.6Z"
                                              transform="translate(-0.55 -0.3)"
                                            />
                                            <path
                                              d="M362.8,245.3a26.3,26.3,0,0,0-6.5-6.4l-3.8-2.4-38.2-.3-35.39-.28H233.18l-35.48.28-38.3.3-4.1,2.7c-6.3,4.1-8.8,9-8.8,16.9,0,5.4.5,7.2,2.7,10.6a26.3,26.3,0,0,0,6.5,6.4l3.8,2.4,76.4.6h40.2l38.3-.3,38.2-.3,4.1-2.7c6.3-4.1,8.8-9,8.8-16.9C365.5,250.5,365,248.7,362.8,245.3Z"
                                              transform="translate(-0.55 -0.3)"
                                            />
                                            <path
                                              d="M235.9,276.1h0Z"
                                              transform="translate(-0.55 -0.3)"
                                            />
                                          </svg>
                                        </button>
                                      )}
                                      <img
                                        onClick={() => {
                                          setCollectionId(ele?._id);
                                          setItemCollection(ele?.items);
                                          {
                                            setCollectionActive({
                                              wantToDo: false,
                                              collection: false,
                                              collectionItem: true,
                                            });
                                          }
                                        }}
                                        src={ele?.cover_photo}
                                        alt="Wonderful Great Cabin"
                                      />
                                      {ele?.visibility === "Private" && (
                                        <MyPlansLock>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12.38"
                                            height="16.249"
                                            viewBox="0 0 12.38 16.249"
                                          >
                                            <path
                                              d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                              transform="translate(-6 -1.5)"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </MyPlansLock>
                                      )}
                                    </MyPlansImage>

                                    <MyPlansBoxTitle className="collection-lg-title">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "500",
                                        }}
                                        onClick={() => {
                                          setCollectionId(ele?._id);
                                          setItemCollection(ele?.items);
                                          setCollectionActive({
                                            wantToDo: false,
                                            collection: false,
                                            collectionItem: true,
                                          });
                                        }}
                                      >
                                        {ele?.name}
                                      </span>
                                    </MyPlansBoxTitle>
                                  </MyPlansBox>
                                )
                              );
                            }
                          )}
                      </MyPlansList>

                      {getUserProfileData?.data?.data?.data?.Collections?.filter(
                        (ele: any) => ele?.name !== "WANTS TO DO"
                      )?.length === 0 ? (
                        <RecordNotFound>
                          No Private,Public Friend and Following Collection
                          found!
                        </RecordNotFound>
                      ) : (
                        !getAlCollection?.collectionCollection &&
                        getUserProfileData?.data?.data?.data?.Collections?.filter(
                          (ele: any) => ele?.name !== "WANTS TO DO"
                        ).length > 5 && (
                          <Collectionbtn>
                            <Button
                              type="button"
                              onClick={() => {
                                setAllCollection({
                                  ...getAlCollection,
                                  collectionCollection: true,
                                });
                              }}
                              className="collection-btn"
                            >
                              More Collections...
                            </Button>
                          </Collectionbtn>
                        )
                      )}
                    </>
                  )}

                  {getCollectionActive?.collectionItem && (
                    <>
                      <>
                        <MyPlansTop>
                          <MyPlansTitle>Collection</MyPlansTitle>
                          <BackToList
                            onClick={() => {
                              setCollectionActive({
                                wantToDo: true,
                                collection: true,
                                collectionItem: false,
                              });
                            }}
                          >
                            <svg
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.55283 9.1117L15.4567 9.1117C15.945 9.1117 16.3398 9.5065 16.3398 9.99481C16.3398 10.4831 15.945 10.8779 15.4567 10.8779L6.54244 10.8779C6.05413 10.8779 5.65932 10.4831 5.65932 9.99481C5.65932 9.5065 6.06452 9.1117 6.55283 9.1117Z"
                                fill="#6C53E4"
                              />
                              <path
                                d="M9.52378 6.14041C9.75235 6.14041 9.98092 6.22352 10.1472 6.40015C10.49 6.743 10.49 7.30404 10.1472 7.65729L7.7991 10.0053L10.1472 12.3534C10.49 12.6962 10.49 13.2573 10.1472 13.6105C9.8043 13.9534 9.24326 13.9534 8.89001 13.6105L5.91858 10.6391C5.57573 10.2962 5.57573 9.73521 5.91858 9.38196L8.89001 6.41053C9.06663 6.23391 9.29521 6.14041 9.52378 6.14041Z"
                                fill="#6C53E4"
                              />
                              <path
                                d="M10.9997 1.86265e-08C16.5166 1.86265e-08 21.0049 4.48831 21.0049 9.9948C21.0049 15.5117 16.5166 20 10.9997 20C5.4828 20 0.994491 15.5117 0.994491 9.9948C1.00488 4.48831 5.49319 1.86264e-08 10.9997 1.86265e-08ZM10.9997 18.2234C15.5399 18.2234 19.2283 14.5351 19.2283 9.9948C19.2283 5.45455 15.5399 1.76623 10.9997 1.76623C6.45943 1.76623 2.77112 5.45455 2.77112 9.9948C2.7815 14.5351 6.46982 18.2234 10.9997 18.2234Z"
                                fill="#6C53E4"
                              />
                            </svg>
                            Back
                          </BackToList>
                        </MyPlansTop>
                        <MyPlansList>
                          {!getAlCollection?.collectionItemColletion &&
                            getCollectionItem?.slice(0, 5).map((ele: any) => {
                              return (
                                <MyPlansBox>
                                  <MyPlansImage>
                                    <img
                                      src={
                                        ele?.Image !== null && ele?.Image !== ""
                                          ? ele?.Image
                                          : LasVegas
                                      }
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                      alt={ele?.name}
                                    />
                                  </MyPlansImage>
                                  <MyPlansBoxTitle className="collection-lg-title">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                      }}
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                    >
                                      {ele?.name}
                                    </span>
                                  </MyPlansBoxTitle>
                                </MyPlansBox>
                              );
                            })}

                          {getAlCollection?.collectionItemColletion &&
                            getCollectionItem?.map((ele: any) => {
                              return (
                                <MyPlansBox>
                                  <MyPlansImage>
                                    <img
                                      src={
                                        ele?.Image !== null && ele?.Image !== ""
                                          ? ele?.Image
                                          : LasVegas
                                      }
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                      alt={ele?.name}
                                    />
                                  </MyPlansImage>
                                  <MyPlansBoxTitle className="collection-lg-title">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                      }}
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                    >
                                      {ele?.name}
                                    </span>
                                  </MyPlansBoxTitle>
                                </MyPlansBox>
                              );
                            })}
                        </MyPlansList>
                        {getCollectionItem?.length === 0 ? (
                          <RecordNotFound>
                            No Collection Items found!
                          </RecordNotFound>
                        ) : (
                          !getAlCollection?.collectionItemColletion &&
                          getCollectionItem?.length > 5 && (
                            <Collectionbtn>
                              <Button
                                type="button"
                                onClick={() => {
                                  setAllCollection({
                                    ...getAlCollection,
                                    collectionItemColletion: true,
                                  });
                                }}
                                className="collection-btn"
                              >
                                More Items...
                              </Button>
                            </Collectionbtn>
                          )
                        )}
                      </>
                    </>
                  )}
                </SocialProfileToDo>
              </>
            ) : (
              <SocialTabsWrap>
                <TabsWrap>
                  {getUserProfileData?.data?.data?.follower_status &&
                    getUserProfileData?.data?.data?.following_status && (
                      <Tabs
                        activeKey={getFollow}
                        onSelect={(eventkey: any) => {
                          setFollow(eventkey);
                          setSearchTerm("");
                        }}
                      >
                        <Tab eventKey="followers" title="Followers">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          value={searchTerm}
                                          handleBlur={handleBlur}
                                          onKeyPress={userNameHandler}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchTextData(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchFollowerss?.data?.data?.length === 0 &&
                                    searchTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No followers found!
                                      </SearchBoxNote>
                                    )}
                                  {getFollowerList?.data?.data?.length === 0 &&
                                    searchTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any follower.
                                        </p>
                                      </TabDataNotFound>
                                    )}

                                  {searchFollowerss?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}

                                  {(
                                    (searchTerm.trim() === "" &&
                                      getFollowerList?.data?.data) ||
                                    searchFollowerss?.data?.data
                                  )?.map((item: any, index: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage
                                            onClick={() =>
                                              navigateFollowers(item)
                                            }
                                          >
                                            <img
                                              src={item?.photo}
                                              alt={item?.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName
                                            onClick={() =>
                                              navigateFollowers(item)
                                            }
                                          >
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>
                                        {searchTerm.trim() === "" &&
                                          item?.follower_id ===
                                            localStorage.getItem("id") &&
                                          searchTerm.trim() !== "" &&
                                          item?._id !==
                                            localStorage.getItem("id") && (
                                            <Button className="btn-border">
                                              Remove
                                            </Button>
                                          )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="following" title="Following">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    searchText: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="searchText"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          value={searchFollowingTerm}
                                          handleBlur={handleBlur}
                                          onKeyPress={userNameHandler}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFollowing(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchApp?.data?.data?.length === 0 &&
                                    searchFollowingTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No following found!
                                      </SearchBoxNote>
                                    )}

                                  {searchApp?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}

                                  {(
                                    (searchFollowingTerm.trim() === "" &&
                                      getFollowingList?.data?.data) ||
                                    searchApp?.data?.data
                                  )?.map((item: any, i: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage
                                            onClick={() =>
                                              navigateFollowing(item)
                                            }
                                          >
                                            <img
                                              src={item?.photo}
                                              alt={item?.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName
                                            onClick={() =>
                                              navigateFollowing(item)
                                            }
                                          >
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>

                                        {searchFollowingTerm.trim() === "" &&
                                          item?.following_id ===
                                            localStorage.getItem("id") &&
                                          searchFollowingTerm.trim() !== "" &&
                                          item?._id !==
                                            localStorage.getItem("id") && (
                                            <Button className="btn-border-color">
                                              Following
                                            </Button>
                                          )}
                                      </li>
                                    );
                                  })}

                                  {getFollowingList?.data?.data?.length === 0 &&
                                    searchFollowingTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any following.
                                        </p>
                                      </TabDataNotFound>
                                    )}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="friends" title="Friends">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    searchFrnd: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="searchFrnd"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search"
                                          value={searchFriendTerm}
                                          handleBlur={handleBlur}
                                          onKeyPress={userNameHandler}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFriend(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchFriendList?.data?.data?.length === 0 &&
                                    searchFriendTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No Friends Found!
                                      </SearchBoxNote>
                                    )}

                                  {searchFriendList?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}

                                  {(
                                    (searchFriendTerm.trim() === "" &&
                                      getFriendsList?.data?.data) ||
                                    searchFriendList?.data?.data
                                  )?.map((item: any, i: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage
                                            onClick={() => navigateFriend(item)}
                                          >
                                            <img
                                              src={item?.photo}
                                              alt={item?.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName
                                            onClick={() => navigateFriend(item)}
                                          >
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>

                                        {searchFriendTerm.trim() === "" &&
                                          item?.friends_id ===
                                            localStorage.getItem("id") &&
                                          searchFriendTerm.trim() !== "" &&
                                          item?._id !==
                                            localStorage.getItem("id") && (
                                            <Button className="btn-border">
                                              Remove
                                            </Button>
                                          )}
                                      </li>
                                    );
                                  })}

                                  {getFriendsList?.data?.data?.length === 0 &&
                                    searchFriendTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any friends.
                                        </p>
                                      </TabDataNotFound>
                                    )}
                                </ul>

                                {/* <ul>
                              <SearchListLoader style={{ display: "none" }}>
                                <img src={PageLoaderIcon} alt="Loading..." />
                              </SearchListLoader>

                              {getAllFriendsHandler.length === 0 &&
                                searchFriendTerm.trim() === "" && (
                                  <TabDataNotFound>
                                    <p>You have not currently any friend.</p>
                                  </TabDataNotFound>
                                )}

                              {searchFriendTerm.trim() !== "" &&
                                getAllFriendsHandler.length === 0 && (
                                  <SearchBoxNote>
                                    No friends found!
                                  </SearchBoxNote>
                                )}
                            </ul> */}
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>
                      </Tabs>
                    )}
                </TabsWrap>
              </SocialTabsWrap>
            )}
          </SocialProfileWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
      <RemoveFriendModal
        show={removeFriendModalShow}
        onHide={() => setRemoveFriendModalShow(false)}
        setRemoveFriendModalShow={setRemoveFriendModalShow}
        user_id={user_id}
      />
      <UnfollowModal
        show={unfollowModalShow}
        onHide={() => setUnfollowModalShow(false)}
        setUnfollowModalShow={setUnfollowModalShow}
        user_id={user_id}
        setFollow={setFollow}
      />
      <DeleteCollectionItemModal
        show={getDeleteCollectionItemModal.show}
        onHide={() =>
          setDeleteCollectionItemModal({
            show: false,
            collection_id: "",
            item_id: "",
          })
        }
        getDeleteCollectionItemModal={getDeleteCollectionItemModal}
      />
      <UnFollowCollectionModal
        show={getUnfollowCollection.show}
        onHide={() =>
          setUnfollowCollection({
            show: false,
            collection_id: "",
            user_id: "",
            follow: false,
          })
        }
        getUnfollowCollection={getUnfollowCollection}
      />
    </>
  );
};

export default SocialProfile;
