import { copyDayToAnotherDayPlanAPI } from "../../../services/copyDayToAnotherDayPlan";

export enum CopyDayToAnotherDayPlanType {
  COPY_DAY_TO_ANOTHER_DAY_PLAN = "COPY_DAY_TO_ANOTHER_DAY_PLAN",
  COPY_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS = "COPY_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS",
  COPY_DAY_TO_ANOTHER_DAY_PLAN_FALIURE = "COPY_DAY_TO_ANOTHER_DAY_PLAN_FALIURE",
}

export const copyDayToAnotherDayPlan = () => (dispatch: any) => {
  dispatch({
    type: CopyDayToAnotherDayPlanType.COPY_DAY_TO_ANOTHER_DAY_PLAN,
  });
};

export const copyDayToAnotherDayPlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: CopyDayToAnotherDayPlanType.COPY_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS,
    data,
  });
};

export const copyDayToAnotherDayPlanFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: CopyDayToAnotherDayPlanType.COPY_DAY_TO_ANOTHER_DAY_PLAN_FALIURE,
      data: { msg },
    });
  };

export const copyDayToAnotherDayPlanHandler =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(copyDayToAnotherDayPlan());
      const result: any = await copyDayToAnotherDayPlanAPI(finalData);
      dispatch(copyDayToAnotherDayPlanSuccess(result));
    } catch (e: any) {
      dispatch(copyDayToAnotherDayPlanFailed(e?.response?.data?.message));
    }
  };
