import { myProfileModel } from "../../models/index";
import { MyProfileType } from "../../action/my-profile/myProfile";

export const initState: myProfileModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case MyProfileType.MYPROFILE_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case MyProfileType.MYPROFILE_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case MyProfileType.MYPROFILE_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
