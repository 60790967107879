import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Res from "../../../resource";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../../assets/images/logo.svg";
import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  AuthBoxTitle,
  FormLink,
  ModalBody,
  ModalBodyBtn,
  Spinner,
} from "./authStyle";
import { Formik } from "formik";
import CustomInput from "../../../components/CustomInput";
import {
  resetPasswordDataAPI,
  resetPasswordInfoSuccess,
  resetPasswordInfoFailed,
} from "../../../redux/action/resetPassword/resetPwd";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPwdLinkExpireData } from "../../../redux/action/resetPwd-linkExpire/resetPwdLink-Expire";
import jwt from "jwt-decode";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const queryParams = window.location.pathname;

  const decodeJwt: any =
    queryParams !== "/reset-password" ? jwt(queryParams) : "";

  const tokenArr = queryParams.split("/");

  const resetPwdLinkExpire = useSelector(
    (state: any) => state?.resetPwdLinkExpire
  );

  // useEffect(() => {
  //   if (queryParams) {
  //     // check token status api call
  //     // api response token expired => navigate to not found page
  //     // api response token valid show this page
  //     if (localStorage?.queryParams) {
  //       let localQuery = localStorage.getItem("queryParams");
  //       if (localQuery === queryParams) {
  //         getToast("expired");
  //         // navigate("/notFound");
  //       } else {
  //         setParams();
  //       }
  //     } else {
  //       setParams();
  //     }
  //   }
  // }, []);

  // const setParams = () => {
  //   localStorage.setItem("queryParams", queryParams);
  // };

  useEffect(() => {
    dispatch(resetPwdLinkExpireData({ email: decodeJwt.email }));
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEye, setShowEye] = useState(false);
  const [confirmPasswordShowEye, setConfirmPasswordShowEye] = useState(false);
  const [isToasting, setIsToasting] = useState(false);

  const user = useSelector((state: any) => state?.resetPassword);

  const handleEyeChange = () => {
    setShowEye(!showEye);
  };

  const handleEyeChangeConfirmPassword = () => {
    setConfirmPasswordShowEye(!confirmPasswordShowEye);
  };
  const resetData = useSelector((state: any) => state?.resetPassword);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        // case "success":
        //   setIsToasting(true);
        //   toast.success("Password reset successfully");
        //   closeToast();
        //   break;
        case "error":
          setIsToasting(true);
          toast.error(
            "Your new password cannot be the same as the old password"
          );
          closeToast();
          break;
        case "expired":
          setIsToasting(true);
          toast.error("Link Expired");
          closeToast();
          break;
        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!resetData?.isLoading) {
      if (resetData?.data) {
        // getToast("success");
        setTimeout(() => {
          // navigate("/");
          setSuccessfulResetModalShow(true);
          // setParams();
          dispatch({ type: "RESET_PASSWORD" });
          // dispatch(resetPasswordInfoFailed(""));
        }, 2000);
      } else if (resetPwdLinkExpire?.data?.data === true) {
        getToast("expired");
        setTimeout(() => {
          navigate("/");
        }, 4000);
      }
    }
  }, [resetData, resetPwdLinkExpire]);

  useEffect(() => {
    if (resetData?.msg) {
      getToast("error");
      dispatch(resetPasswordInfoSuccess(""));
    }
  }, [resetData?.msg]);

  const submitForm = (values: any) => {
    dispatch(
      resetPasswordDataAPI({
        resetToken: tokenArr[tokenArr.length - 1],
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      })
    );
  };

  const [successfulResetModalShow, setSuccessfulResetModalShow] =
    useState(false);

  function SuccessfulResetModal(props: any) {
    return (
      <Modal
        {...props}
        size="lg"
        className="successfulResetModal"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <style>
          {`
            .successfulResetModal{
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
            .successfulResetModal::-webkit-scrollbar {
              display: none !important;
            }
            .successfulResetModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 560px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .successfulResetModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header
            closeButton
            onClick={() => navigate("/")}
          ></Modal.Header>

          <Modal.Body>
            <div className="tripd-logo">
              <Link to="/">
                <img src={Logo} alt="Tripd" />
              </Link>
            </div>
            <h2>Successful Password Reset!</h2>
            <p>
              Your password has been changed successfully.
              <br /> Use your new password to log in.
            </p>
            <ModalBodyBtn>
              <Button onClick={() => navigate("/")} className="btn">
                Login
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      <AuthWrap>
        <Header />
        <AuthWrapInner className="forgotPasswordWrap">
          <AuthContainer>
            <AuthBoxTitle className="titleBox">
              <Link to="/">
                <img src={Logo} alt="Tripd" />
              </Link>

              <h3>Create New Password</h3>
              <p>
                Your new password must be different from
                <br /> previously used passwords.
              </p>
            </AuthBoxTitle>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validate={(values: any) => {
                let errors: any = {};
                const passwordRegex =
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,17}$/;
                if (!values.password) {
                  errors.password = "Please enter password";
                } else if (!passwordRegex.test(values.password)) {
                  errors.password =
                    "Password must be contain 1 special letter, 1 number, 1 capital and 1 lowercase letter.";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = "Please enter confirm password";
                } else if (!passwordRegex.test(values.confirmPassword)) {
                  errors.confirmPassword =
                    "The Password must be 8-16 characters and contain special characters, Numbers, Upper and lower case.";
                }
                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword =
                    "New password and Confirm password does not match";
                }
                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <CustomInput
                      inputid="password"
                      inputlabe=""
                      inputtype={showEye ? "text" : "password"}
                      placeholder="New Password"
                      value={values.password}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.password}
                      touched={touched.password}
                      handleEyeChange={handleEyeChange}
                      showEye={showEye}
                    />

                    <CustomInput
                      inputid="confirmPassword"
                      inputlabe=""
                      inputtype={confirmPasswordShowEye ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      handleEyeChange={handleEyeChangeConfirmPassword}
                      showEye={confirmPasswordShowEye}
                    />

                    <Button type="submit" className="auth-btn mb-0">
                      {user.isLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : null}
                      Reset Password
                    </Button>
                    <ToastContainer autoClose={5000} />

                    <button
                      type="button"
                      className="btn"
                      onClick={() => setSuccessfulResetModalShow(true)}
                      style={{
                        marginTop: "30px",
                        display: "none",
                        boxShadow: "none",
                      }}
                    >
                      Successful Password Reset Modal
                    </button>
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
      <SuccessfulResetModal
        show={successfulResetModalShow}
        onHide={() => setSuccessfulResetModalShow(false)}
      />
    </>
  );
}

export default ResetPassword;
