import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const HomeListingWrap = styled.div`
  background: #f0f0f0;
  padding: 45px 20px 22px;
  @media (max-width: 767px) {
    padding: 45px 15px 22px;
  }
`;

export const HomeListingTags = styled.div`
  margin-bottom: 10px;
  flex-wrap: wrap;
  display: flex;
  .btn {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 9px 14px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    min-width: 51px;
    cursor: pointer;
    outline: none;
    transition: all 0.35s ease 0s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: #6840df;
      color: #fff;
    }
    &.select {
      background: #6840df;
      color: #fff;
    }
    @media screen and (max-width: 1400px) {
      margin-right: 9px;
      margin-bottom: 9px;
      padding: 9px 12px;
      min-width: 45px;
    }
    @media (max-width: 565px) {
      padding: 5px 10px;
      font-size: 11px;
      line-height: 15px;
    }
  }
`;

export const CreateBtn = styled.div`
  text-align: right;
  .btn {
    background: #4d27da;
    border: 1px solid #4d27da;
    border-radius: 8px;
    padding: 6px 17px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    box-shadow: none;
    min-width: 122px;
    @media (max-width: 991px) {
      &:not(:last-child) {
        margin-right: 10px !important;
      }
    }
    @media (max-width: 565px) {
      min-width: auto;
      font-size: 12px;
      padding: 4px 10px;
    }
    svg {
      vertical-align: middle;
      margin-right: 10px;
      path {
        transition: all 0.35s ease 0s;
      }
      @media (max-width: 565px) {
        margin-right: 6px;
        width: 16px;
      }
    }
    &:hover {
      background: #000000;
      border-color: #000000;
      color: #fff;
      .plusIcon {
        fill: #000;
      }
    }
  }
`;

export const HomeListing = styled.div`
  ul {
    padding: 0;
    margin: 0 -10px;
    list-style-type: none;
    flex-wrap: wrap;
    display: flex;
    li {
      width: 25%;
      padding: 0 10px;
      margin-top: 20px;
      @media (max-width: 767px) {
        width: 33.33%;
      }
      @media (max-width: 565px) {
        width: 50%;
      }
    }
  }
`;

export const ListItem = styled.div`
  position: relative;
  padding-bottom: 137.9%;
  border-radius: 15px;
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
    transition: all 0.35s ease 0s;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(104, 64, 223, 0.25);
      border-radius: 15px;
      z-index: 1;
    }
    &:hover {
      img {
        transform: scale(1.05, 1.05);
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
    transition: all 0.35s ease 0s;
  }
`;

export const ListItemName = styled.span`
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #fff;
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 22px;
  text-transform: uppercase;
  z-index: 1;
  @media screen and (max-width: 1400px) {
    font-size: 17px;
    line-height: 25px;
    left: 17px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 565px) {
    font-size: 15px;
    line-height: 22px;
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 15px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.createNewWrap {
    .modal-body {
      padding-top: 18px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        color: #4d27da;
        margin-bottom: 25px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        border-bottom: 1px solid #9597a6;
        padding: 0 20px 20px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .btn {
          padding: 0;
          margin: 0;
          border-radius: 0;
          box-shadow: none;
          outline: none;
          font-family: "Poppins";
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          color: #3b3b3b;
          background: transparent;
          border: none;
          width: 100%;
          align-items: center;
          display: flex;
          transition: all 0.35s ease 0s;
          cursor: pointer;
          svg {
            vertical-align: middle;
            margin-right: 10px;
            path {
              transition: all 0.35s ease 0s;
            }
          }
          &:hover {
            color: #4d27da;
            color: #000;
            path {
              fill: #3b3b3b;
              fill: #000;
            }
          }
        }
      }
    }
  }

  &.shareWithWrap {
    .modal-body {
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
      }
      ${FormGroup} {
        margin-bottom: 0;
        .form-label {
          font-family: Poppins;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.createPlanWrap {
    .modalTitle {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      text-align: center;
      max-width: 410px;
      margin: auto auto 17px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        transition: all 0.35s ease 0s;
        svg {
          margin-right: 15px;
          path,
          circle {
            transition: all 0.35s ease 0s;
          }
        }
        &:hover {
          color: #000;
          circle {
            fill: #000;
          }
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .react-datepicker-popper {
          &[data-placement^="bottom"] {
            padding-top: 5px;
          }
        }
        .react-datepicker {
          border: 1px solid #f0f0f0;
          border-radius: 8px;
          box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.1);
          padding: 11px 20px;
          width: 291px;
          .react-datepicker__triangle {
            display: none;
          }
          .react-datepicker__navigation--previous {
            left: 7px;
            .react-datepicker__navigation-icon--previous {
              &:before {
                left: -4px;
                right: auto;
              }
            }
          }
          .react-datepicker__navigation--next {
            right: 8px;
            .react-datepicker__navigation-icon--next {
              &:before {
                right: -4px;
                left: auto;
              }
            }
          }
          .react-datepicker__navigation-icon {
            padding: 0;
            &:before {
              border-width: 2px 2px 0 0;
              border-color: #3b3b3b;
              height: 8px;
              width: 8px;
            }
          }
          .react-datepicker__month {
            margin: 0;
          }
          .react-datepicker__header {
            background-color: #ffffff;
            border-bottom: none;
            padding: 0 0 20px;
            .react-datepicker__current-month,
            .react-datepicker-year-header,
            .react-datepicker-time__header {
              color: #0e1224;
              font-family: "Poppins";
              font-size: 12px;
              line-height: 17px;
              font-weight: 500;
              border-bottom: 1px solid #f5f5f6;
              padding-bottom: 10px;
              margin-bottom: 10px;
              margin-top: -3px;
            }
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
              color: #3b3b3b;
              font-family: "Poppins";
              font-size: 12px;
              line-height: 19px;
              font-weight: 400;
            }
          }
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            margin: 3px 4px;
            height: 27px;
            width: 27px;
            font-family: "Poppins";
            font-size: 13px;
            line-height: 24px;
            font-weight: normal;
            color: #0e1224;
            border-radius: 30px;
            border: 2px solid transparent;
            transition: all 0.35s ease 0s;
          }
          .react-datepicker__day--selected,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range,
          .react-datepicker__month-text--selected,
          .react-datepicker__month-text--in-selecting-range,
          .react-datepicker__month-text--in-range,
          .react-datepicker__quarter-text--selected,
          .react-datepicker__quarter-text--in-selecting-range,
          .react-datepicker__quarter-text--in-range,
          .react-datepicker__year-text--selected,
          .react-datepicker__year-text--in-selecting-range,
          .react-datepicker__year-text--in-range {
            border-radius: 50%;
            border: 2px solid #6840df;
            background-color: transparent;
            color: #3b3b3b;
          }
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected,
          .react-datepicker__day:hover,
          .react-datepicker__month-text:hover,
          .react-datepicker__quarter-text:hover,
          .react-datepicker__year-text:hover {
            border-radius: 50%;
            border: 2px solid #6840df;
            background-color: transparent;
            color: #3b3b3b;
          }
          .react-datepicker__day--outside-month {
            color: #8d8d8d;
          }
        }
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          z-index: 1;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }

    .planFeature {
      padding: 0 10px;
      margin: 17px 0 0;
      list-style-type: none;
      > li {
        font-family: "Poppins";
        font-size: 12px;
        line-height: 19px;
        font-weight: 400;
        color: #a1a3b0;
        padding-right: 50px;
        margin-bottom: 15px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        h4 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 0;
        }
        .form-switch {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
          min-height: inherit;
          .form-check-input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            z-index: 1;
            cursor: pointer;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            opacity: 0;
          }
          .form-check-label {
            border: 1px solid #8d8d8d;
            border-radius: 30px;
            width: 38px;
            height: 22px;
            background: transparent;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;
            text-indent: -99px;
            overflow: hidden;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 20px;
              background: #cecece;
              display: inline-block;
              position: absolute;
              left: 2px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.25s ease 0s;
            }
          }
          .form-check-input {
            &:checked {
              + .form-check-label {
                &:before {
                  left: 18px;
                  background: #48d85e;
                }
              }
            }
          }
        }
        .planVisibility {
          margin: 10px -10px 0;
          padding: 0;
          list-style-type: none;
          flex-wrap: wrap;
          display: flex;
          > li {
            padding: 0 10px;
            text-align: left;
            width: 50%;
            margin: 20px 0 0;
            &:first-child,
            &:nth-child(2) {
              margin-top: 0;
            }
          }
        }
      }
    }
    .friendsList {
      padding: 5px 0 3px;
      margin: 0 -10px;
      list-style-type: none;
      flex-wrap: wrap;
      align-items: flex-start;
      display: flex;
      > li {
        width: 33.33%;
        padding: 0 10px;
        margin: 0 0 17px;
      }
    }
  }

  &.createGroupWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        margin-right: 10px;
        margin-top: 4px;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        svg {
          margin-right: 15px;
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
        .react-datepicker {
          box-shadow: 0px 3px 6px #0000291a;
          border: 1px solid #f0f0f0;
          border-radius: 8px;
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }
  }

  &.inviteOthersWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
    }
  }

  &.collectionsWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 20px;
    }
    ul {
      margin: 0 -10px 20px;
      padding: 0;
      list-style-type: none;
      max-height: 470px;
      overflow-y: auto;
      flex-wrap: wrap;
      display: flex;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #cecece;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
      }

      @media screen and (max-width: 1440px) {
        max-height: 335px;
      }

      li {
        margin: 0 0 20px;
        padding: 0 10px;
        width: 50%;
        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }

  &.plansCreatedWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 20px;
    }
  }

  &.chatPlansGroupWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 20px;
    }
  }

  &.deleteUserWrap {
    text-align: center;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      color: #4d27da;
      margin-bottom: 20px;
    }
    .modal-body {
      p {
        margin-bottom: 0;
      }
      .btn {
        min-width: 134px;
        + .btn {
          margin-left: 15px;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const PlanChatsGroups = styled.div`
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-bottom: 18px;
  margin: 0 0 20px;
  &:last-child {
    margin-bottom: 0;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #8d8d8d;
    margin: 0 0 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0 45px 0 0;
    list-style-type: none;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 40px;
        vertical-align: middle;
      }
      .ChatsTotalMember {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const GroupsSingleChatImage = styled.div`
  padding-right: 15px;
  align-items: center;
  display: flex;
  img {
    width: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0;
    padding: 0 0 0 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 10px 0 0;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      .totalFriends {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const CreatePlanDate = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 20px;
  display: flex;
  img {
    margin-right: 10px;
  }
`;

export const ButtonTabs = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TabButton = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  border-radius: 50px;
  padding: 3px 15px;
  cursor: default;
  margin: 0 5px;
  height: 34px;
  display: flex;
  align-items: center;
  transition: all 0.35s ease 0s;
  &.active {
    font-weight: 500;
    color: #4d27da;
    border: 2px solid #4d27da;
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const InviteOtherSearch = styled.div`
  margin: 0 0 22px;
  width: 340px;
  max-width: 340px;
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #9597a5;
      border-radius: 5px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      padding: 8px 45px 8px 15px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 17px;
      top: 7px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
    .addIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 7px;
      top: 34px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        circle,
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
  }
  &.inviteEmailBox {
    ${FormGroup} {
      margin-bottom: 10px;
    }
  }
  .addAnotherEmail {
    padding: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: normal;
    color: #4d27da;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
`;

export const MyGroupTabContent = styled.div`
  min-height: 305px;
`;

export const MyGroupTabContentInner = styled.div`
  display: flex;
`;

export const InviteMyGroups = styled.div`
  width: 340px;
  max-width: 100%;
  > ul {
    margin: 0;
    padding: 2px 0 0;
    list-style-type: none;
    > li {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupItem = styled.div`
  position: relative;
  padding-left: 35px;
  display: inline-block;
  vertical-align: middle;
  ${FriendItem} {
    h3 {
      font-size: 12px;
      line-height: 17px;
      margin: 0 0 10px;
    }
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -16px;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupMembers = styled.div`
  padding-left: 35px;
  flex: 1;
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupMemberItem = styled.div`
  padding: 0 0 0 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersLeft = styled.div`
  width: 340px;
  max-width: 100%;
  ${InviteOtherSearch} {
    margin-bottom: 20px;
  }
  ${FormGroup} {
    .form-label {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 8px;
    }
    .searchIconBtn {
      top: 34px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const MyFollowersItemWrap = styled.div`
  position: relative;
  padding-right: 30px;
  ${InviteGroupCheckBox} {
    span {
      left: auto;
      right: 0;
    }
  }
`;

export const MyFollowersItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersRight = styled.div`
  padding: 43px 0 0 59px;
  flex: 1;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 20px;
  }
  ${MyFollowersItemWrap} {
    padding-right: 0;
  }
`;

export const CollectionsItem = styled.div`
  img {
    max-width: 100%;
  }
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 5px 0 0 0;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const CollectionsItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const ModalAddBtn = styled.div`
  text-align: center;
  .btn {
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: transparent;
    color: #3b3b3b;
    padding: 0;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
`;

export const PlansTabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: none;
    padding: 0;
    margin: 0 0 30px;
    display: flex;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 0 10px 13px;
      min-width: 110px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        color: #6840df;
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  ${TabsContent} {
    padding: 0;
    margin: 0 -10px;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
  }

  &.chatPlansTabs {
    .nav-tabs {
      margin-bottom: 20px;
    }
    ${PlanChatsGroups} {
      padding-left: 20px;
      padding-right: 20px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      h4 {
        display: block;
        font-weight: 500;
      }
    }

    ${GroupsSingleChatImage} {
      padding: 0 20px;
      margin-bottom: 25px;
    }
  }
`;

export const PlansItem = styled.div`
  margin: 0 0 20px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const PlansItemImageWrap = styled.div`
  margin: 0;
  width: 160px;
  min-width: 160px;
  max-width: 100%;
`;

export const PlansItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const PlansItemInfo = styled.div`
  margin: 0;
  padding-left: 20px;
  flex: 1;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  color: #8d8d8d;
  h3 {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0 !important;
  }
`;

export const PlansInfoDate = styled.div`
  margin: 20px 0 0;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #3b3b3b;
`;

export const RadioBox = styled.div`
  display: inline-block;
  padding: 0 0 0 33px;
  margin: 0;
  min-height: 16px;
  position: relative;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #a1a3b0;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
  label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    display: block;
    margin: 0 0 2px;
    img {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
`;
