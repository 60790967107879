import { getFollowingAPI } from "../../../services/getFollowing";

export enum GetFollowingType {
  GETFOLLOWING_INFO = "GETFOLLOWING_INFO",
  GETFOLLOWING_INFO_SUCCESS = "GETFOLLOWING_INFO_SUCCESS",
  GETFOLLOWING_INFO_FALIURE = "GETFOLLOWING_INFO_FALIURE",
}

export const getFollowingInfo = () => (dispatch: any) => {
  dispatch({
    type: GetFollowingType.GETFOLLOWING_INFO,
  });
};

export const getFollowingInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetFollowingType.GETFOLLOWING_INFO_SUCCESS,
    data,
  });
};

export const getFollowingInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: GetFollowingType.GETFOLLOWING_INFO_FALIURE,
    error: msg,
  });
};

export const getFollowingInfoData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getFollowingInfo());
    const result: any = await getFollowingAPI(id);
    dispatch(getFollowingInfoSuccess(result));
  } catch (e: any) {
    dispatch(getFollowingInfoFailed(e.response.data.error));
  }
};
