import { ExitGroupeType } from "../../action/exit-group/exitGroup";
import { exitGroupModal } from "../../models/index";

export const initState: exitGroupModal = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case ExitGroupeType.EXIT_GROUP_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case ExitGroupeType.EXIT_GROUP_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case ExitGroupeType.EXIT_GROUP_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
        data: null,
      };
    }
    default:
      return state;
  }
};
