import { helpAndFaqType } from "../../action/help-faq-list/helpAndFaq";
import { helpAndFaqListModel } from "../../models/index";

export const initState: helpAndFaqListModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case helpAndFaqType.HELP_AND_FAQ_LIST: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case helpAndFaqType.HELP_AND_FAQ_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case helpAndFaqType.HELP_AND_FAQ_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }

    default:
      return state;
  }
};
