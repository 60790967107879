import { useState } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GlobalDataType } from "../../redux/action/globalData";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
  PageLoader,
  FriendItem,
} from "../createGroupModal/createGroupModalStyle";
import PageLoaderIcon from "../../assets/images/page-loader.svg";
import CustomInput from "../CustomInput";
import { Formik } from "formik";
import { Group, Hiking } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { createGroupHandler } from "../../redux/action/create-group/createGroup";

const CreateGroupModal = (props: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const submitForm = (values: any) => {
    const removeLoginId = props.getAllId.filter(
      (ele: any) => ele !== localStorage.getItem("id")
    );

    const finalData = {
      name: values.name.trim(),
      description: values.description.trim(),
      invited_user: removeLoginId,
      emails: props.getAllEmail,
      notification_type: "Request To Join Group",
      is_requested: true,
    };
    props.setCreateGroupModalShow(false);
    props.setCreateNewModalShow(false);
    dispatch(createGroupHandler(finalData));
  };
  const addGroup = useSelector((state: any) => state?.createGroup);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="createGroupModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .createGroupModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 560px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .createGroupModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          {addGroup?.isLoading && (
            <PageLoader>
              <img src={PageLoaderIcon} alt="Loading..." />
            </PageLoader>
          )}
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.5"
                height="22.5"
                viewBox="0 0 22.5 22.5"
              >
                <g>
                  <g>
                    <path
                      d="M6,6V22.875h6.75V28.5l5.625-5.625H28.5V6ZM26.25,20.625H17.444l-.659.659L15,23.069V20.625H8.25V8.25h18Z"
                      transform="translate(-6 -6)"
                      fill="#4d27da"
                    />
                    <circle
                      cx="1.688"
                      cy="1.688"
                      r="1.688"
                      transform="translate(3.938 6.75)"
                      fill="#4d27da"
                    />
                    <circle
                      cx="1.688"
                      cy="1.688"
                      r="1.688"
                      transform="translate(9.563 6.75)"
                      fill="#4d27da"
                    />
                    <circle
                      cx="1.688"
                      cy="1.688"
                      r="1.688"
                      transform="translate(15.188 6.75)"
                      fill="#4d27da"
                    />
                  </g>
                </g>
              </svg>
              CREATE A GROUP
            </h2>

            <Button
              className="btn addFriendsBtn"
              onClick={() => {
                props.setInviteOthersModalShow(true);
                props.setEmailValidate("");
                props.setEmail("");
                props.setOpenTab({
                  follower: false,
                  group: true,
                  friend: false,
                  email: false,
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <defs>
                  <clipPath>
                    <rect width="21.911" height="21.731" fill="#fff" />
                  </clipPath>
                </defs>
                <circle cx="24" cy="24" r="24" fill="#4d27da" />
                <g transform="translate(13.045 13.134)">
                  <g clip-path="url(#clip-path)">
                    <path
                      d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                      transform="translate(-219.503 -75.638)"
                      fill="#fff"
                    />
                    <path
                      d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                      transform="translate(-19.846 -182.45)"
                      fill="#fff"
                    />
                    <path
                      d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              ADD FRIENDS
            </Button>
            <ul className="friendsList">
              {props?.pendingEmailRequest?.map((ele: any) => (
                <li>
                  <FriendItem>
                    {ele?.photo && (
                      <img
                        src={
                          ele?.photo.includes("http") ||
                          ele?.photo.includes("https")
                            ? ele?.photo
                            : `${props?.getImageUrl}${ele?.photo}`
                        }
                        alt={ele?.firstName}
                      />
                    )}

                    {ele?.emailPhoto && (
                      <img src={ele?.emailPhoto} alt="email" />
                    )}

                    {ele?.firstName && ele?.lastName && (
                      <h3>{`${ele?.firstName} ${ele?.lastName}`}</h3>
                    )}

                    {ele?.userEmail && <h3>{`${ele?.userEmail}`}</h3>}
                  </FriendItem>
                </li>
              ))}
            </ul>
            <Formik
              initialValues={{
                name: "",
                description: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                if (!values.name) {
                  errors.name = "Please enter your group name";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <CustomInput
                        inputid="name"
                        inputlabe=""
                        maxLength={40}
                        inputtype="text"
                        placeholder="Name your Group"
                        value={values.name.trimStart()}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors.name}
                        touched={touched.name}
                      />
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <CustomInput
                        inputid="description"
                        inputlabe=""
                        inputtype="textarea"
                        as="textarea"
                        placeholder="Add notes or description"
                        value={values.description.trimStart()}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors=""
                        touched=""
                        maxLength={255}
                      />
                    </FormGroup>
                    <ModalBodyBtn>
                      <Button className="btn" type="submit">
                        CREATE
                      </Button>
                    </ModalBodyBtn>
                  </form>
                </>
              )}
            </Formik>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateGroupModal;
