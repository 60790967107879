import React, { useState } from "react";
import Header from "../../../components/Header";
import { Dropdown, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";

import CreateNewModal from "../../../components/createNewModal/index";
import ImageGalleryModal from "../../../components/imageGalleryModal/index";

import RentalMainImage from "../../../assets/images/rentals-detail-main.jpg";
import RentalGallery from "../../../assets/images/rentals-detail-gallery.jpg";
import RentalThumbOne from "../../../assets/images/rentals-detail-thumb.jpg";
import RentalThumbTwo from "../../../assets/images/rentals-detail-thumb-1.jpg";
import RentalThumbThree from "../../../assets/images/rentals-detail-thumb-2.jpg";

import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsGallery,
  SportsGalleryTop,
  SportsGalleryBottom,
  SportsGalleryItem,
  SportsTitleWrap,
  SportsTitle,
  SportsInfo,
  SportsInfoWrap,
  SportsReview,
  SportsRating,
  SportsPrice,
  SportsTeamDesc,
  RentalsFeature,
} from "../rentalsResultsDetail/rentalsResultsDetailStyle";

const RentalsResultsDetail = (props: any) => {
  const [createNewModalShow, setCreateNewModalShow] = React.useState(false);

  const [imageGalleryModalShow, setImageGalleryModalShow] = useState(false);

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainSearchPanel />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn">
                All
              </Link>
              <Link to="/concertsresults" className="btn">
                Concerts
              </Link>
              <Link to="/foodanddrinks" className="btn">
                Food &amp; Drinks
              </Link>
              <Link to="/festivalsresults" className="btn">
                Festivals
              </Link>
              <Link to="/sportsresults" className="btn">
                Sports
              </Link>
              <Link to="/rentalsresults" className="btn select">
                Rentals
              </Link>
              <Link to="/" className="btn">
                Hotels
              </Link>
              <Link to="/interestresults" className="btn">
                Points of Interest
              </Link>
              <Link to="/hiking" className="btn">
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                <button
                  type="submit"
                  className="btn"
                  onClick={() => setCreateNewModalShow(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                  >
                    <g transform="translate(-1016 -337)">
                      <path
                        d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                        transform="translate(1016 337)"
                        fill="#fff"
                      />
                      <path
                        d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                        transform="translate(1020.143 350.845)"
                        fill="#4d27da"
                        stroke="#fff"
                        stroke-width="0.5"
                        className="plusIcon"
                      />
                    </g>
                  </svg>
                  CREATE
                </button>
              </CreateBtnInner>
            </CreateBtn>
            <SportsDetails>
              <SportsDetailsTop>
                <SportsLogo>
                  <SportsLogoInner>
                    <img src={RentalMainImage} alt="Rentals Detail Main" />
                  </SportsLogoInner>
                </SportsLogo>
                <SportsGallery>
                  <SportsGalleryTop>
                    <img src={RentalGallery} alt="Rentals Detail Gallery" />
                  </SportsGalleryTop>
                  <SportsGalleryBottom>
                    <SportsGalleryItem>
                      <img src={RentalThumbOne} alt="Rentals Detail" />
                    </SportsGalleryItem>
                    <SportsGalleryItem>
                      <img src={RentalThumbTwo} alt="Rentals Detail" />
                    </SportsGalleryItem>
                    <SportsGalleryItem>
                      <img src={RentalThumbThree} alt="Rentals Detail" />
                      <button
                        type="submit"
                        className="btn"
                        onClick={() => setImageGalleryModalShow(true)}
                      >
                        +19 Photos
                      </button>
                    </SportsGalleryItem>
                  </SportsGalleryBottom>
                </SportsGallery>
              </SportsDetailsTop>
              <SportsTitleWrap>
                <SportsTitle>
                  Napa Sans Souci - walk to downtown Napa - private gradens
                </SportsTitle>
                <Dropdown>
                  <Dropdown.Toggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                      <path
                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                        transform="translate(2.459 16.62)"
                        fill="#3b3b3b"
                      ></path>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as="button">Share to Chat</Dropdown.Item>
                    <Dropdown.Item as="button">Add to Plans</Dropdown.Item>
                    <Dropdown.Item as="button">Add to collection</Dropdown.Item>
                    <Dropdown.Item as="button">Create Plans</Dropdown.Item>
                    <Dropdown.Item as="button">Share With</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </SportsTitleWrap>
              <RentalsFeature>
                <ul>
                  <li>Sleeps 2</li>
                  <li>1 Bedroom</li>
                  <li>1 Bed</li>
                </ul>
              </RentalsFeature>
              <SportsInfo>
                <SportsInfoWrap>
                  <SportsRating>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Component_44_16"
                      data-name="Component 44 – 16"
                      width="22"
                      height="20.364"
                      viewBox="0 0 19 17.364"
                    >
                      <path
                        id="Polygon_1"
                        data-name="Polygon 1"
                        d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                        fill="#ffc126"
                      />
                    </svg>
                    4.5
                  </SportsRating>
                  <SportsReview>175 Reviews</SportsReview>
                </SportsInfoWrap>
                <SportsPrice>$150/Night</SportsPrice>
              </SportsInfo>
              <SportsTeamDesc>
                <p>
                  Private entrance to beautiful upstairs spacious guest suite
                  with queen size bed and private bath. The suite is part of a
                  lovely house located in a secluded quiet neighborhood off
                  Silverado Trail, in walking distance to downtown, providing
                  easy access to top restaurants and wineries. A walking trail
                  along the Napa River is located 3 blocks away. Lounge on the
                  deck or in the hot tub and enjoy the peace and quiet amongst
                  the lush landscape.
                </p>
                <p>
                  Hosted by <strong>Cynthia Kasten</strong>
                </p>
                <Button type="button" className="btn">
                  VIEW MORE DETAILS
                </Button>
              </SportsTeamDesc>
            </SportsDetails>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
      />

      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
      />
    </>
  );
};

export default RentalsResultsDetail;
