import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 22px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    @media (max-width: 480px) {
      min-width: 100px;
    }
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
      @media (max-width: 480px) {
        margin-left: 10px;
      }
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 10px;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      .totalFriends {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const ButtonTabs = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 565px) {
    flex-wrap: wrap;
  }
`;

export const TabButton = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  border-radius: 50px;
  padding: 3px 15px;
  cursor: default;
  margin: 0 5px;
  height: 34px;
  display: flex;
  align-items: center;
  transition: all 0.35s ease 0s;
  cursor: pointer;
  @media (max-width: 565px) {
    margin-bottom: 10px;
  }
  &.active {
    font-weight: 500;
    color: #4d27da;
    border: 2px solid #4d27da;
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const InviteOtherSearch = styled.div`
  margin: 0 0 20px;
  width: 340px;
  max-width: 340px;
  @media (max-width: 767px) {
    width: 300px;
    max-width: 300px;
  }
  @media (max-width: 565px) {
    width: 100%;
    max-width: 100%;
  }
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #9597a5;
      border-radius: 5px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      padding: 8px 45px 8px 15px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 17px;
      top: 7px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
    .addIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 7px;
      top: 34px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        circle,
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
  }

  &.inviteEmailBox {
    ${FormGroup} {
      margin-bottom: 10px;
      .form-control {
        padding-right: 37px;
      }
    }
  }

  .addAnotherEmail {
    padding: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: normal;
    color: #4d27da;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
`;

export const MyGroupTabContent = styled.div``;

export const MyGroupTabContentInner = styled.div`
  display: flex;
  min-height: 280px;
  @media (max-width: 565px) {
    flex-wrap: wrap;
  }
`;

export const InviteMyGroups = styled.div`
  width: 340px;
  max-width: 100%;
  max-height: 340px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }

  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 565px) {
    width: 100%;
  }
  > ul {
    margin: 0;
    padding: 2px 0 0;
    list-style-type: none;
    > li {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .noRecordFound {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    color: #a1a3b0;
    padding: 0;
  }
`;

export const InviteGroupMembers = styled.div`
  padding-left: 35px;
  flex: 1;
  @media (max-width: 565px) {
    padding-left: 0;
    padding-top: 25px;
    flex: 100%;
  }
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin-bottom: 10px;
  }
  ul {
    margin: 0 -10px 25px;
    padding: 0 10px;
    list-style-type: none;
    max-height: 140px;
    overflow-y: auto;
    &:last-child {
      margin-bottom: 0;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -16px;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupItem = styled.div`
  position: relative;
  padding-left: 35px;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupMemberItem = styled.div`
  padding: 0 0 0 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersLeft = styled.div`
  width: 340px;
  max-width: 100%;
  @media (max-width: 767px) {
    width: 300px;
  }
  @media (max-width: 565px) {
    width: 100%;
  }
  ${FormGroup} {
    .form-label {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 8px;
      @media (max-width: 350px) {
        font-size: 11px;
      }
    }
    .searchIconBtn {
      top: 34px;
    }
  }
  ul {
    margin: 0 -13px;
    padding: 0 13px;
    list-style-type: none;
    max-height: 340px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }

    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const MyFollowersItemWrap = styled.div`
  position: relative;
  padding-right: 0;
  ${InviteGroupCheckBox} {
    span {
      left: auto;
      right: 0;
    }
  }
`;

export const MyFollowersRight = styled.div`
  padding: 43px 0 0 59px;
  flex: 1;
  @media (max-width: 767px) {
    padding: 43px 0 0 25px;
  }
  @media (max-width: 565px) {
    padding: 25px 0 0 0;
    flex: 100%;
  }
  ul {
    margin: 0 -8px;
    padding: 0 8px;
    list-style-type: none;
    max-height: 340px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    + h3 {
      margin-top: 25px;
    }
  }
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 20px;
  }

  &.emailInvitation {
    ul {
      max-height: 190px;
    }
  }
`;

export const MyFollowersItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.noRecordFound {
      font-size: 12px;
      line-height: 18px;
      color: #a1a3b0;
      padding: 0;
    }
  }
`;
