import { resetPasswordModel } from "../../models/index";
import { ResetPasswordType } from "../../action/resetPassword/resetPwd";

export const initState: resetPasswordModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case ResetPasswordType.RESET_PASSWORD_INFO: {
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
      };
    }
    case ResetPasswordType.RESET_PASSWORD_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        msg: null,
      };
    }
    case ResetPasswordType.RESET_PASSWORD_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data.msg,
        data: null,
      };
    }
    case "RESET_PASSWORD": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
