import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import { deletePlanData } from "../../redux/action/deletePlan/deletePlan";

import {
  ModalBody,
  ModalBodyBtn,
  ModalMemberGroupList,
  ModalMemberGroupItem,
  InviteGroupRadioBox,
} from "../deleteGroupModal/deleteGroupModalStyle";

const DeleteGroupModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const deleteGroupHandler = () => {
    props.onHide();
    const data = {
      plan_id: props.planId,
      user_id: props.deleteGroupId,
    };

    props.deleteGroupId !== "" &&
      dispatch(deletePlanData(data)) &&
      navigate("/myplans");

    props.setDeleteGroupId("");
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="deleteGroupModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .deleteGroupModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 476px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .deleteGroupModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Delete Group</h2>
            <p className="text-center">
              Are you sure you want to leave this plan? By leaving this plan you
              will also be removed from the chat for this plan.
            </p>

            <ModalMemberGroupList>
              {props.group?.map((ele: any, index: any, arr: any) => {
                return (
                  <ModalMemberGroupItem>
                    <InviteGroupRadioBox>
                      <input
                        type={arr.length === 1 ? "checkbox" : "radio"}
                        name="group"
                        value={ele?.id}
                        onChange={(e: any) => {
                          e.target.checked === true
                            ? props.setDeleteGroupId(e.target.value)
                            : props.setDeleteGroupId("");
                        }}
                      />
                      <span />
                    </InviteGroupRadioBox>

                    <img src={ele?.photo} alt="jason-pamel" />
                    <h4>{ele?.name}</h4>
                  </ModalMemberGroupItem>
                );
              })}
            </ModalMemberGroupList>

            <ModalBodyBtn>
              <Button className="cancelBtn" onClick={props.onHide}>
                CANCEL
              </Button>
              <Button
                disabled={props.deleteGroupId === "" ? true : false}
                onClick={() => deleteGroupHandler()}
              >
                DELETE GROUP
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteGroupModal;
