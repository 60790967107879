import { getAllFriendsAPI } from "../../../services/getAllFriends";

export enum getAllFriendsType {
  GETALL_FRIENDS_INFO = "GETALL_FRIENDS_INFO",
  GETALL_FRIENDS_INFO_SUCCESS = "GETALL_FRIENDS_INFO_SUCCESS",
  GETALL_FRIENDS_INFO_FALIURE = "GETALL_FRIENDS_INFO_FALIURE",
}

export const getAllFriendInfo = () => (dispatch: any) => {
  dispatch({
    type: getAllFriendsType.GETALL_FRIENDS_INFO,
  });
};

export const getAllFriendInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getAllFriendsType.GETALL_FRIENDS_INFO_SUCCESS,
    data,
  });
};

export const getAllFriendInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getAllFriendsType.GETALL_FRIENDS_INFO_FALIURE,
    error: msg,
  });
};

export const getAllFriendData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getAllFriendInfo());
    const result: any = await getAllFriendsAPI(id);
    dispatch(getAllFriendInfoSuccess(result));
  } catch (e: any) {
    dispatch(getAllFriendInfoFailed(e?.response?.data?.error));
  }
};
