import { useState, useEffect } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import { io } from "socket.io-client";

import {
  TabsContent,
  ModalBody,
  PlanChatsGroups,
  GroupsSingleChatImage,
  PlansTabsWrap,
} from "../chatsPlanModal/chatsPlanModalStyle";
import { mapKey } from "../../resource/constants";
import { dynamicChatInfoSuccess } from "../../redux/action/dynamic-chat/dynamicChat";

const ChatsPlanModal = (props: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const chatModalGroup = useSelector((state: any) => state?.createAllGroup);

  const planList = useSelector((state: any) => state?.planListing);

  const [getFollow, setFollow] = useState("plans");
  const [groupListData, setGroupListData] = useState<any[]>([]);

  let temArr: any = [];

  useEffect(() => {
    if (getFollow === "plans" && planList?.data?.data[0]?.PlanList) {
      temArr.splice(0, temArr.length);
      planList?.data?.data[0]?.PlanList?.map((item: any) => {
        temArr.push(item._id);
      });
      // const loginId = localStorage.getItem("id");
      // const user_id = loginId;
      // const rooms_id = temArr;

      // socket?.emit("UnreadMsg", { user_id, rooms_id });
      // socket?.on("UnreadMsgCount", (count: any) => {
      //   setEventArr(count.countArray);
      // });
      setGroupListData(planList?.data?.data[0]?.PlanList);
    }
  }, [getFollow, planList?.data?.data[0]?.PlanList]);

  const [socket, setSocket] = useState<any | null>(null);

  const local: any = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (
      socket === null &&
      Object.keys(local).length > 0 &&
      props?.dynamicLocation
    ) {
      const socketUrl: any = process.env.REACT_APP_SOCKET_API_KEY;

      setSocket(io(socketUrl));
    }
  }, [socket, props]);

  const groupSave = (item: any) => {
    if (props?.name === "Collection") {
      const room = item?._id;
      const username = local.user_name;
      const user_id = local.id;
      socket.emit("joinPlan", { username, room, user_id });
      if (
        props?.dynamicLocation === "/foodanddrinks" ||
        props?.dynamicLocation === "/interestresults" ||
        props?.dynamicLocation === "/hiking"
      ) {
        let finaldata = {
          username: local.user_name,
          image: props?.groupImages?.photos
            ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${props?.groupImages?.photos[0]?.photo_reference}&key=${mapKey}`
            : props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          item: props?.groupImages,
          name: props?.groupImages?.name,
          sender_id: local.id,
        };

        socket.emit("chatMessage", finaldata);
      } else if (
        props?.dynamicLocation === "/rentalsresults" ||
        props?.dynamicLocation === "/hotelsresult"
      ) {
        let finaldata1 = {
          username: local.user_name,
          image: props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          name: props?.allItems?.name,
          item: props?.allItems,
          sender_id: local.id,
        };

        socket.emit("chatMessage", finaldata1);
      } else {
        let finaldata2 = {
          username: local.user_name,
          image: props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          name: props?.allItems?.name,
          item: props?.allItems,
          sender_id: local.id,
        };

        socket.emit("chatMessage", finaldata2);
      }
    } else {
      const room = item?._id;
      const username = local.user_name;
      const user_id = local.id;
      socket.emit("joinPlan", { username, room, user_id });
      if (
        props?.dynamicLocation === "/food-and-drink-details" ||
        props?.dynamicLocation === "/interest-details-results" ||
        props?.dynamicLocation === "/hiking-result-details"
      ) {
        let finaldata = {
          username: local.user_name,
          image: props?.groupImages?.photos
            ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${props?.groupImages?.photos[0]?.photo_reference}&key=${mapKey}`
            : props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          item: props?.groupImages,
          name: props?.groupImages?.name,
          sender_id: local.id,
        };

        socket.emit("chatMessage", finaldata);
      } else if (
        props?.dynamicLocation === "/rental-result-details" ||
        props?.dynamicLocation === "/hotel-result-details"
      ) {
        let finaldata1 = {
          username: local.user_name,
          image: props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          name: props?.allItems?.Name,
          item: props?.allItems,
          sender_id: local.id,
        };
        const data = {
          plan_name: item?.plan_name,
          groupId: finaldata1?.groupId,
          data: finaldata1,
        };

        // dispatch(dynamicChatInfoSuccess(data));
        socket.emit("chatMessage", finaldata1);
      } else {
        let finaldata2 = {
          username: local.user_name,
          image: props?.groupImages,
          id: item?._id,
          photo: local.photo,
          groupId: props?.placeIdData,
          location: props?.dynamicLocation,
          name: props?.allItems?.name,
          item: props?.allItems,
          sender_id: local.id,
        };

        socket.emit("chatMessage", finaldata2);
      }
    }
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="chatPlansGroupModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .chatPlansGroupModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 427px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .chatPlansGroupModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Chat</h2>
            <PlansTabsWrap className="chatPlansTabs">
              <Tabs defaultActiveKey="planChats">
                <Tab eventKey="planChats" title="Plan Chats">
                  <TabsContent>
                    <PlanChatsGroups>
                      {groupListData?.map((item: any) => {
                        return (
                          <>
                            <h4>{item?.plan_name}</h4>
                            <ul onClick={() => groupSave(item)}>
                              {item.creator && (
                                <li>
                                  <img
                                    src={item?.creator?.creatorPhoto}
                                    alt={item?.creator?.creatorUsername}
                                  />
                                </li>
                              )}
                              {item?.invited_users
                                ?.slice(0, 2)
                                ?.map((itemData: any) => {
                                  return (
                                    <li className="cursorPointer">
                                      <img
                                        src={
                                          planList?.data?.data[0]?.link +
                                          itemData?.photo
                                        }
                                        alt={itemData?.userName}
                                        onClick={props.onHide}
                                      />
                                    </li>
                                  );
                                })}
                              {item?.invited_users?.length > 2 ? (
                                <li>
                                  <button className="btn ChatsTotalMember">
                                    +{item?.invited_users?.slice(2).length}
                                  </button>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </>
                        );
                      })}

                      {/* <h4>Weekend Party</h4> */}
                    </PlanChatsGroups>
                  </TabsContent>
                </Tab>

                <Tab eventKey="groupChats" title="Group Chats">
                  <TabsContent>
                    {/* <GroupsSingleChatImage>
                      <img src={PeterJohn} alt="peter-john" />
                      <h4>Jennifer</h4>
                    </GroupsSingleChatImage> */}
                    <PlanChatsGroups>
                      {chatModalGroup?.data?.data[0]?.GroupList?.map(
                        (item: any) => {
                          return (
                            <>
                              <h4>{item?.name}</h4>
                              <ul onClick={() => groupSave(item)}>
                                {item?.members?.map((itemValue: any) => {
                                  return (
                                    <>
                                      <li className="cursorPointer">
                                        <img
                                          src={`${
                                            chatModalGroup?.data?.data[0]
                                              ?.link + itemValue?.photo
                                          }`}
                                          onClick={props.onHide}
                                          alt="kathy"
                                        />
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </>
                          );
                        }
                      )}
                    </PlanChatsGroups>
                  </TabsContent>
                </Tab>
              </Tabs>
            </PlansTabsWrap>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChatsPlanModal;
