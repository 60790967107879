import { updateVenuePlanAPI } from "../../../services/updateVenuePlan";

export enum UpdateVenuePlanType {
  UPDATE_VENUE_INFO = "UPDATE_VENUE_INFO",
  UPDATE_VENUE_INFO_SUCCESS = "UPDATE_VENUE_INFO_SUCCESS",
  UPDATE_VENUE_INFO_FALIURE = "UPDATE_VENUE_INFO_FALIURE",
}

export const updateVenuePlanInfo = () => (dispatch: any) => {
  dispatch({
    type: UpdateVenuePlanType.UPDATE_VENUE_INFO,
  });
};

export const updateVenuePlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: UpdateVenuePlanType.UPDATE_VENUE_INFO_SUCCESS,
    data,
  });
};

export const updateVenuePlanInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: UpdateVenuePlanType.UPDATE_VENUE_INFO_FALIURE,
    data: { msg },
  });
};

export const updateVenuePlanData =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(updateVenuePlanInfo());
      const result: any = await updateVenuePlanAPI(finalData);
      dispatch(updateVenuePlanInfoSuccess(result));
    } catch (e: any) {
      dispatch(updateVenuePlanInfoFailed(e.response.data.message));
    }
  };
