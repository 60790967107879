import styled from "styled-components";

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width:767px){
      padding:20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    @media (max-width:350px){
      min-width: 40%;
      padding: 6px 10px;
    }
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const ModalMemberGroupList = styled.div`
  margin: 27px auto 0;
  width: 274px;
  max-width: 100%;
`;

export const ModalMemberGroupItem = styled.div`
  position: relative;
  padding-right: 30px;
  margin-bottom: 17px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  border-radius: 30px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;
