import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import {
  PageWrap,
  PageWrapInner,
  PageContainer,
  SearchListLoader,
} from "./termsConditionsStyle";
import { getTermsData } from "../../../redux/action/terms-Listing/termslisting";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";

function TermsListing() {
  const dispatch: any = useDispatch();
  const termsData: any = useSelector((state: any) => state?.termsListing);

  useEffect(() => {
    dispatch(getTermsData());
  }, []);
  const renderHTML: any = (escapedHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: escapedHTML },
    });
  return (
    <>
      <PageWrap>
        <Header />
        {termsData?.isLoading && (
          <SearchListLoader>
            <img src={PageLoaderIcon} alt="Loading..." />
          </SearchListLoader>
        )}
        <PageWrapInner>
          <PageContainer>
            <h1>Terms & Conditions</h1>
            <h2>{termsData?.data?.data?.name}</h2>
            {renderHTML(termsData?.data?.data?.description)}
          </PageContainer>
        </PageWrapInner>
        <Footer />
      </PageWrap>
    </>
  );
}
export default TermsListing;
