import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 9px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
    }
    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
`;

export const ModalBody = styled.div`
  .react-datepicker-popper {
    line-height: 10px;
    z-index: 9;
    &[data-placement^="bottom"] {
      padding-top: 5px;
    }
    &[data-placement^="top"] {
      padding-bottom: 5px;
    }
  }

  .react-datepicker {
    border-radius: 8px;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    border: 1px solid #efdeff;
    padding: 17px 15px 15px;
    width: 281px;
    color: #505887;
    font-family: "Poppins";
    font-size: 13px;
    line-height: 20px;

    &.react-datepicker--time-only {
      width: auto;
      padding: 15px 10px;
      .react-datepicker__time-box {
        border-radius: 0;
      }
      ul {
        &.react-datepicker__time-list {
          overflow-y: auto;
          border: none;
          padding: 0;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar:horizontal {
            height: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #cecece;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            cursor: pointer;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #e9e9e9;
          }

          li {
            &.react-datepicker__time-list-item {
              cursor: pointer;
              font-size: 13px;
              line-height: 20px;
              height: auto;
              font-weight: 500;
              color: #505887;
              margin-bottom: 1px;
              min-width: inherit;
              border: none;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover,
              &.react-datepicker__time-list-item--selected {
                color: #6840df;
              }
            }
          }
        }
      }
      .react-datepicker__header {
        .react-datepicker-time__header {
          margin-bottom: 0;
          padding-bottom: 11px;
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation--previous {
      left: 7px;
      .react-datepicker__navigation-icon--previous {
        &:before {
          left: -4px;
          right: auto;
        }
      }
    }

    .react-datepicker__navigation--next {
      right: 8px;
      .react-datepicker__navigation-icon--next {
        &:before {
          right: -4px;
          left: auto;
        }
      }
    }

    .react-datepicker__navigation-icon {
      padding: 0;
      &:before {
        border-width: 2px 2px 0 0;
        border-color: #3b3b3b;
        height: 8px;
        width: 8px;
        top: 12px;
      }
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__header {
      background-color: #ffffff;
      border-bottom: none;
      padding: 0 0 10px;
      .react-datepicker__current-month,
      .react-datepicker-year-header,
      .react-datepicker-time__header {
        color: #1c295d;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        border-bottom: 1px solid rgba(23, 3, 89, 0.1);
        padding-bottom: 13px;
        margin-bottom: 13px;
        margin-top: -2px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #505887;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .react-datepicker__header__dropdown {
      color: #505887;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      margin-bottom: 13px;
      margin-top: -2px;
      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow,
      .react-datepicker__navigation-icon::before {
        right: -18px;
        top: 3px;
        border-width: 2px 2px 0 0;
        border-color: #3b3b3b;
        height: 8px;
        width: 8px;
      }
      .react-datepicker__year-dropdown,
      .react-datepicker__month-dropdown,
      .react-datepicker__month-year-dropdown {
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
        border: 1px solid #efdeff;
        padding: 5px 0;
        width: 140px;
        left: 22.5%;
        .react-datepicker__year-option,
        .react-datepicker__month-option,
        .react-datepicker__month-year-option {
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
          padding: 4px 5px;
          transition: all 0.25s ease 0s;
          margin-bottom: 1px;
          &:hover {
            color: #6c53e4;
            background-color: rgba(108, 83, 228, 0.12);
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:first-of-type {
            border-radius: 0;
          }
          &:last-of-type {
            border-radius: 0;
          }
          &.react-datepicker__month-option--selected_month {
            color: #6c53e4;
            background-color: rgba(108, 83, 228, 0.12);
            font-weight: 600;
          }
        }
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 3px 4px;
      height: 27px;
      width: 27px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 24px;
      font-weight: normal;
      color: #1c295d;
      border-radius: 5px;
      border: 2px solid transparent;
      transition: all 0.35s ease 0s;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 5px;
      border: 2px solid #6c53e4 !important;
      background-color: #6c53e4 !important;
      color: #fff !important;
      font-weight: 500;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 5px;
      border: 2px solid transparent;
      background: rgba(108, 83, 228, 0.12);
      color: #6c53e4;
      font-weight: 500;
    }

    .react-datepicker__day--outside-month {
      color: #8d8d8d;
    }

    .react-datepicker__day--disabled {
      color: #8d8d8d;
      pointer-events: none;
    }
  }

  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    text-align: center;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      color: #4d27da;
      text-align: center;
      margin-bottom: 6px;
      @media (max-width: 350px) {
        font-size: 16px;
        padding-right: 25px;
      }
    }
    p {
      margin: 0;
    }
    ul {
      padding: 0 10px 0;
      margin: 20px -10px 0;
      list-style-type: none;
      /* max-height: 350px; */
      min-height: 200px;
      /* overflow-y: auto; */
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #cecece;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
      }

      li {
        position: relative;
        text-align: left;
        margin: 0 0 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .error {
      font-family: "Poppins";
      color: #ef3939;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      margin: 5px 0 0;
      &:empty {
        display: none;
      }
    }

    .dateInputGroup {
      position: relative;
      margin: 0;
      .form-control {
        padding-right: 45px;
        @media (max-width: 480px) {
          padding-right: 35px;
        }
      }
      .calendar-icon {
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        position: absolute;
        top: 11px;
        right: 20px;
        pointer-events: none;
        z-index: 1;
        @media (max-width: 480px) {
          right: 10px;
        }
        img {
          vertical-align: middle;
          max-width: 100%;
        }
      }
      .react-datepicker-popper {
        z-index: 3;
        .react-datepicker--time-only .react-datepicker__triangle {
          margin-left: -14px;
        }
        .react-datepicker__time-list {
          li {
            margin: 0;
          }
        }
      }
    }

    .row {
      margin: 0 -7px;
      [class*="col-"] {
        padding: 0 7px;
        position: relative;
        + [class*="col-"] {
          &:before {
            content: "";
            width: 6px;
            background: #8d8d8d;
            position: absolute;
            height: 2px;
            top: 19px;
            left: -3px;
            @media (max-width: 350px) {
              display: none;
            }
          }
        }
        @media (max-width: 350px) {
          flex: 100%;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const CheckBox = styled.div`
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  user-select: none;
  margin: 0 0 10px;
  min-height: 20px;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  &:last-child {
    margin-bottom: 0;
  }
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;
