import { searchFollowersAPI } from "../../../services/searchFollowers";

export enum SearchFollowType {
  SEARCH_FOLLOW_INFO = "SEARCH_FOLLOW_INFO",
  SEARCH_FOLLOW_INFO_SUCCESS = "SEARCH_FOLLOW_INFO_SUCCESS",
  SEARCH_FOLLOW_INFO_FALIURE = "SEARCH_FOLLOW_INFO_FALIURE",
}

export const SearchFollowInfo = () => (dispatch: any) => {
  dispatch({
    type: SearchFollowType.SEARCH_FOLLOW_INFO,
  });
};

export const SearchFollowInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SearchFollowType.SEARCH_FOLLOW_INFO_SUCCESS,
    data,
  });
};

export const SearchFollowInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SearchFollowType.SEARCH_FOLLOW_INFO_FALIURE,
    data: { msg },
  });
};

export const searchFollowData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(SearchFollowInfo());
    const result: any = await searchFollowersAPI(data);
    dispatch(SearchFollowInfoSuccess(result));
  } catch (e: any) {
    dispatch(SearchFollowInfoFailed(e.message));
  }
};
