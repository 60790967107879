import { Route, Navigate, Routes } from "react-router-dom";
import React, { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import LoginPage from "../pages/onboarding/auth/Login";
import SignupInvite from "../pages/onboarding/auth/SignupInvite";
import ForgotPassword from "../pages/onboarding/auth/ForgotPassword";
import ResetPassword from "../pages/onboarding/auth/ResetPassword";
import AccountRequest from "../pages/onboarding/auth/AccountRequest";
import Login from "../pages/onboarding/auth/Login";
import Signup from "../pages/onboarding/auth/Signup";
import MyPlans from "../pages/onboarding/myPlans/index";
import PublicRoute from "./PublicRoute";
import FestivalsResults from "../pages/onboarding/festivalsResults";
import SportsResults from "../pages/onboarding/sportsResults";
import ConcertResults from "../pages/onboarding/concertResults";
import SportsResultsDetail from "../pages/onboarding/sportsResultsDetail";
import RentalsResults from "../pages/onboarding/rentalsResults";
import RentalsResultsDetail from "../pages/onboarding/rentalsResultsDetail";
import InterestResults from "../pages/onboarding/interestResults";
import MyProfile from "../pages/onboarding/myProfile";
import ViewPlanDetails from "../pages/onboarding/viewPlanDetails";
import SocialProfile from "../pages/onboarding/socialProfile";
import UserFollowers from "../pages/onboarding/userFollowers";
import Notification from "../pages/onboarding/notification";
import Settings from "../pages/onboarding/settings";
import Invites from "../pages/onboarding/invites";
import MyFeedback from "../pages/onboarding/myFeedback";
import NotFound from "../pages/onboarding/notFound";
import HotelsResult from "../pages/onboarding/hotelResults";
import MyPlan from "../pages/onboarding/myPlan";
import Collection from "../pages/onboarding/collection";
import Hiking from "../pages/onboarding/hiking";
import FoodAndDrinks from "../pages/onboarding/foodAndDrinks";
import ConcertResultDetails from "../pages/onboarding/concertResultDetails/index";
import FestivalResultDetails from "../pages/onboarding/festivalResultDetails/index";
import SportsResultDetails from "../pages/onboarding/sportsResultsDetails/index";
import RentalsResultsDetails from "../pages/onboarding/rentalResultDetails/index";
import HotelResultDetails from "../pages/onboarding/hotelResultDetails/index";
import AboutUs from "../pages/onboarding/aboutUs";
import TermsListing from "../pages/onboarding/termsConditions";
import PrivacyPolicy from "../pages/onboarding/privacyPolicy";
import HelpAndFaq from "../pages/onboarding/helpFaq/index";
import CollectionItem from "../pages/onboarding/collectionItem";
import FoodAndDrinksDetails from "./../pages/onboarding/foodAndDrinks/foodDetails";
import HikingDetails from "./../pages/onboarding/hiking/hikingDetails";
import InterestResultsDetails from "./../pages/onboarding/interestResults/interestDetails";

function Routing(props: any) {
  return (
    <>
      <AuthRoute path="/" data={props.user} component={LoginPage} />

      <div className="main_container">
        {/* {props.user && <Header />} */}
        <div className="header_content_container">
          <ProtectedRoute
            path="/dashboard"
            data={props.user}
            component={MyPlans}
            logout={props.logout}
          />

          <Routes>
            {/* <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signupinvite" element={<SignupInvite />} /> */}
          </Routes>
          <PublicRoute path="/signup/:token" component={Signup} />
          <PublicRoute path="/forgotpassword" component={ForgotPassword} />
          <PublicRoute path="/signupinvite" component={SignupInvite} />
          <PublicRoute path="/signup" component={SignupInvite} />
          <PublicRoute
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <PublicRoute path="/accountrequest" component={AccountRequest} />
          <PublicRoute path="/terms-and-conditions" component={TermsListing} />
          <PublicRoute path="/about-us" component={AboutUs} />
          <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
          <ProtectedRoute
            path="/festivalsresults/*"
            component={FestivalsResults}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/sportsresults/*"
            component={SportsResults}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/concert-result-details/*"
            component={ConcertResultDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/festival-result-details/*"
            component={FestivalResultDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/food-and-drink-details/*"
            component={FoodAndDrinksDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/sports-results-details/*"
            component={SportsResultDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/rental-result-details/*"
            component={RentalsResultsDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/hotel-result-details/*"
            component={HotelResultDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/hiking-result-details/*"
            component={HikingDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/concertsresults/*"
            component={ConcertResults}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/sportsresultsdetail/*"
            component={SportsResultsDetail}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/rentalsresults/*"
            component={RentalsResults}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/foodAndDrinks/*"
            component={FoodAndDrinks}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/rentalsresultsdetail"
            component={RentalsResultsDetail}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/hotelsresult/*"
            component={HotelsResult}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/interestresults/*"
            component={InterestResults}
            data={props.user}
            logout={props.logout}
          />
          <ProtectedRoute
            path="/interest-details-results/*"
            component={InterestResultsDetails}
            data={props.user}
            logout={props.logout}
          />

          <ProtectedRoute
            path="/myprofile/*"
            component={MyProfile}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/myplans"
            component={MyPlan}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/collection"
            component={Collection}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/viewplandetails/:plan_id"
            component={ViewPlanDetails}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/socialprofile/:user_id"
            component={SocialProfile}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/userfollowers"
            component={UserFollowers}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/hiking/*"
            component={Hiking}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/notification"
            component={Notification}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/settings"
            component={Settings}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/invites"
            component={Invites}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/myfeedback"
            component={MyFeedback}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/help-and-faq"
            component={HelpAndFaq}
            logout={props.logout}
            data={props.user}
          />
          <ProtectedRoute
            path="/collection-items/:collection_id"
            component={CollectionItem}
            logout={props.logout}
            data={props.user}
          />
          <PublicRoute path="/notfound" component={NotFound} />
        </div>
      </div>
    </>
  );
}

export default Routing;
