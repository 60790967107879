import axios from "axios";
import { message } from "antd";
import { mapKey } from "../resource/constants";

// const API_URL = "http://203.109.113.154:3000/api";
const API_URL = "https://app.tripd.co:8080/api";
//const API_URL = "http://localhost:8080/api";
const GOOGLE_MAP_URL =
  "https://maps.googleapis.com/maps/api/place/nearbysearch";
const GOOGLE_SEARCH_URL =
  "https://maps.googleapis.com/maps/api/place/textsearch";

// const API_URL = "http://192.168.1.192:8000/api";
const GOOGLE_DETAILS_URL = "https://maps.googleapis.com/maps/api/place/details";

const getHeader = () => {
  let user: any = localStorage.getItem("token");
  return user && { Authorization: user };
};
export default class Http {
  static get(url: string) {
    return new Promise((resolve, reject) => {
      let token = getHeader();
      axios({
        method: "get",
        url: `${API_URL}/${url}`,
        headers: token,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  static post(url: string, body: any) {
    return new Promise(async (resolve, reject) => {
      let token = getHeader();
      axios({
        method: "post",
        url: `${API_URL}/${url}`,
        data: body,
        headers: token,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  // static google(body: any) {
  //   return new Promise(async (resolve, reject) => {
  //     axios({
  //       method: "get",
  //       url: `${GOOGLE_MAP_URL}/json?query=${body.query}&type=${body.type}&location=${body.Latitude},${body.Longitude}&radius=5000&key=${mapKey}&pagetoken=${body.pagetoken}`,
  //     })
  //       .then(function (response) {
  //         resolve(response.data);
  //       })
  //       .catch(function (error) {
  //         reject(error);
  //       });
  //   });
  // }

  // static getHiking(body: any) {
  //   return new Promise(async (resolve, reject) => {
  //     axios({
  //       method: "get",
  //       url: `${GOOGLE_SEARCH_URL}/json?query=${body.query}&location=${body.Latitude},${body.Longitude}&key=${mapKey}&pagetoken=${body.pagetoken}`,
  //     })
  //       .then(function (response) {
  //         resolve(response.data);
  //       })
  //       .catch(function (error) {
  //         reject(error);
  //       });
  //   });
  // }

  static getPhoto(body: any) {
    return new Promise(async (resolve, reject) => {
      axios({
        method: "get",
        url: `${GOOGLE_DETAILS_URL}/json?&place_id=${body.place_id}&key=${mapKey}`,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  static patch(url: string, body: any) {
    return new Promise((resolve, reject) => {
      let token = getHeader();
      axios({
        method: "patch",
        url: `${API_URL}/${url}`,
        data: body,
        headers: token,
      })
        .then(function (response) {
          if (response.data && response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (error) {
          reject(error.response.data);
        });
    });
  }

  static delete(url: string) {
    return new Promise((resolve, reject) => {
      let token = getHeader();
      axios({
        method: "delete",
        url: `${API_URL}/${url}`,
        headers: token,
      })
        .then(function (response) {
          if (response.data && response.data.success) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (error) {
          reject(error.response.data);
        });
    });
  }
}
