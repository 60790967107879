import { getProfileAPI } from "../../../services/getProfile";

export enum getProfileType {
  GET_PROFILE_INFO = "GET_PROFILE_INFO",
  GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS",
  GET_PROFILE_INFO_FALIURE = "GET_PROFILE_INFO_FALIURE",
}

export const getProfileInfo = () => (dispatch: any) => {
  dispatch({
    type: getProfileType.GET_PROFILE_INFO,
  });
};

export const getProfileInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getProfileType.GET_PROFILE_INFO_SUCCESS,
    data,
  });
};

export const getProfileInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getProfileType.GET_PROFILE_INFO_FALIURE,
    error: msg,
  });
};
export const getProfileData = () => async (dispatch: any) => {
  try {
    dispatch(getProfileInfo());
    const result: any = await getProfileAPI();
    dispatch(getProfileInfoSuccess(result));
  } catch (e: any) {
    dispatch(getProfileInfoFailed(e.response.data.message));
  }
};
