import { searchFollowingAPI } from "../../../services/searchFollowing";

export enum SearchFollowingType {
  SEARCH_FOLLOWING_INFO = "SEARCH_FOLLOWING_INFO",
  SEARCH_FOLLOWING_INFO_SUCCESS = "SEARCH_FOLLOWING_INFO_SUCCESS",
  SEARCH_FOLLOWING_INFO_FALIURE = "SEARCH_FOLLOWING_INFO_FALIURE",
}

export const SearchFollowingInfo = () => (dispatch: any) => {
  dispatch({
    type: SearchFollowingType.SEARCH_FOLLOWING_INFO,
  });
};

export const SearchFollowingInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SearchFollowingType.SEARCH_FOLLOWING_INFO_SUCCESS,
    data,
  });
};

export const SearchFollowingInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SearchFollowingType.SEARCH_FOLLOWING_INFO_FALIURE,
    data: { msg },
  });
};

export const searchFollowingData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(SearchFollowingInfo());
    const result: any = await searchFollowingAPI(data);
    dispatch(SearchFollowingInfoSuccess(result));
  } catch (e: any) {
    dispatch(SearchFollowingInfoFailed(e.message));
  }
};
