import React, { useEffect } from "react";
import Header from "../../../components/Header";
import {
  Tabs,
  Tab,
  Modal,
  Dropdown,
  Button,
  Container,
  Card,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ConcertsImage from "../../../assets/images/concerts-image.png";
import FoodDrinksImage from "../../../assets/images/food-drinks-image.png";
import FestivalsImage from "../../../assets/images/festivals-image.png";
import SportsImage from "../../../assets/images/sports-image.png";
import RentalsImage from "../../../assets/images/rentals-image.png";
import HotelsImage from "../../../assets/images/hotels-image.png";
import POIImage from "../../../assets/images/poi-image.png";
import HikingImage from "../../../assets/images/hiking-image.png";

import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  HomeListing,
  ListItem,
  ListItemName,
} from "./helpFaqStyle";

import "react-datepicker/dist/react-datepicker.css";
import { helpAndFaqListData } from "../../../redux/action/help-faq-list/helpAndFaq";

// import { dynamicCategoryData } from "../../../../redux/action/dynamic-category/dynamicCategory";
// import { mapKey } from "../../../../resource/constants";

const HelpFaq: any = (props: any) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(helpAndFaqListData());
  }, []);

  const helpAndFaqList = useSelector((state: any) => state.helpAndFaqList);

  const renderHTML: any = (escapedHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: escapedHTML },
    });

  return (
    <>
      <Header logout={props.logout} />

      <MyPageWrap>
        <MainContentPanel>
          <HomeListingWrap>
            <HomeListing>
              <Container>
                <Card>
                  <Card.Body>
                    <h3 className="section-title">
                      {window.location.pathname === "/help-and-faq" && <>FAQ</>}
                    </h3>
                    <Accordion defaultActiveKey="0">
                      {helpAndFaqList?.data?.data?.map(
                        (item: any, index: any) => {
                          return (
                            <>
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header>
                                  {item?.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {renderHTML(item?.answer)}
                                </Accordion.Body>
                              </Accordion.Item>
                            </>
                          );
                        }
                      )}
                    </Accordion>
                  </Card.Body>
                </Card>
              </Container>
            </HomeListing>
          </HomeListingWrap>
        </MainContentPanel>
      </MyPageWrap>
    </>
  );
};

export default HelpFaq;
