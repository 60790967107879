import React from "react";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router-dom";

interface Props {
  options?: GoogleMapReact.MapOptions;
  defaultCenter?: GoogleMapReact.Coords;
  defaultZoom?: number;
  bootstrapURLKeys?: GoogleMapReact.BootstrapURLKeys;
  styles?: any;
  mapData?: any;
  mapDataHiking?: any;
  pointOfInterest?: any;
  mapDataHotels?: any;
  [props: string]: any;
}

const defaultProps = {
  center: {
    lat: 36.2082629,
    lng: -113.737393,
  },
  zoom: 4,
};

const MapComponent = ({
  options,
  bootstrapURLKeys = { key: "" },
  defaultCenter = defaultProps.center,
  defaultZoom = defaultProps.zoom,
  mapData,
  mapDataHiking,
  pointOfInterest,
  mapDataHotels,
  value = "",
  styles = "",
  ...props
}: Props) => {
  const navigate: any = useNavigate();

  const AnyReactComponent = ({ text, id }: any) => (
    <div
      className="mapPriceBox"
      onClick={() => navigate(`/rental-result-details/${id}`)}
      style={{ cursor: "pointer" }}
    >
      {text}
    </div>
  );
  const AnyReactComponent1 = ({ text, id }: any) => (
    <div className="mapPriceBox">{text}</div>
  );

  const AnyReactComponent2 = ({ text }: any) => (
    <div className="mapPriceBox">{text}</div>
  );
  const AnyReactComponent3 = ({ text, id }: any) => (
    <div
      className="mapPriceBox"
      onClick={() => navigate(`/hotel-result-details/${id}`)}
      style={{ cursor: "pointer" }}
    >
      {text}
    </div>
  );

  return (
    <GoogleMapReact
      options={options}
      bootstrapURLKeys={bootstrapURLKeys}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      {...props}
    >
      {mapData &&
        mapData?.data?.data?.get_rental?.map((item: any) => {
          return (
            <AnyReactComponent
              id={item._id}
              lat={item?.Location?.GeoLocation?.Latitude}
              lng={item?.Location?.GeoLocation?.Longitude}
              text={
                item?.RoomTypes[0]?.Price?.TotalPrice?.Value
                  ? `$ ${item?.RoomTypes[0]?.Price?.TotalPrice?.Value}`
                  : "N/A "
              }
            />
          );
        })}

      {mapData &&
        mapData?.data?.data?.hotel?.map((item: any) => {
          return (
            <AnyReactComponent3
              id={item._id}
              lat={item?.Location?.GeoLocation?.Latitude}
              lng={item?.Location?.GeoLocation?.Longitude}
              text={
                item?.RoomTypes[0]?.Price?.TotalPrice?.Value
                  ? `$ ${item?.RoomTypes[0]?.Price?.TotalPrice?.Value}`
                  : "N/A "
              }
            />
          );
        })}

      {mapDataHiking?.data?.results?.length !== 0 &&
        mapDataHiking?.data?.results?.map((item: any) => {
          return (
            <AnyReactComponent1
              lat={item?.geometry?.location?.lat}
              lng={item?.geometry?.location?.lng}
              text={""}
            />
          );
        })}

      {pointOfInterest?.data?.results?.length !== 0 &&
        pointOfInterest?.data?.results?.map((item: any) => {
          return (
            <AnyReactComponent2
              lat={item?.geometry?.location?.lat}
              lng={item?.geometry?.location?.lng}
              // text={
              //   item?.RoomTypes[0]?.Price?.TotalPrice?.Value
              //     ? `$ ${item?.RoomTypes[0]?.Price?.TotalPrice?.Value}`
              //     : "N/A "
              // }
            />
          );
        })}
    </GoogleMapReact>
  );
};

export default MapComponent;
