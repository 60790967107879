import { addItemToCollectionApi } from "../../../services/addItemToCollection";

export enum addItemToCollectionType {
  ADD_COLLECTION_ITEM = "ADD_COLLECTION_ITEM",
  ADD_COLLECTION_ITEM_SUCCESS = "ADD_COLLECTION_ITEM_SUCCESS",
  ADD_COLLECTION_ITEM_FAILURE = "ADD_COLLECTION_ITEM_FAILURE",
}

export const addCollectionItem = () => (dispatch: any) => {
  dispatch({
    type: addItemToCollectionType.ADD_COLLECTION_ITEM,
  });
};

export const addCollectionItemSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: addItemToCollectionType.ADD_COLLECTION_ITEM_SUCCESS,
    data,
  });
};

export const addCollectionItemFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: addItemToCollectionType.ADD_COLLECTION_ITEM_FAILURE,
    data: { msg },
  });
};

export const addCollectionItemHandler =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(addCollectionItem());
      const result: any = await addItemToCollectionApi(finalData);
      dispatch(addCollectionItemSuccess(result));
    } catch (e: any) {
      dispatch(addCollectionItemFailure(e?.response?.data?.message));
    }
  };
