import { deleteVenuePlanAPI } from "../../../services/deleteVenuePlan";

export enum DeleteVenuePlanType {
  DELETE_VENUE_INFO = "DELETE_VENUE_INFO",
  DELETE_VENUE_INFO_SUCCESS = "DELETE_VENUE_INFO_SUCCESS",
  DELETE_VENUE_INFO_FALIURE = "DELETE_VENUE_INFO_FALIURE",
}

export const deleteVenuePlanInfo = () => (dispatch: any) => {
  dispatch({
    type: DeleteVenuePlanType.DELETE_VENUE_INFO,
  });
};

export const deleteVenuePlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: DeleteVenuePlanType.DELETE_VENUE_INFO_SUCCESS,
    data,
  });
};

export const deleteVenuePlanInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: DeleteVenuePlanType.DELETE_VENUE_INFO_FALIURE,
    data: { msg },
  });
};

export const deleteVenuePlanData =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(deleteVenuePlanInfo());
      const result: any = await deleteVenuePlanAPI(finalData);
      dispatch(deleteVenuePlanInfoSuccess(result));
    } catch (e: any) {
      dispatch(deleteVenuePlanInfoFailed(e.response.data.message));
    }
  };
