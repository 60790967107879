// import { getPlanAPI } from "../../../services/joinPlan";

export enum latLongType {
  LAT_LONG_INFO = "LAT_LONG_INFO",
  LAT_LONG_INFO_SUCCESS = "LAT_LONG_INFO_SUCCESS",
  LAT_LONG_INFO_FALIURE = "LAT_LONG_INFO_FALIURE",
}

export const latLongInfo = () => (dispatch: any) => {
  dispatch({
    type: latLongType.LAT_LONG_INFO,
  });
};

export const latLongInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: latLongType.LAT_LONG_INFO_SUCCESS,
    data,
  });
};

export const latLongInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: latLongType.LAT_LONG_INFO_FALIURE,
    data: { msg },
  });
};

export const latLongHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(latLongInfo());
    // const result: any = await getPlanAPI(data);
    // dispatch(latLongInfoSuccess(result));
  } catch (e: any) {
    console.error(e);
    dispatch(latLongInfoFailed(e?.respone?.data?.message));
  }
};
