import { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Res from "../../../resource";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../../assets/images/logo.svg";
import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  AuthBoxTitle,
  FormLink,
  Spinner,
} from "./authStyle";
import { Formik } from "formik";

import {
  forgotPasswordDataAPI,
  forgotPasswordInfoFailed,
  forgotPasswordInfoSuccess,
} from "../../../redux/action/forgotPassword/forgotPwd";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPasswords() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isToasting, setIsToasting] = useState(false);

  const forgotData: any = useSelector((state: any) => state?.forgotPasword);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        case "success":
          setIsToasting(true);
          toast.success("Email sent successfully");
          closeToast();
          break;

        case "error":
          setIsToasting(true);
          toast.error("User email does not exists");
          closeToast();
          break;
        case "username":
          setIsToasting(true);
          toast.error("Username does not exists");
          closeToast();
          break;
        case "not registered":
          setIsToasting(true);
          toast.error("You are not registered. Please sign up first");
          closeToast();
          break;
        case "active":
          setTimeout(() => {
            toast.error("Your account has been disable. Please contact admin.");
          }, 1000);
          break;
        case "pending-list":
          setTimeout(() => {
            toast.error(
              "You're account is under review and if we can confirm it, you will be able to access your account within approximately 24 hours."
            );
          }, 1000);
          break;
        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!forgotData?.isLoading) {
      if (forgotData?.data) {
        getToast("success");
        setTimeout(() => {
          navigate("/");
          dispatch(forgotPasswordInfoFailed(""));
        }, 5000);
      } else if (forgotData?.msg === "User email does not exists") {
        getToast("error");
        dispatch(forgotPasswordInfoSuccess(""));
        dispatch({ type: "RESET_FORGOT_PASSWORD" });
      } else if (forgotData?.msg === "User name does not exists") {
        getToast("username");
        dispatch(forgotPasswordInfoFailed(""));
        dispatch({ type: "RESET_FORGOT_PASSWORD" });
      } else if (
        forgotData?.msg === "You do not registered. Please signup first"
      ) {
        getToast("not registered");
        dispatch(forgotPasswordInfoFailed(""));
        dispatch({ type: "RESET_FORGOT_PASSWORD" });
      } else if (forgotData?.msg === "User must be Active") {
        getToast("active");
        dispatch(forgotPasswordInfoFailed(""));
        dispatch({ type: "RESET_FORGOT_PASSWORD" });
      } else if (
        forgotData?.msg ===
        "You're account is under review and if we can confirm it, you will be able to access your account within approximately 24 hours."
      ) {
        getToast("pending-list");
        dispatch(forgotPasswordInfoFailed(""));
        dispatch({ type: "RESET_FORGOT_PASSWORD" });
      }
    }
  }, [forgotData]);

  const submitForm = async (values: any) => {
    let finalValue;
    const regex = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
    const lowerCase = values.email.toLowerCase();

    if (regex.test(lowerCase)) {
      finalValue = {
        ...values,
        type: "email",
      };
    } else {
      finalValue = {
        ...values,
        type: "username",
      };
    }
    await dispatch(forgotPasswordDataAPI(finalValue));
  };

  return (
    <>
      <AuthWrap>
        <Header />

        <AuthWrapInner className="forgotPasswordWrap">
          <AuthContainer>
            <AuthBoxTitle className="titleBox">
              <Link to="/">
                <img src={Logo} alt="Tripd" />
              </Link>

              <h3>Trouble Logging in?</h3>
              <p>
                Enter your email or username and we'll send you a link
                <br /> to get back into your account.
              </p>
            </AuthBoxTitle>
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                const regex =
                  /^(?:[A-Z\d][A-Z\d._-]{0,35}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/;

                // const emailRegex =
                //   /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;

                if (!values.email.trim()) {
                  errors.email = "Please enter your email address or username";
                }
                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <CustomInput
                      inputid="email"
                      inputlabe=""
                      inputtype="text"
                      placeholder="Email or Username"
                      value={values.email}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.email}
                      touched={touched.email}
                      autocomplete="off"
                    />

                    <Button type="submit" className="auth-btn">
                      {forgotData.isLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : null}
                      Forgot Password
                    </Button>
                    {/* <ToastContainer autoClose={5000} /> */}
                    <FormLink className="backToLogin">
                      Back to <Link to="/">Login</Link>
                    </FormLink>
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
    </>
  );
}

export default ForgotPasswords;
