import { getImageGoogleAPI } from "../../../services/getImageGoogle";

export enum getImageGoogleType {
  GET_IMAGE_GOOGLE_INFO = "GET_IMAGE_GOOGLE_INFO",
  GET_IMAGE_GOOGLE_INFO_SUCCESS = "GET_IMAGE_GOOGLE_INFO_SUCCESS",
  GET_IMAGE_GOOGLE_INFO_FALIURE = "GET_IMAGE_GOOGLE_INFO_FALIURE",
}

export const getImageGoogleInfo = () => (dispatch: any) => {
  dispatch({
    type: getImageGoogleType.GET_IMAGE_GOOGLE_INFO,
  });
};

export const getImageGoogleSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getImageGoogleType.GET_IMAGE_GOOGLE_INFO_SUCCESS,
    data,
  });
};

export const getImageGoogleFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getImageGoogleType.GET_IMAGE_GOOGLE_INFO_FALIURE,
    error: msg,
  });
};

export const getImageGoogleData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getImageGoogleInfo());
    const result: any = await getImageGoogleAPI(data);
    dispatch(getImageGoogleSuccess(result));
  } catch (e: any) {
    dispatch(getImageGoogleFailed(e?.response?.data));
  }
};
