import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import RentalMainImage from "../../assets/images/rentals-detail-main.jpg";
import RentalGallery from "../../assets/images/rentals-detail-gallery.jpg";
import RentalThumbOne from "../../assets/images/rentals-detail-thumb.jpg";
import RentalThumbTwo from "../../assets/images/rentals-detail-thumb-1.jpg";
import RentalThumbThree from "../../assets/images/rentals-detail-thumb-2.jpg";

import {
  ModalBody,
  ImageGalleryWrap,
} from "../imageGalleryModal/imageGalleryModalStyle";
import { AnyAaaaRecord } from "dns";

const ImageGalleryModal = (props: any) => {
  const [allImages, setAllImages] = useState<any[]>([]);

  useEffect(() => {
    if (props?.getAllImages) {
      allImages.splice(0, allImages.length);
      props?.getAllImages?.map((images: any) => {
        setAllImages((prev: any) => [
          ...prev,
          { original: images?.link, thumbnail: images?.link },
        ]);
      });
    }
  }, [props?.getAllImages]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="imageGalleryModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .imageGalleryModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 876px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .imageGalleryModal .modal-content {
              background: #FFFFFF;
              border-radius: 19px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);              
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ImageGalleryWrap>
              <ImageGallery items={allImages} />
            </ImageGalleryWrap>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImageGalleryModal;
