import { followingCollectionAPI } from "../../../services/followingCollection";

export enum followCollectionType {
    FOLLOW_COLLECTION_TYPE = "    FOLLOW_COLLECTION_TYPE",
    FOLLOW_COLLECTION_TYPE_SUCCESS = "    FOLLOW_COLLECTION_TYPE_SUCCESS",
    FOLLOW_COLLECTION_TYPE_FALIURE = "    FOLLOW_COLLECTION_TYPE_FALIURE",
}

export const followingCollectionInfo = () => (dispatch: any) => {
    dispatch({
        type: followCollectionType.FOLLOW_COLLECTION_TYPE,
    });
};

export const followingCollectionInfoSuccess = (data: any) => (dispatch: any) => {
    dispatch({
        type: followCollectionType.FOLLOW_COLLECTION_TYPE_SUCCESS,
        data,
    });
};

export const followingCollectionInfoFaliure = (msg: string) => (dispatch: any) => {
    dispatch({
        type: followCollectionType.FOLLOW_COLLECTION_TYPE_FALIURE,
        error: msg,
    });
};
export const followingCollectionHandler = (id: any) => async (dispatch: any) => {
    try {
        dispatch(followingCollectionInfo());
        const result: any = await followingCollectionAPI(id);
        dispatch(followingCollectionInfoSuccess(result));
    } catch (e: any) {
        dispatch(followingCollectionInfoFaliure(e?.response?.data?.message));
    }
};
