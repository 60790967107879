import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deletePlanData } from "../../redux/action/deletePlan/deletePlan";
import { ModalBody, ModalBodyBtn } from "../exitGroupModal/exitGroupModalStyle";

const ExitGroupModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const exitGroupHandler = () => {
    props.onHide();
    const data = {
      plan_id: props.planId,
      user_id: localStorage.getItem("id"),
    };
    dispatch(deletePlanData(data));
    navigate("/myplans");
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="exitGroupModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .exitGroupModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 343px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .exitGroupModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Exit Group</h2>
            <p>Are you sure you want to exit in this plan?</p>
            <ModalBodyBtn>
              <Button onClick={() => exitGroupHandler()}>YES</Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExitGroupModal;
