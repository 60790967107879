import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsContent,
  MainContentPanelWrap,
  InvitesWrap,
  PageLoader,
} from "./invitesStyle";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import { sendInviteData } from "../../../redux/action/send-invite/sendInvite";

const Invites = (props: any) => {
  const [getToster, setToster] = useState(false);

  const dispatch: any = useDispatch();
  const sendInvitation = useSelector((state: any) => state?.sendInvite);

  const submitForm = (values: any, { resetForm }: any) => {
    dispatch(sendInviteData(values));
    setToster(true);
    resetForm({});
  };

  useEffect(() => {
    if (getToster) {
      sendInvitation?.data !== null
        ? toast.success("Invite link sent to the email address")
        : toast.error("User already registered.");
      setToster(false);
    }
  }, [sendInvitation]);

  const inviteLoader = useSelector((state: any) => state?.sendInvite);

  return (
    <>
      <Header logout={props.logout} />
      {/* <ToastContainer autoClose={4000} /> */}
      {inviteLoader?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites" className="active">
                INVITES
              </Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>

            <TabsContent>
              <InvitesWrap>
                <h3 className="invites-title">
                  Send an invite to your friends to join <span>Tripd :)</span>
                </h3>

                <Formik
                  initialValues={{
                    email: "",
                    message: "",
                  }}
                  validate={(values: any) => {
                    let errors: any = {};
                    const email =
                      /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;

                    if (!values.email.trim()) {
                      errors.email = "Please enter email address";
                    } else if (!email.test(values.email)) {
                      errors.email = "Please enter valid email address";
                    }
                    return errors;
                  }}
                  onSubmit={submitForm}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    dirty,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <CustomInput
                          inputid="email"
                          inputlabe="Email"
                          inputtype="email"
                          placeholder="Enter email"
                          value={values.email}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors.email}
                          touched={touched.email}
                        />

                        <CustomInput
                          inputid="message"
                          inputtype="text"
                          as="textarea"
                          inputlabe="Your Message"
                          placeholder="Hey!!! I welcome you to join Tripd. It's a cool new platfrom for friends to connect and make travel plans together."
                          value={values.message}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors=""
                          touched=""
                        />

                        <FormGroup>
                          <Button type="submit">SEND INVITE</Button>
                        </FormGroup>
                      </form>
                    </>
                  )}
                </Formik>
              </InvitesWrap>
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
    </>
  );
};

export default Invites;
