import { useState, useEffect } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LasVegas from "../../assets/images/las-vegas.png";
import { getPlanDetailsHandler } from "../../redux/action/myPlan/myPlan";
import PageLoaderIcon from "../../assets/images/page-loader.svg";

import {
  FormGroup,
  TabsContent,
  ModalBody,
  ModalBodyBtn,
  PlansTabsWrap,
  PlansItem,
  PlansItemImage,
  PlansItemInfo,
  PlansInfoDate,
  PlansItemImageWrap,
  PageLoader,
  PlansItemDes,
  NoPlanAvailable,
} from "../plansModal/plansModalStyle";

import CustomInput from "../CustomInput";
import { Formik } from "formik";
import { Group, Hiking } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const PlansModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [tabOpen, setTabOpen] = useState("created");
  const [planShow, setPlanShow] = useState("Plan Created");
  const [open, setOpen] = useState(false);

  const createPlan = useSelector((state: any) => state?.createPlan);

  useEffect(() => {
    const datas = {
      sorting: planShow,
    };
    dispatch(getPlanDetailsHandler(datas));
  }, [planShow, createPlan]);

  const getMyPlan: any = useSelector((state: any) => state?.myPlan);

  const handleChange = () => {
    if (open) {
      setTabOpen("created");
      setPlanShow("Plan Created");
    } else {
      setTabOpen("invitedTo");
      setPlanShow("Plan Invited To");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="plansCreatedModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .plansCreatedModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 427px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .plansCreatedModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Plans</h2>
            {getMyPlan?.isLoading && (
              <PageLoader>
                <img src={PageLoaderIcon} alt="Loading..." />
              </PageLoader>
            )}

            <PlansTabsWrap>
              <Tabs
                defaultActiveKey={tabOpen}
                onClick={() => {
                  setOpen(!open);
                  handleChange();
                }}
              >
                <Tab eventKey="created" title="Created">
                  <TabsContent>
                    {getMyPlan?.data?.data.length > 0 ? (
                      getMyPlan?.data?.data?.map((ele: any) => {
                        return (
                          <PlansItem>
                            <PlansItemImageWrap>
                              <PlansItemImage
                                onClick={() => {
                                  props.addPlanData(ele);
                                }}
                              >
                                <img
                                  src={ele?.photo ?? LasVegas}
                                  alt={ele?.plan_name}
                                />
                              </PlansItemImage>
                            </PlansItemImageWrap>
                            <PlansItemInfo>
                              <h3>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    props.addPlanData(ele);
                                  }}
                                >
                                  {ele?.plan_name}
                                </Link>
                              </h3>
                              <PlansItemDes>{ele?.description}</PlansItemDes>
                              <PlansInfoDate>
                                {moment(ele?.start_date, "MM/DD/YYYY").format(
                                  "MM/DD/YYYY"
                                )}
                                {ele?.end_date !== ""
                                  ? " - " +
                                    moment(ele?.end_date, "MM/DD/YYYY").format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </PlansInfoDate>
                            </PlansItemInfo>
                          </PlansItem>
                        );
                      })
                    ) : (
                      <NoPlanAvailable>
                        No Created Plan Available.
                      </NoPlanAvailable>
                    )}
                  </TabsContent>
                </Tab>

                <Tab eventKey="invitedTo" title="Invited To">
                  <TabsContent>
                    {getMyPlan?.data?.data.length > 0 ? (
                      getMyPlan?.data?.data?.map((ele: any) => {
                        return (
                          <PlansItem
                            onClick={() => {
                              props.addPlanData(ele);
                            }}
                          >
                            <PlansItemImageWrap>
                              <PlansItemImage>
                                <img
                                  src={ele?.photo ?? LasVegas}
                                  alt="las-vegas"
                                />
                              </PlansItemImage>
                            </PlansItemImageWrap>
                            <PlansItemInfo>
                              <h3>
                                <Link to="#">{ele?.plan_name}</Link>
                              </h3>
                              <PlansItemDes>{ele?.description}</PlansItemDes>
                              <PlansInfoDate>
                                {moment(ele?.start_date, "MM/DD/YYYY").format(
                                  "MM/DD/YYYY"
                                )}
                                {ele?.end_date !== ""
                                  ? " - " +
                                    moment(ele?.end_date, "MM/DD/YYYY").format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </PlansInfoDate>
                            </PlansItemInfo>
                          </PlansItem>
                        );
                      })
                    ) : (
                      <NoPlanAvailable>
                        No Invited Plan Available.
                      </NoPlanAvailable>
                    )}
                  </TabsContent>
                </Tab>
              </Tabs>
            </PlansTabsWrap>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlansModal;
