import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import UserImage from "../../../assets/images/user-image.png";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import UserProfile from "../../../assets/images/michelle-smith.png";
import Nevada from "../../../assets/images/nevada.png";
import AddCollectionModal from "../../../components/addCollectionModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import RemoveFriendModal from "../../../components/removeFriendModal/index";
import RemoveFollowigModal from "../../../components/removeFollowingModal/index";
import { getPlanDetailsHandler } from "../../../redux/action/myPlan/myPlan";
import moment from "moment";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsWrap,
  TabsContent,
  MainContentPanelWrap,
  ProfileWrap,
  ProfileLeft,
  ProfileImage,
  ProfileName,
  ProfileUserName,
  ProfileRight,
  FormGroupWrap,
  MyPlansWrap,
  MyPlansTop,
  MyPlansTitle,
  CreateBtn,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansDate,
  MyPlansLock,
  MyPlansAction,
  MyPlansSubTitle,
  MyPlansActionWrap,
  MyPlansDropdown,
  CityTitle,
  BtnWrap,
  MyPlansMore,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
  PageLoader,
  TabDataNotFound,
  SearchListLoader,
  SearchBoxNote,
  MyPlansNotAvailable,
  EditPlanSvgContainer,
} from "./myPlanStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import { myProfileData } from "../../../redux/action/my-profile/myProfile";
import { changePasswordData } from "../../../redux/action/changepassword/changePassword";
import { getProfileData } from "../../../redux/action/get-profile/getProfile";
import { getFollowersAPIData } from "../../../redux/action/getfollowers/getFollowers";
import { getFollowingInfoData } from "../../../redux/action/getfollowing/getFollowing";
import { getAllFriendData } from "../../../redux/action/getallfriends/getAllFriends";
import { searchFollowData } from "../../../redux/action/search-followers/searchFollowers";
import { searchFollowingData } from "../../../redux/action/search-following/searchFollowing";
import { searchFriendsAPIData } from "../../../redux/action/search-friends/searchFriend";
import { unfollowData } from "../../../redux/action/unfollow/unFollow";
import UnfollowModal from "../../../components/unfollowModal/index";
import RemoveModal from "../../../components/removeModal/index";
import RemoveFriendsMyProfileModal from "../../../components/removeFriendMyProfile/index";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import UpdatePlanModal from "../../../components/updatePlanModal";

const MyProfile = (props: any) => {
  const [getFollow, setFollow] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFollowingTerm, setSearchFollowingTerm] = useState("");
  const [searchFriendTerm, setSearchFriendTerm] = useState("");
  const [showProfilePage, setShowProfilePage] = useState(false);
  const [sortingBy, setSortingBy] = useState("Upcoming Plan");
  // const [getRemove, setRemove] = useState(false);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const getFollowerList = useSelector(
    (state: any) => state?.getFollowers?.data?.data
  );

  const getFollowingList = useSelector(
    (state: any) => state?.getFollowing?.data?.data
  );

  const getFriendList = useSelector(
    (state: any) => state?.getAllFriends?.data?.data
  );

  const searchForFollwerSection = useSelector(
    (state: any) => state?.searchFollowers
  );
  const searchForFollowingSection = useSelector(
    (state: any) => state?.searchFollowing
  );

  const searchForFriendSection = useSelector(
    (state: any) => state?.searchFriends
  );

  const getMyPlan: any = useSelector((state: any) => state?.myPlan);

  const submitForm = (values: any) => {
    console.log("ssss", values);
  };

  const [AddCollectionModalShow, setAddCollectionModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = useState(false);
  const [removeFriendModalShow, setRemoveFriendModalShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [removeFollowinModalShow, setRemoveFollowingModalShow] =
    useState(false);
  const [removeFollowigId, setRemoveFollowingId] = useState("");
  const [removeFriendsId, setRemoveFriendsId] = useState("");
  const [removeId, setRemoveId] = useState("");
  const [sortedDataShow, setSortedDataShow] = useState("All Plan");
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getUploadBox, setUploadBox] = useState(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [getPlanDetails, setPlanDetails] = useState({});
  const [sortedBy, setSortedBy] = useState("All Plan");

  const myProfileDataSelect: any = useSelector(
    (state: any) => state?.myProfile
  );
  const updatePlan: any = useSelector((state: any) => state?.updatePlan);

  const removeFriend: any = useSelector((state: any) => state?.removeFriend);
  const removeFriendMyProfileHandler: any = useSelector(
    (state: any) => state?.removeFriendMyProfile
  );
  const unFollowHandler: any = useSelector((state: any) => state?.unFollow);

  const setLoginId = localStorage.getItem("id");

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  const myPlans = useRef(null);
  const myPlansScroll = () => scrollToRef(myPlans);

  const collection = useRef(null);
  const collectionScroll = () => scrollToRef(collection);

  const navigateToSettings = () => {
    navigate("/settings");
  };
	
  useEffect(() => {
    window.scrollTo(0, 0);
    const data = {
      sorting: sortedBy,
    };
    dispatch(getPlanDetailsHandler(data));
  }, [sortedBy, updatePlan?.data?.data]);

  // My Profile follwer,following and friends page
  useEffect(() => {
    dispatch(getProfileData());
    dispatch(myProfileData(setLoginId));
    dispatch(getFollowersAPIData(setLoginId));
    dispatch(getFollowingInfoData(setLoginId));
    dispatch(getAllFriendData(setLoginId));
  }, [removeFriend, removeFriendMyProfileHandler, unFollowHandler]);

  // Search functionality in following
  const searchFollowing = (event: any) => {
    setSearchFollowingTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };
    if (event.trim() !== "" && getFollow === "following") {
      dispatch(searchFollowingData(finalValue));
    }
  };

  // Search functionality in friend

  const searchFriend = (event: any) => {
    setSearchFriendTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (event.trim() !== "" && getFollow === "friends") {
      dispatch(searchFriendsAPIData(finalValue));
    }
  };

  // Search functionality in follower
  const searchTextData = (event: any) => {
    setSearchTerm(event);
    const finalValue = {
      search: event.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (event.trim() !== "" && getFollow === "followers") {
      dispatch(searchFollowData(finalValue));
    }
  };

  const navigateToMyProfile = () => {
    setShowProfilePage(false);
    navigate("/myprofile");
  };

  const removeFriendHandler = (friendId: any) => {
    setRemoveFriendsId(friendId);
    setRemoveFriendModalShow(true);
  };

  // Remove Functionality in Followers Listing
  const removeHandler = (itemId: any) => {
    setRemoveId(itemId);
    setRemoveModalShow(true);
  };

  // Unfollow Functionality in Following Listing

  const followingHandler = (unFollowId: any) => {
    setRemoveFollowingId(unFollowId);
    setRemoveFollowingModalShow(true);
  };

  const loader = useSelector((state: any) => state?.removeFriendMyProfile);
  const planList: any = useSelector((state: any) => state?.planListing);
  
  
  
  
  return (
    <>
      <Header logout={props.logout} />

      {getMyPlan?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      {planList?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans" className="active">
                MY PLANS
              </Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>

            <TabsContent>
              {getFollow === "" || !showProfilePage ? (
                <>
                  <MyPlansWrap ref={myPlans}>
                    <MyPlansTop>
                      <MyPlansTitle>MY PLANS</MyPlansTitle>
                      <CreateBtn>
                        <Dropdown className="sortby">
                          <Dropdown.Toggle>
                            Sort by
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.656"
                              height="8.596"
                              viewBox="0 0 13.656 8.596"
                            >
                              <path
                                d="M5.944,11.888,0,5.944,5.944,0"
                                transform="translate(0.884 6.828) rotate(-90)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="2.5"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as="button"
                              style={{
                                color: sortedBy == "All Plan" ? "#6840df" : "",
                              }}
                              onClick={() => {
                                // const finalData = { sorting: "Upcoming Plan" };
                                // dispatch(getPlanDetailsHandler(finalData));
                                setSortedBy("All Plan");
                                setSortedDataShow("All Plan");
                              }}
                            >
                              All
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              style={{
                                color:
                                  sortedBy == "Upcoming Plan" ? "#6840df" : "",
                              }}
                              onClick={() => {
                                // const finalData = { sorting: "Upcoming Plan" };
                                // dispatch(getPlanDetailsHandler(finalData));
                                setSortedBy("Upcoming Plan");
                                setSortedDataShow("Upcoming Plan");
                              }}
                            >
                              Upcoming
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              style={{
                                color:
                                  sortedBy == "Plan Created" ? "#6840df" : "",
                              }}
                              onClick={() => {
                                // const finalData = { sorting: "Plan Created" };
                                // dispatch(getPlanDetailsHandler(finalData));
                                setSortedBy("Plan Created");
                                setSortedDataShow("Plan Created");
                              }}
                            >
                              Plan Created
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              style={{
                                color:
                                  sortedBy == "Plan Invited To"
                                    ? "#6840df"
                                    : "",
                              }}
                              onClick={() => {
                                // dispatch(getPlanDetailsHandler(finalData));
                                setSortedBy("Plan Invited To");
                                setSortedDataShow("Plan Invited To");
                              }}
                            >
                              Plan Invited to
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              style={{
                                color: sortedBy == "Private" ? "#6840df" : "",
                              }}
                              onClick={() => {
                                setSortedBy("Private");
                                setSortedDataShow("Private Plan");
                              }}
                            >
                              Private
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </CreateBtn>
                    </MyPlansTop>

                    <MyPlansList>
                      <>
                        {getMyPlan?.data?.data?.map((ele: any) => {
                          return (
                            <MyPlansBox>
                              <MyPlansImage>
                                <Link
                                  to={`/viewplandetails/${ele?._id}`}
                                  className="myPlanImgLink"
                                  style={{ width: "100%" }}
                                >
                                  {ele?.view_plan === "Private" &&
                                    sortedBy === "Private" && (
                                      <MyPlansLock>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12.38"
                                          height="16.249"
                                          viewBox="0 0 12.38 16.249"
                                        >
                                          <path
                                            d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                            transform="translate(-6 -1.5)"
                                            fill="#fff"
                                          />
                                        </svg>
                                      </MyPlansLock>
                                    )}
                                  <img
                                    src={ele?.photo || Nevada}
                                    alt={ele?.plan_name}
                                  />
                                </Link>{" "}
                                {ele?.user_id ===
                                  localStorage.getItem("id") && (
                                  <EditPlanSvgContainer>
                                    {/* <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="edit_svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#3b3b3b"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      onClick={() => {
                                        setPlanDetails(ele);
                                        setCreatePlanModalShow(true);
                                        setUploadBox(true);
                                        setShowImage("");
                                        setImage("");
                                        setImageValidation("");
                                      }}
                                    >
                                      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                    </svg> */}
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="2"
                                      viewBox="0 0 24 24"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() => {
                                        setPlanDetails(ele);
                                        setCreatePlanModalShow(true);
                                        setUploadBox(true);
                                        setShowImage("");
                                        setImage("");
                                        setImageValidation("");
                                      }}
                                    >
                                      <path d="M12 20h9"></path>
                                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                    </svg>
                                  </EditPlanSvgContainer>
                                )}
                              </MyPlansImage>
                              <MyPlansBoxTitle>
                                <Link to={`/viewplandetails/${ele?._id}`}>
                                  {ele?.plan_name}
                                </Link>
                              </MyPlansBoxTitle>
                              <MyPlansDate>
                                {moment(ele?.start_date, "MM/DD/YYYY").format(
                                  "MM/DD/YYYY"
                                )}
                              </MyPlansDate>
                            </MyPlansBox>
                          );
                        })}

                        {getMyPlan?.data?.data?.length === 0 && (
                          <MyPlansNotAvailable>
                            No {sortedDataShow} available right now.
                          </MyPlansNotAvailable>
                        )}
                      </>
                    </MyPlansList>
                  </MyPlansWrap>
                </>
              ) : (
                <>
                  <SocialTabsWrap>
                    <TabsWrap>
                      <Tabs
                        activeKey={getFollow}
                        onSelect={(eventkey: any) => {
                          setFollow(eventkey);
                        }}
                      >
                        <Tab eventKey="followers" title="Followers">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search User"
                                          value={searchTerm}
                                          handleBlur={handleBlur}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchTextData(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />

                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchForFollwerSection?.data?.data
                                    ?.length === 0 &&
                                    searchTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No followers found!
                                      </SearchBoxNote>
                                    )}
                                  {getFollowerList?.length === 0 &&
                                    searchTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any follower.
                                        </p>
                                      </TabDataNotFound>
                                    )}
                                  {searchForFollwerSection?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}
                                  {(
                                    (searchTerm.trim() === "" &&
                                      getFollowerList) ||
                                    searchForFollwerSection?.data?.data
                                  )?.map((item: any, index: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage>
                                            <img
                                              src={item?.photo}
                                              alt={item?.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName>
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>
                                        <Button
                                          className="btn-border"
                                          onClick={() =>
                                            removeHandler(item.follower_id)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="following" title="Following">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search User"
                                          value={searchFollowingTerm}
                                          handleBlur={handleBlur}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFollowing(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  {searchForFollowingSection?.data?.data
                                    ?.length === 0 &&
                                    searchFollowingTerm.trim() !== "" && (
                                      <SearchBoxNote>
                                        No Following Found!
                                      </SearchBoxNote>
                                    )}

                                  {getFollowingList?.length === 0 &&
                                    searchFollowingTerm.trim() === "" && (
                                      <TabDataNotFound>
                                        <p>
                                          You have not currently any following.
                                        </p>
                                      </TabDataNotFound>
                                    )}

                                  {searchForFollowingSection?.isLoading && (
                                    <SearchListLoader>
                                      <img
                                        src={PageLoaderIcon}
                                        alt="Loading..."
                                      />
                                    </SearchListLoader>
                                  )}

                                  {(
                                    (searchFollowingTerm.trim() === "" &&
                                      getFollowingList) ||
                                    searchForFollowingSection?.data?.data
                                  )?.map((item: any, i: number) => {
                                    return (
                                      <li>
                                        <FollowListLeft>
                                          <FollowImage>
                                            <img
                                              src={item.photo}
                                              alt={item.user_name}
                                            />
                                          </FollowImage>
                                          <FollowName>
                                            {item?.user_name ?? item?.userName}
                                          </FollowName>
                                        </FollowListLeft>
                                        <Button
                                          className="btn-border-color"
                                          onClick={() =>
                                            followingHandler(item?.following_id)
                                          }
                                        >
                                          Following
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>

                        <Tab eventKey="friends" title="Friends">
                          <TabsContent>
                            <SocialTabInner>
                              <FormGroup>
                                <Formik
                                  initialValues={{
                                    search: "",
                                  }}
                                  validate={(values: any) => {
                                    let errors: any = {};
                                  }}
                                  onSubmit={submitForm}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <>
                                      <form onSubmit={handleSubmit}>
                                        <CustomInput
                                          inputid="search"
                                          inputlabe=""
                                          inputtype="text"
                                          placeholder="Search User"
                                          value={searchFriendTerm}
                                          handleBlur={handleBlur}
                                          handleChange={(event: any) => {
                                            // setSearchTerm(event.target.value);
                                            searchFriend(event.target.value);
                                          }}
                                          errors=""
                                          touched=""
                                          autocomplete="off"
                                        />
                                        <button
                                          type="submit"
                                          className="searchIconBtn"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21.921"
                                            height="22.01"
                                            viewBox="0 0 21.921 22.01"
                                          >
                                            <path
                                              d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                              transform="translate(-3.002 -3.001)"
                                              fill="#9597a5"
                                            />
                                          </svg>
                                        </button>
                                      </form>
                                    </>
                                  )}
                                </Formik>
                              </FormGroup>

                              <FollowList>
                                <ul>
                                  <ul>
                                    {searchForFriendSection?.data?.data
                                      ?.length === 0 &&
                                      searchFriendTerm.trim() !== "" && (
                                        <SearchBoxNote>
                                          No friends found!
                                        </SearchBoxNote>
                                      )}
                                    {getFriendList?.length === 0 &&
                                      searchFriendTerm.trim() === "" && (
                                        <TabDataNotFound>
                                          <p>
                                            You have not currently any friends.
                                          </p>
                                        </TabDataNotFound>
                                      )}
                                    {searchForFriendSection?.isLoading && (
                                      <SearchListLoader>
                                        <img
                                          src={PageLoaderIcon}
                                          alt="Loading..."
                                        />
                                      </SearchListLoader>
                                    )}
                                    {(
                                      (searchFriendTerm.trim() === "" &&
                                        getFriendList) ||
                                      searchForFriendSection?.data?.data
                                    )?.map((item: any, index: number) => {
                                      return (
                                        <li>
                                          <FollowListLeft>
                                            <FollowImage>
                                              <img
                                                src={item?.photo}
                                                alt={item?.user_name}
                                              />
                                            </FollowImage>
                                            <FollowName>
                                              {item?.user_name ??
                                                item?.userName}
                                            </FollowName>
                                          </FollowListLeft>
                                          <Button
                                            className="btn-border"
                                            onClick={() =>
                                              removeFriendHandler(
                                                item?.friends_id
                                              )
                                            }
                                          >
                                            Remove
                                          </Button>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </ul>
                              </FollowList>
                            </SocialTabInner>
                          </TabsContent>
                        </Tab>
                      </Tabs>
                    </TabsWrap>
                  </SocialTabsWrap>
                </>
              )}
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <AddCollectionModal
        show={AddCollectionModalShow}
        onHide={() => setAddCollectionModalShow(false)}
      />

      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <RemoveFriendsMyProfileModal
        show={removeFriendModalShow}
        onHide={() => setRemoveFriendModalShow(false)}
        setUnfollowModalShow={setRemoveFriendModalShow}
        profile_id={removeFriendsId}
      />

      {/*  For Remove Follower Modal*/}
      <RemoveModal
        show={removeModalShow}
        onHide={() => setRemoveModalShow(false)}
        setUnfollowModalShow={setRemoveModalShow}
        profile_id={removeId}
      />
      {/* For Remove Following Modal */}
      <RemoveFollowigModal
        show={removeFollowinModalShow}
        onHide={() => setRemoveFollowingModalShow(false)}
        setUnfollowModalShow={setRemoveFollowingModalShow}
        profile_id={removeFollowigId}
      />

      <UpdatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
        }}
        imageValidation={imageValidation}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
        getUploadBox={getUploadBox}
        setUploadBox={setUploadBox}
        getPlanDetails={getPlanDetails}
      />
    </>
  );
};

export default MyProfile;
