import { getHotelIdAPI } from "../../../services/getHotelId";

export enum getHotelIdType {
  GET_HOTEL_ID_INFO = "GET_HOTEL_ID_INFO",
  GET_HOTEL_ID_INFO_SUCCESS = "GET_HOTEL_ID_INFO_SUCCESS",
  GET_HOTEL_ID_INFO_FALIURE = "GET_HOTEL_ID_INFO_FALIURE",
}

export const getHotelIdInfo = () => (dispatch: any) => {
  dispatch({
    type: getHotelIdType.GET_HOTEL_ID_INFO,
  });
};

export const getHotelIdInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getHotelIdType.GET_HOTEL_ID_INFO_SUCCESS,
    data,
  });
};

export const getHotelIdInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getHotelIdType.GET_HOTEL_ID_INFO_FALIURE,
    error: msg,
  });
};

export const getHotelIdData = (hotelId: any) => async (dispatch: any) => {
  try {
    dispatch(getHotelIdInfo());
    const result: any = await getHotelIdAPI(hotelId);
    dispatch(getHotelIdInfoSuccess(result));
  } catch (e: any) {
    dispatch(getHotelIdInfoFailed(e?.response?.data));
  }
};
