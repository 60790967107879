import { addFeedbackApi } from "../../../services/addFeedback";

export enum AddFeedBackType {
  ADD_FEEDBACK_INFO = "ADD_FEEDBACK_INFO",
  ADD_FEEDBACK_INFO_SUCCESS = "ADD_FEEDBACK_INFO_SUCCESS",
  ADD_FEEDBACK_INFO_FALIURE = "ADD_FEEDBACK_INFO_FALIURE",
}

export const addFeedbackInfo = () => (dispatch: any) => {
  dispatch({
    type: AddFeedBackType.ADD_FEEDBACK_INFO,
  });
};

export const addFeedbackInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AddFeedBackType.ADD_FEEDBACK_INFO_SUCCESS,
    data,
  });
};

export const addFeedbackinfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: AddFeedBackType.ADD_FEEDBACK_INFO_FALIURE,
    data: { msg },
  });
};

export const addFeedBackData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(addFeedbackInfo());
    const result: any = await addFeedbackApi(finalData);
    dispatch(addFeedbackInfoSuccess(result));
  } catch (e: any) {
    dispatch(addFeedbackinfoFailed(e.message));
  }
};
