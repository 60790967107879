import { myPlanType } from "../../action/myPlan/myPlan";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case myPlanType.MYPLAN_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case myPlanType.MYPLAN_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case myPlanType.MYPLAN_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
