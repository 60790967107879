import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VisibilityIcon from "../../assets/images/visibility-icon.svg";
import FollowersIcon from "../../assets/images/followers-icon.svg";
import FriendsIcon from "../../assets/images/friends-icon.svg";
import PublicIcon from "../../assets/images/public-icon.svg";
import { planStatusHandler } from "../../redux/action/planStatus/planStatus";
import {
  ModalBody,
  ModalBodyBtn,
  RadioBox,
} from "../visiblePubliclyModal/visiblePubliclyModalStyle";

const VisiblePubliclyModal = (props: any) => {
  const dispatch: any = useDispatch();

  const visibilityHandler = () => {
    const data: any = {
      plan_id: props.plan_id,
      visibility: props.getVisibility,
    };

    props.getVisibility !== "" && dispatch(planStatusHandler(data));
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="visiblePubliclyModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .visiblePubliclyModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 460px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .visiblePubliclyModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>VISIBLE PUBLICLY</h2>
            <p>Who can see your post?</p>

            <h4>Select Audience</h4>
            <ul>
              <li>
                <RadioBox>
                  <input
                    type="radio"
                    name="checkbox"
                    value="Public"
                    checked={props.getVisibility === "Public" ? true : false}
                    onChange={(e) => props.setVisibility(e.target.value)}
                    defaultChecked
                  />
                  <span />
                  <label>
                    <img src={PublicIcon} alt="public-icon" />
                    Public
                  </label>
                  <p>All Tripd users</p>
                </RadioBox>
              </li>
              <li>
                <RadioBox>
                  <input
                    type="radio"
                    name="checkbox"
                    value="Friends"
                    checked={props.getVisibility === "Friends" ? true : false}
                    onChange={(e) => props.setVisibility(e.target.value)}
                  />
                  <span />
                  <label>
                    <img src={FriendsIcon} alt="friends-icon" />
                    Friends
                  </label>
                  <p>Only your friends on TripD</p>
                </RadioBox>
              </li>
              <li>
                <RadioBox>
                  <input
                    type="radio"
                    name="checkbox"
                    value="Followers"
                    checked={props.getVisibility === "Followers" ? true : false}
                    onChange={(e) => props.setVisibility(e.target.value)}
                  />
                  <span />
                  <label>
                    <img src={FollowersIcon} alt="followers-icon" />
                    Followers
                  </label>
                  <p>Followers including friends public</p>
                </RadioBox>
              </li>
            </ul>

            <ModalBodyBtn>
              <Button type="button" onClick={() => visibilityHandler()}>
                SUBMIT
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default VisiblePubliclyModal;
