import { forgotPassword } from "../../../services/forgotPassword";

export enum ForgotPasswordType {
  FORGOT_PASSWORD_INFO = "FORGOT_PASSWORD_INFO",
  FORGOT_PASSWORD_INFO_SUCCESS = "FORGOT_PASSWORD_INFO_SUCCESS",
  FORGOT_PASSWORD_INFO_FALIURE = "FORGOT_PASSWORD_INFO_FALIURE",
}

export const forgotPasswordInfo = () => (dispatch: any) => {
  dispatch({
    type: ForgotPasswordType.FORGOT_PASSWORD_INFO,
  });
};

export const forgotPasswordInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ForgotPasswordType.FORGOT_PASSWORD_INFO_SUCCESS,
    data,
  });
};

export const forgotPasswordInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ForgotPasswordType.FORGOT_PASSWORD_INFO_FALIURE,
    error: msg,
  });
};

export const forgotPasswordDataAPI =
  ({ email, type }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(forgotPasswordInfo());
      const result: any = await forgotPassword({
        email,
        type,
      });
      dispatch(forgotPasswordInfoSuccess(result));
    } catch (e: any) {
      dispatch(forgotPasswordInfoFailed(e.response.data.error));
    }
  };
