import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import NotFoundImg from "../../../assets/images/not-found.svg";

import { MyPageWrap, NotFoundImage, NotFoundText } from "./notFoundStyle";

const NotFound = (props: any) => {
  return (
    <>
      <MyPageWrap>
        <NotFoundImage>
          <img src={NotFoundImg} alt="Not Found" />
        </NotFoundImage>
        <NotFoundText>
          We're sorry, the page you requested could not be found please go back
          to the homepage
        </NotFoundText>
        <Link to="/" className="btn">
          Go Home
        </Link>
      </MyPageWrap>
    </>
  );
};

export default NotFound;
