import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const SearchBoxNote = styled.li`
  padding: 0 24px 24px;
  font-family: "Poppins";
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #a1a3b0;
  justify-content: center !important;
  text-align: center;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  padding: 35px 17px 15px 35px;
  border-left: 5px solid #f0f0f0;
  border-right: 5px solid #f0f0f0;
  background: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }

  @media (max-width: 1400px) {
    padding: 35px 17px 15px 17px;
  }
  @media (max-width: 1199px) {
    border-left: none;
    border-right: none;
  }
  @media (max-width: 767px) {
    padding: 35px 15px 15px;
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const ModalMemberGroupList = styled.div`
  margin: 27px auto 0;
  width: 274px;
  max-width: 100%;
`;

export const ModalMemberGroupItem = styled.div`
  position: relative;
  padding-right: 30px;
  margin-bottom: 17px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  border-radius: 30px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const SocialProfileWrap = styled.div`
  padding: 15px 35px 0 0;
  @media (max-width: 1400px) {
    padding: 15px 0 0 0;
  }
`;

export const SocialProfileTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const UserProfileImage = styled.div`
  width: 96px;
  min-width: 96px;
  border-radius: 100px;
  img {
    max-width: 100%;
    border-radius: 100px;
    height: 96px;
    width: 96px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
  }
`;

export const SocialProfileInfo = styled.div`
  padding-left: 15px;
  flex: 52%;
  max-width: 52%;
  @media (max-width: 991px) {
    flex: calc(100% - 96px);
    max-width: calc(100% - 96px);
  }
  @media (max-width: 565px) {
    flex: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      flex: auto;
      max-width: inherit;
      text-align: center;
      padding: 0 30px;
      @media (max-width: 565px) {
        padding: 0 15px;
      }
      &:nth-child(2) {
        border-left: 1px solid #a1a3b0;
        border-right: 1px solid #a1a3b0;
      }
      &:first-child {
        padding-right: 30px;
        padding-left: 0;
        @media (max-width: 565px) {
          padding-right: 15px;
        }
      }
      &:last-child {
        padding-left: 30px;
        padding-right: 0;
        @media (max-width: 565px) {
          padding-left: 15px;
        }
      }
      .btn {
        background: transparent;
        border: none;
        box-shadow: none !important;
        padding: 0;
        margin: 0;
        border-radius: 0;
        cursor: pointer;
      }
      .profile-number {
        display: block;
        color: #3b3b3b;
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        cursor: pointer;
        @media (max-width: 565px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      label {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #a1a3b0;
        margin: 5px 0 0;
        cursor: pointer;
        @media (max-width: 565px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;

export const SocialUserName = styled.h3`
  font-family: "Poppins";
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 15px;
`;

export const AboutUser = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 5px;
  margin-top: 15px;
`;

export const UserEmail = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #4d27da;
  margin-bottom: 5px;
  a {
    color: #4d27da;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #3b3b3b;
    }
  }
`;

export const UserAction = styled.div`
  max-width: calc(100% - (52% + 96px));
  flex: calc(100% - (52% + 96px));
  text-align: right;
  @media (max-width: 1199px) {
    padding-right: 30px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    flex: 100%;
    text-align: left;
    margin: 15px 0 0 111px;
  }
  @media (max-width: 565px) {
    margin: 15px 0 0 0;
    padding-right: 0;
  }
  .btn-fill {
    border-radius: 8px;
    background: #4d27da;
    border: 1px solid #4d27da;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 105px;
    padding: 8px 15px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: transparent;
      color: #4d27da;
    }
    & + .btn-fill {
      margin-left: 20px;
    }
    @media (max-width: 1300px) {
      padding: 8px 12px;
    }
    @media (max-width: 565px) {
      min-width: inherit;
      width: 45%;
    }
  }
  .btn-border {
    border-radius: 8px;
    background: transparent;
    border: 1px solid #4d27da;
    color: #4d27da;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 105px;
    padding: 8px 15px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #4d27da;
      color: #ffffff;
    }
    @media (max-width: 1300px) {
      padding: 8px 12px;
    }
    @media (max-width: 565px) {
      min-width: inherit;
      width: 45%;
    }
  }
  .btn {
    margin-bottom: 20px;
    @media screen and (max-width: 1440px) {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 1199px) {
      margin-bottom: 20px;
    }
    & + .btn {
      margin-left: 20px;
      @media (max-width: 1440px) {
        margin-left: 10px;
      }
      @media (max-width: 1199px) {
        margin-left: 20px;
      }
    }
  }
`;

export const SocialProfileToDo = styled.div`
  padding-top: 30px;

  .collection-btn {
    border: 1px solid #3b3b3b;
    border-radius: 3px;
    background: transparent;
    padding: 3px 13px;
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    min-height: inherit;
    transition: all 0.35s ease 0s;
    box-shadow: none !important;
    margin-bottom: 20px;
  }
`;
// .collection-btn {
//   border: 1px solid #a1a3b0;
//   border-radius: 3px;
//   background: transparent;
//   padding: 3px 13px;
//   color: #a1a3b0;
//   font-family: "Poppins";
//   font-size: 10px;
//   line-height: 16px;
//   font-weight: 400;
//   min-height: inherit;
//   -webkit-transition: all 0.35s ease 0s;
//   transition: all 0.35s ease 0s;
//   box-shadow: none !important;
//   margin-bottom: 20px;
// }

export const MyPlansTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .btn-border {
    border-radius: 8px;
    background: transparent;
    border: 1px solid #4d27da;
    color: #4d27da;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 105px;
    padding: 8px 20px;
    margin-left: 20px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #4d27da;
      color: #ffffff;
    }
    @media (max-width: 1400px) {
      margin-left: 10px;
    }
    @media (max-width: 480px) {
      margin-left: 0;
    }
    @media (max-width: 350px) {
      margin-top: 15px;
    }
  }
`;

export const MyPlansTitle = styled.h4`
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #a1a3b0;
  margin-bottom: 0;
  @media (max-width: 350px) {
    flex: 100%;
  }
`;

export const MyPlansList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

export const MyPlansBox = styled.div`
  flex: 20%;
  max-width: 20%;
  padding: 0 10px;
  position: relative;
  &:hover {
    .collection-action {
      opacity: 1;
    }
  }
  .deletebtn {
    position: absolute;
    top: 5px;
    right: 14px;
    padding: 0;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    z-index: 1;
    background: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
    outline: none;
    svg {
      vertical-align: middle;
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 1300px) {
    flex: 25%;
    max-width: 25%;
  }
  @media (max-width: 1200px) {
    .collection-action {
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    flex: 33.33%;
    max-width: 33.33%;
    margin-bottom: 10px;
  }
  @media (max-width: 565px) {
    flex: 50%;
    max-width: 50%;
  }
`;

export const MyPlansImage = styled.div`
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
  padding-bottom: 85%;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const MyPlansBoxTitle = styled.h4`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #6840df;
  margin-bottom: 5px;
  a {
    color: #000000;
    font-weight: 500;
  }
  &.collection-title {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    text-align: center;
    @media (max-width: 767px) {
      line-height: 14px;
    }
  }
  &.collection-lg-title {
    font-size: 12px;
    line-height: 17px;
    color: #3b3b3b;
    text-align: center;
    font-weight: bold;
  }
`;

export const MyPlansMore = styled.div`
  margin-top: 20px;
  .see-more {
    border: 1px solid #a1a3b0;
    border-radius: 3px;
    background: transparent;
    padding: 3px 13px;
    color: #a1a3b0;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    min-height: inherit;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #3b3b3b;
      color: #ffffff;
      border-color: #3b3b3b;
    }
  }
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: 1px solid #cecece;
    padding: 0 10px;
    display: flex;
    margin: 0;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 17px 10px 15px;
      min-width: 77px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  &.GroupDetailsTab {
    ${TabsContent} {
      padding: 15px 24px;
    }
  }
`;

export const SocialTabsWrap = styled.div`
  width: 570px;
  max-width: 100%;
  margin-top: 35px;

  ${TabsContent} {
    padding: 20px 0 0;
  }
  .nav-tabs {
    border-bottom: none;
    max-width: 66%;
    margin: 0 auto;
    @media (max-width: 1300px) {
      max-width: 75%;
    }
    .nav-item {
      .nav-link {
        font-size: 16px;
        line-height: 25px;
        padding: 8px 4px;
        min-width: inherit;
      }
    }
  }
`;

export const SocialTabInner = styled.div`
  ${FormGroup} {
    margin-bottom: 0;
  }
  form {
    > div {
      margin-bottom: 0;
    }
  }
  .form-control {
    border-radius: 5px;
    background: #f5f5f5;
    height: 45px;
    padding-right: 50px;
    border: none;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .searchIconBtn {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    position: absolute;
    right: 17px;
    top: 12px;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
    path {
      fill: #3b3b3b;
    }
    &:hover {
      path {
        fill: #4d27da;
      }
    }
  }
`;

export const FollowList = styled.div`
  padding: 30px 30px 0;
  min-height: 100px;
  ul {
    &:empty {
      display: none;
    }
    li {
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      .btn-border {
        border: 1px solid #a1a3b0;
        border-radius: 8px;
        background: transparent;
        padding: 8px 23px;
        min-width: 105px;
        color: #a1a3b0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        box-shadow: none !important;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #ffffff;
          background: #3b3b3b;
          border-color: #3b3b3b;
        }
      }
      .btn-border-color {
        border: 1px solid #4d27da;
        border-radius: 8px;
        background: transparent;
        padding: 8px 23px;
        min-width: 105px;
        color: #4d27da;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        box-shadow: none !important;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #ffffff;
          background: #4d27da;
        }
      }
    }
  }
`;

export const FollowListLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const FollowImage = styled.div`
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 36px;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    border-radius: 36px;
    object-fit: cover;
    object-position: top;
  }
`;

export const FollowName = styled.h4`
  color: #8d8d8d;
  font-family: "Poppins";
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
`;

export const TabDataNotFound = styled.li`
  font-family: "Poppins";
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #a1a3b0;
  justify-content: center !important;
  text-align: center;
`;

export const SearchListLoader = styled.li`
  text-align: center;
  justify-content: center !important;
  img {
    width: 36px;
    height: 36px;
    vertical-align: middle;
  }
`;

export const MyPlansLock = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const MyPlansAction = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  .action {
    .dropdown-toggle {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      transition: all 0.35s ease 0s;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        margin-left: 0;
        margin-right: 0;
        transition: all 0.35s ease 0s;
      }
      circle,
      g,
      path {
        transition: all 0.35s ease 0s;
      }
      &:hover {
        circle {
          fill: #6840df;
        }
        g {
          circle {
            fill: #ffffff;
          }
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 8px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          g {
            circle {
              fill: #fff;
            }
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
    &.multiple-action {
      .dropdown-menu {
        min-width: 345px;
        right: auto !important;
        left: 0 !important;
        @media (max-width: 1199px) {
          right: 0 !important;
          left: auto !important;
        }
        @media (max-width: 767px) {
          min-width: 240px;
        }
      }
      .custom-switch-wrap {
        font-family: "Poppins";
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        color: #a1a3b0;
        padding-right: 50px;
        margin-bottom: 15px;
        position: relative;
        .form-switch {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
          min-height: inherit;
          .form-check-input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            z-index: 1;
            cursor: pointer;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            opacity: 0;
          }
          .form-check-label {
            border: 1px solid #8d8d8d;
            border-radius: 30px;
            width: 38px;
            height: 22px;
            background: transparent;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;
            text-indent: -99px;
            overflow: hidden;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 20px;
              background: #cecece;
              display: inline-block;
              position: absolute;
              left: 2px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.25s ease 0s;
            }
          }
          .form-check-input {
            &:checked {
              + .form-check-label {
                &:before {
                  left: 18px;
                  background: #48d85e;
                }
              }
            }
          }
        }
        h4 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
          @media (max-width: 767px) {
            font-size: 13px;
          }
        }
      }
    }
  }
`;

export const CityTitle = styled.h3`
  font-family: "Poppins";
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  color: #3b3b3b !important;
  border-bottom: 1px solid #a1a3b0;
  @media (max-width: 767px) {
    font-size: 15px !important;
  }
`;

export const RecordNotFound = styled.div`
  font-family: "Poppins";
  font-size: 15px;
  line-height: 20px;
  color: #a1a3b0;
  margin-bottom: 20px;
`;

export const MyPlansActionWrap = styled.div`
  opacity: 0;
`;

export const BackToList = styled.button`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6c53e4;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.35s ease 0s;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
  path {
    transition: all 0.35s ease 0s;
  }
  &:hover {
    opacity: 1;
    color: #3b3b3b;
    path {
      fill: #3b3b3b;
    }
  }
`;

export const Collectionbtn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px 15px;
`;
