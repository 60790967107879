import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  background: #ffffff;
  margin: 0 5px;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  @media (max-width: 1199px) {
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
`;

export const ProfileTopMenu = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #cecece;
  padding: 22px 20px 0;
  margin: 0;
  justify-content: space-between;
  display: flex;
  z-index: 3;
  @media (max-width: 565px) {
    overflow-x: scroll;
    width: calc(100vw - 6px);
  }
  a,
  button {
    margin: 0;
    border-radius: 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    position: relative;
    padding: 14px 0 14px;
    text-align: center;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      width: 0;
      margin: auto;
      background: #6840df;
      opacity: 0;
      transition: all 0.35s ease 0s;
    }
    &:hover {
      color: #6840df;
    }
    &.active {
      color: #6840df;
      font-size: 16px;
      &:before {
        opacity: 1;
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    @media (max-width: 600px) {
      font-size: 12px;
    }
    @media (max-width: 565px) {
      min-width: max-content;
      margin: 0 8px;
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  @media (max-width: 767px) {
    padding: 32px 15px;
  }
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: 1px solid #cecece;
    padding: 0 10px;
    display: flex;
    margin: 0;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 17px 10px 15px;
      min-width: 77px;
      text-align: center;
      cursor: pointer;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  &.GroupDetailsTab {
    // .nav-tabs {
    //   .nav-link {
    //     &:before {
    //       display: none;
    //     }
    //   }
    // }
    ${TabsContent} {
      padding: 15px 24px;
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none !important;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 15px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.createNewWrap {
    .modal-body {
      padding-top: 18px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        color: #4d27da;
        margin-bottom: 25px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        border-bottom: 1px solid #9597a6;
        padding: 0 20px 20px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .btn {
          padding: 0;
          margin: 0;
          border-radius: 0;
          box-shadow: none !important;
          outline: none;
          font-family: "Poppins";
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          color: #3b3b3b;
          background: transparent;
          border: none;
          width: 100%;
          align-items: center;
          transition: all 0.35s ease 0s;
          cursor: pointer;
          display: flex;
          svg {
            vertical-align: middle;
            margin-right: 10px;
            path {
              transition: all 0.35s ease 0s;
            }
          }
          &:hover {
            color: #4d27da;
            color: #000;
            path {
              fill: #3b3b3b;
              fill: #000;
            }
          }
        }
      }
    }
  }

  &.shareWithWrap {
    .modal-body {
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
      }
      ${FormGroup} {
        margin-bottom: 0;
        .form-label {
          font-family: Poppins;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.createPlanWrap {
    .modalTitle {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      text-align: center;
      max-width: 410px;
      margin: auto auto 17px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        svg {
          margin-right: 15px;
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          z-index: 1;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }

    .planFeature {
      padding: 0 10px;
      margin: 17px 0 0;
      list-style-type: none;
      li {
        font-family: "Poppins";
        font-size: 12px;
        line-height: 19px;
        font-weight: 400;
        color: #a1a3b0;
        padding-right: 50px;
        margin-bottom: 15px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        h4 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 0;
        }
        .form-switch {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
          min-height: inherit;
          .form-check-input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            z-index: 1;
            cursor: pointer;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            opacity: 0;
          }
          .form-check-label {
            border: 1px solid #8d8d8d;
            border-radius: 30px;
            width: 38px;
            height: 22px;
            background: transparent;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;
            text-indent: -99px;
            overflow: hidden;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 20px;
              background: #cecece;
              display: inline-block;
              position: absolute;
              left: 2px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.25s ease 0s;
            }
          }
          .form-check-input {
            &:checked {
              + .form-check-label {
                &:before {
                  left: 18px;
                  background: #48d85e;
                }
              }
            }
          }
        }
      }
    }
    .friendsList {
      padding: 5px 0 3px;
      margin: 0 -10px;
      list-style-type: none;
      flex-wrap: wrap;
      align-items: flex-start;
      display: flex;
      > li {
        width: 33.33%;
        padding: 0 10px;
        margin: 0 0 17px;
      }
    }
  }

  &.createGroupWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        margin-right: 10px;
        margin-top: 4px;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        svg {
          margin-right: 15px;
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }
  }

  &.inviteOthersWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
    }
  }

  &.collectionsWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 20px;
    }
    ul {
      margin: 0 -10px;
      padding: 0;
      list-style-type: none;
      flex-wrap: wrap;
      display: flex;
      li {
        margin: 0 0 20px;
        padding: 0 10px;
        width: 50%;
      }
    }
  }

  &.plansCreatedWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 20px;
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: none;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 8px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
    }
    + .btn {
      margin-left: 20px;
    }
  }
`;

export const UserListImage = styled.div`
  width: 48px;
  min-width: 48px;
  img {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
  }
`;

export const UserTime = styled.div`
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
  color: #8d8d8d;
  letter-spacing: 0px;
  margin: 7px 0 0;
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 10px 0 0;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      .totalFriends {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const CreatePlanDate = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 20px;
  display: flex;
  img {
    margin-right: 10px;
  }
`;

export const ButtonTabs = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TabButton = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  border-radius: 50px;
  padding: 3px 15px;
  cursor: default;
  margin: 0 5px;
  height: 34px;
  display: flex;
  align-items: center;
  transition: all 0.35s ease 0s;
  &.active {
    font-weight: 500;
    color: #4d27da;
    border: 2px solid #4d27da;
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const InviteOtherSearch = styled.div`
  margin: 0 0 22px;
  width: 340px;
  max-width: 340px;
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #9597a5;
      border-radius: 5px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      padding: 8px 45px 8px 15px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 17px;
      top: 7px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
    .addIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 7px;
      top: 34px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        circle,
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
  }
  &.inviteEmailBox {
    ${FormGroup} {
      margin-bottom: 10px;
    }
  }
  .addAnotherEmail {
    padding: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: normal;
    color: #4d27da;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
`;

export const MyGroupTabContent = styled.div`
  min-height: 305px;
`;

export const MyGroupTabContentInner = styled.div`
  display: flex;
`;

export const InviteMyGroups = styled.div`
  width: 340px;
  max-width: 100%;
  > ul {
    margin: 0;
    padding: 2px 0 0;
    list-style-type: none;
    > li {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupItem = styled.div`
  position: relative;
  padding-left: 35px;
  display: inline-block;
  vertical-align: middle;
  ${FriendItem} {
    h3 {
      font-size: 12px;
      line-height: 17px;
      margin: 0 0 10px;
    }
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -16px;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupMembers = styled.div`
  padding-left: 35px;
  flex: 1;
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupMemberItem = styled.div`
  padding: 0 0 0 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersLeft = styled.div`
  width: 340px;
  max-width: 100%;
  ${InviteOtherSearch} {
    margin-bottom: 20px;
  }
  ${FormGroup} {
    .form-label {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 8px;
    }
    .searchIconBtn {
      top: 34px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const MyFollowersItemWrap = styled.div`
  position: relative;
  padding-right: 30px;
  ${InviteGroupCheckBox} {
    span {
      left: auto;
      right: 0;
    }
  }
`;

export const MyFollowersItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersRight = styled.div`
  padding: 43px 0 0 59px;
  flex: 1;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 20px;
  }
  ${MyFollowersItemWrap} {
    padding-right: 0;
  }
`;

export const CollectionsItem = styled.div`
  img {
    max-width: 100%;
  }
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 5px 0 0 0;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const CollectionsItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const ModalAddBtn = styled.div`
  text-align: center;
  .btn {
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    box-shadow: none !important;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: transparent;
    color: #3b3b3b;
    padding: 0;
    transition: all 0.35s ease 0s;
    svg {
      vertical-align: middle;
      path,
      circle {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      circle {
        fill: #000;
      }
    }
  }
`;

export const PlansTabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: none;
    padding: 0;
    margin: 0 0 30px;
    display: flex;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 0 10px 13px;
      min-width: 110px;
      text-align: center;
      cursor: pointer;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }
  ${TabsContent} {
    padding: 0;
  }
`;

export const PlansItem = styled.div`
  margin: 0 0 20px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const PlansItemImageWrap = styled.div`
  margin: 0;
  width: 160px;
  min-width: 160px;
  max-width: 100%;
`;

export const PlansItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const PlansItemInfo = styled.div`
  margin: 0;
  padding-left: 20px;
  flex: 1;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  color: #8d8d8d;
  h3 {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0 !important;
  }
`;

export const PlansInfoDate = styled.div`
  margin: 20px 0 0;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #3b3b3b;
`;

export const MainContentPanelWrap = styled.div`
  // padding-top: 22px;
  padding-top: 0;
  // ${TabsWrap} {
  //   padding-top: 22px;
  // }
  .nav-tabs {
    .nav-link {
      padding: 14px 10px 14px;
      font-size: 14px;
      &.active {
        color: #6840df;
      }
    }
  }
`;

export const ProfileWrap = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 60%;
  margin-bottom: 20px;
  @media (max-width: 1440px) {
    max-width: 65%;
  }
  @media (max-width: 1400px) {
    max-width: 70%;
  }
`;

export const ProfileLeft = styled.div`
  max-width: 23%;
  flex: 23%;
`;

export const ProfileImage = styled.div`
  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const ProfileName = styled.h4`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #3b3b3b;
  margin: 15px 0 0 0;
`;

export const ProfileUserName = styled.span`
  display: block;
  color: #4d27da;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
`;

export const ProfileRight = styled.div`
  flex: 77%;
  max-width: 77%;
  padding-left: 40px;
  padding-top: 20px;
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    li {
      padding: 0 30px;
      flex: auto;
      max-width: inherit;
      text-align: center;
      &:first-child {
        padding-right: 30px;
        padding-left: 0;
      }
      &:last-child {
        padding-left: 30px;
        padding-right: 0;
      }
      &:nth-child(2) {
        border-left: 1px solid #a1a3b0;
        border-right: 1px solid #a1a3b0;
      }
      .profile-number {
        display: block;
        color: #3b3b3b;
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
      }
      label {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #a1a3b0;
        margin: 5px 0 0;
      }
    }
  }
`;

export const FormGroupWrap = styled.div`
  position: relative;
  .custom-group {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 10px;
    min-height: 70px;
    p {
      font-family: "Poppins";
      font-size: 10px;
      line-height: 15px;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 3px;
    }
    a {
      font-family: "Poppins";
      font-size: 10px;
      line-height: 15px;
      font-weight: 500;
      color: #4d27da;
    }
  }
  .profile-edit-btn {
    position: absolute;
    top: 0;
    right: -30px;
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: none !important;
    &:hover {
      path {
        fill: #4d27da;
      }
    }
  }
`;

export const UserSocialMedia = styled.div`
  ul {
    display: flex;
    align-items: center;
    li {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 17px;
      font-weight: 500;
      color: #a1a3b0;
      text-transform: uppercase;
      & + li {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #cecece;
      }
      a {
        align-items: center;
        display: flex;
        color: #a1a3b0;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #6840df;
        }
      }
      img {
        margin-right: 10px;
      }
    }
  }
`;

export const ProfileSubTitle = styled.h4`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 12px;
`;

export const MyPlansWrap = styled.div`
  margin-top: 45px;
  .collection-tab {
  }
`;

export const MyPlansTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
  .modal-btn {
    padding: 0;
    &:hover {
      circle {
        fill: #6840df;
      }
      path {
        fill: #ffffff;
        stroke: #6840df;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export const MyPlansTitle = styled.h4`
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #a1a3b0;
  margin-bottom: 0;
`;

export const CreateBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .filters {
    padding: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .sortby {
    margin-left: 30px;
    &:last-child {
      margin-right: 0;
    }
    .dropdown-toggle {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        margin-left: 10px;
        margin-right: 0;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 8px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:first-child {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          g {
            circle {
              fill: #fff;
            }
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
`;

export const MyPlansList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

export const MyPlansBox = styled.div`
  flex: 20%;
  max-width: 20%;
  padding: 0 10px;
  &:hover {
    .collection-action {
      opacity: 1;
    }
  }
`;

export const MyPlansImage = styled.div`
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
  padding-bottom: 85%;
  display: block;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const MyPlansBoxTitle = styled.h4`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #6840df;
  margin-bottom: 5px;
  a {
    color: #6840df;
  }
  &.collection-title {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    color: #3b3b3b;
    text-align: center;
  }
  &.collection-lg-title {
    font-size: 12px;
    line-height: 17px;
    color: #3b3b3b;
    text-align: center;
  }
`;

export const MyPlansDate = styled.span`
  display: block;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
`;

export const MyPlansLock = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const MyPlansAction = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  .action {
    .dropdown-toggle {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      transition: all 0.35s ease 0s;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        margin-left: 0;
        margin-right: 0;
        transition: all 0.35s ease 0s;
      }
      circle,
      g,
      path {
        transition: all 0.35s ease 0s;
      }
      &:hover {
        circle {
          fill: #6840df;
        }
        g {
          circle {
            fill: #ffffff;
          }
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 8px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:first-child {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          g {
            circle {
              fill: #fff;
            }
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
    &.multiple-action {
      .dropdown-menu {
        min-width: 345px;
        right: auto !important;
        left: 0 !important;
      }
      .custom-switch-wrap {
        font-family: "Poppins";
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        color: #a1a3b0;
        padding-right: 50px;
        margin-bottom: 15px;
        position: relative;
        .form-switch {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
          min-height: inherit;
          .form-check-input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            z-index: 1;
            cursor: pointer;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            opacity: 0;
          }
          .form-check-label {
            border: 1px solid #8d8d8d;
            border-radius: 30px;
            width: 38px;
            height: 22px;
            background: transparent;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;
            text-indent: -99px;
            overflow: hidden;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 20px;
              background: #cecece;
              display: inline-block;
              position: absolute;
              left: 2px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.25s ease 0s;
            }
          }
          .form-check-input {
            &:checked {
              + .form-check-label {
                &:before {
                  left: 18px;
                  background: #48d85e;
                }
              }
            }
          }
        }
        h4 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const MyPlansSubTitle = styled.span`
  display: block;
  flex: 100%;
  max-width: 100%;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  margin-top: 5px;
`;

export const MyPlansActionWrap = styled.div`
  opacity: 0;
`;

export const MyPlansDropdown = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  .action {
    .dropdown-toggle {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      transition: all 0.35s ease 0s;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        margin-left: 0;
        margin-right: 0;
        transition: all 0.35s ease 0s;
      }
      circle,
      path {
        transition: all 0.35s ease 0s;
      }
      &:hover {
        circle {
          fill: #6840df;
        }
        path {
          fill: #ffffff;
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 8px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:first-child {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          g {
            circle {
              fill: #fff;
            }
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
`;

export const CityTitle = styled.h3`
  font-family: "Poppins";
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 600 !important;
  color: #3b3b3b !important;
  border-bottom: 1px solid #a1a3b0;
`;

export const BtnWrap = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .delete-btn {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #3b3b3b;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #6840df;
    }
  }
  .btn-primary {
    background: #6840df;
    border: none;
    border-radius: 18px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #3b3b3b;
    }
  }
`;

export const MyPlansMore = styled.div`
  margin-top: 20px;
  .see-more {
    border: 1px solid #a1a3b0;
    border-radius: 3px;
    background: transparent;
    padding: 3px 13px;
    color: #a1a3b0;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    min-height: inherit;
    transition: all 0.35s ease 0s;
    box-shadow: none !important;
    &:hover {
      background: #3b3b3b;
      color: #ffffff;
      border-color: #3b3b3b;
    }
  }
`;

export const AddCollectionWrap = styled.div`
  .form-sub-title {
    color: #3b3b3b;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  ${InviteGroupItem} {
    display: inline-block;
    width: 49.5%;
    vertical-align: top;
    margin-bottom: 12px;
    h4 {
      color: #3b3b3b;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 0;
      svg {
        margin-right: 11px;
        vertical-align: -1px;
      }
    }
    p {
      color: #a1a3b0;
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
    }
  }
  ${InviteGroupRadioBox} {
    span {
      bottom: auto;
      top: 0;
    }
  }
  ${BtnWrap} {
    justify-content: center;
    .btn-submit {
      background: #3b3b3b;
      &:hover {
        background: #6840df;
      }
    }
  }
`;

export const UploadImageBox = styled.div`
  max-width: 156px;
  border-radius: 10px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 117px;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .btn-upload {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #3b3b3b;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding-bottom: 15px;
    background: transparent;
    border: none;
    transition: all 0.35s ease 0s;
    box-shadow: none !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    display: flex;

    .uploader-title {
      padding-top: 10px;
    }
  }
`;

export const NotificationWrap = styled.div`
  max-width: 56%;
`;

export const NotificationList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #cecece;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
`;

export const NotificationListInfo = styled.div`
  padding-left: 10px;
  h3 {
    color: #3b3b3b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0;
    strong {
      font-weight: 700;
    }
    svg {
      margin: 0 8px;
    }
  }
  .btn-accept {
    color: #48d85e;
    border: 1px solid #48d85e;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 13px;
    margin-top: 15px;
    margin-right: 15px;
    min-width: 78px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #ffffff;
      background: #48d85e;
    }
  }
  .btn-reject {
    color: #ef3939;
    border: 1px solid #ef3939;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 13px;
    margin-top: 15px;
    min-width: 78px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #ffffff;
      background: #ef3939;
    }
  }
  .unsure {
    color: #ffa700;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
  .going {
    color: #159a43;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
`;

export const NotificationListInner = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const NotificationListImage = styled.div`
  width: 70px;
  img {
    max-width: 100%;
  }
`;

export const SettingsWrap = styled.div`
  width: 418px;
  max-width: 100%;
  @media (max-width: 600px) {
    width: 100%;
  }
  ${FormGroup} {
    margin-bottom: 10px;
    &.user-image-wrap {
      margin-bottom: 25px;
    }
    .form-label {
      color: #3b3b3b;
      font-weight: 500;
      line-height: 21px;
    }
  }

  .form-label {
    color: #3b3b3b;
    font-weight: 500;
    line-height: 21px;
  }
  textarea {
    &.form-control {
      height: 90px;
      resize: none;
    }
  }

  .form-control {
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:focus {
      border-color: #4d27da;
    }
  }

  .notification-setting-group {
    margin-bottom: 25px;
    .form-label {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 23px;
      font-weight: 600;
      color: #3b3b3b;
      margin-bottom: 15px;
    }
    li {
      position: relative;
      margin-bottom: 19px;
      padding-right: 45px;
    }
    h4 {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .form-switch {
      position: absolute;
      right: 0;
      top: -2px;
      margin: 0;
      font-size: 0;
      line-height: 0;
      min-height: inherit;
      .form-check-input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        z-index: 1;
        cursor: pointer;
        border-radius: 30px;
        background: transparent;
        border: none;
        box-shadow: none;
        opacity: 0;
      }
      .form-check-label {
        border: 1px solid #8d8d8d;
        border-radius: 30px;
        width: 38px;
        height: 22px;
        background: transparent;
        font-size: 0;
        line-height: 0;
        margin: 0;
        display: block;
        position: relative;
        text-indent: -99px;
        overflow: hidden;
        &:before {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 20px;
          background: #cecece;
          display: inline-block;
          position: absolute;
          left: 2px;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: all 0.25s ease 0s;
        }
      }
      .form-check-input {
        &:checked {
          + .form-check-label {
            &:before {
              left: 18px;
              background: #48d85e;
            }
          }
        }
      }
    }
  }
  .btn-save {
    border-radius: 8px;
    background: #4d27da;
    border: none;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 131px;
    padding: 8px 15px;
    margin-top: 5px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #3b3b3b;
    }
  }
`;

export const ImageUploader = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 100px;
  position: relative;
  background: #f5f5f5;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: cover;
    object-position: top;
  }
  .edit-image {
    position: absolute;
    bottom: 0;
    right: 0;
    &:hover {
      circle {
        fill: #3b3b3b;
      }
    }
  }
`;

export const SocialLinkList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const SocialLinkLabel = styled.div`
  min-width: 125px;
  max-width: 125px;
  flex: 125px;
  color: #a1a3b0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  img {
    padding-right: 10px;
  }
`;

export const ResetPasswordGroup = styled.div`
  padding: 20px;
  margin: 25px 0 0;
  border-radius: 5px;
  background: #f5f5f5;
  .form-label {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
  }
  .eye-icon {
    top: 10px;
  }
  .form-control {
    border: 1px solid #9597a6;
    background: #f5f5f5;
  }
  .btn-primary {
    border-radius: 8px;
    background: #9597a6;
    border: none;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 19px;
    margin-top: 5px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #6840df;
    }
  }
`;

export const InvitesWrap = styled.div`
  max-width: 53%;
  .invites-title {
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 30px;
    span {
      color: #4d27da;
    }
  }
  .form-label {
    line-height: 21px;
    font-weight: 500;
    color: #3b3b3b;
    margin-bottom: 5px;
  }
  .btn-primary {
    border-radius: 8px;
    background: #4d27da;
    border: none;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 131px;
    padding: 8px 15px;
    margin-top: 15px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #3b3b3b;
    }
  }
`;

export const FeedbackWrap = styled.div`
  .invites-title {
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 5px;
  }
  .subtitle {
    color: #3b3b3b;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 20px;
  }
  form {
    max-width: 52%;
    .form-label {
      line-height: 21px;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 5px;
    }
    .btn-primary {
      border-radius: 8px;
      background: #4d27da;
      border: none;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      min-width: 131px;
      padding: 8px 15px;
      margin-top: 15px;
      box-shadow: none !important;
      transition: all 0.35s ease 0s;
      &:hover {
        background: #3b3b3b;
      }
    }
  }
`;

export const FeedbackUserId = styled.div`
  color: #4d27da;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
`;

export const DropZone = styled.div`
  min-height: 170px;
  border: 1px solid #8d8d8d;
  border-radius: 5px;
  padding: 10px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  .form-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: none !important;
    font-size: 0;
    line-height: 0;
    background: transparent;
    cursor: pointer;
    opacity: 0;
  }
  .upload-text {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    margin-top: 10px;
    a {
      color: #4d27da;
      text-decoration: underline;
    }
  }
  .support-file {
    color: #3b3b3b;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    margin-bottom: -25px;
    margin-top: 40px;
  }
`;

export const ImageEditImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  cursor: pointer;
  overflow: hidden;
  svg {
    vertical-align: middle;
    circle {
      transition: all 0.35s ease 0s;
    }
  }
  &:hover {
    circle {
      fill: #3b3b3b;
    }
  }

  input {
    position: absolute;
    width: 110px;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    padding: 0;
    opacity: 0;
  }
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;

export const ImageValidation = styled.div`
  margin-top: 15px;
  font-family: "Poppins";
  color: #ef3939;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  &:empty {
    display: none;
  }
`;
