import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Dropdown, Button, Carousel, Pagination } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import CreateNewModal from "../../../components/createNewModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import StudioTour from "../../../assets/images/studio-tour-hollywood.jpg";
import AquariumPacific from "../../../assets/images/aquarium-pacific.jpg";
import TourLosAngeles from "../../../assets/images/tour-los-angeles.jpg";
import PlansModal from "../../../components/plansModal/index";

import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  RentalsResultsMain,
  RentalsResultsList,
  RentalsResultsItem,
  RentalsListImage,
  RentalsListInfo,
  RentalsTitleWrap,
  DescriptionInfo,
  RentalsListMap,
  PaginationWrap,
  SearchListLoader,
  SportsGalleryItem,
  SportsLogoInner,
  SportsGallery,
  SportsLogo,
  SportsGalleryBottom,
  SportsDetailsTop,
  SportsDetails,
  SportsGalleryTop,
  SportsTitleWrap,
  SportsTitle,
  DetailSportsInfo,
  DetailSportsInfoWrap,
  DetailSportsRating,
  DetailSportsReview,
  DetailSportsPrice,
  SportsTeamDesc,
  CustomerReviewItem,
  RatingReviewWrap,
  ListRating,
  ListReview,
  RecordNotFound,
  BackToList,
  ModalSection,
} from "./foodAndDrinksResultStyle";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import CreateGroupModal from "../../../components/createGroupModal/index";

import MapComponent from "../../../components/mapComponent/mapComponent";
import { mapKey } from "../../../resource/constants";
import { pointOfInterestData } from "../../../redux/action/point-of-interest/pointOfInterest";
import AnotherDayModal from "../../../components/anotherDayModal";
import CreatePlanModal from "../../../components/createPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import ChatsPlanModal from "../../../components/chatsPlanModal";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import { getImageGoogleData } from "../../../redux/action/getImageGoogle/getImageGoogle";
import RentalThumbOne from "../../../assets/images/rentals-detail-thumb.jpg";
import RentalThumbTwo from "../../../assets/images/rentals-detail-thumb-1.jpg";
import RentalThumbThree from "../../../assets/images/rentals-detail-thumb-2.jpg";
import RentalImageTwo from "../../../assets/images/rental-result-2.jpg";
import ImageGalleryModal from "../../../components/imageGalleryModal";
import ShareWithModal from "../../../components/shareWithModal/index";
import GetAllCollectionsModal from "../../../components/collectionsModal";
import Modal from "react-bootstrap/Modal";
// import { sendEmailInfoData } from "../../../redux/action/send-email/sendEmail";

const FoodAndDrinks: any = (props: any) => {
  const [lang, setLang] = useState("");
  const [long, setLong] = useState("");
  const lats: any = JSON.parse(localStorage.getItem("lat") || "{}");
  const longt: any = JSON.parse(localStorage.getItem("long") || "{}");

  const dispatch: any = useDispatch();

  const navigate = useNavigate();
  const id: any = useLocation();
  const [Data, setData] = useState<any[]>([]);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);
  const [pageToken, setPageToken] = useState<any[]>([]);
  const [plansModal, setPlansModal] = useState(false);
  const [rentalItemId, setRentalItemId] = useState("");
  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [showSortBy, setShowSortBy] = useState(-1);
  const [sortingType, setSortingType] = useState("Price");
  const [totalPages, setTotalPages] = useState(1);
  const [getAllImages, setAllImages] = useState<any[]>([]);
  const [openChatModel, setOpenChatModel] = useState(false);
  const [interestPage, setInterestPage] = useState(false);
  const [photos, setPhotos] = useState<any[]>([]);
  const [imageGalleryModalShow, setImageGalleryModalShow] = useState(false);
  const [interestData, setInterestData] = useState("");
  const [placeIdData, setPlaceIdData] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [sharewithImage, setShareWithImage] = useState([]);
  const [foodAndDrinksLink, setFoodAndDrinksLink] = useState("");
  const [foodAndDrinkName, setFoodAndDrinkName] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "food and drink",
  });
  const [getImageUrl, setImageUrl] = useState("");
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [backButton, setBackButton] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [show, setShow] = useState(false);

  const pointOfInterest: any = useSelector(
    (state: any) => state.pointOfInterest
  );

  const getImageGoogle: any = useSelector((state: any) => state.getImageGoogle);
  const createPlan = useSelector((state: any) => state?.createPlan);
  const history: any = useNavigate();
  const latLong: any = useSelector((state: any) => state?.latLong);

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const lat: any = JSON.parse(localStorage.getItem("lat") || "{}");

  const longs: any = JSON.parse(localStorage.getItem("long") || "{}");

  const [place, setPlace] = useState<any>("");
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    if (!localStorage.getItem("location")) {
      setShow(true);
      dispatch({ type: "RESET_POINT_OF_INTEREST_FOOD_AND_DRINKS_DATA" });
    } else {
      if (
        getSearchResult?.search?.trim()?.length >= 3 ||
        getSearchResult?.search?.trim() === ""
      ) {
        const finalData = {
          query: "restaurant",
          type: "restaurant",
          lat: JSON.parse(localStorage.getItem("lat") || "{}")
            ? JSON.parse(localStorage.getItem("lat") || "{}")
            : "37.33874",
          // lat: (latLong?.data?.item?.lat ? latLong?.data?.item?.lat : lat?lat:Number(latLong?.data?.item?.lat))??"37.33874",
          // lat ? lat : Number(latLong?.data?.item?.lat)
          long: JSON.parse(localStorage.getItem("long") || "{}")
            ? JSON.parse(localStorage.getItem("long") || "{}")
            : "-121.8852525",

          // longs ? longs : Number(latLong?.data?.item?.lng)
          token: "",
          search: localStorage.getItem("main-search-panel-data")
            ? localStorage.getItem("main-search-panel-data")
            : getSearchResult?.search,
        };

        dispatch(pointOfInterestData(finalData));
        setPageNumber(1);
      }
    }
  }, [getSearchResult, place]);

  // useEffect(() => {
  //   const finalData = {
  //     query: "restaurant",
  //     type: "restaurant",
  //     lat: "37.33874",
  //     long: "-121.8852525",
  //     token: "",
  //     search: localStorage.getItem("main-search-panel-data")
  //       ? localStorage.getItem("main-search-panel-data")
  //       : getSearchResult?.search,
  //   };
  //   (getSearchResult?.search?.trim()?.length >= 3 ||
  //     getSearchResult?.search?.trim() === "") &&
  //     dispatch(pointOfInterestData(finalData));
  //   setPageNumber(1);
  // }, [getSearchResult]);

  // useEffect(() => {
  //   if (latLong?.data?.item?.lat && latLong?.data?.item?.lng) {
  //     const finalData = {
  //       query: "restaurant",
  //       type: "restaurant",
  //       lat: lat ? lat : Number(latLong?.data?.item?.lat),
  //       long: longs ? longs : Number(latLong?.data?.item?.lng),
  //       token: "",
  //       search: localStorage.getItem("main-search-panel-data")
  //         ? localStorage.getItem("main-search-panel-data")
  //         : getSearchResult?.search,
  //     };
  //     dispatch(pointOfInterestData(finalData));
  //     setPageNumber(1);
  //   }
  // }, [
  //   latLong?.data?.item?.lat,
  //   latLong?.data?.item?.lng,
  //   getSearchResult,
  //   place,
  // ]);

  useEffect(() => {
    if (pointOfInterest?.data) {
      setData(pointOfInterest?.data?.results);
      if (pointOfInterest?.data?.next_page_token) {
        setPageToken((token) => [
          ...token,
          pointOfInterest?.data?.next_page_token,
        ]);
      }
    }
  }, [pointOfInterest?.data]);

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = React.useState(false);
  const [groupImages, setGroupImages] = useState("");
  const [getToken, setToken] = useState<any[]>([]);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const shareChatImages = (item: any) => {
    setGroupImages(item);
    setDynamicLocation("/food-and-drink-details");
  };

  useEffect(() => {
    if (localStorage.getItem("next-page-token")) {
      const checkLength = JSON.parse(
        localStorage.getItem("next-page-token") || ""
      );
      if (checkLength.length === 0) {
        setPageNumber(1);
      } else if (checkLength.length === 1) {
        setPageNumber(2);
      } else if (checkLength.length === 2) {
        setPageNumber(3);
      }
    } else {
      setPageNumber(1);
    }
  }, [pageNumber]);

  const urlLocation = useLocation();

  useEffect(() => {
    if (urlLocation.pathname.slice(15)?.length > 1) {
      const finalData = {
        query: "restaurant",
        type: "restaurant",
        lat: JSON.parse(localStorage.getItem("lat") || "{}")
          ? JSON.parse(localStorage.getItem("lat") || "{}")
          : "37.33874",
        long: JSON.parse(localStorage.getItem("long") || "{}")
          ? JSON.parse(localStorage.getItem("long") || "{}")
          : "-121.8852525",
        token: urlLocation.pathname.slice(15),
        search: localStorage.getItem("main-search-panel-data")
          ? localStorage.getItem("main-search-panel-data")
          : getSearchResult?.search,
      };
      dispatch(pointOfInterestData(finalData));
    }
  }, [urlLocation.pathname.slice(15)]);

  const prevFun = () => {
    // getToken.shift();
    if (document.getElementById("foodanddrinks") !== undefined) {
      document.getElementById("foodanddrinks")?.scroll(0, 0);
    }

    const tokenArray: any = JSON.parse(
      localStorage.getItem("next-page-token") || ""
    );
    tokenArray.shift();
    localStorage.setItem("next-page-token", JSON.stringify(tokenArray));
    console.log("Dfgdgdfgdfgdrw", tokenArray);
    if (tokenArray.length === 0) {
      navigate(`/foodanddrinks/${1}`);
    } else {
      navigate(`/foodanddrinks/${tokenArray?.[0]}`);
    }
  };

  const tempArr: any = [];

  const nextFun = () => {
    if (pointOfInterest?.data?.next_page_token) {
      if (document.getElementById("foodanddrinks") !== undefined) {
        document.getElementById("foodanddrinks")?.scroll(0, 0);
      }
      // getToken.unshift(pointOfInterest?.data?.next_page_token);

      if (localStorage.getItem("next-page-token")) {
        const nextPageToken = JSON.parse(
          localStorage.getItem("next-page-token") || ""
        );
        nextPageToken.unshift(pointOfInterest?.data?.next_page_token);
        localStorage.setItem("next-page-token", JSON.stringify(nextPageToken));
        if (nextPageToken?.length === 0) {
          navigate(`/foodanddrinks/${1}`);
        } else {
          navigate(`/foodanddrinks/${nextPageToken?.[0]}`);
        }
      } else {
        tempArr.unshift(pointOfInterest?.data?.next_page_token);
        localStorage.setItem("next-page-token", JSON.stringify(tempArr));
        if (tempArr?.length === 0) {
          navigate(`/foodanddrinks/${1}`);
        } else {
          navigate(`/foodanddrinks/${tempArr?.[0]}`);
        }
      }
    }
  };
  const [photoRef, setPhotoRef] = useState("");

  const navigateToShareWith: any = (item: any) => {
    setShareWithModalShow(true);
    setPhotoRef(
      // `https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${item.photos[0]?.photo_reference}&key=${mapKey}`
      item.photos[0]?.imageUrl
    );
    const finalDatas = {
      placeId: item?.place_id,
    };
    dispatch(getImageGoogleData(finalDatas));
  };

  const localStorageClr = () => {
    localStorage.removeItem("next-page-token");
    localStorage.removeItem("main-search-panel-data");
  };

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel id="foodanddrinks">
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <ModalSection>
              <Modal.Body>
                <p>Please Select Location First</p>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(false)}
                >
                  Ok
                </button>
              </Modal.Body>
            </ModalSection>
          </Modal>

          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            setPageNumber={setPageNumber}
            place={place}
            setPlace={setPlace}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn" onClick={localStorageClr}>
                All
              </Link>
              <Link
                to="/concertsresults/1"
                className="btn"
                onClick={localStorageClr}
              >
                Concerts
              </Link>
              <Link
                to="/foodanddrinks/1"
                className="btn select"
                onClick={() => {
                  setInterestPage(false);
                }}
              >
                Food &amp; Drinks
              </Link>
              <Link
                to="/festivalsresults/1"
                className="btn"
                onClick={localStorageClr}
              >
                Festivals
              </Link>
              <Link
                to="/sportsresults/1"
                className="btn"
                onClick={localStorageClr}
              >
                Sports
              </Link>
              <Link
                to="/rentalsresults/1"
                className="btn"
                onClick={localStorageClr}
              >
                Rentals
              </Link>
              <Link
                to="/hotelsresult/1"
                className="btn"
                onClick={localStorageClr}
              >
                Hotels
              </Link>
              <Link
                to="/interestresults/1"
                className="btn"
                onClick={localStorageClr}
              >
                Points of Interest
              </Link>
              <Link to="/hiking/1" className="btn" onClick={localStorageClr}>
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                {pointOfInterest?.data?.results?.length > 0 && (
                  <Dropdown className="sortby" align="start">
                    <Dropdown.Toggle>
                      Sort by
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.656"
                        height="8.596"
                        viewBox="0 0 13.656 8.596"
                      >
                        <path
                          id="Path_133"
                          data-name="Path 133"
                          d="M5.944,11.888,0,5.944,5.944,0"
                          transform="translate(0.884 6.828) rotate(-90)"
                          fill="none"
                          stroke="#3b3b3b"
                          stroke-width="2.5"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as="button">
                        Price : High to Low
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        Price : Low to High
                      </Dropdown.Item>
                      <Dropdown.Item as="button">Guest Rating</Dropdown.Item>
                      <Dropdown.Item as="button">
                        Number of Reviews
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {pointOfInterest?.data?.results?.length > 0 &&
                  pointOfInterest?.isLoading !== true &&
                  localStorage.getItem("location") && (
                    <button
                      type="submit"
                      className="btn"
                      onClick={() => setCreateNewModalShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <g transform="translate(-1016 -337)">
                          <path
                            d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                            transform="translate(1016 337)"
                            fill="#fff"
                          />
                          <path
                            d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                            transform="translate(1020.143 350.845)"
                            fill="#4d27da"
                            stroke="#fff"
                            stroke-width="0.5"
                            className="plusIcon"
                          />
                        </g>
                      </svg>
                      CREATE
                    </button>
                  )}
              </CreateBtnInner>
            </CreateBtn>

            {(pointOfInterest?.data?.results?.length === 0 ||
              !localStorage.getItem("location")) && (
              <RecordNotFound>No data found!</RecordNotFound>
            )}

            {pointOfInterest?.isLoading && (
              <SearchListLoader>
                <img src={PageLoaderIcon} alt="Loading..." />
              </SearchListLoader>
            )}

            <>
              {pointOfInterest?.data?.results?.length > 0 && (
                <RentalsResultsMain>
                  <RentalsListMap>
                    <MapComponent
                      bootstrapURLKeys={{
                        key: mapKey,
                      }}
                      mapDataHiking={pointOfInterest}
                    />
                  </RentalsListMap>
                  <RentalsResultsList>
                    {Data?.map((item: any, index: any) => {
                      console.log(item, "itemememme");
                      return (
                        <RentalsResultsItem>
                          <RentalsListImage>
                            <Carousel controls={false}>
                              <Carousel.Item>
                                {/* <img src={item.icon} alt={item.name} /> */}
                                {item?.photos ? (
                                  <img
                                    src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0]?.photo_reference}&key=${mapKey}`}
                                    alt={item.name}
                                  />
                                ) : (
                                  <img src={TourLosAngeles} alt="no image" />
                                )}
                              </Carousel.Item>
                            </Carousel>
                          </RentalsListImage>
                          <RentalsListInfo>
                            <RentalsTitleWrap>
                              <h3>
                                <Link
                                  to={`/food-and-drink-details/${item?.place_id}`}
                                >
                                  {item?.name}
                                </Link>
                              </h3>

                              <Dropdown>
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="8"
                                      fill="#fff"
                                    ></circle>
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      setOpenChatModel(true);
                                      groupPlanDetails();
                                      shareChatImages(item);
                                      setPlaceIdData(item?.place_id);
                                    }}
                                  >
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      setPlansModal(true);
                                      setRentalItemId(item?.place_id);
                                      setInterestData(item);
                                      setPlaceIdData(item?.place_id);
                                    }}
                                  >
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      const location: any =
                                        item?.plus_code?.compound_code.split(
                                          " "
                                        );
                                      setRentalDetails({
                                        venue_id: item?.place_id,
                                        name: item?.name,
                                        location:
                                          location.length > 0
                                            ? location[1].slice(0, -1)
                                            : "",
                                        province:
                                          location.length > 0
                                            ? location[2].slice(0, -1)
                                            : "",
                                        Image: item?.photos
                                          ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0]?.photo_reference}&key=${mapKey}`
                                          : "",
                                        venue_type: "food and drink",
                                      });
                                      setCollectionModal(true);
                                    }}
                                  >
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      setCreatePlanModalShow(true);
                                      setCreateData(item);
                                      setRentalItemId(item?.place_id);
                                      setInterestData(item);
                                      setPlaceIdData(item?.place_id);
                                      setAllId([]);
                                      setInviteFriend([]);
                                      setInviteFollower([]);
                                      setInviteEmails([]);
                                      setGroupMembers([]);
                                      setPendingEmail([]);
                                      setShowImage("");
                                      setImage("");
                                      setImageValidation("");
                                    }}
                                  >
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => navigateToShareWith(item)}
                                  >
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </RentalsTitleWrap>
                            <RatingReviewWrap>
                              <ListRating>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="Component_44_16"
                                  data-name="Component 44 – 16"
                                  width="22"
                                  height="20.364"
                                  viewBox="0 0 19 17.364"
                                >
                                  <path
                                    id="Polygon_1"
                                    data-name="Polygon 1"
                                    d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                                    fill="#ffc126"
                                  />
                                </svg>
                                {item?.rating ?? "0"}
                              </ListRating>
                              <ListReview>
                                {item?.user_ratings_total ?? "0"} Reviews
                              </ListReview>
                            </RatingReviewWrap>

                            <DescriptionInfo>{item.vicinity}</DescriptionInfo>
                          </RentalsListInfo>
                        </RentalsResultsItem>
                      );
                    })}
                    {pointOfInterest?.data?.results?.length > 0 && (
                      <PaginationWrap>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => {
                              if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1);
                                prevFun();
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7.177"
                              height="12.232"
                              viewBox="0 0 7.177 12.232"
                            >
                              <path
                                d="M1430.642,3924l-5.586,5.586,5.586,5.586"
                                transform="translate(-1423.995 -3923.47)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="1.5"
                              />
                            </svg>
                          </Pagination.Prev>
                          <Pagination.Item active>{pageNumber}</Pagination.Item>

                          <Pagination.Next
                            onClick={() => {
                              if (pageNumber < 3) {
                                setPageNumber(pageNumber + 1);
                                nextFun();
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7.177"
                              height="12.232"
                              viewBox="0 0 7.177 12.232"
                            >
                              <path
                                d="M1425.056,3924l5.586,5.586-5.586,5.586"
                                transform="translate(-1424.526 -3923.47)"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="1.5"
                              />
                            </svg>
                          </Pagination.Next>
                        </Pagination>
                      </PaginationWrap>
                    )}
                  </RentalsResultsList>
                </RentalsResultsMain>
              )}
            </>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        setCreateGroupModalShow={setCreateGroupModalShow}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />

      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={"food and drink"}
        interestData={interestData}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />
      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />
      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
      />

      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
        getAllImages={getAllImages}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        sharewithImage={photoRef}
        link={foodAndDrinksLink}
        name={foodAndDrinkName}
        address={addressInfo}
      />
      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />
    </>
  );
};

export default FoodAndDrinks;
