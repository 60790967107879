import { myPlanAPI } from "../../../services/myPlan";

export enum myPlanType {
  MYPLAN_INFO = "MYPLAN_INFO",
  MYPLAN_INFO_SUCCESS = "MYPLAN_INFO_SUCCESS",
  MYPLAN_INFO_FALIURE = "MYPLAN_INFO_FALIURE",
}

export const getPlanDetails = () => (dispatch: any) => {
  dispatch({
    type: myPlanType.MYPLAN_INFO,
  });
};

export const getPlanDetailsSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: myPlanType.MYPLAN_INFO_SUCCESS,
    data,
  });
};

export const getPlanDetailsFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: myPlanType.MYPLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const getPlanDetailsHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getPlanDetails());
    const result: any = await myPlanAPI(data);
    dispatch(getPlanDetailsSuccess(result));
  } catch (e: any) {
    dispatch(getPlanDetailsFailure(e?.response?.data?.message));
  }
};
