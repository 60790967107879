import { AddFriendNotificationType } from "../../action/add-friend-notification/addFriendNotification";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case AddFriendNotificationType.ADD_FRIEND_NOTIFICATION_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
      };
    }
    default:
      return state;
  }
};
