export const initState = {
  setGuestRatingValue: 0,
  setRangeValue: [0, 1000],
  setFacility: [],
  setRoomTypes: [],
  setRoomNo: [],
  setPetRules: false,
  setFilterPagination1: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_FILTER_DATA": {
      return {
        ...state,
        setFilterPagination1: action.payload,
      };
    }
    case "PET_RULES": {
      return {
        ...state,
        setPetRules: action.payload,
      };
    }
    case "GUEST_RATING_VALUE": {
      return {
        ...state,
        setGuestRatingValue: action.payload,
      };
    }
    case "SET_FACILITY": {
      return {
        ...state,
        setFacility: [...state.setFacility, action.payload],
      };
    }
    case "REMOVE_FACILITY": {
      return {
        ...state,
        setFacility: state.setFacility.filter(
          (item) => item !== action.payload
        ),
      };
    }
    case "SET_ROOM_TYPE": {
      return {
        ...state,
        setRoomTypes: [...state.setRoomTypes, action.payload],
      };
    }
    case "SET_ROOM_NO": {
      return {
        ...state,
        setRoomNo: [...state.setRoomNo, action.payload],
      };
    }

    case "REMOVE_ROOM_NUMBER": {
      return {
        ...state,
        setRoomNo: state.setRoomNo.filter((item) => item !== action.payload),
      };
    }

    case "REMOVE_ROOM_TYPE": {
      return {
        ...state,
        setRoomTypes: state.setRoomTypes.filter(
          (item) => item !== action.payload
        ),
      };
    }
    case "SET_RANGE_VALUE": {
      return {
        ...state,
        setRangeValue: action.payload,
      };
    }
    case "RESET_FILTER_DATA": {
      return {
        ...initState,
      };
    }

    default:
      return state;
  }
};
