import { unFollowCollectionAPI } from "../../../services/unFollowingCollection";

export enum UnFollowCollectionType {
    UNFOLLOW_COLLECTION = "UNFOLLOW_COLLECTION",
    UNFOLLOW_COLLECTION_SUCCESS = "UNFOLLOW_COLLECTION_SUCCESS",
    UNFOLLOW_COLLECTION_FALIURE = "UNFOLLOW_COLLECTION_FALIURE",
}

export const unFollowCollection = () => (dispatch: any) => {
    dispatch({
        type: UnFollowCollectionType.UNFOLLOW_COLLECTION,
    });
};

export const unFollowCollectionSuccess = (data: any) => (dispatch: any) => {
    dispatch({
        type: UnFollowCollectionType.UNFOLLOW_COLLECTION_SUCCESS,
        data,
    });
};

export const unFollowCollectionFailed = (msg: string) => (dispatch: any) => {
    dispatch({
        type: UnFollowCollectionType.UNFOLLOW_COLLECTION_FALIURE,
        data: { msg },
    });
};

export const unFollowCollectionHandler = (finalData: any) => async (dispatch: any) => {
    try {
        dispatch(unFollowCollection());
        const result: any = await unFollowCollectionAPI(finalData);
        dispatch(unFollowCollectionSuccess(result));
    } catch (e: any) {
        dispatch(unFollowCollectionFailed(e?.response?.data?.message));
    }
};
