import { getEventConcertAPI } from "../../../services/getEventConcert";

export enum getEventConcertType {
  GET_EVENT_CONCERT_INFO = "GET_EVENT_CONCERT_INFO",
  GET_EVENT_CONCERT_INFO_SUCCESS = "GET_EVENT_CONCERT_INFO_SUCCESS",
  GET_EVENT_CONCERT_INFO_FALIURE = "GET_EVENT_CONCERT_INFO_FALIURE",
}

export const getEventConcertInfo = () => (dispatch: any) => {
  dispatch({
    type: getEventConcertType.GET_EVENT_CONCERT_INFO,
  });
};

export const getEventConcertInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getEventConcertType.GET_EVENT_CONCERT_INFO_SUCCESS,
    data,
  });
};

export const getEventConcertInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getEventConcertType.GET_EVENT_CONCERT_INFO_FALIURE,
    error: msg,
  });
};

export const getEventConcertData = (eventId: any) => async (dispatch: any) => {
  try {
    dispatch(getEventConcertInfo());
    const result: any = await getEventConcertAPI(eventId);
    dispatch(getEventConcertInfoSuccess(result));
  } catch (e: any) {
    dispatch(getEventConcertInfoFailed(e?.response?.data));
  }
};
