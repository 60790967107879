import { getConversationAPI } from "../../../services/getConversation";

export enum getConversationType {
  GET_COVERSATION_INFO = "GET_COVERSATION_INFO",
  GET_COVERSATION_INFO_SUCCESS = "GET_COVERSATION_INFO_SUCCESS",
  GET_COVERSATION_INFO_FALIURE = "GET_COVERSATION_INFO_FALIURE",
}

export const getConversationInfo = () => (dispatch: any) => {
  dispatch({
    type: getConversationType.GET_COVERSATION_INFO,
  });
};

export const getConversationInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getConversationType.GET_COVERSATION_INFO_SUCCESS,
    data,
  });
};

export const getConversationInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getConversationType.GET_COVERSATION_INFO_FALIURE,
    error: msg,
  });
};

export const getConversationData =
  (finaldata: any) => async (dispatch: any) => {
    try {
      dispatch(getConversationInfo());
      const result: any = await getConversationAPI(finaldata);
      dispatch(getConversationInfoSuccess(result));
    } catch (e: any) {
      dispatch(getConversationInfoFailed(e?.response?.data));
    }
  };
