import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/onboarding/auth/Login";
import Signup from "../pages/onboarding/auth/Signup";
import SignupInvite from "../pages/onboarding/auth/SignupInvite";
import ForgotPassword from "../pages/onboarding/auth/ForgotPassword";

function PublicRoute(props: any) {
  let { path, data, component: Component } = props;
  return (
    <Routes>
      <Route path={path} element={<Component />}></Route>
    </Routes>
  );
}

export default PublicRoute;
