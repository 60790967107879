import { getRequestAPI } from "../../../services/getAllrequest";

export enum getRequestType {
  GET_REQUEST = "GET_REQUEST_INFO",
  GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS",
  GET_REQUEST_FALIURE = "GET_REQUEST_FALIURE",
  GET_REQUEST_RESET = "RESET_REQUEST",
}

export const getRequestInfo = () => (dispatch: any) => {
  dispatch({
    type: getRequestType.GET_REQUEST,
  });
};

export const getRequestInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getRequestType.GET_REQUEST_SUCCESS,
    data,
  });
};

export const getRequestInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getRequestType.GET_REQUEST_FALIURE,
    error: msg,
  });
};
export const getRequestData = () => async (dispatch: any) => {
  try {
    dispatch(getRequestInfo());
    const result: any = await getRequestAPI();
    dispatch(getRequestInfoSuccess(result));
  } catch (e: any) {
    dispatch(getRequestInfoFailed(e?.response?.data));
  }
};
