import { unfollowAPI } from "../../../services/unFollow";

export enum UnFollowType {
  UNFOLLOW_INFO = "UNFOLLOW_INFO",
  UNFOLLOW_INFO_SUCCESS = "UNFOLLOW_INFO_SUCCESS",
  UNFOLLOW_INFO_FALIURE = "UNFOLLOW_INFO_FALIURE",
}

export const unfollowInfo = () => (dispatch: any) => {
  dispatch({
    type: UnFollowType.UNFOLLOW_INFO,
  });
};

export const unfollowInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: UnFollowType.UNFOLLOW_INFO_SUCCESS,
    data,
  });
};

export const unfollowInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: UnFollowType.UNFOLLOW_INFO_FALIURE,
    data: { msg },
  });
};

export const unfollowData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(unfollowInfo());
    const result: any = await unfollowAPI(data);
    dispatch(unfollowInfoSuccess(result));
  } catch (e: any) {
    dispatch(unfollowInfoFailed(e.message));
  }
};
