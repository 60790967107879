import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import UserImage from "../../../assets/images/user-image.png";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import LosAngeles from "../../../assets/images/los-angeles.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import CandyBrian from "../../../assets/images/candy-brian.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import CollectionsImage1 from "../../../assets/images/collections-image-1.png";
import CollectionsImage2 from "../../../assets/images/collections-image-2.png";
import CollectionsImage3 from "../../../assets/images/collections-image-3.png";
import CollectionsImage4 from "../../../assets/images/collections-image-4.png";
import LasVegas from "../../../assets/images/las-vegas.png";
import NapaValley from "../../../assets/images/napa-valley.png";
import InvitedPlans1 from "../../../assets/images/invited-plans-1.png";
import InvitedPlans2 from "../../../assets/images/invited-plans-2.png";
import UserProfile from "../../../assets/images/michelle-smith.png";
import Instagram from "../../../assets/images/instagram.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Pinterest from "../../../assets/images/pinterest.svg";
import Nevada from "../../../assets/images/nevada.png";
import Winetasting from "../../../assets/images/winetasting.png";
import Camping from "../../../assets/images/camping.png";
import HotelFufu from "../../../assets/images/hotel-fufu.png";
import TacoRestaurant from "../../../assets/images/taco-restaurant.png";
import InterestName from "../../../assets/images/interest-name.png";
import SuperTaco from "../../../assets/images/super-taco.png";
import GreatCabin from "../../../assets/images/great-cabin.png";
import Caba from "../../../assets/images/caba.png";
import La from "../../../assets/images/la.png";
import FileIcon from "../../../assets/images/file-icon.svg";

import AddCollectionModal from "../../../components/addCollectionModal/index";
import DeleteCollectionModal from "../../../components/deleteCollectionModal";
import FiltersModal from "../../../components/filtersModal/index";
import RemoveFriendModal from "../../../components/removeFriendModal/index";
import RemoveFollowigModal from "../../../components/removeFollowingModal/index";
import DeleteCollectionItemModal from "../../../components/deleteCollectionItem";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsWrap,
  TabsContent,
  MainContentPanelWrap,
  ProfileWrap,
  ProfileLeft,
  ProfileImage,
  ProfileName,
  ProfileUserName,
  ProfileRight,
  FormGroupWrap,
  MyPlansWrap,
  MyPlansTop,
  MyPlansTitle,
  BackToList,
  CreateBtn,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansDate,
  MyPlansLock,
  MyPlansAction,
  MyPlansSubTitle,
  MyPlansActionWrap,
  MyPlansDropdown,
  CityTitle,
  BtnWrap,
  MyPlansMore,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
  PageLoader,
  TabDataNotFound,
  SearchListLoader,
  SearchBoxNote,
  RecordNotFound,
  Collectionbtn,
} from "./collectionStyle";
import { getCollectionHandler } from "../../../redux/action/getCollection/getCollection";
import { getFollowingInfoData } from "../../../redux/action/getfollowing/getFollowing";
import { updateCollectionHandler } from "../../../redux/action/updateCollection/updateCollection";
import { deleteCollectionItemHandler } from "../../../redux/action/deleteCollectionItem/deleteCollectionItem";
import { getCollectionFollowingHandler } from "../../../redux/action/getFollowingCollection/getFollowingCollection";
import { ToastContainer, toast, collapseToast } from "react-toastify";

const Collection = (props: any) => {
  const dispatch: any = useDispatch();

  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getDeleteCollectionModal, setDeleteCollectionModal] = useState({
    show: false,
    collection_id: "",
  });

  const [getDeleteCollectionItemModal, setDeleteCollectionItemModal] = useState(
    {
      show: false,
      collection_id: "",
      item_id: "",
    }
  );

  const [AddCollectionModalShow, setAddCollectionModalShow] = useState({
    show: false,
    collection_id: "",
  });

  const [getAlCollection, setAllCollection] = useState({
    wantToDoCollection: false,
    collectionCollection: false,
    FollowingCollectionCollection: false,
  });

  const [getUploadBox, setUploadBox] = useState(false);

  const getCollection = useSelector((state: any) => state.getCollection);
  const addItemToCollection = useSelector(
    (state: any) => state.addItemToCollection
  );

  const addCollection = useSelector((state: any) => state.addCollection);
  const deleteCollection = useSelector((state: any) => state.deleteCollection);
  const getFollowing = useSelector((state: any) => state?.getFollowing);
  const updateCollection = useSelector((state: any) => state?.updateCollection);
  const deleteCollectionItem = useSelector(
    (state: any) => state?.deleteCollectionItem
  );
  const navigate: any = useNavigate();

  const navigationLogic = (type: any, id: any) => {
    switch (type) {
      case "concerts":
        navigate(`/concert-result-details/${id}`);
        break;
      case "sports":
        navigate(`/sports-results-details/${id}`);
        break;
      case "festival":
        navigate(`/festival-result-details/${id}`);
        break;
      case "rental":
        navigate(`/rental-result-details/${id}`);
        break;
      case "food and drink":
        navigate(`/food-and-drink-details/${id}`);
        break;
      case "point of interest":
        navigate(`/interest-details-results/${id}`);
        break;
      case "hiking":
        navigate(`/hiking-result-details/${id}`);
        break;
      case "hotel":
        navigate(`/hotel-result-details/${id}`);
        break;

      default:
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = {
      user_id: localStorage.getItem("id"),
    };
    dispatch(getCollectionHandler(data));
  }, [
    addCollection,
    deleteCollection,
    updateCollection,
    deleteCollectionItem,
    addItemToCollection,
  ]);

  useEffect(() => {
    dispatch(getFollowingInfoData(localStorage.getItem("id")));
    dispatch(getCollectionFollowingHandler());
  }, []);

  return (
    <>
      <Header logout={props.logout} />

      {getCollection?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection" className="active">
                COLLECTIONS
              </Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>
            <>
              <TabsContent>
                <MyPlansWrap>
                  {
                    <>
                      <MyPlansTop>
                        <MyPlansTitle>Wants to do</MyPlansTitle>
                      </MyPlansTop>

                      <MyPlansList>
                        {!getAlCollection?.wantToDoCollection &&
                          getCollection?.data?.data?.map((ele: any) => {
                            return (
                              ele?.name === "WANTS TO DO" &&
                              ele?.items
                                ?.slice(0, 5)
                                ?.reverse()
                                .map((item: any) => {
                                  return (
                                    <MyPlansBox>
                                      <MyPlansImage>
                                        <img
                                          src={
                                            item?.Image !== null &&
                                            item?.Image !== ""
                                              ? item?.Image
                                              : LasVegas
                                          }
                                          alt={item?.name}
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                        />
                                      </MyPlansImage>
                                      <button
                                        className="deletebtn"
                                        onClick={() => {
                                          setDeleteCollectionItemModal({
                                            show: true,
                                            collection_id: ele?._id,
                                            item_id: item?._id,
                                          });
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3b3b3b"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                      </button>
                                      <MyPlansBoxTitle className="collection-lg-title">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigationLogic(
                                              item?.venue_type,
                                              item?.venue_id
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </span>
                                      </MyPlansBoxTitle>
                                    </MyPlansBox>
                                  );
                                })
                            );
                          })}

                        {getAlCollection?.wantToDoCollection &&
                          getCollection?.data?.data?.map((ele: any) => {
                            return (
                              ele?.name === "WANTS TO DO" &&
                              ele?.items?.reverse()?.map((item: any) => {
                                return (
                                  <MyPlansBox>
                                    <MyPlansImage>
                                      <img
                                        src={
                                          item?.Image !== null &&
                                          item?.Image !== ""
                                            ? item?.Image
                                            : LasVegas
                                        }
                                        onClick={() =>
                                          navigationLogic(
                                            item?.venue_type,
                                            item?.venue_id
                                          )
                                        }
                                        alt={item?.name}
                                      />
                                    </MyPlansImage>
                                    <button
                                      className="deletebtn"
                                      onClick={() => {
                                        setDeleteCollectionItemModal({
                                          show: true,
                                          collection_id: ele?._id,
                                          item_id: item?._id,
                                        });
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#3b3b3b"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line
                                          x1="10"
                                          y1="11"
                                          x2="10"
                                          y2="17"
                                        ></line>
                                        <line
                                          x1="14"
                                          y1="11"
                                          x2="14"
                                          y2="17"
                                        ></line>
                                      </svg>
                                    </button>
                                    <MyPlansBoxTitle className="collection-lg-title">
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigationLogic(
                                            item?.venue_type,
                                            item?.venue_id
                                          )
                                        }
                                      >
                                        {item?.name}
                                      </span>
                                    </MyPlansBoxTitle>
                                  </MyPlansBox>
                                );
                              })
                            );
                          })}
                      </MyPlansList>

                      {getCollection?.data?.data?.map(
                        (ele: any) =>
                          ele?.name === "WANTS TO DO" &&
                          (ele?.items?.length === 0 ? (
                            <>
                              <RecordNotFound>No Items found!</RecordNotFound>
                            </>
                          ) : (
                            !getAlCollection?.wantToDoCollection &&
                            getCollection?.data?.data?.map(
                              (ele: any) =>
                                ele?.name === "WANTS TO DO" &&
                                ele?.items?.length > 5 && (
                                  <>
                                    <Collectionbtn>
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          setAllCollection({
                                            ...getAlCollection,
                                            wantToDoCollection: true,
                                          });
                                        }}
                                        className="collection-btn"
                                      >
                                        More Items...
                                      </Button>
                                    </Collectionbtn>
                                  </>
                                )
                            )
                          ))
                      )}
                    </>
                  }

                  {
                    <>
                      <MyPlansTop>
                        <MyPlansTitle>Collections</MyPlansTitle>
                        <button
                          type="submit"
                          className="btn modal-btn"
                          onClick={() => {
                            setImageValidation("");
                            setImage("");
                            setShowImage("");
                            setAddCollectionModalShow({
                              show: true,
                              collection_id: "",
                            });
                            dispatch({ type: "RESET_ONE_COLLECTION" });
                            setUploadBox(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                          >
                            <circle cx="15" cy="15" r="15" fill="#f0f0f0" />
                            <path
                              d="M5.749-8.554v4.722h-4.6v2.97h4.6V3.861H8.962V-.862h4.6v-2.97h-4.6V-8.554Z"
                              transform="translate(7.645 17.347)"
                              fill="#3b3b3b"
                              stroke="#f0f0f0"
                              stroke-width="0.5"
                            />
                          </svg>
                        </button>
                      </MyPlansTop>

                      <MyPlansList>
                        {!getAlCollection?.collectionCollection &&
                          getCollection?.data?.data
                            ?.slice(1, 6)
                            ?.map((ele: any) => {
                              return (
                                ele?.name !== "WANTS TO DO" &&
                                ele?.user_id === localStorage.getItem("id") && (
                                  <MyPlansBox>
                                    <MyPlansImage>
                                      <img
                                        onClick={() => {
                                          navigate(
                                            `/collection-items/${ele?._id}`
                                          );
                                        }}
                                        src={ele?.cover_photo}
                                        alt="Wonderful Great Cabin"
                                      />
                                      {ele?.visibility === "Private" && (
                                        <MyPlansLock>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12.38"
                                            height="16.249"
                                            viewBox="0 0 12.38 16.249"
                                          >
                                            <path
                                              d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                              transform="translate(-6 -1.5)"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </MyPlansLock>
                                      )}
                                      <MyPlansActionWrap className="collection-action">
                                        <MyPlansAction>
                                          <Dropdown className="action multiple-action">
                                            <Dropdown.Toggle>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                              >
                                                <circle
                                                  cx="11"
                                                  cy="11"
                                                  r="11"
                                                  fill="#fff"
                                                />
                                                <g transform="translate(-462.297 -416.069)">
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(467.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(472.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(477.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                </g>
                                              </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <CityTitle>{ele?.name}</CityTitle>
                                              <Form>
                                                {(ele?.visibility ===
                                                  "Private" ||
                                                  ele?.visibility ===
                                                    "Public") && (
                                                  <FormGroup className="custom-switch-wrap">
                                                    <Form.Check
                                                      type="switch"
                                                      label="switch"
                                                      checked={
                                                        ele?.visibility ===
                                                        "Private"
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        const data = {
                                                          collection_id:
                                                            ele?._id,
                                                          visibility: e.target
                                                            .checked
                                                            ? "Private"
                                                            : "Public",
                                                        };

                                                        dispatch(
                                                          updateCollectionHandler(
                                                            data
                                                          )
                                                        );
                                                      }}
                                                    />
                                                    <h4>
                                                      This Collection is Private
                                                    </h4>
                                                    <p>
                                                      Private Collections only
                                                      viewable by your Followers
                                                    </p>
                                                  </FormGroup>
                                                )}

                                                <BtnWrap>
                                                  <Link
                                                    to="#"
                                                    className="delete-btn"
                                                    onClick={() =>
                                                      setDeleteCollectionModal({
                                                        show: true,
                                                        collection_id: ele?._id,
                                                      })
                                                    }
                                                  >
                                                    Delete Collection
                                                  </Link>
                                                  <Button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                      setAddCollectionModalShow(
                                                        {
                                                          show: true,
                                                          collection_id:
                                                            ele?._id,
                                                        }
                                                      );
                                                      setUploadBox(true);
                                                    }}
                                                  >
                                                    Update
                                                  </Button>
                                                </BtnWrap>
                                              </Form>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </MyPlansAction>
                                      </MyPlansActionWrap>
                                    </MyPlansImage>

                                    <MyPlansBoxTitle className="collection-lg-title">
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          navigate(
                                            `/collection-items/${ele?._id}`
                                          );
                                        }}
                                      >
                                        {ele?.name}
                                      </span>
                                    </MyPlansBoxTitle>
                                  </MyPlansBox>
                                )
                              );
                            })}

                        {getAlCollection?.collectionCollection &&
                          getCollection?.data?.data?.map((ele: any) => {
                            return (
                              ele?.name !== "WANTS TO DO" &&
                              ele?.user_id === localStorage.getItem("id") && (
                                <MyPlansBox>
                                  <MyPlansImage>
                                    <img
                                      onClick={() => {
                                        navigate(
                                          `/collection-items/${ele?._id}`
                                        );
                                      }}
                                      src={ele?.cover_photo}
                                      alt="Wonderful Great Cabin"
                                    />
                                    {ele?.visibility === "Private" && (
                                      <MyPlansLock>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12.38"
                                          height="16.249"
                                          viewBox="0 0 12.38 16.249"
                                        >
                                          <path
                                            d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                            transform="translate(-6 -1.5)"
                                            fill="#fff"
                                          />
                                        </svg>
                                      </MyPlansLock>
                                    )}
                                    <MyPlansActionWrap className="collection-action">
                                      <MyPlansAction>
                                        <Dropdown className="action multiple-action">
                                          <Dropdown.Toggle>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                            >
                                              <circle
                                                cx="11"
                                                cy="11"
                                                r="11"
                                                fill="#fff"
                                              />
                                              <g transform="translate(-462.297 -416.069)">
                                                <circle
                                                  cx="1.5"
                                                  cy="1.5"
                                                  r="1.5"
                                                  transform="translate(467.297 426.069)"
                                                  fill="#3b3b3b"
                                                />
                                                <circle
                                                  cx="1.5"
                                                  cy="1.5"
                                                  r="1.5"
                                                  transform="translate(472.297 426.069)"
                                                  fill="#3b3b3b"
                                                />
                                                <circle
                                                  cx="1.5"
                                                  cy="1.5"
                                                  r="1.5"
                                                  transform="translate(477.297 426.069)"
                                                  fill="#3b3b3b"
                                                />
                                              </g>
                                            </svg>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <CityTitle>{ele?.name}</CityTitle>
                                            <Form>
                                              {(ele?.visibility === "Private" ||
                                                ele?.visibility ===
                                                  "Public") && (
                                                <FormGroup className="custom-switch-wrap">
                                                  <Form.Check
                                                    type="switch"
                                                    label="switch"
                                                    checked={
                                                      ele?.visibility ===
                                                      "Private"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      const data = {
                                                        collection_id: ele?._id,
                                                        visibility: e.target
                                                          .checked
                                                          ? "Private"
                                                          : "Public",
                                                      };

                                                      dispatch(
                                                        updateCollectionHandler(
                                                          data
                                                        )
                                                      );
                                                    }}
                                                  />
                                                  <h4>
                                                    This Collection is Private
                                                  </h4>
                                                  <p>
                                                    Private Collections only
                                                    viewable by your Followers
                                                  </p>
                                                </FormGroup>
                                              )}

                                              <BtnWrap>
                                                <Link
                                                  to="#"
                                                  className="delete-btn"
                                                  onClick={() =>
                                                    setDeleteCollectionModal({
                                                      show: true,
                                                      collection_id: ele?._id,
                                                    })
                                                  }
                                                >
                                                  Delete Collection
                                                </Link>
                                                <Button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={(e) => {
                                                    setAddCollectionModalShow({
                                                      show: true,
                                                      collection_id: ele?._id,
                                                    });
                                                    setUploadBox(true);
                                                  }}
                                                >
                                                  Update
                                                </Button>
                                              </BtnWrap>
                                            </Form>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </MyPlansAction>
                                    </MyPlansActionWrap>
                                  </MyPlansImage>

                                  <MyPlansBoxTitle className="collection-lg-title">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(
                                          `/collection-items/${ele?._id}`
                                        );
                                      }}
                                    >
                                      {ele?.name}
                                    </span>
                                  </MyPlansBoxTitle>
                                </MyPlansBox>
                              )
                            );
                          })}
                      </MyPlansList>

                      {getCollection?.data?.data?.filter(
                        (ele: any) =>
                          ele?.name !== "WANTS TO DO" &&
                          ele?.user_id === localStorage.getItem("id")
                      )?.length === 0 ? (
                        <RecordNotFound>
                          No Private,Public Friend and Following Collection
                          found!
                        </RecordNotFound>
                      ) : (
                        !getAlCollection?.collectionCollection &&
                        getCollection?.data?.data?.filter(
                          (ele: any) => ele?.name !== "WANTS TO DO"
                        ).length > 5 && (
                          <Collectionbtn>
                            <Button
                              type="button"
                              onClick={() => {
                                setAllCollection({
                                  ...getAlCollection,
                                  collectionCollection: true,
                                });
                              }}
                              className="collection-btn"
                            >
                              More Collections...
                            </Button>
                          </Collectionbtn>
                        )
                      )}
                    </>
                  }

                  {
                    <>
                      <>
                        <MyPlansTop>
                          <MyPlansTitle>Collections Followed</MyPlansTitle>
                        </MyPlansTop>
                        <MyPlansList>
                          {!getAlCollection?.FollowingCollectionCollection &&
                            getCollection?.data?.data
                              ?.slice(0, 5)
                              .map((ele: any) => {
                                return (
                                  ele?.user_id !==
                                    localStorage.getItem("id") && (
                                    <MyPlansBox>
                                      <MyPlansImage>
                                        <img
                                          onClick={() => {
                                            navigate(
                                              `/collection-items/${ele?._id}`
                                            );
                                          }}
                                          src={ele?.cover_photo}
                                          alt="Wonderful Great Cabin"
                                        />
                                        {ele?.visibility === "Private" && (
                                          <MyPlansLock>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="12.38"
                                              height="16.249"
                                              viewBox="0 0 12.38 16.249"
                                            >
                                              <path
                                                d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                                transform="translate(-6 -1.5)"
                                                fill="#fff"
                                              />
                                            </svg>
                                          </MyPlansLock>
                                        )}
                                      </MyPlansImage>

                                      <MyPlansBoxTitle className="collection-lg-title">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            navigate(
                                              `/collection-items/${ele?._id}`
                                            );
                                          }}
                                        >
                                          {ele?.name}
                                        </span>
                                      </MyPlansBoxTitle>
                                    </MyPlansBox>
                                  )
                                );
                              })}

                          {getAlCollection?.FollowingCollectionCollection &&
                            getCollection?.data?.data?.map((ele: any) => {
                              return (
                                ele?.user_id !== localStorage.getItem("id") && (
                                  <MyPlansBox>
                                    <MyPlansImage>
                                      <img
                                        onClick={() => {
                                          navigate(
                                            `/collection-items/${ele?._id}`
                                          );
                                        }}
                                        src={ele?.cover_photo}
                                        alt="Wonderful Great Cabin"
                                      />
                                      {ele?.visibility === "Private" && (
                                        <MyPlansLock>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12.38"
                                            height="16.249"
                                            viewBox="0 0 12.38 16.249"
                                          >
                                            <path
                                              d="M16.833,6.916h-.774V5.369a3.869,3.869,0,1,0-7.738,0V6.916H7.548A1.552,1.552,0,0,0,6,8.464V16.2a1.552,1.552,0,0,0,1.548,1.548h9.285A1.552,1.552,0,0,0,18.38,16.2V8.464A1.552,1.552,0,0,0,16.833,6.916ZM12.19,13.88a1.548,1.548,0,1,1,1.548-1.548A1.552,1.552,0,0,1,12.19,13.88Zm2.4-6.964h-4.8V5.369a2.4,2.4,0,1,1,4.8,0Z"
                                              transform="translate(-6 -1.5)"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </MyPlansLock>
                                      )}
                                    </MyPlansImage>

                                    <MyPlansBoxTitle className="collection-lg-title">
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          navigate(
                                            `/collection-items/${ele?._id}`
                                          );
                                        }}
                                      >
                                        {ele?.name}
                                      </span>
                                    </MyPlansBoxTitle>
                                  </MyPlansBox>
                                )
                              );
                            })}

                          {getCollection?.data?.data?.filter(
                            (ele: any) =>
                              ele?.name !== "WANTS TO DO" &&
                              ele?.user_id !== localStorage.getItem("id")
                          )?.length === 0 ? (
                            <RecordNotFound>
                              No Collection found !
                            </RecordNotFound>
                          ) : (
                            !getAlCollection?.FollowingCollectionCollection &&
                            getCollection?.data?.data?.length === 0 && (
                              <Collectionbtn>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    setAllCollection({
                                      ...getAlCollection,
                                      FollowingCollectionCollection: true,
                                    });
                                  }}
                                  className="collection-btn"
                                >
                                  More Collections...
                                </Button>
                              </Collectionbtn>
                            )
                          )}
                        </MyPlansList>
                      </>
                    </>
                  }
                </MyPlansWrap>
              </TabsContent>
            </>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />

        <ToastContainer autoClose={4000} />
      </MyPageWrap>

      <AddCollectionModal
        show={AddCollectionModalShow.show}
        onHide={() => {
          setAddCollectionModalShow({ show: false, collection_id: "" });
          setImageValidation("");
          setImage("");
          setShowImage("");
        }}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
        AddCollectionModalShow={AddCollectionModalShow}
        setUploadBox={setUploadBox}
        getUploadBox={getUploadBox}
      />

      <DeleteCollectionModal
        show={getDeleteCollectionModal.show}
        onHide={() =>
          setDeleteCollectionModal({ show: false, collection_id: "" })
        }
        getDeleteCollectionModal={getDeleteCollectionModal}
      />

      <DeleteCollectionItemModal
        show={getDeleteCollectionItemModal.show}
        onHide={() =>
          setDeleteCollectionItemModal({
            show: false,
            collection_id: "",
            item_id: "",
          })
        }
        getDeleteCollectionItemModal={getDeleteCollectionItemModal}
      />
    </>
  );
};

export default Collection;
