import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import UserImage from "../../../assets/images/user-image.png";
import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import LosAngeles from "../../../assets/images/los-angeles.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import CandyBrian from "../../../assets/images/candy-brian.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import CollectionsImage1 from "../../../assets/images/collections-image-1.png";
import CollectionsImage2 from "../../../assets/images/collections-image-2.png";
import CollectionsImage3 from "../../../assets/images/collections-image-3.png";
import CollectionsImage4 from "../../../assets/images/collections-image-4.png";
import LasVegas from "../../../assets/images/las-vegas.png";
import NapaValley from "../../../assets/images/napa-valley.png";
import InvitedPlans1 from "../../../assets/images/invited-plans-1.png";
import InvitedPlans2 from "../../../assets/images/invited-plans-2.png";
import UserProfile from "../../../assets/images/michelle-smith.png";
import Instagram from "../../../assets/images/instagram.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Pinterest from "../../../assets/images/pinterest.svg";
import Nevada from "../../../assets/images/nevada.png";
import Winetasting from "../../../assets/images/winetasting.png";
import Camping from "../../../assets/images/camping.png";
import HotelFufu from "../../../assets/images/hotel-fufu.png";
import TacoRestaurant from "../../../assets/images/taco-restaurant.png";
import InterestName from "../../../assets/images/interest-name.png";
import SuperTaco from "../../../assets/images/super-taco.png";
import GreatCabin from "../../../assets/images/great-cabin.png";
import Caba from "../../../assets/images/caba.png";
import La from "../../../assets/images/la.png";
import EyeOpen from "../../../assets/images/eye-open-gray.svg";
import EyeClose from "../../../assets/images/eye-close.svg";
import FileIcon from "../../../assets/images/file-icon.svg";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsWrap,
  TabsContent,
  ModalBody,
  ModalBodyBtn,
  UserListImage,
  UserTime,
  FriendItem,
  CreatePlanDate,
  ButtonTabs,
  TabButton,
  InviteOtherSearch,
  MyGroupTabContent,
  MyGroupTabContentInner,
  InviteMyGroups,
  InviteGroupMembers,
  InviteGroupRadioBox,
  InviteGroupItem,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  MyFollowersLeft,
  MyFollowersRight,
  MyFollowersItemWrap,
  MyFollowersItem,
  CollectionsItem,
  CollectionsItemImage,
  ModalAddBtn,
  PlansTabsWrap,
  PlansItem,
  PlansItemImage,
  PlansItemInfo,
  PlansInfoDate,
  PlansItemImageWrap,
  MainContentPanelWrap,
  ProfileWrap,
  ProfileLeft,
  ProfileImage,
  ProfileName,
  ProfileUserName,
  ProfileRight,
  FormGroupWrap,
  UserSocialMedia,
  ProfileSubTitle,
  MyPlansWrap,
  MyPlansTop,
  MyPlansTitle,
  CreateBtn,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansDate,
  MyPlansLock,
  MyPlansAction,
  MyPlansSubTitle,
  MyPlansActionWrap,
  MyPlansDropdown,
  CityTitle,
  BtnWrap,
  MyPlansMore,
  AddCollectionWrap,
  UploadImageBox,
  NotificationWrap,
  NotificationList,
  NotificationListInner,
  NotificationListInfo,
  NotificationListImage,
  SettingsWrap,
  ImageUploader,
  SocialLinkList,
  SocialLinkLabel,
  ResetPasswordGroup,
  InvitesWrap,
  FeedbackWrap,
  FeedbackUserId,
  DropZone,
  ImageEditImage,
  PageLoader,
  ImageValidation,
} from "./settingsStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import Feedback from "react-bootstrap/esm/Feedback";
import {
  changePasswordData,
  changePasswordInfoFailed,
  changePasswordInfoSuccess,
} from "../../../redux/action/changepassword/changePassword";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import {
  updateProfileData,
  updateProfileInfoFailed,
  updateProfileInfoSuccess,
} from "../../../redux/action/update-profile/updateProfile";
import { getAllUserAPIData } from "../../../redux/action/getallusers/getAllUsers";
import { getProfileData } from "./../../../redux/action/get-profile/getProfile";

const Settings = (props: any) => {
  const [getUpdateToster, setUpdateToster] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [passwordEyeShow, setPasswordEyeShow] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getResetToster, setResetToster] = useState(false);
  const [isToasting, setIsToasting] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const changePasswordState = useSelector(
    (state: any) => state?.changePassword
  );

  const updateProfileHandler = useSelector(
    (state: any) => state?.updateProfile
  );

  const getAllUsers = useSelector((state: any) => state?.getAllUser);

  useEffect(() => {
    setShowImage("");
    dispatch(getProfileData());
    setUpdateToster(false);
    dispatch(getAllUserAPIData());
  }, []);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        case "success":
          setIsToasting(true);
          toast.success("Profile updated successfully");
          closeToast();
          break;
        case "error":
          setIsToasting(true);
          toast.error("User name already registered with other user");
          closeToast();
          break;
        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!updateProfileHandler?.isLoading) {
      if (updateProfileHandler?.data?.data) {
        getToast("success");
        dispatch({ type: "RESET_UPDATE_PROFILE" });
        let local: any = JSON.parse(localStorage.getItem("user") || "{}");
        local["photo"] = updateProfileHandler?.data?.data?.photo;
        local["first_name"] = updateProfileHandler?.data?.data?.firstName;
        local["last_name"] = updateProfileHandler?.data?.data?.lastName;
        local["user_name"] = updateProfileHandler?.data?.data?.userName;
        local["email"] = updateProfileHandler?.data?.data?.email;
        local["birthdate"] = updateProfileHandler?.data?.data?.dob;
        localStorage.setItem("user", JSON.stringify(local));
        setTimeout(() => {
          navigate("/myProfile");
        }, 5000);
      } else if (updateProfileHandler?.error) {
        getToast("error");
        dispatch(updateProfileInfoSuccess(""));
      }
    }
  }, [updateProfileHandler]);

  useEffect(() => {
    if (getResetToster) {
      if (changePasswordState?.data?.message) {
        toast.success("Password reset successfully!");
        setTimeout(() => {
          navigate("/myProfile");
        }, 4000);
      } else if (changePasswordState?.msg) {
        toast.error(
          "Your old password was entered incorrectly. Please enter it again"
        );
      }

      setResetToster(false);
    }
  }, [changePasswordState]);

  const resetHandleData = (values: any, { resetForm }: any) => {
    dispatch(changePasswordData(values));
    setResetToster(true);
    currentPassEye();
    passwordEye();
    handleEyeConfirmPassword();
    resetForm({});
  };

  const currentPassEye: any = () => {
    setShowEye(!showEye);
  };
  const passwordEye: any = () => {
    setPasswordEyeShow(!passwordEyeShow);
  };
  const handleEyeConfirmPassword: any = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const submitForm = (values: any) => {
    var formData = new FormData();

    formData.append("first_name", values.firstname.trim());
    formData.append("last_name", values.lastname.trim());
    formData.append("user_name", values.username.trim());
    formData.append("mobile_no", values.mobilenumber.trim());
    formData.append("bio", values.bio.trim());
    formData.append("website_url", values.webSite_url.trim());
    formData.append("photo", getImage);
    if (!imageValidation) {
      dispatch(updateProfileData(formData));
      setUpdateToster(true);
    }
  };

  const getMyProfile: any = useSelector((state: any) => state?.getProfile);

  const imageUploader = async (e: any) => {
    setImageValidation("");
    setImage(e.target.files[0]);
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageValidation(
        "Uploaded file is not a valid image. Only PNG, JPG and JPEG files are allowed"
      );
      return false;
    }

    const base64: any = await convertBase64(file);
    setShowImage(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const searchTextData = (event: any) => {
    setSearchTerm(event);
  };

  return (
    <>
      <Header logout={props.logout} />
      {/* {updateProfileHandler?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )} */}
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings" className="active">
                SETTINGS
              </Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>

            <TabsContent>
              <SettingsWrap>
                <FormGroup className="user-image-wrap">
                  <ImageUploader>
                    <img
                      src={
                        showimage === ""
                          ? getMyProfile?.data?.data?.photo
                          : showimage
                      }
                      alt={getMyProfile?.data?.data?.firstName}
                    />
                    <ImageEditImage>
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={imageUploader}
                      ></input>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <g
                          id="Component_49_2"
                          data-name="Component 49 – 2"
                          transform="translate(1 1)"
                        >
                          <circle
                            id="Ellipse_15"
                            data-name="Ellipse 15"
                            cx="15"
                            cy="15"
                            r="15"
                            fill="#6840df"
                            stroke="#fff"
                            stroke-width="2"
                          />
                          <g
                            id="Group_829"
                            data-name="Group 829"
                            transform="translate(-434.726 -800.552)"
                          >
                            <g
                              id="background"
                              transform="translate(442.491 808.313)"
                            >
                              <rect
                                id="Rectangle_82"
                                data-name="Rectangle 82"
                                width="14"
                                height="15"
                                transform="translate(0.235 0.239)"
                                fill="none"
                              />
                            </g>
                            <g id="edit" transform="translate(443.756 808)">
                              <path
                                id="Path_118"
                                data-name="Path 118"
                                d="M6.659,24.659H4V22Z"
                                transform="translate(-4 -11.608)"
                                fill="#fff"
                              />
                              <path
                                id="Path_120"
                                data-name="Path 120"
                                d="M0,0,13.234.7l.21,3.97L.21,3.97Z"
                                transform="translate(13.696 3.303) rotate(135)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </ImageEditImage>
                  </ImageUploader>
                  <ImageValidation>
                    {imageValidation && imageValidation}
                  </ImageValidation>
                </FormGroup>

                <Formik
                  enableReinitialize
                  initialValues={{
                    username: getMyProfile?.data?.data?.userName ?? "",
                    firstname: getMyProfile?.data?.data?.firstName ?? "",
                    lastname: getMyProfile?.data?.data?.lastName ?? "",
                    bio: getMyProfile?.data?.data?.bio ?? "",
                    mobilenumber: getMyProfile?.data?.data?.mobile ?? "",
                    webSite_url: getMyProfile?.data?.data?.website_url ?? "",
                    photo: getMyProfile?.data?.data?.photo ?? "",
                  }}
                  validate={(values: any) => {
                    let errors: any = {};

                    let availUser = getAllUsers?.data?.data?.filter(
                      (val: any) => {
                        return (
                          values.username === val.userName &&
                          getMyProfile?.data?.data?.userName !== values.username
                        );
                      }
                    );

                    // getAllUsers?.data?.data
                    //   ?.filter((val: any) => {
                    //     if (searchTerm == "") {
                    //       return val;
                    //     } else if (
                    //       val?.userName
                    //         ?.toLowerCase()
                    //         ?.includes(searchTerm?.toLowerCase())
                    //     ) {
                    //       return val;
                    //     }
                    //   })
                    //   ?.map((item: any, index: number) => {
                    //     return (
                    //       <li>
                    //         <h5>{item?.userName}</h5>
                    //       </li>
                    //     );
                    //   });

                    const email =
                      /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;

                    const webSite_url =
                      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
                    const mobileNumber = /^([+])?[1-9][0-9]{9,13}$/;

                    const userName = /^[a-zA-Z0-9-_.]+$/;
                    const firstname = /[a-zA-Z][a-zA-Z\\s]+$/;
                    const lastname = /[a-zA-Z][a-zA-Z\\s]+$/;

                    if (!values.username.trim()) {
                      errors.username = "Please enter username";
                    } else if (availUser.length > 0) {
                      errors.username = "Username is already exist!";
                    } else if (!userName.test(values.username.trim())) {
                      errors.username = "Username is invalid";
                    }
                    // if (values.username.length > 30) {
                    //   errors.username = "Username length is invalid";
                    // }
                    if (!values.firstname.trim()) {
                      errors.firstname = "First name is require";
                    } else if (!firstname.test(values.firstname.trim())) {
                      errors.firstname = "First name is invalid";
                    }
                    if (!values.lastname.trim()) {
                      errors.lastname = "Last name is require";
                    } else if (!lastname.test(values.lastname.trim())) {
                      errors.lastname = "Last name is invalid";
                    }
                    if (
                      isNaN(values.mobilenumber.trim()) &&
                      values.mobilenumber.trim() !== ""
                    ) {
                      errors.mobilenumber = "Please enter valid mobile number";
                    } else if (
                      !mobileNumber.test(values.mobilenumber.trim()) &&
                      values.mobilenumber.trim() !== ""
                    ) {
                      errors.mobilenumber = "Invalid phone number";
                    }

                    if (
                      !webSite_url.test(values.webSite_url) &&
                      values.webSite_url.trim() !== ""
                    ) {
                      errors.webSite_url = "Please enter valid url";
                    }
                    // if (values.bio.trim() !== "") {
                    //   errors.bio = "Please enter 150 character in bio";
                    // }
                    return errors;
                  }}
                  onSubmit={submitForm}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <CustomInput
                          inputid="username"
                          inputlabe="Username"
                          inputtype="text"
                          placeholder="eg. michelle3000"
                          value={values.username}
                          maxLength={30}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors.username}
                          touched={touched.username}
                        />

                        <Row>
                          <Col sm={6}>
                            <CustomInput
                              inputid="firstname"
                              inputlabe="First Name"
                              inputtype="First Name"
                              placeholder="First Name"
                              value={values.firstname}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              maxLength={30}
                              errors={errors.firstname}
                              touched={touched.firstname}
                            />
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <CustomInput
                                inputid="lastname"
                                inputlabe="Last Name"
                                inputtype="text"
                                placeholder="Last Name"
                                value={values.lastname}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                maxLength={30}
                                errors={errors.lastname}
                                touched={touched.lastname}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <CustomInput
                          inputid="bio"
                          inputlabe="Bio"
                          as="textarea"
                          inputtype="textarea"
                          placeholder=""
                          value={values.bio}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          maxLength={150}
                          errors={errors.bio}
                          touched={touched.bio}
                        />

                        <Row>
                          <Col md={6}>
                            <CustomInput
                              inputid="webSite_url"
                              inputlabe="Your Website URL"
                              inputtype="text"
                              placeholder=""
                              value={values.webSite_url}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errors={errors.webSite_url}
                              touched={touched.webSite_url}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomInput
                              inputid="mobilenumber"
                              inputlabe="Mobile"
                              inputtype="text"
                              placeholder=""
                              value={values.mobilenumber}
                              handleBlur={handleBlur}
                              // maxLength={11}
                              handleChange={handleChange}
                              errors={errors.mobilenumber}
                              touched={touched.mobilenumber}
                            />
                          </Col>
                        </Row>
                        <FormGroup className="mb-0">
                          <Button type="submit" className="btn-save">
                            SAVE
                          </Button>
                          {/* <ToastContainer autoClose={4000} /> */}
                        </FormGroup>
                      </form>
                    </>
                  )}
                </Formik>

                <ResetPasswordGroup>
                  <Formik
                    initialValues={{
                      old_password: "",
                      new_password: "",
                      confirm_password: "",
                    }}
                    validate={(values: any) => {
                      let errors: any = {};

                      const passwordRegex =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,17}$/;

                      if (!values.old_password) {
                        errors.old_password =
                          "Please enter your current password";
                      } else if (!passwordRegex.test(values.old_password)) {
                        errors.old_password =
                          "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                      } else if (values.old_password.length < 8) {
                        errors.old_password =
                          "Please enter eight digit current password";
                      }
                      if (
                        values.old_password === values.new_password &&
                        values.old_password !== ""
                      ) {
                        errors.new_password =
                          "current password and new password can't be same";
                      }
                      if (!values.new_password) {
                        errors.new_password = "Please enter your new password";
                      } else if (!passwordRegex.test(values.new_password)) {
                        errors.new_password =
                          "Password must be contain 1 special letter, 1 number, 1 capital and 1 lowercase letter ";
                      }

                      if (!values.confirm_password) {
                        errors.confirm_password =
                          "Please enter your confirm password";
                      } else if (!passwordRegex.test(values.confirm_password)) {
                        errors.confirm_password =
                          "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                      } else if (values.confirm_password.length < 8) {
                        errors.confirm_password =
                          "Please enter eight confirm password";
                      }
                      // if (
                      //   values.old_password === values.confirm_password &&
                      //   values.old_password !== ""
                      // ) {
                      //   errors.confirm_password =
                      //     "current password and confirm password can't be same";
                      // }
                      if (
                        values.new_password.trim() !==
                        values.confirm_password.trim()
                      ) {
                        errors.confirm_password =
                          "Confirm Password does not match";
                      }

                      if (
                        values.old_password.trim() ===
                        values.new_password.trim()
                      ) {
                        // errors.confirm_password =
                        //   "Old password same as new password";
                      }

                      return errors;
                    }}
                    onSubmit={resetHandleData}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      dirty,
                    }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <Form.Label>Reset Password</Form.Label>

                          <CustomInput
                            inputid="old_password"
                            inputtype={showEye ? "text" : "password"}
                            placeholder="Enter Current Password"
                            value={values.old_password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.old_password}
                            touched={touched.old_password}
                            handleEyeChange={currentPassEye}
                            showEye={showEye}
                            autocomplete="off"
                          />

                          <CustomInput
                            inputid="new_password"
                            inputtype={passwordEyeShow ? "text" : "password"}
                            placeholder="New Password"
                            value={values.new_password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.new_password}
                            touched={touched.new_password}
                            handleEyeChange={passwordEye}
                            showEye={passwordEyeShow}
                            autocomplete="off"
                          />

                          <CustomInput
                            inputid="confirm_password"
                            inputtype={confirmPasswordEye ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={values.confirm_password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.confirm_password}
                            touched={touched.confirm_password}
                            handleEyeChange={handleEyeConfirmPassword}
                            showEye={confirmPasswordEye}
                            autocomplete="off"
                          />

                          <Button type="submit">Change Password</Button>
                          {/* <ToastContainer autoClose={5000} /> */}
                        </form>
                      </>
                    )}
                  </Formik>
                </ResetPasswordGroup>
              </SettingsWrap>
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
    </>
  );
};

export default Settings;
