import { followBackAPI } from "../../../services/followBack";

export enum addFollowBackType {
  ADD_FOLLOW_BACK_INFO = "    ADD_FOLLOW_BACK_INFO",
  ADD_FOLLOW_BACK_INFO_SUCCESS = "    ADD_FOLLOW_BACK_INFO_SUCCESS",
  ADD_FOLLOW_BACK_INFO_FALIURE = "    ADD_FOLLOW_BACK_INFO_FALIURE",
}

export const addFollowBackInfo = () => (dispatch: any) => {
  dispatch({
    type: addFollowBackType.ADD_FOLLOW_BACK_INFO,
  });
};

export const addFollowBackInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: addFollowBackType.ADD_FOLLOW_BACK_INFO_SUCCESS,
    data,
  });
};

export const addFollowBackInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: addFollowBackType.ADD_FOLLOW_BACK_INFO_FALIURE,
    error: msg,
  });
};
export const addFollowBackData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(addFollowBackInfo());
    const result: any = await followBackAPI(id);
    dispatch(addFollowBackInfoSuccess(result));
  } catch (e: any) {
    dispatch(addFollowBackInfoFailed(e?.response?.data?.message));
  }
};
