import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import {
  Dropdown,
  Button,
  Carousel,
  // Pagination,
} from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import RentalImage from "../../../assets/images/rental-result-1.jpg";
import RentalImageTwo from "../../../assets/images/rental-result-2.jpg";
import RentalImageThree from "../../../assets/images/rental-result-3.jpg";
import RentalMap from "../../../assets/images/rental-map.jpg";
import CreateNewModal from "../../../components/createNewModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import PlansModal from "../../../components/plansModal/index";
import RentalFilterModal from "../../../components/rentalFilter/index";
import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  RentalsResultsMain,
  RentalsResultsList,
  RentalsResultsItem,
  RentalsListImage,
  RentalsListInfo,
  RentalsTitleWrap,
  RentalsFeature,
  SportsInfo,
  SportsInfoWrap,
  SportsReview,
  SportsRating,
  SportsPrice,
  RentalsListMap,
  SearchListLoader,
  PaginationWrap,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsGallery,
  SportsGalleryTop,
  SportsGalleryBottom,
  SportsGalleryItem,
  SportsTitleWrap,
  SportsTitle,
  DetailRentalsFeature,
  DetailSportsInfo,
  DetailSportsInfoWrap,
  DetailSportsRating,
  DetailSportsReview,
  DetailSportsPrice,
  SportsTeamDesc,
  RecordNotFound,
  BackToList,
  ModalSection,
} from "../rentalsResults/rentalsResultsStyle";

import RentalMainImage from "../../../assets/images/rentals-detail-main.jpg";
import RentalGallery from "../../../assets/images/rentals-detail-gallery.jpg";
import RentalThumbOne from "../../../assets/images/rentals-detail-thumb.jpg";
import RentalThumbTwo from "../../../assets/images/rentals-detail-thumb-1.jpg";
import RentalThumbThree from "../../../assets/images/rentals-detail-thumb-2.jpg";
import ImageGalleryModal from "../../../components/imageGalleryModal/index";

import { getRentalHandler } from "../../../redux/action/get-rentals/getRentals";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import { Pagination } from "antd";
import { getRentalIdData } from "../../../redux/action/getRentalId/getRentalId";
import MapComponent from "../../../components/mapComponent/mapComponent";
import { mapKey } from "../../../resource/constants";
import AnotherDayModal from "../../../components/anotherDayModal";
//for plan create
import CreatePlanModal from "../../../components/createPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import ChatsPlanModal from "../../../components/chatsPlanModal/index";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import ShareWithModal from "../../../components/shareWithModal/index";
import GetAllCollectionsModal from "../../../components/collectionsModal";
import CreateGroupModal from "../../../components/createGroupModal";
import Modal from "react-bootstrap/Modal";

const RentalsResults = (props: any) => {
  const dispatch: any = useDispatch();
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [getPageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPagelimit] = useState(20);
  const [current, setCurrent] = useState();
  const [showSortBy, setShowSortBy] = useState(-1);
  const [showRentalPage, setShowRentalPage] = useState(false);
  const [plansModal, setPlansModal] = useState(false);
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [finalData, setFinalData] = useState<any[]>([]);
  const [rentalItemId, setRentalItemId] = useState("");
  const [planId, setPlanId] = useState("");
  const [imageGalleryModalShow, setImageGalleryModalShow] = useState(false);
  const [shareWithModalShow, setShareWithModalShow] = useState(false);

  //for plan create
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [sortingType, setSortingType] = useState("Price");
  const [getAllImages, setAllImages] = useState([]);
  const [openChatModel, setOpenChatModel] = useState(false);
  const [groupImages, setGroupImages] = useState("");
  const [placeIdData, setPlaceIdData] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [sharewithImage, setShareWithImage] = useState("");
  const [rentalLink, setRentalLink] = useState("");
  const [rentalName, setRentalName] = useState("");
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [rentalAddress, setRentalAddress] = useState("");
  const [allItems, setAllItems] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);
  const [getImageUrl, setImageUrl] = useState("");
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [guestRatingValue, setGuestRatingValue] = useState(0);
  const [guestReviewCount, setGuestReviewCount] = useState(0);
  const [petRules, setPetRules] = useState(false);
  const [getRangeValue, setRangeValue] = useState<any>([0, 1000]);
  const [getRentalFilter, setRentalFilter] = useState(false);
  const [getFacility, setFacility] = useState([]);
  const [getRoomTypes, setRoomTypes] = useState([]);
  // const [getFilterPagination1, setFilterPagination1] = useState(false);
  const [getFilterData, setFilterData] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [place, setPlace] = useState<any>({});
  const [show, setShow] = useState(false);

  //end plan create

  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "rental",
  });

  const rentalData = useSelector((state: any) => state?.getRentals);

  const createPlan = useSelector((state: any) => state?.createPlan);
  const navigate: any = useNavigate();
  const getAllRentalId = useSelector((state: any) => state?.getRentalId);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  // location filter code start
  const latLong: any = useSelector((state: any) => state?.latLong);

  //location filter code finish

  const local: any = JSON.parse(localStorage.getItem("location") || "{}");

  const currentLocation = useSelector((state: any) => state?.currentLocation);

  const location = useLocation();

  const locationPage: any = location.pathname.split("/")[2];

  const filterInfo = useSelector((state: any) => state?.manageFilter);
  console.log(filterInfo, "sdffs");

  // useEffect(() => {
  //   dispatch(
  //     getRentalHandler({
  //       page: Number(locationPage),
  //       limit: pageLimit,
  //       sort: showSortBy,
  //       sorting: sortingType,
  //       filter: filterInfo?.setFilterPagination1,
  //       PetFriendly: filterInfo?.setPetRules,
  //       GuestRating: filterInfo?.setGuestRatingValue,
  //       Price_gt: filterInfo?.setRangeValue?.[0],
  //       Price_lt: filterInfo?.setRangeValue?.[1],
  //       HotelTeaser: filterInfo?.setFacility?.join(","),
  //       Description: filterInfo?.setRoomTypes?.join(","),
  //       location:
  //         currentLocation?.data !== null
  //           ? currentLocation?.data
  //           : latLong?.data?.name
  //           ? latLong?.data?.name
  //           : typeof local !== "object" && local !== ""
  //           ? local
  //           : "",
  //       search: localStorage.getItem("main-search-panel-data")
  //         ? localStorage.getItem("main-search-panel-data")
  //         : getSearchResult?.search,
  //     })
  //   );
  //   dispatch({ type: "RESET_CURRENT_DATA" });
  // }, [
  //   Number(locationPage),
  //   showSortBy,
  //   sortingType,
  //   getFilterData,
  //   latLong?.data?.name,
  //   currentLocation?.data,
  //   place,
  // ]);

  useEffect(() => {
    if (!localStorage.getItem("location")) {
      setShow(true);
      dispatch({ type: "RESET_RENTAL_DATA" });
    } else {
      (getSearchResult?.search?.trim()?.length >= 3 ||
        getSearchResult?.search?.trim() === "") &&
        dispatch(
          getRentalHandler({
            page: Number(locationPage),
            limit: pageLimit,
            sort: showSortBy,
            sorting: sortingType,
            filter: filterInfo?.setFilterPagination1,
            PetFriendly: filterInfo?.setPetRules,
            GuestRating: filterInfo?.setGuestRatingValue,
            Price_gt: filterInfo?.setRangeValue?.[0],
            Price_lt: filterInfo?.setRangeValue?.[1],
            roomNo: filterInfo?.setRoomNo ?? [],
            HotelTeaser: filterInfo?.setFacility?.join(","),
            Description: filterInfo?.setRoomTypes?.join(","),
            location:
              currentLocation?.data !== null
                ? currentLocation?.data
                : JSON.parse(localStorage.getItem("location") || "{}"),
            search: localStorage.getItem("main-search-panel-data")
              ? localStorage.getItem("main-search-panel-data")
              : getSearchResult?.search,
          })
        );
    }

    dispatch({ type: "RESET_CURRENT_DATA" });
  }, [
    Number(locationPage),
    sortingType,
    showSortBy,
    getSearchResult,
    place,
    getFilterData,
  ]);

  useEffect(() => {
    dispatch({ type: "RESET_RENTAL_INFO" });
  }, []);

  //Days model for created plan

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const shareChatImages = (item: any) => {
    if (item?.RentalImages) {
      const allImages = [
        ...item?.RentalImages?.EXTERIOR,
        ...item?.RentalImages?.NONE,
        ...item?.RentalImages?.ROOMS,
        ...item?.RentalImages?.POOL,
      ];
      setGroupImages(allImages[0].link);
      setAllItems(item);
      setDynamicLocation("/rental-result-details");
    }
  };

  useEffect(() => {
    getAllRentalId?.data?.data?.map((item: any) => {
      let allImages: any;
      if (item?.RentalImages) {
        allImages = [
          ...item?.RentalImages?.EXTERIOR,
          ...item?.RentalImages?.NONE,
          ...item?.RentalImages?.ROOMS,
          ...item?.RentalImages?.POOL,
        ];

        setAllImages(allImages);
      }
    });
  }, [getAllRentalId?.data?.data]);

  //end days model for created.

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };
  const [hotelLink, setHotelLink] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [hotelAddress, setHotelAddress] = useState("");

  const navigateToShareWith = (item: any) => {
    setShareWithModalShow(true);
    const allImages = [
      ...item?.HotelImages?.EXTERIOR,
      ...item?.HotelImages?.NONE,
      ...item?.HotelImages?.ROOMS,
      ...item?.HotelImages?.POOL,
    ];
    setShareWithImage(allImages[0].link);
    setHotelLink(item?.Links?.WebSearchResult?.Href);
    setHotelName(item?.Name);
    setHotelAddress(
      `${item?.Location?.Address?.Address1} ${item?.Location?.Address?.city}` ||
        item?.Description?.LocationTeaser
    );
  };

  const navigateToNextPage = (value: any) => {
    navigate(`/rentalsresults/${value}`);
  };

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel id="main-rental">
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <ModalSection>
              <Modal.Body>
                <p>Please Select Location First</p>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(false)}
                >
                  Ok
                </button>
              </Modal.Body>
            </ModalSection>
          </Modal>

          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            setPageNumber={setPageNumber}
            place={place}
            setPlace={setPlace}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link
                to="/"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                All
              </Link>
              <Link
                to="/concertsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Concerts
              </Link>
              <Link
                to="/foodanddrinks/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Food &amp; Drinks
              </Link>
              <Link
                to="/festivalsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Festivals
              </Link>
              <Link
                to="/sportsresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Sports
              </Link>
              <Link
                to="/rentalsresults/1"
                className="btn select"
                onClick={() => {
                  setGuestRatingValue(0);
                  setGuestReviewCount(0);
                  setRangeValue([0, 1000]);
                  setFacility([]);
                  setRoomTypes([]);
                  setPetRules(false);
                  localStorage.removeItem("main-search-panel-data");
                  // setShowRentalPage(false);
                }}
              >
                Rentals
              </Link>
              <Link
                to="/hotelsresult/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hotels
              </Link>
              <Link
                to="/interestresults/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Points of Interest
              </Link>
              <Link
                to="/hiking/1"
                className="btn"
                onClick={() =>
                  localStorage.removeItem("main-search-panel-data")
                }
              >
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              {!showRentalPage && (
                <button
                  type="submit"
                  className="btn filters"
                  onClick={() => {
                    setRentalFilter(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.939"
                    height="12.69"
                    viewBox="0 0 20.939 12.69"
                  >
                    <g
                      id="Group_1813"
                      data-name="Group 1813"
                      transform="translate(-309.65 -341.65)"
                    >
                      <path
                        id="Path_7466"
                        data-name="Path 7466"
                        d="M-10202.795-11068.4h18.24"
                        transform="translate(10513.795 11411.4)"
                        fill="none"
                        stroke="#3b3b3b"
                        stroke-linecap="round"
                        stroke-width="2.7"
                      />
                      <path
                        id="Path_7467"
                        data-name="Path 7467"
                        d="M-10202.794-11068.4h12.872"
                        transform="translate(10513.795 11416.4)"
                        fill="none"
                        stroke="#3b3b3b"
                        stroke-linecap="round"
                        stroke-width="2.7"
                      />
                      <path
                        id="Path_7468"
                        data-name="Path 7468"
                        d="M-10202.795-11068.4h7.259"
                        transform="translate(10513.795 11421.392)"
                        fill="none"
                        stroke="#3b3b3b"
                        stroke-linecap="round"
                        stroke-width="2.7"
                      />
                    </g>
                  </svg>
                  Filters
                </button>
              )}

              <CreateBtnInner>
                {!showRentalPage &&
                  rentalData?.data?.data?.get_rental?.length > 0 && (
                    <Dropdown className="sortby">
                      <Dropdown.Toggle>
                        Sort by
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.656"
                          height="8.596"
                          viewBox="0 0 13.656 8.596"
                        >
                          <path
                            id="Path_133"
                            data-name="Path 133"
                            d="M5.944,11.888,0,5.944,5.944,0"
                            transform="translate(0.884 6.828) rotate(-90)"
                            fill="none"
                            stroke="#3b3b3b"
                            stroke-width="2.5"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          style={{
                            color:
                              sortingType === "Price" && showSortBy === -1
                                ? "#6840df"
                                : "",
                          }}
                          onClick={() => {
                            setPageNumber(1);
                            setShowSortBy(-1);
                            setSortingType("Price");
                          }}
                        >
                          Price : High to Low
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          style={{
                            color:
                              sortingType === "Price" && showSortBy === 1
                                ? "#6840df"
                                : "",
                          }}
                          onClick={() => {
                            setPageNumber(1);
                            setShowSortBy(1);
                            setSortingType("Price");
                          }}
                        >
                          Price : Low to High
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          style={{
                            color:
                              sortingType == "GuestRating" ? "#6840df" : "",
                          }}
                          onClick={() => {
                            setPageNumber(1);
                            setShowSortBy(-1);
                            setSortingType("GuestRating");
                          }}
                        >
                          Guest Rating
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          style={{
                            color:
                              sortingType == "GuestReviewCount"
                                ? "#6840df"
                                : "",
                          }}
                          onClick={() => {
                            setPageNumber(1);
                            setShowSortBy(-1);
                            setSortingType("GuestReviewCount");
                          }}
                        >
                          Number of Reviews
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                {rentalData?.data?.data?.get_rental?.length !== 0 &&
                  rentalData?.isLoading !== true &&
                  localStorage.getItem("location") && (
                    <button
                      type="submit"
                      className="btn"
                      onClick={() => setCreateNewModalShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <g transform="translate(-1016 -337)">
                          <path
                            d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                            transform="translate(1016 337)"
                            fill="#fff"
                          />
                          <path
                            d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                            transform="translate(1020.143 350.845)"
                            fill="#4d27da"
                            stroke="#fff"
                            stroke-width="0.5"
                            className="plusIcon"
                          />
                        </g>
                      </svg>
                      CREATE
                    </button>
                  )}
              </CreateBtnInner>
            </CreateBtn>

            {(rentalData?.data?.data?.get_rental?.length === 0 ||
              !localStorage.getItem("location")) && (
              <RecordNotFound>No data found!</RecordNotFound>
            )}

            {rentalData?.isLoading ? (
              <SearchListLoader>
                <img src={PageLoaderIcon} alt="Loading..." />
              </SearchListLoader>
            ) : (
              <RentalsResultsMain>
                <>
                  {rentalData?.data?.data?.get_rental?.length > 0 && (
                    <RentalsListMap>
                      <MapComponent
                        bootstrapURLKeys={{
                          key: mapKey,
                        }}
                        mapData={rentalData}
                      />
                    </RentalsListMap>
                  )}
                  <RentalsResultsList>
                    {rentalData?.data?.data?.get_rental?.map((item: any) => {
                      let allImages: any;
                      if (item?.RentalImages) {
                        allImages = [
                          ...item?.RentalImages?.EXTERIOR,
                          ...item?.RentalImages?.NONE,
                          ...item?.RentalImages?.ROOMS,
                          ...item?.RentalImages?.POOL,
                        ];
                      }
                      return (
                        <>
                          <RentalsResultsItem>
                            <RentalsListImage>
                              <Carousel>
                                {allImages?.map((image: any) => {
                                  return (
                                    <Carousel.Item>
                                      <img
                                        src={image?.link}
                                        alt={image?.title}
                                      />
                                    </Carousel.Item>
                                  );
                                })}
                                {allImages?.length === 0 && (
                                  <Carousel.Item>
                                    <img src={RentalImage} alt="dummy_image" />
                                  </Carousel.Item>
                                )}
                              </Carousel>
                            </RentalsListImage>
                            <RentalsListInfo>
                              <RentalsTitleWrap>
                                <h3>
                                  <Link
                                    to={`/rental-result-details/${item?._id}`}
                                  >
                                    {item?.Name}
                                  </Link>
                                </h3>
                                <Dropdown>
                                  <Dropdown.Toggle>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle
                                        cx="8"
                                        cy="8"
                                        r="8"
                                        fill="#fff"
                                      ></circle>
                                      <path
                                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                        transform="translate(2.459 16.62)"
                                        fill="#3b3b3b"
                                      ></path>
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => {
                                        setOpenChatModel(true);
                                        groupPlanDetails();
                                        shareChatImages(item);
                                        setPlaceIdData(item?._id);
                                      }}
                                    >
                                      Share to Chat
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => {
                                        setPlansModal(true);
                                        setRentalItemId(item?._id);
                                      }}
                                    >
                                      Add to Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => {
                                        setRentalDetails({
                                          venue_id: item?._id,
                                          name: item?.Name,
                                          location: item?.Location?.Address,
                                          province: "",
                                          Image: allImages?.[0]?.link,
                                          venue_type: "rental",
                                        });
                                        setCollectionModal(true);
                                      }}
                                    >
                                      Add to collection
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => {
                                        setCreatePlanModalShow(true);
                                        setCreateData(item);
                                        setRentalItemId(item?._id);
                                        setAllId([]);
                                        setInviteFriend([]);
                                        setInviteFollower([]);
                                        setInviteEmails([]);
                                        setGroupMembers([]);
                                        setPendingEmail([]);
                                        setShowImage("");
                                        setImage("");
                                        setImageValidation("");
                                      }}
                                    >
                                      Create Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => navigateToShareWith(item)}
                                    >
                                      Share With
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </RentalsTitleWrap>
                              <RentalsFeature>
                                <ul>
                                  {/* <li>Sleeps 2</li> */}
                                  <li>
                                    {item?.PropertyDetails?.NumberOfBedrooms ??
                                      "No"}{" "}
                                    Bedrooms
                                  </li>
                                  {/* <li>1 Bed</li> */}
                                </ul>
                              </RentalsFeature>
                              <SportsInfo>
                                <SportsInfoWrap>
                                  <SportsRating>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Component_44_16"
                                      data-name="Component 44 – 16"
                                      width="22"
                                      height="20.364"
                                      viewBox="0 0 19 17.364"
                                    >
                                      <path
                                        id="Polygon_1"
                                        data-name="Polygon 1"
                                        d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                                        fill="#ffc126"
                                      />
                                    </svg>
                                    {item?.GuestRating ?? "0"}
                                  </SportsRating>
                                  <SportsReview>
                                    {item?.GuestReviewCount ?? "0"} Reviews
                                  </SportsReview>
                                </SportsInfoWrap>
                                <SportsPrice>
                                  $
                                  {item?.RoomTypes[0]?.Price?.TotalPrice?.Value}
                                  /night
                                </SportsPrice>
                              </SportsInfo>
                            </RentalsListInfo>
                          </RentalsResultsItem>
                        </>
                      );
                    })}

                    {rentalData?.data?.data?.get_rental?.length !== 0 &&
                      localStorage.getItem("location") && (
                        <PaginationWrap>
                          <Pagination
                            total={rentalData?.data?.data?.count}
                            pageSize={pageLimit}
                            showSizeChanger={false}
                            onChange={(value, pagesize) => {
                              setPageNumber(value);
                              navigateToNextPage(value);
                            }}
                            current={Number(locationPage)}
                          />
                        </PaginationWrap>
                      )}
                  </RentalsResultsList>
                </>
              </RentalsResultsMain>
            )}
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />

      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
        allItems={allItems}
      />

      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />
      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={"rental"}
      />
      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
        getAllImages={getAllImages}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        sharewithImage={sharewithImage}
        link={hotelLink}
        name={hotelName}
        address={hotelAddress}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <RentalFilterModal
        show={getRentalFilter}
        onHide={() => {
          setRentalFilter(false);
        }}
        setRentalFilter={setRentalFilter}
        guestRatingValue={guestRatingValue}
        setGuestRatingValue={setGuestRatingValue}
        guestReviewCount={guestReviewCount}
        setGuestReviewCount={setGuestReviewCount}
        setPetRules={setPetRules}
        petRules={petRules}
        getRangeValue={getRangeValue}
        setRangeValue={setRangeValue}
        setFacility={setFacility}
        getFacility={getFacility}
        setRoomTypes={setRoomTypes}
        getRoomTypes={getRoomTypes}
        // setFilterPagination={setFilterPagination1}
        setPageNumber={setPageNumber}
        setFilterData={setFilterData}
        getFilterData={getFilterData}
      />
    </>
  );
};

export default RentalsResults;
