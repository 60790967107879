import { createPlanAPI } from "../../../services/getPlan";

export enum CreatePlanType {
  CREATE_PLAN = "CREATE_PLAN",
  CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS",
  CREATE_PLAN_FALIURE = "CREATE_PLAN_FALIURE",
  CREATE_PLAN_RESET = "CREATE_PLAN_RESET",
}

export const createPlan = () => (dispatch: any) => {
  dispatch({
    type: CreatePlanType.CREATE_PLAN,
  });
};

export const createPlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: CreatePlanType.CREATE_PLAN_SUCCESS,
    data,
  });
};

export const createPlanFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: CreatePlanType.CREATE_PLAN_FALIURE,
    data: { msg },
  });
};

export const createPlanHandler = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(createPlan());
    const result: any = await createPlanAPI(finalData);
    dispatch(createPlanSuccess(result));
    dispatch({ type: "CREATE_PLAN_RESET" });
  } catch (e: any) {
    dispatch(createPlanFailed(e?.response?.data?.message));
  }
};
