import { getFestivalListingAPI } from "../../../services/getFestivalListing";

export enum getFestivalListType {
  GET_FESTIVAL_LIST_INFO = "GET_FESTIVAL_LIST_INFO",
  GET_FESTIVAL_LIST_INFO_SUCCESS = "GET_FESTIVAL_LIST_INFO_SUCCESS",
  GET_FESTIVAL_LIST_INFO_FALIURE = "GET_FESTIVAL_LIST_INFO_FALIURE",
}

export const getFestivalInfo = () => (dispatch: any) => {
  dispatch({
    type: getFestivalListType.GET_FESTIVAL_LIST_INFO,
  });
};

export const getFestivalInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getFestivalListType.GET_FESTIVAL_LIST_INFO_SUCCESS,
    data,
  });
};

export const getFestivalInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getFestivalListType.GET_FESTIVAL_LIST_INFO_FALIURE,
    error: msg,
  });
};

export const getFestivalListData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getFestivalInfo());
    const result: any = await getFestivalListingAPI(data);
    dispatch(getFestivalInfoSuccess(result));
  } catch (e: any) {
    dispatch(getFestivalInfoFailed(e?.response?.data));
  }
};
