import { addToCollection } from "../../action/addCollection/addCollection";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};
export default (state = initState, action: any) => {
  switch (action.type) {
    case addToCollection.ADD_COLLECTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case addToCollection.ADD_COLLECTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case addToCollection.ADD_COLLECTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.message,
        data: null,
      };
    }
    default:
      return state;
  }
};
