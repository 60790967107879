import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import UserImage from "../../../assets/images/user-image.png";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import LasVegas from "../../../assets/images/las-vegas.png";
import AddCollectionModal from "../../../components/addCollectionModal/index";
import DeleteCollectionModal from "../../../components/deleteCollectionModal";
import FiltersModal from "../../../components/filtersModal/index";
import RemoveFriendModal from "../../../components/removeFriendModal/index";
import RemoveFollowigModal from "../../../components/removeFollowingModal/index";
import DeleteCollectionItemModal from "../../../components/deleteCollectionItem";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  FormGroup,
  TabsWrap,
  TabsContent,
  MainContentPanelWrap,
  ProfileWrap,
  ProfileLeft,
  ProfileImage,
  ProfileName,
  ProfileUserName,
  ProfileRight,
  FormGroupWrap,
  MyPlansWrap,
  MyPlansTop,
  MyPlansTitle,
  BackToList,
  CreateBtn,
  MyPlansList,
  MyPlansBox,
  MyPlansImage,
  MyPlansBoxTitle,
  MyPlansDate,
  MyPlansLock,
  MyPlansAction,
  MyPlansSubTitle,
  MyPlansActionWrap,
  MyPlansDropdown,
  CityTitle,
  BtnWrap,
  MyPlansMore,
  SocialTabsWrap,
  SocialTabInner,
  FollowList,
  FollowListLeft,
  FollowImage,
  FollowName,
  PageLoader,
  TabDataNotFound,
  SearchListLoader,
  SearchBoxNote,
  RecordNotFound,
  Collectionbtn,
} from "./collectionStyle";
import { getCollectionItemInfoHandler } from "../../../redux/action/get-collection-item/getCollectionItem";
import { ToastContainer, toast, collapseToast } from "react-toastify";

const CollectionItem = (props: any) => {
  const dispatch: any = useDispatch();
  const [getDeleteCollectionItemModal, setDeleteCollectionItemModal] = useState(
    {
      show: false,
      collection_id: "",
      item_id: "",
    }
  );

  let { collection_id }: any = useParams();

  const [getAlCollection, setAllCollection] = useState({
    collectionItemColletion: false,
  });

  const getCollectionItem = useSelector(
    (state: any) => state.getCollectionItem?.data?.data
  );

  const navigate: any = useNavigate();

  const navigationLogic = (type: any, id: any) => {
    switch (type) {
      case "concerts":
        navigate(`/concert-result-details/${id}`);
        break;
      case "sports":
        navigate(`/sports-results-details/${id}`);
        break;
      case "festival":
        navigate(`/festival-result-details/${id}`);
        break;
      case "rental":
        navigate(`/rental-result-details/${id}`);
        break;
      case "food and drink":
        navigate(`/food-and-drink-details/${id}`);
        break;
      case "point of interest":
        navigate(`/interest-details-results/${id}`);
        break;
      case "hiking":
        navigate(`/hiking-result-details/${id}`);
        break;
      case "hotel":
        navigate(`/hotel-result-details/${id}`);
        break;

      default:
    }
  };

  useEffect(() => {
    const data = {
      collection_id: collection_id,
    };
    dispatch(getCollectionItemInfoHandler(data));
  }, [collection_id]);

  return (
    <>
      <Header logout={props.logout} />

      {getCollectionItem?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection" className="active">
                COLLECTIONS
              </Link>
              <Link to="/notification">NOTIFICATIONS</Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>
            <>
              <TabsContent>
                <MyPlansWrap>
                  <>
                    <>
                      <MyPlansTop>
                        <MyPlansTitle>Collection</MyPlansTitle>
                        <BackToList
                          onClick={() => {
                            navigate("/collection");
                          }}
                        >
                          <svg
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.55283 9.1117L15.4567 9.1117C15.945 9.1117 16.3398 9.5065 16.3398 9.99481C16.3398 10.4831 15.945 10.8779 15.4567 10.8779L6.54244 10.8779C6.05413 10.8779 5.65932 10.4831 5.65932 9.99481C5.65932 9.5065 6.06452 9.1117 6.55283 9.1117Z"
                              fill="#6C53E4"
                            />
                            <path
                              d="M9.52378 6.14041C9.75235 6.14041 9.98092 6.22352 10.1472 6.40015C10.49 6.743 10.49 7.30404 10.1472 7.65729L7.7991 10.0053L10.1472 12.3534C10.49 12.6962 10.49 13.2573 10.1472 13.6105C9.8043 13.9534 9.24326 13.9534 8.89001 13.6105L5.91858 10.6391C5.57573 10.2962 5.57573 9.73521 5.91858 9.38196L8.89001 6.41053C9.06663 6.23391 9.29521 6.14041 9.52378 6.14041Z"
                              fill="#6C53E4"
                            />
                            <path
                              d="M10.9997 1.86265e-08C16.5166 1.86265e-08 21.0049 4.48831 21.0049 9.9948C21.0049 15.5117 16.5166 20 10.9997 20C5.4828 20 0.994491 15.5117 0.994491 9.9948C1.00488 4.48831 5.49319 1.86264e-08 10.9997 1.86265e-08ZM10.9997 18.2234C15.5399 18.2234 19.2283 14.5351 19.2283 9.9948C19.2283 5.45455 15.5399 1.76623 10.9997 1.76623C6.45943 1.76623 2.77112 5.45455 2.77112 9.9948C2.7815 14.5351 6.46982 18.2234 10.9997 18.2234Z"
                              fill="#6C53E4"
                            />
                          </svg>
                          Back
                        </BackToList>
                      </MyPlansTop>
                      <MyPlansList>
                        {!getAlCollection?.collectionItemColletion &&
                          getCollectionItem
                            ?.slice(0, 5)
                            ?.reverse()
                            .map((ele: any) => {
                              return (
                                <MyPlansBox>
                                  <>
                                    <MyPlansImage>
                                      <img
                                        src={
                                          ele?.Image !== null &&
                                          ele?.Image !== ""
                                            ? ele?.Image
                                            : LasVegas
                                        }
                                        onClick={() =>
                                          navigationLogic(
                                            ele?.venue_type,
                                            ele?.venue_id
                                          )
                                        }
                                        alt={ele?.name}
                                      />
                                    </MyPlansImage>
                                  </>
                                  <MyPlansBoxTitle className="collection-lg-title">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                    >
                                      {ele?.name}
                                    </span>
                                  </MyPlansBoxTitle>
                                  <button
                                    className="deletebtn"
                                    onClick={() => {
                                      setDeleteCollectionItemModal({
                                        show: true,
                                        collection_id: "",
                                        item_id: ele?._id,
                                      });
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#3b3b3b"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                      ></line>
                                    </svg>
                                  </button>
                                </MyPlansBox>
                              );
                            })}

                        {getAlCollection?.collectionItemColletion &&
                          getCollectionItem?.reverse()?.map((ele: any) => {
                            return (
                              <MyPlansBox>
                                <>
                                  <MyPlansImage>
                                    <img
                                      src={
                                        ele?.Image !== null && ele?.Image !== ""
                                          ? ele?.Image
                                          : LasVegas
                                      }
                                      onClick={() =>
                                        navigationLogic(
                                          ele?.venue_type,
                                          ele?.venue_id
                                        )
                                      }
                                      alt={ele?.name}
                                    />
                                  </MyPlansImage>
                                </>
                                <MyPlansBoxTitle className="collection-lg-title">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigationLogic(
                                        ele?.venue_type,
                                        ele?.venue_id
                                      )
                                    }
                                  >
                                    {ele?.name}
                                  </span>
                                </MyPlansBoxTitle>

                                <button
                                  className="deletebtn"
                                  onClick={() => {
                                    setDeleteCollectionItemModal({
                                      show: true,
                                      collection_id: "",
                                      item_id: ele?._id,
                                    });
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#3b3b3b"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line
                                      x1="10"
                                      y1="11"
                                      x2="10"
                                      y2="17"
                                    ></line>
                                    <line
                                      x1="14"
                                      y1="11"
                                      x2="14"
                                      y2="17"
                                    ></line>
                                  </svg>
                                </button>
                              </MyPlansBox>
                            );
                          })}

                        {getCollectionItem?.length === 0 ? (
                          <RecordNotFound>
                            No Collection Items found!
                          </RecordNotFound>
                        ) : (
                          !getAlCollection?.collectionItemColletion &&
                          getCollectionItem?.length > 5 && (
                            <Collectionbtn>
                              <Button
                                type="button"
                                onClick={() => {
                                  setAllCollection({
                                    collectionItemColletion: true,
                                  });
                                }}
                                className="collection-btn"
                              >
                                See Items...
                              </Button>
                            </Collectionbtn>
                          )
                        )}
                      </MyPlansList>
                    </>
                  </>
                </MyPlansWrap>
              </TabsContent>
            </>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />

        <ToastContainer autoClose={4000} />
      </MyPageWrap>

      <DeleteCollectionItemModal
        show={getDeleteCollectionItemModal.show}
        onHide={() =>
          setDeleteCollectionItemModal({
            show: false,
            collection_id: "",
            item_id: "",
          })
        }
        getDeleteCollectionItemModal={getDeleteCollectionItemModal}
      />
    </>
  );
};

export default CollectionItem;
