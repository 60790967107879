// import { DELETE_DATA, GLOBAL_DATA } from "../action/globalData";
import { GlobalDataType } from "../action/globalData";

const initialState = {
  user: {},
  displayName: "",
  token: "",
};

const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GlobalDataType.GLOBAL_DATA:
      return Object.assign({}, state, action.payload);
    case GlobalDataType.DELETE_DATA:
      return initialState;
    default:
      return state;
  }
};

export default commonReducer;
