import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { Tabs, Modal, Dropdown, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import {
  signUpDataAPI,
  signUpInfoSuccess,
  signUpInfoFailed,
} from "../../../redux/action/signup/signUp";
import {
  updateUserData,
  updateInfoSuccess,
  updateInfoFailed,
} from "../../../redux/action/updateuser/updateUser";

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  AuthBoxTitle,
  FormNote,
  Spinner,
} from "./authStyle";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import jwt from "jwt-decode";
import {
  linkExpiredDataInfo,
  linkExpireInfoSuccess,
} from "../../../redux/action/link-expire/linkExpire";
import { getInviteFrndRandomStringData } from "../../../redux/action/getInviteFrndRandomString/getInviteFriendRandomString";

function Signup() {
	ReactGA.initialize('G-W12FWLYJS8');
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location: any = useLocation();
  const [tokenData, setTokenData] = useState<any>("");

  const queryParams = window.location.pathname;

  const decodeJwt: any = queryParams !== "/signup" ? queryParams : "";

  const [showEye, setShowEye] = useState(false);
  const [confirmPasswordShowEye, setConfirmPasswordShowEye] = useState(false);
  const [isToasting, setIsToasting] = useState(false);

  const signUpUser = useSelector((state: any) => state?.signUp);

  const updateUserDatas = useSelector((state: any) => state?.updateUser);

  const linkExpire = useSelector((state: any) => state?.linkExpire);

  const getInviteFrndString: any = useSelector(
    (state: any) => state?.getInviteFrndRandomString
  );

  useEffect(() => {
    if (!queryParams.slice(8).includes(".")) {
      dispatch(getInviteFrndRandomStringData(queryParams.slice(8)));
    } else {
      let data = jwt(queryParams);
      setTokenData(data);
    }
  }, []);

  useEffect(() => {
    dispatch(
      linkExpiredDataInfo({
        email: getInviteFrndString?.data?.data?.data?.email || tokenData?.email,
      })
    );
  }, [getInviteFrndString?.data?.data?.data?.email, tokenData]);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        case "success":
          setIsToasting(true);
          toast.success("Signup successfully");
          closeToast();
          break;
        case "error":
          setIsToasting(true);
          toast.error("Email already exist");
          closeToast();
          break;
        case "alreadyRegistered":
          setIsToasting(true);
          toast.error("Link expired");
          closeToast();
          break;
        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!signUpUser?.isLoading) {
      if (signUpUser?.data?.data) {
        getToast("success");
        setTimeout(() => {
          // navigate("/accountrequest");
          dispatch(signUpInfoFailed(""));
        }, 5000);
      } else if (signUpUser?.error) {
        getToast("error");
        dispatch(signUpInfoSuccess(""));
      }
    }
  }, [signUpUser]);

  useEffect(() => {
    if (!updateUserDatas?.isLoading) {
      if (updateUserDatas?.data?.data) {
        getToast("success");
        setTimeout(() => {
          navigate("/accountrequest");
          dispatch(updateInfoFailed(""));
        }, 5000);
      } else if (updateUserDatas?.error) {
        getToast("error");
        dispatch(updateInfoSuccess(""));
      } else if (linkExpire?.data?.data === true) {
        getToast("alreadyRegistered");
        setTimeout(() => {
          navigate("/");
        }, 5000);
        // dispatch(linkExpireInfoSuccess(""));
      }
    }
  }, [updateUserDatas, linkExpire]);

  const submitForm = async (values: any) => {
	  
	  ReactGA.event({
	  category: 'Button Click',
		action: 'Signup User',
		label: 'Signup User',
	});
	  
    if (getInviteFrndString || tokenData) {
      dispatch(
        updateUserData({
          ...values,
          id: getInviteFrndString?.data?.data?.data?._id || tokenData?._id,
          dob: `${values?.month}-${values?.day}-${values?.year}`,
          // moment(values.dob).format("MM-DD-YYYY")
        })
      );
    } else {
      await dispatch(
        signUpDataAPI({
          ...values,
          dob: `${values?.month}/${values?.day}/${values?.year}`,
        })
      );
    }
  };

  const handleEyeChange: any = () => {
    setShowEye(!showEye);
  };
  const handleEyeChangeConfirmPassword = () => {
    setConfirmPasswordShowEye(!confirmPasswordShowEye);
  };

  const onlyNumberHandler = (e: any) => {
    const re = /[0-9 () +:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <AuthWrap>
        <Header />
        <AuthWrapInner className="SignupFormWrap">
          <AuthContainer>
            <Row>
              <Col>
                <AuthBoxTitle>
                  <h2>Signup</h2>
                  {getInviteFrndString?.data?.data?.type === "invite_friend" ? (
                    <h3>
                      You've been invited by "
                      {getInviteFrndString?.data?.data?.data?.invited_by}
                      ".
                      <span className="d-block">Welcome to the platform.</span>
                    </h3>
                  ) : (
                    <h3>Welcome to Tripd</h3>
                  )}

                  <p>
                    <span>
                      <strong>Connect • Plan • Go</strong>
                    </span>
                  </p>
                </AuthBoxTitle>
              </Col>
            </Row>

            <Formik
              enableReinitialize
              initialValues={{
                username: "",
                email:
                  (getInviteFrndString?.data?.data?.data?.email ||
                    tokenData?.email) ??
                  "",
                firstname: "",
                lastname: "",
                password: "",
                confirmPassword: "",
                day: "",
                month: "",
                year: "",
              }}
              validate={(values: any) => {
                let errors: any = {};
                // For Email Regex
                const email =
                  /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
                const regex = /\A[\w+\-.]+@[a-z\d\-.]+\.[a-z]+\z/;

                const passwordRegex =
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,17}$/;
                const dayRegex = /^(0?[1-9]|[12][0-9]|3[01])$/;
                const monthRegex = /(^[1-9]$)|(^0[1-9]|1[0-2]$)/;
                const yearLength = /^\d{4}$/;
                const userName = /^[a-zA-Z0-9-_.]+$/;

                const firstname = /[a-zA-Z][a-zA-Z\\s]+$/;
                const lastname = /[a-zA-Z][a-zA-Z\\s]+$/;

                const date = new Date();
                const currentYear: any = moment().format("YYYY");
                const currentDay: any = moment().format("DD");
                const currentMonth: any = moment().format("MM");

                const currentDate =
                  currentYear + "/" + currentMonth + "/" + currentDay;
                const userDate =
                  values.year + "/" + values.month + "/" + values.day;

                if (!values.email.trim()) {
                  errors.email = "Please enter your email address";
                } else if (!email.test(values.email.trim())) {
                  errors.email = "Please enter valid mail address";
                }
                // For UserName Regex

                if (!values.username.trim()) {
                  errors.username = "Please enter username";
                } else if (!userName.test(values.username.trim())) {
                  errors.username = "Please enter the correct unique Username";
                }
                // if (values.username.length > 30) {
                //   errors.username = "Username length is invalid";
                // }
                // FirstName Regex

                if (!values.firstname.trim()) {
                  errors.firstname = "Please enter first name";
                } else if (!firstname.test(values.firstname.trim())) {
                  errors.firstname = "First name is invalid";
                }

                // For LastName Regex

                if (!values.lastname.trim()) {
                  errors.lastname = "Please enter last name";
                } else if (!lastname.test(values.lastname.trim())) {
                  errors.lastname = "Last name is invalid";
                }
                if (
                  values.day === "" &&
                  values.month === "" &&
                  values.year === ""
                ) {
                  errors.day = "Please enter your date of birth";
                } else if (
                  !values.day ||
                  !dayRegex.test(values.day) ||
                  !values.month ||
                  !monthRegex.test(values.month) ||
                  !values.year ||
                  !yearLength.test(values.year) ||
                  userDate > currentDate
                ) {
                  errors.day = "Please enter valid date of birth";
                }
                if (!values.password) {
                  // else if (!dayRegex.test(values.day)) {
                  //   errors.day = "Please enter valid date format";
                  // }
                  // if (!monthRegex.test(values.month)) {
                  //   errors.month = "Please enter valid month format";
                  // }
                  // if (!yearLength.test(values.year)) {
                  //   errors.year = "Please enter valid year format";
                  // }
                  // else if (userDate >= currentDate) {
                  //   errors.year = "Please enter valid birthdate";
                  // }
                  errors.password = "Please enter your password";
                }
                // else if (!passwordRegex.test(values.password)) {
                //   errors.password =
                //     "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                // }
                else if (values.password.length < 8) {
                  errors.password = "Please enter eight digit password";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = "Please enter your confirm password";
                } else if (values.confirmPassword.length < 8) {
                  errors.confirmPassword =
                    "Please enter eight digit confirm password";
                }
                // else if (!passwordRegex.test(values.confirmPassword)) {
                //   errors.confirmPassword =
                //     "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                // }
                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = "Confirm Password does not match";
                }
                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <>
                      <Row>
                        <Col>
                          <CustomInput
                            inputid="username"
                            inputlabe=""
                            placeholder="username (eg. cyrusontripd)"
                            value={values.username}
                            maxLength={30}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.username}
                            touched={touched.username}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomInput
                            inputid="email"
                            inputlabe=""
                            inputtype="email"
                            placeholder="Email Address"
                            value={values.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.email}
                            touched={touched.email}
                            disabled={decodeJwt}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomInput
                            inputid="firstname"
                            inputlabe=""
                            inputtype="First Name"
                            placeholder="First Name"
                            value={values.firstname}
                            maxLength={30}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.firstname}
                            touched={touched.firstname}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <CustomInput
                            inputid="lastname"
                            inputlabe=""
                            inputtype="Last Name"
                            placeholder="Last Name"
                            value={values.lastname}
                            maxLength={30}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.lastname}
                            touched={touched.lastname}
                          />
                        </Col>
                      </Row>

                      <Row className="birthDate">
                        <Col xs={12}>
                          <FormGroup className="mb-0">
                            <Form.Label>Date of Birth</Form.Label>
                          </FormGroup>
                        </Col>
                        <Col xs={4}>
                          <CustomInput
                            inputid="month"
                            inputlabe=""
                            inputtype="text"
                            placeholder="Month"
                            value={values.month}
                            handleBlur={handleBlur}
                            onKeyPress={onlyNumberHandler}
                            handleChange={handleChange}
                            maxLength={2}
                            errors={errors.day}
                            touched={touched.day}
                          />
                        </Col>

                        <Col xs={4}>
                          <CustomInput
                            inputid="day"
                            inputlabe=""
                            inputtype="text"
                            placeholder="Day"
                            value={values.day}
                            handleBlur={handleBlur}
                            onKeyPress={onlyNumberHandler}
                            handleChange={handleChange}
                            maxLength={2}
                            errors={errors.month}
                            touched={touched.month}
                          />
                        </Col>
                        <Col xs={4}>
                          <CustomInput
                            inputid="year"
                            inputlabe=""
                            inputtype="text"
                            placeholder="Year"
                            value={values.year}
                            handleBlur={handleBlur}
                            onKeyPress={onlyNumberHandler}
                            handleChange={handleChange}
                            maxLength={4}
                            errors={errors.year}
                            touched={touched.year}
                          />
                        </Col>

                        {/* {errors.day ? (
                          <Col xs={12}>
                            <label className="dateError">
                              Please enter birthdate
                            </label>
                          </Col>
                        ) : errors.year ? (
                          <Col xs={12}>
                            <label className="dateError">
                              Please enter valid birthdate
                            </label>
                          </Col>
                        ) : (
                          ""
                        )} */}
                        {/* {touched.year && <p>Please enter valid birthdate</p>} */}
                      </Row>
                      <Row>
                        <Col>
                          <CustomInput
                            inputid="password"
                            inputlabe=""
                            inputtype={showEye ? "text" : "password"}
                            placeholder="Create Password"
                            value={values.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.password}
                            touched={touched.password}
                            handleEyeChange={handleEyeChange}
                            showEye={showEye}
                            maxLength={16}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <CustomInput
                            inputid="confirmPassword"
                            inputlabe=""
                            inputtype={
                              confirmPasswordShowEye ? "text" : "password"
                            }
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors.confirmPassword}
                            touched={touched.confirmPassword}
                            handleEyeChange={handleEyeChangeConfirmPassword}
                            showEye={confirmPasswordShowEye}
                            maxLength={16}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Button type="submit" className="auth-btn">
                            {signUpUser.isLoading ? (
                              <Spinner animation="border" role="status" />
                            ) : null}
                            Signup
                          </Button>
                          {/* <ToastContainer autoClose={5000} /> */}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormNote>
                            By clicking Signup, you are indicating that you have
                            read and acknowledged the{" "}
                            <Link to="/terms-and-conditions">
                              Terms of Service
                            </Link>{" "}
                            and <Link to="/privacy-policy">Privacy Policy</Link>
                          </FormNote>
                        </Col>
                      </Row>
                    </>
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
    </>
  );
}
export default Signup;
