import { getAllUserAPI } from "../../../services/getAllUsers";

export enum GetAllUserType {
  GET_ALL_USERS = "GET_ALL_USERS",
  GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FALIURE = "GET_ALL_USERS_FALIURE",
}

export const getAllUserInfo = () => (dispatch: any) => {
  dispatch({
    type: GetAllUserType.GET_ALL_USERS,
  });
};

export const getAllUserInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetAllUserType.GET_ALL_USERS_SUCCESS,
    data,
  });
};

export const getAllUserInfoFaliure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: GetAllUserType.GET_ALL_USERS_FALIURE,
    error: msg,
  });
};

export const getAllUserAPIData = () => async (dispatch: any) => {
  try {
    dispatch(getAllUserInfo());
    const result: any = await getAllUserAPI();
    dispatch(getAllUserInfoSuccess(result));
  } catch (e: any) {
    dispatch(getAllUserInfoFaliure(e.response.data.error));
  }
};
