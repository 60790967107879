import { getTermsListingAPI } from "../../../services/termsListing";

export enum GetTermsType {
  GET_TERMS_INFO = "GET_TERMS_INFO",
  GET_TERMS_INFO_SUCCESS = "GET_TERMS_INFO_SUCCESS",
  GET_TERMS_INFO_FALIURE = "GET_TERMS_INFO_FALIURE",
}

export const getTermsInfo = () => (dispatch: any) => {
  dispatch({
    type: GetTermsType.GET_TERMS_INFO,
  });
};

export const getTermsInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetTermsType.GET_TERMS_INFO_SUCCESS,
    data: data,
  });
};

export const getTermsInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: GetTermsType.GET_TERMS_INFO_FALIURE,
    data: { msg },
  });
};

export const getTermsData = () => async (dispatch: any) => {
  try {
    dispatch(getTermsInfo());
    const result: any = await getTermsListingAPI();
    dispatch(getTermsInfoSuccess(result));
  } catch (e: any) {
    dispatch(getTermsInfoFailed(e.response.data.message));
  }
};
