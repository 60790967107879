import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #8d8d8d;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #8d8d8d;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #8d8d8d;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #8d8d8d;
      opacity: 1;
    }
    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none !important;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    .message {
      margin: 8px 0 !important;
    }
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 20px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    form {
      .error {
        font-family: "Poppins";
        color: #ef3939;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        margin: -10px 0 8px;
        &:empty {
          display: none;
        }
      }
    }
  }
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 10px;
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupItem = styled.div`
  position: relative;
  padding-left: 35px;
  display: inline-block;
  vertical-align: middle;
  h4 {
    color: #3b3b3b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 0;
    svg {
      margin-right: 11px;
      vertical-align: -1px;
    }
  }
  p {
    color: #a1a3b0;
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
  }
`;

export const BtnWrap = styled.div`
  margin-top: 10px;
  text-align: center;
  .btn-primary {
    background: #3b3b3b;
    border: none;
    border-radius: 18px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px 15px;
    display: inline-block;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #6840df;
      color: #fff;
    }
  }
`;

export const AddCollectionWrap = styled.div``;

export const UploadImageBox = styled.div`
  width: 156px;
  height: 117px;
  padding: 15px;
  border-radius: 10px;
  background: #f0f0f0;
  overflow: hidden;
  margin: 0 0 20px;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .uploader-title {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #3b3b3b;
    padding-top: 10px;
  }
  .form-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    font-size: 0;
    line-height: 0;
    width: 100%;
    padding: 0;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &.imageUploaded {
    padding: 0;

    .deleteBtn {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 0;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.25s ease 0s;
      outline: none;
      svg {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: #f9328d;
        svg {
          stroke: #fff;
        }
      }
    }
  }
`;

export const VisibilityGroup = styled.div`
  h3 {
    font-family: Poppins;
    color: #3b3b3b;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  ul {
    margin: 0 -10px;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    display: flex;
    li {
      width: 50%;
      padding: 0 10px;
      margin: 0 0 15px;
    }
  }
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;
