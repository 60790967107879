import { createGroupAPI } from "../../../services/create-group";

export enum CreateGroupType {
  CREATE_GROUP = "CREATE_GROUP",
  CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_FALIURE = "CREATE_GROUP_FALIURE",
}

export const createGroup = () => (dispatch: any) => {
  dispatch({
    type: CreateGroupType.CREATE_GROUP,
  });
};

export const createGroupSucess = (data: any) => (dispatch: any) => {
  dispatch({
    type: CreateGroupType.CREATE_GROUP_SUCCESS,
    data,
  });
};

export const createGroupFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: CreateGroupType.CREATE_GROUP_FALIURE,
    error: { msg },
  });
};

export const createGroupHandler = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(createGroup());
    const result: any = await createGroupAPI(finalData);
    dispatch(createGroupSucess(result));
  } catch (e: any) {
    dispatch(createGroupFailed(e?.response?.data?.message));
  }
};
