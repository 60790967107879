import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link, Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import DeleteGroupModal from "../../../components/deleteGroupModal/index";
import DeleteDaysModal from "../../../components/deleteDaysModal/index";
import ExitGroupModal from "../../../components/exitGroupModal/index";
import RemoveInvitedUser from "../../../components/removeInvitedUserModal";

import VisiblePubliclyModal from "../../../components/visiblePubliclyModal/index";
import AnotherDayModal from "../../../components/anotherDayModal/index";
import UserImage from "../../../assets/images/user-image.png";
import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import CandyBrian from "../../../assets/images/candy-brian.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import ChatAttachment from "../../../assets/images/chat-attachment.png";
import CandyJohns from "../../../assets/images/candy-johns.png";
import LasVegas from "../../../assets/images/las-vegas.png";
import CircleSuccessIcon from "../../../assets/images/circle-success-icon.svg";
import CircleInfoIcon from "../../../assets/images/circle-info-icon.svg";
import CircleWaitIcon from "../../../assets/images/circle-wait-icon.svg";
import CircleInfoGrayIcon from "../../../assets/images/circle-info-gray-icon.svg";
import VisibilityIcon from "../../../assets/images/visibility-icon.svg";
import {
  MyPageWrap,
  MainContentPanel,
  TabbingPanel,
  FormGroup,
  TabsWrap,
  TabsContent,
  GroupChatWrap,
  GroupChatTitle,
  GroupChatItem,
  GroupChatDate,
  GroupChatHead,
  GroupChatBody,
  ChatUserName,
  ChatTime,
  ChatUserImage,
  ChatUserMessage,
  ChatBodyAttachment,
  ChatMessageAction,
  ChatMessageSendBtn,
  PlanDetailsTitle,
  PlanDetailsWrap,
  PlanDetailsLeft,
  PlanDetailsRight,
  PlanDetailsUser,
  PlanDetailsUserImage,
  PlanDetailsUserInfo,
  PlanDetailsUserTopInfo,
  CreatorBtn,
  PlanDetailsNote,
  PlanDetailsDes,
  PlanDetailsInfoAction,
  DetailsPlanListingWrap,
  DetailsPlanListing,
  DetailsPlanListingTitle,
  PlanListingTitleBtn,
  DetailsPlanListingItem,
  PlanListingItemImage,
  PlanListingItemName,
  PlanListingItemDes,
  PlanListingItemAddBox,
  PlanListingItemAddBoxInner,
  StartEndDate,
  DropdownDateNote,
  DropdownAction,
  DropdownFullBtn,
  GroupMembersList,
  GroupMemberItem,
  MemberItemIcon,
  ModalBody,
  ModalBodyBtn,
  ModalMemberGroupList,
  ModalMemberGroupItem,
  InviteGroupRadioBox,
  PlanConnectionInvite,
  PlanStatusSelect,
  SelectOption,
  OptionRadioBox,
  InviteGroupCheckBox,
  GroupMemberRemove,
  CheckBox,
  RadioBox,
  PageLoader,
  BackToTop,
  VenueImage,
} from "./viewPlanDetailsStyle";
import moment from "moment";
import { planDetailsHandler } from "../../../redux/action/planDetails/planDetails";
import { planStatusHandler } from "../../../redux/action/planStatus/planStatus";
import { manageDayPlanDetailsHandler } from "../../../redux/action/manageDayPlan/manageDayPlan";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import emailImage from "../../../assets/images/defaultImage.jpg";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import UpdatePlanModal from "../../../components/updatePlanModal/index";
import { updatePlanData } from "../../../redux/action/updatePlan/updatePlan";
import AddDayToAnotherDayModal from "../../../components/addDayToAnotherDayModal";
import CopyDayToAnotherDayModal from "../../../components/copyDayToAnotherDayModal";
import DeleteVenueModal from "../../../components/deleteVenueModal";
import JoinGroupModal from "../../../components/joinGroupModal";
import { deleteVenuePlanData } from "../../../redux/action/deleteVenuePlan/deleteVenuePlan";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateVenuePlanData } from "../../../redux/action/updateVenuePlan/updateVenuePlan";

import { updateNonNullChain } from "typescript";

const ViewPlanDetails = (props: any) => {
  const dispatch: any = useDispatch();
  const bottomRef = useRef(null);

  const navigate: any = useNavigate();
  const [deleteGroupModalShow, setDeleteGroupModalShow] = useState(false);
  const [deleteDaysModalShow, setDeleteDaysModalShow] = useState(false);
  const [anotherDayModalShow, setAnotherDayModalShow] = useState(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [showRemoveInvitedModal, setRemoveInvitedUser] = useState(false);
  const [addDayToAnotherDayPlanModalShow, setAddDayToAnotherDayPlanModalShow] =
    useState(false);
  const [
    copyDayToAnotherDayPlanModalShow,
    setCopyDayToAnotherDayPlanModalShow,
  ] = useState(false);
  const [venueIds, setVenueIds] = useState("");
  const [checkIds, setCheckIds] = useState("");
  const [checkDate, setCheckDate] = useState("");
  const [start_time, setStart_Time] = useState("");
  const [end_time, setEnd_Time] = useState("");
  const [scrollButtonHide, setScrollButtonHide] = useState(false);

  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [visiblePubliclyModalShow, setVisiblePubliclyModalShow] =
    useState(false);
  const [getExitgroupModal, setExitGroupModal] = useState(false);

  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [deleteVenueModal, setDeleteVenueModal] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [iniviteUser, setinviteUser] = useState(true);
  const [getPlanStatus, setPlanStatus] = useState("");
  const [daysId, setDaysId] = useState("");
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [bottomScroll, setBottomScroll] = useState(false);

  // const [getUpdatedEmail, setUpdatedEmail] = useState<any[]>([]);
  const [venueIdDelete, setVenueIdDelete] = useState("");
  const [getAllInvitedId, setInvitedId] = useState<any[]>([]);
  const [getVisibility, setVisibility] = useState("Public");
  const [error, setError] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);

  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [getImageUrl, setImageUrl] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState("");
  const [getUserDetails, setUserDetails] = useState([]);
  const [joinPublicPlan, setPublicPlan] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getUploadBox, setUploadBox] = useState(false);

  const ref: any = useRef(null);

  const bottomToTopRef: any = useRef(null);

  const SideSearchUserPanel = () => {
    const panelBtn = document.querySelector(".searchUserPanel");
    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };

  const SideTabbingPanel = () => {
    const panelBtn = document.querySelector(".tabbingPanel");
    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };
  let { plan_id }: any = useParams();

  const planDetails: any = useSelector((state: any) => state?.planDetails);

  useEffect(() => {
    setInviteEmails([]);

    if (planDetails?.data?.data[0]?.Userdetails?.length > 0) {
      planDetails?.data?.data[0]?.Userdetails.map((element: any) => {
        setInviteEmails((prev: any) => [
          ...prev,
          { email: element?.email, photo: element?.photo },
        ]);
      });
    } else if (planDetails?.data?.data[0]?.Userdetails?.length === 0) {
      setInviteEmails([]);
    }
    planDetails?.data?.data[0]?.Userdetails?.map(
      (ele: any) =>
        ele.id == localStorage.getItem("id") &&
        setVisibility(ele?.Status[0]?.visibility)
    );
  }, [planDetails?.data?.data[0]?.Userdetails]);

  useEffect(() => {
    const getUser = planDetails?.data?.data[0]?.Userdetails?.filter(
      (ele: any) => ele?.id === localStorage.getItem("id")
    );
    setUserDetails(getUser);
  }, [planDetails]);

  useEffect(() => {
    setPendingEmail([]);
    if (
      planDetails?.data?.data[0]?.invite_emails?.length > 0 ||
      planDetails?.data?.data[0]?.pendingList?.length > 0
    ) {
      const allInvitedEmails = [
        ...planDetails?.data?.data[0]?.invite_emails,
        ...planDetails?.data?.data[0]?.pendingList,
      ];

      allInvitedEmails?.map((element: any) => {
        setPendingEmail((prev: any) => [
          ...prev,
          { email: element, photo: emailImage },
        ]);
      });
    } else if (
      planDetails?.data?.data[0]?.invite_emails?.length === 0 &&
      planDetails?.data?.data[0]?.pendingList?.length === 0
    ) {
      setPendingEmail([]);
    }
  }, [
    planDetails?.data?.data[0]?.invite_emails,
    planDetails?.data?.data[0]?.pendingList,
  ]);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const plan: any = useSelector(
    (state: any) => state?.planDetails?.data?.data[0]
  );
  const deleteGroup: any = useSelector(
    (state: any) => state?.deletePlan?.data?.data
  );

  const inviteOthersHandler = (ele: any) => {
    const data = {
      start_date: planDetails?.data?.data[0]?.start_date,
      end_date: planDetails?.data?.data[0]?.end_date,
      plan_name: planDetails?.data?.data[0]?.plan_name,
      description: planDetails?.data?.data[0]?.description,
      view_plan: planDetails?.data?.data[0]?.view_plan,
      invite_other: ele,
      connection_invite: true,
      plan_id: planDetails?.data?.data[0]?._id,
    };
    dispatch(updatePlanData(data));
  };
  const updatePlan: any = useSelector((state: any) => state?.updatePlan);
  const planStatus: any = useSelector((state: any) => state?.planStatus);
  const invitePlan: any = useSelector((state: any) => state?.invitePlan);
  const manageDayPlan: any = useSelector((state: any) => state?.manageDayPlan);
  const createPlan = useSelector((state: any) => state?.createPlan);
  const removeInvitePlan: any = useSelector(
    (state: any) => state?.removeInvitePlan
  );
  const addAnotherDayPlan: any = useSelector(
    (state: any) => state?.addAnotherDayPlan
  );
  const copyAnotherDayPlan: any = useSelector(
    (state: any) => state?.copyAnotherDayPlan
  );
  const deleteVenuePlan: any = useSelector(
    (state: any) => state?.deleteVenuePlan
  );
  const updateVenuePlan: any = useSelector(
    (state: any) => state?.updateVenuePlan
  );
  const manageVenuePlan: any = useSelector(
    (state: any) => state?.manageVenuePlan
  );

  useEffect(() => {
    dispatch({ type: "RESET_PLAN_DETAILS" });
  }, []);

  useEffect(() => {
    const data: any = {
      plan_id: plan_id,
    };
    dispatch(planDetailsHandler(data));
  }, [
    updatePlan?.data?.data,
    planStatus,
    manageDayPlan,
    removeInvitePlan?.data?.data,
    invitePlan,
    addAnotherDayPlan,
    copyAnotherDayPlan,
    deleteVenuePlan,
    updateVenuePlan,
    createPlan,
    manageVenuePlan,
  ]);

  useEffect(() => {
    setAllId([]);
    setInviteFriend([]);
    setInviteFollower([]);
    setEmailValidate("");
    setEmail("");
    setAllEmail([]);
    setInviteEmails([]);
    setGroupMembers([]);
    setAllUniqueGroupMember([]);
    setPendingEmail([]);
  }, [invitePlan]);

  useEffect(() => {
    const data: any = {
      plan_id: plan_id,
      status: getPlanStatus,
    };
    getPlanStatus !== "" && dispatch(planStatusHandler(data));
  }, [getPlanStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const AddNewDay = () => {
    if (planDetails?.data?.data[0]?.days.length > 0) {
      const nextNewDay =
        planDetails?.data?.data[0]?.days[
          planDetails?.data?.data[0]?.days.length - 1
        ];
      const data: any = {
        plan_id: plan_id,
        days: {
          date: moment(nextNewDay.date).add(1, "days").format(),
          day: moment(nextNewDay.date).add(1, "days").format("dddd"),
        },
      };
      dispatch(manageDayPlanDetailsHandler(data));
      setTimeout(() => {
        setBottomScroll(true);
      }, 500);
    } else {
      const data: any = {
        plan_id: plan_id,
        days: {
          date: moment(planDetails?.data?.data[0]?.start_date).format(),
          day: moment(planDetails?.data?.data[0]?.start_date).format("dddd"),
        },
      };
      dispatch(manageDayPlanDetailsHandler(data));
      setTimeout(() => {
        setBottomScroll(true);
      }, 500);
    }
  };

  useEffect(() => {
    bottomScroll &&
      ref.current?.scrollIntoView({ bottom: 0, behavior: "smooth" });
    setBottomScroll(false);
  }, [bottomScroll]);

  let scrollToTop = () => {
    bottomToTopRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });

    // setScrollButtonHide(true);
  };

  const updateVenue = (e: any, j: any, k: any, n: any, l: any) => {
    // var star = moment(start_time !== "" ? start_time : k, ["h:mm A"]).format(
    //   "HH:mm"
    // );
    // var en = moment(end_time !== "" ? end_time : n, ["h:mm A"]).format("HH:mm");
    let starHr1: any = moment(start_time !== "" ? start_time : k, [
      "h:mm A",
    ]).format("HH");
    let endHr1: any = moment(start_time !== "" ? start_time : k, [
      "h:mm A",
    ]).format("mm");
    let starHr2: any = moment(end_time !== "" ? end_time : n, [
      "h:mm A",
    ]).format("HH");
    let endHr2: any = moment(end_time !== "" ? end_time : n, ["h:mm A"]).format(
      "mm"
    );
    let start1: any = starHr1 * 3600 + endHr1 * 60;
    let start2: any = starHr2 * 3600 + endHr2 * 60;
    let start: any = start2 - start1;

    const cur1 = moment(moment().format("LT"), "h:mm:ss A").format("HH:mm:ss");
    const curstart = moment(
      start_time !== "" ? start_time : k,
      "h:mm:ss A"
    ).format("HH:mm:ss");
    const curend = moment(end_time !== "" ? end_time : n, "h:mm:ss A").format(
      "HH:mm:ss"
    );

    if (moment(l).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      if (cur1 < curstart && cur1 < curend) {
        if (start_time !== "") {
          if (isNaN(start2)) {
            const finalResult = {
              plan_id: plan_id,
              venue_id: e,
              days_id: j,
              startTime: start_time !== "" ? start_time : k,
              endTime: end_time !== "" ? end_time : n,
            };
            dispatch(updateVenuePlanData(finalResult));
            document
              .querySelectorAll(".dropdown-menu")
              .forEach((ele) => ele?.classList?.remove("show"));
            setStart_Time("");
            setEnd_Time("");
            setError("");
          } else {
            if (start >= 1800) {
              const finalResult = {
                plan_id: plan_id,
                venue_id: e,
                days_id: j,
                startTime: start_time !== "" ? start_time : k,
                endTime: end_time !== "" ? end_time : n,
              };
              dispatch(updateVenuePlanData(finalResult));
              document
                .querySelectorAll(".dropdown-menu")
                .forEach((ele) => ele?.classList?.remove("show"));
              setStart_Time("");
              setEnd_Time("");
              setError("");
            } else {
              setError("Day endtime will be 30 minutes greater starttime");
            }
          }
        } else if (end_time !== "") {
          if (isNaN(start1)) {
            const finalResult = {
              plan_id: plan_id,
              venue_id: e,
              days_id: j,
              startTime: start_time !== "" ? start_time : k,
              endTime: end_time !== "" ? end_time : n,
            };
            dispatch(updateVenuePlanData(finalResult));
            document
              .querySelectorAll(".dropdown-menu")
              .forEach((ele) => ele?.classList?.remove("show"));
            setStart_Time("");
            setEnd_Time("");
            setError("");
          } else {
            if (start >= 1800) {
              const finalResult = {
                plan_id: plan_id,
                venue_id: e,
                days_id: j,
                startTime: start_time !== "" ? start_time : k,
                endTime: end_time !== "" ? end_time : n,
              };
              dispatch(updateVenuePlanData(finalResult));
              document
                .querySelectorAll(".dropdown-menu")
                .forEach((ele) => ele?.classList?.remove("show"));
              setStart_Time("");
              setEnd_Time("");
              setError("");
            } else {
              setError("Day endtime will be 30 minutes greater starttime");
            }
          }
        } else if (k !== "") {
          const finalResult = {
            plan_id: plan_id,
            venue_id: e,
            days_id: j,
            startTime: start_time !== "" ? start_time : k,
            endTime: end_time !== "" ? end_time : n,
          };
          dispatch(updateVenuePlanData(finalResult));
          document
            .querySelectorAll(".dropdown-menu")
            .forEach((ele) => ele?.classList?.remove("show"));
          setStart_Time("");
          setEnd_Time("");
          setError("");
        } else if (n !== "") {
          const finalResult = {
            plan_id: plan_id,
            venue_id: e,
            days_id: j,
            startTime: start_time !== "" ? start_time : k,
            endTime: end_time !== "" ? end_time : n,
          };
          dispatch(updateVenuePlanData(finalResult));
          document
            .querySelectorAll(".dropdown-menu")
            .forEach((ele) => ele?.classList?.remove("show"));
          setStart_Time("");
          setEnd_Time("");
          setError("");
        }
      } else {
        setError("Please select time is greater than current time");
      }
    } else if (moment(l).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
      if (start_time !== "") {
        if (isNaN(start2)) {
          const finalResult = {
            plan_id: plan_id,
            venue_id: e,
            days_id: j,
            startTime: start_time !== "" ? start_time : k,
            endTime: end_time !== "" ? end_time : n,
          };
          dispatch(updateVenuePlanData(finalResult));

          document
            .querySelectorAll(".dropdown-menu")
            .forEach((ele) => ele?.classList?.remove("show"));
          setStart_Time("");
          setEnd_Time("");
          setError("");
        } else {
          if (start >= 1800) {
            const finalResult = {
              plan_id: plan_id,
              venue_id: e,
              days_id: j,
              startTime: start_time !== "" ? start_time : k,
              endTime: end_time !== "" ? end_time : n,
            };
            dispatch(updateVenuePlanData(finalResult));
            document
              .querySelectorAll(".dropdown-menu")
              .forEach((ele) => ele?.classList?.remove("show"));
            setStart_Time("");
            setEnd_Time("");
            setError("");
          } else {
            setError("Day endtime will be 30 minutes greater starttime");
          }
        }
      } else if (end_time !== "") {
        if (isNaN(start1)) {
          const finalResult = {
            plan_id: plan_id,
            venue_id: e,
            days_id: j,
            startTime: start_time !== "" ? start_time : k,
            endTime: end_time !== "" ? end_time : n,
          };
          dispatch(updateVenuePlanData(finalResult));
          document
            .querySelectorAll(".dropdown-menu")
            .forEach((ele) => ele?.classList?.remove("show"));
          setStart_Time("");
          setEnd_Time("");
          setError("");
        } else {
          if (start >= 1800) {
            const finalResult = {
              plan_id: plan_id,
              venue_id: e,
              days_id: j,
              startTime: start_time !== "" ? start_time : k,
              endTime: end_time !== "" ? end_time : n,
            };
            dispatch(updateVenuePlanData(finalResult));
            document
              .querySelectorAll(".dropdown-menu")
              .forEach((ele) => ele?.classList?.remove("show"));
            setStart_Time("");
            setEnd_Time("");
            setError("");
          } else {
            setError("Day endtime will be 30 minutes greater starttime");
          }
        }
      } else if (k !== "") {
        const finalResult = {
          plan_id: plan_id,
          venue_id: e,
          days_id: j,
          startTime: start_time !== "" ? start_time : k,
          endTime: end_time !== "" ? end_time : n,
        };
        dispatch(updateVenuePlanData(finalResult));
        document
          .querySelectorAll(".dropdown-menu")
          .forEach((ele) => ele?.classList?.remove("show"));
        setStart_Time("");
        setEnd_Time("");
        setError("");
      } else if (n !== "") {
        const finalResult = {
          plan_id: plan_id,
          venue_id: e,
          days_id: j,
          startTime: start_time !== "" ? start_time : k,
          endTime: end_time !== "" ? end_time : n,
        };
        dispatch(updateVenuePlanData(finalResult));
        document
          .querySelectorAll(".dropdown-menu")
          .forEach((ele) => ele?.classList?.remove("show"));
        setStart_Time("");
        setEnd_Time("");
        setError("");
      }
    }
  };

  const redirectFun = (e: any) => {
    if (e.venue_type === "rental") {
      navigate(`/rental-result-details/${e.venue_id}`);
    } else if (e.venue_type === "hotel") {
      navigate(`/hotel-result-details/${e.venue_id}`);
    } else if (e.venue_type === "point of interest") {
      navigate(`/interest-details-results/${e.venue_id}`);
    } else if (e.venue_type === "hiking") {
      navigate(`/hiking-result-details/${e.venue_id}`);
    } else if (e.venue_type === "food and drink") {
      navigate(`/food-and-drink-details/${e.venue_id}`);
    } else if (e.venue_type === "concerts") {
      navigate(`/concert-result-details/${e.venue_id}`);
    } else if (e.venue_type === "festival") {
      navigate(`/festival-result-details/${e.venue_id}`);
    } else if (e.venue_type === "sports") {
      navigate(`/sports-results-details/${e.venue_id}`);
    }
  };

  useEffect(() => {
    planDetails?.data?.data?.length !== 0 &&
      planDetails?.data?.data[0]?.Cretordetails?._id !==
        localStorage.getItem("id") &&
      planDetails?.data?.data[0]?.Userdetails?.filter(
        (ele: any) => ele?.id === localStorage.getItem("id")
      ).length === 0 &&
      planDetails?.data?.data[0]?.view_plan !== "Public" &&
      navigate("/myplans");

    planDetails?.data?.data?.length === 0 && navigate("/myplans");
  }, [planDetails]);

  const navigateUserSocialProfile = (ele: any) => {
    navigate(`/socialprofile/${ele?.id}`);
  };

  const navigateToUserProfile = () => {
    navigate(`/myprofile/${planDetails?.data?.data[0]?.user_id}`);
  };

  return (
    <>
      <Header logout={props.logout} />
      {planDetails?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <PlanDetailsTitle ref={bottomToTopRef}>
            <h2>
              {planDetails?.data?.data[0]?.plan_name}
              {planDetails?.data?.data[0]?.Cretordetails?._id ===
                localStorage.getItem("id") && (
                <Button
                  className=""
                  onClick={() => setCreatePlanModalShow(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                  >
                    <g transform="translate(0 0)">
                      <g transform="translate(0 1)">
                        <rect width="24" height="24" fill="none" />
                      </g>
                      <g transform="translate(2.75 1)">
                        <path
                          d="M8.125,26.125H4V22Z"
                          transform="translate(-4 -6.876)"
                          fill="#3b3b3b"
                        />
                        <path
                          d="M0,0,20.533,1.088l.326,6.16L.326,6.16Z"
                          transform="translate(21.25 4.125) rotate(135)"
                          fill="#3b3b3b"
                        />
                      </g>
                    </g>
                  </svg>
                  EDIT PLAN
                </Button>
              )}
            </h2>

            {((planDetails?.data?.data[0]?.invite_other &&
              getUserDetails?.length === 1) ||
              planDetails?.data?.data[0]?.Cretordetails?._id ===
                localStorage.getItem("id")) && (
              <Button
                className="addFriendsBtn"
                onClick={() => {
                  setInviteOthersModalShow(true);
                  setOpenTab({
                    follower: false,
                    group: true,
                    friend: false,
                    email: false,
                  });
                }}
              >
                ADD FRIENDS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <defs>
                    <clipPath>
                      <rect width="21.911" height="21.731" fill="#fff" />
                    </clipPath>
                  </defs>
                  <circle cx="24" cy="24" r="24" fill="#4d27da" />
                  <g transform="translate(13.045 13.134)">
                    <g clip-path="url(#clip-path)">
                      <path
                        d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                        transform="translate(-219.503 -75.638)"
                        fill="#fff"
                      />
                      <path
                        d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                        transform="translate(-19.846 -182.45)"
                        fill="#fff"
                      />
                      <path
                        d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </Button>
            )}
          </PlanDetailsTitle>

          <PlanDetailsWrap>
            <PlanDetailsLeft>
              <PlanDetailsUser>
                <PlanDetailsUserImage>
                  {planDetails?.data?.data[0]?.Cretordetails?._id ===
                    localStorage.getItem("id") && (
                    <Link to={`/myprofile`}>
                      <img
                        src={planDetails?.data?.data[0]?.Cretordetails?.photo}
                        title={planDetails?.data?.data[0]?.Cretordetails?.name}
                      />
                    </Link>
                  )}

                  {getUserDetails?.length === 0 &&
                    planDetails?.data?.data[0]?.Cretordetails?._id !==
                      localStorage.getItem("id") && (
                      <Link to={`/myprofile`}>
                        <img
                          src={planDetails?.data?.data[0]?.Cretordetails?.photo}
                          title={
                            planDetails?.data?.data[0]?.Cretordetails?.name
                          }
                        />
                      </Link>
                    )}

                  {planDetails?.data?.data[0]?.Userdetails?.map(
                    (ele: any) =>
                      ele?.id == localStorage.getItem("id") && (
                        <>
                          <Link to={`/myprofile/${ele.id}`}>
                            <img src={ele?.photo} title={ele?.name} />
                          </Link>
                        </>
                      )
                  )}
                </PlanDetailsUserImage>
                <PlanDetailsUserInfo>
                  <PlanDetailsUserTopInfo>
                    {planDetails?.data?.data[0]?.Cretordetails?._id ===
                      localStorage.getItem("id") && (
                      <h3>
                        {planDetails?.data?.data[0]?.Cretordetails?.name}
                        <CreatorBtn>Creator</CreatorBtn>
                      </h3>
                    )}

                    {getUserDetails?.length === 0 &&
                      planDetails?.data?.data[0]?.Cretordetails?._id !==
                        localStorage.getItem("id") && (
                        <h3>
                          {planDetails?.data?.data[0]?.Cretordetails?.name}
                          <CreatorBtn>Creator</CreatorBtn>
                        </h3>
                      )}

                    {planDetails?.data?.data[0]?.Userdetails?.map(
                      (ele: any) =>
                        ele.id == localStorage.getItem("id") && (
                          <>
                            <h3>{ele?.name}</h3>
                          </>
                        )
                    )}

                    {planDetails?.data?.data[0]?.Cretordetails?._id ===
                      localStorage.getItem("id") &&
                    planDetails?.data?.data[0]?.Userdetails?.length !== 0 ? (
                      <Button
                        type="button"
                        onClick={() => setDeleteGroupModalShow(true)}
                      >
                        Delete Group
                      </Button>
                    ) : getUserDetails?.length === 1 ||
                      (planDetails?.data?.data[0]?.Cretordetails?._id ===
                        localStorage.getItem("id") &&
                        planDetails?.data?.data[0]?.Userdetails?.length ===
                          0) ? (
                      <Button
                        type="button"
                        onClick={() => setExitGroupModal(true)}
                      >
                        Exit Group
                      </Button>
                    ) : (
                      <Button type="button" onClick={() => setPublicPlan(true)}>
                        Join Group
                      </Button>
                    )}
                  </PlanDetailsUserTopInfo>
                  {planDetails?.data?.data[0]?.view_plan === "Private" && (
                    <PlanDetailsNote>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="15.75"
                        viewBox="0 0 12 15.75"
                      >
                        <path
                          d="M17.25,8.484H16.5v-1.5a3.75,3.75,0,1,0-7.5,0v1.5H8.25a1.5,1.5,0,0,0-1.5,1.5v7.5a1.5,1.5,0,0,0,1.5,1.5h9a1.5,1.5,0,0,0,1.5-1.5v-7.5A1.5,1.5,0,0,0,17.25,8.484Zm-4.5,6.825a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12.75,15.309Zm2.325-6.825h-4.65v-1.5a2.325,2.325,0,1,1,4.65,0Z"
                          transform="translate(-6.75 -3.234)"
                          fill="#3b3b3b"
                        />
                      </svg>
                      {` This Plan is ${planDetails?.data?.data[0]?.view_plan} `}
                    </PlanDetailsNote>
                  )}
                  <PlanDetailsDes>
                    {planDetails?.data?.data[0]?.description}
                  </PlanDetailsDes>
                  <PlanDetailsInfoAction>
                    {/* {planDetails?.data?.data[0]?.Cretordetails?._id ===
                    localStorage.getItem("id") ? (
                    ) : (
                      ""
                    )} */}
                    {(getUserDetails?.length === 1 ||
                      planDetails?.data?.data[0]?.Cretordetails?._id ===
                        localStorage.getItem("id")) && (
                      <Button
                        onClick={() => {
                          AddNewDay();

                          // bottomRef?.current?.scrollIntoView();

                          // window.scroll({
                          //   bottom: document.body.scrollHeight,
                          //   left: 0,
                          //   behavior: "smooth",
                          // });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                        >
                          <circle cx="11" cy="11" r="11" fill="#fff" />
                          <path
                            d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                            transform="translate(4.143 13.845)"
                            fill="#4d27da"
                            stroke="#fff"
                            stroke-width="0.5"
                          />
                        </svg>
                        ADD NEW DAY
                      </Button>
                    )}
                  </PlanDetailsInfoAction>
                </PlanDetailsUserInfo>
              </PlanDetailsUser>

              <>
                {planDetails?.data?.data[0]?.days.length > 0
                  ? planDetails?.data?.data[0]?.days?.map(
                      (data: any, index: any) => {
                        return (
                          <DetailsPlanListingWrap>
                            <DetailsPlanListingTitle>
                              <h3>
                                Day {index + 1}
                                <span>{moment(data.date).format("MM/DD")}</span>
                              </h3>
                              <PlanListingTitleBtn>
                                {data.day}
                                {planDetails?.data?.data[0]?.Cretordetails
                                  ?._id === localStorage.getItem("id") ? (
                                  <Button
                                    onClick={() => {
                                      setDeleteDaysModalShow(true);
                                      setDaysId(data._id);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <g transform="translate(-1198.912 -287.912)">
                                        <g transform="translate(-1 1)">
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="12"
                                            transform="translate(1199.912 286.912)"
                                            fill="#3b3b3b"
                                          />
                                          <g transform="translate(1207.449 293.174)">
                                            <g transform="translate(0 0)">
                                              <path
                                                d="M8.138,14.7a1.279,1.279,0,0,0,1.275,1.275h5.1A1.279,1.279,0,0,0,15.789,14.7V7.05H8.138Zm1.569-4.54.9-.9,1.358,1.352,1.352-1.352.9.9-1.352,1.352,1.352,1.352-.9.9-1.352-1.352-1.352,1.352-.9-.9,1.352-1.352Zm4.489-5.024L13.557,4.5H10.369l-.638.638H7.5V6.413h8.927V5.138Z"
                                                transform="translate(-7.5 -4.5)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </PlanListingTitleBtn>
                            </DetailsPlanListingTitle>

                            <DetailsPlanListing ref={ref}>
                              {planDetails?.data?.data[0]?.days[
                                index
                              ].venue.map((final: any, index1: any) => {
                                return (
                                  <li>
                                    <DetailsPlanListingItem>
                                      <PlanListingItemImage>
                                        {/* <Link
                                          to="#"
                                          // onClick={() => {
                                          //   redirectFun(final);
                                          // }}
                                        > */}
                                        <VenueImage>
                                          <img
                                            src={final.Image ?? LasVegas}
                                            alt="las-vegas"
                                            onClick={() => {
                                              redirectFun(final);
                                            }}
                                          />
                                        </VenueImage>
                                        {/* </Link> */}
                                        <Dropdown className="dateDropDown">
                                          {(getUserDetails?.length === 1 ||
                                            planDetails?.data?.data[0]
                                              ?.Cretordetails?._id ===
                                              localStorage.getItem("id")) && (
                                            <Dropdown.Toggle>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                onClick={() => {
                                                  setError("");
                                                  setEnd_Time("");
                                                  setStart_Time("");
                                                }}
                                              >
                                                <circle
                                                  cx="11"
                                                  cy="11"
                                                  r="11"
                                                  fill="#fff"
                                                />
                                                <g transform="translate(-462.297 -416.068)">
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(467.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(472.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                  <circle
                                                    cx="1.5"
                                                    cy="1.5"
                                                    r="1.5"
                                                    transform="translate(477.297 426.069)"
                                                    fill="#3b3b3b"
                                                  />
                                                </g>
                                              </svg>
                                            </Dropdown.Toggle>
                                          )}

                                          <Dropdown.Menu>
                                            <StartEndDate>
                                              <span>Starts</span>
                                              <ul>
                                                <li>
                                                  {data.day.substring(0, 3)},
                                                  {moment(data.date).format(
                                                    "ll"
                                                  )}
                                                </li>
                                                {moment(data.date).format(
                                                  "YYYY-MM-DD"
                                                ) >=
                                                moment().format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <li>
                                                    <DatePicker
                                                      onChange={(e) => {
                                                        setStart_Time(
                                                          moment(e).format("LT")
                                                        );
                                                      }}
                                                      value={
                                                        start_time !== ""
                                                          ? start_time
                                                          : final.starts
                                                      }
                                                      placeholderText={
                                                        "Start Time"
                                                      }
                                                      className="form-control"
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={30}
                                                      dateFormat="LT"
                                                      timeCaption="Time"
                                                    />
                                                    {/* {final.starts} */}
                                                  </li>
                                                ) : (
                                                  <li
                                                  // style={{
                                                  //   width:
                                                  //     moment(
                                                  //       data.date
                                                  //     ).format(
                                                  //       "YYYY-MM-DD"
                                                  //     ) >=
                                                  //     moment().format(
                                                  //       "YYYY-MM-DD"
                                                  //     )
                                                  //       ? ""
                                                  //       : "65px",
                                                  // }}
                                                  >
                                                    {final.starts}
                                                  </li>
                                                )}
                                              </ul>
                                              {moment(data.date).format(
                                                "YYYY-MM-DD"
                                              ) >=
                                              moment().format("YYYY-MM-DD") ? (
                                                ""
                                              ) : (
                                                <DropdownDateNote>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="11.941"
                                                    height="10.99"
                                                    viewBox="0 0 11.941 10.99"
                                                  >
                                                    <defs>
                                                      <clipPath id="clip-path">
                                                        <rect
                                                          width="11.941"
                                                          height="10.99"
                                                          fill="#ffa700"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                    <g clip-path="url(#clip-path)">
                                                      <path
                                                        d="M0,10.371v-.257C.051,10,.1,9.887.155,9.78q2.1-3.819,4.2-7.636.471-.857.942-1.715A.768.768,0,0,1,5.748.036a.755.755,0,0,1,.913.415l4.1,7.452c.359.653.72,1.306,1.077,1.96a.739.739,0,0,1-.174.965.843.843,0,0,1-.558.162H.84c-.05,0-.1,0-.151-.006a.722.722,0,0,1-.649-.506L0,10.371M5.967,3.823a.706.706,0,0,0-.7.785c.024.212.064.422.1.632q.183,1.091.369,2.181a.235.235,0,0,0,.231.22.237.237,0,0,0,.244-.22c.03-.148.053-.3.078-.447.131-.785.268-1.569.39-2.355a.7.7,0,0,0-.71-.8m0,4.3a.714.714,0,1,0,.72.716.719.719,0,0,0-.72-.716"
                                                        transform="translate(0 0)"
                                                        fill="#ffa700"
                                                      />
                                                    </g>
                                                  </svg>
                                                  This event is in the past
                                                </DropdownDateNote>
                                              )}
                                            </StartEndDate>

                                            <StartEndDate>
                                              <span>Ends</span>
                                              <ul>
                                                <li>
                                                  {data.day.substring(0, 3)},
                                                  {moment(data.date).format(
                                                    "ll"
                                                  )}
                                                </li>
                                                {moment(data.date).format(
                                                  "YYYY-MM-DD"
                                                ) >=
                                                moment().format(
                                                  "YYYY-MM-DD"
                                                ) ? (
                                                  <li>
                                                    <DatePicker
                                                      // selected={final.ends}
                                                      onChange={(e) => {
                                                        setEnd_Time(
                                                          moment(e).format("LT")
                                                        );
                                                      }}
                                                      value={
                                                        end_time !== ""
                                                          ? end_time
                                                          : final.ends
                                                      }
                                                      placeholderText={
                                                        "End Time"
                                                      }
                                                      className="form-control"
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={30}
                                                      dateFormat="LT"
                                                      timeCaption="Time"
                                                    />
                                                    {/* {final.ends} */}
                                                  </li>
                                                ) : (
                                                  <li
                                                  // style={{
                                                  //   width:
                                                  //     moment(
                                                  //       data.date
                                                  //     ).format(
                                                  //       "YYYY-MM-DD"
                                                  //     ) >=
                                                  //       moment().format(
                                                  //         "YYYY-MM-DD"
                                                  //       )
                                                  //       ? ""
                                                  //       : "65px",
                                                  // }}
                                                  >
                                                    {final.ends}
                                                  </li>
                                                )}
                                              </ul>
                                            </StartEndDate>
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {error}
                                            </div>
                                            <DropdownAction>
                                              {planDetails?.data?.data[0]
                                                ?.Cretordetails?._id ===
                                              localStorage.getItem("id") ? (
                                                <Button
                                                  className="removeBtn"
                                                  onClick={() => {
                                                    // deleteVenue(final._id);
                                                    setVenueIdDelete(final._id);
                                                    setDeleteVenueModal(true);
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15.429"
                                                    viewBox="0 0 12 15.429"
                                                  >
                                                    <path
                                                      d="M8.357,18.214a1.719,1.719,0,0,0,1.714,1.714h6.857a1.719,1.719,0,0,0,1.714-1.714V7.929H8.357Zm2.109-6.1L11.674,10.9,13.5,12.72,15.317,10.9l1.209,1.209-1.817,1.817,1.817,1.817-1.209,1.209L13.5,15.137l-1.817,1.817-1.209-1.209,1.817-1.817ZM16.5,5.357,15.643,4.5H11.357l-.857.857h-3V7.071h12V5.357Z"
                                                      transform="translate(-7.5 -4.5)"
                                                      fill="#8d8d8d"
                                                    />
                                                  </svg>
                                                  Remove
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                              {moment(data.date).format(
                                                "YYYY-MM-DD"
                                              ) >=
                                              moment().format("YYYY-MM-DD") ? (
                                                <Button
                                                  className="submitBtn"
                                                  onClick={() => {
                                                    updateVenue(
                                                      final._id,
                                                      data._id,
                                                      final.starts,
                                                      final.ends,
                                                      data.date
                                                    );
                                                  }}
                                                >
                                                  SUBMIT
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </DropdownAction>
                                            <DropdownFullBtn>
                                              <Button
                                                onClick={() => {
                                                  setVenueIds(final._id);
                                                  setCheckIds(data._id);
                                                  setCheckDate(data.date);
                                                  setAddDayToAnotherDayPlanModalShow(
                                                    true
                                                  );
                                                  // deleteVenue(final._id);
                                                }}
                                              >
                                                Move day to another day
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  setCopyDayToAnotherDayPlanModalShow(
                                                    true
                                                  );
                                                  setVenueIds(final._id);
                                                  setCheckIds(data._id);
                                                  setCheckDate(data.date);
                                                }}
                                              >
                                                Copy day to another day
                                              </Button>
                                            </DropdownFullBtn>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </PlanListingItemImage>
                                      <PlanListingItemName>
                                        <Link to="#">{final.name}</Link>
                                      </PlanListingItemName>
                                      <PlanListingItemName>
                                        <Link to="#">
                                          {final.location}
                                          {final.province !== undefined
                                            ? `/${final.province}`
                                            : ""}
                                        </Link>
                                      </PlanListingItemName>
                                      {/* <PlanListingItemName>
                                  <Link to="#">Nevada, Las Vegas</Link>
                                </PlanListingItemName> */}
                                      <PlanListingItemDes>
                                        {moment(data.date).format("ll")}{" "}
                                        {final.starts !== null
                                          ? final.starts
                                          : ""}
                                        {final.starts !== null &&
                                        final.ends !== null
                                          ? "-"
                                          : ""}
                                        {final.ends !== null ? final.ends : ""}
                                      </PlanListingItemDes>
                                    </DetailsPlanListingItem>
                                  </li>
                                );
                              })}
                              {/* {scrollButtonHide} */}

                              <li>
                                <PlanListingItemAddBox>
                                  <Link
                                    to={
                                      getUserDetails?.length === 1 ||
                                      planDetails?.data?.data[0]?.Cretordetails
                                        ?._id === localStorage.getItem("id")
                                        ? "/dashboard"
                                        : "#"
                                    }
                                  >
                                    Click here to search for things to do,
                                    places to stay. Then add them to your plans
                                  </Link>
                                </PlanListingItemAddBox>
                              </li>
                            </DetailsPlanListing>
                          </DetailsPlanListingWrap>
                        );
                      }
                    )
                  : "No Days Found"}
              </>

              {planDetails?.data?.data[0]?.days.length >= 3 ? (
                <BackToTop>
                  <button
                    type="submit"
                    onClick={scrollToTop}
                    className="btn"
                    title="Back to Top"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.166"
                      height="10.673"
                      viewBox="0 0 9.166 10.673"
                    >
                      <g transform="translate(-1338.023 -173.24)">
                        <path
                          d="M-9189.215-12227.616l-4.913,4.912,4.913,4.913"
                          transform="translate(10533 12401.28)"
                          fill="none"
                          stroke="#8d8d8d"
                          stroke-width="1.2"
                        />
                        <path
                          d="M-9191.147-12227.612l-2.98,2.977,2.98,2.982"
                          transform="translate(10537.912 12403.21)"
                          fill="none"
                          stroke="#8d8d8d"
                          stroke-width="1.2"
                        />
                      </g>
                    </svg>
                  </button>
                </BackToTop>
              ) : null}
            </PlanDetailsLeft>

            <PlanDetailsRight>
              {planDetails?.data?.data[0]?.Cretordetails?._id ===
                localStorage.getItem("id") && (
                <PlanConnectionInvite>
                  Others can Invite
                  <Form.Check
                    type="switch"
                    label="switch"
                    checked={planDetails?.data?.data[0]?.invite_other}
                    onChange={(e) => inviteOthersHandler(e.target.checked)}
                  />
                </PlanConnectionInvite>
              )}

              <PlanStatusSelect>
                <Dropdown>
                  {planDetails?.data?.data[0]?.Cretordetails?._id ===
                    localStorage.getItem("id") && (
                    <Dropdown.Toggle>
                      {planDetails?.data?.data[0]?.Cretordetails?.status ===
                        "Going" && (
                        <>
                          <img src={CircleSuccessIcon} alt="success-icon" />
                          {planDetails?.data?.data[0]?.Cretordetails?.status}
                        </>
                      )}
                      {planDetails?.data?.data[0]?.Cretordetails?.status ===
                        "Unsure" && (
                        <>
                          <img src={CircleInfoIcon} alt="success-icon" />
                          {planDetails?.data?.data[0]?.Cretordetails?.status}
                        </>
                      )}
                      {planDetails?.data?.data[0]?.Cretordetails?.status ===
                        "Another Time" && (
                        <>
                          <img src={CircleWaitIcon} alt="success-icon" />
                          {planDetails?.data?.data[0]?.Cretordetails?.status}
                        </>
                      )}
                    </Dropdown.Toggle>
                  )}

                  {planDetails?.data?.data[0]?.Userdetails?.map(
                    (ele: any) =>
                      ele?.id === localStorage.getItem("id") && (
                        <Dropdown.Toggle>
                          {ele?.Status[0]?.status === "Going" && (
                            <>
                              <img src={CircleSuccessIcon} alt="success-icon" />
                              {ele?.Status[0]?.status}
                            </>
                          )}
                          {ele?.Status[0]?.status === "Unsure" && (
                            <>
                              <img src={CircleInfoIcon} alt="success-icon" />
                              {ele?.Status[0]?.status}
                            </>
                          )}
                          {ele?.Status[0]?.status === "Another Time" && (
                            <>
                              <img src={CircleWaitIcon} alt="success-icon" />
                              {ele?.Status[0]?.status}
                            </>
                          )}
                        </Dropdown.Toggle>
                      )
                  )}

                  <Dropdown.Menu>
                    <Dropdown.Item as="button">
                      <SelectOption>
                        <OptionRadioBox>
                          <input
                            type="radio"
                            name="status"
                            value="Going"
                            onChange={(e) => setPlanStatus(e.target.value)}
                            checked={
                              planDetails?.data?.data[0]?.Cretordetails
                                ?.status === "Going"
                            }
                          />
                          <span />
                        </OptionRadioBox>
                        <img src={CircleSuccessIcon} alt="success-icon" />
                        GOING
                      </SelectOption>
                    </Dropdown.Item>

                    <Dropdown.Item as="button">
                      <SelectOption>
                        <OptionRadioBox>
                          <input
                            type="radio"
                            name="status"
                            value="Unsure"
                            onChange={(e) => setPlanStatus(e.target.value)}
                            checked={
                              planDetails?.data?.data[0]?.Cretordetails
                                ?.status === "Unsure"
                            }
                          />
                          <span />
                        </OptionRadioBox>
                        <img src={CircleInfoIcon} alt="info-icon" />
                        UNSURE
                      </SelectOption>
                    </Dropdown.Item>

                    <Dropdown.Item as="button">
                      <SelectOption>
                        <OptionRadioBox>
                          <input
                            type="radio"
                            name="status"
                            value="Another Time"
                            onChange={(e) => setPlanStatus(e.target.value)}
                            checked={
                              planDetails?.data?.data[0]?.Cretordetails
                                ?.status === "Another Time"
                            }
                          />
                          <span />
                        </OptionRadioBox>
                        <img src={CircleWaitIcon} alt="wait-icon" />
                        ANOTHER TIME
                      </SelectOption>
                    </Dropdown.Item>

                    {planDetails?.data?.data[0]?.Cretordetails?._id !==
                      localStorage.getItem("id") &&
                      planDetails?.data?.data[0]?.view_plan === "Public" && (
                        <Dropdown.Item as="button">
                          <SelectOption>
                            <OptionRadioBox>
                              <input
                                type="radio"
                                name="status"
                                value="visibility"
                                onClick={() =>
                                  setVisiblePubliclyModalShow(true)
                                }
                              />
                              <span />
                            </OptionRadioBox>
                            <img src={VisibilityIcon} alt="visibility-icon" />
                            VISIBILITY
                          </SelectOption>
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </PlanStatusSelect>

              {planDetails?.data?.data[0]?.Cretordetails._id ==
                localStorage.getItem("id") &&
                planDetails?.data?.data[0]?.Userdetails?.length > 0 &&
                getAllInvitedId.length > 0 && (
                  <GroupMemberRemove>
                    <Button
                      onClick={() => {
                        getAllInvitedId?.length > 0 &&
                          setRemoveInvitedUser(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10.263"
                        height="13.195"
                        viewBox="0 0 10.263 13.195"
                      >
                        <path
                          d="M8.233,16.229A1.47,1.47,0,0,0,9.7,17.7h5.864a1.47,1.47,0,0,0,1.466-1.466v-8.8h-8.8Zm9.53-11H15.2L14.464,4.5H10.8l-.733.733H7.5V6.7H17.763Z"
                          transform="translate(-7.5 -4.5)"
                          fill="#3b3b3b"
                        />
                      </svg>
                      Remove
                    </Button>
                  </GroupMemberRemove>
                )}

              {planDetails?.data?.data[0]?.Cretordetails && (
                <GroupMemberItem>
                  <MemberItemIcon>
                    {planDetails?.data?.data[0]?.Cretordetails?.status ===
                      "Going" && (
                      <>
                        <img src={CircleSuccessIcon} alt="success-icon" />
                      </>
                    )}
                    {planDetails?.data?.data[0]?.Cretordetails?.status ===
                      "Unsure" && (
                      <>
                        <img src={CircleInfoIcon} alt="success-icon" />
                      </>
                    )}
                    {planDetails?.data?.data[0]?.Cretordetails?.status ===
                      "Another Time" && (
                      <>
                        <img src={CircleWaitIcon} alt="success-icon" />
                      </>
                    )}
                  </MemberItemIcon>
                  <img
                    src={planDetails?.data?.data[0]?.Cretordetails?.photo}
                    alt={planDetails?.data?.data[0]?.Cretordetails?.name}
                    onClick={navigateToUserProfile}
                  />

                  <h4 onClick={navigateToUserProfile}>
                    {planDetails?.data?.data[0]?.Cretordetails?.name}
                  </h4>
                  {planDetails?.data?.data[0]?.Cretordetails?._id !==
                    localStorage.getItem("id") && (
                    <Button className="creator-btn">Creator</Button>
                  )}
                </GroupMemberItem>
              )}

              {planDetails?.data?.data[0]?.Userdetails?.map((ele: any) => {
                return (
                  <GroupMemberItem className="selectMember">
                    {planDetails?.data?.data[0]?.Cretordetails?._id ===
                      localStorage.getItem("id") && (
                      <InviteGroupCheckBox>
                        <input
                          type="checkbox"
                          value={ele?.id}
                          checked={getAllInvitedId?.includes(ele?.id)}
                          onChange={(e) =>
                            e.target.checked
                              ? setInvitedId((prev: any) => [
                                  ...prev,
                                  e.target.value,
                                ])
                              : setInvitedId(
                                  getAllInvitedId.filter(
                                    (ele) => ele !== e.target.value
                                  )
                                )
                          }
                        />
                        <span />
                      </InviteGroupCheckBox>
                    )}

                    <MemberItemIcon>
                      {ele?.Status[0]?.status === "Going" && (
                        <img src={CircleSuccessIcon} alt="success-icon" />
                      )}
                      {ele?.Status[0]?.status === "Unsure" && (
                        <img src={CircleInfoIcon} alt="success-icon" />
                      )}
                      {ele?.Status[0]?.status === "Another Time" && (
                        <img src={CircleWaitIcon} alt="success-icon" />
                      )}
                    </MemberItemIcon>

                    <img
                      src={ele?.photo}
                      alt={ele?.name}
                      onClick={() => navigateUserSocialProfile(ele)}
                    />
                    <h4 onClick={() => navigateUserSocialProfile(ele)}>
                      {ele?.name}
                    </h4>
                  </GroupMemberItem>
                );
              })}
            </PlanDetailsRight>
          </PlanDetailsWrap>
        </MainContentPanel>

        <RightTabbingPanel />

        <TabbingPanel className="tabbingPanel" style={{ display: "none" }}>
          <Button onClick={SideTabbingPanel} className="panelToggleBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.156"
              height="10.673"
              viewBox="0 0 9.156 10.673"
            >
              <g transform="translate(-1144.687 -181.733)">
                <path
                  d="M-9189.21-12227.6l-4.913,4.912,4.913,4.913"
                  transform="translate(-8041.129 -12035.617) rotate(180)"
                  fill="none"
                  stroke="#4d27da"
                  stroke-width="1.2"
                />
                <path
                  d="M-9191.15-12227.6l-2.98,2.977,2.98,2.982"
                  transform="translate(-8046.039 -12037.547) rotate(180)"
                  fill="none"
                  stroke="#4d27da"
                  stroke-width="1.2"
                />
              </g>
            </svg>
          </Button>

          <TabsWrap className="GroupDetailsTab">
            <Tabs defaultActiveKey="plansDetails">
              <Tab eventKey="plansDetails" title="Plans Details">
                {/* <TabsContent>Plans Details here...</TabsContent> */}
                <TabsContent>
                  <GroupChatWrap>
                    <GroupChatTitle>
                      <button type="button" className="btn back-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.535"
                          height="14.949"
                          viewBox="0 0 8.535 14.949"
                        >
                          <g transform="translate(1.061 0.53)">
                            <path
                              d="M1432,3924l-6.944,6.944,6.944,6.944"
                              transform="translate(-1425.056 -3924)"
                              fill="none"
                              stroke="#9597a6"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </button>
                      Trip to Vegas
                    </GroupChatTitle>

                    <ul className="chatMessageList">
                      <li>
                        <GroupChatDate>
                          <span>7 Dec</span>
                        </GroupChatDate>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>07:30pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={JasonPamel} alt="jason-pamel" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              OMG, look where Kylie went this weekend !!!
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>07:31pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={SandraJoseph} alt="sandra-joseph" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>WE HAVE TO GO!</ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>07:35pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={PeterJohn} alt="peter-john" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>
                                Stacy suggested Coachella
                                <Link to="#">
                                  https://xd.adobe.com/view....
                                </Link>
                              </p>
                              <ChatBodyAttachment>
                                <img
                                  src={ChatAttachment}
                                  alt="chat-attachment"
                                />
                                <Dropdown>
                                  <Dropdown.Toggle>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" fill="#fff" />
                                      <path
                                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                        transform="translate(2.459 16.62)"
                                        fill="#3b3b3b"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item as="button">
                                      Add to collection
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Add to Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Create Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Share to Chat
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Share With
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </ChatBodyAttachment>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem className="userSendChat">
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">Me</Link>
                            </ChatUserName>
                            <ChatTime>07:36pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={UserImage} alt="user-image" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>I like it !!!</p>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>07:38pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={CandyBrian} alt="candy-brian" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>OMG look where Kylie went</p>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>07:40pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={Kathy} alt="kathy" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>WE HAVE TO GO!</p>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem className="userSendChat">
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">Me</Link>
                            </ChatUserName>
                            <ChatTime>07:55pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={UserImage} alt="user-image" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>
                                Stacy suggested Coachella
                                <Link to="#">
                                  https://xd.adobe.com/view....
                                </Link>
                              </p>
                              <ChatBodyAttachment>
                                <img
                                  src={ChatAttachment}
                                  alt="chat-attachment"
                                />
                                <Dropdown>
                                  <Dropdown.Toggle>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" fill="#fff" />
                                      <path
                                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                        transform="translate(2.459 16.62)"
                                        fill="#3b3b3b"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item as="button">
                                      Add to collection
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Add to Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Create Plans
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Share to Chat
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                      Share With
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </ChatBodyAttachment>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                      <li>
                        <GroupChatItem>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>08:05pm</ChatTime>
                          </GroupChatHead>
                          <GroupChatBody>
                            <ChatUserImage>
                              <Link to="#">
                                <img src={James} alt="james" />
                              </Link>
                            </ChatUserImage>
                            <ChatUserMessage>
                              <p>Stacy suggested Coachella</p>
                            </ChatUserMessage>
                          </GroupChatBody>
                        </GroupChatItem>
                      </li>
                    </ul>

                    <ChatMessageAction>
                      <Form>
                        <FormGroup>
                          <Form.Label>
                            <GroupChatHead>
                              <ChatUserName>
                                <Link to="#">@username</Link>
                              </ChatUserName>
                              <ChatTime>08:05pm</ChatTime>
                            </GroupChatHead>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Type here..."
                          />
                        </FormGroup>
                        <ChatMessageSendBtn>
                          <button type="submit" className="btn planDetailsBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.512"
                              height="11.896"
                              viewBox="0 0 6.512 11.896"
                            >
                              <g transform="translate(1.013 0.504)">
                                <path
                                  d="M1430,3924l-4.944,5.444,4.944,5.444"
                                  transform="translate(-1425.056 -3924)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                            PLAN DETAILS
                          </button>
                          <button type="submit" className="btn">
                            SEND
                          </button>
                        </ChatMessageSendBtn>
                      </Form>
                    </ChatMessageAction>
                  </GroupChatWrap>
                </TabsContent>
              </Tab>

              <Tab eventKey="people" title="People">
                <TabsContent>People Text here...</TabsContent>
              </Tab>
            </Tabs>
          </TabsWrap>
        </TabbingPanel>
      </MyPageWrap>

      <UpdatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        getPlanDetails={planDetails?.data?.data[0]}
        getAllId={getAllId}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
        getUploadBox={getUploadBox}
        setUploadBox={setUploadBox}
      />
      <DeleteGroupModal
        show={deleteGroupModalShow}
        group={planDetails?.data?.data[0]?.Userdetails}
        onHide={() => {
          setDeleteGroupModalShow(false);
          setDeleteGroupId("");
        }}
        setDeleteGroupId={setDeleteGroupId}
        deleteGroupId={deleteGroupId}
        planId={plan_id}
      />
      <DeleteDaysModal
        show={deleteDaysModalShow}
        onHide={() => setDeleteDaysModalShow(false)}
        planId={plan_id}
        daysid={daysId}
      />
      <DeleteVenueModal
        show={deleteVenueModal}
        onHide={() => setDeleteVenueModal(false)}
        planId={plan_id}
        id={venueIdDelete}
      />
      <AnotherDayModal
        show={anotherDayModalShow}
        onHide={() => setAnotherDayModalShow(false)}
      />
      <VisiblePubliclyModal
        show={visiblePubliclyModalShow}
        onHide={() => {
          setVisiblePubliclyModalShow(false);
          setVisibility("");
        }}
        setVisibility={setVisibility}
        getVisibility={getVisibility}
        plan_id={plan_id}
      />
      <ExitGroupModal
        show={getExitgroupModal}
        onHide={() => setExitGroupModal(false)}
        planId={plan_id}
      />
      <RemoveInvitedUser
        show={showRemoveInvitedModal}
        onHide={() => setRemoveInvitedUser(false)}
        plan_id={plan_id}
        getAllInvitedId={getAllInvitedId}
        setInvitedId={setInvitedId}
      />
      <AddDayToAnotherDayModal
        show={addDayToAnotherDayPlanModalShow}
        onHide={() => setAddDayToAnotherDayPlanModalShow(false)}
        planId={plan_id}
        checkId={checkIds}
        checkDate={checkDate}
        days={planDetails?.data?.data[0]?.days}
        venue_id={venueIds}
      />
      <CopyDayToAnotherDayModal
        show={copyDayToAnotherDayPlanModalShow}
        onHide={() => setCopyDayToAnotherDayPlanModalShow(false)}
        planId={plan_id}
        checkId={checkIds}
        checkDate={checkDate}
        days={planDetails?.data?.data[0]?.days}
        venue_id={venueIds}
      />
      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => {
          setInviteOthersModalShow(false);
          setEmailValidate("");
          setEmail("");
        }}
        setInviteOthersModalShow={setInviteOthersModalShow}
        inviteOthersModalShow={inviteOthersModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={plan_id}
        iniviteUser={iniviteUser}
        setinviteUser={setinviteUser}
        invitedUser={planDetails?.data?.data[0]?.Userdetails}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={planDetails?.data?.data[0]?.pending_invite}
        getGroupId={getGroupId}
        setGroupId={setGroupId}
        setGroupMembers={setGroupMembers}
        inviteGroupMembers={inviteGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
      />

      <JoinGroupModal
        show={joinPublicPlan}
        onHide={() => setPublicPlan(false)}
        planId={plan_id}
      />
    </>
  );
};

export default ViewPlanDetails;
