import { addFollowReqAPI } from "../../../services/addFollowRequest";

export enum AddFollowType {
  ADD_FOLLOW_INFO = "ADD_FOLLOW_INFO",
  ADD_FOLLOW_INFO_SUCCESS = "ADD_FOLLOW_INFO_SUCCESS",
  ADD_FOLLOW_INFO_FALIURE = "ADD_FOLLOW_INFO_FALIURE",
}

export const addFollowInfo = () => (dispatch: any) => {
  dispatch({
    type: AddFollowType.ADD_FOLLOW_INFO,
  });
};

export const addFollowInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AddFollowType.ADD_FOLLOW_INFO_SUCCESS,
    data,
  });
};

export const addFollowFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: AddFollowType.ADD_FOLLOW_INFO_FALIURE,
    data: { msg },
  });
};

export const addFollowData = (userIdData: any) => async (dispatch: any) => {
  try {
    dispatch(addFollowInfo());
    const result: any = await addFollowReqAPI(userIdData);
    dispatch(addFollowInfoSuccess(result));
  } catch (e: any) {
    dispatch(addFollowFailed(e.message));
  }
};
