import { getAllCollectionAPI } from "../../../services/getCollection";

export enum getCollection {
  GET_COLLECTION = "GET_COLLECTION",
  GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS",
  GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE",
}

export const getCollectionInfo = () => (dispatch: any) => {
  dispatch({
    type: getCollection.GET_COLLECTION,
  });
};

export const getCollectionInfoSucess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getCollection.GET_COLLECTION_SUCCESS,
    data,
  });
};

export const getCollectionInfoFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getCollection.GET_COLLECTION_FAILURE,
    error: msg,
  });
};

export const getCollectionHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getCollectionInfo());
    const result: any = await getAllCollectionAPI(data);
    dispatch(getCollectionInfoSucess(result));
  } catch (e: any) {
    dispatch(getCollectionInfoFailure(e?.response?.data?.message));
  }
};
