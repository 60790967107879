import { addDayToAnotherDayPlanAPI } from "../../../services/addDayToAnotherDayPlan";

export enum AddDayToAnotherDayPlanType {
  ADD_DAY_TO_ANOTHER_DAY_PLAN = "ADD_DAY_TO_ANOTHER_DAY_PLAN",
  ADD_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS = "ADD_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS",
  ADD_DAY_TO_ANOTHER_DAY_PLAN_FALIURE = "ADD_DAY_TO_ANOTHER_DAY_PLAN_FALIURE",
}

export const addDayToAnotherDayPlan = () => (dispatch: any) => {
  dispatch({
    type: AddDayToAnotherDayPlanType.ADD_DAY_TO_ANOTHER_DAY_PLAN,
  });
};

export const addDayToAnotherDayPlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AddDayToAnotherDayPlanType.ADD_DAY_TO_ANOTHER_DAY_PLAN_SUCCESS,
    data,
  });
};

export const addDayToAnotherDayPlanFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: AddDayToAnotherDayPlanType.ADD_DAY_TO_ANOTHER_DAY_PLAN_FALIURE,
      data: { msg },
    });
  };

export const addDayToAnotherDayPlanHandler =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(addDayToAnotherDayPlan());
      const result: any = await addDayToAnotherDayPlanAPI(finalData);
      dispatch(addDayToAnotherDayPlanSuccess(result));
    } catch (e: any) {
      dispatch(addDayToAnotherDayPlanFailed(e?.response?.data?.message));
    }
  };
