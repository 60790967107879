import { UpdateProfileType } from "../../action/update-profile/updateProfile";
import { updateProfileModel } from "../../models/index";

export const initState: updateProfileModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case UpdateProfileType.UPDATEPROFILE_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case UpdateProfileType.UPDATEPROFILE_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case UpdateProfileType.UPDATEPROFILE_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    case "RESET_UPDATE_PROFILE": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
