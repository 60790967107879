import { StayCurrentLandscape } from "@mui/icons-material";
import { AddFollowType } from "../../action/add-follow-req/addFollow";
import { addFollowReqModel } from "../../models/index";

export const initState: addFollowReqModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case AddFollowType.ADD_FOLLOW_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AddFollowType.ADD_FOLLOW_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case AddFollowType.ADD_FOLLOW_INFO_FALIURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
