import { pointOfInterestType } from "../../action/point-of-interest/pointOfInterest";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case pointOfInterestType.POINT_OF_INTEREST_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case pointOfInterestType.POINT_OF_INTEREST_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
      };
    }
    case pointOfInterestType.POINT_OF_INTEREST_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data,
        data: null,
      };
    }

    case "RESET_POINT_OF_INTEREST_FOOD_AND_DRINKS_DATA": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
