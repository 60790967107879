import { deleteFollow } from "../../../services/deleteFollowRequest";

export enum deleteFollowType {
  DELETE_FOLLOW_TYPE = "DELETE_FOLLOW_TYPE",
  DELETE_FOLLOW_TYPE_SUCCESS = "DELETE_FOLLOW_TYPE_SUCCESS",
  DELETE_FOLLOW_TYPE_FALIURE = "DELETE_FOLLOW_TYPE_FALIURE",
}

export const deleteFollowRequest = () => (dispatch: any) => {
  dispatch({
    type: deleteFollowType.DELETE_FOLLOW_TYPE,
  });
};

export const deleteFollowRequestSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: deleteFollowType.DELETE_FOLLOW_TYPE_SUCCESS,
    data,
  });
};

export const deleteFollowRequestFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: deleteFollowType.DELETE_FOLLOW_TYPE_FALIURE,
    error: msg,
  });
};
export const deleteRequest = (data: any) => async (dispatch: any) => {
  try {
    dispatch(deleteFollowRequest());
    const result: any = await deleteFollow(data);
    dispatch(deleteFollowRequestSuccess(result));
  } catch (e: any) {
    dispatch(deleteFollowRequestFailed(e?.response?.data?.message));
  }
};
