import { removeInvitedUserPlanAPI } from "../../../services/removeInvitedUserPlan";

export enum RemoveInvitedPlanType {
  REMOVE_INVITED_USER_PLAN = "REMOVE_INVITED_USER_PLAN",
  REMOVE_INVITED_USER_PLAN_SUCCESS = "REMOVE_INVITED_USER_PLAN_SUCCESS",
  REMOVE_INVITED_USER_PLAN_FAILURE = "REMOVE_INVITED_USER_PLAN_FAILURE",
}

export const removeInvitedUserPlan = () => (dispatch: any) => {
  dispatch({
    type: RemoveInvitedPlanType.REMOVE_INVITED_USER_PLAN,
  });
};

export const removeInvitedUserPlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: RemoveInvitedPlanType.REMOVE_INVITED_USER_PLAN_SUCCESS,
    data,
  });
};

export const removeInvitedUserPlanFailure =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: RemoveInvitedPlanType.REMOVE_INVITED_USER_PLAN_FAILURE,
      data: { msg },
    });
  };

export const removeInvitedUserPlanHandler =
  (finalValue: any) => async (dispatch: any) => {
    try {
      dispatch(removeInvitedUserPlan());
      const result: any = await removeInvitedUserPlanAPI(finalValue);
      dispatch(removeInvitedUserPlanSuccess(result));
    } catch (e: any) {
      dispatch(removeInvitedUserPlanFailure(e.message));
    }
  };
