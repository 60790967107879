import React, { useEffect, useState } from "react";
import Res from "../resource";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../assets/images/logo.svg";
import { Tabs, Modal, Dropdown } from "react-bootstrap";
import { getNotificationData } from "../redux/action/notification/notification";
import {
  getProfileData,
  getProfileInfoFailed,
} from "../redux/action/get-profile/getProfile";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { io } from "socket.io-client";

import {
  HeaderWrap,
  HeaderContainer,
  HeaderLogo,
  LogoText,
  HeaderRight,
} from "./headerStyle";
// import localStorage from "redux-persist/es/storage";
import { logoutUser } from "../redux/action/signin/user";
import TripdLogo from "../assets/images/tripd-logo.svg";

function Header(props: any) {
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const [countNumber, setCountNumber] = useState(0);
  const [socketConnection, setsocketConnection] = useState(true);
  const [socket, setSocket] = useState<any | null>(null);

  const local: any = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (socket === null && Object.keys(local).length > 0) {
      const socketUrl: any = process.env.REACT_APP_SOCKET_API_KEY;

      setSocket(io(socketUrl));
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(showPosition, showError);
      // }
    }
  }, [socket]);

  const getProfileImage = useSelector((state: any) => state?.getProfile);

  const headerData = localStorage.getItem("token");

  const getNotificationCount = useSelector((state: any) =>
    state?.getAllNotification?.data?.data?.filter((ele: any) => {
      return (
        ele?.notification_type === "Follow Request" ||
        ele?.notification_type === "Friend Request" ||
        ele?.notification_type === "Request To Join Plan" ||
        ele?.notification_type === "Request To Join Group" ||
        ele?.notification_type === "Want To Join Plan"
      );
    })
  );
  const group_data: any = useSelector((state: any) => state.createAllGroup);

  const planList: any = useSelector((state: any) => state?.planListing);

  let temArr: any = [];

  useEffect(() => {
    // group_data?.data?.data[0]?.GroupList.map((item: any) => {
    //   temArr.push(item._id);
    // });
    // const loginId = localStorage.getItem("id");

    // const user_id = loginId;
    // const rooms_id = temArr;

    // socket?.emit("UnreadMsg", { user_id, rooms_id });

    // socket?.on("UnreadMsgCount", (count: any) => {
    //   setCountNumber(count.totalCount);
    // });
    if (group_data?.data?.data[0]?.GroupList) {
      group_data?.data?.data[0]?.GroupList?.map((item: any) => {
        temArr.push(item._id);
      });
    }
    if (planList?.data?.data[0]?.PlanList) {
      planList?.data?.data[0]?.PlanList?.map((item: any) => {
        temArr.push(item._id);
      });
    }
    const loginId = localStorage.getItem("id");
    const user_id = loginId;
    const rooms_id = temArr;
    socket?.emit("UnreadMsg", { user_id, rooms_id });
    socket?.on("UnreadMsgCount", (count: any) => {
      setCountNumber(count.totalCount);
    });
  }, [group_data, planList]);

  // if (socketConnection) {
  //   setTimeout(() => {
  //     setsocketConnection(true);
  //
  //   }, 5000);
  // }

  useEffect(() => {
    if (headerData) {
      dispatch(getProfileData());
    }
  }, [headerData]);

  useEffect(() => {
    if (headerData) {
      dispatch(getNotificationData());
    }
  }, [headerData]);

  const [isToasting, setIsToasting] = useState(false);
  const [isLogOut, setLogOut] = useState(false);

  useEffect(() => {
    if (getProfileImage?.error) {
      dispatch(getProfileInfoFailed(""));
      dispatch(logoutUser());
      setTimeout(() => {
        history("/");
      }, 1000);
    }
  }, [getProfileImage]);

  const myProfile: any = () => {
    history("/myprofile");
  };
  const settings: any = () => {
    history("/settings");
  };
  const invites: any = () => {
    history("/invites");
  };
  const myFeedback: any = () => {
    history("/myfeedback");
  };

  const helpAndFaq: any = () => {
    history("/help-and-faq");
  };

  return (
    <HeaderWrap className={!headerData ? "" : "userHeader"}>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <img src={TripdLogo} alt="Tripd" />
          </Link>
          <LogoText>Connect • Plan • Go</LogoText>
        </HeaderLogo>
        <HeaderRight>
          {!headerData ? (
            <>
              {location.pathname === "/" && (
                <>
                  <span>Don't have an account yet?</span>
                  <button
                    className="btn"
                    onClick={() => history("/signupinvite")}
                  >
                    Signup
                  </button>
                </>
              )}
              {location.pathname === "/terms-and-conditions" && (
                <>
                  <span>Don't have an account yet?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}
              {location.pathname === "/privacy-policy" && (
                <>
                  <span>Don't have an account yet?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}
              {location.pathname === "/about-us" && (
                <>
                  <span>Don't have an account yet?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}

              {(location.pathname === "/signupinvite" ||
                location.pathname.match("/signup")) && (
                <>
                  <span>Already have an account?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}
              {/* 
              {location.pathname.match("/signup") && (
                <>
                  <span>Already have an account?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )} */}

              {location.pathname === "/forgotpassword" && (
                <>
                  <button
                    className="btn"
                    onClick={() => history("/signupinvite")}
                  >
                    Signup
                  </button>
                </>
              )}
              {location.pathname === "/forgotpassword" && (
                <>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}
              {location.pathname === "/" ||
                (location.pathname.match("/reset-password") && (
                  <>
                    <button
                      className="btn"
                      onClick={() => history("/signupinvite")}
                    >
                      Signup
                    </button>
                  </>
                ))}
              {location.pathname === "/" ||
                (location.pathname.match("/reset-password") && (
                  <>
                    <button className="btn" onClick={() => history("/")}>
                      Login
                    </button>
                  </>
                ))}
              {location.pathname === "/accountrequest" && (
                <>
                  <span>Already have an account ?</span>
                  <button className="btn" onClick={() => history("/")}>
                    Login
                  </button>
                </>
              )}
            </>
          ) : location.pathname.match("/signup") ||
            location.pathname.match("/signupinvite") ||
            location.pathname.match("/accountrequest") ? (
            <>
              <span>Already have an account?</span>
              <button className="btn" onClick={() => history("/")}>
                Login
              </button>
            </>
          ) : (
            <>
              <ul>
                <li>
                  <Tippy content="My Plans">
                    <Link to="/myplans">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.863"
                        height="19.867"
                        viewBox="0 0 19.863 19.867"
                      >
                        <path
                          d="M19.309.208A1.254,1.254,0,0,0,18.135.1l-5.3,2.273L7.525.1c.008,0,0,.034.009.042A1.232,1.232,0,0,0,6.546.1L.752,2.585A1.244,1.244,0,0,0,0,3.727v14.9a1.243,1.243,0,0,0,1.242,1.242,1.282,1.282,0,0,0,.49-.1l5.3-2.273,5.306,2.273a1.282,1.282,0,0,0,.49.1,1.239,1.239,0,0,0,.484-.146l.005.047,5.794-2.483a1.244,1.244,0,0,0,.752-1.142V1.243A1.236,1.236,0,0,0,19.309.208ZM2.483,4.547l3.31-1.419v12.2l-3.31,1.419ZM8.277,3.125,11.59,4.547v12.2L8.277,15.324Zm9.107,12.2-3.31,1.419V4.547l3.31-1.419Z"
                          transform="translate(0 -0.002)"
                          fill="#3b3b3b"
                        />
                      </svg>
                    </Link>
                  </Tippy>
                </li>
                <li>
                  <Tippy content="Collections">
                    <Link to="/collection">
                      <svg
                        onClick={() => {
                          props?.setCollectionActive({
                            wantToDo: true,
                            collection: true,
                            FollowingCollection: true,
                            collectionItem: false,
                            followingCollectioItem: false,
                            user_id: "",
                          });
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.141"
                        height="23.145"
                        viewBox="0 0 24.141 23.145"
                      >
                        <g transform="translate(1.25 1.25)">
                          <path
                            d="M14.47,2.784h.006a1.912,1.912,0,0,1,1.719,1.108l0,.008,2.536,5.611c1.3.143,4.1.454,6.121.681a1.9,1.9,0,0,1,1.593,1.3l0,.01a1.911,1.911,0,0,1-.527,1.977l0,0-4.552,4.142c.263,1.272.832,4.022,1.247,6.025a1.9,1.9,0,0,1-2.8,2.03l-5.343-3.045c-1.131.645-3.578,2.04-5.349,3.046a1.893,1.893,0,0,1-2.055-.109l0,0a1.909,1.909,0,0,1-.74-1.912c.41-2,.983-4.756,1.248-6.032L3.022,13.477a1.895,1.895,0,0,1,1.063-3.288l6.122-.679L12.742,3.9a1.9,1.9,0,0,1,1.722-1.116Zm8.782,9.744c-2.538-.283-5.466-.607-5.5-.611l-.708-.078L14.47,6.147l-2.278,5.042-.293.649-6.208.688,4.091,3.725.528.48-.145.7c-.007.034-.608,2.92-1.123,5.417,2.218-1.262,4.78-2.724,4.811-2.741l.619-.353L19.9,22.845c-.522-2.521-1.12-5.416-1.12-5.416l-.144-.7.527-.48Z"
                            transform="translate(-3.649 -4.034)"
                            fill="#3b3b3b"
                          />
                        </g>
                      </svg>
                    </Link>
                  </Tippy>
                </li>
                <li>
                  <Tippy content="Messages">
                    <Link to="/">
                      <>
                        {countNumber > 0 && (
                          <span className="counter">{countNumber}</span>
                        )}
                      </>

                      {/* <span className="counter">{countNumber}</span> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.5"
                        height="22.5"
                        viewBox="0 0 22.5 22.5"
                      >
                        <g transform="translate(-6 -6)">
                          <g transform="translate(6 6)">
                            <path
                              d="M6,6V22.875h6.75V28.5l5.625-5.625H28.5V6ZM26.25,20.625H17.444l-.659.659L15,23.069V20.625H8.25V8.25h18Z"
                              transform="translate(-6 -6)"
                              fill="#3b3b3b"
                            />
                            <circle
                              cx="1.688"
                              cy="1.688"
                              r="1.688"
                              transform="translate(3.938 6.75)"
                              fill="#3b3b3b"
                            />
                            <circle
                              cx="1.688"
                              cy="1.688"
                              r="1.688"
                              transform="translate(9.563 6.75)"
                              fill="#3b3b3b"
                            />
                            <circle
                              cx="1.688"
                              cy="1.688"
                              r="1.688"
                              transform="translate(15.188 6.75)"
                              fill="#3b3b3b"
                            />
                          </g>
                        </g>
                      </svg>
                    </Link>
                  </Tippy>
                </li>
                <li>
                  <Tippy content="Notification">
                    <Link to="/notification">
                      <>
                        {getNotificationCount?.length > 0 && (
                          <span className="counter">
                            {getNotificationCount?.length}
                          </span>
                        )}
                      </>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.022"
                        height="23.017"
                        viewBox="0 0 20.022 23.017"
                      >
                        <path
                          d="M30.142,56.9a.773.773,0,0,0,.081-.569c-.046-.2-.16-.331-.288-.331h-4.65c-.127,0-.242.131-.288.331a.773.773,0,0,0,.081.569c1.441,2.157,3.617,2.157,5.058,0Z"
                          transform="translate(-17.595 -35.501)"
                          fill="#3b3b3b"
                        />
                        <path
                          d="M25.238,22.994H8.716a1.75,1.75,0,0,1-1.725-2.047L7,20.919a1.78,1.78,0,0,1,1.785-1.424.251.251,0,0,0,.207-.117A4.691,4.691,0,0,0,9.6,17.055V11.871a7.374,7.374,0,0,1,5.271-7.06,2.127,2.127,0,0,1,4.209.012,7.484,7.484,0,0,1,5.273,7.2v5.037a4.692,4.692,0,0,0,.613,2.325.25.25,0,0,0,.207.117,1.784,1.784,0,0,1,1.781,1.413l.007.035a1.75,1.75,0,0,1-1.725,2.047Zm-15.046-2H23.759a2.237,2.237,0,0,1-.514-.594l-.008-.014a6.694,6.694,0,0,1-.882-3.329V12.013a5.49,5.49,0,0,0-4.531-5.442l-.734-1.4a.125.125,0,1,0-.227,0l-.749,1.4a5.354,5.354,0,0,0-4.511,5.3v5.181a6.693,6.693,0,0,1-.882,3.327l-.008.014A2.238,2.238,0,0,1,10.191,20.994Z"
                          transform="translate(-6.966 -2.995)"
                          fill="#3b3b3b"
                        />
                      </svg>
                    </Link>
                  </Tippy>
                </li>
                <li className="headerUser">
                  <Dropdown>
                    <Dropdown.Toggle>
                      {
                        <img
                          src={getProfileImage?.data?.data?.photo}
                          alt="user-image"
                        />
                      }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={myProfile}>
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={settings}>Settings</Dropdown.Item>
                      <Dropdown.Item onClick={invites}>Invites</Dropdown.Item>
                      <Dropdown.Item onClick={myFeedback}>
                        Feedback
                      </Dropdown.Item>
                      <Dropdown.Item as="button" onClick={helpAndFaq}>
                        Help/FAQ
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="logout-btn"
                        onClick={() => props.logout()}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </>
          )}
        </HeaderRight>
      </HeaderContainer>
      <ToastContainer autoClose={5000} />
    </HeaderWrap>
  );
}

export default Header;
