import { dynamicCategoryAPI } from "../../../services/dynamicCategory";

export enum DynamicCategoryType {
  DYNAMIC_CATEGORY_INFO = "DYNAMIC_CATEGORY_INFO",
  DYNAMIC_CATEGORY_INFO_SUCCESS = "DYNAMIC_CATEGORY_INFO_SUCCESS",
  DYNAMIC_CATEGORY_INFO_FALIURE = "DYNAMIC_CATEGORY_INFO_FALIURE",
}

export const dynamicCategoryInfo = () => (dispatch: any) => {
  dispatch({
    type: DynamicCategoryType.DYNAMIC_CATEGORY_INFO,
  });
};

export const dynamicCategoryInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: DynamicCategoryType.DYNAMIC_CATEGORY_INFO_SUCCESS,
    data: data,
  });
};

export const dynamicCategoryInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: DynamicCategoryType.DYNAMIC_CATEGORY_INFO_FALIURE,
    data: { msg },
  });
};

export const dynamicCategoryData = () => async (dispatch: any) => {
  try {
    dispatch(dynamicCategoryInfo());
    const result: any = await dynamicCategoryAPI();
    dispatch(dynamicCategoryInfoSuccess(result));
  } catch (e: any) {
    dispatch(dynamicCategoryInfoFailed(e.response.data.message));
  }
};
