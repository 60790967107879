import { shareWithAPI } from "./../../../services/shareWith";

export enum ShareWithType {
  SHARE_WITH_INFO = "SHARE_WITH_INFO",
  SHARE_WITH_INFO_SUCCESS = "SHARE_WITH_INFO_SUCCESS",
  SHARE_WITH_INFO_FALIURE = "SHARE_WITH_INFO_FALIURE",
}

export const shareWithInfo = () => (dispatch: any) => {
  dispatch({
    type: ShareWithType.SHARE_WITH_INFO,
  });
};

export const shareWithInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ShareWithType.SHARE_WITH_INFO_SUCCESS,
    data: data,
  });
};

export const shareWithInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ShareWithType.SHARE_WITH_INFO_FALIURE,
    data: { msg },
  });
};

export const shareWithEmail = (finalValue: any) => async (dispatch: any) => {
  try {
    dispatch(shareWithInfo());
    const result: any = await shareWithAPI(finalValue);
    dispatch(shareWithInfoSuccess(result));
  } catch (e: any) {
    dispatch(shareWithInfoFailed(e.response.data));
  }
};
