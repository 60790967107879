import React, { useRef, useEffect } from "react";
import useGoogleMapsApi from "./useGoogleMapsApi";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import { sendEmailInfo } from "../../redux/action/send-email/sendEmail";
// import { sendEmailInfoData } from "./../../redux/action/send-email/sendEmail";

const LocationInput = (props: any) => {
  const inputRef = useRef<any>();
  const autocompleteRef = useRef<any>();
  const googleMapsApi = useGoogleMapsApi();
  const dispatch: any = useDispatch();

  const latLong: any = useSelector((state: any) => state?.latLong);
  const local: any = JSON.parse(localStorage.getItem("location") || "{}");
  const item: any = JSON.parse(localStorage.getItem("item") || "{}");

  useEffect(() => {
    // dispatch(sendEmailInfoData({ type: "location" }));
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(
        inputRef.current
      );

      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();

        dispatch({ type: "RESET_CURRENT_DATA" });
        props.setPlace(place);
        localStorage.setItem("location", JSON.stringify(place?.name));
        document.querySelector(".dropdown-menu")?.classList.remove("show");
        document.querySelector(".searchLocation")?.classList.remove("show");
        inputRef.current.value = "";
        props.setPageNumber(1);
      });
    }
    if (latLong?.data?.name) {
      props.setPlace(latLong?.data);
    }
  }, [googleMapsApi, latLong?.data?.name]);

  const handleChange = (e: any) => {
    e.preventDefault();
    return false;
  };
  return (
    <>
      <Form.Control
        name="location"
        type="text"
        ref={inputRef}
        placeholder="Enter a location"
        autoComplete="off"
        onChange={handleChange}
        className="form-control"
      />
    </>
  );
};

export default LocationInput;
