import { manageVenuePlanAPI } from "../../../services/manageVenuePlan";

export enum manageVenuePlanType {
  MANAGE_VENUE_PLAN_INFO = "MANAGE_VENUE_PLAN_INFO",
  MANAGE_VENUE_PLAN_INFO_SUCCESS = "MANAGE_VENUE_PLAN_INFO_SUCCESS",
  MANAGE_VENUE_PLAN_INFO_FALIURE = "MANAGE_VENUE_PLAN_INFO_FALIURE",
}

export const manageVenuePlanInfo = () => (dispatch: any) => {
  dispatch({
    type: manageVenuePlanType.MANAGE_VENUE_PLAN_INFO,
  });
};

export const manageVenuePlanDetailsSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: manageVenuePlanType.MANAGE_VENUE_PLAN_INFO_SUCCESS,
    data,
  });
};

export const manageVenuePlanDetailsFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: manageVenuePlanType.MANAGE_VENUE_PLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const manageVenuePlanDetailsHandler =
  (finalData: any) => async (dispatch: any) => {
    try {
      dispatch(manageVenuePlanInfo());
      const result: any = await manageVenuePlanAPI(finalData);
      dispatch(manageVenuePlanDetailsSuccess(result));
    } catch (e: any) {
      dispatch(manageVenuePlanDetailsFailure(e?.response?.data?.message));
    }
  };
