import { planActivityApi } from "../../../services/getPlanActivity";

export enum planActivityType {
  PLAN_ACTIVITY_INFO = "PLAN_ACTIVITY",
  PLAN_ACTIVITY_INFO_SUCCESS = "PLAN_ACTIVITY_INFO_SUCCESS",
  PLAN_ACTIVITY_INFO_FALIURE = "PLAN_ACTIVITY_INFO_FALIURE",
}

export const planActivityInfo = () => (dispatch: any) => {
  dispatch({
    type: planActivityType.PLAN_ACTIVITY_INFO,
  });
};

export const planActivityInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: planActivityType.PLAN_ACTIVITY_INFO_SUCCESS,
    data,
  });
};

export const planActivityInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: planActivityType.PLAN_ACTIVITY_INFO_FALIURE,
    data: { msg },
  });
};

export const planActivityHandler = () => async (dispatch: any) => {
  try {
    dispatch(planActivityInfo());
    const result: any = await planActivityApi();
    dispatch(planActivityInfoSuccess(result));
  } catch (e: any) {
    dispatch(planActivityInfoFailed(e?.response?.data?.message));
  }
};
