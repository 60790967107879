import { getHotelImagesModal } from "../../models/index";
import { getHotelImagesType } from "../../../redux/action/get-hotel-images/getHotelImages";

export const initState: getHotelImagesModal = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getHotelImagesType.GET_HOTEL_IMAGES_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getHotelImagesType.GET_HOTEL_IMAGES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case getHotelImagesType.GET_HOTEL_IMAGES_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }
    case "RESET_REQUEST": {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
