import { ShareWithType } from "../../action/share-with-model/shareWithModel";
import { shareWithModel } from "../../models/index";

export const initState: shareWithModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case ShareWithType.SHARE_WITH_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ShareWithType.SHARE_WITH_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        msg: "",
      };
    }
    case ShareWithType.SHARE_WITH_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data.msg,
        data: null,
      };
    }

    case "RESET_SHAREWITH_MSG": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
