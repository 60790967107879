import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";

import UserImage from "../../../assets/images/user-image.png";

import NoNotification from "../../../assets/images/no-notifications-yet.svg";

import {
  MyPageWrap,
  MainContentPanel,
  ProfileTopMenu,
  TabsContent,
  UserListImage,
  UserTime,
  MainContentPanelWrap,
  NotificationWrap,
  NotificationList,
  NotificationListInner,
  NotificationListInfo,
  PageLoader,
  NotificationNotFound,
} from "./notificationStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";
import ResetPassword from "../auth/ResetPassword";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import Feedback from "react-bootstrap/esm/Feedback";
import { myProfileData } from "../../../redux/action/my-profile/myProfile";
import { addFollowData } from "../../../redux/action/add-follow-req/addFollow";
import { reqFollowData } from "../../../redux/action/req-follow/reqFollow";
import { rejectData } from "../../../redux/action/rejectuser/rejectUser";
import { getNotificationData } from "../../../redux/action/notification/notification";
import { addFollowBackData } from "../../../redux/action/follow-back/followBack";

import { acceptFriendNotificationData } from "../../../redux/action/accept-friend-notification-req/acceptFriendNotificationRequest";
import { declinePlanHandler } from "../../../redux/action/declinePlan/declinePlan";
import { joinPlanHandler } from "../../../redux/action/joinPlan/joinPlan";
import { acceptGroupData } from "../../../redux/action/accept-group-req/acceptGroup";

import moment from "moment";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";

const Notification = (props: any) => {
  const group_data: any = useSelector((state: any) => state.createAllGroup);
  const dispatch: any = useDispatch();

  const getAllNotification: any = useSelector(
    (state: any) => state?.getAllNotification
  );
  const addFollowReqId = useSelector(
    (state: any) => state?.addFollowReq?.data?.data?._id
  );

  const addFollowReqData = useSelector(
    (state: any) => state?.addFollowReq?.data?.data?._id
  );

  const reqFollowHadler = useSelector(
    (state: any) => state?.reqFollow?.data?.data
  );

  const rejectUserHandler = useSelector(
    (state: any) => state?.rejectUser?.data?.data
  );

  const acceptFriendNotification = useSelector(
    (state: any) => state?.acceptFriendNotification?.data?.data
  );

  const acceptGroupReq = useSelector(
    (state: any) => state?.acceptGroup?.data?.data
  );

  const joinPlanInfo = useSelector((state: any) => state?.joinPlan?.data?.data);

  const addFollow: any = addFollowReqId;

  const acceptRequest: any = (id: any, type: any) => {
    const data = {
      id: id,
    };
    type === "Follow Request"
      ? dispatch(reqFollowData(data))
      : dispatch(acceptFriendNotificationData(data));
  };

  const rejectReq: any = (id: any) => {
    dispatch(rejectData(id));
  };
  // Get Notification for add friend

  const acceptPlan = (id: any, notification_type: any) => {
    const data = {
      id: id,
    };
    dispatch(joinPlanHandler(data));
  };

  const acceptGroup = (id: any, notification_type: any) => {
    const data = {
      id: id,
    };
    dispatch(acceptGroupData(data));
    dispatch(getGroupData({ search: "" }));
  };

  useEffect(() => {
    dispatch({ type: "RESET_NOTIFICATION" });
  }, []);

  useEffect(() => {
    dispatch(getNotificationData());
  }, [
    reqFollowHadler,
    rejectUserHandler,
    acceptFriendNotification,
    joinPlanInfo,
    acceptGroupReq,
  ]);

  return (
    <>
      <Header logout={props.logout} />

      {getAllNotification?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainContentPanelWrap>
            <ProfileTopMenu>
              <Link to="/myprofile">MY PROFILE</Link>
              <Link to="/myplans">MY PLANS</Link>
              <Link to="/collection">COLLECTIONS</Link>
              <Link to="/notification" className="active">
                NOTIFICATIONS
              </Link>
              <Link to="/settings">SETTINGS</Link>
              <Link to="/invites">INVITES</Link>
              <Link to="/myfeedback">FEEDBACK</Link>
            </ProfileTopMenu>

            <TabsContent>
              {getAllNotification?.data?.data?.length === 0 && (
                <NotificationNotFound>
                  <img src={NoNotification} alt="no-notifications-yet" />
                  <h3>No notifications yet!</h3>
                  <p>We'll notify you when something arrives!</p>
                </NotificationNotFound>
              )}

              <NotificationWrap>
                {getAllNotification?.data?.data?.map((ele: any) => {
                  return (
                    <>
                      {(ele?.notification_type === "Accepted Follow Request" ||
                        ele?.notification_type === "Accepted Friend Request") &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>
                                        {ele?.user_name}
                                        <br />
                                      </strong>
                                    </Link>
                                    {ele?.notification_type ===
                                    "Accepted Follow Request"
                                      ? " accepted your follow request."
                                      : " accepted your friend request"}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}
                      {(ele?.notification_type === "Accept Follow Request" ||
                        ele?.notification_type === "Accept Friend Request") &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>{ele?.user_name}</strong>
                                    </Link>
                                    {ele?.notification_type ===
                                    "Accept Follow Request"
                                      ? " started following you."
                                      : " added you as a friend."}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}
                      {(ele?.notification_type === "Follow Request" ||
                        ele?.notification_type === "Friend Request") &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>
                                        {ele?.user_name} &nbsp;&nbsp;
                                      </strong>
                                    </Link>
                                    {ele?.notification_type === "Follow Request"
                                      ? " requested to follow you."
                                      : " requested to add you as a friend."}
                                  </h3>
                                  <button
                                    className="btn btn-accept"
                                    onClick={() =>
                                      acceptRequest(
                                        ele?._id,
                                        ele?.notification_type
                                      )
                                    }
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="btn btn-reject"
                                    onClick={() => rejectReq(ele?._id)}
                                  >
                                    Reject
                                  </button>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Accept Group Request" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/myprofile`}>
                                    <img
                                      src={ele?.notification_photo}
                                      alt={ele?.notification_user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/myprofile`}>
                                      <strong>
                                        {ele?.notification_user_name}
                                        &nbsp;&nbsp;
                                      </strong>
                                    </Link>
                                    {"joined group "}
                                    <strong>{ele?.group_name[0]}</strong>
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Accepted Group Request" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>
                                        {ele?.user_name}
                                        &nbsp;&nbsp;
                                      </strong>
                                    </Link>
                                    {"Accepted Group Request"}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Request To Join Plan" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>{ele?.user_name}</strong>
                                    </Link>{" "}
                                    {`invited you to join`}{" "}
                                    {ele?.view_plan === "Public" && (
                                      <Link
                                        to={`/viewplandetails/${ele?.plan_id[0]}`}
                                      >
                                        <strong>{ele?.plan_name[0]}</strong>{" "}
                                      </Link>
                                    )}
                                    {ele?.view_plan !== "Public" && (
                                      <strong>{ele?.plan_name[0]}</strong>
                                    )}
                                  </h3>

                                  <button
                                    className="btn btn-accept"
                                    onClick={() =>
                                      acceptPlan(
                                        ele?._id,
                                        ele?.notification_type
                                      )
                                    }
                                  >
                                    Join
                                  </button>
                                  <button
                                    className="btn btn-reject"
                                    onClick={() => rejectReq(ele?._id)}
                                  >
                                    Decline
                                  </button>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Want To Join Plan" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>{ele?.user_name}</strong>
                                    </Link>{" "}
                                    {`wanted to join`}{" "}
                                    <strong>{ele?.plan_name[0]}</strong>
                                  </h3>
                                  <button
                                    className="btn btn-accept"
                                    onClick={() =>
                                      acceptPlan(
                                        ele?._id,
                                        ele?.notification_type
                                      )
                                    }
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="btn btn-reject"
                                    onClick={() => rejectReq(ele?._id)}
                                  >
                                    Reject
                                  </button>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Accept Join Plan Request" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>{ele?.user_name}</strong>
                                    </Link>{" "}
                                    {`joined`}{" "}
                                    {!ele?.is_deleted &&
                                      (!ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) ||
                                        ele?.view_plan === "Public") && (
                                        <Link
                                          to={`/viewplandetails/${ele?.plan_id[0]}`}
                                        >
                                          <strong>{ele?.plan_name[0]}</strong>{" "}
                                        </Link>
                                      )}
                                    {(ele?.is_deleted ||
                                      (ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) &&
                                        ele?.view_plan !== "Public")) && (
                                      <strong>{ele?.plan_name[0]}</strong>
                                    )}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Accept Plan Request" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/myprofile`}>
                                    <img
                                      src={ele?.notification_photo}
                                      alt={ele?.notification_user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/myprofile`}>
                                      <strong>
                                        {ele?.notification_user_name}
                                      </strong>
                                    </Link>{" "}
                                    {`joined `}{" "}
                                    {!ele?.is_deleted &&
                                      (!ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) ||
                                        ele?.view_plan === "Public") && (
                                        <Link
                                          to={`/viewplandetails/${ele?.plan_id[0]}`}
                                        >
                                          <strong>{ele?.plan_name[0]}</strong>{" "}
                                        </Link>
                                      )}
                                    {(ele?.is_deleted ||
                                      (ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) &&
                                        ele?.view_plan !== "Public")) && (
                                      <strong>{ele?.plan_name[0]}</strong>
                                    )}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}

                      {ele?.notification_type === "Accepted Plan Request" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>
                                        {ele?.user_name}
                                        <br />
                                      </strong>
                                    </Link>
                                    {` accepted your plan `}{" "}
                                    {!ele?.is_deleted &&
                                      (!ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) ||
                                        ele?.view_plan === "Public") && (
                                        <Link
                                          to={`/viewplandetails/${ele?.plan_id[0]}`}
                                        >
                                          <strong>{ele?.plan_name[0]}</strong>{" "}
                                        </Link>
                                      )}
                                    {(ele?.is_deleted ||
                                      (ele?.exit_plan?.includes(
                                        localStorage.getItem("id")
                                      ) &&
                                        ele?.view_plan !== "Public")) && (
                                      <strong>{ele?.plan_name[0]}</strong>
                                    )}
                                    {` request`}
                                  </h3>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}
                      {ele?.notification_type === "Request To Join Group" &&
                        ele?.notification_user_id ===
                          localStorage.getItem("id") && (
                          <>
                            <NotificationList>
                              <NotificationListInner>
                                <UserListImage>
                                  <Link to={`/socialprofile/${ele?.user_id}`}>
                                    <img
                                      src={ele?.photo}
                                      alt={ele?.user_name}
                                    />
                                  </Link>
                                </UserListImage>
                                <NotificationListInfo>
                                  <h3>
                                    <Link to={`/socialprofile/${ele?.user_id}`}>
                                      <strong>
                                        {ele?.user_name}
                                        <br />
                                      </strong>
                                    </Link>
                                    {" Request To Join Group "}
                                    <strong>{ele?.group_name[0]}</strong>
                                  </h3>
                                  <button
                                    className="btn btn-accept"
                                    onClick={() =>
                                      acceptGroup(
                                        ele?._id,
                                        ele?.notification_type
                                      )
                                    }
                                  >
                                    Join
                                  </button>
                                  <button
                                    className="btn btn-reject"
                                    onClick={() => rejectReq(ele?._id)}
                                  >
                                    Decline
                                  </button>
                                  <UserTime>
                                    {moment(ele?.createdAt).fromNow()}
                                  </UserTime>
                                </NotificationListInfo>
                              </NotificationListInner>
                            </NotificationList>
                          </>
                        )}
                    </>
                  );
                })}
              </NotificationWrap>
            </TabsContent>
          </MainContentPanelWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>
    </>
  );
};

export default Notification;
