import { sendInviteAPI } from "../../../services/sendInviteToJoin";

export enum SendInviteType {
  SEND_INVITE_INFO = "SEND_INVITE_INFO",
  SEND_INVITE_INFO_SUCCESS = "SEND_INVITE_INFO_SUCCESS",
  SEND_INVITE_INFO_FALIURE = "SEND_INVITE_INFO_FALIURE",
}

export const sendInviteInfo = () => (dispatch: any) => {
  dispatch({
    type: SendInviteType.SEND_INVITE_INFO,
  });
};

export const sendInviteInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SendInviteType.SEND_INVITE_INFO_SUCCESS,
    data: data,
  });
};

export const sendInviteInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SendInviteType.SEND_INVITE_INFO_FALIURE,
    data: { msg },
  });
};

export const sendInviteData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(sendInviteInfo());
    const result: any = await sendInviteAPI(data);

    dispatch(sendInviteInfoSuccess(result));
  } catch (e: any) {
    dispatch(sendInviteInfoFailed(e.response.data));
  }
};
