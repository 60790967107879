import { useEffect, useState } from "react";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  shareWithEmail,
  shareWithInfoSuccess,
  shareWithInfoFailed,
} from "../../redux/action/share-with-model/shareWithModel";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
} from "../shareWithModal/shareWithModalStyle";

import CustomInput from "../../components/CustomInput";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast, collapseToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareWithModal = (props: any) => {
  const dispatch: any = useDispatch();

  const [isToasting, setIsToasting] = useState(false);

  const shareWith = useSelector((state: any) => state?.shareWithModel);

  const getToast = (type: string) => {
    if (!isToasting) {
      switch (type) {
        case "success":
          setIsToasting(true);
          toast.success("Email sent successfully");
          closeToast();
          break;

        case "error":
          setIsToasting(true);
          toast.error("Email not sent");
          closeToast();
          break;

        default:
          break;
      }
    }
  };

  const closeToast = () => {
    setTimeout(() => {
      setIsToasting(false);
    }, 5000);
  };

  useEffect(() => {
    if (!shareWith.isLoading) {
      if (
        shareWith?.data?.msg === "Email Sent Successfully" &&
        props.show === true
      ) {
        getToast("success");
        dispatch({ type: "RESET_SHAREWITH_MSG" });
        props.onHide();
      } else if (
        shareWith?.msg?.message === "Something went wrong" &&
        props.show === true
      ) {
        getToast("error");
        dispatch({ type: "RESET_SHAREWITH_MSG" });
      }
    }
  }, [shareWith]);

  const submitForm = (values: any) => {
    const finalValue = {
      email: values.email,
      image: props?.sharewithImage ?? "",
      link: props?.link,
      name: props?.name,
      address: props?.address,
    };

    dispatch(shareWithEmail(finalValue));

    // props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="shareWithModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
        .shareWithModal .modal-dialog {
          max-width: calc(100% - 34px);
          width: 560px;
          letter-spacing: 0;
          margin: auto;
          padding: 30px 0;
        }
        .shareWithModal .modal-content {
          background: #FFFFFF;
          border-radius: 15px;
          border: none;
          box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
        }
      `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>SHARE WITH</h2>
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                if (!values.email) {
                  errors.email = "Please enter email address";
                } else if (!regex.test(values.email)) {
                  errors.email = "Please enter valid email address";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Form.Label>Email</Form.Label>

                      <CustomInput
                        inputid="email"
                        // inputlabe="Email"
                        // inputtype="email"
                        placeholder="Email"
                        value={values.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors.email}
                        touched={touched.email}
                      />
                    </FormGroup>
                    <ModalBodyBtn>
                      <Button className="btn" type="submit">
                        SEND
                      </Button>
                    </ModalBodyBtn>
                    <ToastContainer autoClose={5000} />
                  </form>
                </>
              )}
            </Formik>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShareWithModal;
