import { getSportsEvent } from "../../../services/getSportsEvent";

export enum getSportsEventType {
  GET_SPORTS_EVENT_INFO = "GET_RENTAL_IMAGES_INFO",
  GET_SPORTS_EVENT_INFO_SUCCESS = "GET_RENTAL_IMAGES_INFO_SUCCESS",
  GET_SPORTS_EVENT_INFO_FALIURE = "GET_RENTAL_IMAGES_INFO_FALIURE",
}

export const getSportsEventInfo = () => (dispatch: any) => {
  dispatch({
    type: getSportsEventType.GET_SPORTS_EVENT_INFO,
  });
};

export const getSportsEventInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getSportsEventType.GET_SPORTS_EVENT_INFO_SUCCESS,
    data,
  });
};

export const getSportsEventInfoInfoFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: getSportsEventType.GET_SPORTS_EVENT_INFO_FALIURE,
      error: msg,
    });
  };

export const getSportsListData: any = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getSportsEventInfo());
    const result: any = await getSportsEvent(data);
    dispatch(getSportsEventInfoSuccess(result));
  } catch (e: any) {
    dispatch(getSportsEventInfoInfoFailed(e?.response?.data?.message));
  }
};
