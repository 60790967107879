import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

function ProtectedRoute(props: any) {
  let { path, data, component: Component, logout } = props;

  return (
    <Routes>
      <Route
        path={path}
        element={data ? <Component logout={logout} /> : <Navigate to="/" />}
      ></Route>
    </Routes>
  );
}

export default ProtectedRoute;
