import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  padding: 35px 17px 15px 35px;
  border-left: 5px solid #f0f0f0;
  border-right: 5px solid #f0f0f0;
  background: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }

  @media (max-width: 1199px) {
    border-left: none;
    border-right: none;
  }
  @media (max-width: 767px) {
    padding: 15px;
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 20px 0 0;

  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: 1px solid #cecece;
    padding: 0 10px;
    display: flex;
    margin: 0;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 17px 10px 15px;
      min-width: 77px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  &.GroupDetailsTab {
    // .nav-tabs {
    //   .nav-link {
    //     &:before {
    //       display: none;
    //     }
    //   }
    // }
    ${TabsContent} {
      padding: 15px 24px;
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const ModalMemberGroupList = styled.div`
  margin: 27px auto 0;
  width: 274px;
  max-width: 100%;
`;

export const ModalMemberGroupItem = styled.div`
  position: relative;
  padding-right: 30px;
  margin-bottom: 17px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  border-radius: 30px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const SocialProfileWrap = styled.div`
  padding: 15px 35px 0 0;
  @media (max-width: 1400px) {
    padding: 15px 0 0 0;
  }
`;

export const SocialProfileTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
export const UserProfileImage = styled.div`
  width: 96px;
  min-width: 96px;
  border-radius: 100px;
  @media (max-width: 565px) {
    width: 68px;
    min-width: 68px;
  }
  img {
    max-width: 100%;
    border-radius: 100px;
    height: 96px;
    width: 96px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
    @media (max-width: 565px) {
      width: 68px;
      height: 68px;
    }
  }
`;
export const SocialProfileInfo = styled.div`
  padding-left: 15px;
  flex: 55%;
  max-width: 55%;
  @media (max-width: 1300px) {
    flex: 50%;
    max-width: 50%;
  }
  @media (max-width: 1199px) {
    flex: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  @media (max-width: 565px) {
    flex: calc(100% - 68px);
    max-width: calc(100% - 68px);
    padding-left: 10px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      flex: auto;
      max-width: inherit;
      text-align: center;
      padding: 0 30px;
      @media (max-width: 565px) {
        padding: 0 12px;
      }
      &:nth-child(2) {
        border-left: 1px solid #a1a3b0;
        border-right: 1px solid #a1a3b0;
      }
      &:first-child {
        padding-right: 30px;
        padding-left: 0;
        @media (max-width: 565px) {
          padding-right: 12px;
        }
      }
      &:last-child {
        padding-left: 30px;
        padding-right: 0;
        @media (max-width: 565px) {
          padding-left: 12px;
        }
      }
      .btn {
        background: transparent;
        border-radius: 0;
        box-shadow: none !important;
        padding: 0;
        border: none;
        cursor: pointer;
      }
      .profile-number {
        display: block;
        color: #3b3b3b;
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        cursor: pointer;
        @media (max-width: 565px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      label {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #a1a3b0;
        margin: 5px 0 0;
        cursor: pointer;
        @media (max-width: 565px) {
          font-size: 13px;
          line-height: 17px;
        }
      }
    }
  }
`;
export const SocialUserName = styled.h3`
  font-family: "Poppins";
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 15px;
  @media (max-width: 565px) {
    font-size: 15px;
    line-height: 18px;
  }
`;
export const AboutUser = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 5px;
  margin-top: 15px;
`;

export const UserEmail = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #4d27da;
  margin-bottom: 5px;
`;

export const UserAction = styled.div`
  max-width: calc(100% - (55% + 96px));
  flex: calc(100% - (55% + 96px));
  text-align: right;
  @media (max-width: 1300px) {
    max-width: calc(100% - (50% + 96px));
    flex: calc(100% - (50% + 96px));
  }
  @media (max-width: 1199px) {
    max-width: 100%;
    flex: 100%;
    padding-left: 111px;
    text-align: left;
    margin-top: 15px;
  }
  @media (max-width: 565px) {
    padding-left: 78px;
  }
  @media (max-width: 370px) {
    padding-left: 0;
    text-align: center;
  }
  .btn-fill {
    border-radius: 8px;
    background: #4d27da;
    border: 1px solid #4d27da;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 105px;
    padding: 8px 15px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: transparent;
      color: #4d27da;
    }
  }
  .btn-border {
    border-radius: 8px;
    background: transparent;
    border: 1px solid #4d27da;
    color: #4d27da;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    min-width: 105px;
    padding: 8px 15px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #4d27da;
      color: #ffffff;
    }
    @media (max-width: 565px) {
      min-width: 48%;
      font-size: 12px;
      line-height: 15px;
      padding: 6px 12px;
    }
  }
  .btn {
    & + .btn {
      margin-left: 20px;
      @media (max-width: 1400px) {
        margin-left: 10px;
      }
      @media (max-width: 1199px) {
        margin-left: 20px;
      }
      @media (max-width: 565px) {
        margin-left: 10px;
      }
    }
  }
`;

export const SocialTabsWrap = styled.div`
  width: 570px;
  max-width: 100%;
  margin-top: 35px;
  // @media (max-width: 1300px) {
  //   max-width: 80%;
  // }
  // @media (max-width: 1199px) {
  //   max-width: 100%;
  // }
  .nav-tabs {
    border-bottom: none;
    max-width: 66%;
    margin: 0 auto;
    @media (max-width: 1300px) {
      max-width: 75%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
      padding: 0;
    }
    .nav-item {
      .nav-link {
        font-size: 16px;
        line-height: 25px;
        padding: 8px 4px;
        @media (max-width: 565px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
`;

export const SocialTabInner = styled.div`
  ${FormGroup} {
    margin-bottom: 0;
  }
  .form-control {
    border-radius: 5px;
    background: #f5f5f5;
    height: 45px;
    border: none;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .searchIconBtn {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    position: absolute;
    right: 17px;
    top: 12px;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
    path {
      fill: #3b3b3b;
    }
    &:hover {
      path {
        fill: #4d27da;
      }
    }
  }
`;

export const FollowList = styled.div`
  padding: 30px 30px 0;
  @media (max-width: 767px) {
    padding: 20px 20px 0;
  }
  @media (max-width: 565px) {
    padding: 20px 10px 0;
  }
  ul {
    &:empty {
      display: none;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .btn-border {
        border: 1px solid #a1a3b0;
        border-radius: 8px;
        background: transparent;
        padding: 8px 23px;
        min-width: 105px;
        color: #a1a3b0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        box-shadow: none !important;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #ffffff;
          background: #3b3b3b;
          border-color: #3b3b3b;
        }
        @media (max-width: 600px) {
          padding: 6px 12px;
          min-width: 90px;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .btn-border-color {
        border: 1px solid #4d27da;
        border-radius: 8px;
        background: transparent;
        padding: 8px 23px;
        min-width: 105px;
        color: #4d27da;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        box-shadow: none !important;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #ffffff;
          background: #4d27da;
        }
        @media (max-width: 600px) {
          padding: 6px 12px;
          min-width: 90px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const FollowListLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const FollowImage = styled.div`
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

export const FollowName = styled.h4`
  color: #8d8d8d;
  font-family: "Poppins";
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  padding-left: 15px;
`;
