import { updateCollectionAPI } from "../../../services/updateCollection";

export enum updateCollectionType {
  UPDATE_COLLECTION = "UPDATE_COLLECTION",
  UPDATE_COLLECTION_SUCESS = "UPDATE_COLLECTION_SUCESS",
  UPDATE_COLLECTION_FAILURE = "UPDATE_COLLECTION_FAILURE",
}

export const updateCollection = () => (dispatch: any) => {
  dispatch({
    type: updateCollectionType.UPDATE_COLLECTION,
  });
};

export const updateCollectionSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: updateCollectionType.UPDATE_COLLECTION_SUCESS,
    data,
  });
};

export const updateCollectionFaliure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: updateCollectionType.UPDATE_COLLECTION_FAILURE,
    data: { msg },
  });
};

export const updateCollectionHandler =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(updateCollection());
      const result: any = await updateCollectionAPI(data);
      dispatch(updateCollectionSuccess(result));
    } catch (e: any) {
      dispatch(updateCollectionFaliure(e.response.data.message));
    }
  };
