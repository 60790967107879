import { searchFollowingModel } from "../../models/index";
import { SearchFollowingType } from "../../action/search-following/searchFollowing";

export const initState: searchFollowingModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case SearchFollowingType.SEARCH_FOLLOWING_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case SearchFollowingType.SEARCH_FOLLOWING_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case SearchFollowingType.SEARCH_FOLLOWING_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
