import { planStatusAPI } from "../../../services/planStatus";

export enum planStatusType {
  PLAN_STATUS_INFO = " PLAN_STATUS_INFO",
  PLAN_STATUS_INFO_SUCCESS = "PLAN_STATUS_INFO_SUCCESS",
  PLAN_STATUS_INFO_FAILURE = "PLAN_STATUS_INFO_FAILURE",
}

export const planStatusInfo = () => (dispatch: any) => {
  dispatch({
    type: planStatusType.PLAN_STATUS_INFO,
  });
};

export const planStatusInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: planStatusType.PLAN_STATUS_INFO_SUCCESS,
    data,
  });
};

export const planStatusInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: planStatusType.PLAN_STATUS_INFO_FAILURE,
    data: { msg },
  });
};

export const planStatusHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(planStatusInfo());
    const result: any = await planStatusAPI(data);
    dispatch(planStatusInfoSuccess(result));
  } catch (e: any) {
    dispatch(planStatusInfoFailed(e?.response?.data?.message));
  }
};
