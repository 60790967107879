import { getUserProfileAPI } from "./../../../services/getUserProfile";

export enum getUserProfileType {
  GET_USER_PROFILE_INFO = "GET_USER_PROFILE_INFO",
  GET_USER_PROFILE_INFO_SUCCESS = "GET_USER_PROFILE_INFO_SUCCESS",
  GET_USER_PROFILE_INFO_FALIURE = "GET_USER_PROFILE_INFO_FALIURE",
}

export const getUserProfileInfo = () => (dispatch: any) => {
  dispatch({
    type: getUserProfileType.GET_USER_PROFILE_INFO,
  });
};

export const getUserProfileInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getUserProfileType.GET_USER_PROFILE_INFO_SUCCESS,
    data,
  });
};

export const getUserProfileInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getUserProfileType.GET_USER_PROFILE_INFO_FALIURE,
    error: msg,
  });
};
export const getUserProfile = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getUserProfileInfo());
    const result: any = await getUserProfileAPI(id);
    dispatch(getUserProfileInfoSuccess(result));
  } catch (e: any) {
    dispatch(getUserProfileInfoFailed(e?.response?.data?.error));
  }
};
