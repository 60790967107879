import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Res from "../../../resource";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../../assets/images/logo.svg";
import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ProfileUnderReview from "../../../assets/images/profile-under-review.png";

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  AuthBoxTitle,
  FormLink,
  AccountRequestWrap,
  AccountRequestInner,
} from "./authStyle";
import { Formik } from "formik";

import { forgotPasswordDataAPI } from "../../../redux/action/forgotPassword/forgotPwd";
import { useDispatch, useSelector } from "react-redux";

function AccountRequest() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const forgotData = useSelector((state: any) => state?.forgotPasword);

  const submitForm = async (values: any) => {
    await dispatch(forgotPasswordDataAPI(values));
    if (forgotData?.data?.status_code === 200) {
      navigate("/resetpassword");
    }
  };

  return (
    <>
      <AuthWrap>
        <Header />
        <AccountRequestWrap>
          <AccountRequestInner>
            <Link to="/">
              <img src={Logo} alt="Tripd" />
            </Link>
            <h3>Thanks For Request to</h3>
            <p>Once approved you will receive an email</p>
          </AccountRequestInner>
        </AccountRequestWrap>
        <Footer />
      </AuthWrap>
    </>
  );
}

export default AccountRequest;
