import { getAllFoodDrinks } from "../../../services/getAllFoodDrinks";

export enum getAllFoodDrinkType {
  GET_FOODDRINKS_INFO = "GET_FOODDRINKS_INFO",
  GET_FOODDRINKS_INFO_SUCCESS = "GET_FOODDRINKS_INFO_SUCCESS",
  GET_FOODDRINKS_INFO_FALIURE = "GET_FOODDRINKS_INFO_FALIURE",
}

export const getFoodDrinksInfo = () => (dispatch: any) => {
  dispatch({
    type: getAllFoodDrinkType.GET_FOODDRINKS_INFO,
  });
};

export const getFoodDrinksInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getAllFoodDrinkType.GET_FOODDRINKS_INFO_SUCCESS,
    data,
  });
};

export const getFoodDrinksInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getAllFoodDrinkType.GET_FOODDRINKS_INFO_FALIURE,
    error: msg,
  });
};

export const getFoodDrinksHandler =
  ({ page, limit }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(getFoodDrinksInfo());
      const result: any = await getAllFoodDrinks({ page, limit });
      dispatch(getFoodDrinksInfoSuccess(result));
    } catch (e: any) {
      dispatch(getFoodDrinksInfoFailed(e?.response?.data?.message));
    }
  };
