import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  overflow: hidden;
  position: relative;
  display: flex;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  background: #ffffff;
  margin: 0 5px;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  @media (max-width: 1199px) {
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
`;

export const ProfileTopMenu = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #cecece;
  padding: 22px 20px 0;
  margin: 0;
  justify-content: space-between;
  display: flex;
  z-index: 3;

  @media (max-width: 565px) {
    overflow-x: scroll;
    width: calc(100vw - 6px);
  }
  a,
  button {
    margin: 0;
    border-radius: 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    position: relative;
    padding: 14px 0 14px;
    text-align: center;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    transition: all 0.35s ease 0s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      width: 0;
      margin: auto;
      background: #6840df;
      opacity: 0;
      transition: all 0.35s ease 0s;
    }
    &:hover {
      color: #6840df;
    }
    &.active {
      color: #6840df;
      font-size: 16px;
      &:before {
        opacity: 1;
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    @media (max-width: 600px) {
      font-size: 12px;
    }
    @media (max-width: 565px) {
      min-width: max-content;
      margin: 0 8px;
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  @media (max-width: 767px) {
    padding: 32px 15px;
  }
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const UserListImage = styled.div`
  width: 48px;
  min-width: 48px;
  text-align: center;
  img {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
  }
  a {
    display: linline-block;
  }
`;

export const UserTime = styled.div`
  font-family: "Poppins";
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  color: #8d8d8d;
  letter-spacing: 0px;
  margin: 7px 0 0;
`;

export const MainContentPanelWrap = styled.div`
  padding-top: 0;
  .nav-tabs {
    .nav-link {
      padding: 14px 10px 14px;
      font-size: 14px;
      &.active {
        color: #6840df;
      }
    }
  }
`;

export const NotificationWrap = styled.div`
  width: 490px;
  max-width: 100%;
  @media (max-width: 576px) {
    width: 100%;
  }
  &:empty {
    display: none;
  }
`;

export const NotificationList = styled.div`
  padding: 20px 10px;
  border-bottom: 1px solid #cecece;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
`;

export const NotificationListInfo = styled.div`
  padding-left: 15px;
  h3 {
    color: #3b3b3b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0;
    strong {
      font-weight: 700;
    }
    svg {
      margin: 0 8px;
    }
    // @media (max-width:767px){
    //   br{
    //     display:none;
    //   }
    // }

    a {
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
    }
  }
  .btn-accept {
    color: #48d85e;
    border: 1px solid #48d85e;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 13px;
    margin-top: 10px;
    margin-right: 15px;
    min-width: 78px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #ffffff;
      background: #48d85e;
    }
  }
  .btn-reject {
    color: #ef3939;
    border: 1px solid #ef3939;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 13px;
    margin-top: 10px;
    min-width: 78px;
    box-shadow: none !important;
    transition: all 0.35s ease 0s;
    &:hover {
      color: #ffffff;
      background: #ef3939;
    }
  }
  .unsure {
    color: #ffa700;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
  .going {
    color: #159a43;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
`;

export const NotificationListInner = styled.div`
  display: flex;
  align-items: center;
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;

export const NotificationNotFound = styled.div`
  font-family: "Poppins";
  color: #3b3b3b;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  min-height: calc(100vh - 240px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  p {
    margin: 0;
  }
  img {
    width: 335px;
    max-width: 100%;
    margin: 0 auto 20px;
    vertical-align: middle;
  }
  h3 {
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    margin: 0 0 12px;
    text-align: center;
  }
`;
