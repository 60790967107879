import { useState, useEffect } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCollectionHandler } from "../../redux/action/addCollection/addCollection";
import { GlobalDataType } from "../../redux/action/globalData";

import CollectionsImage1 from "../../assets/images/collections-image-1.png";
import CollectionsImage2 from "../../assets/images/collections-image-2.png";
import CollectionsImage3 from "../../assets/images/collections-image-3.png";
import CollectionsImage4 from "../../assets/images/collections-image-4.png";

import {
  FormGroup,
  ModalBody,
  FriendItem,
  InviteGroupRadioBox,
  InviteGroupItem,
  BtnWrap,
  AddCollectionWrap,
  UploadImageBox,
  VisibilityGroup,
  PageLoader,
} from "../addCollectionModal/addCollectionModalStyle";
import PageLoaderIcon from "../../assets/images/page-loader.svg";

import CustomInput from "../CustomInput";
import { Formik, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getOneCollectionHandler } from "../../redux/action/getOneCollection/getOneCollection";
import { updateCollectionHandler } from "../../redux/action/updateCollection/updateCollection";

const AddCollectionModal = (props: any) => {
  const dispatch: any = useDispatch();
  const history = useNavigate();

  const submitForm = (values: any) => {
    if (
      !props?.AddCollectionModalShow?.collection_id &&
      !props.getRentalDetails
    ) {
      var formData = new FormData();
      formData.append("collection_name", values.collection_name.trim());
      formData.append("visibility", values.visibility.trim());
      formData.append("photo", props.getImage);
      dispatch(addCollectionHandler(formData));
    } else if (props?.AddCollectionModalShow?.collection_id) {
      var formData = new FormData();
      formData.append("collection_name", values.collection_name.trim());
      formData.append("visibility", values.visibility.trim());
      props.showimage && formData.append("photo", props.getImage);
      formData.append(
        "collection_id",
        props?.AddCollectionModalShow?.collection_id
      );
      dispatch(updateCollectionHandler(formData));
    } else if (
      props.getRentalDetails &&
      (props?.getRentalDetails?.venue_type === "rental" ||
        props?.getRentalDetails?.venue_type === "concerts" ||
        props?.getRentalDetails?.venue_type === "festival" ||
        props?.getRentalDetails?.venue_type === "sports" ||
        props?.getRentalDetails?.venue_type === "hotel")
    ) {
      var formData = new FormData();
      formData.append("collection_name", values.collection_name.trim());
      formData.append("visibility", values.visibility.trim());
      formData.append("photo", props?.getImage);
      // formData.append("items", props?.getRentalDetails);
      formData.append("venue_type", props?.getRentalDetails?.venue_type);
      formData.append("venue_id", props?.getRentalDetails?.venue_id);
      dispatch(addCollectionHandler(formData));
      history("/collection");
    } else if (
      props.getRentalDetails &&
      (props?.getRentalDetails?.venue_type === "food and drink" ||
        props?.getRentalDetails?.venue_type === "point of interest" ||
        props?.getRentalDetails?.venue_type === "hiking")
    ) {
      var formData = new FormData();
      formData.append("collection_name", values.collection_name.trim());
      formData.append("visibility", values.visibility.trim());
      formData.append("photo", props?.getImage);
      // formData.append("items", props?.getRentalDetails);
      formData.append("venue_type", props?.getRentalDetails?.venue_type);
      formData.append("venue_id", props?.getRentalDetails?.venue_id);
      formData.append("name", props?.getRentalDetails?.name);
      formData.append("location", props?.getRentalDetails?.location);
      formData.append("province", props?.getRentalDetails?.province);
      formData.append("photo", props?.getRentalDetails?.Image);
      dispatch(addCollectionHandler(formData));
      history("/collection");
    }
    props.setShowImage("");
    props.setImageValidation("");
    props.setImage("");
    props.onHide();
    props.setUploadBox(false);
  };

  useEffect(() => {
    const data = {
      collection_id: props?.AddCollectionModalShow?.collection_id,
    };
    props?.AddCollectionModalShow?.collection_id &&
      dispatch(getOneCollectionHandler(data));
  }, [props?.AddCollectionModalShow?.collection_id]);

  const getOneCollection = useSelector((state: any) => state?.getOneCollection);

  const imageUploader = async (e: any) => {
    props.setImageValidation("");
    props.setImage(e.target.files[0]);
    const file = e.target.files[0];

    if (!file.type.includes("image")) {
      props.setImageValidation(
        "Uploaded file is not a valid image. Only PNG, JPG and JPEG files are allowed"
      );
    } else if (!(file.size <= 5242880)) {
      props.setImageValidation(
        "Image size is greater then 5 MB. Please upload image below 5 MB"
      );
    } else {
      const base64: any = await convertBase64(file);
      props.setShowImage(base64);
      props.setUploadBox(true);
    }
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      {getOneCollection?.isLoading && (
        <PageLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </PageLoader>
      )}

      <Modal
        {...props}
        size="lg"
        className="addCollectionModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .addCollectionModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 501px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .addCollectionModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>
              {props?.AddCollectionModalShow?.collection_id
                ? "UPDATE COLLECTION"
                : "ADD COLLECTION"}
            </h2>
            <AddCollectionWrap>
              <Formik
                enableReinitialize
                initialValues={{
                  collection_name: getOneCollection?.data?.data?.name ?? "",
                  visibility:
                    getOneCollection?.data?.data?.visibility ?? "Private",
                }}
                validate={(values: any) => {
                  let errors: any = {};

                  if (!values.collection_name.trim()) {
                    errors.collection_name = "Please enter collection name";
                  }

                  return errors;
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      {!props.showimage && !props.getUploadBox && (
                        <UploadImageBox>
                          <Form.Control
                            type="file"
                            name="photo"
                            onChange={imageUploader}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                            <path
                              d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                              transform="translate(2.459 16.62)"
                              fill="#3b3b3b"
                            ></path>
                          </svg>
                          <span className="uploader-title">
                            Add Cover Image
                          </span>
                        </UploadImageBox>
                      )}

                      {props.getUploadBox &&
                        (props.showimage ||
                          getOneCollection?.data?.data?.cover_photo) && (
                          <UploadImageBox className="imageUploaded">
                            <img
                              src={
                                props.showimage ||
                                getOneCollection?.data?.data?.cover_photo
                              }
                            />
                            <button
                              onClick={() => {
                                props.setShowImage("");
                                props.setImageValidation("");
                                props.setImage("");
                                props.setUploadBox(false);
                              }}
                              className="deleteBtn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#3b3b3b"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </UploadImageBox>
                        )}

                      {props.imageValidation && (
                        <label className="error">{props.imageValidation}</label>
                      )}

                      <FormGroup>
                        <Form.Control
                          type="text"
                          placeholder="Name your Collection"
                          name="collection_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.collection_name.trimStart()}
                          maxLength={26}
                        />

                        <ErrorMessage
                          name="collection_name"
                          component="div"
                          className="error message"
                        />
                      </FormGroup>

                      <VisibilityGroup>
                        <h3>Visibility</h3>
                        <ul>
                          <li>
                            <InviteGroupItem>
                              <InviteGroupRadioBox>
                                <input
                                  type="radio"
                                  name="visibility"
                                  onChange={handleChange}
                                  value="Private"
                                  checked={
                                    values.visibility === "Private" && true
                                  }
                                />
                                <span />
                              </InviteGroupRadioBox>
                              <FriendItem>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Icon_material-lock"
                                    data-name="Icon material-lock"
                                    width="10.12"
                                    height="13.4"
                                    viewBox="0 0 10.12 13.4"
                                  >
                                    <path
                                      id="Icon_material-lock-2"
                                      data-name="Icon material-lock"
                                      d="M14.855,5.967h-.632V4.69a3.163,3.163,0,1,0-6.325,0V5.967H7.265A1.274,1.274,0,0,0,6,7.243v6.381A1.274,1.274,0,0,0,7.265,14.9h7.59a1.274,1.274,0,0,0,1.265-1.276V7.243A1.274,1.274,0,0,0,14.855,5.967ZM11.06,11.71a1.276,1.276,0,1,1,1.265-1.276A1.274,1.274,0,0,1,11.06,11.71Zm1.961-5.743H9.1V4.69a1.961,1.961,0,1,1,3.921,0Z"
                                      transform="translate(-6 -1.5)"
                                      fill="#3b3b3b"
                                    />
                                  </svg>
                                  Private
                                </h4>
                                <p>Only you can view</p>
                              </FriendItem>
                            </InviteGroupItem>
                          </li>
                          <li>
                            <InviteGroupItem>
                              <InviteGroupRadioBox>
                                <input
                                  type="radio"
                                  name="visibility"
                                  value="Friends"
                                  onChange={handleChange}
                                  checked={
                                    values.visibility === "Friends" && true
                                  }
                                />
                                <span />
                              </InviteGroupRadioBox>
                              <FriendItem>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Group_1763"
                                    data-name="Group 1763"
                                    width="13.903"
                                    height="10.398"
                                    viewBox="0 0 13.903 10.398"
                                  >
                                    <g
                                      id="Component_85_40"
                                      data-name="Component 85 – 40"
                                    >
                                      <path
                                        id="Path_7503"
                                        data-name="Path 7503"
                                        d="M258.446,8.821a.72.72,0,0,1-.757.429c-1.239-.014-2.478-.006-3.716-.006h-.219a5.212,5.212,0,0,0-2.076-3.592,3.349,3.349,0,0,1,1.1-.7c.4-.146.816-.246,1.207-.361a2.3,2.3,0,0,1-1.5-.949,2.26,2.26,0,0,1-.391-1.754,2.312,2.312,0,0,1,4.57.166,2.37,2.37,0,0,1-1.977,2.561c.209.038.392.064.57.105a4.065,4.065,0,0,1,3.158,3.448.709.709,0,0,0,.034.109Z"
                                        transform="translate(-244.543 -0.002)"
                                        fill="#3b3b3b"
                                        opacity="0.3"
                                      />
                                      <path
                                        id="Path_7504"
                                        data-name="Path 7504"
                                        d="M117.42,27.08c.2.037.4.067.6.113a4.052,4.052,0,0,1,3.091,3.847.588.588,0,0,1-.648.659h-6.8a.586.586,0,0,1-.642-.646,4.051,4.051,0,0,1,3.532-3.94c.055-.006.108-.022.2-.041a2.337,2.337,0,0,1-1.982-2.579,2.311,2.311,0,0,1,4.6.064,2.362,2.362,0,0,1-1.949,2.523"
                                        transform="translate(-113.024 -21.301)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                  Friends
                                </h4>
                                <p>Can View and follow this collection</p>
                              </FriendItem>
                            </InviteGroupItem>
                          </li>
                          <li>
                            <InviteGroupItem>
                              <InviteGroupRadioBox>
                                <input
                                  type="radio"
                                  name="visibility"
                                  onChange={handleChange}
                                  value="Followers"
                                  checked={
                                    values.visibility === "Followers" && true
                                  }
                                />
                                <span />
                              </InviteGroupRadioBox>
                              <FriendItem>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Group_1762"
                                    data-name="Group 1762"
                                    width="13.903"
                                    height="10.398"
                                    viewBox="0 0 13.903 10.398"
                                  >
                                    <g
                                      id="Component_85_39"
                                      data-name="Component 85 – 39"
                                    >
                                      <path
                                        id="Path_7503"
                                        data-name="Path 7503"
                                        d="M258.446,8.821a.72.72,0,0,1-.757.429c-1.239-.014-2.478-.006-3.716-.006h-.219a5.212,5.212,0,0,0-2.076-3.592,3.349,3.349,0,0,1,1.1-.7c.4-.146.816-.246,1.207-.361a2.3,2.3,0,0,1-1.5-.949,2.26,2.26,0,0,1-.391-1.754,2.312,2.312,0,0,1,4.57.166,2.37,2.37,0,0,1-1.977,2.561c.209.038.392.064.57.105a4.065,4.065,0,0,1,3.158,3.448.709.709,0,0,0,.034.109Z"
                                        transform="translate(-244.543 -0.002)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7504"
                                        data-name="Path 7504"
                                        d="M117.42,27.08c.2.037.4.067.6.113a4.052,4.052,0,0,1,3.091,3.847.588.588,0,0,1-.648.659h-6.8a.586.586,0,0,1-.642-.646,4.051,4.051,0,0,1,3.532-3.94c.055-.006.108-.022.2-.041a2.337,2.337,0,0,1-1.982-2.579,2.311,2.311,0,0,1,4.6.064,2.362,2.362,0,0,1-1.949,2.523"
                                        transform="translate(-113.024 -21.301)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                  Followers
                                </h4>
                                <p>
                                  (Including Friends) can View and follow this
                                  collection
                                </p>
                              </FriendItem>
                            </InviteGroupItem>
                          </li>
                          <li>
                            <InviteGroupItem>
                              <InviteGroupRadioBox>
                                <input
                                  type="radio"
                                  name="visibility"
                                  onChange={handleChange}
                                  value="Public"
                                  checked={
                                    values.visibility === "Public" && true
                                  }
                                />
                                <span />
                              </InviteGroupRadioBox>
                              <FriendItem>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Component_84_12"
                                    data-name="Component 84 – 12"
                                    width="13.903"
                                    height="11.789"
                                    viewBox="0 0 13.903 11.789"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Rectangle_2175"
                                          data-name="Rectangle 2175"
                                          width="13.903"
                                          height="11.789"
                                          fill="#3b3b3b"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Group_1757"
                                      data-name="Group 1757"
                                      clip-path="url(#clip-path)"
                                    >
                                      <path
                                        id="Path_7505"
                                        data-name="Path 7505"
                                        d="M321.706,191.222a1.442,1.442,0,0,1-.3.642,1.012,1.012,0,0,1-.7.392c-.654.022-1.309.007-1.932.007.008-.4.021-.81.022-1.22,0-1,0-2.008,0-3.012a3.3,3.3,0,0,0-.149-1.1.449.449,0,0,1-.009-.083,5.779,5.779,0,0,1,1.714.121,1.96,1.96,0,0,1,1.329,1.607.34.34,0,0,0,.023.062Z"
                                        transform="translate(-307.803 -180.495)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7506"
                                        data-name="Path 7506"
                                        d="M112.531,162.863H109.79c-.333,0-.478-.146-.478-.479,0-1.316,0-2.633,0-3.949a1.99,1.99,0,0,1,1.575-1.991,2.01,2.01,0,0,1,.443-.046c.81,0,1.619-.007,2.429,0a1.985,1.985,0,0,1,1.968,1.569,2.181,2.181,0,0,1,.049.484q.006,1.974,0,3.949a.407.407,0,0,1-.466.464h-2.782"
                                        transform="translate(-105.594 -151.074)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7507"
                                        data-name="Path 7507"
                                        d="M135.858,4.825A2.412,2.412,0,1,1,138.272,2.4a2.43,2.43,0,0,1-2.414,2.425"
                                        transform="translate(-128.906 -0.001)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7508"
                                        data-name="Path 7508"
                                        d="M2.96,192.4H2.15c-.3,0-.6,0-.9,0A1.207,1.207,0,0,1,0,191.139c0-.7,0-1.393,0-2.09a2.006,2.006,0,0,1,2-2.074c.351-.012.7,0,1.069,0-.012.049-.023.083-.028.117a6.913,6.913,0,0,0-.129.922c-.015,1.3-.008,2.6,0,3.9,0,.16.03.32.047.491"
                                        transform="translate(0 -180.611)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7509"
                                        data-name="Path 7509"
                                        d="M26.74,67.977a1.8,1.8,0,1,1,1.805,1.8,1.8,1.8,0,0,1-1.805-1.8"
                                        transform="translate(-25.83 -63.924)"
                                        fill="#3b3b3b"
                                      />
                                      <path
                                        id="Path_7510"
                                        data-name="Path 7510"
                                        d="M279.671,68a1.8,1.8,0,1,1-1.777-1.814A1.811,1.811,0,0,1,279.671,68"
                                        transform="translate(-266.677 -63.931)"
                                        fill="#3b3b3b"
                                      />
                                    </g>
                                  </svg>
                                  Public
                                </h4>
                                <p>
                                  Anyone Tripd user can view and follow this
                                  collection
                                </p>
                              </FriendItem>
                            </InviteGroupItem>
                          </li>
                        </ul>
                      </VisibilityGroup>

                      <BtnWrap>
                        <Button type="submit">
                          {props?.AddCollectionModalShow?.collection_id
                            ? "UPDATE "
                            : "CREATE"}
                        </Button>
                      </BtnWrap>
                    </form>
                  </>
                )}
              </Formik>
            </AddCollectionWrap>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddCollectionModal;
