import { getInviteFrndRendomStringAPI } from "../../../services/getInviteFrndRendomString";

export enum getInviteFrndRandomStringType {
  GET_INVITE_RANDOM_STRING = "GET_INVITE_RANDOM_STRING",
  GET_INVITE_RANDOM_STRING_SUCCESS = "GET_INVITE_RANDOM_STRING_SUCCESS",
  GET_INVITE_RANDOM_STRING_FALIURE = "GET_INVITE_RANDOM_STRING_FALIURE",
}

export const getInviteRandomString = () => (dispatch: any) => {
  dispatch({
    type: getInviteFrndRandomStringType.GET_INVITE_RANDOM_STRING,
  });
};

export const getInviteRandomStringSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getInviteFrndRandomStringType.GET_INVITE_RANDOM_STRING_SUCCESS,
    data,
  });
};

export const getInviteRandomStringFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getInviteFrndRandomStringType.GET_INVITE_RANDOM_STRING_FALIURE,
    error: msg,
  });
};
export const getInviteFrndRandomStringData =
  (randomString: any) => async (dispatch: any) => {
    try {
      dispatch(getInviteRandomString());
      const result: any = await getInviteFrndRendomStringAPI(randomString);
      dispatch(getInviteRandomStringSuccess(result));
    } catch (e: any) {
      dispatch(getInviteRandomStringFailed(e?.response?.data));
    }
  };
