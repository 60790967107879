import { getEventConcertModel } from "../../models/index";
import { getEventConcertType } from "../../action/get-event-concert/getEventConcert";

export const initState: getEventConcertModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getEventConcertType.GET_EVENT_CONCERT_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getEventConcertType.GET_EVENT_CONCERT_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case getEventConcertType.GET_EVENT_CONCERT_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }
    case "RESET_REQUEST": {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
