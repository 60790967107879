import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCollectionHandler } from "../../redux/action/deleteCollection/deleteCollection";
import {
  ModalBody,
  ModalBodyBtn,
} from "../deleteDaysModal/deleteDaysModalStyle";

const DeleteCollectionModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const DeleteCollection = () => {
    const data = {
      collection_id: props.getDeleteCollectionModal.collection_id,
    };
    dispatch(deleteCollectionHandler(data));
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="deleteDaysModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .deleteDaysModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 343px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .deleteDaysModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Delete Collection</h2>
            <p>
              Are you sure you want to delete <br />
              this Collection?
            </p>
            <ModalBodyBtn>
              <Button
                onClick={() => {
                  DeleteCollection();
                }}
              >
                YES
              </Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteCollectionModal;
