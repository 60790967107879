import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeFriendData } from "../../redux/action/removeFriend/removeFriend";
import {
  ModalBody,
  ModalBodyBtn,
} from "../removeFriendModal/removeFriendModalStyle";

const RemoveFriendModal = (props: any) => {
  const dispatch: any = useDispatch();

  const removeFriendHandler = () => {
    const data = {
      to_friend: props.user_id,
    };

    dispatch(removeFriendData(data));
    props.setRemoveFriendModalShow(false);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="removeFriendModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .removeFriendModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 343px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .removeFriendModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>REMOVE FRIEND</h2>
            <p>
              Are you sure you want to delete this
              <br /> user from your friend list?
            </p>
            <ModalBodyBtn>
              <Button onClick={() => removeFriendHandler()}>YES</Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RemoveFriendModal;
