import { getCollectionFollowingType } from "../../action/getFollowingCollection/getFollowingCollection";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getCollectionFollowingType.GET_COLLECTION_FOLLOWING: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case getCollectionFollowingType.GET_COLLECTION_FOLLOWING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case getCollectionFollowingType.GET_COLLECTION_FOLLOWING_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }
    default:
      return state;
  }
};
