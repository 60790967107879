import { collectionItemAPI } from "../../../services/getcollectionItem";

export enum GetCollectionItemType {
  GET_COLLECTION_ITEMS = "GET_COLLECTION_ITEMS",
  GET_COLLECTION_ITEMS_SUCCESS = "GET_COLLECTION_ITEMS_SUCCESS",
  GET_COLLECTION_ITEMS_FALIURE = "GET_COLLECTION_ITEMS_FALIURE",
}

export const getCollectionItemInfo = () => (dispatch: any) => {
  dispatch({
    type: GetCollectionItemType.GET_COLLECTION_ITEMS,
  });
};

export const getCollectionItemInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetCollectionItemType.GET_COLLECTION_ITEMS_SUCCESS,
    data: data,
  });
};

export const getCollectionItemInfoFaliure =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: GetCollectionItemType.GET_COLLECTION_ITEMS_FALIURE,
      data: { msg },
    });
  };

export const getCollectionItemInfoHandler =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(getCollectionItemInfo());
      const result: any = await collectionItemAPI(data);
      dispatch(getCollectionItemInfoSuccess(result));
    } catch (e: any) {
      dispatch(getCollectionItemInfoFaliure(e.response.data.message));
    }
  };
