import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const TabsContent = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 32px 30px;
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 17px;
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .allTabText {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const PlansTabsWrap = styled.div`
  margin: 0;
  .nav-tabs {
    border-bottom: none;
    padding: 0;
    margin: 0 0 30px;
    display: flex;
    .nav-item {
      flex: 1;
      text-align: center;
    }
    .nav-link {
      margin: 0;
      border-radius: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3b3b3b;
      position: relative;
      padding: 0 10px 13px;
      min-width: 110px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.35s ease 0s;
      &:hover {
        color: #6840df;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        width: 0;
        margin: auto;
        background: #6840df;
        opacity: 0;
        transition: all 0.35s ease 0s;
      }
      &.active {
        &:before {
          width: 100%;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1400px) {
        padding: 17px 20px 15px;
      }
    }
  }

  ${TabsContent} {
    padding: 0;
    margin: 0 -10px;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
    @media (max-width: 767px) {
      max-height: inherit;
      overflow-y: visible;
    }
  }
`;

export const PlansItem = styled.div`
  margin: 0 0 20px;
  padding: 0 10px;
  align-items: center;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const PlansItemImageWrap = styled.div`
  margin: 0;
  width: 160px;
  min-width: 160px;
  max-width: 100%;
  cursor: pointer;
  @media (max-width: 565px) {
    width: 90px;
    min-width: 90px;
  }
`;

export const PlansItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  padding-bottom: 75%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const PlansItemInfo = styled.div`
  margin: 0;
  padding-left: 20px;
  flex: 1;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  color: #8d8d8d;

  @media (max-width: 565px) {
    padding-left: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  h3 {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: 565px) {
      font-size: 14px;
      line-height: 18px;
    }
    a {
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      text-decoration: none;
      outline: none;
      &:hover {
        color: #4d27da;
      }
    }
  }
  p {
    margin-bottom: 0 !important;
  }
`;

export const PlansItemDes = styled.div`
  margin-top: 6px;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  color: #8d8d8d;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:empty {
    display: none;
  }
`;

export const PlansInfoDate = styled.div`
  margin: 20px 0 0;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  @media (max-width: 565px) {
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0;
  }
`;

export const PageLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(59, 59, 59, 0.2);
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    vertical-align: middle;
  }
`;

export const NoPlanAvailable = styled.div`
  font-family: "Poppins";
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #a1a3b0;
  text-align: center;
  padding: 0 10px;
`;
