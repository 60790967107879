import styled from "styled-components";

export const MyPageWrap = styled.div`
  min-height: calc(100vh - 84px);
  padding: 15px 0 0;
  background: #f0f0f0;
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    padding: 5px 0 0;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 46px;
    font-weight: 700;
    color: #4631d1;
    margin: 0;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const MainContentPanel = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 101px);

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
`;

export const HomeListingWrap = styled.div`
  background: #f0f0f0;
  padding: 45px 20px 22px;
  @media (max-width: 767px) {
    padding: 45px 15px 22px;
  }
`;

export const SearchListLoader = styled.div`
  text-align: center;
  padding: 15px 0;
  pointer-events: none;
  img {
    width: 42px;
    height: 42px;
    vertical-align: middle;
  }
`;

export const HomeListingTags = styled.div`
  margin-bottom: 10px;
  flex-wrap: wrap;
  display: flex;
  .btn {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 9px 14px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    min-width: 51px;
    cursor: pointer;
    outline: none;
    transition: all 0.35s ease 0s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: #6840df;
      color: #fff;
    }
    &.select {
      background: #6840df;
      color: #fff;
    }
    @media screen and (max-width: 1400px) {
      margin-right: 9px;
      margin-bottom: 9px;
      padding: 9px 12px;
      min-width: 45px;
    }
    @media (max-width: 565px) {
      padding: 5px 10px;
      font-size: 11px;
      line-height: 15px;
    }
  }
`;

export const CreateBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &.flexEnd {
    justify-content: flex-end;
  }
  .btn {
    background: #4d27da;
    border-radius: 8px;
    padding: 6px 17px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.45s ease 0s;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    box-shadow: none;
    min-width: 122px;
    @media (max-width: 565px) {
      min-width: auto;
      font-size: 12px;
      padding: 4px 10px;
    }
    svg {
      vertical-align: middle;
      margin-right: 10px;
      path {
        transition: all 0.35s ease 0s;
      }
      @media (max-width: 565px) {
        margin-right: 6px;
        width: 16px;
      }
    }
    &:hover {
      background: #000000;
      color: #fff;
      .plusIcon {
        fill: #000;
      }
    }
    &.filters {
      background: transparent;
      padding: 0;
      justify-content: flex-start;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      min-width: inherit;
      transition: all 0.35s ease 0s;
      border: none;
      border-radius: 0;
      width: 15%;
      position: relative;
      top: -8px;
      svg {
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        color: #6840df;
        path {
          stroke: #6840df;
        }
      }
      @media (max-width: 565px) {
        font-size: 12px;
        line-height: 18px;
        svg {
          width: 14px;
        }
      }
    }
  }
`;

export const CreateBtnInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  .sortby {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 565px) {
      margin-right: 8px;
    }
    .dropdown-toggle {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #3b3b3b;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      transition: all 0.35s ease 0s;
      @media (max-width: 565px) {
        font-size: 12px;
        line-height: 18px;
      }
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        margin-left: 10px;
        margin-right: 0;
        transition: all 0.35s ease 0s;
        @media (max-width: 565px) {
          margin-left: 6px;
          width: 11px;
        }
      }
      &:hover {
        color: #6840df;
        svg {
          path {
            stroke: #6840df;
          }
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 8px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        // &:first-child {
        //   color: #3b3b3b;
        // }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        color: #6840df;
        svg {
          transform: rotate(180deg);
          path {
            stroke: #6840df;
          }
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    h2 {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      text-align: center;
      margin: 0 0 15px;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.createNewWrap {
    .modal-body {
      padding-top: 18px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        color: #4d27da;
        margin-bottom: 25px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        border-bottom: 1px solid #9597a6;
        padding: 0 20px 20px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .btn {
          padding: 0;
          margin: 0;
          border-radius: 0;
          box-shadow: none;
          outline: none;
          font-family: "Poppins";
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          color: #3b3b3b;
          background: transparent;
          border: none;
          width: 100%;
          align-items: center;
          display: flex;
          transition: all 0.35s ease 0s;
          cursor: pointer;
          svg {
            vertical-align: middle;
            margin-right: 10px;
            path {
              transition: all 0.35s ease 0s;
            }
          }
          &:hover {
            color: #4d27da;
            color: #000;
            path {
              fill: #3b3b3b;
              fill: #000;
            }
          }
        }
      }
    }
  }

  &.shareWithWrap {
    .modal-body {
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
      }
      ${FormGroup} {
        margin-bottom: 0;
        .form-label {
          font-family: Poppins;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.createPlanWrap {
    .modalTitle {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      text-align: center;
      max-width: 410px;
      margin: auto auto 17px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        svg {
          margin-right: 15px;
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }

    .planFeature {
      padding: 0 10px;
      margin: 17px 0 0;
      list-style-type: none;
      li {
        font-family: "Poppins";
        font-size: 12px;
        line-height: 19px;
        font-weight: 400;
        color: #a1a3b0;
        padding-right: 50px;
        margin-bottom: 15px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        h4 {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 0;
        }
        .form-switch {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
          min-height: inherit;
          .form-check-input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            z-index: 1;
            cursor: pointer;
            border-radius: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
            opacity: 0;
          }
          .form-check-label {
            border: 1px solid #8d8d8d;
            border-radius: 30px;
            width: 38px;
            height: 22px;
            background: transparent;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;
            text-indent: -99px;
            overflow: hidden;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 20px;
              background: #cecece;
              display: inline-block;
              position: absolute;
              left: 2px;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.25s ease 0s;
            }
          }
          .form-check-input {
            &:checked {
              + .form-check-label {
                &:before {
                  left: 18px;
                  background: #48d85e;
                }
              }
            }
          }
        }
      }
    }
    .friendsList {
      padding: 5px 0 3px;
      margin: 0 -10px;
      list-style-type: none;
      flex-wrap: wrap;
      align-items: flex-start;
      display: flex;
      > li {
        width: 33.33%;
        padding: 0 10px;
        margin: 0 0 17px;
      }
    }
  }

  &.createGroupWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        margin-right: 10px;
        margin-top: 4px;
      }
    }

    .modal-body {
      padding: 20px 40px 30px;
      .addFriendsBtn {
        border: none;
        padding: 0;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #3b3b3b;
        background: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
        svg {
          margin-right: 15px;
        }
      }
      .dateInputGroup {
        width: 164px;
        position: relative;
        .form-control {
          padding-right: 45px;
        }
        .calendar-icon {
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          position: absolute;
          top: 13px;
          right: 20px;
          pointer-events: none;
          img {
            vertical-align: middle;
            max-width: 100%;
          }
        }
      }
      ${FormGroup} {
        .form-control {
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &::-moz-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &:-moz-placeholder {
            font-size: 14px;
          }
        }
        textarea {
          height: 70px;
          resize: none;
        }
      }
    }
  }

  &.inviteOthersWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #4d27da;
      margin-bottom: 22px;
    }
  }

  &.filtersWrap {
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #3b3b3b;
      margin-bottom: 22px;
      text-align: left;
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: none;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 8px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
    }
    + .btn {
      margin-left: 20px;
    }
  }
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 10px 0 0;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      .totalFriends {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const CreatePlanDate = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 20px;
  display: flex;
  img {
    margin-right: 10px;
  }
`;

export const ButtonTabs = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TabButton = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  border-radius: 50px;
  padding: 3px 15px;
  cursor: default;
  margin: 0 5px;
  height: 34px;
  display: flex;
  align-items: center;
  transition: all 0.35s ease 0s;
  &.active {
    font-weight: 500;
    color: #4d27da;
    border: 2px solid #4d27da;
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const InviteOtherSearch = styled.div`
  margin: 0 0 22px;
  width: 340px;
  max-width: 340px;
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #9597a5;
      border-radius: 5px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      padding: 8px 45px 8px 15px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
      &:-moz-placeholder {
        font-size: 12px;
        color: #a1a3b0;
        opacity: 1;
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 17px;
      top: 7px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
    .addIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 7px;
      top: 34px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        circle,
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
          stroke: #4d27da;
        }
      }
    }
  }
  &.inviteEmailBox {
    ${FormGroup} {
      margin-bottom: 10px;
    }
  }
  .addAnotherEmail {
    padding: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: normal;
    color: #4d27da;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
`;

export const MyGroupTabContent = styled.div`
  min-height: 305px;
`;

export const MyGroupTabContentInner = styled.div`
  display: flex;
`;

export const InviteMyGroups = styled.div`
  width: 340px;
  max-width: 100%;
  > ul {
    margin: 0;
    padding: 2px 0 0;
    list-style-type: none;
    > li {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupItem = styled.div`
  position: relative;
  padding-left: 35px;
  display: inline-block;
  vertical-align: middle;
  ${FriendItem} {
    h3 {
      font-size: 12px;
      line-height: 17px;
      margin: 0 0 10px;
    }
  }
`;

export const InviteGroupRadioBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -16px;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
`;

export const InviteGroupMembers = styled.div`
  padding-left: 35px;
  flex: 1;
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const InviteGroupMemberItem = styled.div`
  padding: 0 0 0 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersLeft = styled.div`
  width: 340px;
  max-width: 100%;
  ${InviteOtherSearch} {
    margin-bottom: 20px;
  }
  ${FormGroup} {
    .form-label {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 8px;
    }
    .searchIconBtn {
      top: 34px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const MyFollowersItemWrap = styled.div`
  position: relative;
  padding-right: 30px;
  ${InviteGroupCheckBox} {
    span {
      left: auto;
      right: 0;
    }
  }
`;

export const MyFollowersItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const MyFollowersRight = styled.div`
  padding: 43px 0 0 59px;
  flex: 1;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 0 0 20px;
  }
  ${MyFollowersItemWrap} {
    padding-right: 0;
  }
`;

export const RentalsResultsMain = styled.div`
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  // flex-wrap: wrap;
`;

export const RentalsResultsList = styled.div`
  padding-top: 20px;

  &:empty {
    display: none;
  }
`;

export const RentalsResultsItem = styled.div`
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const RentalsListImage = styled.div`
  max-width: 40%;
  flex: 40%;
  @media (max-width: 480px) {
    max-width: 100%;
    flex: 100%;
  }
  .carousel-item {
    padding-bottom: 66.66%;
    position: relative;
    border-radius: 10px;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
  .carousel-indicators {
    margin: 0;
    right: 10px;
    bottom: 4px;
    left: 10px;
    flex-wrap: wrap;
    button {
      width: 5px;
      min-width: 5px;
      height: 5px;
      border-radius: 50%;
      opacity: 1;
      border: none;
      margin: 0 3px 6px;
      &.active {
        border-radius: 4px;
        width: 20px;
        min-width: 20px;
        background: #6840df;
      }
    }
  }
  .carousel-control-prev-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #6840df;
    padding: 5px 0;
    width: 24px;
    height: 24px;
    background-size: 16px;
    border-radius: 50%;
    &:hover {
      opacity: 1;
    }
  }
  .carousel-control-next-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #6840df;
    padding: 5px 0;
    width: 24px;
    height: 24px;
    background-size: 16px;
    border-radius: 50%;
    &:hover {
      opacity: 1;
    }
  }
`;

export const RentalsListInfo = styled.div`
  max-width: 60%;
  flex: 60%;
  padding-left: 20px;
  @media (max-width: 480px) {
    max-width: 100%;
    flex: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
`;

export const RentalsTitleWrap = styled.div`
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  h3 {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #000000;
    padding-right: 20px;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      padding-right: 12px;
    }
    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 21px;
    }
    a {
      color: #000000;
      &:hover {
        color: #6840df;
      }
    }
  }
  .dropdown {
    .dropdown-toggle {
      background: transparent;
      box-shadow: none;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      transition: all 0.35s ease 0s;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        transition: all 0.35s ease 0s;
        circle {
          transition: all 0.35s ease 0s;
        }
        path {
          transition: all 0.35s ease 0s;
        }
        @media (max-width: 565px) {
          width: 20px;
        }
      }
      &:hover {
        svg {
          circle {
            fill: #6840df;
          }
          path {
            fill: #ffffff;
          }
        }
        box-shadow: none;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 3px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
`;

export const RentalsFeature = styled.div`
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
  ul {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding-right: 8px;
      margin-right: 8px;
      position: relative;
      color: #3b3b3b;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      &::before {
        position: absolute;
        content: "";
        right: -1px;
        top: 7px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #3b3b3b;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const SportsInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @media (max-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SportsInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const SportsRating = styled.span`
  border-radius: 17px;
  padding: 4px 12px;
  background: #ffffff;
  color: #000000;
  font-family: "Poppins";
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 10px;
  @media (max-width: 480px) {
    padding: 4px 8px;
    margin-right: 8px;
  }
  svg {
    margin-right: 6px;
    vertical-align: -3px;
  }
`;

export const SportsReview = styled.span`
  color: #a1a3b0;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
`;

export const SportsPrice = styled.span`
  color: #000000;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin-top: 12px;
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 480px) {
    margin-top: 0;
  }
`;

// export const RentalsListMap = styled.div`
//   height: 260px;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: center;
//   }
// `;
export const RentalsListMap = styled.div`
  height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mapPriceBox {
    color: #4d27da;
    background: #fff;
    padding: 5px 7px;
    margin-top: -15px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    transform: translate(-50%, -60%);
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    border: 1px solid #4d27da;
    font-weight: 500;
    position: relative;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background: #fff;
      left: 0;
      right: 0;
      bottom: -5px;
      margin: auto;
      transform: rotate(45deg);
      border-bottom: 1px solid #4d27da;
      border-right: 1px solid #4d27da;
    }
  }
`;
export const PaginationWrap = styled.div`
  margin-top: 30px;
  .pagination,
  .ant-pagination {
    justify-content: center;
    display: flex;
  }
  .page-item,
  li {
    margin-right: 12px;
    border: none;
    background: transparent;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
    vertical-align: middle;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 565px) {
      margin-right: 2px;
    }
    .page-link,
    a,
    button {
      height: 32px;
      min-width: 32px;
      padding: 0 7px;
      text-align: center;
      color: #3b3b3b !important;
      border: none !important;
      border-radius: 50px;
      background: transparent !important;
      line-height: 32px;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 500;
      box-shadow: none !important;
      opacity: 1;
      cursor: pointer;
      svg {
        transition: all 0.35s ease 0s;
        path {
          stroke: #3b3b3b;
          transition: all 0.35s ease 0s;
        }
      }

      &:hover {
        // background: #6840df !important;
        color: #6840df !important;
        svg {
          path {
            stroke: #6840df;
          }
        }
      }

      @media (max-width: 565px) {
        height: 28px;
        width: 28px;
        line-height: 28px;
        font-size: 12px;
      }
    }

    &.disabled:not(:first-child) {
      .page-link {
        color: #3b3b3b !important;
      }
    }

    &.active,
    &.ant-pagination-item-active {
      .page-link,
      a,
      button {
        background: #6840df !important;
        color: #ffffff !important;
        cursor: default;
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;
      .page-link {
        color: #9597a6 !important;
        svg {
          path {
            stroke: #9597a6;
          }
        }
        &:hover {
          background: transparent !important;
          color: #9597a6 !important;
        }
      }
    }

    &.ant-pagination-jump-next,
    &.ant-pagination-jump-prev {
      a {
        padding: 0;
        white-space: nowrap;
        align-items: flex-end;
        display: flex;
        .anticon-double-right,
        .anticon-double-left {
          display: none;
        }
        .ant-pagination-item-ellipsis {
          font-family: "Poppins";
          font-size: 10px;
          line-height: 22px;
          font-weight: 500;
          color: #3b3b3b !important;
          opacity: 1;
          letter-spacing: 0.5px;
          position: relative;
          top: auto;
          right: auto;
          bottom: auto;
          left: auto;
        }

        &:hover {
          color: #6840df !important;
          .ant-pagination-item-ellipsis {
            color: #6840df !important;
          }
        }
      }
    }

    &.ant-pagination-next,
    &.ant-pagination-prev {
      .ant-pagination-item-link {
        color: #3b3b3b !important;
        font-family: "Poppins";
        font-size: 13px;
        font-weight: 500;
        .anticon {
          vertical-align: middle;
        }
      }
    }

    &.ant-pagination-disabled {
      pointer-events: none;
      .ant-pagination-item-link {
        color: #9597a6 !important;
      }
    }
  }
`;

export const FiltersFormWrap = styled.div`
  max-height: 484px;
  overflow-y: scroll;
  padding-right: 12px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
  .form-label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #a1a3b0;
  }
  .form-control {
    height: 44px;
    border: 1px solid #cecece;
    border-radius: 5px;
    &:focus {
      box-shadow: none;
      border-color: #4d27da;
    }
  }
  hr {
    margin: 30px 0;
    background-color: #cecece;
    opacity: 1;
  }
  .checkbox-label {
    color: #a1a3b0;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .custom-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-label {
      margin: 0 6px 0 0;
    }
  }
`;

export const FormSubTitle = styled.h4`
  line-height: 23px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
  margin-bottom: 15px;
`;

export const FormMultibutton = styled.div`
  display: flex;
  align-items: center;
  .btn {
    border: 1px solid #cecece;
    border-radius: 5px 0px 0px 5px;
    background: transparent;
    color: #a1a3b0;
    height: 36px;
    min-width: 36px;
    &.custom-plus {
      border-radius: 0 5px 5px 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .form-control {
    width: 55px;
    height: 36px;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    text-align: center;
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    &:focus {
      border-color: #cecece;
    }
  }
`;

export const InputRangeWrap = styled.div`
  padding: 0;
  margin: 15px 0 20px;
  .input-range__label--value {
    display: none;
  }
  .input-range__track--background {
    background: #cecece;
    height: 3px;
    width: 100% !important;
  }
  .input-range__track--active {
    display: none;
    height: 3px;
    background: #4d27da;
  }
  .input-range__slider {
    width: 20px;
    height: 20px;
    background: #4d27da;
    border: 1px solid #4d27da;
    margin: 0;
    top: 0;
  }
  .input-range__slider-container {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    top: -9px;
    + .input-range__slider-container {
      margin-left: -20px;
    }
  }
  .input-range__label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #3b3b3b;
    bottom: -25px;
    padding-left: 10px;
    &:before {
      content: "$";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .input-range__label-container {
    left: 0;
  }
`;

// Detail page

export const SportsDetails = styled.div``;

export const SportsDetailsTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 -10px;
  @media (max-width: 565px) {
    flex-wrap: wrap;
  }
  img {
    max-width: 100%;
  }
`;

export const SportsLogo = styled.div`
  max-width: 60%;
  flex: 60%;
  padding: 0 10px;
  @media (max-width: 565px) {
    max-width: 100%;
    flex: 100%;
  }
`;

export const SportsLogoInner = styled.div`
  // height: 275px;
  overflow: hidden;
  position: relative;
  padding-bottom: 58.3%;
  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const SportsGallery = styled.div`
  max-width: 40%;
  flex: 40%;
  padding: 0 10px;
  @media (max-width: 565px) {
    max-width: 100%;
    flex: 100%;
    margin-top: 20px;
  }
`;

export const SportsGalleryTop = styled.div`
  // height: 173px;
  overflow: hidden;
  position: relative;
  padding-bottom: 55.19%;
  img {
    max-width: 100%;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export const SportsGalleryBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 10px 0 0;
`;

export const SportsGalleryItem = styled.div`
  flex: calc(33.33% - 5px);
  max-width: calc(33.33% - 7px);
  position: relative;
  padding-bottom: 31.11%;
  &:nth-child(3) {
    &:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(59, 59, 59, 0.5);
      border-radius: 10px;
      z-index: 1;
    }
  }
  img {
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .btn {
    position: absolute;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    box-shadow: none !important;
    padding: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const SportsTitleWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;

  .dropdown {
    @media screen and (max-width: 480px) {
      margin-top: -5px;
    }
    .dropdown-toggle {
      background: transparent;
      box-shadow: none !important;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
      svg {
        transition: all 0.35s ease 0s;
        circle {
          transition: all 0.35s ease 0s;
        }
        path {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        svg {
          circle {
            fill: #6840df;
          }
          path {
            fill: #ffffff;
          }
        }
        box-shadow: none;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      left: auto !important;
      right: 0 !important;
      margin: 3px auto 10px;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
          circle {
            fill: #6840df;
          }
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
`;

export const SportsTitle = styled.h3`
  color: #000000;
  font-family: "Poppins";
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 565px) {
    font-size: 16px;
    line-height: 20px;
  }
  span {
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: block;
    margin-top: 6px;
  }
`;

export const DetailRentalsFeature = styled.div`
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
  ul {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding-right: 8px;
      margin-right: 8px;
      position: relative;
      color: #3b3b3b;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      &::before {
        position: absolute;
        content: "";
        right: -2px;
        top: 7px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #3b3b3b;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const DetailSportsInfo = styled.div`
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const DetailSportsInfoWrap = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const DetailSportsRating = styled.span`
  border-radius: 17px;
  padding: 4px 14px;
  background: #ffffff;
  color: #000000;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 20px;
  @media (max-width: 480px) {
    padding: 4px 8px;
    margin-right: 8px;
  }
  svg {
    margin-right: 6px;
    vertical-align: -3px;
  }
`;

export const DetailSportsReview = styled.span`
  color: #a1a3b0;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
`;

export const DetailSportsPrice = styled.span`
  color: #000000;
  font-family: "Poppins";
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 565px) {
    font-size: 16px;
    line-height: 20px;
  }
  &.sportsdetail {
    font-weight: normal;
  }
`;

export const SportsTeamDesc = styled.div`
  color: #3b3b3b;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  margin-top: 25px;
  padding-right: 190px;
  min-height: 40px;
  position: relative;
  p {
    margin: 18px 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  strong {
    font-weight: 600;
  }
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    background: #6840df;
    border: none;
    border-radius: 25px;
    padding: 8px 16px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #ffffff;
    min-width: 150px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    transition: all 0.35s ease 0s;
    &:hover {
      background: #000000;
      color: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    padding-right: 0;
    .btn {
      position: relative;
      margin-top: 20px;
    }
  }
`;

export const RecordNotFound = styled.div`
  &.collection-notfound {
    padding-left: 10px;
  }
  font-family: "Poppins";
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
`;

export const BackToList = styled.button`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6c53e4;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.35s ease 0s;
  min-width: 80px;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
  path {
    transition: all 0.35s ease 0s;
  }
  &:hover {
    opacity: 1;
    color: #3b3b3b;
    path {
      fill: #3b3b3b;
    }
  }
`;
export const ModalSection = styled.div`
  padding: 20px;
  .modal-body {
    .btn {
      background: #6840df;
      color: #fff;
      border-color: transparent;
      min-width: 120px;
      border-radius: 50px;
      margin: 30px auto 0px;
      display: block;
    }
    p {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      text-align: center;
    }
  }
`;
