import { useState, useEffect } from "react";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteUserModal from "../deleteUserModal/index";
import { GlobalDataType } from "../../redux/action/globalData";
import UserImage from "../../assets/images/user-image.png";
import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import CandyBrian from "../../assets/images/candy-brian.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import ChatAttachment from "../../assets/images/chat-attachment.png";
import GalleryImage1 from "../../assets/images/gallery-image-1.png";
import GalleryImage2 from "../../assets/images/gallery-image-2.png";
import GalleryImage3 from "../../assets/images/gallery-image-3.png";
import PageLoaderIcon from "../../assets/images/page-loader.svg";
import {
  TabbingPanel,
  FormGroup,
  TabsWrap,
  TabsContent,
  ModalBody,
  ModalBodyBtn,
  PlanChats,
  PlanChatsGroupsList,
  PlanChatsGroups,
  PlanChatsGroupsInner,
  ChatsTime,
  PlanChatsCounter,
  GroupsSingleChat,
  GroupsSingleChatImage,
  GroupsSingleChatInfo,
  GroupMemberItem,
  GroupChatWrap,
  GroupChatTitle,
  GroupChatItem,
  GroupChatDate,
  GroupChatHead,
  GroupChatBody,
  ChatUserName,
  ChatTime,
  ChatUserImage,
  ChatUserMessage,
  ChatBodyAttachment,
  ChatMessageAction,
  ChatMessageSendBtn,
  GroupDetailMembers,
  GroupDetailsShortby,
  GroupSaveGallery,
  GroupSaveGalleryItem,
  SearchListLoader,
} from "./rightTabbingPanelStyle";

import CustomInput from "../../components/CustomInput";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getGroupData } from "../../redux/action/get-all-group/getAllGroups";
import { io } from "socket.io-client";
import SocketPanel from "../socketPanel";
import { planListingData } from "../../redux/action/plan-listing/planListing";
import PlanPanel from "../../components/planPanel/index";

const RightTabbingPanel = (props: any) => {
  const dispatch: any = useDispatch();
  const [groupListData, setGroupListData] = useState<any[]>([]);

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  const addGroup = useSelector((state: any) => state?.createGroup);

  const exitGroup = useSelector((state: any) => state?.exitGroup);

  const navigate = useNavigate();
  const [tabObj, setTabObj] = useState({
    all: true,
    plans: false,
    groups: false,
  });
  const [getFollow, setFollow] = useState("all");

  const [groupId, setGroupId] = useState("");
  const [planId, setPlanId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [planName, setPlanName] = useState("");
  const [eventArr, setEventArr] = useState<any[]>([]);
  const [countProps, setCountProps] = useState("");
  const [allTabPlanList, setAllTabPlanList] = useState<any[]>([]);

  const [socket, setSocket] = useState<any | null>(null);

  const planList: any = useSelector((state: any) => state?.planListing);

  const createPlanInfo: any = useSelector((state: any) => state?.createPlan);

  const deletePlan: any = useSelector((state: any) => state?.deletePlan);

  useEffect(() => {
    dispatch(planListingData());
    if (deletePlan?.data?.data) {
      setAllTabPlanList(planList?.data?.data);
    }
  }, [deletePlan?.data?.data, createPlanInfo]);

  const local: any = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (socket === null && Object.keys(local).length > 0) {
      const socketUrl: any = process.env.REACT_APP_SOCKET_API_KEY;

      setSocket(io(socketUrl));
    }
  }, [socket]);

  let temArr: any = [];

  let temArr2: any = [];

  useEffect(() => {
    if (getFollow === "groups" && group_data?.data?.data[0]?.GroupList) {
      temArr.splice(0, temArr.length);
      group_data?.data?.data[0]?.GroupList?.map((item: any) => {
        temArr.push(item._id);
      });
      const loginId = localStorage.getItem("id");

      const user_id = loginId;
      const rooms_id = temArr;

      socket?.emit("UnreadMsg", { user_id, rooms_id });
      socket?.on("UnreadMsgCount", (count: any) => {
        setEventArr(count.countArray);
      });
      setGroupListData(group_data?.data?.data[0]?.GroupList);
    }
    if (getFollow === "plans" && planList?.data?.data[0]?.PlanList) {
      temArr.splice(0, temArr.length);
      planList?.data?.data[0]?.PlanList?.map((item: any) => {
        temArr.push(item._id);
      });
      const loginId = localStorage.getItem("id");
      const user_id = loginId;
      const rooms_id = temArr;

      socket?.emit("UnreadMsg", { user_id, rooms_id });
      socket?.on("UnreadMsgCount", (count: any) => {
        setEventArr(count.countArray);
      });
      setGroupListData(planList?.data?.data[0]?.PlanList);
    }
    if (getFollow === "all") {
      eventArr.splice(0, eventArr.length);
      temArr.splice(0, temArr.length);
      if (group_data?.data?.data[0]?.GroupList) {
        group_data?.data?.data[0]?.GroupList?.map((item: any) => {
          temArr.push(item._id);
        });
        setGroupListData(group_data?.data?.data[0]?.GroupList);
      }
      if (planList?.data?.data[0]?.PlanList) {
        planList?.data?.data[0]?.PlanList?.map((item: any) => {
          temArr.push(item._id);
        });
        setAllTabPlanList(planList?.data?.data[0]?.PlanList);
      }
      const loginId = localStorage.getItem("id");
      const user_id = loginId;
      const rooms_id = temArr;
      socket?.emit("UnreadMsg", { user_id, rooms_id });
      socket?.on("UnreadMsgCount", (count: any) => {
        setEventArr(count.countArray);
      });
    }
  }, [
    getFollow,
    group_data?.data?.data[0]?.GroupList,
    planList?.data?.data[0]?.PlanList,
  ]);
  useEffect(() => {
    dispatch(getGroupData({ search: "" }));
  }, [addGroup?.data, exitGroup?.data]);

  const SideTabbingPanel = () => {
    const panelBtn = document.querySelector(".tabbingPanel");

    if (document.body.classList !== null) {
      document.body.classList.toggle("right-panel-open");
    }

    if (panelBtn !== null) {
      panelBtn.classList.toggle("panel-close");
    }
  };

  const submitForm = (values: any) => {
    setFollow(values);
  };

  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);

  const params = useParams();

  const temArrData: any = [];

  useEffect(() => {
    if (params.plan_id && planList?.data?.data[0]?.PlanList) {
      planList?.data?.data[0]?.PlanList.map((itemData: any) => {
        return params.plan_id === itemData._id
          ? temArrData.push(itemData)
          : itemData;
      });
      setPlanId(temArrData[0]?._id);
      setFollow("planChat");
      setPlanName(temArrData[0]?.plan_name);
    }
  }, [planList?.data?.data[0]?.PlanList]);

  const checkCountHandle = (id: any) => {
    for (var i = 0; i < eventArr.length; i++) {
      if (id === eventArr[i].group_id) {
        return eventArr[i].counts;
      }
    }
  };

  const countHandler = (item: any) => {
    for (var i = 0; i < eventArr.length; i++) {
      if (item === eventArr[i].group_id) {
        setCountProps(eventArr[i].counts);
      }
    }
  };

  const dynamicChatPanel = useSelector((state: any) => state?.dynamicChat);

  useEffect(() => {
    if (dynamicChatPanel?.data) {
      setFollow("planChat");
      setGroupId(dynamicChatPanel?.data?.groupId);
      setGroupName(dynamicChatPanel?.data?.plan_name);
    }
  }, [dynamicChatPanel]);

  // const checkCountHandle2 = (item: any) => {
  //   for (var i = 0; i < notification?.length; i++) {
  //     if (item === i) {
  //       return notification[i].counts;
  //     }
  //   }
  // };

  // const countHandler2 = (item: any) => {
  //   for (var i = 0; i < notification?.length; i++) {
  //     if (item === notification[i].group_id) {
  //       setCountProps(notification[i].counts);
  //     }
  //   }
  // };

  return (
    <>
      <TabbingPanel className="tabbingPanel">
        <Button onClick={SideTabbingPanel} className="panelToggleBtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.156"
            height="10.673"
            viewBox="0 0 9.156 10.673"
          >
            <g transform="translate(-1144.687 -181.733)">
              <path
                d="M-9189.21-12227.6l-4.913,4.912,4.913,4.913"
                transform="translate(-8041.129 -12035.617) rotate(180)"
                fill="none"
                stroke="#4d27da"
                stroke-width="1.2"
              />
              <path
                d="M-9191.15-12227.6l-2.98,2.977,2.98,2.982"
                transform="translate(-8046.039 -12037.547) rotate(180)"
                fill="none"
                stroke="#4d27da"
                stroke-width="1.2"
              />
            </g>
          </svg>
        </Button>

        {getFollow == "groups" || getFollow == "all" || getFollow == "plans" ? (
          <TabsWrap className="chat-list-tab">
            <Tabs
              activeKey={getFollow}
              onSelect={(eventkey: any) => {
                setFollow(eventkey);
                eventArr.splice(eventArr.length, 0);
              }}
            >
              <Tab eventKey="all" title="ALL">
                <TabsContent>
                  <div className="allTabText" style={{ display: "none" }}>
                    <h3>Plan</h3>
                    <p>Plans are for one-time groups of people like a dinner</p>
                    <p>
                      You can create a group chat below and Share plans with
                      your group to create one-off plans
                    </p>
                  </div>
                  <div className="allTabText" style={{ display: "none" }}>
                    <h3>Chats</h3>
                    <p>
                      Creating a group chat allows the people you are connected
                      with frequently to chat about places you want to stay,
                      things you want to do.
                    </p>
                    <p>
                      Share things with your group, including external links.
                      You can create separate plans for your group, so you can
                      keep track of multiple plans/trips with the same group.
                    </p>
                    <p>
                      Make a plan instead for people that will only be a part of
                      that plan.
                    </p>
                  </div>

                  <PlanChats>
                    {/* {planList?.length === 0 && <h3>No plan available</h3>} */}
                    {/* <PlanChatsGroups>
                      <PlanChatsCounter>15</PlanChatsCounter>
                    </PlanChatsGroups> */}

                    {/* <PlanChatsGroups> */}
                    {/* <h4>
                        Trip to Vegas <ChatsTime>5 Dec , 12:30pm</ChatsTime>
                      </h4>
                      <ul>
                        <li>
                          <img src={Kathy} alt="kathy" />
                        </li>
                        <li>
                          <img src={James} alt="james" />
                        </li>
                        <li>
                          <img src={JasonPamel} alt="jason-pamel" />
                        </li>
                      </ul> */}
                    {/* <PlanChatsCounter>5</PlanChatsCounter> */}
                    {/* </PlanChatsGroups> */}

                    <h3>Plan Chats</h3>

                    <PlanChatsGroupsList>
                      {allTabPlanList
                        ?.slice(0, 2)
                        .map((itemValue: any, index: any) => {
                          return (
                            <PlanChatsGroups>
                              <PlanChatsGroupsInner
                                onClick={() => {
                                  setFollow("planChat");
                                  setPlanId(itemValue._id);
                                  setPlanName(itemValue.plan_name);
                                  // setCountProps(itemValue.count);
                                  countHandler(itemValue?._id);
                                }}
                              >
                                <h4>
                                  {itemValue?.invited_users?.length > 0
                                    ? itemValue?.plan_name
                                    : ""}
                                  {/* {itemValue?.plan_name}{" "} */}
                                  <ChatsTime>
                                    {moment(itemValue?.createdAt).format(
                                      "DD MMM, hh:mm A "
                                    )}
                                  </ChatsTime>
                                </h4>
                                <ul>
                                  {itemValue.creator && (
                                    <li>
                                      <img
                                        src={itemValue?.creator?.creatorPhoto}
                                        alt={
                                          itemValue?.creator?.creatorUsername
                                        }
                                      />
                                    </li>
                                  )}
                                  {itemValue?.invited_users
                                    ?.slice(0, 2)
                                    .map((itemData: any) => {
                                      return (
                                        <li>
                                          <img
                                            src={
                                              planList?.data?.data[0]?.link +
                                              itemData?.photo
                                            }
                                            alt={itemData?.userName}
                                          />
                                        </li>
                                      );
                                    })}
                                  {itemValue?.invited_users?.length > 2 ? (
                                    <li>
                                      <button className="btn ChatsTotalMember">
                                        +
                                        {itemValue?.invited_users !== undefined
                                          ? itemValue?.invited_users?.slice(2)
                                              .length
                                          : ""}
                                      </button>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {itemValue?.invited_users?.length === 0 ? (
                                    <span>{itemValue?.plan_name}</span>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                                <PlanChatsCounter>
                                  {checkCountHandle(itemValue?._id)}
                                </PlanChatsCounter>
                              </PlanChatsGroupsInner>
                            </PlanChatsGroups>
                          );
                        })}
                    </PlanChatsGroupsList>

                    {planList?.data?.data?.length !== 0 ? (
                      <Button
                        type="button"
                        onClick={() => {
                          setFollow("plans");
                        }}
                        className="seeMore"
                      >
                        See plans...
                      </Button>
                    ) : (
                      <p className="noMsgTxt">No plan available</p>
                    )}
                  </PlanChats>

                  <PlanChats>
                    {/* {groupListData?.length === 0 &&
                    planList?.data?.data?.length === 0 ? (
                      <h3>No group & Plant not available</h3>
                    ) : null} */}

                    <h3>Chats</h3>

                    <PlanChatsGroupsList>
                      {groupListData
                        ?.slice(0, 2)
                        .map((item: any, index: any) => {
                          return (
                            <>
                              <PlanChatsGroups>
                                <PlanChatsGroupsInner
                                  onClick={() => {
                                    setFollow("chat");
                                    setGroupId(item?._id);
                                    setGroupName(item?.name);
                                    countHandler(item?._id);
                                  }}
                                >
                                  <h4>
                                    {item?.members?.length > 1
                                      ? item?.name
                                      : ""}
                                    {/* {item?.name} */}
                                    <ChatsTime>
                                      {moment(item?.createdAt).format(
                                        "DD MMM, hh:mm A "
                                      )}
                                    </ChatsTime>
                                  </h4>
                                  <ul>
                                    {item?.members !== undefined
                                      ? item?.members
                                          ?.slice(0, 4)
                                          .map((item2: any) => {
                                            return (
                                              <>
                                                <li>
                                                  <img
                                                    src={`${
                                                      group_data?.data?.data[0]
                                                        ?.link + item2?.photo
                                                    }`}
                                                    alt="kathy"
                                                  />
                                                </li>
                                              </>
                                            );
                                          })
                                      : null}
                                    {item?.members?.length > 4 ? (
                                      <li>
                                        <button className="btn ChatsTotalMember">
                                          +
                                          {item?.members !== undefined
                                            ? item?.members?.slice(4).length
                                            : ""}
                                        </button>
                                      </li>
                                    ) : (
                                      ""
                                    )}

                                    {item?.members?.length === 1 ? (
                                      <span>{item?.name}</span>
                                    ) : (
                                      ""
                                    )}

                                    {/* <h6>{item?.count}</h6> */}
                                  </ul>
                                  <PlanChatsCounter>
                                    {checkCountHandle(item._id)}
                                  </PlanChatsCounter>
                                </PlanChatsGroupsInner>
                              </PlanChatsGroups>
                            </>
                          );
                        })}
                    </PlanChatsGroupsList>
                    {/* <PlanChatsCounter>5</PlanChatsCounter> */}

                    {groupListData?.length !== 0 ? (
                      <Button
                        type="button"
                        onClick={() => {
                          setFollow("groups");
                        }}
                        className="seeMore"
                      >
                        See groups...
                      </Button>
                    ) : (
                      <p className="noMsgTxt">No group available</p>
                    )}
                  </PlanChats>
                </TabsContent>
              </Tab>

              <Tab eventKey="plans" title="PLANS">
                {planList?.isLoading && (
                  <SearchListLoader>
                    <img src={PageLoaderIcon} alt="Loading..." />
                  </SearchListLoader>
                )}
                <TabsContent>
                  <PlanChats>
                    {planList?.data?.data?.length === 0 && (
                      <p className="noMsgTxt">No plan available</p>
                    )}
                    {allTabPlanList?.map((itemValue: any, index: any) => {
                      return (
                        <PlanChatsGroups>
                          <PlanChatsGroupsInner
                            onClick={() => {
                              setFollow("planChat");
                              setPlanId(itemValue._id);
                              setPlanName(itemValue.plan_name);
                              countHandler(itemValue._id);
                              // setCountProps(itemValue.count);
                            }}
                          >
                            <h4>
                              {itemValue?.invited_users?.length > 0
                                ? itemValue?.plan_name
                                : ""}
                              {/* {itemValue?.plan_name}{" "} */}

                              <ChatsTime>
                                {moment(itemValue?.createdAt).format(
                                  "DD MMM, hh:mm A "
                                )}
                              </ChatsTime>
                            </h4>
                            <ul>
                              {itemValue.creator && (
                                <li>
                                  <img
                                    src={itemValue?.creator?.creatorPhoto}
                                    alt={itemValue?.creator?.creatorUsername}
                                  />
                                </li>
                              )}
                              {itemValue?.invited_users
                                ?.slice(0, 2)
                                ?.map((itemData: any) => {
                                  return (
                                    <li>
                                      <img
                                        src={
                                          planList?.data?.data[0]?.link +
                                          itemData?.photo
                                        }
                                        alt={itemData?.userName}
                                      />
                                    </li>
                                  );
                                })}
                              {itemValue?.invited_users?.length > 2 ? (
                                <li>
                                  <button className="btn ChatsTotalMember">
                                    +{itemValue?.invited_users?.slice(2).length}
                                  </button>
                                </li>
                              ) : (
                                ""
                              )}

                              {itemValue?.invited_users?.length === 0 ? (
                                <span>{itemValue?.plan_name}</span>
                              ) : (
                                ""
                              )}
                            </ul>
                            <PlanChatsCounter>
                              {checkCountHandle(itemValue._id)}
                            </PlanChatsCounter>
                          </PlanChatsGroupsInner>
                        </PlanChatsGroups>
                      );
                    })}
                  </PlanChats>
                </TabsContent>
              </Tab>

              <Tab eventKey="groups" title="GROUPS">
                <TabsContent>
                  <PlanChats>
                    {(group_data?.isLoading || exitGroup?.isLoading) && (
                      <SearchListLoader>
                        <img src={PageLoaderIcon} alt="Loading..." />
                      </SearchListLoader>
                    )}
                    {groupListData?.length === 0 && (
                      <p className="noMsgTxt">No group available</p>
                    )}

                    {/* {group_data?.data?.data[0]?.GroupList.map((item: any) => { */}
                    {groupListData?.map((item: any, index: any) => {
                      return (
                        <PlanChatsGroups>
                          <PlanChatsGroupsInner
                            onClick={() => {
                              setFollow("chat");
                              setGroupId(item._id);
                              setGroupName(item.name);
                              countHandler(item._id);
                              // setCountProps(item.count);
                            }}
                          >
                            <h4>
                              {item?.members?.length > 1 ? item?.name : ""}
                              <ChatsTime>
                                {moment(item?.createdAt).format(
                                  "DD MMM, hh:mm A "
                                )}
                              </ChatsTime>
                            </h4>
                            <ul>
                              {item?.members !== undefined
                                ? item?.members
                                    ?.slice(0, 4)
                                    .map((item2: any) => {
                                      return (
                                        <>
                                          <li>
                                            <img
                                              src={`${
                                                group_data?.data?.data[0]
                                                  ?.link + item2?.photo
                                              }`}
                                              alt="kathy"
                                            />
                                          </li>
                                        </>
                                      );
                                    })
                                : null}
                              {item?.members?.length > 4 ? (
                                <li>
                                  <button className="btn ChatsTotalMember">
                                    +
                                    {item?.members !== undefined
                                      ? item?.members?.slice(4).length
                                      : ""}
                                  </button>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.members?.length === 1 ? (
                                <span>{item?.name}</span>
                              ) : (
                                ""
                              )}
                              {/* <h6>{item?.count}</h6> */}
                            </ul>
                            <PlanChatsCounter>
                              {checkCountHandle(item._id)}
                            </PlanChatsCounter>
                          </PlanChatsGroupsInner>
                        </PlanChatsGroups>
                      );
                    })}
                  </PlanChats>
                </TabsContent>
              </Tab>
            </Tabs>
          </TabsWrap>
        ) : (
          ""
        )}

        {getFollow == "chat" ? (
          <>
            {/* <div
              onClick={() => {
                setFollow("groups");
              }}
            >
              Back
            </div> */}
            <SocketPanel
              id={groupId}
              name={groupName}
              socket={socket}
              form={submitForm}
              activeTab={"groups"}
              countProps={countProps}
              setFollow={setFollow}
            />
          </>
        ) : (
          ""
        )}
        {getFollow == "planChat" ? (
          <div>
            {/* <div
              onClick={() => {
                setFollow("groups");
              }}
            >
              Back
            </div> */}
            <PlanPanel
              id={planId}
              name={planName}
              socket={socket}
              form={submitForm}
              activeTab={"plans"}
              countProps={countProps}
            />
          </div>
        ) : (
          ""
        )}
        <TabsWrap className="GroupDetailsTab" style={{ display: "none" }}>
          <Tabs defaultActiveKey="plansDetails">
            <Tab eventKey="plansDetails" title="Plans Details">
              <TabsContent>
                <GroupChatWrap>
                  <GroupChatTitle>
                    <button type="button" className="btn back-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.535"
                        height="14.949"
                        viewBox="0 0 8.535 14.949"
                      >
                        <g transform="translate(1.061 0.53)">
                          <path
                            d="M1432,3924l-6.944,6.944,6.944,6.944"
                            transform="translate(-1425.056 -3924)"
                            fill="none"
                            stroke="#9597a6"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </button>
                    Trip to Vegas
                  </GroupChatTitle>

                  <ul className="chatMessageList">
                    <li>
                      <GroupChatDate>
                        <span>7 Dec</span>
                      </GroupChatDate>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>07:30pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={JasonPamel} alt="jason-pamel" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            OMG, look where Kylie went this weekend !!!
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>07:31pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={SandraJoseph} alt="sandra-joseph" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>WE HAVE TO GO!</ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>07:35pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={PeterJohn} alt="peter-john" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>
                              Stacy suggested Coachella
                              <Link to="#">https://xd.adobe.com/view....</Link>
                            </p>
                            <ChatBodyAttachment>
                              <img src={ChatAttachment} alt="chat-attachment" />
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle cx="8" cy="8" r="8" fill="#fff" />
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ChatBodyAttachment>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem className="userSendChat">
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">Me</Link>
                          </ChatUserName>
                          <ChatTime>07:36pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={UserImage} alt="user-image" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>I like it !!!</p>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>07:38pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={CandyBrian} alt="candy-brian" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>OMG look where Kylie went</p>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>07:40pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={Kathy} alt="kathy" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>WE HAVE TO GO!</p>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem className="userSendChat">
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">Me</Link>
                          </ChatUserName>
                          <ChatTime>07:55pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={UserImage} alt="user-image" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>
                              Stacy suggested Coachella
                              <Link to="#">https://xd.adobe.com/view....</Link>
                            </p>
                            <ChatBodyAttachment>
                              <img src={ChatAttachment} alt="chat-attachment" />
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle cx="8" cy="8" r="8" fill="#fff" />
                                    <path
                                      d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                      transform="translate(2.459 16.62)"
                                      fill="#3b3b3b"
                                    />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    Add to collection
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Add to Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Create Plans
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share to Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    Share With
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ChatBodyAttachment>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                    <li>
                      <GroupChatItem>
                        <GroupChatHead>
                          <ChatUserName>
                            <Link to="#">@username</Link>
                          </ChatUserName>
                          <ChatTime>08:05pm</ChatTime>
                        </GroupChatHead>
                        <GroupChatBody>
                          <ChatUserImage>
                            <Link to="#">
                              <img src={James} alt="james" />
                            </Link>
                          </ChatUserImage>
                          <ChatUserMessage>
                            <p>Stacy suggested Coachella</p>
                          </ChatUserMessage>
                        </GroupChatBody>
                      </GroupChatItem>
                    </li>
                  </ul>

                  <ChatMessageAction>
                    <Form>
                      <FormGroup>
                        <Form.Label>
                          <GroupChatHead>
                            <ChatUserName>
                              <Link to="#">@username</Link>
                            </ChatUserName>
                            <ChatTime>08:05pm</ChatTime>
                          </GroupChatHead>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Type here..."
                        />
                      </FormGroup>
                      <ChatMessageSendBtn>
                        <button type="submit" className="btn planDetailsBtn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.512"
                            height="11.896"
                            viewBox="0 0 6.512 11.896"
                          >
                            <g transform="translate(1.013 0.504)">
                              <path
                                d="M1430,3924l-4.944,5.444,4.944,5.444"
                                transform="translate(-1425.056 -3924)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                          PLAN DETAILS
                        </button>
                        <button type="submit" className="btn">
                          SEND
                        </button>
                      </ChatMessageSendBtn>
                    </Form>
                  </ChatMessageAction>
                </GroupChatWrap>
              </TabsContent>
            </Tab>

            <Tab eventKey="people" title="People">
              <TabsContent>
                <GroupChatWrap>
                  <GroupChatTitle>
                    <Button type="button" className="btn back-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.535"
                        height="14.949"
                        viewBox="0 0 8.535 14.949"
                      >
                        <g transform="translate(1.061 0.53)">
                          <path
                            d="M1432,3924l-6.944,6.944,6.944,6.944"
                            transform="translate(-1425.056 -3924)"
                            fill="none"
                            stroke="#9597a6"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </Button>
                    Trip to Vegas
                    <Button className="btn addFriendsBtn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                      >
                        <defs>
                          <clipPath>
                            <rect width="21.911" height="21.731" fill="#fff" />
                          </clipPath>
                        </defs>
                        <circle cx="24" cy="24" r="24" fill="#4d27da" />
                        <g transform="translate(13.045 13.134)">
                          <g clip-path="url(#clip-path)">
                            <path
                              d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                              transform="translate(-219.503 -75.638)"
                              fill="#fff"
                            />
                            <path
                              d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                              transform="translate(-19.846 -182.45)"
                              fill="#fff"
                            />
                            <path
                              d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                  </GroupChatTitle>

                  <GroupDetailMembers>
                    <GroupMemberItem>
                      <img src={JasonPamel} alt="jason-pamel" />
                      <h4>Diana Ray</h4>
                      <Button className="btn creator-btn">Creator</Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={SandraJoseph} alt="sandra-joseph" />
                      <h4>Daniel Smith</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={PeterJohn} alt="peter-john" />
                      <h4>Joseph</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={Kathy} alt="kathy" />
                      <h4>Jeremy</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>
                  </GroupDetailMembers>
                </GroupChatWrap>
              </TabsContent>
            </Tab>
          </Tabs>
        </TabsWrap>

        <TabsWrap className="GroupDetailsTab" style={{ display: "none" }}>
          <Tabs defaultActiveKey="groupDetails">
            <Tab eventKey="groupDetails" title="Group Details">
              <TabsContent>
                <GroupChatWrap>
                  <GroupChatTitle>
                    <Button type="button" className="btn back-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.535"
                        height="14.949"
                        viewBox="0 0 8.535 14.949"
                      >
                        <g transform="translate(1.061 0.53)">
                          <path
                            d="M1432,3924l-6.944,6.944,6.944,6.944"
                            transform="translate(-1425.056 -3924)"
                            fill="none"
                            stroke="#9597a6"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </Button>
                    Trip to Vegas
                    <Button className="btn addFriendsBtn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                      >
                        <defs>
                          <clipPath>
                            <rect width="21.911" height="21.731" fill="#fff" />
                          </clipPath>
                        </defs>
                        <circle cx="24" cy="24" r="24" fill="#4d27da" />
                        <g transform="translate(13.045 13.134)">
                          <g clip-path="url(#clip-path)">
                            <path
                              d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                              transform="translate(-219.503 -75.638)"
                              fill="#fff"
                            />
                            <path
                              d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                              transform="translate(-19.846 -182.45)"
                              fill="#fff"
                            />
                            <path
                              d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                  </GroupChatTitle>

                  <GroupDetailMembers>
                    <GroupMemberItem>
                      <img src={JasonPamel} alt="jason-pamel" />
                      <h4>Diana Ray</h4>
                      <Button className="btn creator-btn">Creator</Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={SandraJoseph} alt="sandra-joseph" />
                      <h4>Daniel Smith</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={PeterJohn} alt="peter-john" />
                      <h4>Joseph</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>

                    <GroupMemberItem>
                      <img src={Kathy} alt="kathy" />
                      <h4>Jeremy</h4>
                      <Button
                        className="btn"
                        onClick={() => setDeleteUserModalShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g fill="#fff" stroke="#707070" stroke-width="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                          </g>
                          <path
                            d="M7700.14-13803.409h8.662"
                            transform="translate(-7694.471 13813.409)"
                            fill="none"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </svg>
                      </Button>
                    </GroupMemberItem>
                  </GroupDetailMembers>
                </GroupChatWrap>
              </TabsContent>
            </Tab>

            <Tab eventKey="groupSave" title="Group Save">
              <TabsContent>
                <GroupChatTitle className="groupSaveTitle">
                  <Button type="button" className="btn back-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.535"
                      height="14.949"
                      viewBox="0 0 8.535 14.949"
                    >
                      <g transform="translate(1.061 0.53)">
                        <path
                          d="M1432,3924l-6.944,6.944,6.944,6.944"
                          transform="translate(-1425.056 -3924)"
                          fill="none"
                          stroke="#9597a6"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </Button>
                  Trip to Vegas Group save
                </GroupChatTitle>

                <GroupSaveGallery>
                  <li>
                    <GroupSaveGalleryItem>
                      <Button onClick={() => setDeleteUserModalShow(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-1198.912 -287.912)">
                            <g transform="translate(-1 1)">
                              <circle
                                cx="12"
                                cy="12"
                                r="12"
                                transform="translate(1199.912 286.912)"
                                fill="#fff"
                              />
                              <g transform="translate(1207.449 293.174)">
                                <g transform="translate(0 0)">
                                  <path
                                    d="M8.138,14.7a1.279,1.279,0,0,0,1.275,1.275h5.1A1.279,1.279,0,0,0,15.789,14.7V7.05H8.138Zm1.569-4.54.9-.9,1.358,1.352,1.352-1.352.9.9-1.352,1.352,1.352,1.352-.9.9-1.352-1.352-1.352,1.352-.9-.9,1.352-1.352Zm4.489-5.024L13.557,4.5H10.369l-.638.638H7.5V6.413h8.927V5.138Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#3b3b3b"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <img src={GalleryImage1} alt="gallery-image" />
                    </GroupSaveGalleryItem>
                  </li>
                  <li>
                    <GroupSaveGalleryItem>
                      <Button onClick={() => setDeleteUserModalShow(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-1198.912 -287.912)">
                            <g transform="translate(-1 1)">
                              <circle
                                cx="12"
                                cy="12"
                                r="12"
                                transform="translate(1199.912 286.912)"
                                fill="#fff"
                              />
                              <g transform="translate(1207.449 293.174)">
                                <g transform="translate(0 0)">
                                  <path
                                    d="M8.138,14.7a1.279,1.279,0,0,0,1.275,1.275h5.1A1.279,1.279,0,0,0,15.789,14.7V7.05H8.138Zm1.569-4.54.9-.9,1.358,1.352,1.352-1.352.9.9-1.352,1.352,1.352,1.352-.9.9-1.352-1.352-1.352,1.352-.9-.9,1.352-1.352Zm4.489-5.024L13.557,4.5H10.369l-.638.638H7.5V6.413h8.927V5.138Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#3b3b3b"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <img src={GalleryImage2} alt="gallery-image" />
                    </GroupSaveGalleryItem>
                  </li>
                  <li>
                    <GroupSaveGalleryItem>
                      <Button onClick={() => setDeleteUserModalShow(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-1198.912 -287.912)">
                            <g transform="translate(-1 1)">
                              <circle
                                cx="12"
                                cy="12"
                                r="12"
                                transform="translate(1199.912 286.912)"
                                fill="#fff"
                              />
                              <g transform="translate(1207.449 293.174)">
                                <g transform="translate(0 0)">
                                  <path
                                    d="M8.138,14.7a1.279,1.279,0,0,0,1.275,1.275h5.1A1.279,1.279,0,0,0,15.789,14.7V7.05H8.138Zm1.569-4.54.9-.9,1.358,1.352,1.352-1.352.9.9-1.352,1.352,1.352,1.352-.9.9-1.352-1.352-1.352,1.352-.9-.9,1.352-1.352Zm4.489-5.024L13.557,4.5H10.369l-.638.638H7.5V6.413h8.927V5.138Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#3b3b3b"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <img src={GalleryImage3} alt="gallery-image" />
                    </GroupSaveGalleryItem>
                  </li>
                </GroupSaveGallery>
              </TabsContent>
            </Tab>
          </Tabs>
        </TabsWrap>
      </TabbingPanel>
      <DeleteUserModal
        show={deleteUserModalShow}
        onHide={() => setDeleteUserModalShow(false)}
      />
    </>
  );
};

export default RightTabbingPanel;
