export enum UserType {
  SET_USER = "SET_USER",
  LOGOUT_USER = "LOGOUT_USER",
  USER_LIST = "USER_LIST",
  USER_LIST_FAILED = "USER_LIST_FAILED",
  USER_LIST_SUCCESS = "USER_LIST_SUCCESS",
}

export const setUser = (data: any) => (dispatch: any) => {
  dispatch({
    type: UserType.SET_USER,
    data,
  });
};
export const logoutUser = () => (dispatch: any) => {
  dispatch({
    type: UserType.LOGOUT_USER,
  });
};
