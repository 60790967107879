import { copyPlanAPI } from "./../../../services/copyPlan";

export enum copyPlanType {
  COPY_PLAN = "COPY_PLAN",
  COPY_PLAN_SUCCESS = "COPY_PLAN_SUCCESS",
  COPY_PLAN_FALIURE = "COPY_PLAN_FALIURE",
}

export const copyPlan = () => (dispatch: any) => {
  dispatch({
    type: copyPlanType.COPY_PLAN,
  });
};

export const copyPlanSucess = (data: any) => (dispatch: any) => {
  dispatch({
    type: copyPlanType.COPY_PLAN_SUCCESS,
    data,
  });
};

export const copyPlanFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: copyPlanType.COPY_PLAN_FALIURE,
    error: { msg },
  });
};

export const copyPlanHandler = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(copyPlan());
    const result: any = await copyPlanAPI(finalData);
    dispatch(copyPlanSucess(result));
  } catch (e: any) {
    dispatch(copyPlanFailed(e?.response?.data?.message));
  }
};
