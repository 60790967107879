import { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Dropdown,
  Button,
  CloseButton,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SearchBanner from "../../assets/images/search-banner.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SearchBoxWrap,
  SearchBoxBanner,
  SearchBoxInner,
  SearchBoxTopBtn,
  SearchBox,
  FormGroup,
  LocationSearchInput,
  SearchBoxDate,
  SearchListDropDown,
} from "./mainSearchPanelStyle";

import moment from "moment";
import LeftSearchPanel from "../leftSearchPanel/index";
import MapComponent from "./../mapComponent/mapComponent";
import { mapKey } from "../../resource/constants";
import Geocode from "react-geocode";
import LocationInput from "./locationInput";
import { hikingData } from "../../redux/action/hiking/hiking";
import { latLongInfoSuccess } from "../../redux/action/lat-long/latLong";
import { globalSearchData } from "../../redux/action/global-search/globalSearch";
import { getAllEventHandler } from "../../redux/action/getAllEvent/getAllEvent";
import currentLocation from "../../redux/reducer/current-location/currentLocation";
import { currentLocationInfoSuccess } from "../../redux/action/current-location/currentLocation";

const MainSearchPanel = (props: any) => {
  const [status, setStatus] = useState<any>(null);
  const [addressInfo, setAddressInfo] = useState("");
  const [cityInfo, setCityInfo] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();

  const latLongInfo = useSelector((state: any) => state?.latLong);

  const navigate: any = useNavigate();

  const location: any = useLocation();

  const refSearch: any = useRef(null);

  const [showDropDown, setShowDropDown] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refSearch.current && !refSearch.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showDropDown]);

  const getLocation = (position: any) => {
    Geocode.setApiKey("AIzaSyBW_S7WT9E1ExwvcGcxOOl1r3tA4LAtcno");
    Geocode.setLanguage("en");
    if (!navigator.geolocation) {
      setStatus("");
    } else {
      setStatus("");
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setStatus(null);
          // setLat(position.coords.latitude);
          // setLng(position.coords.longitude);
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            (response: any) => {
              let address = response.results[0].formatted_address;
              setAddressInfo(address);

              let city, country;
              for (
                let i = 0;
                i < response.results[0].address_components.length;
                i++
              ) {
                for (
                  let j = 0;
                  j < response.results[0].address_components[i].types.length;
                  j++
                ) {
                  switch (response.results[0].address_components[i].types[j]) {
                    case "locality":
                      city =
                        response.results[0].address_components[i].long_name;
                      break;

                    case "country":
                      country =
                        response.results[0].address_components[i].long_name;
                      break;
                  }
                }
              }
              setCityInfo(city);
              const finalData = {
                city: city,
              };

              const latInfo = {
                lat: position.coords.latitude,
              };
              const longInfo = {
                lng: position.coords.longitude,
              };
              localStorage.setItem("locationInfo", JSON.stringify(city));
              localStorage.setItem("location", JSON.stringify(city));
              localStorage.setItem("lat", JSON.stringify(latInfo.lat));
              localStorage.setItem("long", JSON.stringify(longInfo.lng));
              localStorage.setItem("latitude-1", latInfo.lat);
              localStorage.setItem("longitude-1", longInfo.lng);
              dispatch(currentLocationInfoSuccess(city));
            },

            (error: any) => {
              console.error(error);
            }
          );
        },
        () => {
          setStatus("");
        }
      );
    }
  };

  const getLocationTest: any = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  };

  const showPosition = (position: any) => {
    getLocation(position);
  };

  // useEffect(() => {
  //   localStorage.setItem("locationInfo", JSON.stringify("San Jose"));
  //   localStorage.setItem("location", JSON.stringify("San Jose"));
  //   localStorage.setItem("lat", JSON.stringify("37.33874"));
  //   localStorage.setItem("long", JSON.stringify("-121.8852525"));
  //   localStorage.setItem("latitude-1", "37.33874");
  //   localStorage.setItem("longitude-1", "-121.8852525");
  // }, []);

  useEffect(() => {
    const localInfo = localStorage.getItem("location");
    if (!localInfo) {
      getLocationTest();
    }
  }, []);

  useEffect(() => {
    // const finalData = {
    //   lat: parseFloat(props.place?.geometry?.location?.lat()),
    //   lng: parseFloat(props.place?.geometry?.location?.lng()),
    //   name: props.place?.name,
    //   item: props.place,
    // };
    // dispatch(latLongInfoSuccess(finalData));
    if (props.place?.geometry?.location) {
      const lats: any = parseFloat(props.place?.geometry?.location?.lat());
      const longs: any = parseFloat(props.place?.geometry?.location?.lng());
      localStorage.setItem("location", JSON.stringify(props.place?.name));
      localStorage.setItem("lat", JSON.parse(lats));
      localStorage.setItem("long", JSON.parse(longs));
    } else {
      // props?.setPlace(latLongInfo?.data?.city);
    }
  }, [props?.place?.geometry?.location, latLongInfo]);

  const globalSearchList = useSelector((state: any) => state?.globalSearch);

  const latLong: any = useSelector((state: any) => state?.latLong);

  const searchTextData = (event: any) => {
    setSearchTerm(event);
    const finalValue = {
      search: event.trim(),
      location: latLong?.data?.name
        ? latLong?.data?.name
        : typeof local !== "object" && local !== ""
          ? local
          : "",
      lat: latLong?.data?.item?.lat || "37.33874",
      long: latLong?.data?.item?.lng || "-121.8852525",
    };

    if (
      event.trim() !== "" &&
      props.getAllTab === "ALL" &&
      event.trim()?.length >= 3
    ) {
      dispatch(globalSearchData(finalValue));
    }
  };

  const navigateTohotel = (id: any) => {
    navigate(`/hotel-result-details/${id}`);
    setShowDropDown(false);
  };
  const navigateToRental = (id: any) => {
    navigate(`/rental-result-details/${id}`);
    setShowDropDown(false);
  };
  const navigateToConcert = (id: any) => {
    navigate(`/concert-result-details/${id}`);
    setShowDropDown(false);
    // setSearchTerm(name);
  };
  const navigateToHiking = (id: any) => {
    navigate(`/hiking-result-details/${id}`);
    setShowDropDown(false);
  };
  const navigateToFoodAndDrink = (id: any) => {
    navigate(`/food-and-drink-details/${id}`);
    setShowDropDown(false);
  };

  const navigateToPointOfInterset = (id: any) => {
    navigate(`/interest-details-results/${id}`);
    setShowDropDown(false);
    // setSearchTerm(name);
  };

  const navigateToFestival = (id: any) => {
    navigate(`/festival-result-details/${id}`);
    setShowDropDown(false);
  };
  const navigateToSports = (id: any) => {
    navigate(`/sports-results-details/${id}`);
    setShowDropDown(false);
  };

  const local: any = JSON.parse(localStorage.getItem("location") || "{}");
  const localInfo: any = JSON.parse(
    localStorage.getItem("locationInfo") || "{}"
  );

  const latitude1Info: any = JSON.parse(
    localStorage.getItem("latitude-1") || "{}"
  );
  const longitude1Info: any = JSON.parse(
    localStorage.getItem("longitude-1") || "{}"
  );

  const concertEvents = useSelector((state: any) => state?.allEvents);
  const getFestivalList = useSelector((state: any) => state?.getFestivalList);
  const getSportsList = useSelector((state: any) => state?.getSportEventsList);
  const rentalData = useSelector((state: any) => state?.getRentals);
  const hotelResult = useSelector((state: any) => state?.getAllHotel);
  const getAllFoodDrinks = useSelector((state: any) => state?.getAllFoodDrinks);

  const hiking = useSelector((state: any) => state?.hiking);
  const pointOfInterest = useSelector((state: any) => state?.pointOfInterest);

  const currentLocation = useSelector((state: any) => state?.currentLocation);
  const locationInfo: any = useLocation();

  const closeDropDown = (event: any) => {
    localStorage.removeItem("main-search-panel");
    // localStorage.setItem("main-search-panel-data", event.target.value);
    if (event.key === "Enter") {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    if (
      locationInfo.pathname.split("/")[2]?.length >= 5 &&
      locationInfo.pathname.split("/")[2]?.length <= 34
    ) {
      setSearchTerm("");
    } else {
      const localStorageData: any = localStorage.getItem(
        "main-search-panel-data"
      );
      setSearchTerm(localStorageData);
    }
  }, []);

  const onSubmitInfo = (event: any) => {
    localStorage.setItem("main-search-panel-data", event.target.value);
  };

  return (
    <>
      <SearchBoxWrap>
        <SearchBoxBanner>
          <img src={SearchBanner} alt="search-banner" />
        </SearchBoxBanner>
        <SearchBoxInner>
          <SearchBoxTopBtn>
            <Dropdown className="searchLocation">
              <Dropdown.Toggle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.338"
                  height="20.482"
                  viewBox="0 0 14.338 20.482"
                >
                  <path
                    d="M14.669,3A7.164,7.164,0,0,0,7.5,10.169c0,5.377,7.169,13.314,7.169,13.314s7.169-7.937,7.169-13.314A7.164,7.164,0,0,0,14.669,3Zm0,9.729a2.56,2.56,0,1,1,2.56-2.56A2.561,2.561,0,0,1,14.669,12.729Z"
                    transform="translate(-7.5 -3)"
                    fill="#fff"
                  />
                </svg>
                {currentLocation.data !== null
                  ? currentLocation?.data
                  : typeof local !== "object" && local !== ""
                    ? local
                    : "Location"}

                {localStorage.getItem("locationInfo") !==
                  localStorage.getItem("location") && (
                  <>
                    {(props.place?.item?.address_components?.[0]?.long_name ||
                      // currentLocation?.data ||
                      localStorage.getItem("location")) && (
                      <svg
                        onClick={() => {
                          if (localStorage.getItem("locationInfo")) {
                            localStorage.setItem(
                              "location",
                              JSON.stringify(localInfo)
                            );
                            localStorage.setItem(
                              "lat",
                              JSON.stringify(latitude1Info)
                            );
                            localStorage.setItem(
                              "long",
                              JSON.stringify(longitude1Info)
                            );
                            dispatch(currentLocationInfoSuccess(localInfo));
                          } else {
                            setShow(true);
                            localStorage.removeItem("location");
                            localStorage.removeItem("lat");
                            localStorage.removeItem("long");
                            dispatch(currentLocationInfoSuccess(null));
                          }
                          let button: any =
                            document.querySelector(".dropdown-toggle");

                          button.setAttribute("aria-expanded", "false");
                          document
                            .querySelector(".dropdown-menu")
                            ?.classList.remove("show");
                          document
                            .querySelector(".searchLocation")
                            ?.classList.remove("show");
                          // localStorage.removeItem("location");
                          // localStorage.setItem(
                          //   "location",
                          //   JSON.stringify(localInfo)
                          // );
                          // localStorage.setItem(
                          //   "lat",
                          //   JSON.stringify(latitude1Info)
                          // );
                          // localStorage.setItem(
                          //   "long",
                          //   JSON.stringify(longitude1Info)
                          // );
                          // localStorage.removeItem("lat");
                          // localStorage.removeItem("long");
                          props.setPlace({});
                          dispatch({ type: "RESET_LAT_LONG" });
                        }}
                        style={{
                          color: "#212324",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        stroke="currentColor"
                        fill="#fff"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path>
                        <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                      </svg>
                    )}
                  </>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <LocationSearchInput>
                  <LocationInput
                    setPlace={props.setPlace}
                    setPageNumber={props.setPageNumber}
                    place={props.place}
                  />
                </LocationSearchInput>
              </Dropdown.Menu>
            </Dropdown>

            {!(
              props?.getSearchResult?.location === "food and drink" ||
              props?.getSearchResult?.location === "point of interest" ||
              props?.getSearchResult?.location === "hiking" ||
              props?.getSearchResult?.location === "concert-result-details" ||
              props?.getSearchResult?.location === "festival-result-details" ||
              props?.getSearchResult?.location === "rental" ||
              props?.getSearchResult?.location === "hotel" ||
              props?.getSearchResult?.location === "hotel-result-details" ||
              props?.getSearchResult?.location === "rental-result-details" ||
              props?.getSearchResult?.location === "food-and-drink-details" ||
              props?.getSearchResult?.location === "interest-details-results" ||
              props?.getSearchResult?.location === "hiking-result-details" ||
              props?.getSearchResult?.location === "sports-results-details" ||
              props?.getAllTab === "ALL"
            ) && (
              <SearchBoxDate>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.668"
                  height="17.979"
                  viewBox="0 0 17.668 17.979"
                >
                  <defs>
                    <clipPath>
                      <rect width="17.668" height="17.979" fill="#fff" />
                    </clipPath>
                  </defs>
                  <g clip-path="url(#clip-path)">
                    <path
                      d="M17.44,43.238a1.606,1.606,0,0,0-1.185-.791c-.175-.026-.22.016-.232.194,0,.064,0,.129,0,.193,0,.591.006,1.182,0,1.773a1.668,1.668,0,0,1-1.348,1.7,4.959,4.959,0,0,1-1.919,0,1.68,1.68,0,0,1-1.33-1.714c0-.6,0-1.194,0-1.791,0-.064,0-.129,0-.193a.167.167,0,0,0-.174-.179c-.053,0-.105,0-.158,0H6.592c-.31,0-.332.022-.333.326,0,.632,0,1.264,0,1.9a1.729,1.729,0,0,1-1.729,1.713c-.409-.014-.819,0-1.229,0a1.452,1.452,0,0,1-.72-.2,1.722,1.722,0,0,1-.918-1.571c-.01-.632,0-1.264,0-1.9,0-.239-.049-.275-.287-.238A1.656,1.656,0,0,0,.009,44.039q0,6.425,0,12.85a1.57,1.57,0,0,0,.567,1.2,1.4,1.4,0,0,0,.923.361H16.18a2.071,2.071,0,0,0,.528-.1,1.638,1.638,0,0,0,.963-1.532q0-6.355,0-12.709a1.651,1.651,0,0,0-.235-.87M14.729,56.732H2.913a.717.717,0,0,1-.81-.809q0-3.178,0-6.355a.716.716,0,0,1,.809-.809H14.728a.719.719,0,0,1,.817.817q0,3.169,0,6.338a.719.719,0,0,1-.816.819"
                      transform="translate(-0.007 -40.472)"
                      fill="#fff"
                    />
                    <path
                      d="M272.585,2.389V.721A.635.635,0,0,1,273.307,0c.322,0,.644,0,.965,0a.6.6,0,0,1,.647.615q.014,1.773,0,3.545a.6.6,0,0,1-.639.62c-.345,0-.69,0-1.036,0a.608.608,0,0,1-.658-.656c-.005-.579,0-1.158,0-1.738"
                      transform="translate(-260.058 0)"
                      fill="#fff"
                    />
                    <path
                      d="M62.634,2.409c0,.568,0,1.135,0,1.7a.617.617,0,0,1-.68.688q-.483,0-.965,0A.618.618,0,0,1,60.3,4.1c0-.614,0-1.229,0-1.843,0-.509,0-1.018,0-1.527a.632.632,0,0,1,.724-.717h.913a.627.627,0,0,1,.7.707q0,.843,0,1.685"
                      transform="translate(-57.526 -0.016)"
                      fill="#fff"
                    />
                  </g>
                </svg>

                <DatePicker
                  selected={props.getSearchDate}
                  onChange={(e: any) => {
                    props.setSearchDate(e);
                    props.setPageNumber(1);
                    props.setTodate(null);
                  }}
                  minDate={moment().toDate()}
                  dateFormat="MM-dd-yyyy"
                  placeholderText={"From Date"}
                  className="form-control"
                />
                  {props.getSearchDate !== null && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffff"
                    viewBox="0 0 50 50"
                    width="16px"
                    height="16px"
                    style={{ position: "relative", left: "-26px" }}
                    onClick={() => {
                      props.setSearchDate(null);
                    }}
                  >
                    <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" />
                  </svg>
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.668"
                  height="17.979"
                  viewBox="0 0 17.668 17.979"
                >
                  <defs>
                    <clipPath>
                      <rect width="17.668" height="17.979" fill="#fff" />
                    </clipPath>
                  </defs>
                  <g clip-path="url(#clip-path)">
                    <path
                      d="M17.44,43.238a1.606,1.606,0,0,0-1.185-.791c-.175-.026-.22.016-.232.194,0,.064,0,.129,0,.193,0,.591.006,1.182,0,1.773a1.668,1.668,0,0,1-1.348,1.7,4.959,4.959,0,0,1-1.919,0,1.68,1.68,0,0,1-1.33-1.714c0-.6,0-1.194,0-1.791,0-.064,0-.129,0-.193a.167.167,0,0,0-.174-.179c-.053,0-.105,0-.158,0H6.592c-.31,0-.332.022-.333.326,0,.632,0,1.264,0,1.9a1.729,1.729,0,0,1-1.729,1.713c-.409-.014-.819,0-1.229,0a1.452,1.452,0,0,1-.72-.2,1.722,1.722,0,0,1-.918-1.571c-.01-.632,0-1.264,0-1.9,0-.239-.049-.275-.287-.238A1.656,1.656,0,0,0,.009,44.039q0,6.425,0,12.85a1.57,1.57,0,0,0,.567,1.2,1.4,1.4,0,0,0,.923.361H16.18a2.071,2.071,0,0,0,.528-.1,1.638,1.638,0,0,0,.963-1.532q0-6.355,0-12.709a1.651,1.651,0,0,0-.235-.87M14.729,56.732H2.913a.717.717,0,0,1-.81-.809q0-3.178,0-6.355a.716.716,0,0,1,.809-.809H14.728a.719.719,0,0,1,.817.817q0,3.169,0,6.338a.719.719,0,0,1-.816.819"
                      transform="translate(-0.007 -40.472)"
                      fill="#fff"
                    />
                    <path
                      d="M272.585,2.389V.721A.635.635,0,0,1,273.307,0c.322,0,.644,0,.965,0a.6.6,0,0,1,.647.615q.014,1.773,0,3.545a.6.6,0,0,1-.639.62c-.345,0-.69,0-1.036,0a.608.608,0,0,1-.658-.656c-.005-.579,0-1.158,0-1.738"
                      transform="translate(-260.058 0)"
                      fill="#fff"
                    />
                    <path
                      d="M62.634,2.409c0,.568,0,1.135,0,1.7a.617.617,0,0,1-.68.688q-.483,0-.965,0A.618.618,0,0,1,60.3,4.1c0-.614,0-1.229,0-1.843,0-.509,0-1.018,0-1.527a.632.632,0,0,1,.724-.717h.913a.627.627,0,0,1,.7.707q0,.843,0,1.685"
                      transform="translate(-57.526 -0.016)"
                      fill="#fff"
                    />
                  </g>
                </svg>

                <DatePicker
                  selected={props.toDate}
                  onChange={(e: any) => {
                    props.setTodate(e);
                  }}
                  minDate={moment(props.getSearchDate).add(1, "days").toDate()}
                  disabled={props.getSearchDate === null ? true : false}
                  dateFormat="MM-dd-yyyy"
                  placeholderText={"To Date"}
                  className="form-control"
                />
                 {props?.toDate !== null && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffff"
                      viewBox="0 0 50 50"
                      width="16px"
                      height="16px"
                      style={{ position: "relative", left: "-26px" }}
                      onClick={() => {
                        props.setTodate(null);
                        props.setPageNumber(1);
                      }}
                    >
                      <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" />
                    </svg>
                  )}
                 
                </SearchBoxDate>
              )}
          </SearchBoxTopBtn>

          <SearchBox ref={refSearch}>
            <FormGroup>
              <Form.Control
                type="text"
                placeholder="Search for Concerts, Points of Interest, Hotels..."
                value={searchTerm}
                onChange={(event: any) => {
                  searchTextData(event.target.value);
                  onSubmitInfo(event);
                  props.setPageNumber(1);
                  setShowDropDown(true);
                  props.setSearchResult({
                    ...props.getSearchResult,
                    search: event.target.value,
                  });
                }}
                onKeyDown={closeDropDown}
              />
              <button type="button" className="searchIconBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.921"
                  height="22.01"
                  viewBox="0 0 21.921 22.01"
                >
                  <path
                    d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                    transform="translate(-3.002 -3.001)"
                    fill="#3b3b3b"
                  />
                </svg>
              </button>
            </FormGroup>

            {searchTerm?.length >= 3 && showDropDown && (
              <SearchListDropDown>
                <>
                  {props?.getSearchResult?.location === "concert" &&
                    concertEvents?.data?.data?.concerts?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateToConcert(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.name} {"-"}
                              {item?._embedded?.venue.city}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "food and drink" &&
                    getAllFoodDrinks?.data?.data?.results?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateTohotel(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.Name}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "point of interest" &&
                    pointOfInterest?.data?.data?.food?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateTohotel(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.Name}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "hiking" &&
                    hiking?.data?.data?.food?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateTohotel(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.Name}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "festival" &&
                    getFestivalList?.data?.data?.Festivals?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateToFestival(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.name} {"-"}
                              {item?._embedded?.venue.city}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "sports" &&
                    getSportsList?.data?.data?.Sports?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateToSports(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel-data",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.name} {" - "}
                              {item?._embedded?.venue?.city}
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  {props?.getSearchResult?.location === "rental" &&
                    rentalData?.data?.data?.get_rental?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateToRental(item?._id);
                                // localStorage.setItem(
                                //   "main-search-panel",
                                //   item?.name
                                // );
                              }}
                            >
                              {item?.Name} {" - "}
                              {item?.Location?.Address?.City}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props?.getSearchResult?.location === "hotel" &&
                    hotelResult?.data?.data?.hotel?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                navigateTohotel(item?._id);
                              }}
                            >
                              {item?.Name} {" - "}
                              {item?.Location?.Address?.City}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.hotel?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li onClick={() => navigateTohotel(item?._id)}>
                              {item?.Name}
                              {" - "}
                              {item?.Location?.Address?.City}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.rental?.map((item: any) => {
                      return (
                        <>
                          <ul>
                            <li onClick={() => navigateToRental(item?._id)}>
                              {item?.Name}
                              {" - "}
                              {item?.Location?.Address?.City}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.Concert_response?.map(
                      (item: any) => {
                        return (
                          <>
                            <ul>
                              <li onClick={() => navigateToConcert(item?._id)}>
                                {item?.name}
                                {" - "}
                                {item?._embedded?.venue?.city}
                              </li>
                            </ul>
                          </>
                        );
                      }
                    )}
                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.hiking?.map((item: any) => {
                      const location: any =
                        item?.plus_code?.compound_code.split(" ");

                      return (
                        <>
                          <ul>
                            <li onClick={() => navigateToHiking(item?._id)}>
                              {item?.name}
                              {" - "}
                              {location?.length > 0
                                ? location[1].slice(0, -1)
                                : ""}
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.food?.map((item: any) => {
                      const location: any =
                        item?.plus_code?.compound_code.split(" ");

                      return (
                        <>
                          <ul>
                            <li
                              onClick={() => navigateToFoodAndDrink(item?._id)}
                            >
                              {item?.name}
                              {" - "}
                              {location?.length > 0
                                ? location[1].slice(0, -1)
                                : ""}
                            </li>
                          </ul>
                        </>
                      );
                    })}

                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.pointOfInterest?.map(
                      (item: any) => {
                        const location: any =
                          item?.plus_code?.compound_code.split(" ");
                        return (
                          <>
                            <ul>
                              <li
                                onClick={() =>
                                  navigateToPointOfInterset(item?._id)
                                }
                              >
                                {item?.name}
                                {" - "}
                                {location?.length > 0
                                  ? location[1]?.slice(0, -1)
                                  : ""}
                              </li>
                            </ul>
                          </>
                        );
                      }
                    )}

                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.festival_response?.map(
                      (item: any) => {
                        return (
                          <>
                            <ul>
                              <li onClick={() => navigateToFestival(item?._id)}>
                                {item?.name}
                                {" - "}
                                {item?._embedded?.venue.city}
                              </li>
                            </ul>
                          </>
                        );
                      }
                    )}
                  {props.getAllTab === "ALL" &&
                    globalSearchList?.data?.data?.sports_response?.map(
                      (item: any) => {
                        return (
                          <>
                            <ul>
                              <li onClick={() => navigateToSports(item?._id)}>
                                {item?.name}
                                {" - "}
                                {item?._embedded?.venue?.city}
                              </li>
                            </ul>
                          </>
                        );
                      }
                    )}
                </>
              </SearchListDropDown>
            )}
            {/* </Form> */}
          </SearchBox>
        </SearchBoxInner>
      </SearchBoxWrap>
    </>
  );
};

export default MainSearchPanel;
