import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Res from "../resource";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TripdLogo from "../assets/images/tripd-logo.svg";

import {
  FooterWrap,
  FooterContainer,
  FooterLogo,
  FooterLinks,
  FooterSocial,
} from "./footerStyle";

function Footer() {
  const navigate = useNavigate();
  const data = [
    { routeName: "About Us", routeUrl: "/about-us" },
    { routeName: "Job", routeUrl: "https://angel.co/company/tripd/jobs" },
    // { routeName: "Press", routeUrl: "" },
    // { routeName: "Socials", routeUrl: "" },
    { routeName: "Partners", routeUrl: "https://www.tripd.co/partners.html" },
    { routeName: "Terms", routeUrl: "/terms-and-conditions" },

    { routeName: "Privacy Policy", routeUrl: "/privacy-policy" },
  ];

  return (
    <FooterWrap>
      <FooterContainer>
        <FooterLogo>
          <Link to="/">
            <img src={TripdLogo} alt="Tripd" />
          </Link>
        </FooterLogo>
        <FooterLinks>
          <ul>
            {data.map((option) => {
              return (
                <li>
                  {option?.routeUrl?.substring(0, 1) === "/" ? (
                    <Link to={option.routeUrl}>{option.routeName}</Link>
                  ) : (
                    <a href={option.routeUrl} target="_blank">
                      {option.routeName}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </FooterLinks>
        <FooterSocial>
          <h4>Socials</h4>
          <ul>
            <li>
              <a href="https://www.instagram.com/tripd_app/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.683"
                  height="19.678"
                  viewBox="0 0 19.683 19.678"
                >
                  <g transform="translate(0)">
                    <path
                      d="M9.838,7.031a5.045,5.045,0,1,0,5.045,5.045A5.037,5.037,0,0,0,9.838,7.031Zm0,8.325a3.28,3.28,0,1,1,3.28-3.28A3.286,3.286,0,0,1,9.838,15.357Zm6.428-8.532A1.177,1.177,0,1,1,15.09,5.648,1.174,1.174,0,0,1,16.266,6.825Zm3.341,1.194A5.823,5.823,0,0,0,18.018,3.9,5.862,5.862,0,0,0,13.9,2.307c-1.625-.092-6.494-.092-8.119,0A5.853,5.853,0,0,0,1.653,3.892,5.843,5.843,0,0,0,.064,8.015c-.092,1.625-.092,6.494,0,8.119a5.823,5.823,0,0,0,1.59,4.123,5.869,5.869,0,0,0,4.123,1.59c1.625.092,6.494.092,8.119,0a5.824,5.824,0,0,0,4.123-1.59,5.862,5.862,0,0,0,1.59-4.123c.092-1.625.092-6.49,0-8.114Zm-2.1,9.858a3.321,3.321,0,0,1-1.871,1.871c-1.3.514-4.369.4-5.8.4s-4.509.114-5.8-.4a3.321,3.321,0,0,1-1.871-1.871c-.514-1.3-.4-4.369-.4-5.8s-.114-4.509.4-5.8A3.321,3.321,0,0,1,4.038,4.406c1.3-.514,4.369-.4,5.8-.4s4.509-.114,5.8.4a3.321,3.321,0,0,1,1.871,1.871c.514,1.3.4,4.369.4,5.8S18.023,16.586,17.509,17.877Z"
                      transform="translate(0.005 -2.237)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/people/Tripd-App/100063482593288/"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.095"
                  height="22.481"
                  viewBox="0 0 12.095 22.481"
                >
                  <path
                    d="M13.257,12.073V7.779H16.72V5.62a5.65,5.65,0,0,1,1.517-3.979A4.825,4.825,0,0,1,21.912,0h3.44V4.294h-3.44a.757.757,0,0,0-.606.371,1.506,1.506,0,0,0-.27.91v2.2h4.316v4.293H21.036v10.41H16.719V12.073Z"
                    transform="translate(-13.257)"
                    fill="#fff"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href=" https://twitter.com/Tripd_App" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.481"
                  height="18.259"
                  viewBox="0 0 22.481 18.259"
                >
                  <g transform="translate(0 0)">
                    <path
                      d="M20.171,7.931c.014.2.014.4.014.6A13.02,13.02,0,0,1,7.075,21.64,13.021,13.021,0,0,1,0,19.571a9.532,9.532,0,0,0,1.113.057,9.228,9.228,0,0,0,5.72-1.969,4.616,4.616,0,0,1-4.308-3.2,5.811,5.811,0,0,0,.87.071,4.873,4.873,0,0,0,1.213-.157A4.608,4.608,0,0,1,.913,9.857V9.8A4.64,4.64,0,0,0,3,10.385,4.614,4.614,0,0,1,1.569,4.222a13.1,13.1,0,0,0,9.5,4.822,5.2,5.2,0,0,1-.114-1.056,4.612,4.612,0,0,1,7.974-3.153,9.071,9.071,0,0,0,2.924-1.113,4.6,4.6,0,0,1-2.026,2.539,9.237,9.237,0,0,0,2.653-.713,9.9,9.9,0,0,1-2.311,2.382Z"
                      transform="translate(0 -3.381)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </FooterSocial>
      </FooterContainer>
    </FooterWrap>

    // <Box
    //   sx={{
    //     bgcolor: Res.variables.color.blackColor,
    //     p: 1,
    //   }}
    // >
    //   <Grid container direction="row">
    //     <Grid item xs={3}>
    //       <Box mt={1}>
    //         <img src={Logo} alt="tripd" width="50" />
    //       </Box>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Grid container direction="row" alignItems="center">
    //         {data.map((option) => {
    //           return (
    //             <Grid item xs={3}>
    //               <Box textAlign="center" mb={1}>
    //                 <Link
    //                   variant="body2"
    //                   underline="none"
    //                   onClick={() => {
    //                     history("/login");
    //                   }}
    //                   style={{
    //                     cursor: "pointer",
    //                     marginRight: 10,
    //                     color: Res.variables.color.whiteColor,
    //                   }}
    //                 >
    //                   {option.routeName}
    //                 </Link>
    //               </Box>
    //             </Grid>
    //           );
    //         })}
    //       </Grid>
    //     </Grid>
    //     <Grid item xs={3}></Grid>
    //   </Grid>
    // </Box>
  );
}

export default Footer;
