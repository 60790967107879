import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Dropdown, Button, Carousel, Pagination } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";
import CreateNewModal from "../../../components/createNewModal/index";
import FiltersModal from "../../../components/filtersModal/index";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import StudioTour from "../../../assets/images/studio-tour-hollywood.jpg";
import AquariumPacific from "../../../assets/images/aquarium-pacific.jpg";
import TourLosAngeles from "../../../assets/images/tour-los-angeles.jpg";
import PlansModal from "../../../components/plansModal/index";
import {
  MyPageWrap,
  MainContentPanel,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  RentalsResultsMain,
  RentalsResultsList,
  RentalsResultsItem,
  RentalsListImage,
  RentalsListInfo,
  RentalsTitleWrap,
  DescriptionInfo,
  RentalsListMap,
  PaginationWrap,
  SearchListLoader,
  SportsGalleryItem,
  SportsLogoInner,
  SportsGallery,
  SportsLogo,
  SportsGalleryBottom,
  SportsDetailsTop,
  SportsDetails,
  SportsGalleryTop,
  SportsTitleWrap,
  SportsTitle,
  DetailSportsInfo,
  DetailSportsInfoWrap,
  DetailSportsRating,
  DetailSportsReview,
  DetailSportsPrice,
  SportsTeamDesc,
  CustomerReviewItem,
  RatingReviewWrap,
  ListRating,
  ListReview,
  RecordNotFound,
  BackToList,
} from "./foodAndDrinksResultStyle";
import PageLoaderIcon from "../../../assets/images/page-loader.svg";
import CreateGroupModal from "../../../components/createGroupModal/index";

import MapComponent from "../../../components/mapComponent/mapComponent";
import { mapKey } from "../../../resource/constants";
import { pointOfInterestData } from "../../../redux/action/point-of-interest/pointOfInterest";
import AnotherDayModal from "../../../components/anotherDayModal";
import CreatePlanModal from "../../../components/createPlanModal/index";
import InviteOthersModal from "../../../components/inviteOthersModal/index";
import ChatsPlanModal from "../../../components/chatsPlanModal";
import { getGroupData } from "../../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../../redux/action/plan-listing/planListing";
import { getImageGoogleData } from "../../../redux/action/getImageGoogle/getImageGoogle";
import RentalThumbOne from "../../../assets/images/rentals-detail-thumb.jpg";
import RentalThumbTwo from "../../../assets/images/rentals-detail-thumb-1.jpg";
import RentalThumbThree from "../../../assets/images/rentals-detail-thumb-2.jpg";
import RentalImageTwo from "../../../assets/images/rental-result-2.jpg";
import ImageGalleryModal from "../../../components/imageGalleryModal";
import ShareWithModal from "../../../components/shareWithModal/index";
import GetAllCollectionsModal from "../../../components/collectionsModal";

const FoodAndDrinksDetails: any = (props: any) => {
  const [lang, setLang] = useState("");
  const [long, setLong] = useState("");
  const lats: any = JSON.parse(localStorage.getItem("lat") || "{}");
  const longt: any = JSON.parse(localStorage.getItem("long") || "{}");
  useEffect(() => {
    setLang(typeof lats !== "object" ? lats : "");
    setLong(typeof longt !== "object" ? longt : "");
  }, []);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [Data, setData] = useState<any[]>([]);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false);
  const [pageToken, setPageToken] = useState<any[]>([]);
  const [plansModal, setPlansModal] = useState(false);
  const [rentalItemId, setRentalItemId] = useState("");
  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);
  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [inviteOthersModalShow, setInviteOthersModalShow] = useState(false);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);
  const [getEmails, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [showSortBy, setShowSortBy] = useState(-1);
  const [sortingType, setSortingType] = useState("Price");
  const [totalPages, setTotalPages] = useState(1);
  const [getAllImages, setAllImages] = useState<any[]>([]);
  const [openChatModel, setOpenChatModel] = useState(false);
  const [interestPage, setInterestPage] = useState(false);
  const [photos, setPhotos] = useState<any[]>([]);
  const [imageGalleryModalShow, setImageGalleryModalShow] = useState(false);
  const [interestData, setInterestData] = useState("");
  const [placeIdData, setPlaceIdData] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [sharewithImage, setShareWithImage] = useState([]);
  const [foodAndDrinksLink, setFoodAndDrinksLink] = useState("");
  const [foodAndDrinkName, setFoodAndDrinkName] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [getSearchResult, setSearchResult] = useState({
    search: "",
    location: "food and drink",
  });
  const [getImageUrl, setImageUrl] = useState("");
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [backButton, setBackButton] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");

  const pointOfInterest: any = useSelector(
    (state: any) => state.pointOfInterest
  );

  const getImageGoogle: any = useSelector((state: any) => state.getImageGoogle);

  const createPlan = useSelector((state: any) => state?.createPlan);
  const history: any = useNavigate();
  const latLong: any = useSelector((state: any) => state?.latLong);

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];
    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const lat: any = JSON.parse(localStorage.getItem("lat") || "{}");
  const longs: any = JSON.parse(localStorage.getItem("long") || "{}");

  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [filtersModalShow, setFiltersModalShow] = React.useState(false);
  const [groupImages, setGroupImages] = useState("");
  const [getToken, setToken] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });

  let { id }: any = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split("/").length > 2) {
      FoodHandler(location.pathname.split("/")[2]);
    }
  }, [id]);

  const FoodHandler = (id: any) => {
    const finalDatas = {
      placeId: id,
    };
    photos.splice(0, photos.length);
    getAllImages.splice(0, getAllImages.length);
    dispatch(getImageGoogleData(finalDatas));
    setInterestPage(true);
    if (document.getElementById("foodanddrinks") !== undefined) {
      document.getElementById("foodanddrinks")?.scroll(0, 0);
    }
    setBackButton(true);
  };

  useEffect(() => {
    if (getImageGoogle?.data) {
      if (getImageGoogle?.data?.result?.photos) {
        photos.splice(0, photos.length);
        getAllImages.splice(0, getAllImages.length);
        const finals = [...photos, ...getImageGoogle?.data?.result?.photos];
        setPhotos(finals);
        getImageGoogle?.data?.result?.photos.map((item: any, index: any) => {
          const ObjArr: any = {
            link: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${item?.photo_reference}&key=${mapKey}`,
          };
          setAllImages((getAllImages) => [...getAllImages, ObjArr]);
        });
        // setAllImages([]);
      }
    }
  }, [getImageGoogle?.data]);

  const prevFun = () => {
    getToken.shift();
    if (document.getElementById("foodanddrinks") !== undefined) {
      document.getElementById("foodanddrinks")?.scroll(0, 0);
    }
    const finalData = {
      query: "restaurant",
      type: "restaurant",
      lat: isNaN(latLong?.data?.lat) ? lang : latLong?.data?.lat,
      long: isNaN(latLong?.data?.lng) ? long : latLong?.data?.lng,
      token: getToken.length > 0 ? getToken[0] : "",
      search: getSearchResult?.search,
    };
    dispatch(pointOfInterestData(finalData));
  };

  const nextFun = () => {
    if (pointOfInterest?.data?.next_page_token) {
      if (document.getElementById("foodanddrinks") !== undefined) {
        document.getElementById("foodanddrinks")?.scroll(0, 0);
      }
      getToken.unshift(pointOfInterest?.data?.next_page_token);
      const finalData = {
        query: "restaurant",
        type: "restaurant",
        lat: isNaN(latLong?.data?.lat) ? lang : latLong?.data?.lat,
        long: isNaN(latLong?.data?.lng) ? long : latLong?.data?.lng,
        token: getToken[0],
        search: getSearchResult?.search,
      };
      dispatch(pointOfInterestData(finalData));
    }
  };
  const [photoRef, setPhotoRef] = useState("");

  useEffect(() => {
    if (getImageGoogle?.data?.result) {
      setFoodAndDrinksLink(getImageGoogle?.data?.result?.website);
      setFoodAndDrinkName(getImageGoogle?.data?.result?.name);
      setAddressInfo(getImageGoogle?.data?.result?.formatted_address);
    }
  }, [getImageGoogle]);

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const shareChatImages = (item: any) => {
    setGroupImages(item);
    setDynamicLocation("/food-and-drink-details");
  };

  const getFooAndDriks = useSelector((state: any) => state);

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel id="foodanddrinks">
          <MainSearchPanel
            setSearchResult={setSearchResult}
            getSearchResult={getSearchResult}
            setPageNumber={setPageNumber}
          />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn">
                All
              </Link>
              <Link to="/concertsresults/1" className="btn">
                Concerts
              </Link>
              <Link
                to="/foodanddrinks"
                className="btn select"
                onClick={() => {
                  setInterestPage(false);
                }}
              >
                Food &amp; Drinks
              </Link>
              <Link to="/festivalsresults/1" className="btn">
                Festivals
              </Link>
              <Link to="/sportsresults/1" className="btn">
                Sports
              </Link>
              <Link to="/rentalsresults/1" className="btn">
                Rentals
              </Link>
              <Link to="/hotelsresult/1" className="btn">
                Hotels
              </Link>
              <Link to="/interestresults/1" className="btn">
                Points of Interest
              </Link>
              <Link to="/hiking/1" className="btn">
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                <BackToList
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.55283 9.1117L15.4567 9.1117C15.945 9.1117 16.3398 9.5065 16.3398 9.99481C16.3398 10.4831 15.945 10.8779 15.4567 10.8779L6.54244 10.8779C6.05413 10.8779 5.65932 10.4831 5.65932 9.99481C5.65932 9.5065 6.06452 9.1117 6.55283 9.1117Z"
                      fill="#6C53E4"
                    />
                    <path
                      d="M9.52378 6.14041C9.75235 6.14041 9.98092 6.22352 10.1472 6.40015C10.49 6.743 10.49 7.30404 10.1472 7.65729L7.7991 10.0053L10.1472 12.3534C10.49 12.6962 10.49 13.2573 10.1472 13.6105C9.8043 13.9534 9.24326 13.9534 8.89001 13.6105L5.91858 10.6391C5.57573 10.2962 5.57573 9.73521 5.91858 9.38196L8.89001 6.41053C9.06663 6.23391 9.29521 6.14041 9.52378 6.14041Z"
                      fill="#6C53E4"
                    />
                    <path
                      d="M10.9997 1.86265e-08C16.5166 1.86265e-08 21.0049 4.48831 21.0049 9.9948C21.0049 15.5117 16.5166 20 10.9997 20C5.4828 20 0.994491 15.5117 0.994491 9.9948C1.00488 4.48831 5.49319 1.86264e-08 10.9997 1.86265e-08ZM10.9997 18.2234C15.5399 18.2234 19.2283 14.5351 19.2283 9.9948C19.2283 5.45455 15.5399 1.76623 10.9997 1.76623C6.45943 1.76623 2.77112 5.45455 2.77112 9.9948C2.7815 14.5351 6.46982 18.2234 10.9997 18.2234Z"
                      fill="#6C53E4"
                    />
                  </svg>
                  Back
                </BackToList>

                <button
                  type="submit"
                  className="btn"
                  onClick={() => setCreateNewModalShow(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                  >
                    <g transform="translate(-1016 -337)">
                      <path
                        d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                        transform="translate(1016 337)"
                        fill="#fff"
                      />
                      <path
                        d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                        transform="translate(1020.143 350.845)"
                        fill="#4d27da"
                        stroke="#fff"
                        stroke-width="0.5"
                        className="plusIcon"
                      />
                    </g>
                  </svg>
                  CREATE
                </button>
              </CreateBtnInner>
            </CreateBtn>

            {
              <>
                {getImageGoogle?.isLoading && (
                  <SearchListLoader>
                    <img src={PageLoaderIcon} alt="Loading..." />
                  </SearchListLoader>
                )}

                <SportsDetails>
                  <SportsDetailsTop>
                    <SportsLogo>
                      {photos?.map((image: any, index: any) => {
                        return (
                          index === 0 && (
                            <SportsLogoInner>
                              <img
                                src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${image?.photo_reference}&key=${mapKey}`}
                                alt="image"
                              />
                            </SportsLogoInner>
                          )
                        );
                      })}

                      {photos?.length === 0 && (
                        <SportsLogoInner>
                          <img src={RentalImageTwo} alt={"default image"} />
                        </SportsLogoInner>
                      )}
                    </SportsLogo>

                    <SportsGallery>
                      {photos?.map((image: any, index: any) => {
                        return (
                          index === 1 && (
                            <SportsGalleryTop>
                              <img
                                src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${image?.photo_reference}&key=${mapKey}`}
                                alt="image"
                              />
                            </SportsGalleryTop>
                          )
                        );
                      })}

                      {photos?.length === 0 && (
                        <SportsGalleryTop>
                          <img src={RentalImageTwo} alt={"default image"} />
                        </SportsGalleryTop>
                      )}

                      <SportsGalleryBottom>
                        {photos?.slice(2, 5).map((image: any, index: any) => {
                          return (
                            <SportsGalleryItem>
                              <img
                                src={
                                  `https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${image?.photo_reference}&key=${mapKey}` ??
                                  RentalThumbOne
                                }
                                alt={image?.title ?? "default image"}
                              />
                              {index === 2 && (
                                <button
                                  type="submit"
                                  className="btn"
                                  onClick={() => setImageGalleryModalShow(true)}
                                >
                                  + photos
                                </button>
                              )}
                            </SportsGalleryItem>
                          );
                        })}

                        {photos?.length === 0 && (
                          <SportsGalleryItem>
                            <img src={RentalImageTwo} alt={"default image"} />
                          </SportsGalleryItem>
                        )}
                      </SportsGalleryBottom>
                    </SportsGallery>
                  </SportsDetailsTop>

                  <SportsTitleWrap>
                    <SportsTitle>
                      {getImageGoogle?.data?.result?.name}
                    </SportsTitle>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                          <path
                            d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                            transform="translate(2.459 16.62)"
                            fill="#3b3b3b"
                          ></path>
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setOpenChatModel(true);
                            groupPlanDetails();
                            shareChatImages(getImageGoogle?.data?.result);
                            setPlaceIdData(
                              getImageGoogle?.data?.result?.place_id
                            );
                          }}
                        >
                          Share to Chat
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setPlansModal(true);
                            setRentalItemId(
                              getImageGoogle?.data?.result?.place_id
                            );
                            setInterestData(getImageGoogle?.data?.result);
                            setPlaceIdData(
                              getImageGoogle?.data?.result?.place_id
                            );
                          }}
                        >
                          Add to Plans
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            const location: any =
                              getImageGoogle?.data?.result?.plus_code?.compound_code.split(
                                " "
                              );
                            setRentalDetails({
                              venue_id: getImageGoogle?.data?.result?.place_id,
                              name: getImageGoogle?.data?.result?.name,
                              location:
                                location.length > 0
                                  ? location[1].slice(0, -1)
                                  : "",
                              province:
                                location.length > 0
                                  ? location[2].slice(0, -1)
                                  : "",
                              Image: getImageGoogle?.data?.result?.photos
                                ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${getImageGoogle?.data?.result?.photos[0]?.photo_reference}&key=${mapKey}`
                                : "",
                              venue_type: "food and drink",
                            });
                            setCollectionModal(true);
                          }}
                        >
                          Add to collection
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setCreatePlanModalShow(true);
                            setCreateData(getImageGoogle?.data?.result);
                            setRentalItemId(
                              getImageGoogle?.data?.result?.place_id
                            );
                            setInterestData(getImageGoogle?.data?.result);
                            setPlaceIdData(
                              getImageGoogle?.data?.result?.place_id
                            );
                            setAllId([]);
                            setInviteFriend([]);
                            setInviteFollower([]);
                            setInviteEmails([]);
                            setGroupMembers([]);
                            setPendingEmail([]);
                            setShowImage("");
                            setImage("");
                            setImageValidation("");
                          }}
                        >
                          Create Plans
                        </Dropdown.Item>
                        <Dropdown.Item as="button">Share With</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </SportsTitleWrap>

                  <DetailSportsInfo>
                    <DetailSportsInfoWrap>
                      <DetailSportsRating>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Component_44_16"
                          data-name="Component 44 – 16"
                          width="22"
                          height="20.364"
                          viewBox="0 0 19 17.364"
                        >
                          <path
                            id="Polygon_1"
                            data-name="Polygon 1"
                            d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                            fill="#ffc126"
                          />
                        </svg>
                        {getImageGoogle?.data?.result?.rating ?? "0"}
                      </DetailSportsRating>
                      <DetailSportsReview>
                        {getImageGoogle?.data?.result?.user_ratings_total ??
                          "0"}{" "}
                        User Rating Total
                      </DetailSportsReview>
                    </DetailSportsInfoWrap>
                  </DetailSportsInfo>

                  <SportsTeamDesc>
                    {getImageGoogle?.data?.result?.formatted_phone_number && (
                      <p>
                        Phone Number :{" "}
                        {getImageGoogle?.data?.result?.formatted_phone_number}
                      </p>
                    )}
                    {getImageGoogle?.data?.result?.formatted_address && (
                      <p>
                        Address :{" "}
                        {getImageGoogle?.data?.result?.formatted_address}
                      </p>
                    )}
                    {getImageGoogle?.data?.result?.price_level && (
                      <p>
                        Price level :{" "}
                        {getImageGoogle?.data?.result?.price_level}
                      </p>
                    )}
                    {getImageGoogle?.data?.result?.website && (
                      <a
                        href={getImageGoogle?.data?.result?.website}
                        target="_blank"
                        rel="noreferrer"
                        className="btn"
                      >
                        VIEW MORE DETAILS
                      </a>
                    )}

                    {getImageGoogle?.data?.result?.reviews && (
                      <>
                        <SportsTitle className="reviewsTitle">
                          Customer Reviews
                        </SportsTitle>

                        {getImageGoogle?.data?.result?.reviews?.map(
                          (item: any, index: any) => {
                            return (
                              <>
                                <CustomerReviewItem>
                                  <DetailSportsInfoWrap>
                                    <DetailSportsReview>
                                      {item.author_name}
                                    </DetailSportsReview>
                                    <DetailSportsRating>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="Component_44_16"
                                        data-name="Component 44 – 16"
                                        width="22"
                                        height="20.364"
                                        viewBox="0 0 19 17.364"
                                      >
                                        <path
                                          id="Polygon_1"
                                          data-name="Polygon 1"
                                          d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                                          fill="#ffc126"
                                        />
                                      </svg>
                                      {item?.rating ?? "0"}
                                    </DetailSportsRating>
                                  </DetailSportsInfoWrap>
                                  {item?.text && <p>{item?.text}</p>}
                                </CustomerReviewItem>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                  </SportsTeamDesc>
                </SportsDetails>
              </>
            }
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
        setCreateNewModalShow={setCreateNewModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setAllId={setAllId}
        setInviteFriend={setInviteFriend}
        setInviteFollower={setInviteFollower}
        setInviteEmails={setInviteEmails}
        setGroupMembers={setGroupMembers}
        setPendingEmail={setPendingEmail}
        setCreateGroupModalShow={setCreateGroupModalShow}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />

      <AnotherDayModal
        show={timeDayModal}
        onHide={() => setTimeDayModal(false)}
        DayData={finalData}
        PlanId={planId}
        VenueId={rentalItemId}
        finalEvent={"food and drink"}
        interestData={interestData}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        pending_invite={[]}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
      />
      <CreateGroupModal
        show={createGroupModalShow}
        onHide={() => {
          setCreateGroupModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
          setGroupMembers([]);
          setPendingEmail([]);
        }}
        setCreateGroupModalShow={setCreateGroupModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
      />
      <FiltersModal
        show={filtersModalShow}
        onHide={() => setFiltersModalShow(false)}
      />

      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
      />

      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
        getAllImages={getAllImages}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        sharewithImage={photoRef}
        link={foodAndDrinksLink}
        name={foodAndDrinkName}
        address={addressInfo}
      />
      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />
    </>
  );
};

export default FoodAndDrinksDetails;
