import { RejectUserType } from "../../../redux/action/rejectuser/rejectUser";
import { rejectUserModel } from "../../../redux/models/index";

export const initState: rejectUserModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case RejectUserType.REJECTUSER_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case RejectUserType.REJECTUSER_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case RejectUserType.REJECTUSER_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
