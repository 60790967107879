export enum CurrentLocationType {
  CURRENT_LOCATION_INFO = "CURRENT_LOCATION_INFO",
  CURRENT_LOCATION_INFO_SUCCESS = "CURRENT_LOCATION_INFO_SUCCESS",
  CURRENT_LOCATION_INFO_FALIURE = "CURRENT_LOCATION_INFO_FALIURE",
}

export const currentLocationInfo = () => (dispatch: any) => {
  dispatch({
    type: CurrentLocationType.CURRENT_LOCATION_INFO,
  });
};

export const currentLocationInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: CurrentLocationType.CURRENT_LOCATION_INFO_SUCCESS,
    data,
  });
};

export const currentLocationInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: CurrentLocationType.CURRENT_LOCATION_INFO_FALIURE,
    data: { msg },
  });
};
