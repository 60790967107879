import { getConversationType } from "../../action/getConversation/getConversation";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getConversationType.GET_COVERSATION_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case getConversationType.GET_COVERSATION_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case getConversationType.GET_COVERSATION_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null,
      };
    }
    case "RESET_NOTIFICATION_DATA": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }

    default:
      return state;
  }
};
