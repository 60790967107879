import styled from "styled-components";

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    text-align: center;
    @media (max-width:767px){
      padding:20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      color: #4d27da;
      text-align: center;
      margin-bottom: 12px;
    }
    p {
      margin: 0;
    }
    h4 {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3b3b3b;
      text-align: left;
      margin: 18px 0 20px;
    }

    ul {
      margin: 0 -10px;
      padding: 0;
      list-style-type: none;
      flex-wrap: wrap;
      display: flex;
      li {
        padding: 0 10px;
        text-align: left;
        width: 50%;
        margin: 0 0 15px;
        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
        @media (max-width:480px){
          width:100%;
        }
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 35px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 134px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 15px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const RadioBox = styled.div`
  display: inline-block;
  padding: 0 0 0 33px;
  margin: 0;
  min-height: 16px;
  position: relative;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #a1a3b0;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
  label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    display: block;
    margin: 0 0 2px;
    img {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
`;
