import { DataArraySharp } from "@mui/icons-material";
import { getRentalsAPI } from "../../../services/get-rentals";

export enum getRentalsType {
  GET_RENTAL_INFO = "GET_RENTAL_INFO",
  GET_RENTAL_INFO_SUCCESS = "GET_RENTAL_INFO_SUCCESS",
  GET_RENTAL_INFO_FALIURE = "GET_RENTAL_INFO_FALIURE",
}

export const getRentalInfo = () => (dispatch: any) => {
  dispatch({
    type: getRentalsType.GET_RENTAL_INFO,
  });
};

export const getRentalInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getRentalsType.GET_RENTAL_INFO_SUCCESS,
    data,
  });
};

export const getRentalInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getRentalsType.GET_RENTAL_INFO_FALIURE,
    error: msg,
  });
};

export const getRentalHandler =
  (data: any) =>
  async (dispatch: any) => {
    try {
      dispatch(getRentalInfo());
      const result: any = await getRentalsAPI(data);
      dispatch(getRentalInfoSuccess(result));
    } catch (e: any) {
      dispatch(getRentalInfoFailed(e?.response?.data?.message));
    }
  };
