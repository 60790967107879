import React, { useState, useEffect } from "react";

import {
  Tabs,
  Tab,
  Modal,
  Dropdown,
  Button,
  Carousel,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";

import {
  ModalBody,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  FiltersFormWrap,
  FormSubTitle,
  FormMultibutton,
  InputRangeWrap,
} from "../filtersModal/filtersModalStyle";

import { Formik } from "formik";
import { Group, Hiking } from "@mui/icons-material";

const FiltersModal = (props: any) => {
  const dispatch = useDispatch();

  const history = useNavigate();
  useEffect(() => {
    setRangeValue({
      ...rangeValue,
      max: props.Maximum,
    });
  }, []);

  const [rangeValue, setRangeValue] = React.useState({
    min: 0,
    max: props.Maximum,
  });

  const handleValueChange = (value: any) => {
    setRangeValue({ max: value.max, min: value.min });
  };

  return (
    <>
      <React.Fragment>
        <Modal
          show={props.show}
          onHide={props.onHide}
          // {...props}
          size="lg"
          className="filtersModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <style>
            {`
            .filtersModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 561px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .filtersModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,38,0.16);
            }
          `}
          </style>
          <ModalBody>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h2>Filters</h2>

              <FiltersFormWrap>
                <Form>
                  <FormSubTitle>Price</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Minimum</Form.Label>
                        <Form.Control
                          type="number"
                          name="min"
                          min={0}
                          value={rangeValue.min}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setRangeValue((prevState) => ({
                              ...rangeValue,
                              [name]: value,
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Maximum</Form.Label>
                        <Form.Control
                          type="number"
                          name="max"
                          max={props.Maximum}
                          value={rangeValue.max}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setRangeValue((prevState) => ({
                              ...rangeValue,
                              [name]: value,
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputRangeWrap>
                        <InputRange
                          minValue={0}
                          maxValue={props.Maximum}
                          value={props ? rangeValue : { max: 0, min: 0 }}
                          onChange={(value: any) => handleValueChange(value)}
                        />
                      </InputRangeWrap>
                    </Col>
                  </Row>

                  <hr />
                  <FormSubTitle>Rooms &amp; Spaces</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3 custom-group">
                        <Form.Label className="inline-label">
                          Bedrooms
                        </Form.Label>
                        <FormMultibutton>
                          <Button type="button" className="custom-minus">
                            -
                          </Button>
                          <Form.Control type="text" value="1" />
                          <Button type="button" className="custom-plus">
                            +
                          </Button>
                        </FormMultibutton>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3 custom-group">
                        <Form.Label className="inline-label">
                          Bathrooms
                        </Form.Label>
                        <FormMultibutton>
                          <Button type="button" className="custom-minus">
                            -
                          </Button>
                          <Form.Control type="text" value="2" />
                          <Button type="button" className="custom-plus">
                            +
                          </Button>
                        </FormMultibutton>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Living Room</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Dining Room</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Kitchen</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Outdoor Space
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                  <hr />
                  <FormSubTitle>Free Cancellation</FormSubTitle>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="checkbox-label">
                          Thank you plans might change? No worries. Filter for
                          places that let you cancel for free up to :
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <InviteGroupMemberItem className="mb-3 d-block">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            60 Days before check - in
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                      <InviteGroupMemberItem className="mb-3 d-block">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            30 Days before check - in
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                      <InviteGroupMemberItem className="mb-3 d-block">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            14 Days before check - in
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                  <hr />
                  <FormSubTitle>Features &amp; Amenities</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Pool</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Dryer</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Private pool</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Washer</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Hot tub</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Oven</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Internet or wifi
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Parking available
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Air conditioning
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">TV</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Stove</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Outdoor grill
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                  <hr />
                  <FormSubTitle>Property Reviews</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            4 Star + Rating
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            High rated for Cleanliness
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Highly rated for location
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                  <hr />
                  <FormSubTitle>House Rules</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Pets allowed</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Kids welcome</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Events allowed
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Smoking allowed
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                  <hr />
                  <FormSubTitle>Property Type</FormSubTitle>
                  <Row>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Fishing</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Cycling</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Golfing</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Horseback riding
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Skiing or snowboarding
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Wineries and breweries
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Hiking</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Spa and wellness
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Shopping</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Scuba diving or snorkeling
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Spa and wellness
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Theme parks</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Zoo or wildlife viewing
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Rock or mountain climbing
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">
                            Winter activities
                          </label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                    <Col lg={6}>
                      <InviteGroupMemberItem className="mb-3">
                        <InviteGroupCheckBox>
                          <input type="checkbox" />
                          <span />
                        </InviteGroupCheckBox>
                        <GroupMemberItem>
                          <label className="checkbox-label">Casinos</label>
                        </GroupMemberItem>
                      </InviteGroupMemberItem>
                    </Col>
                  </Row>
                </Form>
              </FiltersFormWrap>
            </Modal.Body>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default FiltersModal;
