import { hikingAPI } from "../../../services/hiking";

export enum hikingType {
  HIKING_PLAN_INFO = "HIKING_PLAN_INFO",
  HIKING_PLAN_INFO_SUCCESS = "HIKING_PLAN_INFO_SUCCESS",
  HIKING_PLAN_INFO_FAILURE = "HIKING_PLAN_INFO_FAILURE",
}

export const hikingPlanInfo = () => (dispatch: any) => {
  dispatch({
    type: hikingType.HIKING_PLAN_INFO,
  });
};

export const hikingPlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: hikingType.HIKING_PLAN_INFO_SUCCESS,
    data,
  });
};

export const hikingPlanInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: hikingType.HIKING_PLAN_INFO_FAILURE,
    error: msg,
  });
};
export const hikingData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(hikingPlanInfo());
    const result: any = await hikingAPI(data);
    // if (result?.data?.results?.length === 0) {
    //   window.location.reload();
    // }
    dispatch(hikingPlanInfoSuccess(result));
  } catch (e: any) {
    dispatch(hikingPlanInfoFailed(e?.response?.data));
  }
};
