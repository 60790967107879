import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import Res from "../../../resource";
import { Tabs, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logopwa from "../../../assets/images/logopwa.png";
import uploadpwa from "../../../assets/images/upload.png";
import pluspwa from "../../../assets/images/plus.png";
import ReactGA from "react-ga4";

import { getMessaging, getToken,onMessage  } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

import {
  AuthWrap,
  AuthWrapInner,
  AuthContainer,
  FormGroup,
  OR,
  FormLink,
  Spinner,
} from "./authStyle";
import { Formik } from "formik";
import {
  userLoginDataAPI,
  loginInfoSuccess,
  loginInfoFailed,
} from "../../../redux/action/signin/loginData";

import { ToastContainer, toast, collapseToast } from "react-toastify";

function Login() {
	
const firebaseConfig = {
  apiKey: "AIzaSyDpOMn2csIskPypLREKQwITsEKFDCIhymg",
  authDomain: "testtripd.firebaseapp.com",
  projectId: "testtripd",
  storageBucket: "testtripd.appspot.com",
  messagingSenderId: "709334481936",
  appId: "1:709334481936:web:d7825272dc580969131e95",
  measurementId: "G-ZS2W0931GV"
};

const firebaseApp = initializeApp(firebaseConfig);
//const auth = app.auth();


	
	 
	
	ReactGA.initialize('G-W12FWLYJS8');
const [deferredPrompt, setDeferredPrompt] = useState<any>("");
	
	const [firetoken, setFiretoken] = useState("");
	
	const [showandroid, setShowandroid] = useState(false);
	
	const [showiphone, setShowiphone] = useState(false);
	
	const [showiphoneper, setShowiphoneper] = useState(false);
	
  const [isToasting, setIsToasting] = useState(false);
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const loginSelector = useSelector((state: any) => state?.loginData);

  const getLoginToaster = (type: string) => {
    switch (type) {
      case "email":
        setTimeout(() => {
          toast.error("Please enter correct email address & password.");
        }, 1000);
        break;
      case "password":
        setTimeout(() => {
          toast.error("Please enter correct email address & password.");
        }, 1000);
        break;
      case "active":
        setTimeout(() => {
          toast.error("Your account has been disable. Please contact admin.");
        }, 1000);
        break;
      case "pending-list":
        setTimeout(() => {
          toast.error("Your account is pending approval");
        }, 1000);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch({ type: "RESET_LOGIN_PROFILE" });
	
	if (window.matchMedia('(display-mode: standalone)').matches) {
	  //alert("This is running as standalone.");
	  var sd=navigator.userAgent.match(/iPhone|iPad|iPod/i);
	 
		if(sd==null)
		{
			
		}else{
			const stringValue = sd.toString();
			var type_phone=stringValue;
			if(type_phone =='iPhone' || type_phone =='iPad' || type_phone =='iPod'){
				//setShowiphoneper(true);
				requestPermission_chk();
			}
		}
	}else{
		
		var sd=navigator.userAgent.match(/iPhone|iPad|iPod/i);
		if(sd==null)
		{
			
		}else{
			const stringValue = sd.toString();
			var type_phone=stringValue;
			//alert(type_phone);
			if(type_phone =='iPhone' || type_phone =='iPad' || type_phone =='iPod'){
				/*setTimeout(function(){ 
		      var hours = 120; // Reset when storage is more than 24hours
				var now = new Date().getTime();
				var setupTime = localStorage.getItem('setupTime');	
				var tt=0;
			   if(setupTime==null)
			   {
				   
			   }else{
					var tt=parseInt(setupTime);
			   }
				var t=now-tt;
				if(t > hours*60*60*1000) {
				   setShowiphone(true);
				}
			}, 2000);*/
			
			setShowiphone(true);
				/*if(Notification.permission=='default'){
				   if (localStorage.getItem('set_noti') !== null) { 
					  setShowiphone(true);
				   }else{
						setShowiphoneper(true);
				   }
				}else if(Notification.permission=='denied'){
					setShowiphone(true);
				}*/
			
			}
		}
	}
	
	
	 window.addEventListener('beforeinstallprompt', (e:any) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
	
	
	const dp = getPWADisplayMode();
	  
		 if (dp == 'browser') {
			 setShowandroid(true);
           /*setTimeout(function(){ 
		      var hours = 120; // Reset when storage is more than 24hours
				var now = new Date().getTime();
				var setupTime = localStorage.getItem('setupTime');	
				var tt=0;
			   if(setupTime==null)
			   {
				   
			   }else{
					var tt=parseInt(setupTime);
			   }
				var t=now-tt;
				if(t > hours*60*60*1000) {
				   setShowandroid(true);
				}
			}, 2000);*/
		 }
		 
	 });
	 
	  

window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the default prompt
  event.preventDefault();
  // Store the event for later use

  setDeferredPrompt(event);
});
	 
	const messaging = getMessaging(firebaseApp);
	//requestPermission();
	//console.log(JSON.stringify(messaging) +'ffffffff');
	getToken(messaging, {
  vapidKey:
    "BCU2i2XHvT8HIEVGMzY4_uz-XYMOSfS0c4E8GmjKzRG3Eq7KbWL9ltwWZbSUpe8Z0qinx5UGsXoj7jEU10Yn-0I",
})
  .then((currentToken) => {
    if (currentToken) {
		setFiretoken(currentToken);
      console.log("Firebase Token", currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });



/*onMessage(messaging, (payload:any) => {
   const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,       
    };
	if (!("Notification" in window)) {
        console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(notificationTitle,notificationOptions);
        notification.onclick = function(event) {
            event.preventDefault();
            window.open(payload.notification.click_action , '_blank');
            notification.close();
        }
    }
   
});*/
  }, []);

  useEffect(() => {
    if (loginSelector?.msg?.msg === "Email does not exist.") {
      getLoginToaster("email");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (loginSelector?.msg?.msg === "Password is not match.") {
      getLoginToaster("password");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (loginSelector?.msg?.msg === "User must be Active") {
      getLoginToaster("active");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    } else if (
      loginSelector?.msg?.msg ===
      "You're account is under review and if we can confirm it, you will be able to access your account within approximately 24 hours."
    ) {
      getLoginToaster("pending-list");
      dispatch({ type: "RESET_LOGIN_PROFILE" });
    }
  }, [loginSelector]);

function getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'twa';
        } else if (isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

  const submitForm = async (values: any) => {
    const finalValue = {
      ...values,
      email: values.email.toLowerCase(),
    };
	
    dispatch(userLoginDataAPI(finalValue,firetoken));
	
  };

  const [showEye, setShowEye] = useState(false);

  const handleEyeChange = () => {
    setShowEye(!showEye);
  };


const handleCloseandroid = () =>{
	setShowandroid(false);
	/*var now:any;
	now= new Date().getTime();
	localStorage.setItem('setupTime', now);*/
}

const handleCloseiphone = () =>{
	setShowiphone(false);
	//requestPermission_chk();
	/*var now:any;
	now= new Date().getTime();
	localStorage.setItem('setupTime', now);*/
}
const handleCloseiphoneper = () =>{
	setShowiphone(false);
	/*var now:any;
	now= new Date().getTime();
	localStorage.setItem('setupTime', now);*/
}


 function showInstallPrompt() {
	setShowandroid(false);
  // Show your custom install button or other UI element
  // and handle the installation when the user clicks it
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
		  
		  var lbl='Install app';
			var lbl_act="Install app";
		  ReactGA.event({
		  category: 'Button Click',
			action: lbl_act,
			label: lbl,
		});
		  
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      //deferredPrompt = null;
    });
  }
}
 
function requestPermission_chk() {
	
	if (window.Notification && window.Notification.permission === "granted") {
    
  }else{
	  setShowiphoneper(true);
  }
 }
 function requestPermission() {
	setShowiphoneper(false);
	  Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			initializeFCM();
		  console.log('Notification permission granted.');
		}else{
			localStorage.setItem("set_noti", '1');
		}
	  })
 }
 
 function initializeFCM() {
	 const messaging = getMessaging(firebaseApp);
	 getToken(messaging, {
  vapidKey:
    "BCU2i2XHvT8HIEVGMzY4_uz-XYMOSfS0c4E8GmjKzRG3Eq7KbWL9ltwWZbSUpe8Z0qinx5UGsXoj7jEU10Yn-0I",
})
  .then((currentToken) => {
    if (currentToken) {
		setFiretoken(currentToken);
      console.log("Firebase Token", currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });
 }
 
  return (
    <>
      <AuthWrap>
        <Header />
        <AuthWrapInner>
          <AuthContainer>
            <h2>Login</h2>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                // const regex =
                //   /^(([^<>()\    [\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const email =
                  /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
                const passwordRegex =
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,17}$/;

                if (!values.email.trim()) {
                  errors.email = "Please enter email address";
                }
                // } else if (!email.test(values.email)) {
                //   errors.email = "Please enter correct email address";
                // }

                if (!values.password) {
                  errors.password = "Please enter your password";
                }
                // else if (!passwordRegex.test(values.password)) {
                //   errors.password =
                //     "Password must be contain 8 to 17 character with 1 special letter, 1 number, 1 capital and 1 lowercase letter";
                // }
                else if (values.password.length < 8) {
                  errors.password = "Please enter eight digit password";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <CustomInput
                      inputid="email"
                      inputlabe="Email Address"
                      inputtype="email"
                      placeholder="Enter Email"
                      value={values.email}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.email}
                      touched={touched.email}
                    />

                    <CustomInput
                      inputid="password"
                      inputlabe="Password"
                      inputtype={showEye ? "text" : "password"}
                      placeholder="Enter your Password"
                      value={values.password}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors.password}
                      touched={touched.password}
                      handleEyeChange={handleEyeChange}
                      showEye={showEye}
                      maxLength={16}
                    />

                    <FormLink>
                      <Link to="/forgotpassword">Forgot password?</Link>
                    </FormLink>

                    <Button
                      type="submit"
                      className="auth-btn"
                      // disabled={isLoading}
                    >
                      {loginSelector?.isLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : null}
                      Login
                    </Button>
					{/*<Button type="button" onClick={requestPermission}>tttt</Button>*/}
                    {/* <ToastContainer autoClose={5000} /> */}
					<input type="hidden" name="fire_token" value="ggggggg" />
                  </form>
                </>
              )}
            </Formik>
          </AuthContainer>
        </AuthWrapInner>
        <Footer />
      </AuthWrap>
	  {/* ========== Android Popup Start ========== */}
			<Modal show={showandroid} onHide={handleCloseandroid} className="msmodal">
				
			   <Modal.Body>
					<div className="row pwa-main-row">
					
						<div>
							<img src={logopwa} className="img-fluid" />
						</div>
						<div>
							<p className="text-center">Install Tripd</p>
						</div>
					</div>
				<div className="row mt-3 pwa-body">
                    <div className="col-12 px-3">
                            <p className="">Install to home screen. Allow notification for chat & plan making</p>
							
							<ul>
								
								<li><span>1. </span> <span onClick={() =>showInstallPrompt()} className="pwa-install-btn"> Install APP <img src={pluspwa} className="img-fluid img-pwa-upload" /></span> </li>
							</ul>
                    </div>
                    </div> 
					   
			   </Modal.Body>
			   <Modal.Footer>
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() =>handleCloseandroid()}  >Close</button>
			   </Modal.Footer>
			</Modal>
	  {/* ========== Android Popup End ========== */}
	  {/* ========== iphone Popup Start ========== */}
			<Modal show={showiphone} onHide={handleCloseiphone} className="msmodal">
			   
			   <Modal.Body>
				  <div className="row pwa-main-row">
					
						<div>
							<img src={logopwa} className="img-fluid" />
						</div>
						<div>
							<p className="text-center">Install Tripd</p>
						</div>
					</div>
				<div className="row mt-3 pwa-body">
                    <div className="col-12 px-3">
                            <p className="">Install to home screen. Allow notification for chat & plan making</p>
							
							<ul>
								<li><span>1. Tap on</span> <span><img src={uploadpwa} className="img-fluid img-pwa-upload" /></span></li>
								<li><span>2. Select</span> <span>Add to Home Screen <img src={pluspwa} className="img-fluid img-pwa-upload" /></span> </li>
							</ul>
                    </div>
                    </div> 
			   </Modal.Body>
			    <Modal.Footer>
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() =>handleCloseiphone()}  >Close</button>
			   </Modal.Footer>
			</Modal>
	  {/* ========== iphone Popup End ========== */}
	  {/* ========== iphone Popup Start ========== */}
			<Modal show={showiphoneper} onHide={handleCloseiphoneper} className="msmodal">
			   <Modal.Header>
                          <h3 className="mdlttltxt">Notification</h3>
                </Modal.Header>
			   <Modal.Body>
			   <div className="row mt-3 pwa-body">
                    <div className="col-12 px-3">
				   <p>
					tripd.co wants to send you notifications
                
                </p>
				 </div>
                    </div> 
			   </Modal.Body>
			    <Modal.Footer>
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() =>requestPermission()}>Request Permision</button>
			   </Modal.Footer>
			</Modal>
	  {/* ========== iphone Popup End ========== */}
    </>
  );
}

export default Login;
