import { addCollectionAPI } from "../../../services/addCollection";

export enum addToCollection {
  ADD_COLLECTION = "ADD_COLLECTION",
  ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS",
  ADD_COLLECTION_FAILURE = "ADD_COLLECTION_FAILURE",
}

export const addCollection = () => (dispatch: any) => {
  dispatch({
    type: addToCollection.ADD_COLLECTION,
  });
};

export const addCollectionSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: addToCollection.ADD_COLLECTION_SUCCESS,
    data,
  });
};

export const addCollectionFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: addToCollection.ADD_COLLECTION_FAILURE,
    data: { msg },
  });
};

export const addCollectionHandler =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(addCollection());
      const result: any = await addCollectionAPI(data);
      dispatch(addCollectionSuccess(result));
    } catch (e: any) {
      dispatch(addCollectionFailure(e?.response?.data?.message));
    }
  };
