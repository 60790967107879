import { useState, useEffect } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GlobalDataType } from "../../redux/action/globalData";
import { getCollectionHandler } from "../../redux/action/getCollection/getCollection";
import { addCollectionItemHandler } from "../../redux/action/addItemToCollection/addItemToCollection";
import PageLoaderIcon from "../../assets/images/page-loader.svg";

import {
  ModalBody,
  ModalBodyBtn,
  CollectionsItem,
  CollectionsItemImage,
  ModalAddBtn,
  SearchListLoader,
  BackToList,
} from "../collectionsModal/collectionsModalStyle";
import AddCollectionModal from "../addCollectionModal";
import Nevada from "../../assets/images/nevada.png";

const GetAllCollectionsModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");
  const [getUploadBox, setUploadBox] = useState(false);

  const [AddCollectionModalShow, setAddCollectionModalShow] = useState({
    show: false,
    collection_id: "",
  });

  const addCollection = useSelector((state: any) => state.addCollection);

  const getCollection = useSelector((state: any) => state.getCollection);

  useEffect(() => {
    const data = {
      user_id: localStorage.getItem("id"),
    };

    dispatch(getCollectionHandler(data));
  }, []);

  const addItemtoCollection = (id: any) => {
    if (
      props?.getRentalDetails?.venue_type === "rental" ||
      props?.getRentalDetails?.venue_type === "concerts" ||
      props?.getRentalDetails?.venue_type === "sports" ||
      props?.getRentalDetails?.venue_type === "festival" ||
      props?.getRentalDetails?.venue_type === "hotel"
    ) {
      const data = {
        collection_id: id,
        venue_type: props?.getRentalDetails?.venue_type,
        venue_id: props?.getRentalDetails?.venue_id,
      };
      dispatch(addCollectionItemHandler(data));
    } else if (
      props?.getRentalDetails?.venue_type === "food and drink" ||
      props?.getRentalDetails?.venue_type === "point of interest" ||
      props?.getRentalDetails?.venue_type === "hiking"
    ) {
      const data = {
        collection_id: id,
        venue_type: props?.getRentalDetails?.venue_type,
        venue_id: props?.getRentalDetails?.venue_id,
        name: props?.getRentalDetails?.name,
        location: props?.getRentalDetails?.location,
        province: props?.getRentalDetails?.province,
        photo: props?.getRentalDetails?.Image,
      };
      dispatch(addCollectionItemHandler(data));
    }
    props.onHide();
    navigate("/collection");
  };

  return (
    <>
      {/* {getCollection?.isLoading && (
        <SearchListLoader>
          <img src={PageLoaderIcon} alt="Loading..." />
        </SearchListLoader>
      )} */}

      <Modal
        {...props}
        size="lg"
        className="collectionsModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .collectionsModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 400px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .collectionsModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Collections</h2>

            <ul>
              {getCollection?.data?.data?.map((ele: any) => {
                return (
                  ele?.user_id === localStorage.getItem("id") && (
                    <li>
                      <CollectionsItem>
                        <CollectionsItemImage
                          onClick={() => addItemtoCollection(ele?._id)}
                        >
                          <img src={ele?.cover_photo} alt={ele?.name} />
                        </CollectionsItemImage>
                        <h3>{ele?.name}</h3>
                      </CollectionsItem>
                    </li>
                  )
                );
              })}
            </ul>

            <ModalAddBtn>
              <Button
                className="btn"
                onClick={() => {
                  setAddCollectionModalShow({ show: true, collection_id: "" });
                  props.setCollectionModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g transform="translate(36 9807)">
                    <circle
                      cx="15"
                      cy="15"
                      r="15"
                      transform="translate(-36 -9807)"
                      fill="#6840df"
                    />
                    <path
                      d="M6.593-12.42v4.967H1.74V-5.69H6.593V-.745H8.539V-5.69h4.876V-7.453H8.539V-12.42Z"
                      transform="translate(-28.578 -9785.418)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </Button>
            </ModalAddBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>

      <AddCollectionModal
        show={AddCollectionModalShow.show}
        onHide={() => {
          setAddCollectionModalShow({ show: false, collection_id: "" });
          setImageValidation("");
          setImage("");
          setShowImage("");
        }}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        getItemId={props.getItemId}
        setShowImage={setShowImage}
        AddCollectionModalShow={AddCollectionModalShow}
        setUploadBox={setUploadBox}
        getUploadBox={getUploadBox}
        getRentalDetails={props?.getRentalDetails}
        setCollectionModal={props?.setCollectionModal}
      />
    </>
  );
};

export default GetAllCollectionsModal;
