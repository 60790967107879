import { joinPublicPlanAPI } from "../../../services/joinPublicPlan";

export enum joinPublicPlan {
  JOIN_PUBLIC_PLAN_INFO = "JOIN_PUBLIC_PLAN_INFO",
  JOIN_PUBLIC_PLAN_INFO_SUCCESS = "JOIN_PUBLIC_PLAN_INFO_SUCCESS",
  JOIN_PUBLIC_PLAN_INFO_FAILURE = "JOIN_PUBLIC_PLAN_INFO_FAILURE",
}

export const joinPublicPlanInfo = () => (dispatch: any) => {
  dispatch({
    type: joinPublicPlan.JOIN_PUBLIC_PLAN_INFO,
  });
};

export const joinPublicPlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: joinPublicPlan.JOIN_PUBLIC_PLAN_INFO_SUCCESS,
    data,
  });
};

export const joinPublicPlanInfoFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: joinPublicPlan.JOIN_PUBLIC_PLAN_INFO_FAILURE,
    data: { msg },
  });
};

export const joinPublicPlanInfoHandler =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(joinPublicPlanInfo());
      const result: any = await joinPublicPlanAPI(data);
      dispatch(joinPublicPlanInfoSuccess(result));
    } catch (e: any) {
      console.error(e);
      dispatch(joinPublicPlanInfoFailure(e?.message));
    }
  };
