import styled, { keyframes } from "styled-components";

export const PageWrap = styled.div`
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  background: #f5f5f5;
`;

export const PageWrapInner = styled.div`
  padding: 50px 0;
  background: #f5f5f5;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #3b3b3b;
  flex: 1 0 0;
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    color: #4d27da;
    &:hover {
      color: #3b3b3b;
    }
  }
  h1 {
    font-family: "Poppins";
    font-size: 34px;
    line-height: 44px;
    font-weight: 600;
    color: #4d27da;
    margin: 0 0 30px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 36px;
      margin: 0 0 20px;
    }
  }
  h2 {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 38px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0 0 20px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 20px;
    }
  }
  h3 {
    font-family: "Poppins";
    font-size: 26px;
    line-height: 34px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0 0 20px;
  }
  h4 {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0 0 20px;
  }
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ol {
    margin: 0 0 20px;
    padding: 0 0 0 25px;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      margin: 0 0 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin: 0 0 20px;
    padding: 0;
    list-style-type: none;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 10px;
      padding-left: 23px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        background: #3b3b3b;
        position: absolute;
        left: 3px;
        top: 7px;
        width: 7px;
        height: 7px;
        border-radius: 20px;
        display: inline-block;
      }
    }
  }
`;

export const PageContainer = styled.div`
  width: 900px;
  max-width: 100%;
  margin: auto;
  padding: 0 15px;
`;
export const SearchListLoader = styled.div`
  text-align: center;
  padding: 15px 0;
  pointer-events: none;
  img {
    width: 42px;
    height: 42px;
    vertical-align: middle;
  }
`;
