import { declinePlanAPI } from "../../../services/declinePlan";

export enum declinePlanType {
  DECLINE_PLAN = "DECLINE_PLAN",
  DECLINE_PLAN_SUCCESS = "DECLINE_PLAN_SUCCESS",
  DECLINE_PLAN_FALIURE = "DECLINE_PLAN_FALIURE",
}

export const declinePlan = () => (dispatch: any) => {
  dispatch({
    type: declinePlanType.DECLINE_PLAN,
  });
};

export const declinePlanSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: declinePlanType.DECLINE_PLAN_SUCCESS,
    data,
  });
};

export const declinePlanFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: declinePlanType.DECLINE_PLAN_FALIURE,
    data: { msg },
  });
};

export const declinePlanHandler = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(declinePlan());
    const result: any = await declinePlanAPI(finalData);
    dispatch(declinePlanSuccess(result));
  } catch (e: any) {
    dispatch(declinePlanFailed(e?.response?.data?.message));
  }
};
