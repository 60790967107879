import { resetPasswordAPI } from "../../../services/resetPassword";

export enum ResetPasswordType {
  RESET_PASSWORD_INFO = "RESET_PASSWORD_INFO",
  RESET_PASSWORD_INFO_SUCCESS = "RESET_PASSWORD_INFO_SUCCESS",
  RESET_PASSWORD_INFO_FALIURE = "RESET_PASSWORD_INFO_FALIURE",
}

export const resetPasswordInfo = () => (dispatch: any) => {
  dispatch({
    type: ResetPasswordType.RESET_PASSWORD_INFO,
  });
};

export const resetPasswordInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ResetPasswordType.RESET_PASSWORD_INFO_SUCCESS,
    data: data,
  });
};

export const resetPasswordInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ResetPasswordType.RESET_PASSWORD_INFO_FALIURE,
    data: { msg },
  });
};
export const resetPasswordDataAPI =
  ({ resetToken, newPassword, confirmPassword }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(resetPasswordInfo());
      const result: any = await resetPasswordAPI({
        resetToken,
        newPassword,
        confirmPassword,
      });
      dispatch(resetPasswordInfoSuccess(result));
    } catch (e: any) {
      dispatch(resetPasswordInfoFailed(e.response.data.message));
    }
  };
