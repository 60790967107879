import styled from "styled-components";

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 30px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: #3b3b3b;
      text-align: center;
      margin: 0 0 22px;
      text-align: left;
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: none;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 8px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
    }
    + .btn {
      margin-left: 20px;
    }
  }
`;

export const InviteGroupMemberItem = styled.div`
  padding: 0 0 0 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const InviteGroupCheckBox = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  user-select: none;
  margin: 0;
  min-height: 20px;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 5px;
      background: transparent;
      margin: auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
      transform: rotate(-25deg);
      transition: all 0.35s ease;
    }
  }
`;

export const GroupMemberItem = styled.div`
  align-items: center;
  display: flex;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top center;
    vertical-align: middle;
  }
  h4 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
    padding: 0 0 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const FiltersFormWrap = styled.div`
  /* max-height: 484px;
  overflow-y: scroll;
  padding-right: 12px; */
  @media (max-width: 767px) {
    max-height: inherit;
    overflow-y: visible;
    padding-right: 0;
  }
  /* &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  } */
  .form-label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #a1a3b0;
  }
  .form-control {
    height: 44px;
    border: 1px solid #cecece;
    border-radius: 5px;
    &:focus {
      box-shadow: none;
      border-color: #4d27da;
    }
  }
  hr {
    margin: 30px 0;
    background-color: #cecece;
    opacity: 1;
  }
  .checkbox-label {
    color: #a1a3b0;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .custom-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-label {
      margin: 0 6px 0 0;
    }
  }
  .ant-slider {
    margin: 10px;
  }
  .btn {
    background: #4d27da;
    border-color: #4d27da;
  }
`;

export const FormMultibutton = styled.div`
  display: flex;
  align-items: center;
  .btn {
    border: 1px solid #cecece;
    border-radius: 5px 0px 0px 5px;
    background: transparent;
    color: #a1a3b0;
    height: 36px;
    min-width: 36px;
    &.custom-plus {
      border-radius: 0 5px 5px 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .form-control {
    width: 55px;
    height: 36px;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    text-align: center;
    color: #3b3b3b;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    &:focus {
      border-color: #cecece;
    }
  }
`;

export const FormSubTitle = styled.h4`
  line-height: 23px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
  margin-bottom: 15px;
`;

export const InputRangeWrap = styled.div`
  padding: 0;
  margin: 15px 0 20px;
  .input-range__label--value {
    display: none;
  }
  .input-range__track--background {
    background: #cecece;
    height: 3px;
    width: 100% !important;
  }
  .input-range__track--active {
    display: none;
    height: 3px;
    background: #4d27da;
  }
  .input-range__slider {
    width: 20px;
    height: 20px;
    background: #4d27da;
    border: 1px solid #4d27da;
    margin: 0;
    top: 0;
  }
  .input-range__slider-container {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    top: -9px;
    + .input-range__slider-container {
      margin-left: -20px;
    }
  }
  .input-range__label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #3b3b3b;
    bottom: -25px;
    padding-left: 10px;
    &:before {
      content: "$";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .input-range__label-container {
    left: 0;
  }
`;

export const Filterdata = styled.div`
  max-height: 440px;
  overflow-y: scroll;
  padding-right: 12px;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
`;

export const Filterbtn = styled.div`
  text-align: center;
`;
