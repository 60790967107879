import React, { useState, useEffect, useRef } from "react";
import { InputNumber, Slider } from "antd";
import {
  Tabs,
  Tab,
  Modal,
  Dropdown,
  Button,
  Carousel,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";

import {
  ModalBody,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  FiltersFormWrap,
  FormSubTitle,
  FormMultibutton,
  InputRangeWrap,
  Filterbtn,
  Filterdata,
} from "../filtersModal/filtersModalStyle";

import { Formik } from "formik";

const RentalFilterModal = (props: any) => {
  const dispatch: any = useDispatch();

  const onChange = (value: number | [number, number]) => {
    console.log("onChange: ", value);
  };

  const GuestReviewtRef = useRef<any>(null);

  const filterInfo = useSelector((state: any) => state?.hotelFilterinfo);

  useEffect(() => {
    GuestReviewtRef?.current?.focus();
  }, [filterInfo.setGuestRatingValue]);

  const onAfterChange = (value: number | [number, number]) => {
    dispatch({ type: "HOTEL_SET_RANGE_VALUE", payload: value });
    // props.setRangeValue(value);
  };

  const GuestRatingStep = () => {
    const onChange = (newValue: any) => {
      dispatch({ type: "HOTEL_GUEST_RATING_VALUE", payload: newValue });
      // props.setGuestRatingValue(newValue);
      // dispatch({ type: "GUEST_RATING_VALUE", payload: newValue });
    };

    return (
      <Row>
        <Col span={12}>
          <Slider
            step={1}
            min={0}
            max={5}
            onChange={onChange}
            value={
              typeof filterInfo.setGuestRatingValue === "number"
                ? filterInfo.setGuestRatingValue
                : 0
            }
          />
        </Col>
        <Col span={4}>
          <InputNumber
            step={1}
            ref={GuestReviewtRef}
            min={0}
            max={5}
            maxLength={1}
            style={{
              margin: "0 16px",
            }}
            value={filterInfo.setGuestRatingValue}
            onChange={onChange}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <React.Fragment>
        <Modal
          {...props}
          size="lg"
          className="filtersModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <style>
            {`
              .filtersModal .modal-dialog {
                max-width: calc(100% - 34px);
                width: 561px;
                letter-spacing: 0;
                margin: auto;
                padding: 10px 0;
              }
              .filtersModal .modal-content {
                background: #FFFFFF;
                border-radius: 15px;
                border: none;
                box-shadow: 0px 3px 8px rgba(0,0,38,0.16);
              }
            `}
          </style>
          <ModalBody>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h2>Filters</h2>
              <FiltersFormWrap>
                <Form>
                  <FormSubTitle>Price</FormSubTitle>
                  <Filterdata>
                    <Row>
                      <Col lg={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>{`$${filterInfo.setRangeValue[0]}`}</p>
                          <p>
                            {filterInfo.setRangeValue[1] === 10000
                              ? `$${filterInfo.setRangeValue[1]}+`
                              : `$${filterInfo.setRangeValue[1]}`}
                          </p>
                        </div>

                        <Slider
                          range
                          step={100}
                          defaultValue={filterInfo.setRangeValue}
                          onChange={onChange}
                          onAfterChange={onAfterChange}
                          min={0}
                          max={10000}
                        />
                      </Col>
                    </Row>
                    <hr />

                    <FormSubTitle>Star Rating</FormSubTitle>
                    <Row>
                      <Col lg={12}>
                        <GuestRatingStep />
                      </Col>
                    </Row>
                    <hr />

                    <FormSubTitle>Features & Amenities</FormSubTitle>

                    <Row>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="smoke-free"
                              defaultChecked={filterInfo.setFacility.includes(
                                "smoke-free"
                              )}
                              onChange={
                                (e: any) =>
                                  e.target.checked
                                    ? // ? props.setFacility((prev: any) => [
                                      //     ...prev,
                                      //     e.target.value,
                                      //   ])

                                      dispatch({
                                        type: "HOTEL_SET_FACILITY",
                                        payload: e.target.value,
                                      })
                                    : dispatch({
                                        type: "HOTEL_REMOVE_FACILITY",
                                        payload: e.target.value,
                                      })

                                // props.setFacility(
                                //   props.getFacility.filter(
                                //     (ele: any) => ele !== e.target.value
                                //   )
                                // )
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">Smoke Free</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="fitness center"
                              defaultChecked={filterInfo.setFacility.includes(
                                "fitness center"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Fitness Center
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="concierge services"
                              defaultChecked={filterInfo.setFacility.includes(
                                "concierge services"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Concierge Services
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="continental breakfast"
                              defaultChecked={filterInfo.setFacility.includes(
                                "continental breakfast"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Continental Breakfast
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              defaultChecked={filterInfo.setFacility.includes(
                                "free self parking"
                              )}
                              value="free self parking"
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Free Self Parking
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>

                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="free WiFi"
                              defaultChecked={filterInfo.setFacility.includes(
                                "free WiFi"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">Free WiFi</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="outdoor pool"
                              defaultChecked={filterInfo.setFacility.includes(
                                "outdoor pool"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_FACILITY",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_FACILITY",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Outdoor Pool
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                    </Row>
                    <hr />

                    <FormSubTitle>Room Type</FormSubTitle>

                    <Row>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="Deluxe Studio"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "Deluxe Studio"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Deluxe Studio
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="Deluxe Suite"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "Deluxe Suite"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Deluxe Suite
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="room"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "room"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">Room</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>

                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="Standard Double Room"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "Standard Double Room"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Standard Double Room
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                    </Row>
                    <hr />

                    <FormSubTitle>Room No.</FormSubTitle>

                    <Row>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="1"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                1
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">1</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="2"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                2
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">2</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>

                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="3"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                3
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: Number(e.target.value),
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">3</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                    </Row>
                    <hr />

                    <FormSubTitle>House Rules</FormSubTitle>
                    <Row>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              defaultChecked={
                                filterInfo.setPetRules === true ? true : false
                              }
                              onChange={(e) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_PET_RULES",
                                      payload: Boolean(e.target.value),
                                    })
                                  : dispatch({
                                      type: "HOTEL_PET_RULES",
                                      payload: false,
                                    })
                              }
                              value="true"
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">
                              Pets allowed
                            </label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                    </Row>

                    <hr />

                    <FormSubTitle>Others.</FormSubTitle>

                    <Row>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="Bathtub"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "Bathtub"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">Bathtub</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                      <Col lg={6}>
                        <InviteGroupMemberItem className="mb-3">
                          <InviteGroupCheckBox>
                            <input
                              type="checkbox"
                              value="Accessible"
                              defaultChecked={filterInfo.setRoomTypes.includes(
                                "Accessible"
                              )}
                              onChange={(e: any) =>
                                e.target.checked
                                  ? dispatch({
                                      type: "HOTEL_SET_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                                  : dispatch({
                                      type: "HOTEL_REMOVE_ROOM_TYPE",
                                      payload: e.target.value,
                                    })
                              }
                            />
                            <span />
                          </InviteGroupCheckBox>
                          <GroupMemberItem>
                            <label className="checkbox-label">Accessible</label>
                          </GroupMemberItem>
                        </InviteGroupMemberItem>
                      </Col>
                    </Row>
                    <hr />
                  </Filterdata>
                  <Filterbtn>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        dispatch({
                          type: "HOTEL_UPDATE_FILTER_DATA",
                          payload: true,
                        });

                        props.setHotelFilter(false);
                        props.setPageNumber(1);
                        props.setFilterData(!props.getFilterData);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        dispatch({ type: "HOTEL_RESET_FILTER_DATA" });
                        props.setPageNumber(1);
                        props.setHotelFilter(false);
                        props.setFilterData(!props.getFilterData);
                        // props.setGuestRatingValue(0);
                        // props.setRangeValue([0, 1000]);
                        // props.setFacility([]);
                        // props.setRoomTypes([]);
                        // props.setPetRules(false);
                        // props.setFilterPagination1(false);
                      }}
                    >
                      Reset
                    </Button>
                  </Filterbtn>
                </Form>
              </FiltersFormWrap>
            </Modal.Body>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default RentalFilterModal;
