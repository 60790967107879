import { updatePlanAPI } from "../../../services/updatePlan";

export enum UpdatePlanType {
  UPDATEPLAN_INFO = "UPDATEPLAN_INFO",
  UPDATEPLAN_INFO_SUCCESS = "UPDATEPLAN_INFO_SUCCESS",
  UPDATEPLAN_INFO_FALIURE = "UPDATEPLAN_INFO_FALIURE",
}

export const updatePlanInfo = () => (dispatch: any) => {
  dispatch({
    type: UpdatePlanType.UPDATEPLAN_INFO,
  });
};

export const updatePlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: UpdatePlanType.UPDATEPLAN_INFO_SUCCESS,
    data,
  });
};

export const updatePlanInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: UpdatePlanType.UPDATEPLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const updatePlanData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(updatePlanInfo());
    const result: any = await updatePlanAPI(finalData);
    dispatch(updatePlanInfoSuccess(result));
  } catch (e: any) {
    dispatch(updatePlanInfoFailed(e.response.data.message));
  }
};
