import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteVenuePlanData } from "../../redux/action/deleteVenuePlan/deleteVenuePlan";
import {
  ModalBody,
  ModalBodyBtn,
} from "../deleteVenueModal/deleteVenueModalStyle";

const DeleteVenueModal = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const DeleteDays = () => {
    const finalResult = {
      plan_id: props?.planId,
      venue_id: props?.id,
    };
    dispatch(deleteVenuePlanData(finalResult));
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="deleteDaysModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .deleteDaysModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 343px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .deleteDaysModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>DELETE VENUE</h2>
            <p>
              Are you sure you want to delete <br />
              the venue?
            </p>
            <ModalBodyBtn>
              <Button
                onClick={() => {
                  DeleteDays();
                }}
              >
                YES
              </Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteVenueModal;
