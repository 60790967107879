import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  .modal-header {
    border: none;
    padding: 0;
    position: relative;
    display: block;
    border-radius: 0;
    .btn-close {
      padding: 0;
      position: absolute;
      right: 22px;
      top: 21px;
      width: 22px;
      height: 22px;
      background: transparent;
      margin: 0;
      border-radius: 0;
      z-index: 2;
      opacity: 1;
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: rotate(45deg);
        transition: all 0.35s ease 0s;
        background: #9597a6;
      }
      &:before {
        width: 22px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 22px;
      }
      &:hover:before,
      &:hover:after {
        background: #000;
      }
    }
  }

  .modal-body {
    padding: 20px 40px 30px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #3b3b3b;
    @media (max-width: 767px) {
      padding: 20px;
    }
    .modalTitle {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #3b3b3b;
      text-align: center;
      max-width: 410px;
      margin: auto auto 25px;
      h2 {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: #4d27da;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
      }
    }
    p {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .addFriendsBtn {
      border: none;
      padding: 0;
      border-radius: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #3b3b3b;
      background: transparent;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      margin-bottom: 15px;
      align-items: center;
      display: flex;
      transition: all 0.35s ease 0s;
      svg {
        margin-right: 15px;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &:hover {
        color: #000;
        circle {
          fill: #000;
        }
      }
    }

    .dateInputGroup {
      width: 164px;
      position: relative;
      .react-datepicker-popper {
        z-index: 9;
        &[data-placement^="bottom"] {
          padding-top: 5px;
        }
      }
      .react-datepicker {
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.1);
        padding: 11px 20px;
        width: 291px;
        .react-datepicker__triangle {
          display: none;
        }
        .react-datepicker__navigation--previous {
          left: 7px;
          .react-datepicker__navigation-icon--previous {
            &:before {
              left: -4px;
              right: auto;
            }
          }
        }
        .react-datepicker__navigation--next {
          right: 8px;
          .react-datepicker__navigation-icon--next {
            &:before {
              right: -4px;
              left: auto;
            }
          }
        }
        .react-datepicker__navigation-icon {
          padding: 0;
          &:before {
            border-width: 2px 2px 0 0;
            border-color: #3b3b3b;
            height: 8px;
            width: 8px;
          }
        }
        .react-datepicker__month {
          margin: 0;
        }
        .react-datepicker__header {
          background-color: #ffffff;
          border-bottom: none;
          padding: 0 0 20px;
          .react-datepicker__current-month,
          .react-datepicker-year-header,
          .react-datepicker-time__header {
            color: #0e1224;
            font-family: "Poppins";
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
            border-bottom: 1px solid #f5f5f6;
            padding-bottom: 10px;
            margin-bottom: 10px;
            margin-top: -3px;
          }
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            color: #3b3b3b;
            font-family: "Poppins";
            font-size: 12px;
            line-height: 19px;
            font-weight: 400;
          }
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          margin: 3px 4px;
          height: 27px;
          width: 27px;
          font-family: "Poppins";
          font-size: 13px;
          line-height: 24px;
          font-weight: normal;
          color: #0e1224;
          border-radius: 30px;
          border: 2px solid transparent;
          transition: all 0.35s ease 0s;
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
          border-radius: 50%;
          border: 2px solid #6840df;
          background-color: transparent;
          color: #3b3b3b;
        }
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected,
        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
          border-radius: 50%;
          border: 2px solid #6840df;
          background-color: transparent;
          color: #3b3b3b;
        }
        .react-datepicker__day--outside-month {
          color: #8d8d8d;
        }
        .react-datepicker__day--disabled {
          color: #8d8d8d;
          pointer-events: none;
        }
      }
      .form-control {
        padding-right: 45px;
      }
      .calendar-icon {
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        position: absolute;
        top: 13px;
        right: 20px;
        pointer-events: none;
        z-index: 1;
        img {
          vertical-align: middle;
          max-width: 100%;
        }
      }
    }
    ${FormGroup} {
      .form-control {
        &::-webkit-input-placeholder {
          font-size: 14px;
        }
        &::-moz-placeholder {
          font-size: 14px;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
        }
        &:-moz-placeholder {
          font-size: 14px;
        }
      }
      textarea {
        height: 70px;
        resize: none;
      }
    }
  }

  .planFeature {
    padding: 0 10px;
    margin: 17px 0 0;
    list-style-type: none;
    > li {
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #a1a3b0;
      padding-right: 50px;
      margin-bottom: 15px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      @media (max-width: 565px) {
        padding-right: 0;
      }
      h4 {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #3b3b3b;
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        @media (max-width: 565px) {
          padding-right: 50px;
        }
      }
      p {
        margin-bottom: 0;
      }
      .form-switch {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        font-size: 0;
        line-height: 0;
        min-height: inherit;
        .form-check-input {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: 0;
          z-index: 1;
          cursor: pointer;
          border-radius: 30px;
          background: transparent;
          border: none;
          box-shadow: none;
          opacity: 0;
        }
        .form-check-label {
          border: 1px solid #8d8d8d;
          border-radius: 30px;
          width: 38px;
          height: 22px;
          background: transparent;
          font-size: 0;
          line-height: 0;
          display: block;
          position: relative;
          text-indent: -99px;
          overflow: hidden;
          &:before {
            content: "";
            width: 16px;
            height: 16px;
            border-radius: 20px;
            background: #cecece;
            display: inline-block;
            position: absolute;
            left: 2px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: all 0.25s ease 0s;
          }
        }
        .form-check-input {
          &:checked {
            + .form-check-label {
              &:before {
                left: 18px;
                background: #48d85e;
              }
            }
          }
        }
      }
      .planVisibility {
        margin: 10px -10px 0;
        padding: 0;
        list-style-type: none;
        flex-wrap: wrap;
        display: flex;
        > li {
          padding: 0 10px;
          text-align: left;
          width: 50%;
          margin: 20px 0 0;
          &:first-child,
          &:nth-child(2) {
            margin-top: 0;
          }
          @media (max-width: 565px) {
            width: 100%;
            margin: 15px 0 0 !important;
          }
        }
      }
    }
  }

  .friendsList {
    padding: 5px 0 3px;
    margin: 0 -10px;
    list-style-type: none;
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
    > li {
      width: 33.33%;
      padding: 0 10px;
      margin: 0 0 17px;
      @media (max-width: 565px) {
        width: 50%;
      }
    }
  }
`;

export const ModalBodyBtn = styled.div`
  text-align: center;
  margin-top: 30px;
  .btn {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 30px;
    min-width: 121px;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 7px 15px;
    box-shadow: none !important;
    color: #fff;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    &:hover {
      background: #4d27da;
      color: #fff;
      border-color: #4d27da;
    }
    + .btn {
      margin-left: 20px;
    }
    &.cancelBtn {
      background: transparent;
      color: #3b3b3b;
      &:hover {
        background: #4d27da;
        color: #fff;
        border-color: #4d27da;
      }
    }
  }
`;

export const FriendItem = styled.div`
  img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
    border-radius: 50px;
  }
  h3 {
    font-family: "Poppins";
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #8d8d8d;
    margin: 10px 0 0;
  }
  ul {
    margin: 0 0 0 -2px;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    li {
      position: relative;
      border: 2px solid #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      margin-right: -16px;
      .totalFriends {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 40px;
        background: #6840df;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.35s ease 0s;
        &:hover {
          background: #000000;
          color: #fff;
        }
      }
    }
  }
`;

export const CreatePlanDate = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 20px;
  display: flex;
  img {
    margin-right: 10px;
  }
`;

export const RadioBox = styled.div`
  display: inline-block;
  padding: 0 0 0 33px;
  margin: 0;
  min-height: 16px;
  position: relative;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  color: #a1a3b0;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 1;
    &:checked {
      ~ span {
        background-color: #4d27da;
        border-color: #4d27da;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #8d8d8d;
    transition: all 0.35s ease;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      background: #ffffff;
      margin: auto;
      opacity: 0;
      transition: all 0.35s ease;
    }
  }
  label {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #3b3b3b;
    display: block;
    margin: 0 0 2px;
    img {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
`;

export const CreatePlanBody = styled.div`
  max-height: 440px;
  overflow-y: auto;
  margin: 0 -18px;
  padding: 0 18px;
  @media (max-width: 767px) {
    max-height: inherit;
    overflow-y: visible;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
`;

export const PlanDates = styled.div`
  display: flex;
  .dateInputGroup {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
