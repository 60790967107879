import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import { ModalBody, ModalBodyBtn } from "../unfollowModal/unfollowModalStyle";
import { useDispatch, useSelector } from "react-redux";
import { deleteGroupMember } from "../../redux/action/delete-group-member/deleteGroupMember";
import { useNavigate } from "react-router-dom";
const RemoveGroupMemberModel = (props: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const removeGroup = () => {
    const data = {
      member_id: props.removeId,
      group_id: props.groupId,
    };
    if (data) {
      dispatch(deleteGroupMember(data));
      navigate("/");
    }

    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="unfollowModal"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        // keyboard={false}
        centered
      >
        <style>
          {`
          .unfollowModal .modal-dialog {
            max-width: calc(100% - 34px);
            width: 343px;
            letter-spacing: 0;
            margin: auto;
            padding: 30px 0;
          }
          .unfollowModal .modal-content {
            background: #FFFFFF;
            border-radius: 15px;
            border: none;
            box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
          }
        `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2> Exit this Group ?</h2>
            <p>
              Are you sure you want to Exit this
              <br /> {props.getUserName} {props.getGroupName}?
            </p>
            <ModalBodyBtn>
              <Button
                onClick={() => {
                  removeGroup();
                }}
              >
                YES
              </Button>
              <Button className="cancelBtn" onClick={props.onHide}>
                NO
              </Button>
            </ModalBodyBtn>
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RemoveGroupMemberModel;
