import { useEffect, useState } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { useDispatch, useSelector } from "react-redux";
import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import CandyBrian from "../../assets/images/candy-brian.png";
import Kathy from "../../assets/images/kathy.png";
import emailImage from "../../assets/images/defaultImage.jpg";

import James from "../../assets/images/james.png";
import { getAllFriendData } from "../../redux/action/getallfriends/getAllFriends";
import { getFollowersAPIData } from "../../redux/action/getfollowers/getFollowers";
import { searchFollowData } from "../../redux/action/search-followers/searchFollowers";
import { searchFriendsAPIData } from "../../redux/action/search-friends/searchFriend";
import { invitePlanHandler } from "../../redux/action/invitePlan/invitePlan";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
  FriendItem,
  ButtonTabs,
  TabButton,
  InviteOtherSearch,
  MyGroupTabContent,
  MyGroupTabContentInner,
  InviteMyGroups,
  InviteGroupMembers,
  InviteGroupRadioBox,
  InviteGroupItem,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  MyFollowersLeft,
  MyFollowersRight,
  MyFollowersItemWrap,
  MyFollowersItem,
} from "../inviteOthersModal/inviteOthersModalStyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getGroupData } from "../../redux/action/get-all-group/getAllGroups";
import { addInviteGroupApi } from "../../redux/action/add-Invite-Group/addInviteGroup";

const ChatsPlanModal = (props: any) => {
  const [getSearchTerm, setSearchTerm] = useState("");
  const [getFollowerSearchTerm, setFollowerSearchTerm] = useState("");
  const [getSearchGroup, setSearchGroup] = useState("");
  const [grouplist, setGroupList] = useState<any[]>([]);
  const [getAllModalMembers, setAllModalMembers] = useState<any[]>([]);
  const [getAllUserData, setAllUserData] = useState<any[]>([]);

  const dispatch: any = useDispatch();

  const removeInvitePlan: any = useSelector(
    (state: any) => state?.removeInvitePlan
  );

  useEffect(() => {
    dispatch(getAllFriendData(localStorage.getItem("id")));
    dispatch(getFollowersAPIData(localStorage.getItem("id")));
    dispatch(getGroupData({ search: "" }));
  }, [removeInvitePlan]);

  // useEffect(() => {
  //   dispatch(getGroupData({ search: "" }));
  //   const finalValue = {
  //     search: "",
  //     user_id: localStorage.getItem("id"),
  //   };
  //   dispatch(searchFriendsAPIData(finalValue));
  //   dispatch(searchFollowData(finalValue));
  //   setSearchGroup("");
  //   setFollowerSearchTerm("");
  //   setSearchTerm("");
  //   props?.setEmail("");
  //   props?.setEmailValidate("");
  // }, [props?.openTab]);

  const getFollowersList = useSelector((state: any) =>
    state?.getFollowers?.data?.data?.filter(
      (ele: any) =>
        !ele?.plan_id?.includes(props.planId) &&
        !props?.pending_invite?.includes(ele?.follower_id) &&
        !ele?.group_id?.includes(props?.groupId)
    )
  );

  const getFriendsList = useSelector((state: any) =>
    state?.getAllFriends?.data?.data?.filter(
      (ele: any) =>
        !ele.plan_id.includes(props.planId) &&
        !props?.pending_invite?.includes(ele?.friends_id) &&
        !ele?.group_id?.includes(props?.groupId)
    )
  );

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  const searchFriendList = useSelector((state: any) =>
    state?.searchFriends?.data?.data?.filter(
      (ele: any) =>
        !ele.plan_id.includes(props.planId) &&
        !props?.pending_invite?.includes(ele?.friends_id) &&
        !ele?.group_id?.includes(props?.groupId)
    )
  );

  const searchFollowersList = useSelector((state: any) =>
    state?.searchFollowers?.data?.data?.filter(
      (ele: any) =>
        !ele.plan_id.includes(props.planId) &&
        !props?.pending_invite?.includes(ele?.follower_id) &&
        !ele?.group_id?.includes(props?.groupId)
    )
  );

  const searchFriend = (e: any) => {
    setSearchTerm(e.target.value.trim());
    const finalValue = {
      search: e.target.value.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (e.target.value.trim() !== "") {
      dispatch(searchFriendsAPIData(finalValue));
    }
  };

  const searchFollower = (e: any) => {
    setFollowerSearchTerm(e.target.value.trim());
    const finalValue = {
      search: e.target.value.trim(),
      user_id: localStorage.getItem("id"),
    };

    if (e.target.value.trim() !== "") {
      dispatch(searchFollowData(finalValue));
    }
  };

  const gettingFollowerIdHandler = (e: any, ele: any) => {
    if (e.target.checked) {
      props.setInviteFollower((prev: any) => [...prev, ele]);

      props.setPendingEmail((prev: any) => [...prev, ele]);
      !props?.getAllId?.includes(ele?.follower_id) &&
        props.setAllId((prev: any) => [...prev, ele?.follower_id]);

      getFriendsList?.map((element: any) => {
        element?.friends_id === ele?.follower_id &&
          props.setInviteFriend((prev: any) => [...prev, element]);
      });

      props?.getAllUniqueGroupMember?.map((element: any) => {
        element?._id === ele?.follower_id &&
          props?.setGroupMembers((prev: any) => [...prev, element]);
      });
    } else {
      props.setInviteFollower(
        props.inviteFollower.filter(
          (element: any) => element?.follower_id !== ele?.follower_id
        )
      );

      props.setPendingEmail(
        props.pendingEmailRequest.filter(
          (element: any) => element?.email !== ele?.email
        )
      );

      props.setGroupMembers(
        props?.inviteGroupMembers?.filter(
          (element: any) => element?._id !== ele?.follower_id
        )
      );

      props.setInviteFriend(
        props.inviteFriend.filter(
          (element: any) => element?.friends_id !== ele?.follower_id
        )
      );

      props.setAllId(
        props?.getAllId?.filter((element: any) => element !== ele?.follower_id)
      );
    }
  };

  const gettingFriendsIdHandler = (e: any, ele: any) => {
    if (e.target.checked) {
      props.setInviteFriend((prev: any) => [...prev, ele]);
      props.setPendingEmail((prev: any) => [...prev, ele]);

      getFollowersList?.map((element: any) => {
        element?.follower_id === ele?.friends_id &&
          props.setInviteFollower((prev: any) => [...prev, element]);
      });

      props?.getAllUniqueGroupMember?.map((element: any) => {
        element?._id === ele?.friends_id &&
          props?.setGroupMembers((prev: any) => [...prev, element]);
      });

      !props?.getAllId?.includes(ele?.friends_id) &&
        props.setAllId((prev: any) => [...prev, ele?.friends_id]);
    } else {
      props?.setInviteFriend(
        props.inviteFriend.filter(
          (element: any) => element?.friends_id !== ele?.friends_id
        )
      );

      props?.setGroupMembers(
        props?.inviteGroupMembers?.filter(
          (element: any) => element?._id !== ele?.friends_id
        )
      );

      props.setAllId(
        props?.getAllId?.filter((element: any) => element !== ele?.friends_id)
      );

      props.setInviteFollower(
        props.inviteFollower.filter(
          (element: any) => element?.follower_id !== ele?.friends_id
        )
      );

      props.setPendingEmail(
        props.pendingEmailRequest.filter(
          (element: any) => element?.email !== ele?.email
        )
      );
    }
  };

  const gettingGroupMembersHandler = (e: any, ele: any) => {
    if (e.target.checked) {
      props?.setGroupMembers((prev: any) => [...prev, ele]);

      props?.setPendingEmail((prev: any) => [...prev, ele]);

      !props?.getAllId?.includes(ele?._id) &&
        props.setAllId((prev: any) => [...prev, ele?._id]);

      getFriendsList?.map((element: any) => {
        element?.friends_id === ele?._id &&
          props.setInviteFriend((prev: any) => [...prev, element]);
      });

      getFollowersList?.map((element: any) => {
        element?.follower_id === ele?._id &&
          props.setInviteFollower((prev: any) => [...prev, element]);
      });
    } else {
      props.setInviteFriend(
        props.inviteFriend.filter(
          (element: any) => element?.friends_id !== ele?._id
        )
      );

      props?.setGroupMembers(
        props?.inviteGroupMembers?.filter(
          (element: any) => element?._id !== ele?._id
        )
      );

      props.setAllId(
        props?.getAllId?.filter((element: any) => element !== ele?._id)
      );

      props.setInviteFollower(
        props.inviteFollower.filter(
          (element: any) => element?.follower_id !== ele?._id
        )
      );

      props.setPendingEmail(
        props.pendingEmailRequest.filter(
          (element: any) => element?.email !== ele?.email
        )
      );
    }
  };

  const emailValidator = (value: any) => {
    props.setEmail(value);

    const userInfo: any = JSON.parse(localStorage.getItem("user") as string);

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const findDuplicateEmail = props.inviteEmails.filter(
      (ele: any) => ele?.email === value
    );

    const pendingRequestEmail = props.pendingEmailRequest.filter(
      (ele: any) => ele?.userEmail === value
    );

    if (
      emailRegex.test(value) &&
      findDuplicateEmail.length === 0 &&
      userInfo.email !== value &&
      pendingRequestEmail.length === 0
    ) {
      props.setEmailValidate("");
    } else if (!emailRegex.test(value)) {
      props.setEmailValidate("Please enter valid email address!");
    } else if (emailRegex.test(value) && findDuplicateEmail.length > 0) {
      props.setEmailValidate("Email with this user already exist in plan!");
    } else if (emailRegex.test(value) && pendingRequestEmail.length > 0) {
      props.setEmailValidate("Already exist in invitation email!");
    } else if (userInfo.email === value) {
      props.setEmailValidate("You can't invite login user!");
    }
  };

  const getAllEmailHandler = () => {
    props.getEmails === "" &&
      props.setEmailValidate("Please enter valid email address!");

    // props.getUpdatedEmail === "" &&
    props.emailValidate === "" &&
      props.getEmails !== "" &&
      props.getAllUniqueGroupMember?.filter(
        (element: any) => element?.email === props.getEmails
      ).length === 0 &&
      props.setPendingEmail((prev: any) => [
        ...prev,
        { userEmail: props.getEmails, emailPhoto: emailImage },
      ]);

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      props.getAllUniqueGroupMember?.filter(
        (element: any) =>
          element?.email === props.getEmails &&
          props.setPendingEmail((prev: any) => [...prev, element])
      );

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      getFriendsList?.filter(
        (element: any) => element?.email === props.getEmails
      ).length === 0 &&
      getFollowersList?.filter(
        (element: any) => element?.email === props.getEmails
      ).length === 0 &&
      props.getAllUniqueGroupMember?.filter(
        (element: any) => element?.email === props.getEmails
      ).length === 0 &&
      props.setAllEmail((prev: any) => [...prev, props.getEmails]);

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      getFriendsList?.map((element: any) => {
        element?.email === props.getEmails &&
          props.setInviteFriend((prev: any) => [...prev, element]);
      });

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      getFollowersList?.map((element: any) => {
        element?.email === props.getEmails &&
          props.setInviteFollower((prev: any) => [...prev, element]);
      });

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      getFriendsList?.map((element: any) => {
        element?.email === props.getEmails &&
          props.setAllId((prev: any) => [...prev, element?.friends_id]);
      });

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      getFollowersList?.map((element: any) => {
        element?.email === props.getEmails &&
          props.setAllId((prev: any) => [...prev, element?.follower_id]);
      });

    props.emailValidate === "" &&
      props.getEmails !== "" &&
      props?.getAllUniqueGroupMember?.map((element: any) => {
        element?.email === props.getEmails &&
          props.setAllId((prev: any) => [...prev, element?._id]);
      });

    props.emailValidate === "" && props.setEmail("");
  };

  const invitedUserHandler = () => {
    if (props.iniviteUser) {
      const removeLoginId = props.getAllId.filter(
        (ele: any) => ele !== localStorage.getItem("id")
      );

      const removeDuplicate = removeLoginId.filter(
        (ele: any, index: any) => removeLoginId.indexOf(ele) === index
      );

      const data: any = {
        plan_id: props.planId,
        invited_user: removeDuplicate,
        emails: props.getAllEmail,
        is_requested: true,
        notification_type: "Request To Join Plan",
      };

      (data?.emails?.length > 0 || data?.invited_user?.length > 0) &&
        dispatch(invitePlanHandler(data));
    } else if (props.inivitGroup) {
      const removeLoginId = props.getAllId.filter(
        (ele: any) => ele !== localStorage.getItem("id")
      );
      const removeDuplicate = removeLoginId.filter(
        (ele: any, index: any) => removeLoginId.indexOf(ele) === index
      );

      const data = {
        group_id: props.propsId,
        emails: props.getAllEmail,
        invited_user: removeDuplicate,
        notification_type: "Request To Join Group",
      };

      dispatch(addInviteGroupApi(data));
    } else if (props.inivitPlan) {
      const removeLoginId = props.getAllId.filter(
        (ele: any) => ele !== localStorage.getItem("id")
      );
      const data = {
        emails: props.getAllEmail,
        invited_user: removeLoginId,
        is_requested: true,
        notification_type: "Request To Join Plan",
        plan_id: props.planId,
      };
      dispatch(invitePlanHandler(data));
    }
  };

  const userNameHandler = (e: any) => {
    const re = /^[a-zA-Z0-9-_.]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const groupSearch = (e: any) => {
    setSearchGroup(e.target.value);
    dispatch(getGroupData({ search: e.target.value }));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="inviteOthersModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .inviteOthersModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 642px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .inviteOthersModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>
        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>INVITE OTHERS</h2>

            <ButtonTabs>
              {
                <TabButton
                  className={`${props.openTab?.group ? `active` : ""}`}
                  onClick={() => {
                    props.setOpenTab({
                      follower: false,
                      group: true,
                      friend: false,
                      email: false,
                    });
                    group_data?.data?.data[0]?.GroupList?.map(
                      (list: any, index: any) => {
                        index === 0 && props.setGroupId(list?._id);
                      }
                    );
                  }}
                >
                  MY GROUPS
                </TabButton>
              }

              {
                <TabButton
                  className={`${props.openTab?.follower ? `active` : ""}`}
                  onClick={() => {
                    props.setOpenTab({
                      follower: true,
                      group: false,
                      friend: false,
                      email: false,
                    });
                  }}
                >
                  FOLLOWERS
                </TabButton>
              }

              {
                <TabButton
                  className={`${props.openTab?.friend ? `active` : ""}`}
                  onClick={() => {
                    props.setOpenTab({
                      follower: false,
                      group: false,
                      friend: true,
                      email: false,
                    });
                  }}
                >
                  FRIENDS
                </TabButton>
              }

              {
                <TabButton
                  className={`${props.openTab?.email ? `active` : ""}`}
                  onClick={() => {
                    props.setOpenTab({
                      follower: false,
                      group: false,
                      friend: false,
                      email: true,
                    });
                  }}
                >
                  EMAIL
                </TabButton>
              }
            </ButtonTabs>

            {/* MY GROUPS tabs content */}
            {props.openTab?.group && (
              <MyGroupTabContent>
                <InviteOtherSearch>
                  <Form>
                    <FormGroup>
                      <Form.Control
                        type="text"
                        placeholder="Search Groups"
                        onKeyPress={userNameHandler}
                        onChange={(e: any) => groupSearch(e)}
                        value={getSearchGroup}
                      />
                      <button type="button" className="searchIconBtn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21.921"
                          height="22.01"
                          viewBox="0 0 21.921 22.01"
                        >
                          <path
                            d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                            transform="translate(-3.002 -3.001)"
                            fill="#9597a5"
                          />
                        </svg>
                      </button>
                    </FormGroup>
                  </Form>
                </InviteOtherSearch>

                <MyGroupTabContentInner>
                  {group_data?.data?.data[0]?.GroupList?.length !== 0 && (
                    <InviteMyGroups>
                      <ul>
                        {group_data?.data?.data[0]?.GroupList.map(
                          (list: any, index: any, arr: any) => (
                            <li>
                              <InviteGroupItem>
                                <InviteGroupRadioBox>
                                  <input
                                    type={
                                      arr.length === 1 ? "checkbox" : "radio"
                                    }
                                    name="inviteGroup"
                                    value={list?._id}
                                    onChange={(e) => {
                                      props?.setGroupId(e.target.value);
                                    }}
                                    defaultChecked={index === 0 ? true : false}
                                  />
                                  <span />
                                </InviteGroupRadioBox>
                                <FriendItem>
                                  <h3>{list?.name}</h3>
                                  <ul>
                                    {list?.members?.map((ele: any) => {
                                      return (
                                        <li>
                                          <img
                                            src={`${
                                              group_data?.data?.data[0]?.link +
                                              ele?.photo
                                            }`}
                                            alt="kathy"
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </FriendItem>
                              </InviteGroupItem>
                            </li>
                          )
                        )}
                      </ul>
                    </InviteMyGroups>
                  )}

                  {group_data?.data?.data[0]?.GroupList?.length === 0 && (
                    <InviteMyGroups>
                      <ul>
                        <li className="noRecordFound">No any Group found!</li>
                      </ul>
                    </InviteMyGroups>
                  )}

                  {group_data?.data?.data[0]?.GroupList?.length !== 0 && (
                    <InviteGroupMembers>
                      <h3>Group Members</h3>
                      <ul>
                        {group_data?.data?.data[0]?.GroupList.map(
                          (list: any) =>
                            props.getGroupId === list?._id &&
                            list?.members?.map((ele: any) => {
                              return (
                                !ele?.plan_id?.includes(props.planId) &&
                                !ele?.group_id?.includes(props.groupId) &&
                                !props?.pending_invite?.includes(ele?._id) && (
                                  <li>
                                    <InviteGroupMemberItem>
                                      <InviteGroupCheckBox>
                                        <input
                                          type="checkbox"
                                          checked={
                                            props?.getAllId?.find(
                                              (element: any) =>
                                                element === ele?._id
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e: any) =>
                                            gettingGroupMembersHandler(e, ele)
                                          }
                                        />
                                        <span />
                                      </InviteGroupCheckBox>

                                      <GroupMemberItem>
                                        <img
                                          src={`${
                                            group_data?.data?.data[0]?.link +
                                            ele?.photo
                                          }`}
                                          alt={ele?.name}
                                        />
                                        <h4>
                                          {ele?.userName || ele?.user_name}
                                        </h4>
                                      </GroupMemberItem>
                                    </InviteGroupMemberItem>
                                  </li>
                                )
                              );
                            })
                        )}
                      </ul>

                      <h3>Invited Group Members</h3>

                      <ul>
                        {props?.inviteGroupMembers?.map((list: any) => (
                          <li>
                            {/* <InviteGroupMemberItem> */}
                            <GroupMemberItem>
                              <img
                                src={`${
                                  group_data?.data?.data[0]?.link + list?.photo
                                }`}
                                alt={list?.userName}
                              />
                              <h4>{list?.userName}</h4>
                            </GroupMemberItem>
                            {/* </InviteGroupMemberItem> */}
                          </li>
                        ))}
                      </ul>

                      {props?.inviteGroupMembers?.length === 0 && (
                        <ul>
                          <li className="noRecordFound">
                            No any Invited Group members!
                          </li>
                        </ul>
                      )}
                    </InviteGroupMembers>
                  )}
                </MyGroupTabContentInner>
                <ModalBodyBtn>
                  <Button
                    className="btn"
                    onClick={() => {
                      props.setOpenTab({
                        follower: true,
                        group: false,
                        friend: false,
                        email: false,
                      });
                      props.setEmailValidate("");
                      props.setEmail("");
                    }}
                  >
                    ADD
                  </Button>
                  <Button
                    className="btn"
                    onClick={() => {
                      props.setInviteOthersModalShow(false);
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: false,
                        email: false,
                      });
                      invitedUserHandler();
                    }}
                  >
                    DONE
                  </Button>
                </ModalBodyBtn>
              </MyGroupTabContent>
            )}
            {/* END MY GROUPS tabs content */}

            {/* FOLLOWERS tabs content */}
            {props.openTab?.follower && (
              <MyGroupTabContent>
                <MyGroupTabContentInner>
                  <MyFollowersLeft>
                    <InviteOtherSearch>
                      <Form>
                        <FormGroup>
                          <Form.Label>Search your followers</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            onChange={(e: any) => searchFollower(e)}
                            value={getFollowerSearchTerm}
                            onKeyPress={userNameHandler}
                          />
                          <button type="button" className="searchIconBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.921"
                              height="22.01"
                              viewBox="0 0 21.921 22.01"
                            >
                              <path
                                d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                transform="translate(-3.002 -3.001)"
                                fill="#9597a5"
                              />
                            </svg>
                          </button>
                        </FormGroup>
                      </Form>
                    </InviteOtherSearch>

                    <ul>
                      {(
                        (getFollowerSearchTerm === "" && getFollowersList) ||
                        (getFollowerSearchTerm !== "" && searchFollowersList)
                      )?.map((ele: any) => {
                        return (
                          <li>
                            <MyFollowersItemWrap>
                              <InviteGroupCheckBox>
                                <input
                                  type="checkbox"
                                  value={ele?.friends_id}
                                  checked={
                                    props?.getAllId?.find(
                                      (element: any) =>
                                        element == ele?.follower_id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) =>
                                    gettingFollowerIdHandler(e, ele)
                                  }
                                />
                                <span />
                              </InviteGroupCheckBox>
                              <MyFollowersItem>
                                <img src={ele?.photo} alt={ele?.user_name} />
                                <h4>
                                  {getFollowerSearchTerm === ""
                                    ? ele?.user_name
                                    : ele?.userName}
                                </h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}
                      {getFollowersList?.length === 0 &&
                        getFollowerSearchTerm === "" && (
                          <li>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <h4 className="noRecordFound">
                                  No any followers available for invitation, Add
                                  atleast one followers!
                                </h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        )}

                      {searchFollowersList?.length === 0 &&
                        getFollowerSearchTerm !== "" && (
                          <li>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <h4 className="noRecordFound">
                                  No any follower found!
                                </h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        )}
                    </ul>
                  </MyFollowersLeft>
                  <MyFollowersRight>
                    <h3>Invited</h3>
                    <ul>
                      {props.inviteFollower?.map((ele: any) => {
                        return (
                          <li>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <img src={ele?.photo} alt={ele?.user_name} />
                                <h4>{ele?.user_name || ele?.userName}</h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}
                      {props.inviteFollower?.length === 0 && (
                        <li>
                          <MyFollowersItemWrap>
                            <MyFollowersItem>
                              <h4 className="noRecordFound">
                                No any invitation found!
                              </h4>
                            </MyFollowersItem>
                          </MyFollowersItemWrap>
                        </li>
                      )}
                    </ul>
                  </MyFollowersRight>
                </MyGroupTabContentInner>

                <ModalBodyBtn>
                  <Button
                    className="btn"
                    onClick={() =>
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: true,
                        email: false,
                      })
                    }
                  >
                    ADD
                  </Button>
                  <Button
                    className="btn"
                    onClick={() => {
                      props.setInviteOthersModalShow(false);
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: false,
                        email: false,
                      });
                      invitedUserHandler();
                    }}
                  >
                    DONE
                  </Button>
                </ModalBodyBtn>
              </MyGroupTabContent>
            )}
            {/* END FOLLOWERS tabs content */}

            {/* FRIENDS tabs content */}
            {props.openTab?.friend && (
              <MyGroupTabContent>
                <MyGroupTabContentInner>
                  <MyFollowersLeft>
                    <InviteOtherSearch>
                      <Form>
                        <FormGroup>
                          <Form.Label>Search your friends</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            onChange={(e: any) => searchFriend(e)}
                            onKeyPress={userNameHandler}
                            value={getSearchTerm}
                          />
                          <button type="button" className="searchIconBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.921"
                              height="22.01"
                              viewBox="0 0 21.921 22.01"
                            >
                              <path
                                d="M19.978,17.941l-.918-.975a7.878,7.878,0,0,0,1.6-5.136,8.829,8.829,0,1,0-8.829,8.829,9.11,9.11,0,0,0,5.108-1.633c0,.015,1.006.956,1.006.956L22.9,25.011l2.023-2.023Zm-8.15,0a6.1,6.1,0,1,1,4.331-1.788A6.1,6.1,0,0,1,11.828,17.941Z"
                                transform="translate(-3.002 -3.001)"
                                fill="#9597a5"
                              />
                            </svg>
                          </button>
                        </FormGroup>
                      </Form>
                    </InviteOtherSearch>
                    <ul>
                      {(
                        (getSearchTerm === "" && getFriendsList) ||
                        (getSearchTerm !== "" && searchFriendList)
                      )?.map((ele: any) => {
                        return (
                          <li>
                            <MyFollowersItemWrap>
                              <InviteGroupCheckBox>
                                <input
                                  type="checkbox"
                                  value={ele?.friends_id}
                                  checked={
                                    props?.getAllId?.find(
                                      (element: any) =>
                                        element == ele?.friends_id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) =>
                                    gettingFriendsIdHandler(e, ele)
                                  }
                                />
                                <span />
                              </InviteGroupCheckBox>
                              <MyFollowersItem>
                                <img src={ele?.photo} alt={ele?.user_name} />
                                <h4>
                                  {getSearchTerm === ""
                                    ? ele?.user_name
                                    : ele?.userName}
                                </h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}
                      {getFriendsList?.length === 0 && getSearchTerm === "" && (
                        <li>
                          <MyFollowersItemWrap>
                            <MyFollowersItem>
                              <h4 className="noRecordFound">
                                No any friends available for invitation!
                              </h4>
                            </MyFollowersItem>
                          </MyFollowersItemWrap>
                        </li>
                      )}

                      {searchFriendList?.length === 0 &&
                        getSearchTerm !== "" && (
                          <li>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <h4 className="noRecordFound">
                                  No any friends found!
                                </h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        )}
                    </ul>
                  </MyFollowersLeft>
                  <MyFollowersRight>
                    <h3>Invited</h3>
                    <ul>
                      {props?.inviteFriend?.map((ele: any) => {
                        return (
                          <li>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <img src={ele?.photo} alt={ele?.user_name} />
                                <h4>{ele?.user_name || ele?.userName}</h4>
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}

                      {props.inviteFriend?.length === 0 && (
                        <li>
                          <MyFollowersItemWrap>
                            <MyFollowersItem>
                              <h4 className="noRecordFound">
                                No any invitation found!
                              </h4>
                            </MyFollowersItem>
                          </MyFollowersItemWrap>
                        </li>
                      )}
                    </ul>
                  </MyFollowersRight>
                </MyGroupTabContentInner>

                <ModalBodyBtn>
                  <Button
                    className="btn"
                    onClick={() =>
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: false,
                        email: true,
                      })
                    }
                  >
                    ADD
                  </Button>
                  <Button
                    className="btn"
                    onClick={() => {
                      props.setInviteOthersModalShow(false);
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: false,
                        email: false,
                      });
                      invitedUserHandler();
                    }}
                  >
                    DONE
                  </Button>
                </ModalBodyBtn>
              </MyGroupTabContent>
            )}
            {/* END FRIENDS tabs content */}

            {/* EMAIL tabs content */}
            {props.openTab?.email && (
              <MyGroupTabContent>
                <MyGroupTabContentInner>
                  <MyFollowersLeft>
                    <InviteOtherSearch className="inviteEmailBox">
                      <Form>
                        <FormGroup>
                          <Form.Label>
                            Enter the person's email you want to invite
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="tripd@gmail.com"
                            value={props.getEmails}
                            onChange={(e) => {
                              emailValidator(e.target.value);
                            }}
                          />
                          <button
                            type="button"
                            className="addIconBtn"
                            onClick={(e) => {
                              getAllEmailHandler();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <circle cx="11" cy="11" r="11" fill="#fff" />
                              <path
                                d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                                transform="translate(4.143 13.845)"
                                fill="#A1A3B0"
                                stroke="#fff"
                                stroke-width="0.5"
                              />
                            </svg>
                          </button>
                        </FormGroup>
                        {props.emailValidate !== "" ? (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {props.emailValidate}
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </InviteOtherSearch>
                  </MyFollowersLeft>
                  <MyFollowersRight className="emailInvitation">
                    <h3>Already Invited</h3>
                    <ul>
                      {props?.inviteEmails?.map((ele: any, key: any) => {
                        return (
                          <li key={key}>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                <img src={ele?.photo} alt="kathy" />
                                <h4>{ele?.email}</h4>
                                &nbsp;
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}

                      {props?.inviteEmails?.length === 0 && (
                        <li>
                          <MyFollowersItemWrap>
                            <MyFollowersItem>
                              <h4 className="noRecordFound">
                                Email not invited!
                              </h4>
                            </MyFollowersItem>
                          </MyFollowersItemWrap>
                        </li>
                      )}
                    </ul>

                    <h3>Pending invitation</h3>
                    <ul>
                      {props?.pendingEmailRequest?.map((ele: any, key: any) => {
                        return (
                          <li key={key}>
                            <MyFollowersItemWrap>
                              <MyFollowersItem>
                                {ele?.photo && (
                                  <img
                                    src={
                                      ele?.photo.includes("http") ||
                                      ele?.photo.includes("https")
                                        ? ele?.photo
                                        : `${props?.getImageUrl}${ele?.photo}`
                                    }
                                    alt={ele?.firstName}
                                  />
                                )}
                                {ele?.emailPhoto && (
                                  <img src={ele?.emailPhoto} alt="email" />
                                )}
                                {ele?.userEmail && (
                                  <h4>{`${ele?.userEmail}`}</h4>
                                )}
                                {ele?.email && <h4>{`${ele?.email}`}</h4>}
                              </MyFollowersItem>
                            </MyFollowersItemWrap>
                          </li>
                        );
                      })}

                      {props?.pendingEmailRequest?.length === 0 && (
                        <li>
                          <MyFollowersItemWrap>
                            <MyFollowersItem>
                              <h4 className="noRecordFound">
                                No email invitations found!
                              </h4>
                            </MyFollowersItem>
                          </MyFollowersItemWrap>
                        </li>
                      )}
                    </ul>
                  </MyFollowersRight>
                </MyGroupTabContentInner>
                <ModalBodyBtn>
                  <Button
                    className="btn"
                    onClick={() => {
                      props.setInviteOthersModalShow(false);
                      props.setOpenTab({
                        follower: false,
                        group: false,
                        friend: false,
                        email: false,
                      });
                      invitedUserHandler();
                    }}
                  >
                    DONE
                  </Button>
                </ModalBodyBtn>
              </MyGroupTabContent>
            )}
            {/* END EMAIL tabs content */}
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChatsPlanModal;
