import { notificationAPI } from "../../../services/notification";

export enum NotificationType {
  GET_NOTIFICATION = "GET_NOTIFICATION",
  GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FALIURE = "GET_NOTIFICATION_FALIURE",
}

export const notificationInfo = () => (dispatch: any) => {
  dispatch({
    type: NotificationType.GET_NOTIFICATION,
  });
};

export const notificationInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: NotificationType.GET_NOTIFICATION_SUCCESS,
    data,
  });
};

export const notificationInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: NotificationType.GET_NOTIFICATION_FALIURE,
    data: msg,
  });
};

export const getNotificationData = () => async (dispatch: any) => {
  try {
    dispatch(notificationInfo());
    const result: any = await notificationAPI();
    dispatch(notificationInfoSuccess(result));
  } catch (e: any) {
    dispatch(notificationInfoFailed(e.response.data.message));
  }
};
