import { style } from "@mui/system";
import styled from "styled-components";

export const FooterWrap = styled.div`
  background: #000;
  padding: 25px 0;
  font-family: Poppins;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.35s ease 0s;
    cursor: pointer;
    color: #8d8d8d;
    &:hover {
      color: #fff;
    }
  }
`;

export const FooterContainer = styled.div`
  max-width: 900px;
  padding: 0 15px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  display: flex;
  @media (max-width:767px){
    flex-wrap:wrap;
  }
  @media (max-width:565px){
    flex-direction:column;
  }
`;

export const FooterLogo = styled.div`
  img {
    width: 80px;
    vertical-align: middle;
  }
`;

export const FooterLinks = styled.div`
  @media (max-width:767px){
    max-width:calc(100% - 170px);
    flex:calc(100% - 170px);
  }
  @media (max-width:565px){
    max-width:100%;
    flex:100%;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 480px;
    max-width: 100%;
    margin-left: 8%;
    @media (max-width:767px){
      width: auto;
      margin-left: 0;
      padding:0 15px;
    }
    @media (max-width:565px){
      width:100%;
      padding:15px 0;
    }
    li {
      width: 33.33%;
      padding: 0 10px;
      font-family: Poppins;
      font-size: 12px;
      line-height: 19px;
      font-weight: normal;
      margin-top: 13px;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
      @media (max-width:565px){
        padding:0 5px;
        text-align:center;
      }
      a {
        color: #8d8d8d;
        transition: all 0.35s ease 0s;
        &:hover {
          color: #fff;
        }
      }
    }
  }
`;

export const FooterSocial = styled.div`
  h4 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0 0 14px;
    @media (max-width:480px){
      text-align:center;
    }
  }
  ul {
    align-items: center;
    display: flex;
    li {
      margin-right: 15px;
      font-size: 0;
      line-height: 0;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        vertical-align: middle;
        svg {
          vertical-align: middle;
          path {
            transition: all 0.35s ease 0s;
          }
        }
        &:hover {
          path {
            fill: #6840df;
          }
        }
      }
    }
  }
`;
