import { deleteCollectionAPI } from "../../../services/deleteCollection";

export enum deleteCollection {
  DELETE_COLLECTION = "DELETE_COLLECTION",
  DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS",
  DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE",
}

export const deleteCollectionInfo = () => (dispatch: any) => {
  dispatch({
    type: deleteCollection.DELETE_COLLECTION,
  });
};

export const deleteCollectionSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: deleteCollection.DELETE_COLLECTION_SUCCESS,
    data,
  });
};

export const deleteCollectionFailure = (msg: string) => (dispatch: any) => {
  dispatch({
    type: deleteCollection.DELETE_COLLECTION_FAILURE,
    data: { msg },
  });
};

export const deleteCollectionHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(deleteCollectionInfo());
    const result: any = await deleteCollectionAPI(data);
    dispatch(deleteCollectionSuccess(result));
  } catch (e: any) {
    dispatch(deleteCollectionFailure(e?.response?.data?.message));
  }
};
