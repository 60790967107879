export enum DynamicChatType {
  DYNAMIC_CHAT_INFO = "DYNAMIC_CHAT_INFO",
  DYNAMIC_CHAT_INFO_SUCCESS = "DYNAMIC_CAETEGORY_INFO_SUCCESS",
  DYNAMIC_CHAT_INFO_FALIURE = "DYNAMIC_CATEGORY_INFO_FALIURE",
}

export const dynamicChatInfo = () => (dispatch: any) => {
  dispatch({
    type: DynamicChatType.DYNAMIC_CHAT_INFO,
  });
};

export const dynamicChatInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: DynamicChatType.DYNAMIC_CHAT_INFO_SUCCESS,
    data: data,
  });
};

export const dynamicChatInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: DynamicChatType.DYNAMIC_CHAT_INFO_FALIURE,
    data: { msg },
  });
};
