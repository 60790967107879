import { getOneCollectionType } from "../../action/getOneCollection/getOneCollection";

export const initState = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getOneCollectionType.GET_ONE_COLLECTION: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case getOneCollectionType.GET_ONE_COLLETION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case getOneCollectionType.GET_ONE_COLLECTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    case "RESET_ONE_COLLECTION": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }

    default:
      return state;
  }
};
