import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { setEmitFlags } from "typescript";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeleteUserModal from "../deleteUserModal/index";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import UserImage from "../../assets/images/user-image.png";
import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import CandyBrian from "../../assets/images/candy-brian.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import ChatAttachment from "../../assets/images/chat-attachment.png";
import GalleryImage1 from "../../assets/images/gallery-image-1.png";
import GalleryImage2 from "../../assets/images/gallery-image-2.png";
import GalleryImage3 from "../../assets/images/gallery-image-3.png";
import PageLoaderIcon from "../../assets/images/page-loader.svg";
import InviteOthersModal from "../inviteOthersModal/index";
import NoMessages from "../../assets/images/no-messages-yet.svg";
import {
  TabbingPanel,
  FormGroup,
  TabsWrap,
  TabsContent,
  ModalBody,
  ModalBodyBtn,
  PlanChats,
  PlanChatsGroups,
  ChatsTime,
  PlanChatsCounter,
  GroupsSingleChat,
  GroupsSingleChatImage,
  GroupsSingleChatInfo,
  GroupMemberItem,
  GroupChatWrap,
  GroupChatTitle,
  GroupChatItem,
  GroupChatDate,
  GroupChatHead,
  GroupChatBody,
  ChatUserName,
  ChatTime,
  ChatUserImage,
  ChatUserMessage,
  ChatBodyAttachment,
  ChatMessageAction,
  ChatMessageSendBtn,
  GroupDetailMembers,
  GroupDetailsShortby,
  GroupSaveGallery,
  GroupSaveGalleryItem,
  SearchListLoader,
  ExitButton,
  ChatNotFound,
} from "./socketPanelStyle";
import {
  getConversationData,
  getConversationInfoFailed,
} from "../../redux/action/getConversation/getConversation";
import RemoveGroupMemberModel from "../removeGroupMemberModel";
import { exitGroupInfoData } from "../../redux/action/exit-group/exitGroup";
import RejectGroupModal from "../../components/rejectGroup/index";
import { addInviteGroupApi } from "../../redux/action/add-Invite-Group/addInviteGroup";
import DeleteGroup from "../deleteGroup";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import PlansModal from "../../components/plansModal";
import AnotherDayModal from "../../components/anotherDayModal";
import CreatePlanModal from "../../components/createPlanModal/index";
import ShareWithModal from "../../components/shareWithModal/index";
import ChatsPlanModal from "../../components/chatsPlanModal/index";
import { getGroupData } from "../../redux/action/get-all-group/getAllGroups";
import { planListingData } from "../../redux/action/plan-listing/planListing";
import LasVegas from "../../assets/images/las-vegas.png";
import { mapKey } from "../../resource/constants";
import GetAllCollectionsModal from "../../components/collectionsModal";
import ShowMoreText from "react-show-more-text";
import ConcertFestivalSportsImage from "../../assets/images/concert-festival-sports.png";

// import { getLinkPreview, getPreviewFromContent } from "link-preview-js";

// let socket = io("http://localhost:8000");

const SocketPanel = (props: any) => {
  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);
  const dispatch: any = useDispatch();
  const [msg, setMsg] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const [groupDetailsInfo, setGroupDetailsInfo] = useState<any>([]);
  const [getFollow, setFollow] = useState<any>("chat");
  const [removeGroupModalShow, setRemoveGroupModalShow] =
    useState<boolean>(false);
  const [removeGroupId, setRemoveGroupId] = useState<any>("");
  //group model state
  const [inviteOthersModalShow, setInviteOthersModalShow] =
    useState<boolean>(false);
  const [createPlanModalShow, setCreatePlanModalShow] = useState(false);
  const [openTab, setOpenTab] = useState({
    group: false,
    follower: false,
    friend: false,
    email: false,
  });
  const [getPlanDetails, setPlanDetails] = useState("Private");
  const [pendingEmailRequest, setPendingEmail] = useState<any[]>([]);
  const [inviteFriend, setInviteFriend] = useState<string[]>([]);

  const [inviteFollower, setInviteFollower] = useState<string[]>([]);
  const [getAllId, setAllId] = useState<string[]>([]);
  const [inviteEmails, setInviteEmails] = useState<any[]>([]);

  const [getEmails, setEmail] = useState("");

  const [emailValidate, setEmailValidate] = useState("");
  const [getAllEmail, setAllEmail] = useState<any[]>([]);
  const [getGroupId, setGroupId] = useState("");
  const [getAllGroupMember, setAllGroupMember] = useState<any[]>([]);
  const [getAllUniqueGroupMember, setAllUniqueGroupMember] = useState<any[]>(
    []
  );
  const [iniviteGroupMember, setinviteGroupMember] = useState(true);
  const [inviteGroupMembers, setGroupMembers] = useState<string[]>([]);
  const [inivitGroup, setInviteGroup] = useState(true);
  const [getImageUrl, setImageUrl] = useState("");
  const [getUserName, setGetUserName] = useState("");
  const [getGroupName, setGetGroupName] = useState("");
  const [rejectModalShow, setRejectModalShow] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [open, setOpen] = useState(true);
  const [images, setImages] = useState<any[]>([]);

  const [rejectId, setRejectId] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const [shareWithModalShow, setShareWithModalShow] = useState(false);
  const [socketDataName, setSocketDataName] = useState("");
  const [socketDataAddress, setSocketDataAddress] = useState("");
  const [socketDataLink, setSocketDataLink] = useState("");
  const [socketDataImage, setSocketDataImage] = useState("");
  const [openChatModel, setOpenChatModel] = useState(false);
  const [placeIdData, setPlaceIdData] = useState("");
  const [groupImages, setGroupImages] = useState("");
  const [dynamicLocation, setDynamicLocation] = useState("");
  const [inviteOtherModal, setInviteOtherModal] = useState(false);

  const [getRentalDetails, setRentalDetails] = useState({
    venue_id: "",
    name: "",
    location: "",
    province: "",
    Image: "",
    venue_type: "",
  });
  const [getCollectionModal, setCollectionModal] = useState(false);
  const [imageValidation, setImageValidation] = useState("");
  const [getImage, setImage] = useState("");
  const [showimage, setShowImage] = useState("");

  const navigate: any = useNavigate();

  const removeUser = useSelector((state: any) => state?.deleteGroupMember);

  const exitGroup = useSelector((state: any) => state?.exitGroup);
  const removeGroup = (item: any) => {
    const memberID = groupDetailsInfo[0]?.members?.findIndex((item: any) => {
      return item._id === localStorage.getItem("id");
    });

    const username: any = localStorage.getItem("userName");
    if (localStorage.getItem("id") === groupDetailsInfo[0]?.user_id) {
      setRemoveGroupModalShow(true);
      setRemoveGroupId(item._id);
      setGetUserName(item?.userName);
      setGetGroupName(groupDetailsInfo[0]?.name);
    } else if (memberID !== -1) {
      setRemoveGroupModalShow(true);
      setRemoveGroupId(item);
      setGetUserName(username);
      setGetGroupName(groupDetailsInfo[0]?.name);
    }
  };

  const exitGroupHandler = () => {
    setDeleteGroupId(props.id);
    setRejectId(props.id);
    setRejectModalShow(true);
    setDeleteGroup(true);
  };

  const local: any = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    dispatch(getConversationData({ id: props?.id }));
    const room = props?.id;
    const username = local.user_name;
    const user_id = local.id;
    const group_id = props?.id;
    const id = props?.id;

    props?.socket.emit("joinPlan", { username, room, user_id });
    props?.socket?.emit("getOneGroup", { group_id });
    props?.socket?.emit("GroupSave", id);
    props?.socket?.on("GroupSaveImages", (data: any) => {
      setImages(data?.group_images);
    });
    props?.socket?.on("getOneMembers", (data: any) => {
      setGroupDetailsInfo(data);
    });
  }, [props, removeUser]);

  const lastMessageRef = React.useRef<HTMLDivElement>(null);

  const groupConversation: any = useSelector(
    (state: any) => state?.getConversation
  );

  useEffect(() => {
    // let arr: any = [];
    // setMessages([...messages, ...arr]);

    if (groupConversation?.data?.data) {
      setMessages(groupConversation?.data?.data);
    }
  }, [groupConversation]);

  useEffect(() => {
    props?.socket.on("message", (message: any) => {
      setMessages((messages) => [...messages, message]);
      const id = props?.id;
      props?.socket?.emit("GroupSave", id);
      props?.socket?.on("GroupSaveImages", (data: any) => {
        setImages(data?.group_images);
      });
    });
  }, []);

  // use ref for autoscroll
  useEffect(() => {
    if (props?.countProps > 0 && messages.length > 0 && open) {
      var ul: any = document.getElementById("msgul");
      var li: any = document.getElementById(
        `msg${messages.length - props?.countProps - 3}`
      );
      window.scrollTo(0, (ul.scrollTop = li?.offsetTop));
    } else {
      if (messages.length > 0) {
        var ul1: any = document.getElementById("msgul");
        var li1: any = document.getElementById(`msg${messages.length - 1}`);
        if (ul1) {
          window.scrollTo(0, (ul1.scrollTop = li1?.offsetTop));
        }
      }
    }
  }, [messages, props, getFollow]);

  const goBack = () => {
    const user_id = local.id;
    const id = props?.id;
    props?.form(props?.activeTab);
    props?.socket.emit("LeaveGroup", id, user_id);
  };

  //  useEffect clean up function

  useEffect(() => {
    return () => {
      goBack();
    };
  }, []);

  const submit = (event: any) => {
    event.preventDefault();
    if (msg.trim() !== "") {
      const finaldata = {
        username: local.user_name,
        sender_id: local.id,
        msg: msg.trim(),
        id: props?.id,
        photo: local.photo,
        image: "",
      };
      setOpen(false);
      props?.socket.emit("chatMessage", finaldata);

      setMsg("");
      event.target.value = "";
    }
  };

  const DateSort = (index: any) => {
    for (var i = 0; i < messages.length; i++) {
      if (index === i) {
        if (i > 0) {
          if (
            moment(messages[i - 1].createdAt).format("YYYY-DD-MM") !==
            moment(messages[i].createdAt).format("YYYY-DD-MM")
          ) {
            return moment(messages[i].createdAt).format("D MMM");
          }
        } else {
          return moment(messages[i].createdAt).format("D MMM");
        }
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (msg.trim() !== "") {
      if (event.key === "Enter") {
        const finaldata = {
          username: local.user_name,
          msg: msg.trim(),
          id: props?.id,
          photo: local.photo,
          sender_id: local.id,
          image:
            "" ??
            "https://www.expedia.com/go/hotel/search/pinned/64578058/0/0?tpid=1&NumAdult1=2&chid=a910d2d3-8f29-4a04-b33d-18f141c8c92c&eapid=310116&NumRoom=1&currency=USD&langid=1033",
        };
        setOpen(false);
        props?.socket.emit("chatMessage", finaldata);
        setMsg("");
        event.target.value = "";
      }
    }
  };

  const navigateToChat = () => {
    setShowDetails(true);
    setFollow("chat");
  };

  useEffect(() => {
    if (inviteOthersModalShow === false) {
    }
  }, [inviteOthersModalShow]);

  const AdminId = groupDetailsInfo[0]?.members?.filter(
    (items: any) => items._id === groupDetailsInfo[0].user_id
  );
  const GroupUserId = groupDetailsInfo[0]?.members?.filter(
    (items: any) => items._id !== groupDetailsInfo[0].user_id
  );

  const navigateToDynamicLocation = (item: any) => {
    // if (item?.location) {
    navigate(`${item.location}/${item.id}`);
    // } else {
    // window.open(item?.image, "_blank");
    // }
  };

  //add to plans start

  const [plansModal, setPlansModal] = useState(false);
  const [interestId, setInterestId] = useState("");
  const [timeDayModal, setTimeDayModal] = useState(false);
  const [interestData, setInterestData] = useState("");

  const [finalData, setFinalData] = useState<any[]>([]);
  const [planId, setPlanId] = useState("");
  const [venueName, setVenueName] = useState("");

  const planData = (e: any) => {
    setFinalData(e.days);
    setPlanId(e._id);
    setTimeDayModal(true);
  };

  //add to plans end

  //create plans start

  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [createData, setCreateData] = useState("");
  const createPlan = useSelector((state: any) => state?.createPlan);
  useEffect(() => {
    if (createData !== "" && createPlan?.data) {
      setFinalData(createPlan?.data?.data[0]?.days);
      setPlanId(createPlan?.data?.data[0]?._id);
      setTimeDayModal(true);
      setCreateData("");
      dispatch({ type: "CREATE_PLAN_RESET" });
    }
  }, [createPlan]);

  //create plans end
  const navigateToShareWith = (val: any) => {
    if (val.location === "/foodanddrinks") {
      setSocketDataName(val?.item?.name);
      setSocketDataAddress(val?.item?.vicinity);
      setSocketDataLink(val?.item?.Links?.WebSearchResult?.Href);
      setSocketDataImage(val?.image);
    } else if (val.location === "/rentalsresults") {
      setSocketDataName(val?.item?.Name);
      setSocketDataAddress(
        `${val?.item?.Location?.Address?.City} ${val?.item?.Location?.Address?.Country}`
      );
      setSocketDataImage(val?.image);
      setSocketDataLink(val?.item?.Links?.WebSearchResult?.Href);
    } else if ((val.location = "/hotelsresult")) {
      setSocketDataName(val?.item?.Name);
      setSocketDataAddress(
        `${val?.item?.Location?.Address?.City} ${val?.item?.Location?.Address?.Country}`
      );
      setSocketDataImage(val?.image);
      setSocketDataLink(val?.item?.Links?.WebSearchResult?.Href);
    }
    setShareWithModalShow(true);
  };

  const groupPlanDetails = () => {
    dispatch(getGroupData({ search: "" }));
    dispatch(planListingData());
  };

  const shareChatImages = (item: any) => {
    setGroupImages(item);
    setDynamicLocation("/interestresults");
  };

  const [allItemsData, setAllItemsData] = useState("");

  const group_data: any = useSelector((state: any) => state.createAllGroup);

  useEffect(() => {
    group_data?.data?.data[0]?.GroupList?.map((list: any, index: any) => {
      index === 0 && setGroupId(list?._id);
      list?.members?.map((ele: any) => {
        setAllGroupMember((prev: any) => [...prev, ele]);
      });
    });
    setImageUrl(group_data?.data?.data[0]?.link);
  }, [group_data]);

  useEffect(() => {
    const unqiueMembers = [
      ...getAllGroupMember
        .reduce((a, c) => {
          a.set(c._id, c);
          return a;
        }, new Map())
        .values(),
    ];

    setAllUniqueGroupMember(unqiueMembers);
  }, [getAllGroupMember]);

  const navigateToGroupImage = (val: any) => {
    setOpenChatModel(true);
    if (
      val.location === "/rentalsresults" ||
      val.location === "/hotelsresult"
    ) {
      setGroupImages(val?.image);
      setPlaceIdData(val?.groupId);
      setDynamicLocation(val?.location);
      setAllItemsData(val?.item);
    } else if (
      val.location === "/interestresults" ||
      val.location === "/hiking" ||
      val.location === "/foodanddrinks"
    ) {
      setGroupImages(val?.item);
      setPlaceIdData(val?.groupId);
      setDynamicLocation(val?.location);
      setAllItemsData(val?.item);
    } else if (
      val.location === "/festivalsresults" ||
      val.location === "/sportsresults" ||
      val.location === "/concertsresults"
    ) {
      setGroupImages(val?.image);
      setPlaceIdData(val?.groupId);
      setDynamicLocation(val?.location);
      setAllItemsData(val?.item);
    }
  };

  useEffect(() => {
    if (getFollow === "groupSave") {
      const id = props?.id;
      props?.socket?.emit("GroupSave", id);
      props?.socket?.on("GroupSaveImages", (data: any) => {
        setImages(data?.group_images);
      });
    }
  }, [getFollow]);

  const [visible, setVisible] = useState(true);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  useEffect(() => {
    setInviteEmails([]);
    if (groupDetailsInfo[0]?.members?.length > 0) {
      groupDetailsInfo[0]?.members?.map((element: any) => {
        element?._id !== localStorage.getItem("id") &&
          setInviteEmails((prev: any) => [
            ...prev,
            { email: element?.email, photo: element?.photo },
          ]);
      });
    } else if (groupDetailsInfo[0]?.members?.length === 0) {
      setInviteEmails([]);
    }
  }, [groupDetailsInfo[0]?.members]);

  useEffect(() => {
    setPendingEmail([]);
    if (
      groupDetailsInfo[0]?.invite_emails?.length > 0 ||
      groupDetailsInfo[0]?.pendingList?.length > 0
    ) {
      const allInvitedEmails = [
        ...groupDetailsInfo[0]?.invite_emails,
        ...groupDetailsInfo[0]?.pendingList,
      ];

      allInvitedEmails?.map((element: any) => {
        setPendingEmail((prev: any) => [
          ...prev,
          { email: element, photo: "" },
        ]);
      });
    } else if (
      groupDetailsInfo[0]?.invite_emails?.length === 0 &&
      groupDetailsInfo[0]?.pendingList?.length === 0
    ) {
      setPendingEmail([]);
    }
  }, [groupDetailsInfo[0]?.invite_emails, groupDetailsInfo[0]?.pendingList]);

  const navigateToProfile = (item: any) => {
    navigate(`/myProfile/${item?._id}`);
  };

  const navigateToSocialProfile = (item: any) => {
    navigate(`/socialprofile/${item?._id}`);
  };

  const executeOnClick = (isExpanded: any) => {
    console.log(isExpanded);
  };

  return (
    <>
      <TabsWrap className="GroupDetailsTab">
        <Tabs
          // defaultActiveKey="groupDetails"
          activeKey={getFollow}
          onSelect={(eventkey: any) => {
            setFollow(eventkey);
            setShowDetails(false);
          }}
        >
          <Tab
            eventKey="groupDetails"
            title="Group Details"
            onClick={() => setInviteOtherModal(true)}
          >
            <TabsContent>
              <GroupChatWrap className="groupDetailList">
                <GroupChatTitle>
                  <Button
                    type="button"
                    className="btn back-btn"
                    onClick={navigateToChat}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.535"
                      height="14.949"
                      viewBox="0 0 8.535 14.949"
                    >
                      <g transform="translate(1.061 0.53)">
                        <path
                          d="M1432,3924l-6.944,6.944,6.944,6.944"
                          transform="translate(-1425.056 -3924)"
                          fill="none"
                          stroke="#9597a6"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </Button>
                  <span className="group-detail-title">
                    {groupDetailsInfo[0]?.name}
                  </span>
                  <span>{groupDetailsInfo[0]?.description}</span>

                  {groupDetailsInfo[0]?.user_id ===
                    localStorage.getItem("id") && (
                    <Button
                      className="btn addFriendsBtn"
                      onClick={() => {
                        setInviteOthersModalShow(true);
                        setOpenTab({
                          follower: false,
                          group: true,
                          friend: false,
                          email: false,
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                      >
                        <defs>
                          <clipPath>
                            <rect width="21.911" height="21.731" fill="#fff" />
                          </clipPath>
                        </defs>
                        <circle cx="24" cy="24" r="24" fill="#4d27da" />
                        <g transform="translate(13.045 13.134)">
                          <g clip-path="url(#clip-path)">
                            <path
                              d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                              transform="translate(-219.503 -75.638)"
                              fill="#fff"
                            />
                            <path
                              d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                              transform="translate(-19.846 -182.45)"
                              fill="#fff"
                            />
                            <path
                              d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                  )}
                </GroupChatTitle>

                <GroupDetailMembers>
                  {AdminId?.isLoading && (
                    <SearchListLoader>
                      <img src={PageLoaderIcon} alt="Loading..." />
                    </SearchListLoader>
                  )}

                  {AdminId?.map((item: any) => {
                    return (
                      <>
                        <GroupMemberItem>
                          <img
                            src={item?.photo}
                            alt={item?.userName}
                            onClick={() => navigateToProfile(item)}
                          />
                          <h4 onClick={() => navigateToProfile(item)}>
                            {item?.userName}
                          </h4>
                          <Button className="btn creator-btn">Creator</Button>
                        </GroupMemberItem>
                      </>
                    );
                  })}
                  {GroupUserId?.map((item: any) => {
                    return (
                      <>
                        <GroupMemberItem>
                          <img src={item?.photo} alt={item?.userName} />
                          <h4 onClick={() => navigateToSocialProfile(item)}>
                            {item?.userName}
                          </h4>
                          {groupDetailsInfo[0]?.user_id ===
                            localStorage.getItem("id") && (
                            <Tippy
                              content="Remove"
                              visible={visible}
                              onClickOutside={hide}
                            >
                              <Button
                                className="btn"
                                onClick={() => {
                                  removeGroup(item);
                                  visible ? hide() : show();
                                }}
                                onMouseEnter={show}
                                onMouseLeave={hide}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g
                                    fill="#fff"
                                    stroke="#707070"
                                    stroke-width="1"
                                  >
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="9.5"
                                      fill="none"
                                    />
                                  </g>
                                  <path
                                    d="M7700.14-13803.409h8.662"
                                    transform="translate(-7694.471 13813.409)"
                                    fill="none"
                                    stroke="#707070"
                                    stroke-width="1"
                                  />
                                </svg>
                              </Button>
                            </Tippy>
                          )}
                        </GroupMemberItem>
                      </>
                    );
                  })}
                </GroupDetailMembers>

                <ExitButton>
                  {groupDetailsInfo[0]?.user_id !==
                  localStorage.getItem("id") ? (
                    <Button
                      type="submit"
                      onClick={() => removeGroup(localStorage.getItem("id"))}
                      className="btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ef3939"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      Exit Group
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      onClick={exitGroupHandler}
                      className="btn"
                    >
                      Delete Group
                    </Button>
                  )}
                </ExitButton>
              </GroupChatWrap>
            </TabsContent>
          </Tab>

          <Tab eventKey="groupSave" title="Group Save">
            <TabsContent>
              <GroupChatTitle className="groupSaveTitle">
                <Button
                  type="button"
                  className="btn back-btn"
                  onClick={navigateToChat}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.535"
                    height="14.949"
                    viewBox="0 0 8.535 14.949"
                  >
                    <g transform="translate(1.061 0.53)">
                      <path
                        d="M1432,3924l-6.944,6.944,6.944,6.944"
                        transform="translate(-1425.056 -3924)"
                        fill="none"
                        stroke="#9597a6"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </Button>
                {/* Trip to Vegas Group save */}
                <span className="group-detail-title">
                  {groupDetailsInfo[0]?.name}
                </span>
              </GroupChatTitle>

              <GroupSaveGallery>
                {images?.map((item: any) => {
                  return (
                    <>
                      <li>
                        <GroupSaveGalleryItem>
                          <img
                            src={
                              item.image === "" || item.image === null
                                ? ConcertFestivalSportsImage
                                : item.image
                            }
                            alt="gallery-image"
                            onClick={() => navigateToDynamicLocation(item)}
                          />
                        </GroupSaveGalleryItem>
                      </li>
                    </>
                  );
                })}
              </GroupSaveGallery>
            </TabsContent>
          </Tab>
        </Tabs>
      </TabsWrap>

      {showDetails && (
        <TabsWrap className="GroupDetailsTab">
          <TabsContent>
            <GroupChatWrap>
              <GroupChatTitle>
                <button
                  type="button"
                  className="btn back-btn"
                  onClick={() => {
                    goBack();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.535"
                    height="14.949"
                    viewBox="0 0 8.535 14.949"
                  >
                    <g transform="translate(1.061 0.53)">
                      <path
                        d="M1432,3924l-6.944,6.944,6.944,6.944"
                        transform="translate(-1425.056 -3924)"
                        fill="none"
                        stroke="#9597a6"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </button>
                {props?.name}
              </GroupChatTitle>

              <ul className="chatMessageList" id="msgul">
                {messages?.length === 0 ? (
                  <ChatNotFound>
                    <img src={NoMessages} alt="no-messages-yet" />
                    <h3>No messages yet!</h3>
                  </ChatNotFound>
                ) : (
                  <>
                    {messages.map((val: any, i: any) => (
                      <>
                        {props?.countProps ? (
                          messages.length - props?.countProps === i && open ? (
                            <GroupChatDate>
                              <span>{props?.countProps} Unread Messages</span>
                            </GroupChatDate>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {DateSort(i) === undefined ? (
                          ""
                        ) : (
                          <li id={`msg${i}`}>
                            <GroupChatDate>
                              <span>
                                {DateSort(i) === moment().format("D MMM")
                                  ? "Today"
                                  : DateSort(i) ===
                                    moment(moment().subtract(1, "days")).format(
                                      "D MMM"
                                    )
                                  ? "Yesterday"
                                  : DateSort(i)}
                              </span>
                            </GroupChatDate>
                          </li>
                        )}
                        <li id={`msg${i}`}>
                          <GroupChatItem
                            className={
                              local.user_name == val.username
                                ? "userSendChat"
                                : ""
                            }
                          >
                            <GroupChatHead>
                              <ChatUserName>
                                {local.user_name == val.username
                                  ? "Me"
                                  : val.username}
                              </ChatUserName>
                              <ChatTime>
                                {moment(val.createdAt).format("LT")}
                              </ChatTime>
                            </GroupChatHead>

                            <GroupChatBody>
                              <ChatUserImage
                                onClick={() => {
                                  if (val.sender_id === local.id) {
                                    navigate("/myprofile");
                                  } else {
                                    navigate(`/socialprofile/${val.sender_id}`);
                                  }
                                }}
                              >
                                <img src={val.photo} alt={val.username} />
                              </ChatUserImage>
                              {val.message === undefined ? (
                                <>
                                  <ChatUserMessage>
                                    <p>
                                      <Link
                                        to={`${val.location}/${val.groupId}`}
                                        // onClick={() => chatInnerImage(val)}

                                        className="msgLink"
                                      >
                                        {val.name}
                                      </Link>
                                    </p>

                                    <ChatBodyAttachment>
                                      <Link
                                        to={`${val.location}/${val.groupId}`}
                                      >
                                        <img
                                          src={
                                            val.image === "" ||
                                            val.image === null
                                              ? LasVegas
                                              : val.image
                                          }
                                          alt={val.username}
                                        />
                                      </Link>
                                      <Dropdown>
                                        <Dropdown.Toggle>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                          >
                                            <circle
                                              cx="8"
                                              cy="8"
                                              r="8"
                                              fill="#fff"
                                            />
                                            <path
                                              d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                                              transform="translate(2.459 16.62)"
                                              fill="#3b3b3b"
                                            />
                                          </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              navigateToGroupImage(val);
                                              groupPlanDetails();
                                              // shareChatImages(val);
                                              // setPlaceIdData(val?.place_id);
                                            }}
                                          >
                                            Share to Chat
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              setPlansModal(true);
                                              if (
                                                val?.location ===
                                                "/foodanddrinks"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id
                                                );
                                                setVenueName("food and drink");
                                              } else if (
                                                val?.location ===
                                                "/concertsresults"
                                              ) {
                                                setInterestId(val?.item?._id);
                                                setVenueName("concerts");
                                              } else if (
                                                val?.location ===
                                                "/festivalsresults"
                                              ) {
                                                setInterestId(val?.item?._id);
                                                setVenueName("festival");
                                              } else if (
                                                val?.location ===
                                                "/sportsresults"
                                              ) {
                                                setInterestId(val?.item?._id);
                                                setVenueName("sports");
                                              } else if (
                                                val?.location ===
                                                "/rentalsresults"
                                              ) {
                                                setInterestId(val?.item?._id);
                                                setVenueName("rental");
                                              } else if (
                                                val?.location ===
                                                "/hotelsresult"
                                              ) {
                                                setInterestId(val?.item?._id);
                                                setVenueName("hotel");
                                              } else if (
                                                val?.location ===
                                                "/interestresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id
                                                );
                                                setVenueName(
                                                  "point of interest"
                                                );
                                              } else if (
                                                val?.location === "/hiking"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id
                                                );
                                                setVenueName("hiking");
                                              }
                                              setInterestData(val.item);
                                            }}
                                          >
                                            Add to Plans
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              if (
                                                val?.location ===
                                                "/foodanddrinks"
                                              ) {
                                                const location: any =
                                                  val?.item?.plus_code?.compound_code.split(
                                                    " "
                                                  );

                                                setRentalDetails({
                                                  venue_id: val?.item?.place_id,
                                                  name: val?.item?.name,
                                                  location:
                                                    location.length > 0
                                                      ? location[1].slice(0, -1)
                                                      : "",
                                                  province:
                                                    location.length > 0
                                                      ? location[2].slice(0, -1)
                                                      : "",
                                                  Image: val?.item?.photos
                                                    ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${val?.item?.photos[0]?.photo_reference}&key=${mapKey}`
                                                    : "",
                                                  venue_type: "food and drink",
                                                });
                                                setVenueName("food and drink");
                                              } else if (
                                                val?.location ===
                                                "/concertsresults"
                                              ) {
                                                setRentalDetails({
                                                  venue_id: val?.groupId,
                                                  name: val?.name,
                                                  location: "",
                                                  province: "",
                                                  Image: val?.image,
                                                  venue_type: "concerts",
                                                });
                                                setVenueName("concerts");
                                              } else if (
                                                val?.location ===
                                                "/festivalsresults"
                                              ) {
                                                setRentalDetails({
                                                  venue_id: val?.groupId,
                                                  name: val?.name,
                                                  location: "",
                                                  province: "",
                                                  Image: val?.image,
                                                  venue_type: "festival",
                                                });
                                                setVenueName("festival");
                                              } else if (
                                                val?.location ===
                                                "/sportsresults"
                                              ) {
                                                setRentalDetails({
                                                  venue_id: val?.groupId,
                                                  name: val?.name,
                                                  location: "",
                                                  province: "",
                                                  Image: val?.image,
                                                  venue_type: "sports",
                                                });
                                                setVenueName("sports");
                                              } else if (
                                                val?.location ===
                                                "/rentalsresults"
                                              ) {
                                                setRentalDetails({
                                                  venue_id: val?.groupId,
                                                  name: val?.name,
                                                  location: "",
                                                  province: "",
                                                  Image: val?.image,
                                                  venue_type: "rental",
                                                });
                                                setVenueName("rental");
                                              } else if (
                                                val?.location ===
                                                "/hotelsresult"
                                              ) {
                                                setRentalDetails({
                                                  venue_id: val?.groupId,
                                                  name: val?.name,
                                                  location: "",
                                                  province: "",
                                                  Image: val?.image,
                                                  venue_type: "hotel",
                                                });
                                                setVenueName("hotel");
                                              } else if (
                                                val?.location ===
                                                "/interestresults"
                                              ) {
                                                const location: any =
                                                  val?.item?.plus_code?.compound_code.split(
                                                    " "
                                                  );

                                                setRentalDetails({
                                                  venue_id: val?.item?.place_id,
                                                  name: val?.item?.name,
                                                  location:
                                                    location.length > 0
                                                      ? location[1].slice(0, -1)
                                                      : "",
                                                  province:
                                                    location.length > 0
                                                      ? location[2].slice(0, -1)
                                                      : "",
                                                  Image: val?.item?.photos
                                                    ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${val?.item?.photos[0]?.photo_reference}&key=${mapKey}`
                                                    : "",
                                                  venue_type:
                                                    "point of interest",
                                                });
                                                setVenueName(
                                                  "point of interest"
                                                );
                                              } else if (
                                                val?.location === "/hiking"
                                              ) {
                                                const location: any =
                                                  val?.item?.plus_code?.compound_code.split(
                                                    " "
                                                  );

                                                setRentalDetails({
                                                  venue_id: val?.item?.place_id,
                                                  name: val?.item?.name,
                                                  location:
                                                    location.length > 0
                                                      ? location[1].slice(0, -1)
                                                      : "",
                                                  province:
                                                    location.length > 0
                                                      ? location[2].slice(0, -1)
                                                      : "",
                                                  Image: val?.item?.photos
                                                    ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${val?.item?.photos[0]?.photo_reference}&key=${mapKey}`
                                                    : "",
                                                  venue_type: "hiking",
                                                });
                                                setVenueName("hiking");
                                              }

                                              setCollectionModal(true);
                                            }}
                                          >
                                            Add to collection
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              setCreatePlanModalShow(true);
                                              setCreateData(val?.item);
                                              if (
                                                val?.location ===
                                                "/foodanddrinks"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("food and drink");
                                              } else if (
                                                val?.location ===
                                                "/concertsresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?._id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("concerts");
                                              } else if (
                                                val?.location ===
                                                "/festivalsresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?._id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("festival");
                                              } else if (
                                                val?.location ===
                                                "/sportsresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?._id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("sports");
                                              } else if (
                                                val?.location ===
                                                "/rentalsresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?._id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("rental");
                                              } else if (
                                                val?.location ===
                                                "/hotelsresult"
                                              ) {
                                                setInterestId(
                                                  val?.item?._id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("hotel");
                                              } else if (
                                                val?.location ===
                                                "/interestresults"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName(
                                                  "point of interest"
                                                );
                                              } else if (
                                                val?.location === "/hiking"
                                              ) {
                                                setInterestId(
                                                  val?.item?.place_id ??
                                                    val?.item?.venue_id
                                                );
                                                setVenueName("hiking");
                                              }
                                              setInterestData(val?.item);
                                              setAllId([]);
                                              setInviteFriend([]);
                                              setInviteFollower([]);
                                              setInviteEmails([]);
                                              setGroupMembers([]);
                                              setPendingEmail([]);
                                              setInviteOtherModal(false);
                                              setShowImage("");
                                              setImage("");
                                              setImageValidation("");
                                            }}
                                          >
                                            Create Plans
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() =>
                                              navigateToShareWith(val)
                                            }
                                          >
                                            Share With
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </ChatBodyAttachment>
                                  </ChatUserMessage>
                                </>
                              ) : (
                                <>
                                  {val?.message.substring(0, 5) === "https" ||
                                  val.message.substring(0, 4) === "http" ? (
                                    <ChatUserMessage>
                                      <a href={val.message} target="_blank">
                                        <ShowMoreText
                                          lines={3}
                                          more="Show more"
                                          less="Show less"
                                          className="content-css"
                                          anchorClass="show-more-less-clickable"
                                          onClick={executeOnClick}
                                          expanded={false}
                                          width={500}
                                          truncatedEndingComponent={"... "}
                                        >
                                          {val?.message}
                                        </ShowMoreText>
                                      </a>
                                    </ChatUserMessage>
                                  ) : (
                                    <>
                                      {/* <ChatUserMessage>
                                        {val.message}
                                      </ChatUserMessage> */}

                                      <ChatUserMessage>
                                        <ShowMoreText
                                          lines={3}
                                          more="Show more"
                                          less="Show less"
                                          className="content-css"
                                          anchorClass="show-more-less-clickable"
                                          onClick={executeOnClick}
                                          expanded={false}
                                          width={500}
                                          truncatedEndingComponent={"... "}
                                        >
                                          <label>{val?.message}</label>
                                        </ShowMoreText>
                                      </ChatUserMessage>
                                    </>
                                  )}
                                </>
                              )}
                            </GroupChatBody>
                          </GroupChatItem>
                        </li>
                      </>
                    ))}
                  </>
                )}

                <div ref={lastMessageRef} />
              </ul>

              <ChatMessageAction>
                <Form>
                  <FormGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Type here..."
                      value={msg.trimStart()}
                      onChange={(e) => {
                        setMsg(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </FormGroup>
                  <ChatMessageSendBtn>
                    <button type="button" className="btn" onClick={submit}>
                      SEND
                    </button>
                  </ChatMessageSendBtn>
                </Form>
              </ChatMessageAction>
            </GroupChatWrap>
          </TabsContent>
        </TabsWrap>
      )}

      <RemoveGroupMemberModel
        show={removeGroupModalShow}
        onHide={() => setRemoveGroupModalShow(false)}
        removeId={removeGroupId}
        groupId={props?.id}
        getUserName={getUserName}
        getGroupName={getGroupName}
      />
      {/* <RejectGroupModal
        show={rejectModalShow}
        onHide={() => setRejectModalShow(false)}
        setUnfollowModalShow={setRejectModalShow}
        rejectId={rejectId}
        setFollow={props.setFollow}
        // userName = {}
        // planName = {}
      /> */}

      <DeleteGroup
        show={deleteGroup}
        onHide={() => setDeleteGroup(false)}
        setUnfollowModalShow={setDeleteGroup}
        deleteGroupId={deleteGroupId}
        setFollow={props.setFollow}
      />

      <ShareWithModal
        show={shareWithModalShow}
        onHide={() => setShareWithModalShow(false)}
        name={socketDataName}
        address={socketDataAddress}
        link={socketDataLink}
        sharewithImage={socketDataImage}
        // sharewithImage={sharewithImage}
        // link={festivalLink}
        // name={festivalName}
        // address={addressInfo}
      />

      <ChatsPlanModal
        show={openChatModel}
        onHide={() => setOpenChatModel(false)}
        groupImages={groupImages}
        placeIdData={placeIdData}
        dynamicLocation={dynamicLocation}
        allItems={allItemsData}
      />

      <InviteOthersModal
        show={inviteOthersModalShow}
        onHide={() => setInviteOthersModalShow(false)}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setOpenTab={setOpenTab}
        openTab={openTab}
        getPlanDetails={getPlanDetails}
        setInviteFriend={setInviteFriend}
        inviteFriend={inviteFriend}
        setInviteFollower={setInviteFollower}
        inviteFollower={inviteFollower}
        setAllId={setAllId}
        getAllId={getAllId}
        planId={[]}
        groupId={groupDetailsInfo[0]?._id}
        setInviteEmails={setInviteEmails}
        inviteEmails={inviteEmails}
        setEmail={setEmail}
        getEmails={getEmails}
        setEmailValidate={setEmailValidate}
        emailValidate={emailValidate}
        setAllEmail={setAllEmail}
        getAllEmail={getAllEmail}
        setPendingEmail={setPendingEmail}
        pendingEmailRequest={pendingEmailRequest}
        setGroupId={setGroupId}
        getGroupId={getGroupId}
        setGroupMembers={setGroupMembers}
        getAllUniqueGroupMember={getAllUniqueGroupMember}
        pending_invite={groupDetailsInfo[0]?.pending_invite}
        getImageUrl={getImageUrl}
        inviteGroupMembers={inviteGroupMembers}
        inivitGroup={inivitGroup}
        setInviteGroup={setInviteGroup}
        propsId={props?.id}
      />

      <GetAllCollectionsModal
        show={getCollectionModal}
        onHide={() => setCollectionModal(false)}
        getRentalDetails={getRentalDetails}
        setCollectionModal={setCollectionModal}
      />

      <CreatePlanModal
        show={createPlanModalShow}
        onHide={() => {
          setCreatePlanModalShow(false);
          setAllId([]);
          setInviteFriend([]);
          setInviteFollower([]);
          setInviteEmails([]);
        }}
        setCreatePlanModalShow={setCreatePlanModalShow}
        setCreateNewModalShow={setCreateNewModalShow}
        setInviteOthersModalShow={setInviteOthersModalShow}
        setPlanDetails={setPlanDetails}
        setOpenTab={setOpenTab}
        getPlanDetails={getPlanDetails}
        getAllId={getAllId}
        inviteEmails={inviteEmails}
        setInviteEmails={setInviteEmails}
        setEmailValidate={setEmailValidate}
        setEmail={setEmail}
        getAllEmail={getAllEmail}
        pendingEmailRequest={pendingEmailRequest}
        getImageUrl={getImageUrl}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        getImage={getImage}
        setImage={setImage}
        showimage={showimage}
        setShowImage={setShowImage}
      />

      <AnotherDayModal
        show={timeDayModal}
        onHide={() => {
          setTimeDayModal(false);
          setPlansModal(false);
        }}
        DayData={finalData}
        PlanId={planId}
        VenueId={interestId}
        finalEvent={venueName}
        interestData={interestData}
      />

      <PlansModal
        show={plansModal}
        onHide={() => setPlansModal(false)}
        addPlanData={planData}
      />
    </>
  );
};

export default SocketPanel;
