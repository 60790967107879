import React, { useState } from "react";
import Header from "../../../components/Header";
import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import LeftSearchPanel from "../../../components/leftSearchPanel/index";
import RightTabbingPanel from "../../../components/rightTabbingPanel/index";
import MainSearchPanel from "../../../components/mainSearchPanel/index";

import CreateNewModal from "../../../components/createNewModal/index";
import ImageGalleryModal from "../../../components/imageGalleryModal/index";

import { GlobalDataType } from "../../../redux/action/globalData";

import JasonPamel from "../../../assets/images/jason-pamel.png";
import SandraJoseph from "../../../assets/images/sandra-joseph.png";
import PeterJohn from "../../../assets/images/peter-john.png";
import CandyBrian from "../../../assets/images/candy-brian.png";
import Kathy from "../../../assets/images/kathy.png";
import James from "../../../assets/images/james.png";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";
import SportsCompanyImage from "../../../assets/images/sports-detail-logo.jpg";
import SportsEventMain from "../../../assets/images/sports-event-main.jpg";
import SportsEventThumbOne from "../../../assets/images/sports-event-thumb1.jpg";
import SportsEventThumbTwo from "../../../assets/images/sports-event-thumb2.jpg";
import SportsEventThumbThree from "../../../assets/images/sports-event-thumb3.jpg";

import {
  MyPageWrap,
  MainContentPanel,
  FormGroup,
  HomeListingWrap,
  HomeListingTags,
  CreateBtn,
  CreateBtnInner,
  ModalBody,
  ModalBodyBtn,
  FriendItem,
  CreatePlanDate,
  ButtonTabs,
  TabButton,
  InviteOtherSearch,
  MyGroupTabContent,
  MyGroupTabContentInner,
  InviteMyGroups,
  InviteGroupMembers,
  InviteGroupRadioBox,
  InviteGroupItem,
  InviteGroupMemberItem,
  InviteGroupCheckBox,
  GroupMemberItem,
  MyFollowersLeft,
  MyFollowersRight,
  MyFollowersItemWrap,
  MyFollowersItem,
  SportsDetails,
  SportsDetailsTop,
  SportsLogo,
  SportsLogoInner,
  SportsGallery,
  SportsGalleryTop,
  SportsGalleryBottom,
  SportsGalleryItem,
  SportsTitleWrap,
  SportsTitle,
  SportsInfo,
  SportsInfoWrap,
  SportsReview,
  SportsRating,
  SportsPrice,
  SportsTeamDesc,
  RentalsFeature,
  ImageGalleryWrap,
} from "../sportsResultsDetail/sportsResultsDetailStyle";

import CustomInput from "../../../components/CustomInput";
import { Formik } from "formik";

const SportsResultsDetail = (props: any) => {
  const dispatch = useDispatch();
  const global_data: any = useSelector((state: any) => state);
  const history = useNavigate();

  const [createNewModalShow, setCreateNewModalShow] = React.useState(false);

  const [imageGalleryModalShow, setImageGalleryModalShow] =
    React.useState(false);

  return (
    <>
      <Header logout={props.logout} />
      <MyPageWrap>
        <LeftSearchPanel />

        <MainContentPanel>
          <MainSearchPanel />

          <HomeListingWrap>
            <HomeListingTags>
              <Link to="/" className="btn">
                All
              </Link>
              <Link to="/concertsresults" className="btn">
                Concerts
              </Link>
              <Link to="/foodanddrinks" className="btn">
                Food &amp; Drinks
              </Link>
              <Link to="/festivalsresults" className="btn">
                Festivals
              </Link>
              <Link to="/sportsresults" className="btn select">
                Sports
              </Link>
              <Link to="/rentalsresults" className="btn">
                Rentals
              </Link>
              <Link to="/" className="btn">
                Hotels
              </Link>
              <Link to="/interestresults" className="btn">
                Points of Interest
              </Link>
              <Link to="/hiking" className="btn">
                Hiking
              </Link>
            </HomeListingTags>

            <CreateBtn>
              <CreateBtnInner>
                <button
                  type="submit"
                  className="btn"
                  onClick={() => setCreateNewModalShow(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                  >
                    <g transform="translate(-1016 -337)">
                      <path
                        d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                        transform="translate(1016 337)"
                        fill="#fff"
                      />
                      <path
                        d="M5.38-8.554V-4.21H1.148v2.732H5.38V2.865H8.335V-1.479h4.232V-4.21H8.335V-8.554Z"
                        transform="translate(1020.143 350.845)"
                        fill="#4d27da"
                        stroke="#fff"
                        stroke-width="0.5"
                        className="plusIcon"
                      />
                    </g>
                  </svg>
                  CREATE
                </button>
              </CreateBtnInner>
            </CreateBtn>
            <SportsDetails>
              <SportsDetailsTop>
                <SportsLogo>
                  <SportsLogoInner>
                    <img src={SportsCompanyImage} alt="Comapny Logo" />
                  </SportsLogoInner>
                </SportsLogo>
                <SportsGallery>
                  <SportsGalleryTop>
                    <img src={SportsEventMain} alt="Sports Event" />
                  </SportsGalleryTop>
                  <SportsGalleryBottom>
                    <SportsGalleryItem>
                      <img src={SportsEventThumbOne} alt="Sports Event" />
                    </SportsGalleryItem>
                    <SportsGalleryItem>
                      <img src={SportsEventThumbTwo} alt="Sports Event" />
                    </SportsGalleryItem>
                    <SportsGalleryItem>
                      <img src={SportsEventThumbThree} alt="Sports Event" />
                      <button
                        type="submit"
                        className="btn"
                        onClick={() => setImageGalleryModalShow(true)}
                      >
                        +10 Photos
                      </button>
                    </SportsGalleryItem>
                  </SportsGalleryBottom>
                </SportsGallery>
              </SportsDetailsTop>

              <SportsTitleWrap>
                <SportsTitle>Golden State Warriors Vs. Mavericks</SportsTitle>
                <Dropdown>
                  <Dropdown.Toggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                      <path
                        d="M4.9-12.42v3.234H1.74v1.147H4.9V-4.82H6.166V-8.039H9.34V-9.186H6.166V-12.42Z"
                        transform="translate(2.459 16.62)"
                        fill="#3b3b3b"
                      ></path>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as="button">Share to Chat</Dropdown.Item>
                    <Dropdown.Item as="button">Add to Plans</Dropdown.Item>
                    <Dropdown.Item as="button">Add to collection</Dropdown.Item>
                    <Dropdown.Item as="button">Create Plans</Dropdown.Item>
                    <Dropdown.Item as="button">Share With</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </SportsTitleWrap>
              <RentalsFeature>
                <ul>
                  <li>Basketball team</li>
                </ul>
              </RentalsFeature>
              <SportsInfo>
                <SportsInfoWrap>
                  <SportsRating>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Component_44_16"
                      data-name="Component 44 – 16"
                      width="22"
                      height="20.364"
                      viewBox="0 0 19 17.364"
                    >
                      <path
                        id="Polygon_1"
                        data-name="Polygon 1"
                        d="M9.5,0l2.375,6.457L19,6.632,13.343,10.8l2.028,6.566L9.5,13.481,3.629,17.364,5.657,10.8,0,6.632l7.125-.175Z"
                        fill="#ffc126"
                      />
                    </svg>
                    4.5
                  </SportsRating>
                  <SportsReview>175 Reviews</SportsReview>
                </SportsInfoWrap>
                <SportsPrice>$85</SportsPrice>
              </SportsInfo>
              <SportsTeamDesc>
                <p>
                  Click "Book Now" button on the right view tickets and complete
                  the purchase on Stubhub
                </p>
                <Button type="button" className="btn">
                  VIEW MORE DETAILS
                </Button>
              </SportsTeamDesc>
            </SportsDetails>
          </HomeListingWrap>
        </MainContentPanel>

        <RightTabbingPanel />
      </MyPageWrap>

      <CreateNewModal
        show={createNewModalShow}
        onHide={() => setCreateNewModalShow(false)}
      />

      <ImageGalleryModal
        show={imageGalleryModalShow}
        onHide={() => setImageGalleryModalShow(false)}
      />
    </>
  );
};

export default SportsResultsDetail;
