import { UserType } from "../../action/signin/user";
import { UserModel } from "../../models/index";

let data: any = localStorage.getItem("user");
let token: any = localStorage.getItem("token");
let id: any = localStorage.getItem("id");
// user = user ? JSON.parse(user) : null;

export const initState: UserModel = {
  data: data,
  token: token,
  id: id,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case UserType.SET_USER: {
      return {
        ...state,
        data: action.data,
        token: action.data.token,
        id: id,
      };
    }
    case UserType.LOGOUT_USER: {
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("id");
      localStorage.removeItem("lat");
      localStorage.removeItem("long");
      localStorage.removeItem("location");
      localStorage.removeItem("locationInfo");
      localStorage.removeItem("latitude-1");
      localStorage.removeItem("longitude-1");

      return {
        data: null,
        token: null,
        id: "",
      };
    }

    default:
      return state;
  }
};
