import { style } from "@mui/system";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 12px 63px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  @media (max-width: 767px) {
    padding: 12px 20px;
  }
`;

export const HeaderLogo = styled.div`
  align-items: flex-end;
  display: flex;
  @media (max-width: 565px) {
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    img {
      max-width: 51px;
    }
  }
`;

export const LogoText = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
  color: #3b3b3b;
  margin: 0 0 0 13px;
  cursor: default;
  @media (max-width: 565px) {
    font-size: 10px;
    margin: 0;
  }
`;

export const HeaderRight = styled.div`
  font-family: "Poppins";
  font-size: 14px;
  line-height: 23px;
  font-weight: normal;
  color: #8d8d8d;
  align-items: center;
  display: flex;
  span {
    display: inline-block;
  }
  a {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: normal;
    color: #8d8d8d;
    &:hover {
      color: #4631d1;
    }
  }
  .btn {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: #ffffff;
    padding: 7px 21px;
    background: #000000;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    margin-left: 15px;
    border: none;
    outline: none;
    transition: all 0.45s ease 0s;
    &:hover {
      background: #4631d1;
      color: #fff;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    li {
      margin: 0 0 0 23px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 565px) {
        margin: 0 0 0 15px;
      }
      @media (max-width: 320px) {
        margin: 0 0 0 12px;
      }
      a {
        position: relative;
        .counter {
          position: absolute;
          top: -5px;
          right: -10px;
          background: #ef3939;
          width: 18px;
          height: 18px;
          border-radius: 20px;
          border: 1px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-family: "Poppins";
          font-size: 10px;
          line-height: 16px;
          font-weight: 500;
        }
        svg {
          vertical-align: middle;
          path {
            transition: all 0.35s ease 0s;
          }
        }
        &:hover {
          path {
            fill: #6840df;
          }
        }
      }
      &.headerUser {
        .dropdown-toggle {
          background: transparent;
          padding: 0;
          border: none;
          border-radius: 100px;
          width: 48px;
          height: 48px;
          box-shadow: none !important;
          outline: none;
          cursor: pointer;
          margin: 0;
          @media (max-width: 565px) {
            width: 36px;
            height: 36px;
          }
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            object-fit: cover;
            object-position: top center;
            border-radius: 100px;
          }
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          background: #ffffff;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
          border-radius: 5px;
          border: none;
          min-width: 108px;
          padding: 15px;
          top: 53px !important;
          left: auto !important;
          right: -30px !important;
          margin: auto;
          transform: none !important;
          @media (max-width: 767px) {
            right: 0 !important;
          }
          .dropdown-item {
            background: transparent;
            padding: 0;
            border: none;
            border-radius: 0;
            font-family: "Poppins";
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            letter-spacing: 0px;
            color: #000;
            display: block;
            margin: 0 0 10px;
            transition: all 0.35s ease 0s;
            &.logout-btn {
              color: #a1a3b0;
              font-style: italic;
            }
            &:first-child,
            &:nth-child(2) {
              color: #3b3b3b;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              color: #6840df;
            }
          }
        }
      }
    }
  }
`;

export const HeaderWrap = styled.div`
  box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.18);
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 99;
  img {
    vertical-align: middle;
    max-width: 100%;
  }
  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: all 0.35s ease 0s;
  }
  &.userHeader {
    ${HeaderContainer} {
      padding: 18px 42px;
      @media (max-width: 767px) {
        padding: 18px 20px;
      }
    }
    ${HeaderLogo} {
      img {
        max-width: 90px;
        width: 90px;
      }
    }
  }
  &:not(.userHeader) {
    ${HeaderRight} {
      @media (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: flex-end;
        span {
          font-size: 12px;
          margin-bottom: 6px;
        }
        .btn {
          font-size: 12px;
          line-height: 18px;
          margin-left: 8px;
        }
      }
    }
    ${HeaderLogo} {
      @media (max-width: 480px) {
        min-width: 100px;
      }
    }
  }
`;
