import { AddFeedBackType } from "../../action/add-feedback/addFeedback";
import { addFeedbackModel } from "../../models";

export const initState: addFeedbackModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case AddFeedBackType.ADD_FEEDBACK_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AddFeedBackType.ADD_FEEDBACK_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case AddFeedBackType.ADD_FEEDBACK_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
