import { addFriendAPI } from "../../../services/addFriend";

export enum AddFriendType {
  ADD_FRIEND_INFO = "ADD_FRIEND_INFO",
  ADD_FRIEND_INFO_SUCCESS = "ADD_FRIEND_INFO_SUCCESS",
  ADD_FRIEND_INFO_FALIURE = "ADD_FRIEND_INFO_FALIURE",
}

export const addFriendInfo = () => (dispatch: any) => {
  dispatch({
    type: AddFriendType.ADD_FRIEND_INFO,
  });
};

export const addFriendInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AddFriendType.ADD_FRIEND_INFO_SUCCESS,
    data,
  });
};

export const addFriendInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: AddFriendType.ADD_FRIEND_INFO_FALIURE,
    data: { msg },
  });
};

export const addFriendData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(addFriendInfo());
    const result: any = await addFriendAPI(finalData);
    dispatch(addFriendInfoSuccess(result));
  } catch (e: any) {
    dispatch(addFriendInfoFailed(e.message));
  }
};
