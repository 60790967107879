import { updateUserAPI } from "../../../services/updateUser";

export enum UpdateUserType {
  UPDATE_INFO = "UPDATE_INFO",
  UPDATE_INFO_SUCCESS = "UPDATE_INFO_SUCCESS",
  UPDATE_INFO_FALIURE = "UPDATE_INFO_FALIURE",
}

export const updateInfo = () => (dispatch: any) => {
  dispatch({
    type: UpdateUserType.UPDATE_INFO,
  });
};

export const updateInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: UpdateUserType.UPDATE_INFO_SUCCESS,
    data,
  });
};

export const updateInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: UpdateUserType.UPDATE_INFO_FALIURE,
    data: { msg },
  });
};

export const updateUserData =
  ({ id, firstname, lastname, password, username, dob }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(updateInfo());
      const result: any = await updateUserAPI({
        id,
        firstName: firstname,
        lastName: lastname,
        password: password,
        userName: username,
        dob,
      });
      dispatch(updateInfoSuccess(result));
    } catch (e: any) {
      console.error(e);
      dispatch(updateInfoFailed(e.message));
    }
  };
