import { pointOfInterestAPI } from "../../../services/pointOfInterest";

export enum pointOfInterestType {
  POINT_OF_INTEREST_INFO = "POINT_OF_INTEREST_INFO",
  POINT_OF_INTEREST_INFO_SUCCESS = "POINT_OF_INTEREST_INFO_SUCCESS",
  POINT_OF_INTEREST_INFO_FAILURE = "POINT_OF_INTEREST_INFO_FAILURE",
}

export const pointOfInterestInfo = () => (dispatch: any) => {
  dispatch({
    type: pointOfInterestType.POINT_OF_INTEREST_INFO,
  });
};

export const pointOfInterestInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: pointOfInterestType.POINT_OF_INTEREST_INFO_SUCCESS,
    data,
  });
};

export const pointOfInterestInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: pointOfInterestType.POINT_OF_INTEREST_INFO_FAILURE,
    data: msg,
  });
};

export const pointOfInterestData = (data: any) => async (dispatch: any) => {
  try {
    dispatch(pointOfInterestInfo());
    const result: any = await pointOfInterestAPI(data);

    // if (result?.data?.results?.length === 0) {
    //   window.location.reload();
    // }
    dispatch(pointOfInterestInfoSuccess(result));
  } catch (e: any) {
    dispatch(pointOfInterestInfoFailed(e?.response?.data?.message));
  }
};
