import { useState, useEffect } from "react";

import { Tabs, Tab, Modal, Dropdown, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GlobalDataType } from "../../redux/action/globalData";
import { updatePlanData } from "../../redux/action/updatePlan/updatePlan";
import JasonPamel from "../../assets/images/jason-pamel.png";
import SandraJoseph from "../../assets/images/sandra-joseph.png";
import PeterJohn from "../../assets/images/peter-john.png";
import CandyBrian from "../../assets/images/candy-brian.png";
import Kathy from "../../assets/images/kathy.png";
import James from "../../assets/images/james.png";
import CalendarIcon from "../../assets/images/calendar-icon.svg";
import PrivateIcon from "../../assets/images/private-icon.svg";
import FollowersIcon from "../../assets/images/followers-icon.svg";
import FriendsIcon from "../../assets/images/friends-icon.svg";
import PublicIcon from "../../assets/images/public-icon.svg";

import {
  FormGroup,
  ModalBody,
  ModalBodyBtn,
  FriendItem,
  CreatePlanDate,
  RadioBox,
  CreatePlanBody,
  PlanDates,
} from "./copyPlanModalStyle";

import { createPlanHandler } from "../../redux/action/createPlan/createPlan";
import CustomInput from "../CustomInput";
import { Formik, FormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { copyPlanHandler } from "../../redux/action/copyPlan/copyPlan";

const CopyPlanModal = (props: any) => {
  const dispatch: any = useDispatch();
  let { plan_id }: any = useParams();

  const submitForm = (values: any) => {
    const removeLoginId = props.getAllId.filter(
      (ele: any) => ele !== localStorage.getItem("id")
    );

    const removeDuplicate = removeLoginId.filter(
      (ele: any, index: any) => removeLoginId.indexOf(ele) === index
    );

    const finalValue = {
      ...values,
      start_date: moment(values.start_date).format("MM-DD-YYYY"),
      end_date:
        values.end_date !== ""
          ? moment(values.end_date).format("MM-DD-YYYY")
          : moment(values.start_date).format("MM-DD-YYYY"),
      is_requested: true,
      notification_type: "Request To Join Plan",
      days: props.planDetails?.data?.data[0]?.days,
      emails: props.getAllEmail,
      invited_user: removeDuplicate,
    };

    dispatch(createPlanHandler(finalValue));
    props.setCopyPlanModal(false);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="updatePlanModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`
            .updatePlanModal .modal-dialog {
              max-width: calc(100% - 34px);
              width: 560px;
              letter-spacing: 0;
              margin: auto;
              padding: 30px 0;
            }
            .updatePlanModal .modal-content {
              background: #FFFFFF;
              border-radius: 15px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
          `}
        </style>

        <ModalBody>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modalTitle">
              <h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.863"
                  height="19.867"
                  viewBox="0 0 19.863 19.867"
                >
                  <path
                    d="M19.309.208A1.254,1.254,0,0,0,18.135.1l-5.3,2.273L7.525.1c.008,0,0,.034.009.042A1.232,1.232,0,0,0,6.546.1L.752,2.585A1.244,1.244,0,0,0,0,3.727v14.9a1.243,1.243,0,0,0,1.242,1.242,1.282,1.282,0,0,0,.49-.1l5.3-2.273,5.306,2.273a1.282,1.282,0,0,0,.49.1,1.239,1.239,0,0,0,.484-.146l.005.047,5.794-2.483a1.244,1.244,0,0,0,.752-1.142V1.243A1.236,1.236,0,0,0,19.309.208ZM2.483,4.547l3.31-1.419v12.2l-3.31,1.419ZM8.277,3.125,11.59,4.547v12.2L8.277,15.324Zm9.107,12.2-3.31,1.419V4.547l3.31-1.419Z"
                    transform="translate(0 -0.002)"
                    fill="#4d27da"
                  />
                </svg>
                CREAT A PLAN
              </h2>
              <p>
                You are copying content from a plan that "Username" created. You
                can keep the same information or update it if you choose.
              </p>
            </div>
            <Button
              className="btn addFriendsBtn"
              onClick={() => {
                // props.setCreatePlanModalShow(false);
                props.setInviteOthersModalShow(true);
                props.setEmailValidate("");
                props.setEmail("");
                props.setOpenTab({
                  follower: false,
                  group: true,
                  friend: false,
                  email: false,
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <defs>
                  <clipPath>
                    <rect width="21.911" height="21.731" fill="#fff" />
                  </clipPath>
                </defs>
                <circle cx="24" cy="24" r="24" fill="#4d27da" />
                <g transform="translate(13.045 13.134)">
                  <g clip-path="url(#clip-path)">
                    <path
                      d="M241.414,95.111c-.013.469-.029.939-.038,1.408-.006.277,0,.554,0,.845h-5.32a6.051,6.051,0,0,0-3.136-6.1c.1-.28.214-.563.3-.854a5.832,5.832,0,0,0-3.108-6.961.237.237,0,0,1-.165-.288,4.3,4.3,0,0,1,2.132-3.285,4.4,4.4,0,0,1,6.5,2.436,4.266,4.266,0,0,1-1.029,4.448,1.828,1.828,0,0,1-.232.177c.378.095.7.148,1,.255a4.409,4.409,0,0,1,3.057,3.668,1.1,1.1,0,0,0,.043.141Z"
                      transform="translate(-219.503 -75.638)"
                      fill="#fff"
                    />
                    <path
                      d="M24.956,198.851a4.422,4.422,0,0,1-1.42-3.811,4.279,4.279,0,0,1,1.377-2.724,4.451,4.451,0,1,1,6.043,6.537,4.5,4.5,0,0,1,3.553,2.438,4.7,4.7,0,0,1,.441,2.89H20.811a4.648,4.648,0,0,1,3.8-5.284c.1-.019.205-.027.343-.045"
                      transform="translate(-19.846 -182.45)"
                      fill="#fff"
                    />
                    <path
                      d="M2.868,5.348c-.555,0-1.088.006-1.62,0A1.238,1.238,0,0,1,1.16,2.874c.484-.016.969-.005,1.454-.006h.255c0-.567-.007-1.114,0-1.66A1.166,1.166,0,0,1,3.465.183a1.236,1.236,0,0,1,1.88,1.04c.011.463,0,.927,0,1.39,0,.077,0,.154,0,.255.551,0,1.084,0,1.616,0a1.239,1.239,0,0,1,.067,2.478c-.477.012-.955,0-1.433,0H5.348c0,.56,0,1.1,0,1.64a1.239,1.239,0,0,1-2.476.063c-.009-.278,0-.556,0-.834s0-.555,0-.87"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              ADD FRIENDS
            </Button>
            <CreatePlanBody>
              <ul className="friendsList">
                {props?.pendingEmailRequest?.map((ele: any) => (
                  <li>
                    <FriendItem>
                      <img
                        src={
                          ele?.photo.includes("http") ||
                          ele?.photo.includes("https")
                            ? ele?.photo
                            : `${props?.getImageUrl}${ele?.photo}`
                        }
                        alt={ele?.firstName}
                      />
                      <h3>{`${ele?.firstName} ${ele?.lastName}`}</h3>
                    </FriendItem>
                  </li>
                ))}
              </ul>

              <Formik
                enableReinitialize
                initialValues={{
                  start_date: moment(
                    props.planDetails?.data?.data[0]?.start_date
                  ).toDate(),
                  end_date: moment(
                    props.planDetails?.data?.data[0]?.end_date
                  ).toDate(),
                  plan_name: props.planDetails?.data?.data[0]?.plan_name,
                  description: props.planDetails?.data?.data[0]?.description,
                  view_plan: props.planDetails?.data?.data[0]?.view_plan,
                }}
                validate={(values: any) => {
                  let errors: any = {};

                  if (!values.plan_name) {
                    errors.plan_name = "Please enter your plan name";
                  }
                  if (!values.start_date) {
                    errors.start_date = "Please select start date";
                  }

                  return errors;
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <CreatePlanDate style={{ display: "none" }}>
                        <img src={CalendarIcon} alt="calendar-icon" />
                        Dec 21-25
                      </CreatePlanDate>
                      <PlanDates>
                        <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>

                          <DatePicker
                            selected={values.start_date}
                            onChange={(e: any) => {
                              setFieldValue("start_date", e);
                              setFieldValue("end_date", "");
                            }}
                            // dateFormat="MM-DD-YYYY"
                            dateFormat="MM-dd-yyyy"
                            placeholderText={"Start Date"}
                            minDate={moment().toDate()}
                            onBlur={handleBlur}
                            // value={values.date}
                            // onChange={handleChange}
                            className="form-control"
                          />

                          {errors.start_date && touched.start_date && (
                            <label className="error">
                              please select start date
                            </label>
                          )}
                        </FormGroup>
                        <FormGroup className="dateInputGroup">
                          <i className="calendar-icon">
                            <img src={CalendarIcon} alt="calendar-icon" />
                          </i>
                          <DatePicker
                            selected={values.end_date}
                            onChange={(e: Date) => {
                              setFieldValue("end_date", e);
                            }}
                            disabled={values.start_date === null ? true : false}
                            // dateFormat="dd/MM/yyyy"
                            dateFormat="MM-dd-yyyy"
                            minDate={moment(values.start_date)
                              .add(1, "days")
                              .toDate()}
                            placeholderText={"End Date"}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.end_date && touched.end_date && (
                            <label className="error">
                              please select end date
                            </label>
                          )}
                        </FormGroup>
                      </PlanDates>
                      <FormGroup>
                        <CustomInput
                          inputid="plan_name"
                          inputlabe=""
                          inputtype="text"
                          placeholder="Name your Plan"
                          value={values?.plan_name?.trimStart()}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors.plan_name}
                          touched={touched.plan_name}
                          maxLength={25}
                        />
                      </FormGroup>

                      <FormGroup className="mb-0">
                        <CustomInput
                          inputid="description"
                          inputlabe=""
                          inputtype="text"
                          as="textarea"
                          placeholder="Add notes or description"
                          value={values?.description?.trimStart()}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors=""
                          touched=""
                          maxLength={255}
                        />
                      </FormGroup>

                      <ul className="planFeature">
                        <li>
                          <h4>Choose who can see and join this</h4>
                          <ul className="planVisibility">
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  value="Private"
                                  checked={
                                    values.view_plan == "Private" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                  id="private"
                                  defaultChecked
                                />
                                <span />
                                <label>
                                  <img src={PrivateIcon} alt="private-icon" />
                                  Private
                                </label>
                                <p>Only people that are invited</p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  value="Friends"
                                  id="friend"
                                  checked={
                                    values.view_plan == "Friends" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img src={FriendsIcon} alt="friends-icon" />
                                  Friends
                                </label>
                                <p>Only your friends on TripD</p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  id="followers"
                                  value="Followers"
                                  checked={
                                    values.view_plan == "Followers"
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img
                                    src={FollowersIcon}
                                    alt="followers-icon"
                                  />
                                  Followers
                                </label>
                                <p>
                                  Your Followers (Friends automatically follow
                                  you)
                                </p>
                              </RadioBox>
                            </li>
                            <li>
                              <RadioBox>
                                <input
                                  type="radio"
                                  name="view_plan"
                                  id="public"
                                  value="Public"
                                  checked={
                                    values.view_plan == "Public" ? true : false
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue("view_plan", e.target.value);
                                    props.setPlanDetails(e.target.value);
                                  }}
                                />
                                <span />
                                <label>
                                  <img src={PublicIcon} alt="public-icon" />
                                  Public
                                </label>
                                <p>All Tripd users</p>
                              </RadioBox>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ModalBodyBtn>
                        <Button type="submit" className="btn">
                          CREATE
                        </Button>
                      </ModalBodyBtn>
                    </form>
                  </>
                )}
              </Formik>
            </CreatePlanBody>
            {/* </Form> */}
          </Modal.Body>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CopyPlanModal;
