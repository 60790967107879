import { helpAndFaqList } from "../../../services/helpAndFaqList";

export enum helpAndFaqType {
  HELP_AND_FAQ_LIST = "HELP_AND_FAQ_LIST",
  HELP_AND_FAQ_LIST_SUCCESS = "HELP_AND_FAQ_LIST_SUCCESS",
  HELP_AND_FAQ_LIST_FAILURE = "HELP_AND_FAQ_LIST_FAILURE",
}

export const helpAndFaqListInfo = () => (dispatch: any) => {
  dispatch({
    type: helpAndFaqType.HELP_AND_FAQ_LIST,
  });
};

export const helpAndFaqListInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: helpAndFaqType.HELP_AND_FAQ_LIST_SUCCESS,
    data,
  });
};

export const helpAndFaqListInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: helpAndFaqType.HELP_AND_FAQ_LIST_FAILURE,
    error: msg,
  });
};

export const helpAndFaqListData: any = () => async (dispatch: any) => {
  try {
    dispatch(helpAndFaqListInfo());
    const result: any = await helpAndFaqList();
    dispatch(helpAndFaqListInfoSuccess(result));
  } catch (e: any) {
    dispatch(helpAndFaqListInfoFailed(e?.response?.data));
  }
};
