import { getPlanAPI } from "../../../services/joinPlan";

export enum joinInfoType {
  JOIN_PLAN_INFO = "JOIN_PLAN_INFO",
  JOIN_PLAN_INFO_SUCCESS = "JOIN_PLAN_INFO_SUCCESS",
  JOIN_PLAN_INFO_FALIURE = "JOIN_PLAN_INFO_FALIURE",
}

export const joinInfo = () => (dispatch: any) => {
  dispatch({
    type: joinInfoType.JOIN_PLAN_INFO,
  });
};

export const joinInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: joinInfoType.JOIN_PLAN_INFO_SUCCESS,
    data,
  });
};

export const joinInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: joinInfoType.JOIN_PLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const joinPlanHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(joinInfo());
    const result: any = await getPlanAPI(data);
    dispatch(joinInfoSuccess(result));
  } catch (e: any) {
    console.error(e);
    dispatch(joinInfoFailed(e?.respone?.data?.message));
  }
};
