import { signupAPI } from "../../../services/signup";

export enum SignUpType {
  SIGNUP_INFO = "SIGNUP_INFO",
  SIGNUP_INFO_SUCCESS = "SIGNUP_INFO_SUCCESS",
  SIGNUP_INFO_FALIURE = "SIGNUP_INFO_FALIURE",
}

export const signUpInfo = () => (dispatch: any) => {
  dispatch({
    type: SignUpType.SIGNUP_INFO,
  });
};

export const signUpInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: SignUpType.SIGNUP_INFO_SUCCESS,
    data,
  });
};

export const signUpInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: SignUpType.SIGNUP_INFO_FALIURE,
    data: { msg },
  });
};

export const signUpDataAPI =
  ({ firstname, lastname, username, password, email, dob }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(signUpInfo());

      const result: any = await signupAPI({
        firstname,
        lastname,
        username,
        password,
        email,
        dob,
      });

      dispatch(signUpInfoSuccess(result));
    } catch (e: any) {
      dispatch(signUpInfoFailed(e.message));
    }
  };
