import { UnFollowType } from "../../../redux/action/unfollow/unFollow";
import { unFollowModel } from "../../../redux/models/index";

export const initState: unFollowModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case UnFollowType.UNFOLLOW_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UnFollowType.UNFOLLOW_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
      };
    }
    case UnFollowType.UNFOLLOW_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        msg: action.data,
      };
    }
    default:
      return state;
  }
};
