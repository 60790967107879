export const initState = {
  setGuestRatingValue: 0,
  setRangeValue: [0, 1000],
  setFacility: [],
  setRoomTypes: [],
  setPetRules: false,
  setFilterPagination1: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case "HOTEL_UPDATE_FILTER_DATA": {
      return {
        ...state,
        setFilterPagination1: action.payload,
      };
    }
    case "HOTEL_PET_RULES": {
      return {
        ...state,
        setPetRules: action.payload,
      };
    }
    case "HOTEL_GUEST_RATING_VALUE": {
      return {
        ...state,
        setGuestRatingValue: action.payload,
      };
    }
    case "HOTEL_SET_FACILITY": {
      return {
        ...state,
        setFacility: [...state.setFacility, action.payload],
      };
    }
    case "HOTEL_REMOVE_FACILITY": {
      return {
        ...state,
        setFacility: state.setFacility.filter(
          (item) => item !== action.payload
        ),
      };
    }
    case "HOTEL_SET_ROOM_TYPE": {
      return {
        ...state,
        setRoomTypes: [...state.setRoomTypes, action.payload],
      };
    }
    case "HOTEL_REMOVE_ROOM_TYPE": {
      return {
        ...state,
        setRoomTypes: state.setRoomTypes.filter(
          (item) => item !== action.payload
        ),
      };
    }
    case "HOTEL_SET_RANGE_VALUE": {
      return {
        ...state,
        setRangeValue: action.payload,
      };
    }
    case "HOTEL_RESET_FILTER_DATA": {
      return {
        ...initState,
      };
    }

    default:
      return state;
  }
};
