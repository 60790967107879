import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const SearchUserPanel = styled.div`
  width: 20.209%;
  background: #ffffff;
  position: relative;
  transition: all 0.35s ease 0s;
  @media (max-width: 1199px) {
    position: absolute;
    width: 320px;
    top: 15px;
    bottom: 0;
    z-index: 9;
    margin-left: -320px;
  }
  @media (max-width: 767px) {
    top: 5px;
  }
  @media (max-width: 565px) {
    width: calc(100% - 15px);
    margin-left: calc(-100% + 15px);
  }
  &.panel-close {
    margin-left: -20.209%;
    @media (max-width: 1199px) {
      margin-left: 0;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      z-index: 10;
    }
    .panelToggleBtn {
      right: -25px;
      @media (max-width: 1199px) {
        right: -11px;
      }
      @media (max-width: 767px) {
        top: 10px;
      }
      svg {
        transform: rotate(180deg);
        @media (max-width: 1199px) {
          transform: inherit;
        }
      }
    }
  }
  .panelToggleBtn {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 30px;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 77px;
    right: -11px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.35s ease 0s;
    @media (max-width: 1199px) {
      right: -25px;
    }
    @media (max-width: 767px) {
      top: 10px;
    }
    svg {
      vertical-align: middle;
      transition: all 0.35s ease 0s;
      @media (max-width: 1199px) {
        transform: rotate(180deg);
      }
      path {
        transition: all 0.35s ease 0s;
      }
    }
    &:hover {
      background: #4d27da;
      @media (max-width: 1199px) {
        background: inherit;
      }
      path {
        stroke: #fff;
        @media (max-width: 1199px) {
          stroke: #4d27da;
        }
      }
    }
  }
`;

export const SearchUserBox = styled.div`
  margin: 0;
  padding: 24px 24px 20px;
  @media (max-width: 1199px) {
    padding: 15px 15px 12px;
  }
  form {
    > div {
      margin-bottom: 0;
    }
  }
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #cecece;
      border-radius: 5px;
      outline: none;
      box-shadow: none;
      background: #fff;
      padding: 13px 50px 13px 20px;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      @media (max-width: 1199px) {
        padding: 8px 38px 8px 15px;
      }
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
      }
      &:-moz-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 20px;
      top: 14px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      @media (max-width: 1199px) {
        right: 11px;
        top: 8px;
      }
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
        @media (max-width: 1199px) {
          width: 18px;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
  }
`;

export const SearchBoxNote = styled.div`
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 0 24px 24px;
`;

export const UserListItem = styled.div`
  display: flex;
  border-bottom: 1px solid #cecece;
  padding: 0 17px 20px;
  margin-bottom: 20px;
  @media (max-width: 1199px) {
    padding: 0 10px 20px;
  }
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  .btn {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
  }
`;

export const UserListImage = styled.div`
  width: 48px;
  min-width: 48px;
  @media (max-width: 1199px) {
    width: 40px;
    min-width: 40px;
  }
  img {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
    @media (max-width: 1199px) {
      width: 40px;
      height: 40px;
    }
  }
`;

export const UserTime = styled.div`
  font-family: "Poppins";
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
  color: #8d8d8d;
  letter-spacing: 0px;
  margin: 5px 0 0;
`;

export const UserListInfo = styled.div`
  padding-left: 9px;
  flex: 1;
  h3 {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #3b3b3b;
    margin: 0;
    strong {
      font-weight: 700;
      cursor: pointer;
    }
    a {
      &:hover {
        color: #4d27da !important;
      }
    }
    strong {
      &:hover {
        color: #4d27da !important;
      }
    }
  }
`;

export const UserListInfoImage = styled.div`
  position: relative;
  justify-content: flex-end;
  display: flex;
  margin-top: 5px;
  img {
    max-width: 100%;
    width: 70px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    vertical-align: middle;
  }
  .dropdown {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 12px;
    line-height: 12px;
    .dropdown-toggle {
      border: none;
      padding: 0;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 12px;
      border-radius: 30px;
      background: transparent;
      outline: none;
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      svg {
        vertical-align: middle;
        transition: all 0.25s ease 0s;
        path,
        circle {
          transition: all 0.35s ease 0s;
        }
      }
      &::after {
        display: none;
      }
      &:hover {
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    &.show {
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
        }
        circle {
          fill: #4d27da;
        }
        path {
          fill: #fff;
        }
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: none;
      min-width: 146px;
      padding: 15px;
      // top: 38px !important;
      left: auto !important;
      right: -15px !important;
      margin: 19px auto 10px;
      // transform: none !important;
      .dropdown-item {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000;
        display: block;
        margin: 0 0 10px;
        transition: all 0.35s ease 0s;
        &:nth-child(3) {
          color: #3b3b3b;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #6840df;
        }
      }
    }
  }
`;

export const UserListWrap = styled.div`
  padding: 0 10px 20px;
  max-height: calc(100vh - 195px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      border-bottom: 1px solid #cecece;
      padding: 0 17px 20px;
      margin-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &.searchUserList {
    ${UserListItem} {
      padding-bottom: 0;
      border-bottom: none;
      align-items: center;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SearchListLoader = styled.div`
  text-align: center;
  padding: 5px 0 15px;
  img {
    width: 36px;
    height: 36px;
    vertical-align: middle;
  }
`;
