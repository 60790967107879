import { getAboutUsAPI } from "../../../services/aboutUs";
export enum GetAboutUsType {
  GET_ABOUT_US_INFO = "GET_ABOUT_US_INFO",
  GET_ABOUT_US_INFO_SUCCESS = "GET_ABOUT_US_INFO_SUCCESS",
  GET_ABOUT_US_INFO_FALIURE = "GET_ABOUT_US_INFO_FALIURE",
}

export const getAboutUsInfo = () => (dispatch: any) => {
  dispatch({
    type: GetAboutUsType.GET_ABOUT_US_INFO,
  });
};

export const getAboutUsInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: GetAboutUsType.GET_ABOUT_US_INFO_SUCCESS,
    data: data,
  });
};

export const getAboutUsInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: GetAboutUsType.GET_ABOUT_US_INFO_FALIURE,
    data: { msg },
  });
};

export const getAboutUsData = () => async (dispatch: any) => {
  try {
    dispatch(getAboutUsInfo());
    const result: any = await getAboutUsAPI();
    dispatch(getAboutUsInfoSuccess(result));
  } catch (e: any) {
    dispatch(getAboutUsInfoFailed(e.response.data.message));
  }
};
