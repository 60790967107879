import { getHotelImagesAPI } from "../../../services/getHotelImages";

export enum getHotelImagesType {
  GET_HOTEL_IMAGES_INFO = "GET_HOTEL_IMAGES_INFO",
  GET_HOTEL_IMAGES_SUCCESS = "GET_HOTEL_IMAGES_SUCCESS",
  GET_HOTEL_IMAGES_FALIURE = "GET_HOTEL_IMAGES_FALIURE",
}

export const getHotelImagesInfo = () => (dispatch: any) => {
  dispatch({
    type: getHotelImagesType.GET_HOTEL_IMAGES_INFO,
  });
};

export const getHotelImagesInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getHotelImagesType.GET_HOTEL_IMAGES_SUCCESS,
    data,
  });
};

export const getHotelImagesInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getHotelImagesType.GET_HOTEL_IMAGES_FALIURE,
    error: msg,
  });
};

export const getHotelImagesData = (id: any) => async (dispatch: any) => {
  try {
    dispatch(getHotelImagesInfo());
    const result: any = await getHotelImagesAPI(id);
    dispatch(getHotelImagesInfoSuccess(result));
  } catch (e: any) {
    dispatch(getHotelImagesInfoFailed(e?.response?.data));
  }
};
