import { getSportsModel } from "../../models/index";
import { getSportsEventType } from "../../action/get-sports-event/getSportsEvent";

export const initState: getSportsModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case getSportsEventType.GET_SPORTS_EVENT_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getSportsEventType.GET_SPORTS_EVENT_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case getSportsEventType.GET_SPORTS_EVENT_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case "RESET_SPORTS_EVENT": {
      return {
        isLoading: false,
        error: "",
        msg: "",
        data: null,
      };
    }
    default:
      return state;
  }
};
