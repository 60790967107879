import { searchFollowersModel } from "../../models/index";
import { SearchFollowType } from "../../action/search-followers/searchFollowers";

export const initState: searchFollowersModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case SearchFollowType.SEARCH_FOLLOW_INFO: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case SearchFollowType.SEARCH_FOLLOW_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    }
    case SearchFollowType.SEARCH_FOLLOW_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.data.msg,
        data: null,
      };
    }
    default:
      return state;
  }
};
