import { AddFriendType } from "../../action/add-friends/addFriends";
import { addFriendsModel } from "../../models/index";

export const initState: addFriendsModel = {
  isLoading: false,
  error: "",
  msg: "",
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case AddFriendType.ADD_FRIEND_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AddFriendType.ADD_FRIEND_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case AddFriendType.ADD_FRIEND_INFO_FALIURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
