import { style } from "@mui/system";
import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  label {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #9597a6;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: middle;
    &:empty {
      display: none;
    }
  }
  .form-control {
    background: #fff;
    padding: 11px 15px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #8d8d8d;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #a1a3b0;
      font-size: 12px;
      opacity: 1;
    }

    &:focus {
      border-color: #4d27da;
    }
  }
  .error {
    font-family: "Poppins";
    color: #ef3939;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    &:empty {
      display: none;
    }
  }
  .eye-icon {
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    position: absolute;
    top: 39px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
  &.inputGroupIcon {
    .form-control {
      padding-right: 50px;
    }
    & > {
      .eye-icon {
        &:first-child {
          top: 9px;
        }
      }
    }
  }
`;

export const SearchBoxWrap = styled.div`
  position: relative;
  @media screen and (max-width: 1199px) {
    z-index: 2;
  }
`;

export const SearchBoxBanner = styled.div`
  width: 100%;
  height: 172px;
  background: #e7e7e7;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }
  @media screen and (max-width: 1600px) {
    height: 150px;
  }
  @media screen and (max-width: 1440px) {
    height: 130px;
  }
`;

export const SearchBoxInner = styled.div`
  position: absolute;
  bottom: -25px;
  right: 20px;
  left: 20px;
  width: 74.85%;
  margin: auto;
  z-index: 2;
  @media (max-width: 1199px) {
    z-index: 0;
  }
  @media (max-width: 565px) {
    bottom: -15px;
    right: 10px;
    left: 10px;
    width: 94%;
  }
`;

export const SearchBoxTopBtn = styled.div`
  margin-bottom: 10px;
  display: flex;

  .btn {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    color: #ffffff;
    background: transparent;
    align-items: center;
    display: flex;
    svg {
      vertical-align: middle;
      margin-right: 10px;
      path {
        transition: all 0.35s ease 0s;
      }
    }
    /* &:hover {
      color: #6840df;
      path {
        fill: #6840df;
      }
    } */
  }

  .searchLocation {
    margin-right: 32px;
    position: initial;
    display: flex;
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      border-radius: 50px;
      border: none;
      padding: 5px;
      margin: 6px 0;
      left: 0 !important;
      right: 0 !important;
      bottom: 16px !important;
      width: 100%;
      input {
        border: none;
        padding: 10px 5px;
        width: 99%;
        margin: 0 auto;
      }
    }
  }
`;

export const LocationSearchInput = styled.div`
  .form-control {
    background: #fff;
    padding: 10px 13px;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    width: 100%;
    border: 1px solid #cecece;
    border-radius: 5px;
    transition: all 0.35s ease 0s;
    outline: none;
    box-shadow: none;
    position: relative;
    &::-webkit-input-placeholder {
      color: #8d8d8d;
      font-size: 13px;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #8d8d8d;
      font-size: 13px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #8d8d8d;
      font-size: 13px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #8d8d8d;
      font-size: 13px;
      opacity: 1;
    }
    &:focus {
      border-color: #4d27da;
    }
  }
`;

export const SearchBox = styled.div`
  ${FormGroup} {
    position: relative;
    margin-bottom: 0;
    .form-control {
      border: 1px solid transparent;
      border-radius: 50px;
      outline: none;
      background: #fff;
      padding: 13px 50px 13px 20px;
      font-family: "Poppins";
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #3b3b3b;
      transition: all 0.35s ease 0s;
      box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.16);
      @media (max-width: 565px) {
        padding: 8px 50px 8px 20px;
        font-size: 12px;
        line-height: 18px;
      }
      &:focus {
        border-color: #4d27da;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
        @media (max-width: 565px) {
          font-size: 12px;
        }
      }
      &::-moz-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
        @media (max-width: 565px) {
          font-size: 12px;
        }
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
        @media (max-width: 565px) {
          font-size: 12px;
        }
      }
      &:-moz-placeholder {
        font-size: 14px;
        color: #8d8d8d;
        opacity: 1;
        @media (max-width: 565px) {
          font-size: 12px;
        }
      }
    }
    .searchIconBtn {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: absolute;
      right: 20px;
      top: 14px;
      font-size: 18px;
      line-height: 18px;
      cursor: auto;
      @media (max-width: 565px) {
        right: 14px;
        top: 6px;
      }
      svg {
        vertical-align: middle;
        path {
          transition: all 0.35s ease 0s;
        }
        @media (max-width: 565px) {
          width: 15px;
        }
      }
      &:hover {
        path {
          fill: #4d27da;
        }
      }
    }
  }
`;

export const SearchListDropDown = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  padding: 0;
  max-height: 396px;
  overflow-y: auto;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin-top: 8px;
  word-break: break-word;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cecece;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 1560px) {
    max-height: 331px;
  }
  @media screen and (max-width: 1440px) {
    max-height: 274px;
  }
  @media screen and (max-width: 767px) {
    max-height: 140px;
  }
  @media screen and (max-width: 480px) {
    max-height: 194px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      border-bottom: 1px solid #e6e6e6;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #3b3b3b;
      position: relative;
      cursor: pointer;
      padding: 10px 15px;

      &:last-child {
        border-bottom: none;
      }
      &:hover {
        color: #6840df;
      }
    }
  }
`;

export const SearchBoxDate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
  .react-datepicker-wrapper {
    line-height: 18px;
    width: 100px;
    .form-control {
      border: none;
      padding: 0;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 19px;
      font-weight: 400;
      color: #ffffff;
      width: 100%;
      cursor: pointer;
      margin-left: -30px;
      padding-left: 30px;
      &::-webkit-input-placeholder {
        color: #fff;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: #fff;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #fff;
        opacity: 1;
      }
      &:-moz-placeholder {
        color: #fff;
        opacity: 1;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 9;
    line-height: 10px;
    margin-left: -28px;

    &[data-placement^="bottom"] {
      padding-top: 5px;
    }
    &[data-placement^="top"] {
      padding-bottom: 5px;
    }
  }

  .react-datepicker {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.1);
    padding: 11px 20px;
    width: 291px;
    background: #fff;
    font-family: "Poppins";
    font-size: 13px;
    line-height: 20px;
    color: #0e1224;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__navigation--previous {
      left: 7px;
      .react-datepicker__navigation-icon--previous {
        &:before {
          left: -4px;
          right: auto;
        }
      }
    }
    .react-datepicker__navigation--next {
      right: 8px;
      .react-datepicker__navigation-icon--next {
        &:before {
          right: -4px;
          left: auto;
        }
      }
    }
    .react-datepicker__navigation-icon {
      padding: 0;
      &:before {
        border-width: 2px 2px 0 0;
        border-color: #3b3b3b;
        height: 8px;
        width: 8px;
      }
    }
    .react-datepicker__month {
      margin: 0;
    }
    .react-datepicker__header {
      background-color: #ffffff;
      border-bottom: none;
      padding: 0 0 20px;
      .react-datepicker__current-month,
      .react-datepicker-year-header,
      .react-datepicker-time__header {
        color: #0e1224;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        border-bottom: 1px solid #f5f5f6;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: -3px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #3b3b3b;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 19px;
        font-weight: 400;
      }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 3px 4px;
      height: 27px;
      width: 27px;
      font-family: "Poppins";
      font-size: 13px;
      line-height: 24px;
      font-weight: normal;
      color: #0e1224;
      border-radius: 30px;
      border: 2px solid transparent;
      transition: all 0.35s ease 0s;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 50%;
      border: 2px solid #6840df;
      background-color: transparent;
      color: #3b3b3b;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 50%;
      border: 2px solid #6840df;
      background-color: transparent;
      color: #3b3b3b;
    }
    .react-datepicker__day--outside-month {
      color: #8d8d8d;
    }
    .react-datepicker__day--disabled {
      color: #8d8d8d;
      pointer-events: none;
    }
  }
`;
