import { planListingAPI } from "../../../services/planListing";

export enum planListingType {
  PLAN_LISTING_INFO = "PLAN_LISTING_INFO",
  PLAN_LISTING_INFO_SUCCESS = "PLAN_LISTING_INFO_SUCCESS",
  PLAN_LISTING_INFO_FAILURE = "PLAN_LISTING_INFO_FAILURE",
}

export const planListingInfo = () => (dispatch: any) => {
  dispatch({
    type: planListingType.PLAN_LISTING_INFO,
  });
};

export const planListingInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: planListingType.PLAN_LISTING_INFO_SUCCESS,
    data,
  });
};

export const planListingInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: planListingType.PLAN_LISTING_INFO_FAILURE,
    data: msg,
  });
};

export const planListingData = () => async (dispatch: any) => {
  try {
    dispatch(planListingInfo());
    const result: any = await planListingAPI();
    dispatch(planListingInfoSuccess(result));
  } catch (e: any) {
    dispatch(planListingInfoFailed(e?.response?.data?.message));
  }
};
