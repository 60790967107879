import { getAllHotelsAPI } from "../../../services/getAllHotel";

export enum getAllHotels {
  GET_ALL_HOTELS = "GET_ALL_HOTELS",
  GET_ALL_HOTELS_SUCCESS = "GET_ALL_HOTELS_SUCCESS",
  GET_ALL_HOTELS_FALIURE = "GET_ALL_HOTELS_FALIURE",
}

export const getAllHotelsInfo = () => (dispatch: any) => {
  dispatch({
    type: getAllHotels.GET_ALL_HOTELS,
  });
};

export const getAllHotelsInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: getAllHotels.GET_ALL_HOTELS_SUCCESS,
    data,
  });
};

export const getAllHotelsInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: getAllHotels.GET_ALL_HOTELS_FALIURE,
    error: msg,
  });
};

export const getAllHotelHandler = (data: any) => async (dispatch: any) => {
  try {
    dispatch(getAllHotelsInfo());
    const result: any = await getAllHotelsAPI(data);

    dispatch(getAllHotelsInfoSuccess(result));
  } catch (e: any) {
    dispatch(getAllHotelsInfoFailed(e?.response?.data?.error));
  }
};
