import { acceptFriendRequestAPI } from "../../../services/accept-friend-request";

export enum AcceptFriendNotificationType {
  ACCEPT_FRIEND_NOTIFICATION_INFO = "ACCEPT_FRIEND_NOTIFICATION_INFO",
  ACCEPT_FRIEND_NOTIFICATION_INFO_SUCCESS = "ACCEPT_FRIEND_NOTIFICATION_INFO_SUCCESS",
  ACCEPT_FRIEND_NOTIFICATION_INFO_FALIURE = "ACCEPT_FRIEND_NOTIFICATION_INFO_FALIURE",
}

export const acceptFriendNotificationInfo = () => (dispatch: any) => {
  dispatch({
    type: AcceptFriendNotificationType.ACCEPT_FRIEND_NOTIFICATION_INFO,
  });
};

export const acceptFriendNotificationInfoSuccess =
  (data: any) => (dispatch: any) => {
    dispatch({
      type: AcceptFriendNotificationType.ACCEPT_FRIEND_NOTIFICATION_INFO_SUCCESS,
      data,
    });
  };

export const acceptFriendNotificationInfoFailed =
  (msg: string) => (dispatch: any) => {
    dispatch({
      type: AcceptFriendNotificationType.ACCEPT_FRIEND_NOTIFICATION_INFO_FALIURE,
      data: { msg },
    });
  };

export const acceptFriendNotificationData =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(acceptFriendNotificationInfo());
      const result: any = await acceptFriendRequestAPI(data);
      dispatch(acceptFriendNotificationInfoSuccess(result));
    } catch (e: any) {
      dispatch(acceptFriendNotificationInfoFailed(e.response.data.message));
    }
  };
