import { deletePlanAPI } from "../../../services/deletePlan";

export enum DeletePlanType {
  DELETEPLAN_INFO = "DELETEPLAN_INFO",
  DELETEPLAN_INFO_SUCCESS = "DELETEPLAN_INFO_SUCCESS",
  DELETEPLAN_INFO_FALIURE = "DELETEPLAN_INFO_FALIURE",
}

export const deletePlanInfo = () => (dispatch: any) => {
  dispatch({
    type: DeletePlanType.DELETEPLAN_INFO,
  });
};

export const deletePlanInfoSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: DeletePlanType.DELETEPLAN_INFO_SUCCESS,
    data,
  });
};

export const deletePlanInfoFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: DeletePlanType.DELETEPLAN_INFO_FALIURE,
    data: { msg },
  });
};

export const deletePlanData = (finalData: any) => async (dispatch: any) => {
  try {
    dispatch(deletePlanInfo());
    const result: any = await deletePlanAPI(finalData);
    dispatch(deletePlanInfoSuccess(result));
  } catch (e: any) {
    dispatch(deletePlanInfoFailed(e.response.data.message));
  }
};
