import { addGroupInviteApi } from "../../../services/addGroupInvite";

export enum AddInviteGroupType {
  ADD_INVITE_GROUP_INFO = "ADD_INVITE_GROUP_INFO",
  ADD_INVITE_GROUP_INFO_SUCCESS = "ADD_INVITE_GROUP_INFO_SUCCESS",
  ADD_INVITE_GROUP_INFO_FALIURE = "ADD_INVITE_GROUP_INFO_FALIURE",
}

export const addInviteGroupInfo = () => (dispatch: any) => {
  dispatch({
    type: AddInviteGroupType.ADD_INVITE_GROUP_INFO,
  });
};

export const addInviteGroupSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: AddInviteGroupType.ADD_INVITE_GROUP_INFO_SUCCESS,
    data,
  });
};
export const addInviteGroupFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: AddInviteGroupType.ADD_INVITE_GROUP_INFO_FALIURE,
    data: { msg },
  });
};

export const addInviteGroupApi = (finalData: any) => async (dispatch: any) => {

  try {
    dispatch(addInviteGroupInfo());
    const result: any = await addGroupInviteApi(finalData);
    dispatch(addInviteGroupSuccess(result));
  } catch (e: any) {
    dispatch(addInviteGroupFailed(e.message));
  }
};
